<template>
  <div class="wait_all">
    <div class="wait_table">
      <el-table
        height="100%"
        ref="multipleTable"
        :data="tableData"
        class="v_table"
        :header-cell-style="getRowClass"
      >
        <template slot="empty">
          <div>
            <img src="@/assets/nodata.png" class="nodata" />
            <div>无数据</div>
          </div>
        </template>
        <el-table-column label="姓名" width="200">
          <template slot-scope="scope">
            <div v-if="scope.row.umsUser" class="member">
              <img
                :src="scope.row.umsUser.headUrl"
                class="member_head"
                v-if="scope.row.umsUser.headUrl"
              />
              <img src="@/assets/head.png" v-else class="member_head" />
              <div>{{ scope.row.umsUser.name }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="员工工号" width="130">
          <template slot-scope="scope">
            <div v-if="scope.row.umsUser">
              {{ scope.row.umsUser.jobNumber }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="部门" width="170">
          <template slot-scope="scope">
            <div v-if="scope.row.umsDepartment" class="ellipsis">
              {{ scope.row.umsDepartment.name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="外出地址" width="200">
        </el-table-column>
        <el-table-column label="外出开始时间" width="200">
          <template slot-scope="scope">
            <div>{{ handelTime(scope.row.timeBegin) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="外出结束时间" width="200">
          <template slot-scope="scope">
            <div>{{ handelTime(scope.row.timeEnd) }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="外出时长(小时)" width="150">
          <template slot-scope="scope">
            <div>{{ formatHours(scope.row.timeBegin, scope.row.timeEnd) }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="description" label="外出事由" width="230">
        </el-table-column>
        <el-table-column prop="createTime" label="提交时间" width="200">
        </el-table-column>
        <el-table-column prop="userExec" label="审批人" width="120">
          <template slot-scope="scope">
            <div v-if="scope.row.execUmsUser">
              {{ scope.row.execUmsUser.name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="审批时间" width="200">
          <template slot-scope="scope">
            <div>{{ handelTime(scope.row.timeExec) }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="opinion" label="审批意见" width="230">
        </el-table-column>
        <el-table-column label="审批状态" width="130">
          <template slot-scope="scope">
            <div v-if="scope.row.status == 1">同意</div>
            <div v-if="scope.row.status == 2">驳回</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div
      class="newpagination"
      style="padding-left: 20px; box-sizing: border-box"
    >
      <div class="newpagination_text">共 {{ pages.total }} 条数据</div>
      <el-pagination
        layout="prev, pager, next"
        :total="pages.total"
        :current-page="pages.pageNum"
        :page-size="10"
        @current-change="chagePage"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import http from "@/utils/http";
export default {
  data() {
    return {
      tableData: [], //表格数据
      pages: {}, //分页数据
      searchForm: {}, //搜索表单
    };
  },
  props: ["searchValue"],

  watch: {
    searchValue: {
      handler(newValue, oldValue) {
        if (newValue) {
          var object = JSON.stringify(newValue);
          object = JSON.parse(object);

          // 判断是否选了日期
          if (newValue.selectDate) {
            object.beginDay = this.formatDays(object.selectDate[0]);
            object.endDay = this.formatDays(object.selectDate[1]);
          }

          delete object.selectDate;

          this.searchForm = object;

          this.getApprovalList({
            pageNum: 1,
          });
        }
      },
      deep: true,
    },
  },
  methods: {
    // 获取加班审批列表
    getApprovalList(object) {
      http
        .get(
          "/bmsApply/list",
          Object.assign(
            {
              pageSize: 10,
              type: 4,
              statusList: "1,2",
            },
            object,
            this.searchForm
          )
        )
        .then((res) => {
          if (res.code == 200) {
            this.tableData = res.data.list;
            this.pages = res.data;
          }
        });
    },
    // 表头背景颜色变色
    getRowClass({ rowIndex, columnIndex }) {
      if (rowIndex == 0) {
        return "background:#F5F6FA;color:#5C5C5C";
      }
    },
    formatHours(date1, date2) {
      // 创建Date对象
      var date1 = new Date(date1);
      var date2 = new Date(date2);

      // 计算两个日期之间的毫秒差值
      var timeDifference = Math.abs(date2 - date1);

      // 将毫秒差值转换为小时，并保留一位小数
      var hoursDifference = (timeDifference / (1000 * 60 * 60)).toFixed(1);
      return hoursDifference;
    },
    // 处理时间
    handelTime(val) {
      if (val) {
        // 将字符串解析为Date对象
        const dateTime = new Date(val);
        const formattedDateTime = `${dateTime.getFullYear()}-${(
          "0" +
          (dateTime.getMonth() + 1)
        ).slice(-2)}-${("0" + dateTime.getDate()).slice(-2)} ${(
          "0" + dateTime.getHours()
        ).slice(-2)}:${("0" + dateTime.getMinutes()).slice(-2)}`;

        return formattedDateTime;
      }
    },
    // 标准时间转换成天数
    formatDays(standardTimeString) {
      // 创建一个Date对象
      var date = new Date(standardTimeString);

      // 提取年、月、日
      var year = date.getFullYear();
      var month = ("0" + (date.getMonth() + 1)).slice(-2); // 月份从0开始，所以要加1，并确保两位数格式
      var day = ("0" + date.getDate()).slice(-2); // 确保两位数格式

      // 将年、月、日拼接成所需的格式
      var formattedDate = year + "-" + month + "-" + day;
      return formattedDate;
    },
    // 页数改变
    chagePage(e) {
      this.getApprovalList({
        pageNum: e,
      });
    },
  },
};
</script>

<style scoped>
.wait_all {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.newpagination {
  display: flex;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.wait_table {
  width: 100%;
  flex: 1;
  position: relative;
  box-sizing: border-box;
}
.v_table {
  width: 100%;
  height: 100%;
  position: absolute;
}
.member {
  display: flex;
  align-items: center;
}
.member_head {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 10px;
}
.ellipsis {
  width: 150px;
  white-space: nowrap; /* 防止文本换行 */
  overflow: hidden; /* 隐藏超出容器的文本 */
  text-overflow: ellipsis; /* 显示省略号表示文本被截断 */
}
</style>