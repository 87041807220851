<template>
  <div class="structure">
    <div class="structure_t">审批管理 / <span>事件审批</span></div>
    <div class="structure_c">
      <div class="structure_frame">
        <div class="structure_frame_head">
          <div class="structure_nav">
            <el-badge :is-dot="isHot" class="item">
              <div
                @click="switchTab(1)"
                class="structure_nav_s"
                :style="{
                  color: selectIndex == 1 ? '#0058FF' : '#1A1A1A',
                }"
              >
                待审批
              </div>
            </el-badge>
            <div
              @click="switchTab(2)"
              class="structure_nav_s"
              :style="{
                color: selectIndex == 2 ? '#0058FF' : '#1A1A1A',
              }"
            >
              已审批
            </div>
          </div>
        </div>
      </div>

      <div class="structure_cstretch">
        <!-- 待审批 -->
        <Wait v-if="selectIndex == 1" />
        <!-- 已审批 -->
        <Complete v-if="selectIndex == 2" />
      </div>
    </div>
    <div class="filing">
      CopyRight@2023广州巨源信息技术有限公司-<a
        href="https://beian.miit.gov.cn/"
        >粤ICP备2023127373号-4</a
      >
    </div>
  </div>
</template>

<script>
// 在职成员
import Wait from "./event/wait.vue";
// 离职成员
import Complete from "./event/complete.vue";

import http from "../../utils/http";
export default {
  data() {
    return {
      selectIndex: 1, //当前所在的导航索引
      isHot: false, //审批数量
      navi: [
        {
          name: "待审批",
          id: 1,
        },
        {
          name: "已审批",
          id: 2,
        },
      ],
    };
  },

  components: { Wait, Complete },

  created() {
    // 获取审批数量
    this.getApproval();

    // 实时监听审批时的动态
    this.$bus.$on("approvalNum", (arr) => {
      this.total = arr;
    });
  },

  methods: {
    // 切换tab
    switchTab(id) {
      if (this.selectIndex == id) {
        return;
      }
      this.selectIndex = id;
    },

    // 获取审批列表
    getApproval() {
      http
        .get("/bmsApply/list", {
          pageNum: 1,
          pageSize: 1,
          statusList: 0,
        })
        .then((res) => {
          if (res.code == 200) {
            if (res.data.list.length > 0) {
              this.isHot = true;
            } else {
              this.isHot = false;
            }
          }
        });
    },
  },
};
</script>

<style scoped>
.structure {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  box-sizing: border-box;
  background-color: #f4f7fc;
}
.structure_c {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.structure_cstretch{
  flex: 1;
}
.structure_t {
  font-size: 14px;
  color: #b2b2b2;
  margin-bottom: 16px;
}
.structure_t span {
  color: #5c5c5c;
}
.structure_frame {
  width: 100%;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: white;
}
.structure_frame_head {
  width: 100%;
  height: 75px;
  justify-content: space-between;
  border-bottom: 1px solid rgba(207, 212, 232, 0.5);
  display: flex;
  padding: 0 20px 0 30px;
  box-sizing: border-box;
  align-items: center;
}
.structure_nav {
  display: flex;
  width: 180px;
  color: #1a1a1a;
  justify-content: space-between;
  font-size: 20px;
  align-items: center;
}
.structure_nav_s {
  cursor: pointer;
  font-size: 20px;
}
::v-deep .el-badge__content.is-fixed {
  background: #f0142f;
}
</style>
