<template>
  <div
    :class="[isinput ? 'selected_status' : 'unselected_status', 'button']"
    @click="onSubmit"
  >
    {{ text }}
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  props: ["isinput", "text"],

  methods: {
    // 点击确定传送时间
    onSubmit() {
      if (!this.isinput) {
        return;
      }
      this.$emit("onSubmit");
    },
  },
};
</script>

<style scoped>
.button {
  display: inline-block;
  width: 100px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 4px;
  font-size: 15px;
  opacity: 1;
  margin-left: 14px;
}
.selected_status {
  background: #0058ff;
  color: white;
  cursor: pointer;
}
.unselected_status {
  background: rgba(0, 88, 255, 0.5);
  color: #ffffff;
}
</style>
