<template>
  <div class="department">
    <div class="department_title">组织架构 / <span>部门管理</span></div>
    <div class="department_content">
      <div class="department_t">
        <div class="department_search">
          <el-select
            ref="clearValue"
            v-model="searchValue"
            filterable
            remote
            :loading="loading"
            @change="clickResult"
            class="department_search_inp"
            placeholder="搜索部门名"
            :remote-method="remoteMethod"
          >
            <el-option
              v-for="item in searchData"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <!-- <el-select
            ref="clearValue"
            v-model="searchValue"
            filterable
            remote
            :loading="loading"
            @change="clickResult"
            class="department_search_inp"
            placeholder="搜索部门名"
            :remote-method="remoteMethod"
          >
            <el-option
              v-for="item in searchData"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select> -->
          <img :src="searchicon" class="searchicon" @click="clearup" />
        </div>
        <div>
          <el-button
            @click="addBranchfun"
            type="primary"
            icon="el-icon-plus"
            class="structure_addstaff"
            >新建部门</el-button
          >
          <el-select
            value="更多功能"
            class="memeber_operation"
            @change="batchOperation"
          >
            <el-option
              v-for="item in operate"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="department_table">
        <el-table
          height="100%"
          v-if="menuTable"
          :data="tableData"
          :header-cell-style="getRowClass"
          :row-class-name="getRoStyle"
          class="department_table_cell"
          :key="treeKey"
          row-key="id"
          :default-expand-all="isExpand"
          :tree-props="{
            children: 'subDepartment',
            hasChildren: 'hasChildren',
          }"
        >
          <template slot="empty">
            <div>
              <img src="@/assets/nodata.png" class="nodata" />
              <div>无数据</div>
            </div>
          </template>
          <el-table-column
            prop="name"
            label="部门名称"
            class-name="el-table-lable-box"
          >
            <template slot-scope="scope">
              <div class="department_n">
                <el-checkbox
                  @change="
                    (checked) => handleSelectionChange(checked, scope.row.id)
                  "
                  v-model="scope.row.isclick"
                  class="department_n_checkbox"
                  size="16"
                ></el-checkbox>
                <div>{{ scope.row.name }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="no" label="部门编号"> </el-table-column>
          <el-table-column prop="userCount" label="部门人数"> </el-table-column>
          <el-table-column prop="user" label="负责人">
            <template slot-scope="scope">
              <div v-if="scope.row.umsUser" class="user">
                <img
                  :src="scope.row.umsUser.headUrl"
                  v-if="scope.row.umsUser.headUrl"
                  class="member_head"
                />
                <img src="@/assets/head.png" v-else class="member_head" />
                <div>{{ scope.row.umsUser.name }}</div>
              </div>
              <div v-else>--</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="300" fixed="right">
            <template slot-scope="scope">
              <el-link type="primary" @click="addsubDept(scope.row.id)"
                >添加子部门</el-link
              >
              <el-link
                type="primary"
                style="margin-left: 24px"
                @click="editDept(scope.row)"
                >编辑部门</el-link
              >
              <el-link
                type="primary"
                style="margin-left: 24px"
                v-if="scope.row.parent"
                @click="deletecheckout(scope.row.id)"
                >删除</el-link
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- 添加部门 -->
      <el-drawer
        :wrapperClosable="false"
        title=""
        :key="addDepatKey"
        :visible.sync="addBranch"
        :with-header="false"
        size="25%"
      >
        <Branch
          @close="closeEdit"
          @comfin="Successfully"
          :id="selectId"
          :userName="selectName"
          :type="branchType"
        />
      </el-drawer>

      <!-- 删除部门 -->
      <showDialog
        :title="'确认删除部门'"
        :tips="tips"
        :isShow="deleteButton"
        @closeDiglog="canceldelete"
        @affirmDiglog="delectdept"
      />

      <!-- 删除部门提示 -->
      <el-dialog
        title=""
        :visible.sync="errorTips"
        width="33%"
        :show-close="false"
        :close-on-click-modal="false"
      >
        <div class="diglog_h">
          <div>确认删除部门</div>
          <img
            src="@/assets/structure/close.png"
            class="diglog_icon"
            @click="errorTips = false"
          />
        </div>
        <div class="delectdiglog">
          <div class="delectdiglog_title">{{ tips }}</div>
        </div>

        <div class="diglog_operation">
          <el-button type="primary" @click="errorTips = false">确认</el-button>
        </div>
      </el-dialog>

      <!-- 部门导入 -->
      <Upload
        @close="batchUpload = false"
        @success="uploadReasult"
        :show="batchUpload"
      />
    </div>
    <div class="filing">
      CopyRight@2023广州巨源信息技术有限公司-<a
        href="https://beian.miit.gov.cn/"
        >粤ICP备2023127373号-4</a
      >
    </div>
  </div>
</template>

<script>
import Branch from "./module/branch/newbranch.vue";
import showDialog from "../../assembly/dialog.vue";
import http from "../../utils/http";

import searchIcon from "../../assets/structure/search.png";
import deleteIcon from "../../assets/structure/delete.png";
import Upload from "./module/branch/upload.vue";
import exprotExcel from "../../utils/exprotExcel";
export default {
  data() {
    return {
      tips: "",
      selectId: -1, //所选部门信息
      deleteButton: false, //删除部门
      errorTips: false, //删除部门错误提示窗
      addBranch: false, //添加部门弹窗
      batchUpload: false, //批量添加部门
      branchType: 1, //1是添加，2是子级，3是编辑
      selectIds: [], //所选的id
      selectName: "", //所选的负责人
      searchValue: "", //搜索的关键词
      searchicon: searchIcon, //搜索图标
      tableData: [], //部门列表
      tableDataCopy: [], //部门列表(备份)
      multipleData: [], //多选选项
      loading: false, //部门搜索加载显示
      menuTable: true,
      searchData: [], //部门搜索列表
      treeKey: 0, //结构树key
      isExpand: false, //默认不展开
      addDepatKey: Math.random(), //部门弹窗key
      operate: [
        {
          label: "部门导入",
          value: 1,
        },
        {
          label: "部门导出",
          value: 2,
        },
        {
          label: "批量删除",
          value: 3,
        },
      ],
      tableColumn: [
        {
          title: "部门名称",
          dataIndex: "name",
          id: 1,
        },
        {
          title: "部门编号",
          dataIndex: "no",
          id: 2,
        },
        {
          title: "部门人数",
          dataIndex: "userCount",
          id: 3,
        },
        {
          title: "负责人",
          dataIndex: "umsUserText",
          id: 4,
        },
      ], //导出内容参数
    };
  },
  created() {
    this.getDeptment();
  },
  watch: {
    searchValue(newName, oldName) {
      if (newName) {
        this.searchicon = deleteIcon;
      } else {
        this.searchicon = searchIcon;
      }
    },
  },
  components: { Branch, showDialog, Upload },
  methods: {
    // 表头背景颜色变色
    getRowClass({ rowIndex, columnIndex }) {
      if (rowIndex == 0) {
        return "background:#F5F6FA;color:#5C5C5C";
      }
    },

    getRoStyle({ row, rowIndex }) {
      if (row.id == 0) {
        return "disableheadselection";
      }
    },

    // 获取部门列表
    getDeptment() {
      var object = {};
      if (this.searchValue) {
        object.name = this.searchValue;
      }
      http
        .get(
          "/umsDepartment/list",
          Object.assign(
            {
              pageNum: 1,
              pageSize: 999,
            },
            object
          )
        )
        .then((res) => {
          if (res.code == 200) {
            if (res.data.length > 0) {
              const nestedArr = this.convertToNestedStructure(res.data);

              this.tableData = nestedArr;
              this.tableDataCopy = nestedArr;

              this.isExpand = true;
              this.menuTable = false;
              this.$nextTick(() => {
                this.menuTable = true;
              });
            }
          }
        });
    },

    // 递归处理数据
    convertToNestedStructure(arr) {
      // 创建一个映射，用于快速查找具有特定id的对象
      const idMap = new Map(arr.map((item) => [item.id, item]));

      // 创建一个新数组来存储转换后的嵌套结构
      const nestedArr = [];

      // 遍历原始数组
      arr.forEach((item) => {
        // 如果该项有父元素，则将其添加到父元素的subDepartment数组中
        if (item.parent) {
          const parent = idMap.get(item.parent);
          if (parent) {
            if (!parent.subDepartment) {
              parent.subDepartment = [];
            }
            item.parentDepament = parent.name;
            parent.subDepartment.push(item);
          }
        } else {
          // 如果没有父元素，则添加到新数组的根级别
          nestedArr.push(item);
        }
      });

      // 返回转换后的嵌套数组
      return nestedArr;
    },

    // 部门搜索
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        http
          .get("/umsDepartment/search", {
            departmentName: query,
          })
          .then((res) => {
            if (res.code == 200) {
              this.loading = false;
              this.searchData = res.data;
            }
          });
      } else {
        this.searchData = [];
      }
    },
    // 点击部门搜索结果
    clickResult(value) {
      const searchResult = this.searchNestedObject(this.tableDataCopy, value);
      this.tableData = [searchResult];

      this.isExpand = true;
      this.menuTable = false;
      this.$nextTick(() => {
        this.menuTable = true;
      });
    },
    // 递归筛选
    searchNestedObject(array, targetValue) {
      for (let i = 0; i < array.length; i++) {
        const item = array[i];
        if (item.id === targetValue) {
          // 如果找到目标值，返回当前对象
          return item;
        }
        if (Array.isArray(item.subDepartment)) {
          // 如果对象有子数组，递归搜索子数组
          const result = this.searchNestedObject(
            item.subDepartment,
            targetValue
          );
          if (result) {
            // 如果在子数组中找到目标值，返回整个路径
            return {
              ...item,
              subDepartment: [result], // 只包含找到目标值的子路径
            };
          }
        }
      }
      return null; // 如果没有找到目标值，返回null
    },
    // 清除输入框
    clearup() {
      this.searchValue = "";
      // 重新获取部门列表
      this.getDeptment();
    },

    // 添加部门
    addBranchfun() {
      this.addDepatKey = Math.random();
      this.branchType = 1;
      setTimeout(() => {
        this.addBranch = true;
      }, 200);
    },

    // 添加部门成功
    Successfully() {
      this.selectId = -1;
      this.searchValue = "";

      this.addBranch = false;
      // 重新获取部门列表
      this.getDeptment();
    },

    // 添加子部门
    addsubDept(id) {
      this.selectId = id;
      this.addDepatKey = Math.random();
      this.branchType = 2;
      setTimeout(() => {
        this.addBranch = true;
      }, 200);
    },

    // 编辑部门
    editDept(value) {
      this.selectId = value.id;
      this.addDepatKey = Math.random();
      if (value.umsUser) {
        this.selectName = value.umsUser.name;
      }
      this.branchType = 3;
      setTimeout(() => {
        this.addBranch = true;
      }, 200);
    },

    // 取消编辑或添加
    closeEdit() {
      this.selectId = "";
      this.selectName = "";
      this.addBranch = false;
    },

    // 批量操作
    batchOperation(val) {
      var arr = this.multipleData;
      // 1是批量删除
      switch (val) {
        case 1:
          this.batchUpload = true;
          break;
        case 2:
          this.startDownload();
          break;
        case 3:
          if (this.multipleData.length == 0) {
            this.$message.error("请先选择人员");
            return;
          }
          this.deletecheckout(arr);
          break;
      }
    },

    // 表格下载之前
    startDownload() {
      const loading = this.$loading({
        lock: true,
        text: "导出报表中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let target = event.target;
      if (target.nodeName == "I" || target.nodeName == "SPAN") {
        target = event.target.parentNode;
      }
      target.blur();
      let arr2 = [];
      for (let key of this.tableColumn) {
        arr2.push(key.key);
      }
      http
        .get("/umsDepartment/list", {
          pageNum: 1,
          typeList: "-1",
          pageSize: 99999,
        })
        .then((res) => {
          if (res.code == 200) {
            loading.close();
            if (res.data.length > 0) {
              res.data.map((v) => {
                if (v.umsUser) {
                  v.umsUserText = v.umsUser.name;
                }
                for (let i = 0; i < arr2.length; i++) {
                  if (v[arr2[i]] == undefined) {
                    v[arr2[i]] = "";
                  }
                }
                for (let key in v) {
                  if (v[key] == null) v[key] = "";
                }
              });
              exprotExcel.export(this.tableColumn, res.data, "部门列表");
              this.$message.success("导出成功");
            } else {
              this.$message.error("无数据导出");
            }
          }
        });
    },

    // 删除部门(检验)
    deletecheckout(ids) {
      var arr = [];
      if (Array.isArray(ids)) {
        if (ids.length == 0) {
          this.$message.error("请先选择部门");
          return;
        }
        arr = ids;
      } else {
        arr = [ids];
      }

      http
        .post("/umsDepartment/delete", {
          ids: arr,
          operation: 1,
        })
        .then((res) => {
          if (res.code == 200) {
            this.tips = "部门删除后不可恢复，是否继续？";
            this.selectIds = arr;
            this.deleteButton = true;
          } else {
            this.tips = res.message;
            this.errorTips = true;
          }
        });
    },

    // 取消删除部门
    canceldelete() {
      this.tips = "";
      this.deleteButton = false;
    },

    // 确认删除部门
    delectdept() {
      http
        .post("/umsDepartment/delete", {
          ids: this.selectIds,
          operation: 2,
        })
        .then((res) => {
          if (res.code == 200) {
            // 取消弹窗
            this.canceldelete();

            // 重新获取部门列表
            this.clearup();
            this.$message.success("删除成功");
          } else {
            this.$message.error(res.message);
          }
        });
    },

    // 多选
    handleSelectionChange(val, item) {
      if (val) {
        this.multipleData.push(item);
      } else {
        var index = this.multipleData.findIndex((id) => id == item);
        this.multipleData.splice(index, 1);
      }
    },

    // 上传结束
    uploadReasult() {
      this.batchUpload = false;
      this.getDeptment();
    },
  },
};
</script>

<style scoped>
.department {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  box-sizing: border-box;
  background: #f4f7fc;
}
.department_title {
  font-size: 14px;
  color: #b2b2b2;
  margin-bottom: 16px;
}
.department_title span {
  color: #5c5c5c;
}
.department_content {
  width: 100%;
  overflow-y: scroll;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 30px;
  background: white;
  border-radius: 4px;
  box-shadow: 0px 1px 4px 1px rgba(21, 34, 50, 0.08);
  border-radius: 6px 6px 6px 6px;
  box-sizing: border-box;
}
.department_content::-webkit-scrollbar {
  display: none;
}
.department_t {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  padding-right: 15px;
  box-sizing: border-box;
  justify-content: space-between;
}
.department_search {
  width: 243px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #d0d0d0;
  display: flex;
  align-items: center;
  padding-right: 12px;
  box-sizing: border-box;
  justify-content: space-between;
}
.department_search_inp {
  width: 200px;
}
.delectdiglog {
  width: 100%;
  margin-top: 20px;
  border-top: 1px solid #cfd4e8;
}
.delectdiglog_title {
  font-size: 16px;
  color: #1a1a1a;
  padding: 30px;
  box-sizing: border-box;
}
.diglog_h {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  padding: 24px 24px 0 24px;
  color: #1a1a1a;
}
.diglog_icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.diglog_operation {
  margin: 60px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.department_n {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #1a1a1a;
}
.department_n_checkbox {
  margin-right: 10px !important;
}
::v-deep .el-table-lable-box > .cell {
  padding-left: 30px !important;
  display: flex;
  align-items: center;
}
::v-deep .el-table .cell {
  padding-left: 15px;
}
::v-deep .el-icon-arrow-right {
  font-size: 18px;
}
::v-deep .el-icon-arrow-right:before {
  content: "\e791";
  color: #c0c4cc;
}
.department_table {
  width: 99%;
  flex: 1;
  overflow: hidden;
  position: relative;
}
.department_table_cell {
  position: absolute;
  width: 100%;
  height: 100%;
}
::v-deep .el-table tbody tr:hover > td {
  background-color: rgba(0, 98, 255, 0.05) !important;
}
.department_search:focus-within {
  border-color: #0096fa;
}
::v-deep .el-table__fixed-right-patch {
  width: 0px !important;
}
.memeber_operation {
  margin-left: 8px;
  width: 107px;
}
.user {
  display: flex;
  align-items: center;
}
.member_head {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 10px;
}
</style>
