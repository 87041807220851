var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"structure"},[_vm._m(0),_c('div',{staticClass:"structure_frame"},[_c('div',{staticClass:"structure_frame_head"},[_c('div',{staticClass:"structure_nav"},[_c('div',{staticClass:"structure_nav_content"},[_c('div',{staticClass:"structure_nav_s",style:({
              color: _vm.selectIndex == 1 ? '#0058FF' : '#1A1A1A',
            }),on:{"click":function($event){return _vm.switchTab(1)}}},[_vm._v(" GPS打卡 ")]),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"有固定的办公地点，允许考勤人员在一定的地点范围内（例如 500 米内）进行打卡","placement":"top"}},[_c('img',{staticClass:"tips",attrs:{"src":require("@/assets/tips.png")}})])],1),_c('div',{staticClass:"structure_nav_content"},[_c('div',{staticClass:"structure_nav_s",style:({
              color: _vm.selectIndex == 2 ? '#0058FF' : '#1A1A1A',
            }),on:{"click":function($event){return _vm.switchTab(2)}}},[_vm._v(" Wi-Fi打卡 ")]),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"办公地点有办公Wi-Fi，允许考勤人员连接公司Wi-Fi 进行打卡，且IT能提供一份较完整的MAC地址","placement":"top"}},[_c('img',{staticClass:"tips",attrs:{"src":require("@/assets/tips.png")}})])],1)])])]),_c('div',{staticClass:"structure_frame_content"},[(_vm.selectIndex == 1)?_c('GpsVue'):_vm._e(),(_vm.selectIndex == 2)?_c('WifiVue'):_vm._e()],1),_vm._m(1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"structure_t"},[_vm._v("考勤管理 / "),_c('span',[_vm._v("小程序打卡")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"filing"},[_vm._v(" CopyRight@2023广州巨源信息技术有限公司-"),_c('a',{attrs:{"href":"https://beian.miit.gov.cn/"}},[_vm._v("粤ICP备2023127373号-4")])])
}]

export { render, staticRenderFns }