<template>
  <div class="record common-style">
    <div class="record_head">
      <span style="color: #b2b2b2">系统设置 /</span>
      <span style="color: #5c5c5c"> 角色权限</span>
    </div>
    <div class="record_wrapper">
      <div class="record_search_wrapper">
        <div class="record_search_a" style="width: 243px">
          <el-input
            v-model="dimsearch"
            placeholder="搜索管理员"
            @keyup.enter.native="searchAdminlist"
          >
          </el-input>
          <img
            src="@/assets/structure/delete.png"
            class="searchicon"
            @click="clearup"
            v-if="searchPass"
          />
          <img
            src="@/assets/structure/search.png"
            class="searchicon"
            @click="searchAdminlist"
          />
        </div>
        <div class="record_export">
          <el-button type="primary" icon="el-icon-plus" @click="adminAdd"
            >新增管理员</el-button
          >
        </div>
      </div>
      <div class="record_table_wrapper role_empty_text">
        <el-table
          height="95%"
          :data="tableData"
          :header-cell-style="{
            background: '#F5F6FA',
            color: '#5C5C5C',
          }"
          class="record_table"
        >
          <template slot="empty">
            <div>
              <img src="@/assets/nodata.png" class="nodata" />
              <div style="height: 50px">无数据</div>
            </div>
          </template>
          <el-table-column label="" header-align="left" align="left" width="20">
            {{ empty }}
          </el-table-column>
          <el-table-column
            prop="name"
            label="管理员"
            header-align="left"
            align="left"
            width="150"
          >
            <template slot-scope="scope">{{ scope.row.name }}</template>
          </el-table-column>
          <el-table-column
            prop="departmentName"
            label="部门"
            header-align="left"
            align="left"
            width="150"
          >
            <template slot-scope="scope">
              {{
                scope.row.umsDepartment ? scope.row.umsDepartment.name : "--"
              }}</template
            >
          </el-table-column>
          <el-table-column
            prop="status"
            label="角色"
            header-align="left"
            align="left"
            width="120"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.status == 3">管理员</span>
              <span v-else-if="scope.row.status == 4">超级管理员</span>
            </template>
          </el-table-column>
          <el-table-column
            label="权限"
            header-align="left"
            align="left"
            width="120"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.status == 4">全部</span>
              <span v-else>{{
                splitPermissions(scope.row.umsUserPermissionList)
              }}</span>
            </template>
          </el-table-column>
          <el-table-column header-align="left" align="left" width="50">
            {{ empty }}
          </el-table-column>
          <el-table-column
            label="操作"
            header-align="left"
            align="left"
            width="250"
          >
            <template slot-scope="scope">
              <div class="operate">
                <span
                  v-if="scope.row.status == 4"
                  @click="transferAdmin(scope.row)"
                >
                  转让
                </span>
                <span
                  v-if="scope.row.status == 3"
                  @click="toremoveAdmin(scope.row.id)"
                >
                  移除
                </span>
                <span
                  v-if="scope.row.status == 3"
                  @click="toupdateAdmin(scope.row)"
                >
                  编辑
                </span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="filing">
      CopyRight@2023广州巨源信息技术有限公司-<a
        href="https://beian.miit.gov.cn/"
        >粤ICP备2023127373号-4</a
      >
    </div>
    <!-- 转让 -->
    <el-dialog
      width="473px"
      :visible.sync="dialogTransfer"
      custom-class="role_transfer_dialog"
      top="0"
      :showClose="false"
      :close-on-click-modal="false"
    >
      <span slot="title" class="dialog_title">
        <i
          class="el-icon-close dialog_title_close"
          @click="cancelTransferNext"
        ></i>
        <span class="dialog_title_t">转让</span>

        <div class="dialog_confirm">
          <el-button
            class="add_confirm"
            @click="cancelTransferNext"
            style="background: none"
            >取 消</el-button
          >
          <Button
            :text="'下一步'"
            :isinput="checkInput"
            @onSubmit="transferNext"
            >下一步</Button
          >

          <!-- <el-button type="primary" v-else class="add_confirm-next" disabled
            >下一步</el-button
          > -->
        </div>
      </span>
      <el-form
        class="form_transfer"
        :model="transferForm"
        :rules="rules"
        ref="ruleForm"
        label-position="left"
      >
        <div class="form_transfer-tip">
          <span>转让超级管理员帐号，需要验证当前超级管理员身份</span>
        </div>
        <div style="padding-top: 30px; padding-bottom: 20px">
          超级管理员：{{ currentAdmin }}
        </div>
        <el-form-item>
          <el-input
            v-model="transferForm.phone"
            placeholder="请输入手机号"
            style="width: 413px"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-input
            v-model="transferForm.code"
            placeholder="请输入验证码"
            style="width: 413px"
            maxlength="6"
          >
            <template slot="suffix">
              <span style="display: inline-block">|</span>
              <div class="form_transfer_c" @click="getCode">
                {{ codeText }}
              </div>
            </template>
          </el-input>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 转让 下一步-->
    <el-dialog
      width="473px"
      :visible.sync="dialogTransferNext"
      custom-class="role_transfer_dialog_t"
      top="0"
      :showClose="false"
      :close-on-click-modal="false"
    >
      <span slot="title" class="dialog_title">
        <i class="el-icon-close dialog_title_close" @click="cancelTransfer"></i>
        <span class="dialog_title_t">转让</span>

        <div class="dialog_confirm">
          <el-button
            class="add_confirm"
            @click="cancelTransfer"
            style="background: none"
            >取 消</el-button
          >

          <el-button type="primary" @click="transferConfirm" class="add_confirm"
            >确认</el-button
          >
        </div>
      </span>
      <el-form
        class="form_transfer"
        :model="transferFormNext"
        :rules="rulesTwo"
        ref="ruleForm2"
        label-position="left"
      >
        <div class="form_transfer-tip">
          <span>修改完成后，绑定的手机号会同步修改</span>
        </div>
        <div style="padding-top: 30px; padding-bottom: 20px">
          新的超级管理员
        </div>
        <el-form-item prop="formnum">
          <el-select
            v-model="sMember"
            filterable
            remote
            placeholder="输入本企业的员工姓名或手机号"
            :remote-method="remoteTranster"
            :loading="loading"
            style="width: 413px"
          >
            <template>
              <el-option
                v-for="item in memberList"
                :key="item.id"
                :label="`${item.name}`"
                :value="`${item.id},${item.phone}`"
              >
              </el-option>
            </template>
          </el-select>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 移除 -->
    <el-dialog
      width="640px"
      height="266px"
      title="移除"
      :visible.sync="dialogRemove"
      :close-on-click-modal="false"
    >
      <div class="remove_body">确认移除该管理员，移除后将失去所有管理权限</div>
      <span slot="footer" class="dialog-footer">
        <el-button
          class="add_confirm"
          style="background: none; margin-right: 20px"
          @click="dialogRemove = false"
          >取 消</el-button
        >
        <el-button type="primary" class="add_confirm" @click="removeAdmin"
          >确认移除</el-button
        >
      </span>
    </el-dialog>
    <!-- 新增管理员 -->
    <el-drawer
      size="473px"
      :visible.sync="dialogAdd"
      custom-class="dialog_device common_drawer"
      direction="rtl"
      :showClose="false"
      :wrapperClosable="false"
    >
      <span slot="title" class="dialog_title">
        <i class="el-icon-close icon-close" @click="cancelAdd"></i>
        <span class="dialog_t">{{
          dialogUpdate ? "编辑管理员" : "新增管理员"
        }}</span>
        <div class="dialog_confirm">
          <el-button
            class="add-confirm_a"
            @click="cancelAdd"
            style="background: none"
            >取 消</el-button
          >
          <el-button type="primary" @click="addAdmin" class="add-confirm_a"
            >确 认</el-button
          >
        </div>
      </span>
      <div class="form_device">
        <el-form
          :model="ruleForm"
          label-position="left"
          ref="ruleForm"
          :rules="rules"
        >
          <el-form-item label="管理员" prop="sAdmin">
            <el-select
              :disabled="dialogUpdate"
              v-model="ruleForm.sAdmin"
              filterable
              remote
              placeholder="输入本企业的员工姓名、员工工号、设备工号"
              @change="changeAdmin"
              :remote-method="remoteMethod"
              :loading="loading"
            >
              <el-option
                v-for="item in adminList"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              >
                {{ item.name }}<span v-if="item.phone">({{ item.phone }})</span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="权限" prop="permissionList">
            <el-checkbox-group
              v-model="ruleForm.permissionList"
              class="update_checkgroup"
            >
              <el-checkbox
                :label="item.id"
                v-for="item in permissionsList"
                :key="item.id"
                >{{ item.description }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import http from "../../utils/http";
import Button from "./button.vue";
export default {
  components: {},
  data() {
    return {
      empty: "",
      tableData: [],
      ruleForm: {
        permissionList: [],
      }, //管理员表单
      original: [], //原始权限表格
      dimsearch: "", //列表搜索管理员
      sMember: "",
      adminList: [],
      memberList: [],
      queryName: "",
      currentAdmin: "", //当前管理员
      updatenName: "", //编辑管理员
      uId: 0, //管理员ID
      loading: false,
      dialogTransfer: false, //转让
      dialogTransferNext: false, //转让的下一步
      dialogRemove: false, //移除
      dialogAdd: false, //新增管理员
      dialogUpdate: false, //编辑
      checkInput: false, //检测是否完全输入
      transferForm: {
        phone: "",
        code: "",
      },
      permissionsList: [],
      transferFormNext: {
        transferTicket: "",
      },

      admin: "11111",
      perList: [], //编辑权限列表
      timer: null,
      codeText: "获取验证码",
      deteId: 0, //移除的ID
      currentPhone: "",
      currentId: null, //当前选择的id
      canClick: true, //节流
      rules: {
        sAdmin: [
          { required: true, message: "请先选择管理员", trigger: "change" },
        ],
        permissionList: [
          { required: true, message: "至少选择一个选项", trigger: "change" },
        ],
      },
      rulesTwo: {
        sMember: [{ required: true, message: "必填", trigger: "change" }],
      },
      empty: "",
      searchPass: false,
    };
  },
  components: { Button },

  watch: {
    // 监听输入框变化
    transferForm: {
      handler: function (val, oldval) {
        if (val.phone && val.code) {
          this.checkInput = true;
        } else {
          this.checkInput = false;
        }
      },
      deep: true,
    },
    dimsearch(newName, oldName) {
      if (newName) {
        this.searchPass = true;
      } else {
        this.searchPass = false;

        this.getAdminList({});
      }
    },
  },
  created() {
    this.getAdminList({});

    this.getRoleList();
  },

  mounted() {},
  methods: {
    // 获取权限列表
    getRoleList() {
      http
        .get("/umsPermission/list", {
          pageNum: 1,
          pageSize: 999,
        })
        .then((res) => {
          var arr = res.data.list;
          arr = arr.filter(
            (obj) => obj.id !== 3 && obj.id !== 4 && obj.id !== 10
          );
          this.permissionsList = arr;
        });
    },
    clearup() {
      this.dimsearch = "";
      this.getAdminList({});
    },
    // 获取管理员列表
    getAdminList(obj) {
      http
        .get(
          "/umsUser/list/admin",
          Object.assign(
            {
              pageNum: 1,
              pageSize: 999,
            },
            obj
          )
        )
        .then((res) => {
          if (res.code == 200) {
            setTimeout(() => {
              var userInfo = localStorage.getItem("userinfo");
              if (userInfo) {
                userInfo = JSON.parse(userInfo);
              }
              res.data.list.map((item) => {
                if (item.umsUserPermissionList) {
                  item.umsUserPermissionList.sort(
                    (a, b) => a.permission - b.permission
                  );
                }
              });
              this.tableData = res.data.list;
              var depa = "";
              if (userInfo.umsDepartment) {
                depa = {
                  name: userInfo.umsDepartment.name,
                };
              }
              this.tableData.unshift({
                name: userInfo.name,
                status: 4,
                id: userInfo.id,
                phone: userInfo.phone,
                umsDepartment: depa,
              });
            }, 500);
          } else {
            this.$message.error(res.message);
          }
        });
    },
    splitPermissions(p) {
      if (p.length == 8) return "全部";
      if (p.length <= 0) return "-";
      let arr = [];
      // p.sort((a, b) => a.permission - b.permission);
      p.forEach((item) => {
        var value = this.permissionsList.find(
          (citem) => citem.id == item.permission
        );
        if (value) {
          arr.push(value.description);
        }
      });
      if (arr.length == 1) return arr.join("");
      else return arr.join("/");
    },
    searchAdminlist() {
      if (!this.dimsearch) {
        return;
      }
      http
        .get("/umsUser/list/admin", {
          likeString: this.dimsearch,
        })
        .then((res) => {
          if (res.code == 200) {
            this.tableData = res.data.list;
          } else {
            this.$message.error(res.message);
          }
        });
    },
    // 搜索员工姓名或手机号
    remoteTranster(query) {
      this.queryName = query;
      console.log(query);
      this.$refs["ruleForm2"].validate((valid) => {
        if (valid) {
          http
            .get("/umsUser/list", {
              likeString: query,
              typeList: "2,3",
            })
            .then((res) => {
              if (res.code == 200) {
                this.memberList = res.data.list;
              } else {
                this.$message.error(res.message);
              }
            });
        }
      });
    },
    // 确认转让
    transferConfirm() {
      let id = this.sMember.split(",")[0];
      let num = this.sMember.split(",")[1];

      if (num == "undefined") {
        this.$message.error("该用户没绑定手机,无法转让");
        return;
      }

      http
        .post("/umsUser/update", {
          ids: [this.currentId],
          transferTicket: this.transferFormNext.transferTicket,
          phone: this.transferForm.phone,
          transferId: parseInt(id),
          userUpdateType: "ADMIN_TRANSFER",
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("转让成功");
            this.dialogTransferNext = false;
            var _this = this;
            _this.$router.replace("/");
            // 清除缓存
            localStorage.clear();
          } else {
            this.$message.error(res.message);
          }
        });
    },
    remoteMethod(query) {
      http
        .get("/umsUser/list", {
          likeString: query,
          typeList: "2",
        })
        .then((res) => {
          if (res.code == 200) {
            this.adminList = res.data.list;
          } else {
            this.$message.error(res.message);
          }
        });
    },
    searchAdmin() {
      http
        .get("/umsUser/list", {
          likeString: this.sAdmin,
          typeList: 2,
        })
        .then((res) => {
          if (res.code == 200) {
            this.adminList = res.data.list;
          } else {
            this.$message.error(res.message);
          }
        });
    },
    // 新增管理员弹窗
    adminAdd() {
      this.dialogAdd = true; //新增管理员
      this.dialogUpdate = false; //编辑
    },
    // 选择管理员
    changeAdmin(value) {
      var val = this.adminList.find((item) => item.name == value);
      if (val) {
        this.ruleForm.sAdmin = val.name;
        this.ruleForm.id = val.id;
        this.ruleForm.phone = val.phone;
      }
    },
    // 确认绑定管理员
    addAdmin() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (!this.ruleForm.phone) {
            this.$message.error("请先绑定手机");
            return;
          }
          var object = JSON.stringify(this.ruleForm);
          object = JSON.parse(object);
          object.ids = [object.id];
          delete object.id;
          object.status = 3;
          if (this.dialogUpdate) {
            object.userUpdateType = "ADMIN_UPDATE";
            // let arr3 = object.permissionList.filter(
            //   (item) => !this.original.includes(item)
            // );
            // object.permissionList = arr3;
            http.post("/umsUser/update", object).then((res) => {
              if (res.code == 200) {
                this.$message.success("修改成功");
                this.getAdminList({ name: this.dimsearch });
                this.cancelAdd();
              } else {
                this.$message.error(res.message);
              }
            });
          } else {
            object.userUpdateType = "ADMIN_ADD";
            http.post("/umsUser/update", object).then((res) => {
              if (res.code == 200) {
                this.$message.success("新增成功");
                this.getAdminList({ name: this.dimsearch });
                this.cancelAdd();
              } else {
                this.$message.error(res.message);
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    checkNum() {
      const re = /^[0-9]+.?[0-9]*/;
      if (re.test(this.queryName)) return true;
      else return false;
    },
    getCode() {
      if (this.transferForm.phone != this.currentPhone) {
        this.$message.error("该手机号与当前超级管理员手机号不同，请重新填写");
        return;
      }
      if (!this.canClick) return;
      this.canClick = false;
      http
        .get("/umsSysUser/code_get", {
          phone: this.transferForm.phone,
          getCodeType: "SUPER_TRANSFER",
        })
        .then((res) => {
          if (res.code == 200) {
            const times = 60; // 倒计时时间
            if (!this.timer) {
              let count = times;
              this.codeText = `${count}s`;
              this.timer = setInterval(() => {
                if (count > 0 && count <= times) {
                  count--;
                  this.codeText = `${count}s`;
                } else {
                  clearInterval(this.timer);
                  this.codeText = "发送验证码";
                  this.timer = null;
                  this.canClick = true;
                }
              }, 1000);
            }
          } else {
            this.codeText = "发送验证码";
            this.canClick = true;
            if (res.message == "操作失败") {
              this.$message.error("获取验证码过于频繁，请1分钟后再试");
            } else {
              this.$message.error(res.message);
            }
          }
        });
    },
    // 取消管理员弹窗(管理员)
    cancelTransferNext() {
      this.dialogTransfer = false;
      this.sMember = "";
      this.transferForm = {};
    },
    // 转让管理员(第一步)
    transferNext() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.isdisabled = false;
          var obj = JSON.stringify(this.transferForm);
          obj = JSON.parse(obj);
          obj.getCodeType = "SUPER_TRANSFER";
          http.post("/umsSysUser/code_verify", obj).then((res) => {
            if (res.code == 200) {
              this.dialogTransfer = false;
              this.dialogTransferNext = true;
              this.transferFormNext.transferTicket = res.data.actionTicket;
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    updateAdmin() {
      let p = [];
      if (this.perList.length == 0) {
        this.$message.error("最少选择一个权限");
        return;
      }
      this.perList.map((item) => {
        switch (item) {
          case "组织架构":
            p.push(1);
            break;
          case "审批管理":
            p.push(2);
            break;
          case "访客管理":
            p.push(3);
            break;
          case "考勤管理":
            p.push(4);
            break;
          case "设备管理":
            p.push(5);
            break;
          case "通行记录":
            p.push(6);
            break;
          case "账号管理":
            p.push(7);
            break;
          case "前台登记":
            p.push(8);
            break;
        }
      });
      http
        .post("/user/admin_edit", {
          id: parseInt(this.uId),
          permissionList: p,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("修改成功");
            this.getAdminList({ name: this.dimsearch });
            this.dialogUpdate = false;
          } else {
            this.$message.error(res.message);
          }
        });
    },
    toremoveAdmin(a) {
      this.dialogRemove = true;
      this.deteId = a;
    },
    removeAdmin() {
      http
        .post("//umsUser/update", {
          ids: [this.deteId],
          userUpdateType: "ADMIN_DELETE",
          status: 2,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("移除成功");
            this.dialogRemove = false;
            this.getAdminList({ name: this.dimsearch });
          } else {
            this.$message.error(res.message);
          }
        });
    },
    transferAdmin(name) {
      if (!name.phone) {
        this.$message.error("请先绑定手机号");
        return;
      }
      this.currentAdmin = name.name;
      this.currentPhone = name.phone;
      this.currentId = name.id;
      this.dialogTransfer = true;
    },
    toupdateAdmin(n) {
      var arr = [];
      if (n.umsUserPermissionList) {
        n.umsUserPermissionList.map((item) => {
          arr.push(item.permission);
        });
      }
      this.ruleForm.id = n.id;
      this.ruleForm.phone = n.phone;
      this.ruleForm.sAdmin = n.name;
      this.original = arr;
      this.ruleForm.permissionList = arr;
      setTimeout(() => {
        this.dialogUpdate = true;
        this.dialogAdd = true;
      }, 200);
    },
    cancelTransfer() {
      this.dialogTransferNext = false;
      this.sMember = "";
      this.transferForm = {};
    },
    cancelAdd() {
      this.ruleForm = {
        permissionList: [],
      };
      this.$refs.ruleForm.resetFields();
      this.dialogAdd = false;
    },
  },
};
</script>
<style scoped>
.record {
  width: 100%;
  padding: 16px 20px;
  background: #f4f7fc;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.record_head {
  font-size: 14px;
}

.record_wrapper {
  width: calc(100vw - 290px);
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 1px 4px 1px rgba(21, 34, 50, 0.08);
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  margin-top: 16px;
}

.record_search_wrapper {
  display: flex;
  padding: 20px;
  border-bottom: 1px solid rgba(207, 212, 232, 0.5);
}

.record_search {
  display: flex;
  justify-content: space-between;
}

.record_export {
  margin-left: auto;
  margin-right: 40px;
  width: 88px;
}

.record_table_wrapper {
  padding: 20px 18px 20px 20px;
  width: calc(100vw - 330px);
  font-size: 16px;
  flex: 1;
  position: relative;
  box-sizing: border-box;
}

.record_table {
  position: absolute;
  font-size: 16px;
  overflow: auto;
  width: 100%;
  height: 100%;
}

.operate {
  display: flex;
  /* justify-content: space-between; */
  padding: 0 !important;
  color: #0058ff;
}

.operate span {
  margin-right: 40px;
  padding: 0 !important;
  cursor: pointer;
}

.dialog_title_t {
  font-size: 18px;
  margin-left: 20px;
  font-weight: 400;
  color: #1a1a1a;
}

.form_transfer {
  margin: 20px;
}

.dialog_confirm {
  margin: 0 0 0 auto;
}

.add_confirm {
  background: #0058ff;
  font-size: 15px;
  width: 100px;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
}

.add_confirm-next {
  background: #0058ff;
  font-size: 15px;
  width: 100px;
  border-radius: 4px 4px 4px 4px;
  opacity: 0.5;
}

.form_transfer-tip {
  width: 399px;
  height: 32px;
  background-color: rgba(0, 88, 255, 0.1);
  border-radius: 4px 4px 4px 4px;
  line-height: 32px;
  font-size: 14px;
  padding-left: 14px;
  color: #1a1a1a;
}

.remove_body {
  margin: 30px;
  font-size: 16px;
  font-weight: 400;
  color: #1a1a1a;
  height: 50px;
}

.update_body {
  margin-left: 40px;
  margin-top: 40px;
  font-size: 16px;
  font-weight: 400;
}

.record_search_a {
  width: 413px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #d0d0d0;
  display: flex;
  align-items: center;
  padding-right: 12px;
  box-sizing: border-box;
  justify-content: space-between;
  margin-top: 14px;
}

.update_checkgroup {
  display: flex;
  flex-wrap: wrap;
  line-height: 35px;
  margin-top: 4px;
  color: #1a1a1a;
}

.form_transfer_c {
  display: inline-block;
  width: 100px;
  color: #0058ff;
  cursor: pointer;
}

::v-deep .el-dialog__title {
  color: #1a1a1a;
}

.common_color {
  color: #1a1a1a;
}

::v-deep .el-checkbox__label {
  color: #1a1a1a;
  font-size: 15px;
  font-weight: normal;
}

.icon-close {
  font-size: 24px;
  cursor: pointer;
  color: #7e84a3;
}

.dialog_title {
  display: flex;
  align-items: center;
  color: #1a1a1a;
}

.dialog_t {
  font-size: 18px;
  margin-left: 20px;
}
.form_device {
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
  overflow-x: hidden;
}
::v-deep .form_device .el-select {
  width: 413px;
  height: 40px;
}
::v-deep .form_device .el-input__inner {
  width: 413px;
  height: 40px;
}
/*
    给所有表格空项加上--
  */
::v-deep.el-table .el-table__body td .cell:empty::after,
.el-table .el-table__body td .cell > span:empty::after,
.el-table .el-table__body td .cell .approval:empty::after,
.el-table .el-table__body td .cell div:empty::after,
.el-table .el-table__body td .cell .line-limit-length:empty::after {
  content: "--";
}
</style>