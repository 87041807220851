<template>
  <div class="wait_all">
    <div class="wait_table">
      <el-table
        height="100%"
        ref="multipleTable"
        :data="tableData"
        class="v_table"
        :header-cell-style="getRowClass"
      >
        <template slot="empty">
          <div>
            <img src="@/assets/nodata.png" class="nodata" />
            <div>无数据</div>
          </div>
        </template>
        <el-table-column label="姓名" width="170">
          <template slot-scope="scope">
            <div v-if="scope.row.umsUser" class="member">
              <img
                :src="scope.row.umsUser.headUrl"
                class="member_head"
                v-if="scope.row.umsUser.headUrl"
              />
              <img src="@/assets/head.png" v-else class="member_head" />
              <div>{{ scope.row.umsUser.name }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="员工工号" width="130">
          <template slot-scope="scope">
            <div v-if="scope.row.umsUser">
              {{ scope.row.umsUser.jobNumber }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="部门" width="170">
          <template slot-scope="scope">
            <div v-if="scope.row.umsDepartment" class="ellipsis">
              {{ scope.row.umsDepartment.name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="外出地址" width="200">
        </el-table-column>
        <el-table-column prop="timeBegin" label="外出开始时间" width="200">
          <template slot-scope="scope">
            <div>{{ handelTime(scope.row.timeBegin) }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="timeEnd" label="外出结束时间" width="200">
          <template slot-scope="scope">
            <div>{{ handelTime(scope.row.timeEnd) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="外出时长(小时)" width="150">
          <template slot-scope="scope">
            <div>{{ formatHours(scope.row.timeBegin, scope.row.timeEnd) }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="description" label="外出事由" width="230">
        </el-table-column>
        <el-table-column prop="createTime" label="提交时间" width="200">
        </el-table-column>
        <el-table-column label="操作" width="200" fixed="right">
          <template slot-scope="scope">
            <el-link
              type="primary"
              style="margin-left: 4px"
              @click="openReviewen(1, scope.row.id)"
              >同意</el-link
            >
            <el-link
              type="danger"
              style="margin-left: 24px"
              @click="openReviewen(2, scope.row.id)"
              >驳回</el-link
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div
      class="newpagination"
      style="padding-left: 20px; box-sizing: border-box"
    >
      <div class="newpagination_text">共 {{ pages.total }} 条数据</div>
      <el-pagination
        layout="prev, pager, next"
        :total="pages.total"
        :current-page="pages.pageNum"
        :page-size="10"
        @current-change="chagePage"
      >
      </el-pagination>
    </div>

    <!-- 审核弹窗 -->
    <el-dialog
      title=""
      :visible.sync="isAuditing"
      width="33%"
      :key="auditingKey"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <Auditing
        @closeDiglog="closeReview"
        @affirmDiglog="confirmReview"
        :title="auditingText"
      />
    </el-dialog>
  </div>
</template>

<script>
import Auditing from "../../../../assembly/auditing.vue";
import http from "@/utils/http";
export default {
  data() {
    return {
      tableData: [], //表格数据
      pages: {}, //分页数据
      auditingText: "同意", //审核文字
      isAuditing: false,
      searchForm: {}, //搜索表单
      auditingKey: Math.random(),
      selectId: null, //所选人的id
    };
  },
  props: ["searchValue"],

  created() {
    this.getPendingList({
      pageNum: 1,
    });
  },

  watch: {
    searchValue: {
      handler(newValue, oldValue) {
        if (newValue) {
          var object = JSON.stringify(newValue);
          object = JSON.parse(object);

          // 判断是否选了日期
          if (newValue.selectDate) {
            object.beginDay = this.formatDays2(object.selectDate[0]);
            object.endDay = this.formatDays2(object.selectDate[1]);
          }

          delete object.selectDate;

          this.searchForm = object;

          this.getPendingList({
            pageNum: 1,
          });
        }
      },
      deep: true,
    },
  },
  components: { Auditing },
  methods: {
    // 获取待审批列表
    getPendingList(objcet) {
      http
        .get(
          "/bmsApply/list",
          Object.assign(
            {
              pageSize: 10,
              type: 4,
              statusList: "0",
            },
            objcet,
            this.searchForm
          )
        )
        .then((res) => {
          this.tableData = res.data.list;
          this.pages = res.data;

          var obj = { type: 5 };
          if (res.data.list.length > 0) {
            var newObj = {
              isHot: true,
            };
            this.$emit("childEvent", Object.assign(obj, newObj));
          } else {
            var newObj = {
              isHot: false,
            };
            this.$emit("childEvent", Object.assign(obj, newObj));
          }
        });
    },
    // 表头背景颜色变色
    getRowClass({ rowIndex, columnIndex }) {
      if (rowIndex == 0) {
        return "background:#F5F6FA;color:#5C5C5C";
      }
    },
    // 相差几个小时
    formatHours(date1String, date2String) {
      // 创建Date对象
      var date1 = new Date(date1String);
      var date2 = new Date(date2String);

      // 计算两个日期之间的毫秒差值
      var timeDifference = Math.abs(date2 - date1);

      // 将毫秒差值转换为小时，并保留一位小数
      var hoursDifference = (timeDifference / (1000 * 60 * 60)).toFixed(1);
      return hoursDifference;
    },

    // 改变页数
    chagePage(page) {
      this.getPendingList({
        pageNum: page,
      });
    },
    // 标准时间转换成天数
    formatDays2(standardTimeString) {
      // 创建一个Date对象
      var date = new Date(standardTimeString);

      // 提取年、月、日
      var year = date.getFullYear();
      var month = ("0" + (date.getMonth() + 1)).slice(-2); // 月份从0开始，所以要加1，并确保两位数格式
      var day = ("0" + date.getDate()).slice(-2); // 确保两位数格式

      // 将年、月、日拼接成所需的格式
      var formattedDate = year + "-" + month + "-" + day;
      return formattedDate;
    },
    // 处理时间
    handelTime(val) {
      if (val) {
        // 将字符串解析为Date对象
        const dateTime = new Date(val);
        const formattedDateTime = `${dateTime.getFullYear()}-${(
          "0" +
          (dateTime.getMonth() + 1)
        ).slice(-2)}-${("0" + dateTime.getDate()).slice(-2)} ${(
          "0" + dateTime.getHours()
        ).slice(-2)}:${("0" + dateTime.getMinutes()).slice(-2)}`;

        return formattedDateTime;
      }
    },
    // 打开审核
    openReviewen(value, id) {
      this.auditingKey = Math.random();
      this.selectId = id;
      if (value == 1) {
        this.auditingText = "同意";
      } else {
        this.auditingText = "驳回";
      }
      setTimeout(() => {
        this.isAuditing = true;
      }, 200);
    },

    // 关闭审核
    closeReview() {
      this.isAuditing = false;
    },
    // 获取年月日
    getDate() {
      // 获取当前时间
      var now = new Date();

      // 获取年、月、日、时、分、秒
      var year = now.getFullYear();
      var month = now.getMonth() + 1; // 月份从0开始，所以要加1
      var day = now.getDate();
      var hour = now.getHours();
      var minute = now.getMinutes();
      var second = now.getSeconds();
      return (
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second
      );
    },
    // 确认审核
    confirmReview(value) {
      var date = this.getDate();
      var status = "";
      if (this.auditingText == "同意") {
        status = 1;
      } else {
        status = 2;
      }
      if (value == "" || !value) {
        value = " ";
      }
      var userinfo = localStorage.getItem("userinfo");
      if (userinfo) {
        userinfo = JSON.parse(userinfo);
      }
      http
        .post("/bmsApply/update", {
          id: this.selectId,
          opinion: value,
          timeExec: date,
          userExec: userinfo.id,
          status: status,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("审核成功");
            this.isAuditing = false;

            this.getPendingList({
              pageNum: 1,
            });
          } else {
            this.$message.error(res.message);
          }
        });
    },
  },
};
</script>

<style scoped>
.wait_all {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.newpagination {
  display: flex;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.wait_table {
  width: 100%;
  flex: 1;
  position: relative;
  box-sizing: border-box;
}
.v_table {
  width: 100%;
  height: 100%;
  position: absolute;
}
.member {
  display: flex;
  align-items: center;
}
.member_head {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 10px;
}
.ellipsis {
  width: 150px;
  white-space: nowrap; /* 防止文本换行 */
  overflow: hidden; /* 隐藏超出容器的文本 */
  text-overflow: ellipsis; /* 显示省略号表示文本被截断 */
}
</style>