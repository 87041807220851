<template>
  <div class="register">
    <div class="head_navi" @click="reback">
      <img src="~@/assets/login/back.png" class="navi_icon" />
      <div>返回登陆</div>
    </div>
    <div class="step_process">
      <div :class="['step', step == 2 || step == 3 ? 'select_step' : '']">
        <div class="yuan" v-if="step == 1"></div>
        <img src="@/assets/ok2.png" class="step_icon" v-else />
        <div>1.验证账号</div>
      </div>
      <div :class="['step', step == 3 ? 'select_step' : '']">
        <div class="yuan" v-if="step == 2 || step == 1"></div>
        <img src="@/assets/ok2.png" class="step_icon" v-else />
        <div>2.填写个人信息</div>
      </div>
      <div :class="['step', step == 3 ? 'select_step' : '']">
        <div class="yuan" v-if="step == 2 || step == 1"></div>
        <img src="@/assets/ok2.png" class="step_icon" v-else />
        <div>3.创建或加入组织</div>
      </div>
    </div>
    <!-- 步骤一 -->
    <div v-if="step == 1">
      <div class="content" v-if="validate == 1">
        <el-input
          v-model="phone"
          maxlength="11"
          placeholder="请输入手机号"
        ></el-input>
        <div class="content_bto">
          <div class="reset" @click="resetPhone">重置</div>
          <div class="next" @click="getCode">下一步</div>
        </div>
      </div>
      <div class="content" v-if="validate == 2">
        <div class="px-6">
          <div class="title">输入手机验证码</div>
          <div class="tips">
            为确认您的手机可用，请点击发送验证码，验证码将发送至 您的手机，<span
              >{{ phone }}</span
            >，有效期为5分钟
          </div>

          <div
            class="ml-2 mt-4 flex px-1 text-center item-center"
            ref="vertifyWrapper"
          >
            <input
              type="text"
              onpaste="return false"
              readonly
              onfocus="this.removeAttribute('readonly')"
              v-for="index in 6"
              :key="index"
              class="w-2/12 login_input"
              @keydown="clearInput"
              @keyup="goVertify"
            />
          </div>
          <div class="mt-8 flex">
            <div v-if="isShowVertifyCode" class="onget" @click="getVertifycode">
              发送验证码
            </div>
            <div
              v-else
              class="text-sm text-jpfont-400 pl-3"
              :class="[{ hidden: isShowVertifyCode }]"
            >
              <span style="color: #0058ff">{{ timersecond }}</span
              >秒后可重新获取验证码
            </div>
          </div>
        </div>
        <div class="next2" @click="verificationCode">下一步</div>
      </div>
    </div>
    <!-- 步骤二 -->
    <div v-if="step == 2" class="content">
      <div class="persion">
        <el-input
          v-model="personalForm.name"
          placeholder="请填写姓名"
        ></el-input>
        <el-input
          type="password"
          v-model="personalForm.password"
          placeholder="请填写至少8位数密码（英文+数字）"
        ></el-input>
        <el-input
          type="password"
          v-model="personalForm.comfin"
          placeholder="确认密码"
        ></el-input>
      </div>
      <div class="content_bto">
        <div class="reset" @click="resetForm">重置</div>
        <div class="next" @click="registNext">下一步</div>
      </div>
    </div>
    <!-- 步骤三 -->
    <div v-if="step == 3">
      <!-- 未加入任何组织 -->
      <div class="nothing" v-if="organizationStep == 1">
        <div class="nothing_tips">
          <span>{{ phone }} </span>还未加入任何组织，您可通过 以下方式快速体验
        </div>
        <div>
          <div class="create" @click="jumpCompany(2)">创建组织</div>
          <div class="join2" @click="jumpCompany(3)">加入组织</div>
          <div @click="skip" class="skip">跳过</div>
        </div>
      </div>
      <!-- 创建组织 -->
      <div class="nothing_create" v-if="organizationStep == 2">
        <div class="persion">
          <el-input
            v-model.trim="companyName"
            oninput="if(value.length>30)value=value.slice(0,30)"
            maxlength="30"
            placeholder="请填写组织名称"
          ></el-input>
        </div>
        <div>
          <div class="content_bto">
            <div class="reset" @click="resetCompany">重置</div>
            <div class="next" @click="registBto">完成</div>
          </div>
          <div class="join2" @click="jumpCompany(3)">加入组织</div>
        </div>
      </div>
      <!-- 加入组织 -->
      <div class="nothing_create" v-if="organizationStep == 3">
        <div class="persion">
          <el-input
            @keyup.enter.native="seachOrganization"
            v-model="personalForm.searchCompanyValue"
            placeholder="请填写完整组织名称"
          >
            <i
              slot="suffix"
              class="el-input__icon el-icon-search"
              @click="seachOrganization"
            ></i>
          </el-input>
        </div>
        <el-table
          :data="tableData"
          style="width: 100%; margin-top: 25px"
          height="12.5rem"
          :header-cell-style="getRowClass"
        >
          <el-table-column prop="name" label="组织名称" width="200">
            <template slot-scope="scope">
              <div class="name">{{ scope.row.name }}</div>
            </template>
          </el-table-column>
          <el-table-column label="组织联系人" width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.umsUser">{{ scope.row.umsUser.name }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="操作">
            <template slot-scope="scope">
              <el-link type="primary" @click="joiny(scope.row.id)"
                >申请加入组织</el-link
              >
            </template>
          </el-table-column>
        </el-table>
        <div>
          <div class="join2" @click="jumpCompany(2)">创建组织</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../../assembly/button.vue";

import http from "../../utils/http";
export default {
  data() {
    return {
      step: 1, //注册步骤
      validate: 1, //1输入手机号，2验证手机号
      phone: "", //手机号
      organizationStep: 1, //1是没有任何组织，2是创建组织，3是加入组织
      isShowVertifyCode: false,
      personalForm: {}, //填写个人信息表单
      companyName: "", //组织名称
      tableData: [], //搜索的组织列表
      inputVertifyCode: [],
      timer: null, //倒计时
      inputMobile: "",
      timersecond: 60,
      actionTicket: "", //注册凭证
    };
  },

  components: { Button },

  watch: {
    form: {
      handler: function (val, oldval) {
        if (val.account && val.code && val.inviteCode) {
          this.firstBto = true;
        } else {
          this.firstBto = false;
        }
      },
      deep: true,
    },
    personForm: {
      handler: function (val, oldval) {
        if (val.name && val.idCard) {
          this.secondBto = true;
        } else {
          this.secondBto = false;
        }
      },
      deep: true,
    },
    enterprise: function (e) {
      if (e) {
        this.thirdBto = true;
      } else {
        this.thirdBto = false;
      }
    },
    passForm: {
      handler: function (val, oldval) {
        if (val.passWord && val.agaginpassword) {
          this.fourBto = true;
        } else {
          this.fourBto = false;
        }
      },
      deep: true,
    },
  },

  methods: {
    // 表头背景颜色变色
    getRowClass({ rowIndex, columnIndex }) {
      if (rowIndex == 0) {
        return "background:#F5F6FA;color:#5C5C5C";
      }
    },
    // 发送验证码
    getVertifycode() {
      this.isShowVertifyCode = false;

      if (this.isCodeDisabled) {
        return;
      }

      this.isCodeDisabled = true;

      this.getCode();
    },
    // 获取验证码
    getCode() {
      // 使用正则表达式验证手机号格式
      const regex = /^1[3-9]\d{9}$/;
      var isValidPhoneNumber = regex.test(this.phone);
      if (!isValidPhoneNumber) {
        this.$message.error("手机号格式不正确");
        return;
      }
      http
        .get("/umsSysUser/code_get", {
          phone: this.phone,
          getCodeType: "REGISTER",
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("发送成功");
            this.validate = 2;
            this.setTimersecond(60);
          } else {
            this.isShowVertifyCode = true;
            this.$message.error(res.message);
          }
        });
    },
    setTimersecond(num) {
      this.timersecond = num;
      this.timer = setInterval(() => {
        this.timersecond--;
        if (this.timersecond === 0) {
          clearInterval(this.timer);
          localStorage.setItem("countdown", 0);
          this.isShowVertifyCode = true;
          this.isCodeDisabled = false;
        }
        localStorage.setItem("countdown", this.timersecond);
      }, 1000);
    },
    // 验证码校验
    verificationCode() {
      http
        .post("/umsSysUser/code_verify", {
          phone: this.phone,
          code: this.inputMobile,
          getCodeType: "REGISTER",
        })
        .then((res) => {
          if (res.code == 200) {
            this.inputVertifyCode = [];
            this.actionTicket = res.data.actionTicket;
            this.step = 2;
          } else {
            this.$message.error(res.message);
          }
        });
    },
    // 重置手机号
    resetPhone() {
      this.phone = "";
    },
    // 重置个人信息表单
    resetForm() {
      this.personalForm = {};
    },
    // 验证个人信息
    registNext() {
      const regex = /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/;
      if (!this.personalForm.name || this.personalForm.name == "") {
        this.$message.error("请输入姓名");
        return;
      }
      if (!this.personalForm.password) {
        this.$message.error("请输入密码");
        return;
      }
      var isValidPassword = regex.test(this.personalForm.password);
      if (!isValidPassword) {
        this.$message.error("密码格式不正确");
        return;
      }
      if (this.personalForm.password != this.personalForm.comfin) {
        this.$message.error("两次密码不一致");
        return;
      }

      this.step = 3;
    },
    clearInput(event) {
      if (event.srcElement.value.toString().length >= 1) {
        event.srcElement.value = "";
      }
    },
    goVertify(event) {
      let vertifyInputArray =
        this.$refs.vertifyWrapper.getElementsByClassName("login_input");

      for (let n1 = 0; n1 < vertifyInputArray.length; n1++) {
        if (event.srcElement === vertifyInputArray[n1]) {
          this.inputVertifyCode[n1] = vertifyInputArray[n1].value;

          if (n1 === vertifyInputArray.length - 1) {
            this.isInput = true;
            this.inputMobile = this.inputVertifyCode.join("");
          } else {
            this.isInput = false;
            if (vertifyInputArray[n1].value !== "") {
              vertifyInputArray[n1 + 1].focus();
            }
          }
        }
      }
    },
    // 组织操作
    // 跳转步骤
    jumpCompany(index) {
      this.organizationStep = index;
    },
    // 搜索组织
    seachOrganization() {
      if (!this.personalForm.searchCompanyValue) {
        this.$message.error("请先输入关键词");
        return;
      }
      http
        .get("/umsCompany/get/by/name", {
          name: this.personalForm.searchCompanyValue,
        })
        .then((res) => {
          if (res.code == 200) {
            if (res.data) {
              this.$set(this.tableData, 0, res.data);
            } else {
              this.tableData = [];
            }
          } else {
            this.$message.error(res.message);
          }
        });
    },
    // 重置创建组织
    resetCompany() {
      this.companyName = "";
    },
    // 加入公司
    joiny(id) {
      http
        .post("/umsSysUser/register", {
          name: this.personalForm.name,
          password: this.personalForm.password,
          company: id,
          ticket: this.actionTicket,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("申请成功,请等待管理员审核");
          } else {
            this.$message.error(res.message);
          }
        });
    },
    // 注册操作
    registBto() {
      if (!this.companyName) {
        this.$message.error("请先输入要创建的组织名称");
        return;
      }
      http
        .post("/umsSysUser/register", {
          name: this.personalForm.name,
          password: this.personalForm.password,
          companyName: this.companyName,
          ticket: this.actionTicket,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("注册成功");
            this.reback();
          } else {
            this.$message.error(res.message);
          }
        });
    },
    // 跳过按钮
    skip() {
      var that = this;
      http
        .post("/umsSysUser/register", {
          password: that.personalForm.password,
          ticket: that.actionTicket,
        })
        .then((res) => {
          if (res.code == 200) {
            that.$message.success("注册成功");
            http
              .post("/umsSysUser/login", {
                phone: that.phone,
                password: that.personalForm.password,
                platformType: "PC",
              })
              .then((res2) => {
                if (res2.code == 200) {
                  localStorage.setItem("token", res2.data.token);
                  this.$router.replace("/choosecompany");
                }
              });
          } else {
            this.$message.error(res.message);
          }
        });
    },

    // 返回上一层
    reback() {
      this.$emit("stopRegister");
    },
  },
};
</script>

<style scoped>
.register {
  padding: 21px 30px 0 30px;
  box-sizing: border-box;
}
.head_navi {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #909090;
}
.navi_icon {
  width: 15px;
  height: 13px;
  margin-right: 9px;
}
.step_process {
  display: flex;
  padding-left: 20px;
  box-sizing: border-box;
  align-items: center;
  margin: 30px 0 34px;
}
.step {
  display: flex;
  align-items: center;
  width: 160px;
  height: 35px;
  font-size: 14px;
  color: #161616;
  border-top: 2px solid #e0e0e0;
}
.yuan {
  width: 14px;
  height: 14px;
  border: 1px solid #161616;
  border-radius: 50%;
  margin-right: 5px;
}
.content {
  display: flex;
  width: 100%;
  padding-left: 20px;
  box-sizing: border-box;
  height: 384px;
  flex-direction: column;
  justify-content: space-between;
}
::v-deep .content .el-input {
  width: 450px;
  height: 48px;
}
::v-deep .content .el-input__inner {
  width: 450px;
  height: 48px;
}
.content_bto {
  display: flex;
  align-items: center;
  justify-content: center;
}
.reset {
  width: 160px;
  height: 48px;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #d5d7e3;
  font-size: 15px;
  color: #7e84a3;
  text-align: center;
  line-height: 48px;
  margin-right: 30px;
  cursor: pointer;
}
.next {
  width: 160px;
  height: 48px;
  background: #0058ff;
  border-radius: 4px 4px 4px 4px;
  font-size: 15px;
  color: white;
  line-height: 48px;
  text-align: center;
  cursor: pointer;
}
.px-6 {
  box-sizing: border-box;
}
.title {
  font-size: 24px;
  color: #1a1a1a;
}
.vertifycodeIcon {
  color: #e2041b !important;
  width: 2.5rem !important;
  height: 2.5rem !important;
}
.tips {
  color: rgba(26, 26, 26, 0.6);
  margin: 6px 0 20px;
  width: 364px;
}
.tips span {
  color: #0058ff;
}
.login_input {
  text-align: center;
  line-height: 40px;
  height: 40px;
  width: 40px;
  color: #262626;
  font-size: 24px;
  border-radius: 2px;
  box-sizing: border-box;
  line-height: 1;
  color: #0058ff;
  border: solid 1px #d0d0d0;
  outline: none;
  margin-right: 8px;
  background-color: white;
}
.login_input:focus {
  border: solid 1px #0058ff;
}

.InfoWrapper {
  position: absolute;
  flex-direction: column;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 50;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.mainWrapper {
  margin: 0px 50px 0px 50px;
  padding: 18px 16px 16px 16px;
  text-align: center;
  background-color: #1a202c;
  opacity: 0.9;
  color: #fff;
  font-size: 14px;
  border-radius: 0.75rem;
}
.infoIcon {
  color: #fff !important;
  width: 2.5rem !important;
  height: 2.5rem !important;
}
.onget {
  font-size: 12px;
  cursor: pointer;
  color: #0058ff;
}
.mt-8 {
  margin-top: 10px;
}
.text-sm {
  font-size: 12px;
  color: #909090;
}
.next2 {
  width: 419px;
  height: 48px;
  background: #0058ff;
  border-radius: 4px 4px 4px 4px;
  color: white;
  line-height: 48px;
  text-align: center;
  font-size: 16px;
  margin: 0 auto;
  cursor: pointer;
}
.select_step {
  border-color: #0058ff;
  color: #0058ff;
}
.step_icon {
  width: 16px;
  height: 16px;
}
::v-deep .persion .el-input {
  width: 450px;
  height: 48px;
  margin-bottom: 20px;
}
::v-deep .persion .el-input__inner {
  width: 450px;
  height: 48px;
}
.nothing_tips {
  width: 348px;
  text-align: center;
  font-size: 18px;
  color: #1a1a1a;
  margin: 0 auto;
  padding-top: 50px;
  box-sizing: border-box;
}
.nothing_tips span {
  color: #0058ff;
}
.nothing {
  height: 384px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.nothing_create {
  height: 384px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 20px;
  box-sizing: border-box;
}
.create {
  width: 419px;
  height: 48px;
  background: #0058ff;
  border-radius: 4px 4px 4px 4px;
  font-size: 16px;
  color: white;
  text-align: center;
  line-height: 48px;
  margin: 0 auto;
  cursor: pointer;
}
.join2 {
  width: 419px;
  height: 48px;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #0058ff;
  font-size: 16px;
  color: #0058ff;
  line-height: 48px;
  margin: 20px auto 0;
  text-align: center;
  cursor: pointer;
}
.name {
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.el-icon-search {
  cursor: pointer;
  font-size: 16px;
}
.skip{
  margin-top: 20px;
  text-align: center;
  font-size: 16px;
  color: gray;
  cursor: pointer;
}
</style>
