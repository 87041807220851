<template>
  <el-dialog
    title=""
    :visible.sync="isShow"
    width="33%"
    :show-close="false"
    :close-on-click-modal="false"
  >
    <div class="diglog_h">
      <div>{{ title }}</div>
      <img
        src="@/assets/structure/close.png"
        class="diglog_icon"
        @click="closeDiglog"
      />
    </div>
    <div class="delectdiglog">
      <div class="delectdiglog_title">{{ tips }}</div>
    </div>

    <div class="diglog_operation">
      <el-button @click="closeDiglog">取消</el-button>
      <el-button type="primary" @click="affirmDiglog">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: ["title", "tips", "isShow"],
  methods: {
    // 关闭弹窗
    closeDiglog() {
      this.$emit("closeDiglog");
    },
    // 确认弹窗
    affirmDiglog(){
      this.$emit("affirmDiglog");
    }
  },
};
</script>

<style scoped>
.delectdiglog {
  width: 100%;
  margin-top: 20px;
  border-top: 1px solid #cfd4e8;
}
.delectdiglog_title {
  font-size: 16px;
  color: #1a1a1a;
  padding: 30px;
  box-sizing: border-box;
}
.diglog_h {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  padding: 24px 24px 0 24px;
  color: #1a1a1a;
}
.diglog_icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.diglog_operation {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
