<template>
  <div class="alterationdept">
    <div class="alterationdept_h">
      <div>变更部门</div>
      <img
        src="@/assets/structure/close.png"
        class="alterationdept_icon"
        @click="close"
      />
    </div>
    <div class="alterationdept_content">
      <div class="alterationdept_search">
        <el-input
          v-model="searchValue"
          placeholder="搜索部门名称"
          @keyup.enter.native="searchResult"
        ></el-input>
        <img
          src="@/assets/structure/delete.png"
          class="searchicon"
          @click="clearup"
          v-if="delectIcon"
        />
        <img
          src="@/assets/structure/search.png"
          class="searchicon"
          @click="searchResult"
        />
      </div>
      <div class="alterationdept_search_n">
        <el-tree
          v-if="result.length == 0"
          :key="treeKey"
          :data="departmentList"
          :default-expand-all="false"
          :props="defaultProps"
          @node-click="handleNodeClick"
        >
          <span class="custom-tree-node" slot-scope="{ node }">
            <div class="tree_sinle">
              <div class="tree_sinle_l">
                <img
                  src="@/assets/structure/plan.png"
                  class="tree_sinle_icon"
                />
                <div>{{ node.label }}</div>
              </div>
            </div>
          </span>
        </el-tree>
        <div v-else>
          <div class="result" v-for="item in result" :key="item.id">
            <div
              :class="[
                selectDept == item.id ? 'select_result_sinle_l' : '',
                'result_sinle_l',
              ]"
              @click="selectDeptid(item.id)"
            >
              <img src="@/assets/structure/plan.png" class="tree_sinle_icon" />
              <div>{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="alterationdept_bto">
        <el-button style="margin-right: 30px" @click="close">取消</el-button>
        <el-button type="primary" @click="comfinAlteration">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../../../../utils/http";
export default {
  data() {
    return {
      searchValue: "", //搜索内容
      departmentList: [], //部门列表
      selectDept: null, //选中的部门id
      result: [], //搜索结果
      delectIcon: false, //删除图标
      treeKey: Date.now(),
      defaultProps: {
        children: "subDepartment",
        label: "name",
      },
    };
  },

  created() {
    // 获取部门列表
    this.getDeptment(); 
  },

  props: ["ids"],

  watch: {
    searchValue(newName, oldName) {
      if (newName == "") {
        this.result = [];
        this.selectDept = null;
        this.delectIcon = false;
      } else {
        this.delectIcon = true;
      }
    },
  },

  methods: {
    // 关闭弹窗
    close() {
      this.$emit("close");
    },

    // 获取部门列表
    getDeptment() {
      http.get("/umsDepartment/list").then((res) => {
        if (res.code == 200) {
          // this.companyName = res.data.departmentName;
          // this.ischangec = res.data.departmentName;

          if (res.data) {
            this.departmentList = this.convertToNestedStructure(res.data);
          }
        }
      });
    },

    convertToNestedStructure(arr) {
      // 创建一个映射，用于快速查找具有特定id的对象
      const idMap = new Map(arr.map((item) => [item.id, item]));

      // 创建一个新数组来存储转换后的嵌套结构
      const nestedArr = [];

      // 遍历原始数组
      arr.forEach((item) => {
        // 如果该项有父元素，则将其添加到父元素的subDepartment数组中
        if (item.parent) {
          const parent = idMap.get(item.parent);
        if (parent) {
            if (!parent.subDepartment) {
              parent.subDepartment = [];
            }
            item.parentDepament = parent.name;
            parent.subDepartment.push(item);
          }
        } else {
          // 如果没有父元素，则添加到新数组的根级别
          nestedArr.push(item);
        }
      });

      // 返回转换后的嵌套数组
      return nestedArr;
    },
    // 点击选中的树状
    handleNodeClick(value) {
      this.selectDept = value.id;
    },

    // 搜索结果点击部门
    selectDeptid(id) {
      this.selectDept = id;
    },

    // 搜索结果
    searchResult() {
      this.selectDept = null;
      if (this.searchValue == "") {
        this.result = [];
        this.selectDept = null;
      } else {
        http
          .get("/umsDepartment/search", {
            departmentName: this.searchValue,
          })
          .then((res) => {
            if (res.code == 200) {
              this.result = res.data;
            }
          });
      }
    },

    // 清除输入内容
    clearup() {
      this.searchValue = "";

      this.result = [];
      this.selectDept = null;
    },

    // 确认变更部门
    comfinAlteration() {
      var arr = JSON.parse(this.ids);
      var arr2 = [];

      arr.map((item) => {
        arr2.push(item.id);
      });

      if (!this.selectDept) {
        this.$message.error("请先选择要变更的部门");
        return;
      }

      for (let i = 0; i < arr.length; i++) {
        if (arr[i].jobDepartment === this.selectDept) {
          // 使用 return 结束循环和函数
          this.$message.error("原部门不能和新部门一致");
          return;
        }
      }

      http
        .post("/umsUser/update", {
          ids: arr2,
          jobDepartment: this.selectDept,
          userUpdateType: "CHANGE_DEPARTMENT",
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("操作成功");
            this.$emit("comfin");
          } else {
            this.$message.error(res.message);
          }
        });
    },
  },
};
</script>

<style scoped>
.alterationdept_h {
  height: 73px;
  padding: 0 24px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 18px;
  justify-content: space-between;
  border-bottom: 1px solid #cfd4e8;
}
.alterationdept_icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.alterationdept_content {
  width: 100%;
  padding: 24px 24px 0 24px;
  box-sizing: border-box;
}
.alterationdept_search {
  width: 100%;
  height: 36px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #d0d0d0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 13px;
  box-sizing: border-box;
}
.alterationdept_s_icon {
  cursor: pointer;
}
.alterationdept_search_n {
  width: 100%;
  height: 394px;
  margin-top: 24px;
  background: #f5f6fa;
  overflow-y: scroll;
}
.alterationdept_search_n::-webkit-scrollbar {
  display: none;
}
.tree_sinle {
  width: 100%;
  display: flex;
  align-items: center;
  padding-right: 12px;
  box-sizing: border-box;
  justify-content: space-between;
}
.tree_sinle_l {
  display: flex;
  font-size: 16px;
  color: #5c5c5c;
  align-items: center;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
.tree_sinle_icon {
  width: 18px;
  height: 18px;
  margin-right: 12px;
}
::v-deep .el-tree {
  background: #f5f6fa;
}
::v-deep .el-tree-node:focus > .el-tree-node__content {
  background: #edeeee;
}
.result_sinle_l {
  cursor: pointer;
  padding-left: 20px;
  box-sizing: border-box;
  height: 48px;
  align-items: center;
  display: flex;
}
.select_result_sinle_l {
  background: #edeeee;
}
.alterationdept_bto {
  display: flex;
  align-items: center;
  margin-top: 60px;
  justify-content: center;
}
::v-deep .alterationdept_bto .el-button {
  width: 100px;
}
.alterationdept_search:focus-within {
  border-color: #0096fa;
}
</style>
