<template>
  <div class="login">
    <!-- 登陆 -->
    <div class="login_frame" v-if="process == 1">
      <div class="login_left">
        <div class="title">您正以管理员身份登录</div>
        <div class="tips">选择管理的企业</div>
        <div class="company_ul">
          <div
            v-for="item in companyList"
            @click="changeCompany(item.id)"
            :key="item.id"
            :class="[
              'company_li',
              selectIndex == item.id ? 'company_li_select' : '',
            ]"
          >
            <div v-if="item.umsCompany">{{ item.umsCompany.name }}</div>
            <img
              src="@/assets/ok.png"
              class="select_icon"
              v-if="selectIndex == item.id"
            />
          </div>
        </div>
        <div class="bto" @click="login">下一步</div>
        <div class="other_bto">
          <div @click="jumpProcess(2)">创建组织</div>
          <div @click="jumpProcess(3)">加入组织</div>
        </div>
      </div>
      <img src="~@/assets/login/loginlogo.png" class="loginlogo" />
    </div>
    <!-- 创建组织 -->
    <div class="establish" v-if="process == 2">
      <div class="establish_back" @click="jumpProcess(1)">
        <img src="@/assets/login/back.png" class="establish_back_icon" />
        <div>返回登陆</div>
      </div>
      <div class="establish_content">
        <div class="establish_input">
          <el-input
            v-model="establishForm.name"
            placeholder="请填写姓名"
          ></el-input>
          <el-input
            v-model="establishForm.companyName"
            oninput="if(value.length>30)value=value.slice(0,30)"
            maxlength="30"
            placeholder="请填写组织名称"
          ></el-input>
        </div>
        <div>
          <div class="comfin_bto">
            <div class="reset" @click="resetCreate">重置</div>
            <div class="comfin" @click="comfimCreate">完成</div>
          </div>
          <div class="join" @click="jumpProcess(3)">加入组织</div>
        </div>
      </div>
    </div>
    <!-- 加入组织 -->
    <div class="establish" v-if="process == 3">
      <div class="establish_back" @click="jumpProcess(1)">
        <img src="@/assets/login/back.png" class="establish_back_icon" />
        <div>返回登陆</div>
      </div>
      <div class="establish_content">
        <div class="establish_input">
          <el-input v-model="joinForm.name" placeholder="请填写姓名"></el-input>
          <el-input
            @keyup.enter.native="seachOrganization"
            v-model="joinForm.searchCompanyValue"
            placeholder="请填写完整组织名称"
          >
            <i
              slot="suffix"
              class="el-input__icon el-icon-search"
              @click="seachOrganization"
            ></i>
          </el-input>
        </div>
        <el-table
          :data="tableData"
          style="width: 100%; margin-top: 25px"
          height="12.5rem"
          :header-cell-style="getRowClass"
        >
          <el-table-column prop="name" label="组织名称" width="150">
            <template slot-scope="scope">
              <div class="name">
                {{ scope.row.name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="组织联系人" width="150">
            <template slot-scope="scope">
              <div class="ellipsis3" v-if="scope.row.umsUser">
                {{ scope.row.umsUser.name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="操作" fixed="right">
            <template slot-scope="scope">
              <el-link type="primary" @click="joinOrganization(scope.row.id)"
                >申请加入组织</el-link
              >
            </template>
          </el-table-column>
        </el-table>
        <div>
          <div class="join" @click="jumpProcess(2)">创建组织</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../../utils/http";
export default {
  data() {
    return {
      selectIndex: 1,
      companyList: [],
      process: 1, //1是选择企业，2是创建，3是加入
      establishForm: {}, //创建企业表单
      joinForm: {}, //加入组织企业表单
      tableData: [], //搜索的组织列表
    };
  },

  created() {
    // 获取信息
    this.getInfo();
  },

  methods: {
    // 获取个人信息
    getInfo() {
      http.get("/umsSysUser/get").then((res) => {
        if (res.code == 200) {
          var arr = [];
          res.data.umsUserGetVOList.map((item) => {
            if (item.status == 3 || item.status == 4) {
              arr.push(item);
            }
          });

          if (arr.length > 0) {
            this.companyList = arr;
            this.selectIndex = this.companyList[0].id;
            this.process = 1;
          }
        }
      });
    },
    // 表头背景颜色变色
    getRowClass({ rowIndex, columnIndex }) {
      if (rowIndex == 0) {
        return "background:#F5F6FA;color:#5C5C5C";
      }
    },

    // 选择企业
    changeCompany(id) {
      this.selectIndex = id;
    },

    // 登陆
    login() {
      var value = this.companyList.find((item) => item.id == this.selectIndex);
      http
        .post("/umsSysUser/switch_company", {
          company: value.company,
        })
        .then((res) => {
          if (res.code == 200) {
            localStorage.setItem("company", JSON.stringify(value.umsCompany));
            this.$router.replace({
              path: "/comment",
            });
          }
        });
    },

    // 跳转步骤
    jumpProcess(index) {
      this.establishForm = {};
      this.joinForm = {};
      this.process = index;
    },

    // 重置创建企业
    resetCreate() {
      this.establishForm = {};
    },

    // 确认创建企业
    comfimCreate() {
      if (!this.establishForm.name) {
        this.$message.error("请输入姓名");
        return;
      }
      if (!this.establishForm.companyName) {
        this.$message.error("请输入组织名称");
        return;
      }

      http
        .post("/umsSysUser/create_company", this.establishForm)
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("创建成功");
            this.establishForm = {};
            this.getInfo();
          } else {
            this.$message.error(res.message);
          }
        });
    },
    // 搜索组织
    seachOrganization() {
      if (!this.joinForm.searchCompanyValue) {
        this.$message.error("请先输入关键词");
        return;
      }
      http
        .get("/umsCompany/get/by/name", {
          name: this.joinForm.searchCompanyValue,
        })
        .then((res) => {
          if (res.code == 200) {
            if (res.data) {
              this.$set(this.tableData, 0, res.data);
            } else {
              this.tableData = [];
            }
          } else {
            this.$message.error(res.message);
          }
        });
    },
    // 加入组织
    joinOrganization(id) {
      if (!this.joinForm.name) {
        this.$message.error("请先输入姓名");
        return;
      }
      http
        .post("/umsSysUser/join_company", {
          company: id,
          name: this.joinForm.name,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("申请成功,请等待管理员审核");
          } else {
            this.$message.error(res.message);
          }
        });
    },
  },
};
</script>

<style scoped>
.login {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("@/assets/login/background.png");
  background-size: 100% 100%;
}
.login_frame {
  width: 1184px;
  height: 597px;
  background: white;
  box-shadow: 0px 0px 30px 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
}
.login_left {
  width: 592px;
  height: 100%;
  padding: 74px 87px 0 87px;
  box-sizing: border-box;
}
.title {
  font-size: 32px;
  font-weight: bold;
  color: #1a1a1a;
}
.loginlogo {
  width: 592px;
  height: 100%;
}
.tips {
  font-size: 18px;
  color: rgba(26, 26, 26, 0.6);
  margin: 58px 0 26px;
}
.company_li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px 0 20px;
  box-sizing: border-box;
  font-size: 16px;
  color: #1a1a1a;
  width: 99%;
  height: 48px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #d0d0d0;
  margin-bottom: 20px;
  cursor: pointer;
}
.company_li_select {
  border-color: #0058ff;
}
.select_icon {
  width: 20px;
  height: 20px;
}
.company_ul {
  height: 200px;
  overflow-x: scroll;
}
.company_ul::-webkit-scrollbar {
  width: 0;
}
.bto {
  width: 419px;
  height: 48px;
  background: #0058ff;
  border-radius: 4px 4px 4px 4px;
  font-size: 16px;
  color: white;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
}
.other_bto {
  width: 100%;
  margin-top: 16px;
  font-size: 14px;
  color: #909090;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.other_bto div {
  cursor: pointer;
}
.establish {
  width: 580px;
  height: 597px;
  background: #ffffff;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);
  border-radius: 10px 10px 10px 10px;
}
.establish_back {
  font-size: 14px;
  color: #333333;
  display: flex;
  align-items: center;
  padding: 24px 30px;
  cursor: pointer;
  box-sizing: border-box;
}
.establish_back_icon {
  width: 14px;
  height: 13px;
  margin-right: 9px;
}
.establish_content {
  width: 450px;
  margin: 39px auto 0;
}
::v-deep .establish_input .el-input {
  height: 48px;
  margin-bottom: 20px;
}
::v-deep .establish_input .el-input__inner {
  height: 48px;
}
.establish_content {
  display: flex;
  height: 440px;
  flex-direction: column;
  justify-content: space-between;
}
.join {
  width: 100%;
  height: 48px;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #0058ff;
  font-size: 16px;
  color: #0058ff;
  text-align: center;
  line-height: 48px;
  margin-top: 40px;
  cursor: pointer;
}
.comfin_bto {
  display: flex;
  align-items: center;
  justify-content: center;
}
.reset {
  width: 160px;
  height: 48px;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #d5d7e3;
  font-size: 15px;
  color: #7e84a3;
  line-height: 48px;
  text-align: center;
  margin-right: 30px;
  cursor: pointer;
}
.comfin {
  width: 160px;
  height: 48px;
  background: #0058ff;
  border-radius: 4px 4px 4px 4px;
  color: white;
  font-size: 15px;
  line-height: 48px;
  text-align: center;
  cursor: pointer;
}
.name {
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.el-icon-search {
  cursor: pointer;
  font-size: 16px;
}
</style>