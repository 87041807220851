<template>
  <div class="wait">
    <div class="wait_header">
      <el-radio-group v-model="tabPosition" @change="switchTab">
        <el-radio-button
          :label="item.id"
          v-for="item in tabList"
          :key="item.id"
          >{{ item.name }}</el-radio-button
        >
      </el-radio-group>
      <div class="wait_search">
        <el-date-picker
          @change="selectDate"
          type="daterange"
          v-model="searchForm.selectDate"
          range-separator="至"
          start-placeholder="申请开始时间"
          end-placeholder="申请结束时间"
        >
        </el-date-picker>
        <div class="visitor_information_search">
          <el-input
            class="department_search_inp"
            v-model="searchForm.likeString"
            @keyup.enter.native="seachResult"
            placeholder="姓名、员工工号"
          ></el-input>
          <img
            src="@/assets/structure/delete.png"
            class="searchicon"
            @click="clearup"
            v-if="searchicon"
          />
          <img
            src="@/assets/structure/search.png"
            class="searchicon_t"
            @click="seachResult"
          />
        </div>
        <el-select
          clearable
          @change="clearDept"
          ref="stlectTree"
          v-model="selectTree"
          placeholder="部门"
          :popper-append-to-body="false"
          class="select-tree"
        >
          <el-option :value="selectTreeValue" style="height: auto">
            <!-- option展开高度太小，把height设置为auto就好啦 -->
            <el-tree
              :data="departmentList"
              node-key="departmentId"
              ref="tree"
              accordion
              highlight-current
              :props="defaultProps"
              @node-click="nodeClick"
            ></el-tree>
          </el-option>
        </el-select>
        <el-select
          clearable
          v-model="searchForm.statusList"
          placeholder="审核状态"
          @change="seachResult"
        >
          <el-option
            v-for="item in reviewList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <div class="export_table" @click="startDownload">导出报表</div>
      </div>
    </div>
    <div class="wait_complete">
      <!-- 加班审批 -->
      <Overtime v-if="tabPosition == 1" :searchValue="searchValueFinan" />
      <!-- 补签审批 -->
      <Supplementary v-if="tabPosition == 2" :searchValue="searchValueFinan" />
      <!-- 请假审批 -->
      <Leave v-if="tabPosition == 3" :searchValue="searchValueFinan" />
      <!-- 出差审批 -->
      <Business v-if="tabPosition == 4" :searchValue="searchValueFinan" />
      <!-- 外出审批 -->
      <Goout v-if="tabPosition == 5" :searchValue="searchValueFinan" />
    </div>
  </div>
</template>

<script>
import Overtime from "./complete/overtime.vue";
import Supplementary from "./complete/supplementary.vue";
import Leave from "./complete/leave.vue";
import Business from "./complete/business.vue";
import Goout from "./complete/goout.vue";
import http from "../../../utils/http";
import exprotExcel from "../../../utils/exprotExcel";
export default {
  data() {
    return {
      tabPosition: 1, //已审批审批序号
      searchForm: {}, //搜索表单
      searchValueFinan: {
        statusList: "1,2",
      }, //最终搜索的值
      searchicon: false,
      //   部门选择
      selectTree: "",
      treeKey: 0,
      selectTreeValue: "",
      departmentList: [], //部门列表
      defaultProps: {
        children: "subDepartment",
        label: "name",
      },
      tableData: [], //表格数据
      pages: {}, //分页数据
      reviewList: [
        {
          label: "同意",
          value: 1,
        },
        {
          label: "驳回",
          value: 2,
        },
      ],
      leaveList: [
        {
          label: "事假",
          value: 0,
        },
        {
          label: "年假",
          value: 1,
        },
        {
          label: "婚假",
          value: 2,
        },
        {
          label: "产休假",
          value: 3,
        },
        {
          label: "病假",
          value: 4,
        },
        {
          label: "调休",
          value: 5,
        },
        {
          label: "其他",
          value: 6,
        },
      ],
      tabList: [
        {
          name: "加班审批",
          id: 1,
        },
        {
          name: "补卡审批",
          id: 2,
        },
        {
          name: "请假审批",
          id: 3,
        },
        {
          name: "出差审批",
          id: 4,
        },
        {
          name: "外出审批",
          id: 5,
        },
      ],
      tableColumn: [
        {
          title: "姓名",
          dataIndex: "name",
          id: 1,
        },
        {
          title: "员工工号",
          dataIndex: "jobNumber",
          id: 2,
        },
        {
          title: "部门",
          dataIndex: "umsDepartment",
          id: 3,
        },
        {
          title: "补卡类型",
          dataIndex: "replacementType",
          id: 5,
        },
        {
          title: "补卡时间",
          dataIndex: "replacementTime",
          id: 6,
        },
        {
          title: "请假类型",
          dataIndex: "leaveType",
          id: 7,
        },
        {
          title: "外出地址",
          dataIndex: "address",
          id: 8,
        },
        {
          title: "加班原因",
          dataIndex: "description",
          id: 9,
        },
        {
          title: "加班开始时间",
          dataIndex: "timeBeginText",
          id: 10,
        },
        {
          title: "加班结束时间",
          dataIndex: "timeEndText",
          id: 11,
        },
        {
          title: "加班时长(小时)",
          dataIndex: "difference",
          id: 12,
        },
        {
          title: "提交时间",
          dataIndex: "createTime",
          id: 13,
        },
        {
          title: "审批人",
          dataIndex: "userExecText",
          id: 14,
        },
        {
          title: "审批时间",
          dataIndex: "timeExec",
          id: 15,
        },
        {
          title: "审批意见",
          dataIndex: "opinion",
          id: 16,
        },
        {
          title: "审批状态",
          dataIndex: "statusText",
          id: 17,
        },
      ], //导出内容参数
    };
  },
  created() {
    // 获取部门列表
    this.getDeptment();
  },

  watch: {
    "searchForm.likeString": {
      immediate: true,
      deep: true,
      handler(newV) {
        if (newV) {
          this.searchicon = true;
        } else {
          this.searchicon = false;
        }
      },
    },
  },

  components: { Overtime, Supplementary, Leave, Business, Goout },
  methods: {
    // 表头背景颜色变色
    getRowClass({ rowIndex, columnIndex }) {
      if (rowIndex == 0) {
        return "background:#F5F6FA;color:#5C5C5C";
      }
    },
    // 切换tab
    switchTab(value) {
      this.searchForm = {};
      this.selectTree = "";
      this.searchValueFinan = {
        statusList: "1,2",
      };
      this.tabPosition = value;
    },
    // 获取部门列表
    getDeptment() {
      http.get("/umsDepartment/list").then((res) => {
        if (res.code == 200) {
          // this.companyName = res.data.departmentName;
          // this.ischangec = res.data.departmentName;

          if (res.data.length > 0) {
            this.departmentList = this.convertToNestedStructure(res.data);
          }
        }
      });
    },

    // 递归处理数据
    convertToNestedStructure(arr) {
      // 创建一个映射，用于快速查找具有特定id的对象
      const idMap = new Map(arr.map((item) => [item.id, item]));

      // 创建一个新数组来存储转换后的嵌套结构
      const nestedArr = [];

      // 遍历原始数组
      arr.forEach((item) => {
        // 如果该项有父元素，则将其添加到父元素的subDepartment数组中
        if (item.parent) {
          const parent = idMap.get(item.parent);
        if (parent) {
            if (!parent.subDepartment) {
              parent.subDepartment = [];
            }
            item.parentDepament = parent.name;
            parent.subDepartment.push(item);
          }
        } else {
          // 如果没有父元素，则添加到新数组的根级别
          nestedArr.push(item);
        }
      });

      // 返回转换后的嵌套数组
      return nestedArr;
    },

    // 搜索
    seachResult() {
      var object = JSON.stringify(this.searchForm);
      object = JSON.parse(object);
      if (!object.statusList) {
        object.statusList = "1,2";
      }
      this.searchValueFinan = object;
    },

    // 清除输入框
    clearup() {
      this.searchForm.likeString = "";
      var object = JSON.stringify(this.searchForm);
      object = JSON.parse(object);
      if (!object.statusList) {
        object.statusList = "1,2";
      }
      this.searchValueFinan = object;
    },

    // 改变日期
    selectDate(value) {
      var object = JSON.stringify(this.searchForm);
      this.searchValueFinan = JSON.parse(object);
    },
    // 部门选择
    nodeClick(item) {
      const { id, name } = item;
      this.selectTree = name;
      this.selectTreeValue = id;
      this.$refs.stlectTree.blur();

      this.searchForm.department = this.selectTreeValue;
      var object = JSON.stringify(this.searchForm);
      this.searchValueFinan = JSON.parse(object);
    },

    // 表格下载之前
    startDownload() {
      let arr2 = [];
      var title = ""; //标题
      var type = null; //接口名称
      // 判断是那个
      var object = JSON.stringify(this.tableColumn);
      object = JSON.parse(object);

      // 处理最终搜索的表格
      var object2 = JSON.stringify(this.searchValueFinan);
      object2 = JSON.parse(object2);

      if (object2) {
        // 判断是否有日期
        if (object2.selectDate) {
          object2.beginDay = this.formatDate(object2.selectDate[0]);
          object2.endDay = this.formatDate(object2.selectDate[1]);
        }

        delete object2.selectDate;
      }

      switch (this.tabPosition) {
        case 1:
          title = "加班";
          type = 0;
          var filteredArr = object.filter(function (item) {
            return (
              item.title !== "补卡类型" &&
              item.title !== "补卡时间" &&
              item.title !== "请假类型" &&
              item.title !== "外出地址"
            );
          });
          object = filteredArr;
          break;
        case 2:
          type = 1;
          title = "补卡";
          var filteredArr = object.filter(function (item) {
            return (
              item.title !== "请假类型" &&
              item.title !== "加班开始时间" &&
              item.title !== "加班结束时间" &&
              item.title !== "加班时长(小时)" &&
              item.title !== "外出地址"
            );
          });
          object = filteredArr;
          break;
        case 3:
          type = 2;
          title = "请假";
          var filteredArr = object.filter(function (item) {
            return (
              item.title !== "补卡类型" &&
              item.title !== "补卡时间" &&
              item.title !== "外出地址"
            );
          });
          object = filteredArr;
          break;
        case 4:
          type = 3;
          title = "出差";
          var filteredArr = object.filter(function (item) {
            return (
              item.title !== "补卡类型" &&
              item.title !== "补卡时间" &&
              item.title !== "请假类型"
            );
          });
          object = filteredArr;
          break;
        case 5:
          type = 4;
          title = "外出";
          var filteredArr = object.filter(function (item) {
            return (
              item.title !== "请假类型" &&
              item.title !== "补卡类型" &&
              item.title !== "补卡时间"
            );
          });
          object = filteredArr;
          break;
      }

      for (let key of object) {
        if (key.dataIndex == "description") {
          if (this.tabPosition == 1) {
            key.title = title + "原因";
          } else {
            key.title = title + "事由";
          }
        }
        if (key.dataIndex == "timeBeginText") {
          key.title = title + "开始时间";
        }
        if (key.dataIndex == "address") {
          if (this.tabPosition == 4) {
            key.title = "出差地址";
          }
          if (this.tabPosition == 5) {
            key.title = "外出地址";
          }
        }
        if (key.dataIndex == "timeEndText") {
          key.title = title + "结束时间";
        }
        if (key.dataIndex == "difference") {
          if (this.tabPosition == 3 || this.tabPosition == 4) {
            key.title = title + "时长(天)";
          } else {
            key.title = title + "时长(小时)";
          }
        }
        arr2.push(key.dataIndex);
      }

      const loading = this.$loading({
        lock: true,
        text: "导出表格中，请稍后...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      http
        .get(
          "/bmsApply/list",
          Object.assign(
            {
              pageNum: 1,
              type: type,
              pageSize: 99999,
            },
            object2
          )
        )
        .then((res) => {
          loading.close();
          if (res.code == 200) {
            if (res.data.list.length > 0) {
              res.data.list.map((v) => {
                if (v.umsUser) {
                  v.name = v.umsUser.name;
                  v.jobNumber = v.umsUser.jobNumber;
                  v.job = v.umsUser.job;
                }
                // 出差审批比较特殊
                if (v.timeBegin && v.timeEnd) {
                  if (this.tabPosition == 3 || this.tabPosition == 4) {
                    v.difference = this.formatDays2(v.description);
                  } else {
                    v.difference = this.formatHours(v.timeBegin, v.timeEnd);
                  }
                }
                if (this.tabPosition == 3 || this.tabPosition == 4) {
                  v.timeBeginText = this.handelTime2(v.description, 1);
                  v.timeEndText = this.handelTime2(v.description, 2);
                  v.description = this.handleReason(v.description);
                } else {
                  v.timeBeginText = this.handelTime(v.timeBegin);
                  v.timeEndText = this.handelTime(v.timeEnd);
                }
                v.timeExec = this.handelTime(v.timeExec);
                v.userExecText = v.execUmsUser.name;
                if (v.umsDepartment) {
                  v.umsDepartment = v.umsDepartment.name;
                }
                // 补卡
                if (this.tabPosition == 2) {
                  v.replacementType = this.replacementText(v.typeRecord);
                  v.replacementTime = this.handelTime(v.timeRecord);
                }
                // 请假
                if (this.tabPosition == 3) {
                  v.leaveType = this.leaveText(v.typeRest);
                }
                v.statusText = v.status == 1 ? "同意" : "驳回";
                for (let i = 0; i < arr2.length; i++) {
                  if (v[arr2[i]] == undefined) {
                    v[arr2[i]] = "";
                  }
                }
                for (let key in v) {
                  if (v[key] == null) v[key] = "";
                }
              });
              exprotExcel.export(object, res.data.list, "已审批列表");
              this.$message.success("导出成功");
            } else {
              this.$message.error("暂无数据导出");
            }
          }
        });
    },
    // 处理时间
    handelTime(val) {
      if (val) {
        // 将字符串解析为Date对象
        const dateTime = new Date(val);
        const formattedDateTime = `${dateTime.getFullYear()}-${(
          "0" +
          (dateTime.getMonth() + 1)
        ).slice(-2)}-${("0" + dateTime.getDate()).slice(-2)} ${(
          "0" + dateTime.getHours()
        ).slice(-2)}:${("0" + dateTime.getMinutes()).slice(-2)}`;

        return formattedDateTime;
      }
    },
    // 处理时间
    handelTime2(reason, type) {
      // 使用正则表达式匹配括号内的内容以及0.5
      // 注意：这里假设括号内只包含日期和“下午”这样的简单模式
      const regex = /\(([^)]+)\)(.*)/;
      var match = reason.match(regex);

      if (match) {
        // 括号内的内容是match[1]，0.5是match[3]（因为捕获组从1开始编号）
        var insideParens = match[1];

        if (insideParens) {
          var arr = insideParens.split(",");
          if (type == 1) {
            return arr[0];
          } else {
            return arr[1];
          }
        } else {
          return "- -";
        }
      } else {
        // 如果没有匹配到，返回一个空对象或抛出错误
        return "- -"; // 或者你可以选择抛出错误
      }
    },
    // 处理原因
    handleReason(reason) {
      if (reason) {
        var index = reason.indexOf("("); // 查找 '(' 的索引
        if (index !== -1) {
          // 如果找到了 '('，则截取 '(' 之前的字符串
          return reason.substring(0, index);
        }
        // 如果没有找到 '('，则返回原字符串（或者你可以选择抛出一个错误）
        return "- -";
      }
    },
    // 清空部门
    clearDept(val) {
      if (val == "") {
        delete this.searchForm.department;
      }

      this.seachResult();
    },
    // 筛选补卡类型
    replacementText(value) {
      switch (value) {
        case 0:
          return "旷工";
        case 1:
          return "迟到";
        case 2:
          return "早退";
        case 3:
          return "其他";
      }
    },
    // 筛选补卡类型
    leaveText(value) {
      switch (value) {
        case 0:
          return "事假";
        case 1:
          return "年假";
        case 2:
          return "婚假";
        case 3:
          return "产休假";
        case 4:
          return "病假";
        case 5:
          return "调休";
        case 6:
          return "其他";
      }
    },

    // 相隔几个小时
    formatHours(date1, date2) {
      // 创建Date对象
      var date1 = new Date(date1);
      var date2 = new Date(date2);

      // 计算两个日期之间的毫秒差值
      var timeDifference = Math.abs(date2 - date1);

      // 将毫秒差值转换为小时，并保留一位小数
      var hoursDifference = (timeDifference / (1000 * 60 * 60)).toFixed(1);
      return hoursDifference;
    },
    // 标准时间转换成天数
    formatDate(standardTimeString) {
      // 创建一个Date对象
      var date = new Date(standardTimeString);

      // 提取年、月、日
      var year = date.getFullYear();
      var month = ("0" + (date.getMonth() + 1)).slice(-2); // 月份从0开始，所以要加1，并确保两位数格式
      var day = ("0" + date.getDate()).slice(-2); // 确保两位数格式

      // 将年、月、日拼接成所需的格式
      var formattedDate = year + "-" + month + "-" + day;
      return formattedDate;
    },
    // 相差几天
    formatDays(date1, date2) {
      // 创建Date对象
      var date1 = new Date(date1);
      var date2 = new Date(date2);

      // 计算两个日期之间的毫秒差值
      var timeDifference = Math.abs(date2 - date1);

      // 将毫秒差值转换为天，并保留一位小数
      var daysDifference = (timeDifference / (1000 * 60 * 60 * 24)).toFixed(1);
      return daysDifference;
    },
    // 相差几天
    formatDays2(reason) {
      // 使用正则表达式匹配括号内的内容以及0.5
      // 注意：这里假设括号内只包含日期和“下午”这样的简单模式
      const regex = /\(([^)]+)\)(.*)/;
      var match = reason.match(regex);

      if (match) {
        // 括号内的内容是match[1]，0.5是match[3]（因为捕获组从1开始编号）
        var insideParens = match[1];
        var zeroFive = match[2];

        if (zeroFive) {
          return zeroFive;
        } else {
          return "- -";
        }
      } else {
        // 如果没有匹配到，返回一个空对象或抛出错误
        return "- -"; // 或者你可以选择抛出错误
      }
    },
    splitString(str) {
      // 初始化结果数组
      let result = [];

      // 将前两个字符单独拆分
      for (let i = 0; i < 2 && i < str.length; i++) {
        result.push(str[i]);
      }

      // 将剩下的字符作为一个整体元素加入数组
      if (str.length > 2) {
        result.push(str.slice(2));
      }

      return result;
    },
    // 判断两个时间相隔几天
    daysBetweenDates(dateString1, dateString2) {
      const date1 = new Date(dateString1 + "T00:00:00"); // 开始时间设置为当天的0点
      const date2 = new Date(dateString2 + "T23:59:59"); // 结束时间设置为当天的23:59:59
      const diffInMs = date2 - date1;
      const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24)); // 转换为天数并向上取整
      return diffInDays;
    },
  },
};
</script>

<style scoped>
.wait {
  width: 100%;
  height: 100%;
  padding: 19px 16px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: white;
}
.wait_header {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-between;
}
.wait_complete{
  flex: 1;
}
::v-deep .el-radio-button {
  margin-right: 14px;
}
::v-deep .el-radio-button__inner {
  border: 1px solid #d0d0d0;
  border-radius: 6px;
  font-size: 15px;
  color: #1a1a1a;
}
::v-deep .el-radio-button:last-child .el-radio-button__inner,
.el-radio-button:first-child .el-radio-button__inner {
  border-radius: 6px;
}
::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background: #0058ff;
  color: white;
  border-color: #0058ff;
}
::v-deep .el-radio-button:first-child .el-radio-button__inner {
  border-radius: 6px;
}
.visitor_information_search {
  width: 243px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #d0d0d0;
  display: flex;
  align-items: center;
  padding-right: 12px;
  box-sizing: border-box;
  justify-content: space-between;
}
.wait_search {
  display: flex;
  align-items: center;
}
::v-deep .el-select {
  width: 140px;
  height: 36px;
  margin-left: 10px;
}
::v-deep .el-select .el-input__inner {
  width: 140px;
  height: 36px;
}
.export_table {
  width: 80px;
  height: 36px;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #d5d7e3;
  font-size: 14px;
  color: #7e84a3;
  line-height: 36px;
  text-align: center;
  cursor: pointer;
  margin-left: 10px;
}
::v-deep .el-range-editor.el-input__inner {
  width: 300px;
  height: 36px;
  margin-right: 10px;
}
::v-deep .wait_search .el-date-editor .el-range-separator {
  line-height: 28px;
}
::v-deep .el-select-dropdown__item {
  padding: 0;
}
</style>