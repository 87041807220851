<template>
  <div class="scree">
    <div class="scree_head">
      <div class="scree_head_l">
        <img
          src="@/assets/structure/close.png"
          class="scree_close"
          @click="close"
        />
        <div>筛选</div>
      </div>
      <div class="staff_r">
        <el-button @click="close">取消</el-button>
        <el-button class="staff_f_comfin" type="primary" @click="comfin"
          >确认</el-button
        >
      </div>
    </div>

    <!-- 筛选内容 -->
    <div class="scree_information">
      <el-form
        label-position="top"
        label-width="80px"
        :model="formScree"
        ref="formScree"
      >
        <el-form-item label="性别" prop="sex">
          <el-select v-model="formScree.sex" placeholder="">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="入职日期" prop="timeJoin">
          <el-date-picker
            v-model="formScree.timeJoin"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formScree: {}, //筛选表单
      mineStatusValue: [],
      options: [
        {
          label: "男",
          value: 1,
        },
        {
          label: "女",
          value: 0,
        },
      ],
      data: [],
      defaultProps: {
        children: "subDepartment",
        label: "departmentName",
      },
    };
  },

  created() {
    // 获取缓存部门列表
    var arr = localStorage.getItem("department");
    this.data = JSON.parse(arr);
  },

  // mounted() {
  //   this.$nextTick(() => {
  //     this.$refs.formScree.resetFields();
  //   });
  // },

  methods: {
    // 关闭弹窗
    close() {
      this.$refs.formScree.resetFields();
      this.$emit("close");
    },

    comfin() {
      this.$emit("comfin", this.formScree);
    },
  },
};
</script>

<style scoped>
.scree_head {
  height: 89px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 0 20px;
  box-sizing: border-box;
  border-bottom: 1px solid #cfd4e8;
}
.scree_head_l {
  display: flex;
  font-size: 18px;
  align-items: center;
}
.scree_close {
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-right: 20px;
}
.scree_information {
  padding: 40px 30px 0 30px;
  box-sizing: border-box;
}
::v-deep .el-tree-node__content {
  height: 50px;
}
</style>
