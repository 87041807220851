<template>
  <div class="visitor_information">
    <div class="visitor_information_title">
      考勤报表 / <span>原始记录表</span>
    </div>
    <div class="visitor_information_c">
      <div class="visitor_information_t">
        <div class="visitor_information_t_left">
          <el-date-picker
            v-model="date"
            type="daterange"
            @change="changeDate"
            range-separator="-"
            class="department_search_date"
            :picker-options="expireTimeOPtion"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
          <div class="visitor_information_search">
            <el-input
              @keyup.enter.native="searchSetof(1)"
              class="department_search_inp"
              v-model="searchValue"
              placeholder="姓名、员工工号"
            ></el-input>
            <img
              src="@/assets/structure/delete.png"
              class="searchicon"
              @click="clearup"
              v-if="searchicon"
            />
          </div>
          <el-select
            v-model="selectTree"
            placeholder="部门"
            :popper-append-to-body="false"
            multiple
            @change="selectChange"
            class="select-tree"
          >
            <el-option :value="selectTreeValue" style="height: auto">
              <!-- option展开高度太小，把height设置为auto就好啦 -->
              <el-tree
                :data="departmentList"
                show-checkbox
                node-key="id"
                ref="tree"
                accordion
                check-strictly
                highlight-current
                :props="defaultProps"
                @check="handleCheckChange"
              ></el-tree>
            </el-option>
          </el-select>
        </div>
        <div>
          <el-button @click="startDownload">导出报表</el-button>
        </div>
      </div>
      <div class="visitor_information_table">
        <el-table
          v-loading="tableloading"
          height="95%"
          ref="multipleTable"
          :data="tableData"
          border
          class="v_table"
          row-key="id"
          @selection-change="handleSelectionChange"
          :header-cell-style="getRowClass"
        >
          <template slot="empty">
            <div>
              <img src="@/assets/nodata.png" class="nodata" />
              <div>无数据</div>
            </div>
          </template>
          <el-table-column type="selection" width="55" reserve-selection>
          </el-table-column>
          <el-table-column prop="name" label="姓名" width="200">
            <template slot-scope="scope">
              <span class="ellipsis" v-if="scope.row.umsUser">{{
                scope.row.umsUser.name
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="员工工号" width="130">
            <template slot-scope="scope">
              <span class="ellipsis" v-if="scope.row.umsUser">{{
                scope.row.umsUser.jobNumber
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="部门" width="180">
            <template slot-scope="scope">
              <div class="ellipsis" v-if="scope.row.umsDepartment.name">
                {{ scope.row.umsDepartment.name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="日期" width="200">
            <template slot-scope="scope">
              <div v-if="scope.row.clockTime">
                {{ scope.row.clockTime.split(" ")[0] }} ({{
                  weekDay(scope.row.clockTime)
                }})
              </div>
              <div v-else>— —</div>
            </template>
          </el-table-column>
          <el-table-column label="打卡时间" width="200">
            <template slot-scope="scope">
              <div v-if="scope.row.clockTime">
                {{ scope.row.clockTime.split(" ")[1] }}
              </div>
              <div v-else>— —</div>
            </template>
          </el-table-column>
          <el-table-column label="验证方式" width="130">
            <template slot-scope="scope">
              <div v-if="scope.row.type">
                {{ verification(scope.row.type) }}
              </div>
              <div v-else>- -</div>
            </template>
          </el-table-column>
          <el-table-column label="设备名称" width="200">
            <template slot-scope="scope">
              <div v-if="scope.row.dmsDevice">
                {{ scope.row.dmsDevice.name }}
              </div>
              <div v-else>- -</div>
            </template>
          </el-table-column>
          <el-table-column label="云序列号">
            <template slot-scope="scope">
              <div v-if="scope.row.dmsDevice">
                {{ scope.row.dmsDevice.sn }}
              </div>
              <div v-else>- -</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div
        class="newpagination"
        v-if="pages.total > 0"
        style="padding-left: 20px; box-sizing: border-box"
      >
        <div class="newpagination_text">共 {{ pages.total }} 条数据</div>
        <el-pagination
          layout="prev, pager, next"
          :total="pages.total"
          :current-page="pages.pageNum"
          :page-size="10"
          @current-change="chagePage"
        >
        </el-pagination>
      </div>
    </div>
    <div class="filing">
      CopyRight@2023广州巨源信息技术有限公司-<a
        href="https://beian.miit.gov.cn/"
        >粤ICP备2023127373号-4</a
      >
    </div>
  </div>
</template>

<script>
import http from "../../../utils/http";
import exprotExcel from "../../../utils/exprotExcel";
export default {
  data() {
    return {
      tableloading: true,
      searchValue: "", //搜索的关键词
      seletObject: {}, //搜索合集
      pageNum: 1, //页数
      selectTree: [], //部门多选
      selectDeptid: [], //选择的部门id
      selectTreeValue: [], //部门多选
      departmentList: [], //部门列表
      date: null, //搜索的日期
      startTime: "", //开始日期
      endTime: "", //结束日期
      searchicon: false, //监听输入
      tableData: [], //表格数据
      selectedData: [], //已经选择
      tipstitle: "确认删除此访客?",
      expireTimeOPtion: {
        disabledDate(time) {
          return time.getTime() > Date.now(); //如果没有后面的-8.64e7就是不可以选择今天的
        },
      },
      selectUserid: [], //最终删除的数组
      pages: {}, //分页
      multipleSelection: [],
      tableSelection: [], //导出表格需要
      company: {}, //公司信息
      delectVistorDiglog: false, //删除访客提示框
      defaultProps: {
        children: "subDepartment",
        label: "name",
      },
      tableColumn: [
        {
          title: "姓名",
          dataIndex: "nameText",
          id: 1,
        },
        {
          title: "工号",
          dataIndex: "jobNumbernew",
          id: 2,
        },
        {
          title: "部门",
          dataIndex: "departmentName",
          id: 3,
        },
        {
          title: "日期",
          dataIndex: "date",
          id: 4,
        },
        {
          title: "打卡时间",
          dataIndex: "day",
          id: 5,
        },
        {
          title: "验证方式",
          dataIndex: "mode",
          id: 6,
        },
        {
          title: "设备名称",
          dataIndex: "deviceName",
          id: 7,
        },
        {
          title: "云序列号",
          dataIndex: "sn",
          id: 8,
        },
      ], //导出内容参数
    };
  },
  created() {
    // 获取访客列表
    this.getDate();

    // 获取部门列表
    this.getDepartment();
  },
  watch: {
    searchValue(newName, oldName) {
      if (newName) {
        this.searchicon = true;
      } else {
        this.searchicon = false;
      }
    },
  },
  methods: {
    // 表头背景颜色变色
    getRowClass({ rowIndex, columnIndex }) {
      if (rowIndex == 0) {
        return "background:#F5F6FA;color:#5C5C5C";
      }
    },

    // 获取原始记录表
    getVisitor() {
      http
        .get(
          "/bmsAttendanceClock/list",
          Object.assign(this.seletObject, {
            pageSize: 10,
            pageNum: this.pageNum,
          })
        )
        .then((res) => {
          if (res.code == 200) {
            this.pages = res.data;
            this.tableData = res.data.list;
            this.tableloading = false;
          }
        });
    },

    // 获取部门列表
    getDepartment() {
      http.get("/umsDepartment/list").then((res) => {
        if (res.code == 200) {
          if (res.data.length > 0) {
            this.departmentList = this.convertToNestedStructure(res.data);
          }
        }
      });
    },

    // 递归处理数据
    convertToNestedStructure(arr) {
      // 创建一个映射，用于快速查找具有特定id的对象
      const idMap = new Map(arr.map((item) => [item.id, item]));

      // 创建一个新数组来存储转换后的嵌套结构
      const nestedArr = [];

      // 遍历原始数组
      arr.forEach((item) => {
        // 如果该项有父元素，则将其添加到父元素的subDepartment数组中
        if (item.parent) {
          const parent = idMap.get(item.parent);
        if (parent) {
            if (!parent.subDepartment) {
              parent.subDepartment = [];
            }
            item.parentDepament = parent.name;
            parent.subDepartment.push(item);
          }
        } else {
          // 如果没有父元素，则添加到新数组的根级别
          nestedArr.push(item);
        }
      });

      // 返回转换后的嵌套数组
      return nestedArr;
    },

    // 表格下载之前
    async startDownload() {
      var finalArr = [];
      const loading = this.$loading({
        lock: true,
        text: "报表导出中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let target = event.target;
      if (target.nodeName == "I" || target.nodeName == "SPAN") {
        target = event.target.parentNode;
      }
      target.blur();
      var total = this.pages.total;
      let arr2 = [];
      for (let key of this.tableColumn) {
        arr2.push(key.key);
      }
      if (this.tableSelection.length > 0) {
        finalArr = this.tableSelection;
      } else {
        if (this.seletObject.beginDay && this.seletObject.endDay) {
          var date1 = new Date(this.seletObject.beginDay);
          var date2 = new Date(this.seletObject.endDay);
          const oneDay = 24 * 60 * 60 * 1000; // 一天的毫秒数
          const diff = Math.abs(date2.getTime() - date1.getTime()); // 计算差值的绝对值
          var isTrue = diff > 30 * oneDay;

          if (isTrue || total >= 40000) {
            loading.close();
            this.$message.error("导出时间相隔不能超过一个月,且不能高于4万条");
            return;
          }
        }
        try {
          var arr = await http.get(
            "/bmsAttendanceClock/list",
            Object.assign(this.seletObject, {
              pageSize: total,
              pageNum: 1,
            })
          );
          if (arr.code == 200) {
            finalArr = arr.data.list;
          } else {
            loading.close();
          }
        } catch (e) {
          loading.close();
        }
      }

      if (finalArr.length > 0) {
        loading.close();
        finalArr.map((v) => {
          if (v.umsUser.jobNumber) {
            v.jobNumbernew = "\xa0" + v.umsUser.jobNumber;
          }
          v.nameText = v.umsUser.name;
          v.departmentName = v.umsDepartment.name;
          v.date =
            v.clockTime.split(" ")[0] + "(" + this.weekDay(v.clockTime) + ")";
          v.day = v.clockTime.split(" ")[1];
          v.week = this.weekDay(v.clockTime);
          v.mode = this.verification(v.type);
          if (v.dmsDevice) {
            v.deviceName = v.dmsDevice.name;
            v.sn = v.dmsDevice.sn;
          }
          for (let i = 0; i < arr2.length; i++) {
            if (v[arr2[i]] == undefined) {
              v[arr2[i]] = "";
            }
          }
          for (let key in v) {
            if (v[key] == null) v[key] = "";
          }
        });
        exprotExcel.export(this.tableColumn, finalArr, "原始记录表");
        this.$message.success("导出成功");
      } else {
        loading.close();
        this.$message.error("导出失败");
      }
    },
    // 页数改变
    chagePage(e) {
      this.searchSetof(e);
    },

    // 清除输入框
    clearup() {
      this.searchValue = "";
      this.searchSetof(1);
    },
    // 多选,删除任一select选项的回调

    selectChange(e) {
      var arrNew = [];
      var dataLength = this.selectedData.length;
      var eleng = e.length;
      for (let i = 0; i < dataLength; i++) {
        for (let j = 0; j < eleng; j++) {
          if (e[j] === this.selectedData[i].name) {
            arrNew.push(this.selectedData[i]);
          }
        }
      }
      this.$refs.tree.setCheckedNodes(arrNew); //设置勾选的值

      if (e.length > 0) {
        this.selectDeptid = [];
        var arr = [];
        e.map((item) => {
          var index = this.selectedData.findIndex(
            (citem) => citem.name == item
          );

          if (index > -1) {
            this.selectDeptid.push(this.selectedData[index].id);
            arr.push(this.selectedData[index]);
          }
        });

        this.selectedData = arr;
      } else {
        this.selectedData = [];
        this.selectDeptid = [];
      }

      this.searchSetof(1);
    },
    // 多选框
    handleCheckChange(data, checked, indeterminate) {
      var arr = checked.checkedKeys;
      this.selectTree = [];

      var index = this.selectedData.findIndex((item) => item.id == data.id);
      if (index > -1) {
        this.selectedData.splice(index, 1);
      } else {
        this.selectedData.push(data);
      }

      this.selectedData.map((item) => {
        this.selectTree.push(item.name);
      });

      this.selectDeptid = arr;
      this.selectTreeValue = arr;

      this.searchSetof(1);

      // console.log(this.selectTreeValue);
      // console.log(this.selectTree);
    },

    // 设置默认选中的tree回显
    setCheckedKeys() {
      this.$refs.tree.setCheckedKeys([7, 81, 82]);
    },

    // 删除访客弹窗
    deleteInvitefirst(ids) {
      let target = event.target;
      if (target.nodeName == "I" || target.nodeName == "SPAN") {
        target = event.target.parentNode;
      }
      target.blur();
      var arr = [];
      if (Array.isArray(ids)) {
        if (ids.length == 0) {
          this.$message.error("请先选择访客");
          return;
        }
        this.tipstitle = "确认删除所选的访客？";
        arr = ids;
      } else {
        this.tipstitle = "确认删除此访客？";
        arr = [ids];
      }
      setTimeout(() => {
        this.delectVistorDiglog = true;
      }, 200);
      this.selectUserid = arr;
    },

    // 转换成星期几
    weekDay(time) {
      var arr = time.split(" ");
      const weekNum = new Date(arr[0]).getDay();
      let week = "";
      switch (weekNum) {
        case 0:
          week = "星期天";
          break;
        case 1:
          week = "星期一";
          break;
        case 2:
          week = "星期二";
          break;
        case 3:
          week = "星期三";
          break;
        case 4:
          week = "星期四";
          break;
        case 5:
          week = "星期五";
          break;
        case 6:
          week = "星期六";
          break;
      }
      return week;
    },

    // 验证方式
    verification(value) {
      switch (value) {
        case "face":
          return "人脸";
        case "fp":
          return "指纹";
        case "palm":
          return "掌静脉";
        case "password":
          return "密码";
        case "fp+password":
          return "指纹+密码";
        case "face+Password":
          return "人脸+密码";
        case "palm+password":
          return "掌静脉+密码";
        case "record":
          return "补卡";
        case "wifi":
          return "WIFI";
        case "gps":
          return "GPS";
      }
    },

    // 选择所选日期
    changeDate(e) {
      if (e) {
        var start = new Date(e[0]);
        var end = new Date(e[1]);

        var syear = start.getFullYear();
        var smonth =
          start.getMonth() + 1 <= 9
            ? "0" + (start.getMonth() + 1)
            : start.getMonth() + 1;
        var sday =
          start.getDate() <= 9 ? "0" + start.getDate() : start.getDate();

        var eyear = end.getFullYear();
        var emonth =
          end.getMonth() + 1 <= 9
            ? "0" + (end.getMonth() + 1)
            : end.getMonth() + 1;
        var eday = end.getDate() <= 9 ? "0" + end.getDate() : end.getDate();

        this.startTime = syear + "-" + smonth + "-" + sday;
        this.endTime = eyear + "-" + emonth + "-" + eday;

        this.searchSetof(1);
      } else {
        this.$nextTick(() => {
          setTimeout(() => {
            this.getDate();
          }, 500);
        });
      }
    },

    // 取消删除
    closeDelete() {
      this.delectVistorDiglog = false;
    },

    // 搜索集合
    searchSetof(page) {
      this.pageNum = page;
      var object = {};
      if (this.searchValue != "") {
        object.likeString = this.searchValue;
      } else {
        delete object.likeString;
      }

      if (this.startTime && this.endTime) {
        object.beginDay = this.startTime;
        object.endDay = this.endTime;
      }

      if (this.selectDeptid.length > 0) {
        object.departmentIdList = this.selectDeptid.join(",");
      }

      this.seletObject = object;
      this.getVisitor();
    },

    // 重置
    reset() {
      this.searchValue = "";
      this.selectTreeValue = [];
      this.selectTree = [];
      this.selectDeptid = [];
      this.getDate();
    },

    // 获取当前月份
    getDate() {
      var date = new Date();
      var year = date.getFullYear(); //获取完整的年份(4位)
      var month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      var newmonth =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      var day = date.getDate();

      var lastDate = new Date(year, month, 0).getDate() + 1; //获取当月最后一日

      if (day == "01") {
        this.startTime = year + "-" + month + "-01";
      } else {
        this.startTime = year + "-" + newmonth + "-01";
      }
      if (day <= lastDate) {
        var newday = "";
        if (day < 10) {
          newday = "0" + day;
        } else {
          newday = day;
        }

        this.endTime = year + "-" + newmonth + "-" + newday;
      }
      this.date = [this.startTime, this.endTime];

      this.searchSetof(1);
    },

    // 表格多选
    handleSelectionChange(val) {
      this.multipleSelection = [];
      this.tableSelection = val;
      val.map((item) => {
        this.multipleSelection.push(item.id);
      });
    },

    itemError(item) {
      item.headUrl = require("@/assets/head.png");
    },
  },
};
</script>

<style scoped>
.visitor_information {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  box-sizing: border-box;
  background: #f4f7fc;
}
.visitor_information_c {
  width: 100%;
  box-shadow: 0px 1px 4px 1px rgba(21, 34, 50, 0.08);
  flex: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 6px;
  background: white;
}
.visitor_information_t {
  height: 76px;
  padding: 0 30px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(207, 212, 232, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.visitor_information_search {
  width: 243px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #d0d0d0;
  display: flex;
  align-items: center;
  padding-right: 12px;
  box-sizing: border-box;
  justify-content: space-between;
}
.department_search_inp {
  width: 200px;
}
.visitor_information_table {
  width: 100%;
  flex: 1;
  padding: 20px;
  position: relative;
  box-sizing: border-box;
}
.visitor_information_title {
  font-size: 14px;
  color: #b2b2b2;
  margin-bottom: 16px;
}
.visitor_information_title span {
  color: #5c5c5c;
}
.memeber_operation {
  margin-left: 8px;
  width: 107px;
}
.detailed {
  width: 86px;
  height: 26px;
  background: #e0ecff;
  color: #0058ff;
  font-size: 14px;
  text-align: center;
  line-height: 26px;
  cursor: pointer;
}
.qrcode {
  width: 100%;
}
.qrcode_frame {
  width: 457px;
  height: 358px;
  display: flex;
  color: #1a1a1a;
  margin: 60px auto 24px;
  flex-direction: column;
  align-items: center;
  padding-top: 46px;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.16);
}
.qrcode_name {
  font-size: 16px;
  margin-bottom: 30px;
}
.qrcode_img {
  width: 200px;
  height: 200px;
  background: black;
}
.qrcode_tips {
  color: #9a9a9a;
  font-size: 15px;
  text-align: center;
}
.qrcode_bto {
  display: flex;
  margin-top: 39px;
  justify-content: center;
}
.link_frame {
  width: 797px;
  height: 132px;
  margin: 30px;
  font-size: 16px;
  padding: 24px;
  color: #1a1a1a;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.16);
}
.link_tips {
  font-size: 15px;
  color: #9a9a9a;
  padding-left: 30px;
  box-sizing: border-box;
}
.link_bto {
  display: flex;
  margin-top: 289px;
  justify-content: center;
}
.invite_h {
  height: 73px;
  padding: 0 24px;
  color: #1a1a1a;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 18px;
  justify-content: space-between;
}
.invite_icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.visitor_name {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #1a1a1a;
}
.visitor_head {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 10px;
}
.primary-button-style {
  background: #2979ff;
  border-color: #2979ff;
}
::v-deep .el-table__body .el-table__row.hover-row td {
  background-color: rgba(0, 98, 255, 0.05);
}
.newpagination {
  height: 50px;
  margin-top: 30px;
}
.visitor_information_search:focus-within {
  border-color: #0096fa;
}
.v_table {
  width: 97%;
  height: 100%;
  position: absolute;
}
.visitor_information_t_left {
  display: flex;
  align-items: center;
}
.department_search_date {
  height: 36px;
  margin-right: 10px;
}
::v-deep .el-select {
  margin-left: 10px;
  height: 36px;
}
::v-deep .el-input__inner {
  height: 36px;
}
::v-deep .select-tree .el-select__tags {
  height: 36px;
  overflow: hidden;
  white-space: nowrap;
  flex-wrap: nowrap;
}
::v-deep .select-tree .el-select__tags-text {
  display: inline-block;
  max-width: 85px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
::v-deep .el-select-dropdown.is-multiple .el-select-dropdown__item {
  padding: 0;
}
.timestatus {
  width: 68px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 16px;
}
::v-deep .el-table__fixed {
  height: 98% !important;
}
::v-deep .el-tree-node__content {
  height: 51px;
  font-size: 15px;
  color: #5c5c5c;
}

::v-deep .el-date-editor .el-range-separator {
  line-height: 28px;
}
::v-deep .el-input--suffix .el-input__inner {
  height: 36px !important;
}
.ellipsis2 {
  width: 160px;
  overflow: hidden; /* 超出部分隐藏 */
  white-space: nowrap; /* 禁止文本换行 */
  text-overflow: ellipsis;
}
::v-deep .visitor_information_bto {
  height: 35px;
  line-height: 0.8;
  margin-left: 20px;
  background: #0058ff;
  border-color: #0058ff;
}
::v-deep .visitor_information_bto2 {
  height: 35px;
  line-height: 0.8;
  margin-left: 20px;
  background: #e8f4fe;
}
</style>
