<template>
  <div class="visitor_information">
    <div class="visitor_information_title">
      考勤报表 / <span>打卡记录表</span>
    </div>
    <div class="visitor_information_c">
      <div class="visitor_information_t">
        <div class="visitor_information_t_left">
          <el-date-picker
            v-model="date"
            type="daterange"
            @change="changeDate"
            range-separator="-"
            class="department_search_date"
            :picker-options="expireTimeOPtion"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
          <div class="visitor_information_search">
            <el-input
              class="department_search_inp"
              @keyup.enter.native="searchSetof(1)"
              v-model="searchValue"
              placeholder="姓名"
            ></el-input>
            <img
              src="@/assets/structure/delete.png"
              class="searchicon"
              @click="clearup"
              v-if="searchicon"
            />
          </div>
          <el-select
            v-model="selectTree"
            placeholder="部门"
            :popper-append-to-body="false"
            multiple
            @change="selectChange"
            class="select-tree"
          >
            <el-option :value="selectTreeValue" style="height: auto">
              <!-- option展开高度太小，把height设置为auto就好啦 -->
              <el-tree
                :data="departmentList"
                show-checkbox
                node-key="id"
                ref="tree"
                accordion
                check-strictly
                highlight-current
                :props="defaultProps"
                @check="handleCheckChange"
              ></el-tree>
            </el-option>
          </el-select>
        </div>
        <div>
          <el-button @click="startDownload(tableSelection)">导出报表</el-button>
        </div>
      </div>
      <div class="visitor_information_table">
        <el-table
          v-loading="tableloading"
          height="95%"
          ref="multipleTable"
          :data="tableData"
          class="v_table"
          border
          row-key="id"
          @selection-change="handleSelectionChange"
          :header-cell-style="getRowClass"
        >
          <template slot="empty">
            <div>
              <img src="@/assets/nodata.png" class="nodata" />
              <div>无数据</div>
            </div>
          </template>
          <el-table-column type="selection" width="55" reserve-selection>
          </el-table-column>
          <el-table-column label="姓名" width="200" fixed>
            <template slot-scope="scope">
              <div class="visitor_name">
                <div class="ellipsis2">{{ scope.row.name }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="部门" width="150" fixed>
            <template slot-scope="scope">
              <span class="ellipsis3">{{ scope.row.umsDepartment.name }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :label="item"
            min-width="210"
            :key="index"
            v-for="(item, index) in totalday"
          >
            <template slot-scope="scope">
              <div>
                {{ circularComparison(scope.row.bmsAttendanceClockList, item) }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div
        class="newpagination"
        v-if="pages.total > 0"
        style="padding-left: 20px; box-sizing: border-box"
      >
        <div class="newpagination_text">共 {{ pages.total }} 条数据</div>
        <el-pagination
          layout="prev, pager, next"
          :total="pages.total"
          :current-page="pages.pageNum"
          :page-size="10"
          @current-change="chagePage"
        >
        </el-pagination>
      </div>
    </div>
    <div class="filing">
      CopyRight@2023广州巨源信息技术有限公司-<a
        href="https://beian.miit.gov.cn/"
        >粤ICP备2023127373号-4</a
      >
    </div>
  </div>
</template>

<script>
import http from "../../../utils/http";
import exprotExcel from "../../../utils/exprotExcel";
export default {
  data() {
    return {
      tableloading: true,
      searchValue: "", //搜索的关键词
      selectTree: "", //部门多选
      serchValue: {}, //搜索合集
      selectDeptid: [], //选择的部门id
      selectTreeValue: [], //部门多选
      departmentList: [], //部门列表
      departmentCopyList: [], //复制的部门列表
      peopleList: [], //人员列表
      peopleCopyList: [], //最终处理的人员列表
      date: null, //搜索的日期
      totalday: [], //当前月份总天数
      copytotalday: [], //复制
      startTime: "", //开始日期
      endTime: "", //结束日期
      searchicon: false, //监听输入
      tableData: [], //表格数据
      tipstitle: "确认删除此访客?",
      selectUserid: [], //最终删除的数组
      selectedData: [],
      pages: {}, //分页
      multipleSelection: [],
      tableSelection: [], //导出表格需要
      company: {}, //公司信息
      delectVistorDiglog: false, //删除访客提示框
      tableHeight: null,
      arrayKey: "attendanceRecordList",
      expireTimeOPtion: {
        disabledDate(time) {
          return time.getTime() > Date.now(); //如果没有后面的-8.64e7就是不可以选择今天的
        },
      },
      defaultProps: {
        children: "subDepartment",
        label: "name",
      },
      tableColumn: [
        {
          title: "姓名",
          dataIndex: "nameText",
          id: 1,
        },
        {
          title: "部门",
          dataIndex: "departmentName",
          id: 2,
        },
      ], //导出内容参数
      tableColumnFinal: [], //最终导出内容参数
    };
  },
  created() {
    // 获取当前日期并搜索
    this.getDate();

    // 获取部门列表
    this.getDepartment();

    // 获取人员列表
    this.getPeople();

    this.getDay();
  },
  watch: {
    searchValue(newName, oldName) {
      if (newName) {
        this.searchicon = true;
      } else {
        this.searchicon = false;
      }
    },
  },
  methods: {
    // 表头背景颜色变色
    getRowClass({ rowIndex, columnIndex }) {
      if (rowIndex == 0) {
        return "background:#F5F6FA;color:#5C5C5C";
      }
    },

    // 获取报表
    getVisitor() {
      http
        .get(
          "/bmsAttendanceClock/list/by_user",
          Object.assign(this.serchValue, {
            pageSize: 10,
          })
        )
        .then((res) => {
          if (res.code == 200) {
            this.tableData = res.data.list;
            this.pages = res.data;
            this.tableloading = false;
          }
        });
    },

    // 获取人员列表
    getPeople() {
      http
        .get("/umsUser/list", {
          pageNum: 1,
          pageSize: 10000,
          typeList: "-1,2,3,4",
        })
        .then((res) => {
          if (res.code == 200) {
            this.peopleList = res.data.list;
          }
        });
    },

    // 获取部门列表
    getDepartment() {
      http.get("/umsDepartment/list").then((res) => {
        if (res.code == 200) {
          if (res.data.length > 0) {
            this.departmentList = this.convertToNestedStructure(res.data);
            this.departmentCopyList = res.data;
          }
        }
      });
    },
    // 递归处理数据
    convertToNestedStructure(arr) {
      // 创建一个映射，用于快速查找具有特定id的对象
      const idMap = new Map(arr.map((item) => [item.id, item]));

      // 创建一个新数组来存储转换后的嵌套结构
      const nestedArr = [];

      // 遍历原始数组
      arr.forEach((item) => {
        // 如果该项有父元素，则将其添加到父元素的subDepartment数组中
        if (item.parent) {
          const parent = idMap.get(item.parent);
          if (parent) {
            if (!parent.subDepartment) {
              parent.subDepartment = [];
            }
            item.parentDepament = parent.name;
            parent.subDepartment.push(item);
          }
        } else {
          // 如果没有父元素，则添加到新数组的根级别
          nestedArr.push(item);
        }
      });

      // 返回转换后的嵌套数组
      return nestedArr;
    },

    // 表格多选
    handleSelectionChange(val) {
      this.multipleSelection = [];
      this.tableSelection = val;
      val.map((item) => {
        this.multipleSelection.push(item.id);
      });
    },

    // 表格下载之前
    async startDownload() {
      var that = this;
      var finalArr = [];
      const loading = that.$loading({
        lock: true,
        text: "表格导出中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let target = event.target;
      if (target.nodeName == "I" || target.nodeName == "SPAN") {
        target = event.target.parentNode;
      }
      target.blur();
      let arr2 = [];
      for (let key of that.tableColumnFinal) {
        arr2.push(key.key);
      }
      // 判断是否选择数据
      if (that.tableSelection.length > 0) {
        finalArr = that.tableSelection;
      } else {
        if (that.serchValue.beginDay && that.serchValue.endDay) {
          var date1 = new Date(that.serchValue.beginDay);
          var date2 = new Date(that.serchValue.endDay);
          const oneDay = 24 * 60 * 60 * 1000; // 一天的毫秒数
          const diff = Math.abs(date2.getTime() - date1.getTime()); // 计算差值的绝对值
          var isTrue = diff > 30 * oneDay;
          if (isTrue) {
            loading.close();
            that.$message.error("导出时间相隔不能超过一个月");
            return;
          }
        }
        try {
          var arr = await http.get(
            "/bmsAttendanceClock/list/export",
            Object.assign(that.serchValue, {
              pageSize: 99999,
              pageNum: 1,
            })
          );
          if (arr.code == 200) {
            var newArr = []; //组成新的数组
            arr.data.umsUserList.map((item) => {
              newArr.push({
                name: item.name,
                jobDepartment: item.jobDepartment,
                id: item.id,
                deviceNumber: item.deviceNumber,
                bmsAttendanceClockList: [],
              });
            });
            arr.data.bmsAttendanceClockList.map((item) => {
              var value = newArr.find((citem) => citem.id == item.user);
              if (value) {
                value.bmsAttendanceClockList.push(item);
              }
            });

            // 判断是否有缺人
            this.peopleCopyList.forEach((obj) => {
              if (!this.objectInArray(obj, newArr)) {
                // 如果对象不存在于数组 a 中，则添加到 a
                newArr.push({
                  name: obj.name,
                  id: obj.id,
                  deviceNumber: obj.deviceNumber,
                  jobDepartment: obj.jobDepartment,
                  bmsAttendanceClockList: [],
                });
              }
            });

            newArr.map((item) => {
              var index = this.departmentCopyList.findIndex(
                (citem) => citem.id == item.jobDepartment
              );
              if (index > -1) {
                item.umsDepartment = this.departmentCopyList[index];
              }
            });

            newArr.sort(function (a, b) {
              return a.deviceNumber - b.deviceNumber;
            });

            finalArr = newArr;
          } else {
            loading.close();
          }
        } catch (e) {
          loading.close();
        }
      }

      if (finalArr.length > 0) {
        loading.close();
        finalArr.map((v) => {
          v.nameText = v.name;
          if (v.umsDepartment) {
            v.departmentName = v.umsDepartment.name;
          }
          that.totalday.map((item) => {
            v[item] = that.circularComparison(v.bmsAttendanceClockList, item);
          });
        });

        exprotExcel.export(that.tableColumnFinal, finalArr, "打卡记录表");
        that.$message.success("导出成功");
      } else {
        loading.close();
        that.$message.error("导出失败");
      }
    },
    // 创建一个函数来检查一个对象是否存在于数组中
    objectInArray(obj, array) {
      return array.some((item) => item.id === obj.id);
    },
    // 页数改变
    chagePage(e) {
      this.searchSetof(e);
    },

    // 清除输入框
    clearup() {
      this.searchValue = "";
      this.searchSetof(1);
    },
    // 多选,删除任一select选项的回调
    selectChange(e) {
      var arrNew = [];
      var dataLength = this.selectedData.length;
      var eleng = e.length;
      for (let i = 0; i < dataLength; i++) {
        for (let j = 0; j < eleng; j++) {
          if (e[j] === this.selectedData[i].name) {
            arrNew.push(this.selectedData[i]);
          }
        }
      }
      this.$refs.tree.setCheckedNodes(arrNew); //设置勾选的值

      if (e.length > 0) {
        this.selectDeptid = [];
        var arr = [];
        e.map((item) => {
          var index = this.selectedData.findIndex(
            (citem) => citem.name == item
          );

          if (index > -1) {
            this.selectDeptid.push(this.selectedData[index].id);
            arr.push(this.selectedData[index]);
          }
        });

        this.selectedData = arr;
      } else {
        this.selectedData = [];
        this.selectDeptid = [];
      }

      this.searchSetof(1);
    },
    // 多选框
    handleCheckChange(data, checked, indeterminate) {
      var arr = checked.checkedKeys;

      this.selectTree = [];

      var index = this.selectedData.findIndex((item) => item.id == data.id);
      if (index > -1) {
        this.selectedData.splice(index, 1);
      } else {
        this.selectedData.push(data);
      }

      this.selectedData.map((item) => {
        this.selectTree.push(item.name);
      });

      this.selectDeptid = arr;
      this.selectTreeValue = arr;

      this.searchSetof(1);
    },
    // 设置默认选中的tree回显
    setCheckedKeys() {
      this.$refs.tree.setCheckedKeys([7, 81, 82]);
    },

    // 删除访客弹窗
    deleteInvitefirst(ids) {
      let target = event.target;
      if (target.nodeName == "I" || target.nodeName == "SPAN") {
        target = event.target.parentNode;
      }
      target.blur();
      var arr = [];
      if (Array.isArray(ids)) {
        if (ids.length == 0) {
          this.$message.error("请先选择访客");
          return;
        }
        this.tipstitle = "确认删除所选的访客？";
        arr = ids;
      } else {
        this.tipstitle = "确认删除此访客？";
        arr = [ids];
      }
      setTimeout(() => {
        this.delectVistorDiglog = true;
      }, 200);
      this.selectUserid = arr;
    },
    // 选择所选日期
    changeDate(e) {
      if (e) {
        var start = new Date(e[0]);
        var end = new Date(e[1]);
        this.tableloading = true;

        var syear = start.getFullYear();
        var smonth =
          start.getMonth() + 1 <= 9
            ? "0" + (start.getMonth() + 1)
            : start.getMonth() + 1;
        var sday =
          start.getDate() <= 9 ? "0" + start.getDate() : start.getDate();

        var eyear = end.getFullYear();
        var emonth =
          end.getMonth() + 1 <= 9
            ? "0" + (end.getMonth() + 1)
            : end.getMonth() + 1;
        var eday = end.getDate() <= 9 ? "0" + end.getDate() : end.getDate();

        this.startTime = syear + "-" + smonth + "-" + sday;
        this.endTime = eyear + "-" + emonth + "-" + eday;

        this.serchValue.beginDay = this.startTime;
        this.serchValue.endDay = this.endTime;

        this.totalday = this.getAllDatesInRange(this.startTime, this.endTime);

        var arr = [];
        this.totalday.map((item) => {
          arr.push({
            title: item,
            dataIndex: item,
            type: "text",
          });
        });

        this.tableColumnFinal = this.tableColumn.concat(arr);

        this.$nextTick(() => {
          this.$refs["multipleTable"].doLayout();
        });
        this.getVisitor();
      } else {
        this.$nextTick(() => {
          setTimeout(() => {
            this.getDate();
            this.getDay();
          }, 500);
        });
      }
    },

    // 取消删除
    closeDelete() {
      this.delectVistorDiglog = false;
    },

    // 搜索集合
    searchSetof(page) {
      var object = {
        pageNum: page,
      };
      if (this.searchValue != "") {
        object.likeString = this.searchValue;
      } else {
        delete object.likeString;
      }

      if (this.startTime && this.endTime) {
        object.beginDay = this.startTime;
        object.endDay = this.endTime;
      }

      if (this.selectDeptid.length > 0) {
        object.departmentIdList = this.selectDeptid.join(",");
      }

      this.serchValue = object;

      // 处理人员列表和部门列表
      if (this.serchValue.departmentIdList) {
        var departmentIdList = this.selectDeptid;
        var filteredA = this.peopleList.filter(function (objA) {
          return departmentIdList.some(function (objB) {
            return objB === objA.jobDepartment;
          });
        });
        this.peopleCopyList = filteredA;
      } else {
        this.peopleCopyList = this.peopleList;
      }

      // 如果搜索有人
      if (this.serchValue.likeString) {
        var text = this.serchValue.likeString;
        this.peopleCopyList = this.peopleCopyList.filter(function (obj) {
          return obj.name == text;
        });
      }

      this.getVisitor();
    },

    // 重置
    reset() {
      this.searchValue = "";
      this.selectTreeValue = [];
      this.selectTree = [];
      this.selectDeptid = [];
      this.getDate();
    },

    // 秒换成分钟
    minuteConversion(arr, type) {
      // 1是迟到，2是早退
      if (arr.length > 0) {
        var time_l = 0;
        var time_e = 0;
        arr.map((item) => {
          if (type == 1) {
            time_l += item.secLate;
          }

          if (type == 2) {
            time_e += item.secEarly;
          }
        });

        switch (type) {
          case 1:
            return Math.ceil(time_l / 60);
          case 2:
            return Math.ceil(time_e / 60);
        }
      } else {
        return "- -";
      }
    },
    getDaysInMonth(year, month) {
      // 注意：月份在 JavaScript 中是从 0 到 11，所以实际月份要加 1
      return new Date(year, month + 1, 0).getDate();
    },
    // 获取当前月份的天数
    getDay() {
      this.totalday = [];
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month =
        currentDate.getMonth() + 1 < 10
          ? "0" + (currentDate.getMonth() + 1)
          : currentDate.getMonth() + 1;

      var day = currentDate.getDate();

      const monday = new Date(currentDate);
      monday.setDate(currentDate.getDate() - 6); // 计算周一
      var year2 = monday.getFullYear();
      var month2 = monday.getMonth() + 1;
      var day2 = monday.getDate();

      if (month2 < 10) {
        month2 = "0" + month2;
      }
      if (day < 10) {
        day = "0" + day;
      }
      if (day2 < 10) {
        day2 = "0" + day2;
      }

      var mon = year2 + "-" + month2 + "-" + day2;
      var last = year + "-" + month + "-" + day;

      var dateList = this.listDates(mon, last);
      this.totalday = dateList;
      this.copytotalday = dateList;

      var arr = [];
      this.totalday.map((item) => {
        arr.push({
          title: item,
          dataIndex: item,
          type: "text",
        });
      });

      this.tableColumnFinal = this.tableColumn.concat(arr);
    },

    // 得出中间的相隔日期
    listDates(startDate, endDate) {
      // 解析起始和结束日期
      let start = new Date(startDate);
      let end = new Date(endDate);
      // 初始化一个数组来存储日期
      let dateArray = [];
      // 循环生成日期
      while (start <= end) {
        // 获取日期和星期几
        let dateStr = start.toISOString().split("T")[0].replace(/-/g, "/"); // 格式化日期为 YYYY/MM/DD
        let dayOfWeek = start.getDay(); // 0 - 星期日, 1 - 星期一, ..., 6 - 星期六
        let daysOfWeek = [
          "星期日",
          "星期一",
          "星期二",
          "星期三",
          "星期四",
          "星期五",
          "星期六",
        ];
        dateArray.push(`${dateStr}(${daysOfWeek[dayOfWeek]})`);
        // 日期递增一天
        start.setDate(start.getDate() + 1);
      }
      return dateArray;
    },

    // 循环寻找
    circularComparison(arr, value) {
      var that = this;
      const dateMatch = value.match(/^(\d{4})\/(\d{2})\/(\d{2})/);
      if (arr.length > 0) {
        const [_, year, month, day] = dateMatch;
        var newString = `${year}-${month}-${day}`;
        var newArr = [];
        arr.map((item) => {
          if (item.clockTime) {
            let dateString = item.clockTime.split(" ");
            if (dateString[0] == newString) {
              newArr.push(dateString[1]);
            }
          }
        });

        if (newArr.length > 0) {
          newArr.forEach(
            (val, idx) => (newArr[idx] = this.removeSeconds(newArr[idx]))
          );
          newArr.sort(function (a, b) {
            // 将时间字符串转换为分钟数
            let timeA = Number(a.split(":")[0]) * 60 + Number(a.split(":")[1]);
            let timeB = Number(b.split(":")[0]) * 60 + Number(b.split(":")[1]);

            // 比较两个时间（分钟数）
            if (timeA < timeB) {
              return -1; // a应该在b之前
            }
            if (timeA > timeB) {
              return 1; // a应该在b之后
            }
            return 0; // a和b相等
          });

          // 数组去重
          var duplicateArr = [...new Set(newArr)];
          return duplicateArr.join(" ");
        } else {
          return "- -";
        }
      } else {
        return "- -";
      }
    },
    // 去掉秒数
    removeSeconds(timeString) {
      const parts = timeString.split(":");
      // 移除秒数部分
      parts.pop();
      // 重新组合时间字符串
      return parts.join(":");
    },

    // 秒数切换
    convertSecondsToHMS(seconds) {
      var hours = Math.floor(seconds / 3600);
      var minutes = Math.floor((seconds % 3600) / 60);
      var secs = seconds % 60;

      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      secs = secs < 10 ? "0" + secs : secs;

      return hours + ":" + minutes;
    },

    // 两个日期之间的天数
    formatNumberWithZero(number) {
      return number < 10 ? "0" + number : String(number);
    },

    getAllDatesInRange(startDate, endDate) {
      const datesInRange = [];
      const currentDate = new Date(startDate);
      const lastDate = new Date(endDate);

      var week = [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ];

      while (currentDate <= lastDate) {
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1;
        const day = currentDate.getDate();

        const formattedMonth = this.formatNumberWithZero(month);
        const formattedDay = this.formatNumberWithZero(day);

        var specifiedDate = new Date(year, formattedMonth - 1, formattedDay);
        var dayOfWeek = specifiedDate.getDay();

        datesInRange.push(
          `${year}/${formattedMonth}/${formattedDay}/(${week[dayOfWeek]})`
        );

        currentDate.setDate(currentDate.getDate() + 1);
      }

      return datesInRange;
    },

    // 获取当前月份
    getDate() {
      const today = new Date();
      const dayOfWeek = today.getDay(); // 获取今天是周几
      const monday = new Date(today);
      monday.setDate(today.getDate() - 6); // 计算周一

      // 周一日期
      var year = monday.getFullYear();
      var month = monday.getMonth() + 1;
      var day = monday.getDate();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }

      // 周日日期
      var year2 = today.getFullYear();
      var month2 = today.getMonth() + 1;
      var day2 = today.getDate();
      if (month2 < 10) {
        month2 = "0" + month2;
      }
      if (day2 < 10) {
        day2 = "0" + day2;
      }

      this.startTime = year + "-" + month + "-" + day;
      this.endTime = year2 + "-" + month2 + "-" + day2;
      this.date = [this.startTime, this.endTime];

      this.searchSetof(1);
    },

    itemError(item) {
      item.headUrl = require("@/assets/head.png");
    },
  },
};
</script>

<style scoped>
.visitor_information {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  box-sizing: border-box;
  background: #f4f7fc;
}
.visitor_information_c {
  width: 100%;
  box-shadow: 0px 1px 4px 1px rgba(21, 34, 50, 0.08);
  flex: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 6px;
  background: white;
}
.visitor_information_t {
  height: 76px;
  padding: 0 30px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(207, 212, 232, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.visitor_information_search {
  width: 243px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #d0d0d0;
  display: flex;
  align-items: center;
  padding-right: 12px;
  box-sizing: border-box;
  justify-content: space-between;
}
.department_search_inp {
  width: 200px;
}
.visitor_information_table {
  width: 100%;
  height: 100%;
  padding: 20px;
  position: relative;
  box-sizing: border-box;
}
.visitor_information_title {
  font-size: 14px;
  color: #b2b2b2;
  margin-bottom: 16px;
}
.visitor_information_title span {
  color: #5c5c5c;
}
.memeber_operation {
  margin-left: 8px;
  width: 107px;
}
.detailed {
  width: 86px;
  height: 26px;
  background: #e0ecff;
  color: #0058ff;
  font-size: 14px;
  text-align: center;
  line-height: 26px;
  cursor: pointer;
}
.qrcode {
  width: 100%;
}
.qrcode_frame {
  width: 457px;
  height: 358px;
  display: flex;
  color: #1a1a1a;
  margin: 60px auto 24px;
  flex-direction: column;
  align-items: center;
  padding-top: 46px;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.16);
}
.qrcode_name {
  font-size: 16px;
  margin-bottom: 30px;
}
.qrcode_img {
  width: 200px;
  height: 200px;
  background: black;
}
.qrcode_tips {
  color: #9a9a9a;
  font-size: 15px;
  text-align: center;
}
.qrcode_bto {
  display: flex;
  margin-top: 39px;
  justify-content: center;
}
.link_frame {
  width: 797px;
  height: 132px;
  margin: 30px;
  font-size: 16px;
  padding: 24px;
  color: #1a1a1a;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.16);
}
.link_tips {
  font-size: 15px;
  color: #9a9a9a;
  padding-left: 30px;
  box-sizing: border-box;
}
.link_bto {
  display: flex;
  margin-top: 289px;
  justify-content: center;
}
.invite_h {
  height: 73px;
  padding: 0 24px;
  color: #1a1a1a;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 18px;
  justify-content: space-between;
}
.invite_icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.visitor_name {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #1a1a1a;
}
.visitor_head {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 10px;
}
.primary-button-style {
  background: #2979ff;
  border-color: #2979ff;
}
::v-deep .el-table__body .el-table__row.hover-row td {
  background-color: rgba(0, 98, 255, 0.05);
}
.newpagination {
  height: 50px;
  margin-top: 34px;
}
.visitor_information_search:focus-within {
  border-color: #0096fa;
}
.v_table {
  width: 97%;
  height: 100%;
  position: absolute;
}
.visitor_information_t_left {
  display: flex;
  align-items: center;
}
.department_search_date {
  height: 36px;
  margin-right: 10px;
}
::v-deep .el-select {
  margin-left: 10px;
  height: 36px;
}
::v-deep .el-input__inner {
  height: 36px;
}
::v-deep .select-tree .el-select__tags {
  height: 36px;
  overflow: hidden;
  white-space: nowrap;
  flex-wrap: nowrap;
}
::v-deep .select-tree .el-select__tags-text {
  display: inline-block;
  max-width: 85px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
::v-deep .el-select-dropdown.is-multiple .el-select-dropdown__item {
  padding: 0;
}
.timestatus {
  width: 68px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 16px;
}
.showdate {
  display: flex;
  align-items: center;
}
.showdate_single {
  width: 40px;
  height: 30px;
  margin: 0 5px;
  line-height: 30px;
  text-align: center;
  font-size: 16px;
}
::v-deep .el-table__fixed {
  height: 98% !important;
}
::v-deep .el-tree-node__content {
  height: 51px;
  font-size: 15px;
  color: #5c5c5c;
}
::v-deep .el-date-editor .el-range-separator {
  line-height: 28px;
}
::v-deep .el-input--suffix .el-input__inner {
  height: 36px !important;
}
.ellipsis {
  width: 200px;
  overflow: hidden; /* 超出部分隐藏 */
  white-space: nowrap; /* 禁止文本换行 */
  text-overflow: ellipsis;
  display: block;
}
.ellipsis2 {
  width: 120px;
  overflow: hidden; /* 超出部分隐藏 */
  white-space: nowrap; /* 禁止文本换行 */
  text-overflow: ellipsis;
}
.ellipsis3 {
  width: 120px;
  overflow: hidden; /* 超出部分隐藏 */
  white-space: nowrap; /* 禁止文本换行 */
  text-overflow: ellipsis;
}
::v-deep .visitor_information_bto {
  height: 35px;
  line-height: 0.8;
  margin-left: 20px;
  background: #0058ff;
  border-color: #0058ff;
}
::v-deep .visitor_information_bto2 {
  height: 35px;
  line-height: 0.8;
  margin-left: 20px;
  background: #e8f4fe;
}
</style>
