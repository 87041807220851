<template>
  <div class="bg-white w-full fixed h-full">
    <div class="head_navi" @click="reback">
      <img src="~@/assets/login/back.png" class="navi_icon" />
      <div>返回</div>
    </div>
    <div class="px-6" v-show="step == 1">
      <div class="title">输入手机验证码</div>
      <div class="tips">
        请输入发送至<span>{{ handleTel(tel) }}</span
        >的6位验证码，有效期5分钟
      </div>

      <div
        class="ml-2 mt-4 flex px-1 text-center item-center"
        ref="vertifyWrapper"
      >
        <input
          type="text"
          onpaste="return false"
          readonly
          onfocus="this.removeAttribute('readonly')"
          v-for="index in 6"
          :key="index"
          class="w-2/12 login_input"
          @keydown="clearInput"
          @keyup="goVertify"
        />
      </div>
      <div class="mt-8 flex">
        <div v-if="isShowVertifyCode" class="onget" @click="getVertifycode">
          重新获取
        </div>
        <div
          v-else
          class="text-sm text-jpfont-400 pl-3"
          :class="[{ hidden: isShowVertifyCode }]"
        >
          <span style="color: #0058ff">{{ timersecond }}</span
          >秒后可重新获取验证码
        </div>
      </div>

      <div class="fogetbto">
        <Button
          :text="'下一步'"
          :isinput="isInput"
          @onSubmit="verificationCode"
        />
      </div>
    </div>
    <!-- 重置密码 -->
    <div class="frames" v-show="step == 2">
      <div class="title_s">重置密码</div>
      <div class="tips">密码需同时包含字母和数字，至少8个字符</div>
      <div class="p_eamil">
        <el-input
          :type="isShowpasS"
          v-model="passForm.passWord"
          placeholder="请输入密码"
        ></el-input>
        <img :src="showiconS" class="check" @click="switchShowS" />
      </div>

      <div class="p_eamil">
        <el-input
          :type="isShowpasQ"
          v-model="passForm.agaginpassword"
          placeholder="再次输入密码"
        ></el-input>
        <img :src="showiconQ" class="check" @click="switchShowQ" />
      </div>

      <div class="invitebto_s">
        <Button :text="'确认'" :isinput="fourBto" @onSubmit="fourStep" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../../assembly/button.vue";
import http from "../../utils/http";

import check from "../../assets/login/check.png";
import close from "../../assets/login/close.png";
export default {
  data() {
    return {
      timer: null, //倒计时
      inputMobile: "",
      isFocus: false,
      inputVertifyCode: [],
      currentIndex: 0,
      isShowVertifyCode: false,
      timersecond: 60,
      isShowPromptInfo: false,
      promptInfo: "none",
      promptTimer: null,
      isInput: false, //是否输入完成
      isCodeDisabled: false, //节流
      step: 1,
      ticket: "", //凭证
      passForm: {
        passWord: "", //密码
        agaginpassword: "", //确认密码
      },
      fourBto: false, //第四步按钮
      showiconS: check, //显示图标
      isShowpasS: "password", //是否显示密码
      showiconQ: check, //显示图标
      isShowpasQ: "password", //是否显示密码
    };
  },
  created() {
    // 马上获取验证码
    var num = localStorage.getItem("countdown");
    this.inputVertifyCode = [];
    if (num && num > 0) {
      this.setTimersecond(num);
    } else {
      this.getCode();
    }
  },
  watch: {
    passForm: {
      handler: function (val, oldval) {
        if (val.passWord && val.agaginpassword) {
          this.fourBto = true;
        } else {
          this.fourBto = false;
        }
      },
      deep: true,
    },
  },
  props: ["tel"],
  components: { Button },
  methods: {
    // 马上获取验证码
    getCode() {
      http
        .get("/umsSysUser/code_get", {
          phone: this.tel,
          getCodeType: "RESET_PASSWORD",
        })
        .then((res) => {
          if (res.code == 200) {
            this.setTimersecond(60);
          } else {
            this.isShowVertifyCode = true;
            if (res.message == "操作失败") {
              this.$message.error("获取验证码过于频繁，请1分钟后再试");
            } else {
              this.$message.error(res.message);
            }
          }
        });
    },

    // 验证码校验
    verificationCode() {
      http
        .post("/umsSysUser/code_verify", {
          phone: this.tel,
          code: this.inputMobile,
          getCodeType: "RESET_PASSWORD",
        })
        .then((res) => {
          if (res.code == 200) {
            this.inputVertifyCode = [];
            this.ticket = res.data.actionTicket;
            this.step = 2;
          } else {
            this.$message.error(res.message);
          }
        });
    },
    // 显示密码
    switchShowS() {
      if (this.isShowpasS == "password") {
        this.isShowpasS = "text";
        this.showiconS = close;
      } else {
        this.isShowpasS = "password";
        this.showiconS = check;
      }
    },

    switchShowQ() {
      if (this.isShowpasQ == "password") {
        this.isShowpasQ = "text";
        this.showiconQ = close;
      } else {
        this.isShowpasQ = "password";
        this.showiconQ = check;
      }
    },

    // 提交重置密码
    fourStep() {
      if (!this.validatePassword(this.passForm.passWord)) {
        this.$message.error("密码只能由数字和字母组合，至少8个字符");
        return;
      }
      if (this.passForm.passWord != this.passForm.agaginpassword) {
        this.$message.error("两次密码输入不一致");
        return;
      }

      http
        .post("/umsSysUser/reset_password", {
          account: this.tel,
          ticket: this.ticket,
          password: this.passForm.passWord,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("修改成功");

            this.$emit("stopRegister");
          } else {
            this.$message.error(res.message);
          }
        });
    },
    // 验证密码
    validatePassword(password) {
      // 密码必须至少包含一个字母和一个数字，并且长度至少为8个字符
      const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
      return regex.test(password);
    },
    showPromptInfo(msg) {
      this.isShowPromptInfo = true;
      this.promptInfo = msg;

      this.promptTimer = setTimeout(() => {
        this.isShowPromptInfo = false;
      }, 2000);
    },
    setTimersecond(num) {
      this.timersecond = num;
      this.timer = setInterval(() => {
        this.timersecond--;
        if (this.timersecond === 0) {
          clearInterval(this.timer);
          localStorage.setItem("countdown", 0);
          this.isShowVertifyCode = true;
          this.isCodeDisabled = false;
        }
        localStorage.setItem("countdown", this.timersecond);
      }, 1000);
    },
    getVertifycode() {
      this.isShowVertifyCode = false;

      if (this.isCodeDisabled) {
        return;
      }

      this.isCodeDisabled = true;

      this.getCode();
    },
    clearInput(event) {
      if (event.srcElement.value.toString().length >= 1) {
        event.srcElement.value = "";
      }
    },
    goVertify(event) {
      let vertifyInputArray =
        this.$refs.vertifyWrapper.getElementsByClassName("login_input");

      for (let n1 = 0; n1 < vertifyInputArray.length; n1++) {
        if (event.srcElement === vertifyInputArray[n1]) {
          this.inputVertifyCode[n1] = vertifyInputArray[n1].value;

          if (n1 === vertifyInputArray.length - 1) {
            this.isInput = true;
            this.inputMobile = this.inputVertifyCode.join("");
          } else {
            this.isInput = false;
            if (vertifyInputArray[n1].value !== "") {
              vertifyInputArray[n1 + 1].focus();
            }
          }
        }
      }
    },

    // 处理电话号码
    handleTel(tel) {
      let result = tel.replace(tel.substring(3, 9), "******");
      return result;
    },

    // 返回上一层
    reback() {
      if (this.step == 1) {
        this.$emit("stopRegister");
        clearInterval(this.timer);
      } else {
        var value = this.step - 1;

        this.step = value;
      }
    },
  },
};
</script>

<style scoped>
.bg-white {
  width: 592px;
  height: 597px;
  box-sizing: border-box;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.head_navi {
  display: flex;
  position: absolute;
  top: 33px;
  left: 30px;
  cursor: pointer;
  align-items: center;
  color: #909090;
}
.navi_icon {
  width: 15px;
  height: 13px;
  margin-right: 9px;
}
.px-6 {
  padding: 43px 0 0 0;
  box-sizing: border-box;
}
.title {
  font-size: 30px;
  color: #1a1a1a;
}
.vertifycodeIcon {
  color: #e2041b !important;
  width: 2.5rem !important;
  height: 2.5rem !important;
}
.tips {
  color: #909090;
  margin: 10px 0 40px;
}
.tips span {
  color: #0058ff;
}
.login_input {
  text-align: center;
  line-height: 40px;
  height: 40px;
  width: 43px;
  color: #262626;
  font-size: 24px;
  border-radius: 2px;
  box-sizing: border-box;
  line-height: 1;
  color: #0058ff;
  border: solid 1px #d0d0d0;
  outline: none;
  margin-right: 8px;
  background-color: white;
}
.login_input:focus {
  border: solid 1px #0058ff;
}

.InfoWrapper {
  position: absolute;
  flex-direction: column;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 50;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.mainWrapper {
  margin: 0px 50px 0px 50px;
  padding: 18px 16px 16px 16px;
  text-align: center;
  background-color: #1a202c;
  opacity: 0.9;
  color: #fff;
  font-size: 14px;
  border-radius: 0.75rem;
}
.infoIcon {
  color: #fff !important;
  width: 2.5rem !important;
  height: 2.5rem !important;
}
.onget {
  font-size: 12px;
  cursor: pointer;
  color: #0058ff;
}
.mt-8 {
  margin-top: 10px;
}
.text-sm {
  font-size: 12px;
  color: #909090;
}
.fogetbto {
  margin-top: 194px;
}
.frames {
  padding: 43px 86px 0 43px;
  box-sizing: border-box;
}
.title {
  font-size: 30px;
  color: #1a1a1a;
  margin-bottom: 20px;
}
.title_s {
  font-size: 30px;
  color: #1a1a1a;
  margin-bottom: 10px;
}
.phone {
  width: 419px;
  height: 48px;
  margin-top: 20px;
}
.p_eamil {
  margin-top: 20px;
  width: 417px;
  position: relative;
  display: flex;
  padding-right: 15px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
}
.p_eamil_get {
  height: 20px;
  padding-left: 19px;
  box-sizing: border-box;
  border-left: 1px solid #c6c6c6;
  color: #0058ff;
  font-size: 14px;
  cursor: pointer;
}
.p_eamil_backwards {
  height: 20px;
  text-align: center;
  flex: 1;
  box-sizing: border-box;
  border-left: 1px solid #c6c6c6;
  color: #b2b2b2;
  font-size: 14px;
}
.invitebto_s {
  margin-top: 165px;
}
</style>
