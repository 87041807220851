<template>
  <div>
    <div class="batch_t">
      <div class="branch_l">
        <img
          src="@/assets/structure/close.png"
          class="batch_close"
          @click="close"
        />
        <div>加班</div>
      </div>
      <div class="branch_bto">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="switcOperation">确认</el-button>
      </div>
    </div>

    <div class="batch_c">
      <el-form
        ref="batch"
        label-position="top"
        label-width="80px"
        :model="formLabelAlign"
        :rules="rules"
      >
        <el-form-item label="员工" prop="ids">
          <div class="unableChange">{{ name.join(",") }}</div>
        </el-form-item>
        <el-form-item label="开始时间" prop="timeBegin">
          <el-date-picker
            v-model="formLabelAlign.timeBegin"
            @change="isWithintheshift"
            format="yyyy-MM-dd HH:mm"
            type="datetime"
            style="width: 100%"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间" prop="timeEnd">
          <el-date-picker
            v-model="formLabelAlign.timeEnd"
            @change="isWithintheshift"
            format="yyyy-MM-dd HH:mm"
            type="datetime"
            style="width: 100%"
          >
          </el-date-picker>
        </el-form-item>
        <div v-if="isError" style="color: red">{{ error }}</div>
        <el-form-item label="加班时长(小时)" prop="ids">
          <div class="unableChange">{{ difference }}</div>
        </el-form-item>
        <el-form-item label="加班事由" prop="description">
          <el-input
            type="textarea"
            :rows="5"
            v-model="formLabelAlign.description"
            maxlength="100"
            show-word-limit
          >
          </el-input>
        </el-form-item>
        <el-form-item label="审批人">
          <div class="unableChange">{{ approvedBy }}</div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import http from "../../../../utils/http";
export default {
  data() {
    return {
      formLabelAlign: {}, //提交的表单
      difference: "", //小时差
      handler: {}, //经办人
      error: "", //报错
      approvedBy: "", //审批人
      holidayList: [], //节假日列表
      clockData: {}, //每日的开始结束时间
      isError: false,
      rules: {
        ids: [
          {
            type: "array",
            required: true,
            message: "请选择至少一个用户",
            trigger: "blur",
          },
        ],
        timeBegin: [
          {
            type: "date",
            required: true,
            message: "请选择开始时间",
            trigger: "change",
          },
        ],
        timeEnd: [
          {
            type: "date",
            required: true,
            message: "请选择结束时间",
            trigger: "change",
          },
        ],
        description: [
          { required: true, message: "请输入加班事由", trigger: "blur" },
        ],
      },
      customerList: [],
    };
  },
  props: ["name", "id", "group"],
  created() {
    this.formLabelAlign.ids = this.id;

    var user = localStorage.getItem("userinfo");
    user = JSON.parse(user);
    this.formLabelAlign.userExec = user.id;
    this.approvedBy = user.name;

    this.getHoliday();
  },
  methods: {
    // 获取节假日
    getHoliday() {
      http
        .get("/bmsHoliday/list", {
          pageNum: 1,
          pageSize: 999,
        })
        .then((res) => {
          if (res.code == 200) {
            if (res.data.list.length > 0) {
              var arr = this.getAllDates(res.data.list);
              this.holidayList = arr;
            }
          }
        });
    },
    // 处理节假日日期
    getAllDates(ranges) {
      // 将日期字符串解析为 Date 对象
      const parseDate = (dateString) => new Date(dateString);

      // 生成从开始日期到结束日期的所有日期
      const getDatesInRange = (startDate, endDate) => {
        let dates = [];
        let currentDate = new Date(startDate);

        while (currentDate <= endDate) {
          dates.push(new Date(currentDate));
          currentDate.setDate(currentDate.getDate() + 1);
        }

        return dates;
      };

      // 存储所有日期的数组
      let allDates = [];

      ranges.forEach((range) => {
        let startDate = parseDate(range.dayBegin);
        let endDate = parseDate(range.dayEnd);
        let datesInRange = getDatesInRange(startDate, endDate);
        allDates = allDates.concat(datesInRange);
      });

      // 格式化日期为 YYYY-MM-DD
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // 月份从0开始计数，需要加1，并保证两位数
        const day = String(date.getDate()).padStart(2, "0"); // 获取日期，并保证两位数
        return `${year}-${month}-${day}`;
      };

      return allDates.map(formatDate);
    },

    // 关闭弹窗
    close() {
      this.$refs.batch.resetFields();
      this.$emit("close");
    },

    // 确认弹窗
    switcOperation() {
      this.$refs.batch.validate((valid) => {
        if (valid) {
          var value = JSON.stringify(this.formLabelAlign);
          value = JSON.parse(value);

          value.timeBegin = this.formatDate(value.timeBegin);
          value.timeEnd = this.formatDate(value.timeEnd);
          value.type = 0;
          value.status = 1;
          value.timeExec = this.getCurrentDate();

          if (this.isError) {
            return;
          }

          http.post("/bmsApply/add", value).then((res) => {
            if (res.code == 200) {
              this.$message.success("登记成功");
              this.close();
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          return false;
        }
      });
    },

    // 获取当前时间
    getCurrentDate() {
      var now = new Date();

      // 获取年份
      var year = now.getFullYear();

      // 获取月份（注意：月份是从0开始的，所以我们需要加1）
      var month = now.getMonth() + 1;
      month = month < 10 ? "0" + month : month; // 如果月份是一位数，前面补零

      // 获取日期
      var date = now.getDate();
      date = date < 10 ? "0" + date : date; // 如果日期是一位数，前面补零

      // 获取小时
      var hours = now.getHours();
      hours = hours < 10 ? "0" + hours : hours; // 如果小时是一位数，前面补零

      // 获取分钟
      var minutes = now.getMinutes();
      minutes = minutes < 10 ? "0" + minutes : minutes; // 如果分钟是一位数，前面补零
      // 秒
      var seconds = now.getSeconds();
      seconds = seconds < 10 ? "0" + seconds : seconds;

      // 将获取到的年月日时分拼接成字符串
      var currentDateTime =
        year +
        "-" +
        month +
        "-" +
        date +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds;
      return currentDateTime;
    },

    // 判断时间在不在班次内
    isWithintheshift() {
      if (this.formLabelAlign.timeBegin && this.formLabelAlign.timeEnd) {
        var beginTime = this.formatDate(this.formLabelAlign.timeBegin);
        var endTime = this.formatDate(this.formLabelAlign.timeEnd);
        if (endTime < beginTime) {
          this.error = "结束时间必须大于开始时间";
          this.isError = true;
          return;
        } else {
          this.isError = false;
        }

        http
          .get("/bmsAttendanceGroup/get", {
            id: this.group,
          })
          .then((res) => {
            if (res.code == 200) {
              var clockData = res.data.attendanceGroupConfigData.clockData;
              if (!clockData) {
                clockData = {
                  beginDaySecond: 14400,
                  endDaySecond: 100800,
                };
              }
              this.clockData = clockData;
              var beginDay = this.range(beginTime, clockData.endDaySecond);
              var endDay = this.range(endTime, clockData.endDaySecond);
              this.getRange(beginDay, endDay);
            } else {
              this.error = "该员工无任何考勤组,请检查后再试";
              this.isError = true;
              return;
            }
          });
      }
    },

    // 获取到配置再进行判断
    getRange(beginDay, endDay) {
      var beginTime = this.formatDate(this.formLabelAlign.timeBegin);
      var endTime = this.formatDate(this.formLabelAlign.timeEnd);
      let array = beginTime.split(" ");
      let array2 = endTime.split(" ");
      http
        .get("/bmsAttendanceRecord/search", {
          user: this.id[0],
          beginDay: beginDay,
          endDay: endDay,
          pageNum: 1,
          pageSize: 10,
        })
        .then((res) => {
          if (res.code == 200) {
            var arr = res.data.list;
            // arr.map((item) => {
            //   var isExist = this.holidayList.indexOf(item.dayString);
            //   if (item.bmsAttendanceGroupGetVO) {
            //     if (
            //       item.bmsAttendanceGroupGetVO.attendanceGroupConfigData
            //         .useHoliday
            //     ) {
            //       if (isExist) {
            //         item.arrange = -3;
            //       }
            //     }
            //   }
            // });
            if (arr.length > 0) {
              // 开始时间日期
              var begin = this.timeToSeconds(array[1]);
              // 结束时间日期
              var end = this.timeToSeconds(array2[1]);
              var firstAttendance = arr[0];
              var endAttendance = arr[arr.length - 1];

              if (begin <= this.clockData.beginDaySecond) {
                // 属于前一天
                console.log("属于前一天");
                if (firstAttendance.arrange > 0) {
                  var attendance = firstAttendance.bmsAttendanceArrangeGetVO;
                  var attendanceDate =
                    attendance.arrangeDataList[
                      attendance.arrangeDataList.length - 1
                    ].clockData;
                  // 属于前一天的加班
                  if (end > this.clockData.beginDaySecond) {
                    this.error = "结束时间不允许大于每日开始时间";
                    this.isError = true;
                    return;
                  } else {
                    this.timeDifference(beginTime, endTime);
                    this.isError = false;
                  }

                  begin = begin + 86400;

                  if (begin < attendanceDate.endDaySecond) {
                    this.error = "开始时间必须在班次结束之后";
                    this.isError = true;
                    return;
                  } else {
                    this.timeDifference(beginTime, endTime);
                    this.isError = false;
                  }
                } else {
                  this.timeDifference(beginTime, endTime);
                  this.isError = false;
                }
              } else {
                console.log("属于当天");
                if (endAttendance.arrange > 0) {
                  var attendance = endAttendance.bmsAttendanceArrangeGetVO;
                  var attendanceDate =
                    attendance.arrangeDataList[
                      attendance.arrangeDataList.length - 1
                    ].clockData;
                  //  属于当天加班
                  if (begin < attendanceDate.endDaySecond) {
                    this.error = "开始时间必须在班次结束之后";
                    this.isError = true;
                    return;
                  } else {
                    this.timeDifference(beginTime, endTime);
                    this.isError = false;
                  }
                } else {
                  this.timeDifference(beginTime, endTime);
                  this.isError = false;
                }

                if (array2[0] > array[0]) {
                  // 跨天加班
                  if (end > this.clockData.beginDaySecond) {
                    this.error = "结束时间必须小于每日结束时间";
                    this.isError = true;
                    return;
                  } else {
                    this.timeDifference(beginTime, endTime);
                    this.isError = false;
                  }

                  var allGreaterThanOne = arr.every(function (obj) {
                    return obj.arrange < 0;
                  });

                  if (allGreaterThanOne) {
                    this.timeDifference(beginTime, endTime);
                    this.isError = false;
                  } else {
                    this.error = "中间不能隔着工作日";
                    this.isError = true;
                    return;
                  }
                }
              }
            } else {
              this.timeDifference(beginTime, endTime);
              this.isError = false;
            }
          }
        });
    },

    // 判断是否是指定日期之前
    range(dateTimeStr, time) {
      if (time > 86400) {
        time = time - 86400;
      }

      var hours1 = Math.floor(time / 3600); // 计算小时数
      var minutes1 = Math.floor((time % 3600) / 60); // 计算剩余的分钟数

      // 创建一个Date对象
      const dateTime = new Date(dateTimeStr);

      // 提取小时和分钟
      const hours = dateTime.getHours();
      const minutes = dateTime.getMinutes();

      // 判断时间是否在凌晨4点15分之前
      if (hours < hours1 || (hours === hours1 && minutes <= minutes1)) {
        // 如果是，则将日期减一天
        dateTime.setDate(dateTime.getDate() - 1);
      }

      // 格式化日期为 'YYYY-MM-DD'
      const year = dateTime.getFullYear();
      const month = String(dateTime.getMonth() + 1).padStart(2, "0"); // 月份从0开始，所以需要+1
      const day = String(dateTime.getDate()).padStart(2, "0");

      // 返回格式化后的日期字符串
      return `${year}-${month}-${day}`;
    },

    // 标准时间转换成yyyy-mm-dd
    formatDate(standardTime) {
      const date = new Date(standardTime);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    // 时间转成秒来显示
    timeToSeconds(timeString) {
      const [hours, minutes] = timeString.split(":").map(Number);
      const seconds = hours * 3600 + minutes * 60;
      return seconds;
    },
    // 判断两个时间差多少
    timeDifference(date1, date2) {
      // 获取两个时间的时间戳（假设时间格式为 "YYYY-MM-DD HH:mm:ss"）
      var time1 = new Date(date1).getTime(); // 第一个时间
      var time2 = new Date(date2).getTime(); // 第二个时间

      var date = "";

      if (time2 < time1) {
        date = "0";
      } else {
        // 计算两个时间的时间戳差值（单位：毫秒）
        var timeDiff = Math.abs(time2 - time1);

        // 将毫秒转换为小时
        var hoursDiff = timeDiff / (1000 * 60 * 60);

        date = hoursDiff.toFixed(1);
      }

      this.difference = date;
    },
  },
};
</script>

<style scoped>
.branch_l {
  display: flex;
  align-items: center;
}
.batch_t {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
  box-sizing: border-box;
  padding-left: 20px;
  font-size: 18px;
  color: #1a1a1a;
  border-bottom: 1px solid #cfd4e8;
}
.batch_close {
  width: 24px;
  cursor: pointer;
  height: 24px;
  margin-right: 20px;
}
.batch_c {
  padding: 0 30px;
  box-sizing: border-box;
  margin-top: 40px;
  overflow-y: scroll;
}
.batch_c::-webkit-scrollbar {
  display: none;
}
.department_search_inp {
  width: 100%;
  border: 1px solid #dcdfe6;
}
::v-deep .depa .el-select-dropdown {
  top: 330px !important;
}
::v-deep .batch_c .el-autocomplete {
  width: 100%;
}
.unableChange {
  width: 413px;
  height: 40px;
  border: 1px solid #d0d0d0;
  padding-left: 17px;
  box-sizing: border-box;
  line-height: 40px;
  font-size: 16px;
  color: #1a1a1a;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
