<template>
  <div class="record common-style common-table_style">
    <div class="record_head">
      <span style="color: #b2b2b2">通行记录 /</span>
      <span style="color: #5c5c5c"> 在场人员</span>
    </div>
    <div class="record_wrapper">
      <div class="record_search_wrapper">
        <div class="record_search">
          <div class="record_search_t">
            <el-date-picker
              v-model="totaltime"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              @change="onSearch(1)"
              suffix-icon="el-icon-date"
              clearable
            >
              <i slot="suffix" class="el-icon-date"></i>
            </el-date-picker>
          </div>

          <div class="record_search_t">
            <div
              v-if="isFiltrate && Object.values(filtrateResult).length > 0"
              class="filtrate_wrapper"
            >
              <div class="filtrate_single">
                <div
                  class="filtrate_single_b"
                  v-if="filtrateResult.departmentIdList"
                >
                  <div>{{ formatRecord(filtrateName) }}</div>
                  <img
                    src="@/assets/structure/optionclose.png"
                    class="filtrate_icon"
                    @click="delectscreening(3)"
                  />
                </div>
                <div
                  class="filtrate_single_a"
                  v-if="
                    filtrateResult.peopleType == 0 ||
                    filtrateResult.peopleType == 1
                  "
                >
                  <div v-if="filtrateResult.peopleType == 0">员工</div>
                  <div v-else>访客</div>
                  <img
                    src="@/assets/structure/optionclose.png"
                    class="filtrate_icon"
                    @click="delectscreening(2)"
                  />
                </div>
              </div>
            </div>
            <div v-else @click="openChoose" class="device_search_h">
              <el-input placeholder="筛选" readonly width="200px">
                <img
                  slot="suffix"
                  src="@/assets//recordfilter.png"
                  class="re-icon-filter"
                />
                ></el-input
              >
            </div>
          </div>
          <div class="record_search_t record_search_item_n">
            <div class="approval_search">
              <el-input
                placeholder="搜索设备名称、SN序列号"
                v-model="passform.nameAndSNLike"
                @keyup.enter.native="onSearch(2)"
                class="department_search_inp"
              ></el-input>
              <img
                src="@/assets/structure/delete.png"
                class="searchicon"
                @click="clearup"
                v-if="searchPass"
              />
              <img
                src="@/assets/structure/search.png"
                class="searchicon"
                @click="onSearch(2)"
              />
            </div>
          </div>
          <el-button type="primary" @click="onSearch">查询</el-button>
        </div>
        <div class="record_export">
          <el-button @click="startDownload(tableSelection)">批量导出</el-button>
        </div>
      </div>
      <div class="record_table_wrapper">
        <el-table
          ref="multipleTable"
          :data="onList"
          :header-cell-style="{
            background: '#F5F6FA',
            color: '#5C5C5C',
          }"
          height="48rem"
          class="record_table common_text"
          @selection-change="handleSelectionChange"
        >
          <template slot="empty">
            <div>
              <img src="@/assets/nodata.png" class="nodata_common" />
              <div class="nodata_text">无数据</div>
            </div>
          </template>
          <el-table-column
            type="selection"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="peopleType"
            label="人员类型"
            header-align="left"
            align="left"
            width="120"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.peopleType == 0">员工</span>
              <span v-else>访客</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="jobNumber"
            label="工号"
            header-align="left"
            align="left"
            width="140"
            style="mso-number-format: '/@'"
          >
          </el-table-column>
          <el-table-column
            prop="name"
            label="姓名"
            header-align="left"
            align="left"
            width="140"
          >
          </el-table-column>
          <el-table-column
            prop="departmentName"
            label="部门"
            header-align="left"
            align="left"
            width="140"
          >
          </el-table-column>
          <el-table-column
            prop="arrivalTime"
            label="通行时间"
            header-align="left"
            align="left"
            width="190"
          >
          </el-table-column>
          <el-table-column
            prop="deviceName"
            label="设备名称"
            header-align="left"
            align="left"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="sn"
            label="SN序列号"
            header-align="left"
            align="left"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="direction"
            label="通行方向"
            header-align="left"
            align="left"
            width="150"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.direction == 0">进向</span>
              <span v-else-if="scope.row.direction == 1">出向</span>
              <span v-else>不限</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="category"
            label="设备种类"
            header-align="left"
            align="left"
            width="200"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.category == 0" class="record_type"
                >门禁</span
              >
              <span v-else class="record_type">考勤</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="recognizeType"
            label="识别方式"
            header-align="left"
            align="left"
            width="200"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.recognizeType == 0">人脸</span>
              <span v-else-if="scope.row.recognizeType == 1">指纹</span>
              <span v-else-if="scope.row.recognizeType == 2">密码</span>
              <span v-else-if="scope.row.recognizeType == 3">刷卡</span>
              <span v-else-if="scope.row.recognizeType == 4">扫码</span>
              <span v-else></span>
            </template>
          </el-table-column>

          <el-table-column
            prop="headUrl"
            label="实时照片"
            header-align="left"
            align="left"
            width="200"
          >
            <template slot-scope="scope">
              <img
                :src="scope.row.headUrl"
                class="record-img"
                @click="traUrl(scope.row.headUrl)"
              />
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div
        v-if="this.onList != 0"
        class="newpagination"
        style="padding-left: 20px; box-sizing: border-box"
      >
        <div class="newpagination_text">共 {{ pages.total }} 条数据</div>
        <el-pagination
          layout="prev, pager, next"
          :total="pages.total"
          :current-page="pages.pageNum"
          :page-size="50"
          @current-change="changePage"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 实时图片 -->
    <el-dialog
      :visible.sync="openImg"
      custom-class="img-dialog common-style"
      :close-on-click-modal="false"
    >
      <template slot="title">
        <div class="img-dialog_title">实时照片</div>
      </template>
      <img class="img-dialog-body" :src="bigImg" alt="" />
    </el-dialog>
    <!-- 筛选 -->
    <el-drawer
      size="473px"
      :visible.sync="dialogChoose"
      custom-class="drawer_device"
      :direction="direction"
      :showClose="false"
      :wrapperClosable="false"
      @close="cancelDevice"
      :key="cKey"
    >
      <span slot="title" class="dialog_title">
        <i
          class="el-icon-close"
          style="font-size: 24px"
          @click="cancelDevice"
        ></i>
        <span class="dialog_title_filter">筛选</span>

        <div class="dialog_confirm">
          <el-button
            class="add_confirm_f"
            @click="cancelDevice()"
            style="background: none"
            >取 消</el-button
          >
          <el-button type="primary" @click="chooseConfirm" class="add_confirm_f"
            >确 认</el-button
          >
        </div>
      </span>
      <el-form class="form_choose" :model="formScree" label-position="left">
        <el-form-item label="部门" class="common_select">
          <el-select
            v-model="formScree.name"
            class="common_select_s"
            multiple
            @change="selectChange"
          >
            <el-option
              :value="mineStatusValue"
              style="height: auto"
              class="treescree"
            >
              <el-tree
                :data="data"
                show-checkbox
                node-key="id"
                ref="eltree"
                highlight-current
                :props="defaultProps"
                @check-change="handleCheckChange"
              ></el-tree>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="人员属性">
          <el-select v-model="formScree.peopleType" class="common_select_s">
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>

<script>
import http from "../../utils/http";
import exprotExcel from "../../utils/exprotExcel";
export default {
  components: {},
  data() {
    return {
      cKey: 1,
      form: {},
      formScree: {},
      mineStatusValue: [],

      data: [],
      defaultProps: {
        children: "subDepartment",
        label: "departmentName",
      },
      chooseText: "", //筛选框
      onList: [],
      direction: "rtl",
      chooseKey: false,
      value: "",
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      openImg: false, //实时照片弹窗
      bigImg: "",
      dialogChoose: false, //筛选
      dialogRange: false, //选择人员范围
      passform: {},
      totaltime: null,
      options2: [
        {
          value: 0,
          label: "员工",
        },
        {
          value: 1,
          label: "访客",
        },
      ],
      value2: "",
      checkAll: false,
      checkedCities: [],
      checklist: [
        "上海",
        "北京",
        "广州",
        "深圳",
        "74125",
        "一二三",
        "南京",
        "重庆",
        "武汉",
      ],
      isIndeterminate: true,
      pickedList: [],
      pages: {}, //分页
      conditionObj: {}, //搜索条件
      tableSelection: [], //导出表格需要
      multipleSelection: [],
      tableColumn: [
        {
          title: "人员类型",
          dataIndex: "peopleType",
          id: 1,
        },
        {
          title: "工号",
          dataIndex: "jobNumber",
          id: 2,
        },
        {
          title: "姓名",
          dataIndex: "name",
          id: 3,
        },
        {
          title: "部门",
          dataIndex: "departmentName",
          id: 4,
        },
        {
          title: "通行时间",
          dataIndex: "arrivalTime",
          id: 5,
        },
        {
          title: "设备名称",
          dataIndex: "deviceName",
          id: 6,
        },
        {
          title: "SN序列号",
          dataIndex: "sn",
          id: 7,
        },
        {
          title: "通行方向",
          dataIndex: "direction",
          id: 8,
        },
        {
          title: "设备种类",
          dataIndex: "category",
          id: 9,
        },
        {
          title: "识别方式",
          dataIndex: "recognizeType",
          id: 10,
        },
      ],
      isFiltrate: true, //是否进行了筛选
      filtrateResult: {}, //筛选字段
      filtrateNum: 0, //筛选数量
      filtrateName: [],
      sidebarShowIcon: true, //图标显示时间
      prangge: {},
      searchPass: false,
    };
  },
  watch: {
    "passform.nameAndSNLike"(newName, oldName) {
      if (newName) {
        this.searchPass = true;
      } else {
        this.searchPass = false;
      }
    },
  },
  created() {
    this.getList({ pageNum: 1 });
    // 获取缓存部门列表
    var arr = localStorage.getItem("department");
    var ar = JSON.parse(arr);
    this.data = ar[0].subDepartment;
  },
  mounted() {},
  methods: {
    // 筛选员工
    dressing(value, b) {
      this.isFiltrate = true;
      this.filtrateResult = value;
      if (value.peopleType == undefined) this.$delete(value, "peopleType");
      if (!value.departmentIdList) this.$delete(value, "departmentIdList");
      this.filtrateName = b;
      // 清空输入框
      // this.totaltime = "";
      // this.passform = {};
      // value.pageNum = 1;
      // this.getList(value);
    },
    // 删除某个筛选(2是部门，3是员工)
    delectscreening(v) {
      switch (v) {
        case 2:
          this.$delete(this.filtrateResult, "peopleType");
          this.dressing(this.filtrateResult, this.filtrateName);
          break;
        case 3:
          this.$delete(this.filtrateResult, "departmentIdList");
          this.filtrateName = [];
          this.dressing(this.filtrateResult);
          break;
      }
    },
    getList(onsiteform) {
      // console.log(onsiteform)
      let nS = {};
      if (this.totaltime != null)
        nS = {
          startDate: this.totaltime[0],
          endDate: this.totaltime[1],
        };
      let aaa = { ...this.filtrateResult, ...this.passform, ...nS };

      http
        .post("/pass/present_list", { ...onsiteform, pageSize: 50, ...aaa })
        .then((res) => {
          if (res.code == 200) {
            this.pages = res.data;
            this.onList = res.data.list;
            this.onList.map((v) => {
              for (let key in v) {
                if (v[key] == null) v[key] = "--";
              }
            });
          } else {
            this.$message.error(res.message);
          }
        });
    },
    onSearch(num) {
      // let nS = {};
      // let a = this.totaltime;
      // switch (num) {
      //   case 1:
      //     let a = this.totaltime;
      //     if (a == null) {
      //       this.getList({ pageNum: 1 });
      //       return;
      //     }
      //     nS = {
      //       startDate: a[0],
      //       endDate: a[1],
      //     };
      //     this.passform = {};
      //     this.filtrateName = [];
      //     this.filtrateResult = {};
      //     break;
      //   case 2:
      //     nS = {
      //       nameAndSNLike: this.passform.nameAndSNLike,
      //     };
      //     this.filtrateName = [];
      //     this.filtrateResult = {};
      //     this.totaltime = "";
      //     break;
      // }
      // if (a == null) {
      //   nS = {
      //     ...this.passform,
      //   };
      // }
      // else {
      //   nS = {
      //     ...this.passform,
      //     startDate: a[0],
      //     endDate: a[1],
      //   };
      // }

      this.getList({ pageNum: 1 });
    },
    clearup() {
      this.passform.nameAndSNLike = "";
      // this.getList({
      //   pageNum: 1,
      // });
    },
    // 选择方法
    selectChange(e) {
      var arrNew = [];
      var dataLength = this.mineStatusValue.length;
      var eleng = e.length;
      for (let i = 0; i < dataLength; i++) {
        for (let j = 0; j < eleng; j++) {
          if (e[j] === this.mineStatusValue[i].label) {
            arrNew.push(this.mineStatusValue[i]);
          }
        }
      }
      this.$refs.eltree.setCheckedNodes(arrNew); //设置勾选的值
    },
    handleCheckChange() {
      //调用获取节点
      let res2 = this.getSimpleCheckedNodes(this.$refs.eltree.store);
      // let res = this.$refs.eltree.getCheckedNodes(false, true); //这里两个true，1. 是否只是叶子节点 2. 是否包含半选节点（就是使得选择的时候不包含父节点）
      let arrLabel = [];
      let arr = [];
      res2.forEach((item) => {
        arrLabel.push(item.departmentName);
        arr.push(item);
      });
      this.formScree.departmentIdList = [];
      arr.map((item) => {
        this.formScree.departmentIdList.push(item.departmentId);
      });
      this.mineStatusValue = arr;
      this.formScree.name = arrLabel;
    },
    // 获取父节点的方法
    getSimpleCheckedNodes(store) {
      const checkedNodes = [];
      const traverse = function (node) {
        const childNodes = node.root ? node.root.childNodes : node.childNodes;
        childNodes.forEach((child) => {
          if (child.checked) {
            checkedNodes.push(child.data);
          }
          if (child.indeterminate) {
            traverse(child);
          }
        });
      };
      traverse(store);
      return checkedNodes;
    },

    chooseConfirm() {
      if (this.formScree.name == "") {
        this.formScree.departmentIdList = null;
      }
      this.dressing(
        {
          departmentIdList: this.formScree.departmentIdList,
          peopleType: this.formScree.peopleType,
        },
        this.formScree.name
      );
      this.dialogChoose = false;
    },
    // 表格下载之前
    startDownload(arrlist) {
      // let target = event.target;
      // if (target.nodeName == "I" || target.nodeName == "SPAN") {
      //   target = event.target.parentNode;
      // }
      // target.blur();
      var arr = JSON.parse(JSON.stringify(arrlist));
      if (arr.length == 0) {
        this.$message.error("请先选择在场人员");
        return;
      }
      let arr2 = [];
      for (let key of this.tableColumn) {
        arr2.push(key.key);
      }
      if (arr) {
        if (arr.length === 0) {
          this.$message.info("暂无数据可导出！");
        } else {
          arr.map((v) => {
            if (v.category == 0) {
              v.category = "门禁";
            } else {
              v.category = "考勤";
            }
            if (v.direction == 0) {
              v.direction = "进向";
            } else if (v.direction == 1) {
              v.direction = "出向";
            } else {
              v.direction = "不限";
            }
            if (v.peopleType == 0) {
              v.peopleType = "员工 ";
            } else {
              v.peopleType = "访客";
            }
            switch (v.recognizeType) {
              case 0:
                v.recognizeType = "人脸";
                break;
              case 1:
                v.recognizeType = "指纹";
                break;
              case 2:
                v.recognizeType = "密码";
                break;
              case 3:
                v.recognizeType = "刷卡";
                break;
              case 4:
                v.recognizeType = "扫码";
                break;
            }
            if (v.arrivalTime) {
              v.arrivalTime = v.arrivalTime.split(" ").join("\xa0");
            }
            if (v.jobNumber) v.jobNumber = "\xa0" + v.jobNumber;

            for (let i = 0; i < arr2.length; i++) {
              if (v[arr2[i]] == undefined) {
                v[arr2[i]] = "";
              }
            }
            for (let key in v) {
              if (v[key] == null) v[key] = "";
            }
          });
        }
        exprotExcel.export(this.tableColumn, arr, "在场人员");
        this.$message.success("导出成功");
        this.$refs.multipleTable.clearSelection();
      }
    },
    // 表格多选
    handleSelectionChange(val) {
      this.multipleSelection = [];
      this.tableSelection = val;
      val.map((item) => {
        this.multipleSelection.push(item.id);
      });
    },
    cancelDevice() {
      this.dialogChoose = false;
      this.formScree = {};
    },
    formatRecord(list) {
      if (list.length == 1) return list[0];
      else return list.join("、");
    },
    openChoose() {
      this.cKey = Math.random();
      setTimeout(() => {
        this.dialogChoose = true;
      }, 200);
      if (this.$refs.eltree) this.$refs.eltree.setChecked([]);
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.checklist.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.checklist.length;
    },
    // 页数改变
    changePage(e) {
      this.getList({ pageNum: e });
    },
    //十进制数转为对应的开门方式
    resolve(num) {
      let a = parseInt(num.toString(2));
      let b = (Array(5).join(0) + a).slice(-5);
      let arr = [];
      b.split("").forEach((index, item) => {
        if (index == 1) {
          switch (item) {
            case 0:
              arr.push("人脸");
              break;
            case 1:
              arr.push("指纹");
              break;
            case 2:
              arr.push("密码");
              break;
            case 3:
              arr.push("刷卡");
              break;
            case 4:
              arr.push("扫码");
              break;
          }
        }
      });
      if (arr.length <= 0) return "-";
      if (arr.length == 1) return arr.join("");
      else return arr.join("/");
    },
    traUrl(url) {
      this.openImg = true;
      this.bigImg = url;
    },
  },
};
</script>
<style scoped>
.record {
  width: 100%;
  padding: 16px 20px;
  background: #f4f7fc;
  /* height: 100%; */
}

.record_head {
  font-size: 14px;
}

.record_wrapper {
  width: calc(100vw - 290px);
  height: 940px;
  background: #ffffff;
  box-shadow: 0px 1px 4px 1px rgba(21, 34, 50, 0.08);
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  margin-top: 16px;
}

.record_search_wrapper {
  display: flex;
  padding: 20px;
  width: auto;
  border-bottom: 1px solid rgba(207, 212, 232, 0.5);
}

.record_search {
  display: flex;
}

.record_search_t {
  margin-right: 10px;
  width: auto;
}

.approval_search {
  width: 243px;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #d0d0d0;
  display: flex;
  align-items: center;
  padding-right: 12px;
  box-sizing: border-box;
  justify-content: space-between;
}

.approval_search:focus-within {
  border-color: #0096fa;
}

.device_search_b {
  border: none;
  padding-left: 0;
  padding-right: 10px;
  background: none;
}

.device_search_b:hover {
  background: none;
}

.member_r_filter {
  display: flex;
  cursor: pointer;
  font-size: 15px;
  color: #b2b2b2;
  width: 98px;
  height: 36px;
  padding-left: 16px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #d0d0d0;
  align-items: center;
}

.member_r_filter_content {
  display: flex;
  cursor: pointer;
  font-size: 15px;
  color: #0058ff;
  width: 119px;
  height: 36px;
  padding-left: 16px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #0058ff;
  align-items: center;
}

.member_r_filter_icon {
  width: 18px;
  height: 18px;
  margin-left: 18px;
}

.filtrate_wrapper {
  min-width: 200px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #d0d0d0;
}

.filtrate_single_a {
  margin: 5px 3px 5px 5px;
  padding: 3px 6px;
  color: #0058ff;
  font-size: 12px;
  display: flex;
  align-items: center;
  background: #e0ecff;
  border-radius: 4px;
  width: 52px;
  height: 20px;
}

.filtrate_single {
  white-space: nowrap;
  display: flex;
}

.filtrate_single_b {
  margin: 5px 3px;
  padding: 3px 6px;
  color: #0058ff;
  font-size: 12px;
  display: flex;
  align-items: center;
  background: #e0ecff;
  border-radius: 4px;
  max-width: 190px;
  height: 20px;
}

.filtrate_single_b div {
  overflow: hidden;
  text-overflow: ellipsis;
}

.filtrate_icon {
  margin-left: 6px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

::v-deep .device_search_h .el-input {
  width: 200px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #d0d0d0;
}

::v-deep .device_search_h .el-input__inner {
  border: none;
  height: 36px;
}

::v-deep .record_search .el-range-editor .el-range-input::placeholder {
  font-size: 15px;
  font-weight: 400;
  color: #b2b2b2;
}

.record_export {
  margin-left: auto;
}

.record_table_wrapper {
  padding: 20px 18px 20px 20px;
  width: calc(100vw - 330px);
  font-size: 16px;
}

.record_table {
  white-space: nowrap;
  font-size: 16px;
}

.record_type {
  display: inline-block;
  width: 48px;
  height: 26px;
  line-height: 26px;
  background: #e0ecff;
  opacity: 1;
  text-align: center;
  color: #0058ff;
}

.re-icon-filter {
  width: 18px;
  height: 18px;
  margin: 8px 5px 0 0;
}

.record-img {
  width: 44px;
  height: 42px;
}

.img-dialog_title {
  font-size: 18px;
  font-weight: 400;
  color: #1a1a1a;
}

::v-deep .img-dialog {
  width: 540px;
  /* height: 614px; */
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
}

::v-deep .img-dialog .el-dialog__body {
  text-align: center;
}

.img-dialog-body {
  width: 380px;
  height: 420px;
  margin-top: 60px;
  margin-bottom: 30px;
}

.dialog_title {
  display: flex;
  align-items: center;
}

.dialog_title_filter {
  font-size: 18px;
  margin-left: 20px;
  color: #1a1a1a;
}

.add_confirm_f {
  background: #0058ff;
  font-size: 15px;
  width: 100px;
  height: 40px;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
}

.common_select_s {
  width: 413px;
}

.dialog_confirm {
  margin: 0 0 0 auto;
}

.add_confirm {
  background: #0058ff;
  font-size: 15px;
  width: 120px;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
}

.dialog-range-body {
  height: 583px;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #d0d0d0;
  margin: 0 10px;
  display: flex;
}

.dialog-range-left {
  width: 50%;
  border-right: 1px solid #d0d0d0;
  padding: 20px;
}

.range-left-all {
  padding: 20px 0;
}

.dialog-range-right {
  padding: 20px;
  width: 50%;
}

.right-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.right-list div {
  width: 79px;
  height: 32px;
  background: #e0ecff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  margin-right: 15px;
  margin-top: 15px;
  font-size: 15px;
  color: #0058ff;
  text-align: center;
  line-height: 32px;
}

.icon-close {
  background: #0058ff;
  color: #e0ecff;
  border-radius: 10px;
}

.newpagination {
  display: flex;
  margin-top: 0px;
  justify-content: space-between;
}

.newpagination_text {
  font-size: 16px;
  color: #5c5c5c;
}

::v-deep .newpagination .el-pagination {
  margin: 0 20px 30px auto;
}

::v-deep .el-tree-node__content {
  height: 50px;
}

::v-deep .el-tag.el-tag--info .el-tag__close {
  background-color: #0058ff;
  color: #e0ecff;
}

::v-deep .el-tag {
  width: auto;
  background-color: #e0ecff !important;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  min-width: 74px;
}

/* ::v-deep .common_text .el-table__empty-block {
  text-align: left;
} */
::v-deep.el-table .el-table__body td .cell:empty::after,
.el-table .el-table__body td .cell > span:empty::after,
.el-table .el-table__body td .cell .approval:empty::after,
.el-table .el-table__body td .cell div:empty::after,
.el-table .el-table__body td .cell .line-limit-length:empty::after {
  content: "--";
}

::v-deep .el-date-editor {
  width: 283px;
  height: 38px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #d0d0d0;
}

::v-deep .record_search .el-button--primary {
  height: 38px;
  line-height: 38px;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: 20px;
}
</style>