import { render, staticRenderFns } from "./distribute.vue?vue&type=template&id=7aff05dd&scoped=true"
import script from "./distribute.vue?vue&type=script&lang=js"
export * from "./distribute.vue?vue&type=script&lang=js"
import style0 from "./distribute.vue?vue&type=style&index=0&id=7aff05dd&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7aff05dd",
  null
  
)

export default component.exports