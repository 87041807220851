<template>
  <div>
    <div class="batch_t">
      <div class="branch_l">
        <img
          src="@/assets/structure/close.png"
          class="batch_close"
          @click="close"
        />
        <div>添加设备管理员</div>
      </div>
      <div class="branch_bto">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="switcOperation">确认</el-button>
      </div>
    </div>

    <div class="batch_c">
      <el-form
        ref="batch"
        label-position="top"
        label-width="80px"
        :model="formLabelAlign"
        :rules="rules"
      >
        <el-form-item label="设备管理员" prop="parentDepartment">
          <el-input
            v-model="formLabelAlign.admin"
            placeholder="搜索人员姓名、员工工号"
          ></el-input>
        </el-form-item>
        <el-form-item label="设备管理员权限" prop="departmentName">
          <el-select v-model="formLabelAlign.type" style="width: 100%">
            <el-option
              v-for="item in roleList"
              :key="item.index"
              :label="item.name"
              :value="item.index"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import http from "../../../utils/http";
export default {
  data() {
    return {
      title: "新建部门",
      searchData: [],
      loading: false,
      name: "", //部门负责人名字
      formLabelAlign: {
        parentDepartment: 0,
        departmentLeader: null,
      }, //提交的表单
      rules: {
        parentDepartment: [
          { required: true, message: "请输入员工名称", trigger: "blur" },
        ],
        departmentName: [
          { required: true, message: "请选择权限", trigger: 'change' },
        ],
      },
      roleList: [
        {
          name: "超级管理员",
          index: 1,
        },
        {
          name: "管理员",
          index: 2,
        },
      ], //离职类型
      customerList: [],
      defaultProps: {
        children: "subDepartment",
        label: "departmentName",
      },
      selectTreeName: "",
      selectTree: "",
    };
  },
  methods: {
    // 关闭弹窗
    close() {
      this.$refs.batch.resetFields();
      this.$emit("close");
    },

    // 确认弹窗
    comfin() {
      this.$refs.batch.resetFields();
      this.$emit("comfin");
    },

    // 选择上级部门点击方法
    addAdminHandleNodeClick(e) {
      this.selectTreeName = e.departmentName;
      this.formLabelAlign.parentDepartment = e.departmentId;
      this.$refs.multiSelect.blur();
    },

    // 新建还是编辑
    switcOperation() {
      if (this.type == 3) {
        this.editBranch();
      } else {
        this.createBranch();
      }
    },

    // 新建部门
    createBranch() {
      this.$refs["batch"].validate((valid) => {
        if (valid) {
          http.post("/department/add", this.formLabelAlign).then((res) => {
            if (res.code == 200) {
              this.$message.success("添加成功");
              this.comfin();
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },

    // 编辑部门
    editBranch() {
      http.post("/department/update", this.formLabelAlign).then((res) => {
        if (res.code == 200) {
          this.$message.success("编辑成功");
          this.comfin();
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 搜索员工
    querySearchAsync(queryString, cb) {
      http
        .get("/user/list", {
          content: queryString,
          pageNum: 1,
          pageSize: 1000,
          type: 1,
        })
        .then((res) => {
          if (res.code == 200) {
            var nameAndId = [];
            if (res.data.list.length > 0) {
              res.data.list.map((item) => {
                nameAndId.push({
                  id: item.id,
                  value: item.name,
                });
              });
            }
            setTimeout(() => {
              cb(nameAndId);
            }, 1000);
          }
        });
    },

    // 选择员工
    handleSelect(item) {
      if (item.id) {
        this.formLabelAlign.departmentLeader = item.id;
      }
    },
  },
};
</script>

<style scoped>
.branch_l {
  display: flex;
  align-items: center;
}
.batch_t {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
  box-sizing: border-box;
  padding-left: 20px;
  font-size: 18px;
  color: #1a1a1a;
  border-bottom: 1px solid #cfd4e8;
}
.batch_close {
  width: 24px;
  cursor: pointer;
  height: 24px;
  margin-right: 20px;
}
.batch_c {
  padding: 0 30px;
  box-sizing: border-box;
  margin-top: 40px;
}
.department_search_inp {
  width: 100%;
  border: 1px solid #dcdfe6;
}
::v-deep .depa .el-select-dropdown {
  top: 330px !important;
}
::v-deep .batch_c .el-autocomplete {
  width: 100%;
}
</style>
