<template>
  <div v-if="isshow">
    <div class="reception" v-if="isnormal">
      <div class="reception_back"></div>
      <div class="approve" v-if="visitInfo.status == 0">
        <img src="@/assets/invite/progress.png" class="approve_icon" />
        <div class="approve_text">正在审核中，请耐心等待</div>
      </div>
      <div class="approve" v-if="visitInfo.status == 2">
        <img src="@/assets/invite/refuse.png" class="approve_icon" />
        <div class="approve_text">审核拒绝，如有疑问请联系邀请人</div>
      </div>
      <div class="approve" v-if="visitInfo.status == 1">
        <img :src="qrCodeDataUrl" class="approve_icon" />
        <div class="approve_text">访客通行码</div>
      </div>
      <div>
        <div class="fillin">
          <img src="@/assets/invite/img6.png" class="fillin_icon" />
          <div class="fillin_right">
            <div class="fillin_text">到访公司</div>
            <div class="fillin_text2">{{ visitInfo.companyName }}</div>
          </div>
        </div>
        <div class="fillin">
          <img src="@/assets/invite/img8.png" class="fillin_icon" />
          <div class="fillin_right">
            <div class="fillin_text">到访地点</div>
            <div class="fillin_text2" v-if="visitInfo.location">{{ visitInfo.location }}</div>
            <div class="fillin_text2" v-else>- -</div>
          </div>
        </div>
        <div class="fillin">
          <img src="@/assets/invite/img2.png" class="fillin_icon" />
          <div class="fillin_right">
            <div class="fillin_text">邀请人</div>
            <div class="fillin_text2" v-if="visitInfo.inviteType == 0">
              前台邀请
            </div>
            <div class="fillin_text2" v-if="visitInfo.inviteType == 1">
              {{ visitInfo.invitorName }}
            </div>
            <div class="fillin_text2" v-if="visitInfo.inviteType == 2">
              前台填写
            </div>
          </div>
        </div>
        <div class="fillin">
          <img src="@/assets/invite/img5.png" class="fillin_icon" />
          <div class="fillin_right">
            <div class="fillin_text">到访事由</div>
            <div class="fillin_text2" v-if="visitInfo.reason">{{ visitInfo.reason }}</div>
            <div class="fillin_text2" v-else>- -</div>
          </div>
        </div>
        <div class="fillin">
          <img src="@/assets/invite/img9.png" class="fillin_icon" />
          <div class="fillin_right">
            <div class="fillin_text">到访日期</div>
            <div>
              <div class="fillin_text2">{{ visitInfo.beginTime }}</div>
              <div class="fillin_text2">{{ visitInfo.endTime }}</div>
            </div>
          </div>
        </div>
        <div class="reception_tip">
          <div class="reception_tips" v-if="visitInfo.status == 0">
            管理员审核中，审核完成后将以短信的形式通知您
          </div>
          <div class="reception_tips" v-if="visitInfo.status == 1">
            如遇异常情况，可联系邀请人
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="fillin_fail">
        <img src="@/assets/invite/fail.png" class="fillin_fail_icon" />
        <div>{{ failMessage }}</div>
      </div>
    </div>
  </div>
  <div v-else></div>
</template>

<script>
import http from "../../utils/http";
import QRCode from "qrcode";
import { Loading } from "element-ui";
export default {
  data() {
    return {
      visitInfo: {}, //访客信息
      failMessage: "", //错误信息
      isnormal: true, //正常显示
      isshow:false,
      load: null,
      qrCodeDataUrl: "", //二维码
    };
  },

  created() {
    var code = this.$route.query.visit_check;
    this.getVisitinfo(code);

    this.load = Loading.service({ fullscreen: true });
  },

  methods: {
    // 获取信息
    getVisitinfo(code) {
      http
        .get("/visit/get", {
          visitCheck: code,
        })
        .then((res) => {
          this.isshow = true
          this.load.close();
          if (res.code == 200) {
            this.visitInfo = res.data;
            if (res.data.status == 1) {
              this.generateQRCode(res.data.qrContent);
            }
          } else {
            this.isnormal = false;
            this.failMessage = res.message;
          }
        });
    },

    // 生成二维码
    async generateQRCode(url) {
      const data = url; // 替换为你想要生成二维码的数据
      try {
        this.qrCodeDataUrl = await QRCode.toDataURL(data);
      } catch (error) {
        console.error("生成二维码失败:", error);
      }
    },
  },
};
</script>

<style scoped>
.title {
  width: 100%;
  height: 46px;
  color: #1a1a1a;
  font-size: 16px;
  text-align: center;
  line-height: 46px;
  background: white;
}
.fillin {
  width: 100%;
  height: 52px;
  display: flex;
  background: white;
  align-items: center;
  justify-content: flex-end;
}
.fillin_icon {
  width: 19px;
  height: 19px;
  margin-right: 6px;
}
.fillin_right {
  width: 348px;
  display: flex;
  color: #1a1a1a;
  align-items: center;
  justify-content: space-between;
  padding-right: 16px;
  box-sizing: border-box;
  height: 100%;
  margin-left: 6px;
  border-bottom: 1px solid #e5e5e5;
}
.fillin_text {
  font-size: 16px;
}
.reception_back {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
  background: #f7f7f7;
}
.reception_tip {
  margin-top: 14px;
}
.reception_tips {
  padding: 0 16px;
  box-sizing: border-box;
  font-size: 13px;
  color: #767676;
}
.fillin_text2 {
  font-size: 14px;
  color: #1a1a1a;
}
.approve {
  width: 100%;
  height: 210px;
  background: white;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.approve_icon {
  width: 118px;
  height: 118px;
}
.approve_text {
  margin-top: 21px;
  font-size: 14px;
  color: #1a1a1a;
}
.fillin_fail_icon {
  width: 23.4375rem;
  height: 18.75rem;
}
.fillin_fail {
  font-size: 1rem;
  color: #000;
  text-align: center;
}
</style>
