<template>
  <div class="method common-style">
    <div class="method_head">
      <span style="color: #b2b2b2">系统设置 /</span>
      <span style="color: #5c5c5c"> 登录方式</span>
    </div>
    <div class="method_wrapper">
      <div class="method_search_wrapper">
        <div class="method_search">登录方式</div>
      </div>
      <el-form
        class="form_method"
        :model="methodForm"
        label-position="left"
        label-width="70px"
      >
        <div class="form_method-tip">
          <span>用于登录此帐号和身份验证的手机号和邮箱</span>
        </div>
        <el-form-item label="手机号" style="font-size: 20px">
          <span v-if="!mPhone == ''" class="form_method_p">{{ mPhone }}</span>
          <span
            v-else
            @click="bindEmail(1)"
            class="form_method_p"
            style="color: #0058ff"
            >去绑定</span
          >
        </el-form-item>
        <el-form-item label="邮箱">
          <span
            v-if="!mEmail == ''"
            class="form_method_p"
            @click="mailboxpostbox"
            >{{ mEmail }}</span
          >
          <span
            v-else
            @click="mailboxpostbox"
            class="form_method_p"
            style="color: #0058ff"
            >去绑定</span
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="filing">
      CopyRight@2023广州巨源信息技术有限公司-<a
        href="https://beian.miit.gov.cn/"
        >粤ICP备2023127373号-4</a
      >
    </div>
    <!-- 邮箱验证 -->
    <el-dialog
      width="539px"
      height="453px"
      custom-class="dialog-email-two"
      :visible.sync="emailTwo"
      :close-on-click-modal="false"
    >
      <div class="email_two_body">
        <span class="verify_text">绑定登录邮箱</span>
        <p class="verify_body_text">请添加用于登录此帐号的邮箱</p>
        <el-input v-model="email" placeholder="请输入邮箱"></el-input>
        <p class="verify-check-code_tip">完成绑定后，你可使用邮箱进行登录</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          :loading="issendEmail"
          class="email_two_confirm"
          @click="emailNextTwo"
          >下一步</el-button
        >
      </span>
    </el-dialog>
    <!-- 邮箱验证3 -->
    <el-dialog
      width="539px"
      height="453px"
      custom-class="dialog-email-two common-style"
      :visible.sync="emailThree"
      :close-on-click-modal="false"
      append-to-body
    >
      <div class="email_two_body">
        <span class="verify_text">输入邮箱验证码</span>
        <p class="verify_body_text">
          验证码已发送至：<span style="color: #0058ff">{{ email }}</span>
        </p>
        <Verify ref="getCode2"></Verify>

        <el-link
          :disabled="issendEmail"
          v-if="codeText2 == '重新发送'"
          class="verify-check-code_tip"
          style="color: #0058ff; cursor: pointer"
          @click="emailNextTwo"
          >{{ codeText2 }}</el-link
        >

        <p v-else class="verify-check-code_tip" v-html="codeText2"></p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          class="email_two_confirm"
          @click="emailNextThree"
          >确 认</el-button
        >
      </span>
    </el-dialog>
    <!-- 绑定手机 -->
    <el-dialog
      width="539px"
      height="453px"
      custom-class="dialog_verify"
      :visible.sync="phoneOne"
      :close-on-click-modal="false"
      append-to-body
    >
      <div class="verify-body">
        <span class="verify_text">输入邮箱验证码</span>
        <p class="verify_body_text">
          为了你的账号安全，需完成身份验证，请输入发送至<span
            style="color: #0058ff"
            >{{ mEmail | filterTel }}</span
          >邮箱的6位验证码，有效期5分钟
        </p>
        <Verify ref="getPhone"></Verify>
        <p
          v-if="codeText3 == '重新发送'"
          class="verify-check-code_tip"
          style="color: #0058ff; cursor: pointer"
          @click="bindEmail(6)"
        >
          {{ codeText3 }}
        </p>
        <p v-else class="verify-check-code_tip" v-html="codeText3"></p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          class="verify_confirm"
          style="background: none; margin-right: 20px"
          @click="phoneOne = false"
          >取 消</el-button
        >
        <el-button type="primary" class="verify_confirm" @click="emailNext(1)"
          >下一步</el-button
        >
      </span>
    </el-dialog>
    <!-- 绑定手机2 -->
    <el-dialog
      width="539px"
      height="453px"
      custom-class="dialog-email-two"
      :visible.sync="phoneTwo"
      :close-on-click-modal="false"
    >
      <div @click="returnPre3" style="cursor: pointer">
        <i class="el-icon-back icon_back"></i> 返回
      </div>
      <div class="email_two_body">
        <span class="verify_text">绑定登录手机号</span>
        <p class="verify_body_text">请添加用于登录此帐号的手机号</p>
        <el-input v-model="ePhone" placeholder="请输入手机号"></el-input>
        <p class="verify-check-code_tip">完成绑定后，你可使用手机号进行登录</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          class="email_two_confirm"
          @click="phoneNextTwo"
          >下一步</el-button
        >
      </span>
    </el-dialog>
    <!--绑定手机3 -->
    <el-dialog
      width="539px"
      height="453px"
      custom-class="dialog-email-two"
      :visible.sync="phoneThree"
      :close-on-click-modal="false"
    >
      <span @click="returnPre4" style="cursor: pointer"
        ><i class="el-icon-back icon_back"></i> 返回</span
      >
      <div class="email_two_body">
        <span class="verify_text">验证手机</span>
        <p class="verify_body_text">验证码已发送至：{{ ePhone }}</p>
        <Verify ref="getPhone2"></Verify>
        <p
          v-if="codeText4 == '重新发送'"
          @click="bindEmail(4)"
          class="verify-check-code_tip"
          style="color: #0058ff; cursor: pointer"
        >
          {{ codeText4 }}
        </p>
        <p v-else class="verify-check-code_tip" v-html="codeText4"></p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          class="email_two_confirm"
          @click="phoneNextThree"
          >确 认</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import http from "../../utils/http";
import Verify from "./verify.vue";

export default {
  components: { Verify },
  data() {
    return {
      mPhone: "",
      mEmail: "",
      ticket: null, //凭证
      emailOne: false, //邮箱验证
      emailTwo: false, //邮箱验证2
      emailThree: false, //邮箱验证3
      phoneOne: false, //绑定手机
      phoneTwo: false, //绑定手机2
      phoneThree: false, //绑定手机3
      methodForm: {},
      // count: 60,
      timer: null, // 倒计时控制
      timer2: null,
      timer3: null,
      timer4: null,
      codeText: "", // 发送按钮文字
      codeText2: "",
      codeText3: "",
      codeText4: "",
      eCode1: "",
      eCode2: "",
      email: "", //要绑定的邮箱
      issendEmail: false, //是否发送验证码
      ePhone: "", //要绑定的手机号
      canClick: true, //节流
      canClick3: true,
      canClick2: true,
      canClick4: true,
    };
  },
  filters: {
    //加密手机号
    filterTel(val) {
      if (val) {
        return val.substring(3, 0) + "******" + val.substring(9);
      }
    },
  },
  created() {
    let otherData = JSON.parse(localStorage.getItem("other"));
    this.getInfo(otherData);
  },
  methods: {
    getInfo(data) {
      if (data.phone) this.mPhone = data.phone;
      if (data.email) this.mEmail = data.email;
    },
    // 获取验证码
    bindEmail(a) {
      if (this.$refs.getCode) {
        this.$refs.getCode.aCheckCodeInput = ["", "", "", "", "", ""];
      }
      if (this.$refs.getCode2) {
        this.$refs.getCode2.aCheckCodeInput = ["", "", "", "", "", ""];
      }
      if (this.$refs.getPhone) {
        this.$refs.getPhone.aCheckCodeInput = ["", "", "", "", "", ""];
      }
      if (this.$refs.getPhone2) {
        this.$refs.getPhone2.aCheckCodeInput = ["", "", "", "", "", ""];
      }
      switch (a) {
        case 0:
          this.emailTwo = true;
          this.email = "";
          if (this.timer) return;
          break;
        case 1:
          this.phoneOne = true;
          var b = this.mEmail;
          this.ePhone = "";
          if (this.timer3) return;
          break;
        case 2:
          this.emailOne = true;
          var b = this.mPhone;
          break;
        case 3:
          var b = this.email;
          if (this.timer2) return;
          break;
        case 4:
          var b = this.ePhone;
          if (this.timer4) return;

          break;
        case 5:
          var b = this.mPhone;
          if (this.timer) return;

          break;
        case 6:
          var b = this.mEmail;
          if (this.timer3) return;

          break;
      }
      this.sendCode(b, a);
    },
    // 绑定邮箱
    mailboxpostbox() {
      this.emailTwo = true;
      this.email = "";
    },
    // 判断是否是邮箱
    IsEmail(str) {
      var reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return reg.test(str);
    },
    sendCode(b, a) {
      if (a == 0 || a == 5) {
        if (!this.canClick) return;
        this.canClick = false;
      }
      if (a == 1 || a == 6) {
        if (!this.canClick2) return;
        this.canClick2 = false;
      }
      if (a == 3) {
        if (!this.canClick3) return;
        this.canClick3 = false;
      }
      if (a == 4) {
        if (!this.canClick4) return;
        this.canClick4 = false;
      }
      http
        .get("/umsSysUser/code_get", {
          phone: b,
          getCodeType: "ACCOUNT_BIND",
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("已发送验证码");
            if (a == 0 || a == 5) {
              const times = 60; // 倒计时时间
              if (!this.timer) {
                let count = times;
                this.codeText = `<span style="color:#0058FF">${count}</span>s后可重新获取验证码`;
                this.timer = setInterval(() => {
                  if (count > 0 && count <= times) {
                    count--;
                    this.codeText = `<span style="color:#0058FF">${count}</span>s后可重新获取验证码`;
                  } else {
                    clearInterval(this.timer);
                    this.codeText = "重新发送";
                    this.timer = null;
                    this.canClick = true;
                  }
                }, 1000);
              }
            }
            if (a == 1 || a == 6) {
              const times = 60; // 倒计时时间
              if (!this.timer3) {
                let count = times;
                this.codeText3 = `<span style="color:#0058FF">${count}</span>s后可重新获取验证码`;
                this.timer3 = setInterval(() => {
                  if (count > 0 && count <= times) {
                    count--;
                    this.codeText3 = `<span style="color:#0058FF">${count}</span>s后可重新获取验证码`;
                  } else {
                    clearInterval(this.timer3);
                    this.codeText3 = "重新发送";
                    this.timer3 = null;
                    this.canClick2 = true;
                  }
                }, 1000);
              }
            }
            if (a == 3) {
              const times = 60; // 倒计时时间
              if (!this.timer2) {
                let count = times;
                this.codeText2 = `<span style="color:#0058FF">${count}</span>s后可重新获取验证码`;
                this.timer2 = setInterval(() => {
                  if (count > 0 && count <= times) {
                    count--;
                    this.codeText2 = `<span style="color:#0058FF">${count}</span>s后可重新获取验证码`;
                  } else {
                    clearInterval(this.timer2);
                    this.codeText2 = "重新发送";
                    this.timer2 = null;
                    this.canClick3 = true;
                  }
                }, 1000);
              }
            }
            if (a == 4) {
              const times = 60; // 倒计时时间
              if (!this.timer4) {
                let count = times;
                this.codeText4 = `<span style="color:#0058FF">${count}</span>s后可重新获取验证码`;
                this.timer4 = setInterval(() => {
                  if (count > 0 && count <= times) {
                    count--;
                    this.codeText4 = `<span style="color:#0058FF">${count}</span>s后可重新获取验证码`;
                  } else {
                    clearInterval(this.timer4);
                    this.codeText4 = "重新发送";
                    this.timer4 = null;
                    this.canClick4 = true;
                  }
                }, 1000);
              }
            }
          } else {
            if (a == 0 || a == 5) {
              this.codeText = "重新发送";
              this.canClick = true;
            }
            if (a == 1 || a == 6) {
              this.codeText3 = "重新发送";
              this.canClick2 = true;
            }
            if (a == 3) {
              this.codeText2 = "重新发送";
              this.canClick3 = true;
            }
            if (a == 4) {
              this.codeText4 = "重新发送";
              this.canClick4 = true;
            }
            this.$message.error(res.message);
          }
        });
    },
    emailNext(a) {
      if (a == 0) {
        var acc = this.mPhone;
        var check_code = this.$refs.getCode.aCheckCodeInput.join("");
        this.eCode1 = check_code;
      } else {
        var acc = this.mEmail;
        var check_code = this.$refs.getPhone.aCheckCodeInput.join("");
        this.eCode2 = check_code;
      }
      http
        .post("/umsSysUser/code_verify", {
          phone: acc,
          code: check_code,
          getCodeType: "ACCOUNT_BIND",
        })
        .then((res) => {
          if (res.code == 200) {
            this.ticket = res.data.actionTicket;
            if (a == 0) {
              this.emailOne = false;
              this.emailTwo = true;
            } else {
              this.phoneOne = false;
              this.phoneTwo = true;
            }
          } else {
            this.$message.error(res.message);
          }
        });
    },
    emailNextTwo() {
      if (!this.email) {
        this.$message.error("请先输入邮箱");
        return;
      }
      if (!this.IsEmail(this.email)) {
        this.$message.error("请输入正确的邮箱");
        return;
      }

      this.issendEmail = true;
      http
        .get("/umsSysUser/code_get", {
          email: this.email,
          getCodeType: "ACCOUNT_BIND",
        })
        .then((res) => {
          if (res.code == 200) {
            this.issendEmail = false;
            this.emailTwo = false;
            this.emailThree = true;
            this.$message.success("已发送验证码");
            const times = 60; // 倒计时时间
            if (!this.timer2) {
              let count = times;
              this.codeText2 = `<span style="color:#0058FF">${count}</span>s后可重新获取验证码`;
              this.timer2 = setInterval(() => {
                if (count > 0 && count <= times) {
                  count--;
                  this.codeText2 = `<span style="color:#0058FF">${count}</span>s后可重新获取验证码`;
                } else {
                  clearInterval(this.timer2);
                  this.codeText2 = "重新发送";
                  this.timer2 = null;
                  this.canClick3 = true;
                }
              }, 1000);
            }
          } else {
            this.$message.error(res.message);
          }
        });
    },
    emailNextThree() {
      let check_e_code = this.$refs.getCode2.aCheckCodeInput.join("");
      if (check_e_code.length != 6) {
        return;
      }
      http
        .post("/umsSysUser/code_verify", {
          email: this.email,
          code: check_e_code,
          getCodeType: "ACCOUNT_BIND",
        })
        .then((res) => {
          if (res.code == 200) {
            http
              .post("/umsSysUser/bind", {
                ticket: res.data.actionTicket,
              })
              .then((res2) => {
                if (res2.code == 200) {
                  this.$message.success("绑定成功");
                  let user = JSON.parse(localStorage.getItem("userinfo"));
                  user.email = this.email;
                  localStorage.setItem("userinfo", JSON.stringify(user));
                  this.getInfo(user);
                  this.emailThree = false;
                } else {
                  this.$message.error(res2.message);
                }
              });
          } else {
            this.$message.error(res.message);
          }
        });
    },
    phoneNextTwo() {
      http
        .post("/umsSysUser/bind", {
          ticket: this.ticket,
        })
        .then((res) => {
          if (res.code == 200) {
            this.phoneTwo = false;
            this.phoneThree = true;
            if (this.timer4) return;
            if (!this.canClick4) return;
            this.canClick4 = false;
            http
              .get("/umsSysUser/code_get", {
                phone: this.ePhone,
                getCodeType: "ACCOUNT_BIND",
              })
              .then((res) => {
                if (res.code == 200) {
                  this.$message.success("已发送验证码");
                  const times = 60; // 倒计时时间
                  if (!this.timer4) {
                    let count = times;
                    this.codeText4 = `<span style="color:#0058FF">${count}</span>s后可重新获取验证码`;
                    this.timer4 = setInterval(() => {
                      if (count > 0 && count <= times) {
                        count--;
                        this.codeText4 = `<span style="color:#0058FF">${count}</span>s后可重新获取验证码`;
                      } else {
                        clearInterval(this.timer4);
                        this.codeText4 = "重新发送";
                        this.timer4 = null;
                        this.canClick4 = true;
                      }
                    }, 1000);
                  }
                } else {
                  this.canClick4 = true;
                  this.codeText4 = "重新发送";
                  this.$message.error(res.message);
                }
              });
          } else {
            this.$message.error(res.message);
          }
        });
    },
    phoneNextThree() {
      http
        .post("/umsSysUser/bind", {
          ticket: this.ticket,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("绑定成功");
            let user = JSON.parse(localStorage.getItem("userinfo"));
            user.phone = this.ePhone;
            localStorage.setItem("userinfo", JSON.stringify(user));
            this.getInfo(user);
            this.phoneThree = false;
          } else {
            this.$message.error(res.message);
          }
        });
    },
    returnPre4() {
      this.phoneTwo = true;
      this.phoneThree = false;
    },
    returnPre() {
      this.emailTwo = false;
    },
    returnPre2() {
      this.emailTwo = true;
      this.emailThree = false;
    },
    returnPre3() {
      this.phoneTwo = false;
    },
  },
  beforeDestroy() {
    // 组件被销毁了，不能进行数据传输
    // 解绑事件
    // this.$bus.$off("userInfo");
  },
};
</script>
<style scoped>
.method {
  /* height: 100%; */
  background-color: #f4f7fc;
  z-index: -1;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
}
.method_head {
  font-size: 14px;
}
.method_wrapper {
  flex: 1;
  background: #ffffff;
  box-shadow: 0px 1px 4px 1px rgba(21, 34, 50, 0.08);
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  margin-top: 16px;
}

.method_search_wrapper {
  display: flex;
  padding: 30px;
  width: auto;
  border-bottom: 1px solid rgba(207, 212, 232, 0.5);
  font-size: 16px;
}
.method_search {
  display: flex;
  width: 750px;
}
.form_method {
  margin: 30px 0 0 30px;
}
.form_method-tip {
  height: 32px;
  border-radius: 4px 4px 4px 4px;
  line-height: 32px;
  color: #9a9a9a;
  margin-bottom: 30px;
  font-size: 16px;
}
.form_method_p {
  font-size: 16px;
}
.form_method_p:hover {
  cursor: pointer;
}
.verify_body_text {
  width: 421px;
  height: 44px;
  font-size: 14px;
  font-weight: 500;
  color: #909090;
  line-height: 24px;
}
.verify_text {
  font-size: 30px;
  font-weight: 500;
  color: #1a1a1a;
}
.verify_confirm {
  background: #0058ff;
  font-size: 15px;
  width: 120px;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
}

.verify-check-code_tip {
  font-size: 12px;
  margin-top: 10px;
  color: #999;
  text-align: left;
}
::v-deep .verify-check-code_tip .el-link.el-link--default {
  font-size: 12px;
}
.email_two_body {
  margin: 40px 30px;
}
.icon_back {
  color: #909090;
  font-size: 14px;
  font-weight: 400;
}
.email_two_confirm {
  background: #5290ff;
  width: 300px;
  margin-bottom: 20px;
}
::v-deep .el-form--label-left .el-form-item__label {
  width: 70px !important;
}
</style>