<template>
  <div>
    <div class="batch_t">
      <img
        src="@/assets/structure/close.png"
        class="batch_close"
        @click="close"
      />
      <div>人员导入</div>
    </div>
    <div class="batch_all">
      <div class="batch_explain">
        <div class="batch_explain_t">
          <el-radio-group v-model="tabPosition" @change="switchUpload">
            <el-radio-button label="1">导入人员</el-radio-button>
            <el-radio-button label="2">导入照片</el-radio-button>
          </el-radio-group>
          <div
            class="dowload_template"
            v-if="tabPosition == '1'"
            @click="dowloadTemplate"
          >
            下载模版
          </div>
        </div>
      </div>
      <!-- 导入人员 -->
      <div v-if="tabPosition == '1'">
        <div class="upload_step">
          <el-upload
            ref="upload1"
            action=""
            :show-file-list="false"
            :auto-upload="false"
            :on-change="changeExcel"
          >
            <div class="upload_step_text">选择文件</div>
          </el-upload>
          <img src="@/assets/structure/next.png" class="upload_step_next" />
          <div class="upload_step_text" @click="checkInvite">检测预览</div>
          <img src="@/assets/structure/next.png" class="upload_step_next" />
          <div class="upload_step_text" @click="formalimport">导入</div>
        </div>

        <div class="upload_browse" v-if="ispreview == 2">
          <div class="upload_browse_people">
            检测结果：有效人员：{{ successReason }} 无效人员：{{ failReason }}
          </div>
          <div class="upload_browse_fail">人员导入预览</div>
          <el-table
            height="40rem"
            :data="browseTableData"
            class="browseTableData"
            style="width: 100%"
            :header-cell-style="getRowClass"
          >
            <el-table-column prop="name" label="姓名"> </el-table-column>
            <el-table-column label="员工工号">
              <template slot-scope="scope">
                <div v-if="scope.row.umsUserImportExcel">
                  {{ scope.row.umsUserImportExcel.jobNumber }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="设备工号">
              <template slot-scope="scope">
                <div v-if="scope.row.umsUserImportExcel">
                  {{ scope.row.umsUserImportExcel.deviceNumber }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="部门编号">
              <template slot-scope="scope">
                <div v-if="scope.row.umsUserImportExcel">
                  {{ scope.row.umsUserImportExcel.departmentIdString }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="入职日期" width="200">
              <template slot-scope="scope">
                <div v-if="scope.row.umsUserImportExcel">
                  {{ handleTime(scope.row.umsUserImportExcel.timeJoin) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="检测结果" width="180" fixed="right">
              <template slot-scope="scope">
                <span v-if="scope.row.failReason">{{
                  scope.row.failReason
                }}</span>
                <span v-else>- -</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="upload_browse" v-if="ispreview == 3">
          <div class="upload_browse_people">
            导入结果：有效人员：{{ successReason }} 无效人员：{{ failReason }}
          </div>
          <div class="upload_browse_fail">
            以下为导入失败人员结果，<span @click="startDownload(formalData)"
              >下载失败人员报告</span
            >
          </div>
          <el-table
            height="40rem"
            :data="formalData"
            class="browseTableData"
            style="width: 100%"
            :header-cell-style="getRowClass"
          >
            <el-table-column prop="name" label="姓名"> </el-table-column>
            <el-table-column label="员工工号">
              <template slot-scope="scope">
                <div v-if="scope.row.umsUserImportExcel">
                  {{ scope.row.umsUserImportExcel.jobNumber }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="设备工号">
              <template slot-scope="scope">
                <div v-if="scope.row.umsUserImportExcel">
                  {{ scope.row.umsUserImportExcel.deviceNumber }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="部门编号">
              <template slot-scope="scope">
                <div v-if="scope.row.umsUserImportExcel">
                  {{ scope.row.umsUserImportExcel.departmentIdString }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="入职日期" width="200">
              <template slot-scope="scope">
                <div v-if="scope.row.umsUserImportExcel">
                  {{ handleTime(scope.row.umsUserImportExcel.timeJoin) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="失败原因" width="180" fixed="right">
              <template slot-scope="scope">
                <span v-if="scope.row.failReason">{{
                  scope.row.failReason
                }}</span>
                <span v-else>- -</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div v-if="tabPosition == '2'">
        <div class="photo_all">
          <div class="photo_name">
            照片命名方式<span style="color: red"
              >(照片需放在文件夹内一起上传)</span
            >
          </div>
          <el-radio-group v-model="photoType" @change="namingUpload">
            <el-radio :label="1"
              >以姓名命名<span>（例：王大锤.jpg）</span></el-radio
            >
            <el-radio :label="2"
              >以员工工号命名<span>（例：00001.jpg）</span></el-radio
            >
          </el-radio-group>
        </div>
        <el-upload
          ref="upload1"
          action="https://jsonplaceholder.typicode.com/posts/"
          :show-file-list="false"
          :auto-upload="false"
          :file-list="fileList1"
          :on-change="changeFile"
          :on-error="fileErr"
          multiple
        >
          <el-button type="primary" class="upload_ex">导入</el-button>
          <!-- <div class="upload_ex">
              <img
                src="@/assets/structure/upload.png"
                class="uploadregion_icon"
              />
              <div class="uploadregion_t">点击文件上传</div>
            </div> -->
        </el-upload>
        <el-progress
          v-if="showPercent"
          :percentage="Number(((percentNow * 100) / percentTotal).toFixed(0))"
        ></el-progress>
        <div v-if="isfinish">
          <div class="batch_fail">
            <div class="batch_fail_tips">
              以下为导入结果报告,
              <span @click="startDownload2(failList)" style="cursor: pointer"
                >下载失败报告</span
              >
            </div>
            <el-table
              height="37.5rem"
              :data="failList"
              class="failresult"
              :header-cell-style="getRowClass"
            >
              <el-table-column prop="name" label="照片名称" width="400">
              </el-table-column>
              <el-table-column prop="result" label="导入结果" width="150">
              </el-table-column>
              <el-table-column prop="failMessage" label="失败原因">
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>

    <!-- 拖拽区域 -->

    <!-- <div class="uploadregion" id="drop-area" @click="handleAddfoler">
        <el-upload
          ref="upload1"
          style="width: 100; height: 100%"
          action="https://jsonplaceholder.typicode.com/posts/"
          :show-file-list="false"
          :file-list="fileList1"
          :auto-upload="false"
          :on-change="changeFile"
          :on-error="fileErr"
          multiple
        >
          <div class="upload_ex">
            <img
              src="@/assets/structure/upload.png"
              class="uploadregion_icon"
            />
            <div class="uploadregion_t">点击文件上传</div>
          </div>
        </el-upload>
      </div> -->
  </div>
</template>

<script>
import axios from "axios";
import http from "../../../../utils/http";
import GLOBAL from "../../../../utils/common";
import exprotExcel from "../../../../utils/exprotExcel";
import { Header } from "element-ui";
export default {
  data() {
    return {
      fileNum: "", // 单词递归上传的文件
      upFileList: "", //需要依次上传的待传列表
      percentTotal: 0, //总上传个数
      percentNow: 0, //当前上传个数
      showDesc: "", //结束文案
      isfinish: false, //结束
      fileUpload: null, //保存的文件
      failList: [], //失败列表
      ossObj: {}, //oss上传参数
      tabPosition: "1", //导入操作
      photoType: 1, //照片命名方式
      showPercent: false, //显示上传进度条
      time: null, // change事件是否结束 是否可以直接调手动上传事件（目前设置1.5s）
      disabledUpload: false, //正在上传中 禁止再次选择文件上传
      ispreview: 1, //是否预览
      successReason: 0, //成功人数
      failReason: 0, //失败人数

      fileData: {}, //上传参数
      result: [], //最终结果

      fileList1: [],

      tableColumn: [
        {
          title: "姓名",
          dataIndex: "name",
          id: 1,
        },
        {
          title: "员工工号",
          dataIndex: "jobNumbernew",
          id: 2,
        },
        {
          title: "设备工号",
          dataIndex: "deviceNumberText",
          id: 3,
        },
        {
          title: "部门编号",
          dataIndex: "jobDepartmentNew",
          id: 4,
        },
        {
          title: "入职日期",
          dataIndex: "timeJoinText",
          id: 5,
        },
        {
          title: "失败原因",
          dataIndex: "failReason",
          id: 6,
        },
      ], //导出内容参数
      tableColumn2: [
        {
          title: "照片名称",
          dataIndex: "name",
          id: 1,
        },
        {
          title: "失败原因",
          dataIndex: "failMessage",
          id: 2,
        },
      ],
      // 预览表格
      browseTableData: [],
      // 正式表格
      formalData: [],
    };
  },
  methods: {
    // 关闭弹窗
    close() {
      if ((this.ispreview = 3)) {
        this.$emit("success");
      } else {
        this.$emit("close");
      }
    },

    // 获取上传参数
    getOss() {
      http
        .get("/oss/policy", {
          type: 0,
        })
        .then((res) => {
          this.ossObj = res.data;
        });
    },

    // 表头背景颜色变色
    getRowClass({ rowIndex, columnIndex }) {
      if (rowIndex == 0) {
        return "background:#F5F6FA;color:#5C5C5C";
      }
    },

    // 只能上传文件夹
    handleAddfoler() {
      this.getOss();
      this.$refs.upload1.$children[0].$refs.input.webkitdirectory = true;
    },

    // 切换上传
    switchUpload(value) {
      switch (value) {
        case "1":
          this.failList = [];
          this.isfinish = false;
          break;
        case "2":
          this.handleAddfoler();
          break;
      }
    },

    // 命名上传
    namingUpload(val) {
      this.$refs.upload1.clearFiles();
    },

    // excel上传
    changeExcel(file, fileList) {
      this.fileUpload = file.raw;
      this.$message.success("已选择文件");
    },
    // 检测预览
    checkInvite() {
      if (!this.fileUpload) {
        this.$message.error("请先选择文件");
        return;
      }

      const loading = this.$loading({
        lock: true,
        text: "导入中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      var token = localStorage.getItem("token");
      const formData = new FormData();
      formData.append("isCheck", true);
      formData.append("multipartFile", this.fileUpload);
      axios
        .post(GLOBAL.BASE_URL + "/umsUser/upload", formData, {
          timeout: 300 * 1000,
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
        })
        .then(
          (res) => {
            loading.close();
            if (res.data.code == 200) {
              this.$message.success("预览成功");
              this.ispreview = 2;
              this.browseTableData = res.data.data;
              var arr = [];
              var arr2 = [];
              res.data.data.map((item) => {
                if (item.failReason) {
                  arr.push(item);
                } else {
                  arr2.push(item);
                }
              });

              this.successReason = arr2.length;
              this.failReason = arr.length;
            }
          },
          (err) => {
            loading.close();
            this.$message.error("请重新选择文件");
          }
        );
    },

    // 正式导入
    formalimport() {
      if (!this.fileUpload) {
        this.$message.error("请先选择文件");
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "导入中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var token = localStorage.getItem("token");
      const formData = new FormData();
      formData.append("isCheck", false);
      formData.append("multipartFile", this.fileUpload);
      axios
        .post(GLOBAL.BASE_URL + "/umsUser/upload", formData, {
          timeout: 300 * 1000,
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
        })
        .then(
          (res) => {
            loading.close();
            if (res.data.code == 200) {
              this.$message.success("上传成功");
              this.ispreview = 3;
              var arr = [];
              var arr2 = [];
              res.data.data.map((item) => {
                if (item.failReason) {
                  arr.push(item);
                } else {
                  arr2.push(item);
                }
              });

              this.successReason = arr2.length;
              this.formalData = arr;
              this.failReason = arr.length;
            }
          },
          (err) => {
            loading.close();
            this.$message.error("请重新选择文件");
          }
        );
    },

    // 图片上传
    changeFile(file, fileList) {
      this.disabledUpload = true;
      this.failList = [];
      this.fileList1 = [];

      // 符合条件的进入待传列表
      this.upFileList = [];

      for (let x of fileList) {
        // 过滤掉非pdf 和小于100M的
        if (x.name != ".DS_Store") {
          this.upFileList.push(x.raw);
        }

        this.percentTotal = this.upFileList.length;
        this.percentNow = 0;
        this.showPercent = false;
        this.showDesc = "";
      }

      clearTimeout(this.time);
      this.time = setTimeout(() => {
        this.time = null;
        this.fnBegin(); //说明此时change了所有文件了 可以上传了
      }, 1500);
    },

    fnBegin() {
      console.log("此时change了所有文件 开始上传", this.upFileList);
      // 如果不符合jpg的话，直接省略
      this.upFileList.map((item, index) => {
        if (item.type != "image/jpeg") {
          this.failList.push({
            name: item.name,
            result: "导入失败",
            failMessage: "图片格式不正确",
          });
        } else {
          // 判断是否为存数字
          var text = item.name.split(".jpg")[0];
          var isJob = this.isNumeric(text);
          // 按姓名命名
          if (this.photoType == 1) {
            if (isJob) {
              this.failList.push({
                name: item.name,
                result: "导入失败",
                failMessage: "命名格式不正确",
              });
            } else {
              this.fileList1.push(item);
            }
            // 按工号命名
          } else {
            if (!isJob) {
              this.failList.push({
                name: item.name,
                result: "导入失败",
                failMessage: "命名格式不正确",
              });
            } else {
              this.fileList1.push(item);
            }
          }
        }
      });

      this.submitUpload2();
    },

    // 判断是否为纯数字
    isNumeric(str) {
      return /^[a-zA-Z0-9]+$/.test(str);
    },

    // 上传oss
    uploadOss(file) {
      var _this = this;

      const formData = new FormData();
      var url = _this.ossObj.host;
      formData.append("key", _this.ossObj.dir + file.name); //key 唯一值  即相对路径
      formData.append("policy", _this.ossObj.policy); //服务器返回的policy
      formData.append("OSSAccessKeyId", _this.ossObj.accessKeyId); //服务器返回的accessId
      formData.append("signature", _this.ossObj.signature); //服务器返回的signature
      formData.append("name", file.name); //文件名
      formData.append("file", file);
      var text = file.name.split(".jpg")[0];

      axios
        .post(url, formData, {
          "Content-type": "multipart/form-data",
        })
        .then(
          (res) => {
            var finalUrl = url + "/" + _this.ossObj.dir + file.name;
            this.uploadAdmin(finalUrl, text);
          },
          (err) => {
            this.$message.error("页面停留过长,请重新上传");
            // console.log(err);
            // // 出现错误时的处理
            // _this.$message({
            //   message: err.msg,
            //   type: "none",
            // });
          }
        );
    },

    // 上传后端接口
    uploadAdmin(url, value) {
      var object = {};
      if (this.photoType == 1) {
        object = {
          headUrl: url,
          name: value,
          userUpdateType: "CHANGE_HEAD_URL",
        };
      } else {
        object = {
          headUrl: url,
          jobNumber: value,
          userUpdateType: "CHANGE_HEAD_URL",
        };
      }
      http.post("/umsUser/update", object).then((res) => {
        this.percentNow = this.percentNow + 1;
        this.fileList1.shift();
        setTimeout(() => {
          this.submitUpload2();
        }, 1000);
        if (res.code == 200) {
          this.failList.push({
            name: value + ".jpg",
            result: "导入成功",
            failMessage: "",
          });
        } else {
          this.failList.push({
            name: value + ".jpg",
            result: "导入失败",
            failMessage: "找不到该员工",
          });
        }
      });
    },

    // 处理轮询上传oss
    submitUpload2() {
      var token = localStorage.getItem("token");
      if (this.fileList1.length > 0) {
        this.showPercent = true;

        this.fileNum = new FormData(); // new formData对象
        this.fileNum.append("file", this.fileList1[0]); // append增加数据

        let _vm = this;

        var num = _vm.fileList1.length;
        var remainder = num % 50;
        if (remainder == 0) {
          this.getOss();
          setTimeout(() => {
            _vm.uploadOss(_vm.fileList1[0]);
          }, 1000);
        } else {
          _vm.uploadOss(_vm.fileList1[0]);
        }
      } else {
        this.disabledUpload = false;
        this.showPercent = false;
        this.fileList1 = []; //清空待传列表

        this.$refs.upload1.clearFiles();
        this.fileList1 = [];

        if (this.percentNow == this.percentTotal && this.percentTotal) {
          this.percentTotal = 0;
          this.percentNow = 0;
        } else if (
          this.percentNow == this.percentTotal &&
          this.percentTotal == 0
        ) {
          this.percentTotal = 0;
          this.percentNow = 0;
        } else {
          this.percentTotal = 0;
          this.percentNow = 0;
        }

        if (this.failList.length == this.upFileList.length) {
          this.isfinish = true;
        }
        return false;
      }
    },

    fileErr(err, file, fileList) {
      this.$message({
        message: file.name + "上传失败",
        type: "error",
      });
    },

    // 表格下载之前
    startDownload(arr) {
      if (arr.length == 0) {
        this.$message.error("暂无要下载的表格");
        return;
      }
      let arr2 = [];
      for (let key of this.tableColumn) {
        arr2.push(key.key);
      }
      // const loading = this.$loading({
      //   lock: true,
      //   text: "导出表格中，请稍后...",
      //   spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.7)",
      // });
      arr.map((v) => {
        v.jobNumbernew = "\xa0" + v.jobNumber;
        v.idCardnew = "\xa0" + v.idCard;
        v.deviceNumberText = v.umsUserImportExcel.deviceNumber;
        v.jobDepartmentNew = v.umsUserImportExcel.departmentIdString;
        if (v.umsUserImportExcel.timeJoin) {
          v.timeJoinText = this.handleTime(v.umsUserImportExcel.timeJoin);
        }
        for (let i = 0; i < arr2.length; i++) {
          if (v[arr2[i]] == undefined) {
            v[arr2[i]] = "";
          }
        }
        for (let key in v) {
          if (v[key] == null) v[key] = "";
        }
      });
      exprotExcel.export(this.tableColumn, arr, "失败列表");
      this.$message.success("导出成功");
    },
    // 表格下载之前
    startDownload2(arr) {
      if (arr.length == 0) {
        this.$message.error("暂无要下载的表格");
        return;
      }
      let arr2 = [];
      var newArr = [];
      for (let key of this.tableColumn2) {
        arr2.push(key.key);
      }
      arr.map((item) => {
        if (item.result == "导入失败") {
          newArr.push(item);
        }
      });
      // const loading = this.$loading({
      //   lock: true,
      //   text: "导出表格中，请稍后...",
      //   spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.7)",
      // });
      newArr.map((v) => {
        for (let i = 0; i < arr2.length; i++) {
          if (v[arr2[i]] == undefined) {
            v[arr2[i]] = "";
          }
        }
        for (let key in v) {
          if (v[key] == null) v[key] = "";
        }
      });
      exprotExcel.export(this.tableColumn2, newArr, "失败列表");
      this.$message.success("导出成功");
    },
    // 处理日期
    handleTime(time) {
      if (time) {
        var arr = time.split(" ");
        return arr[0];
      }
    },

    // 下载模版
    dowloadTemplate() {
      const filePath = "./static/人员资料导入模板.xls";
      const link = document.createElement("a");
      link.href = filePath;
      link.setAttribute("download", "人员资料导入模版.xls");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>

<style scoped>
.batch_t {
  display: flex;
  align-items: center;
  height: 90px;
  box-sizing: border-box;
  padding-left: 20px;
  font-size: 18px;
  color: #1a1a1a;
  border-bottom: 1px solid #cfd4e8;
}
.batch_close {
  width: 24px;
  cursor: pointer;
  height: 24px;
  margin-right: 20px;
}
.batch_all {
  padding: 0 30px;
  box-sizing: border-box;
}
.batch_explain {
  display: flex;
  box-sizing: border-box;
  align-items: flex-start;
}
.batch_explain_t {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dowload_template {
  width: 100px;
  height: 40px;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #0058ff;
  box-sizing: border-box;
  text-align: center;
  line-height: 40px;
  color: #0058ff;
  font-size: 15px;
  cursor: pointer;
}
::v-deep .batch_explain_t .el-radio-button {
  width: 100px;
  height: 40px;
}
::v-deep
  .batch_explain_t
  .el-radio-button__orig-radio:checked
  + .el-radio-button__inner {
  background-color: #0058ff;
  border-color: #0058ff;
}
.uploadregion {
  width: 413px;
  height: 370px;
  margin: 0 auto;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px dashed #cfd4e8;
}
.uploadregion_icon {
  width: 44px;
  height: 38px;
}
.uploadregion_t {
  font-size: 16px;
  color: #5c5c5c;
  margin: 18px 0 9px;
}
.uploadregion_o {
  font-size: 14px;
  color: #9a9a9a;
}
.template_dowload {
  width: 88px;
  height: 32px;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #0058ff;
  font-size: 14px;
  color: #0058ff;
  text-align: center;
  line-height: 32px;
  cursor: pointer;
  text-decoration: none;
}
.upload_step {
  width: 100%;
  height: 100px;
  box-sizing: border-box;
  background: #f2f7ff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px 4px 4px 4px;
}
.upload_step_text {
  width: 100px;
  height: 40px;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #0058ff;
  text-align: center;
  line-height: 40px;
  color: #0058ff;
  font-size: 14px;
  cursor: pointer;
}
.upload_step_next {
  width: 24px;
  height: 24px;
  margin: 0 72px;
}
.browseTableData {
  margin-top: 20px;
}
.photo_name {
  font-size: 16px;
  color: #000000;
  margin-bottom: 14px;
}
::v-deep .photo_all .el-radio-group {
  display: flex;
  flex-direction: column;
}
::v-deep .photo_all .el-radio__label {
  color: #1a1a1a;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
}
::v-deep .photo_all .el-radio__label span {
  color: rgba(26, 26, 26, 0.6);
}

::v-deep .photo_all .el-radio .el-radio__input .el-radio__inner {
  border-radius: 2px;
}
::v-deep .photo_all .el-radio__input.is-checked .el-radio__inner {
  border-color: #0058ff;
  background: #0058ff;
}
::v-deep
  .photo_all
  .el-radio
  .el-radio__input.is-checked
  .el-radio__inner::after {
  content: "";
  width: 8px;
  height: 3px;
  border: 2px solid white;
  border-top: transparent;
  border-right: transparent;
  text-align: center;
  display: block;
  position: absolute;
  top: 2px;
  left: 1px;
  transform: rotate(-45deg);
  border-radius: 0px;
  background: none;
}
::v-deep .photo_all .el-radio__input.is-checked + .el-radio__label {
  color: #1a1a1a;
}
.batch_fail {
  width: 100%;
  margin: 50px auto 0;
}
.batch_fail_t {
  width: 100%;
  height: 62px;
  background: #f5f6fa;
  border-radius: 4px;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #1a1a1a;
}
.batch_fail_icon {
  margin-right: 10px;
}
.batch_fail_tips {
  font-size: 14px;
  color: #9a9a9a;
  margin-bottom: 20px;
}
.batch_fail_tips span {
  color: #0058ff;
  cursor: pointer;
}
.upload_ex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.failresult {
  width: 100%;
}
.upload_browse_people {
  width: 100%;
  height: 50px;
  background: #f5f6fa;
  padding-left: 20px;
  box-sizing: border-box;
  line-height: 50px;
  font-size: 16px;
  color: #000000;
  margin: 20px 0 30px;
}
.upload_browse_fail {
  font-size: 16px;
}
.upload_browse_fail span {
  font-size: 16px;
  color: #0058ff;
  cursor: pointer;
}
</style>
