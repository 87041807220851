<template>
  <div class="classes_form">
    <el-form
      :model="classesForm"
      label-position="top"
      :rules="rules"
      ref="addForm"
    >
      <div class="attendance_dialog" v-if="attendanceGroup.type">
        <div class="attendance_dialog_content">
          <div class="attendance_dialog_single">
            <div class="attendance_dialog_single_name">考勤类型：</div>
            <div>{{ attendanceGroup.type }}</div>
          </div>
          <div class="attendance_dialog_single">
            <div class="attendance_dialog_single_name">
              每日开始结束时间设置：
            </div>
            <div>{{ attendanceGroup.time }}</div>
          </div>
          <div class="attendance_dialog_single">
            <div class="attendance_dialog_single_name">节假日设置：</div>
            <div v-if="attendanceGroup.useHoliday">开启</div>
            <div v-else>关闭</div>
          </div>
          <div class="attendance_dialog_single">
            <div class="attendance_dialog_single_name">加班规则：</div>
            <div>{{ attendanceGroup.overtime }}</div>
          </div>
          <div class="attendance_dialog_single">
            <div class="attendance_dialog_single_name">补卡申请：</div>
            <div v-if="attendanceGroup.clockIn">开启</div>
            <div v-else>关闭</div>
          </div>
          <div class="attendance_dialog_single" v-if="attendanceGroup.clockIn">
            <div class="attendance_dialog_single_name">补卡类型：</div>
            <div>
              <div
                class="attendance_dialog_single_chock"
                v-if="attendanceGroup.chockType"
              >
                {{ attendanceGroup.chockType.join("/") }}
              </div>
              <div class="attendance_dialog_single_chock">
                允许补卡时间限制({{ attendanceGroup.timelimit }})
              </div>
              <div class="attendance_dialog_single_chock">
                每月允许补卡次数({{ attendanceGroup.number }})
              </div>
              <div>每月补卡截止日期({{ attendanceGroup.date }})</div>
            </div>
          </div>
          <div class="attendance_dialog_single" v-if="attendanceGroup.work">
            <div class="attendance_dialog_single_name">工作时长：</div>
            <div>{{ attendanceGroup.work }}</div>
          </div>
          <div
            class="attendance_dialog_single"
            v-if="attendanceGroup.beginTime"
          >
            <div class="attendance_dialog_single_name">打卡开始时间：</div>
            <div>{{ attendanceGroup.beginTime }}</div>
          </div>
          <div
            class="attendance_dialog_single"
            v-if="attendanceGroup.beginTime"
          >
            <div class="attendance_dialog_single_name">打卡间隔时间：</div>
            <div>{{ attendanceGroup.interval }}分钟</div>
          </div>
        </div>
      </div>
      <div v-else>该考勤组已被删除或经过修改</div>
    </el-form>
  </div>
</template>

<script>
import http from "../../../../utils/http";
export default {
  data() {
    return {
      classesForm: {
        type: 1,
        arrangeDataList: [], //班次列表
        clockDataList: [], //休息时间
      }, //新建班次表格
      timedifference: 0, //计算总时长
      attendanceGroup: {}, //考勤组配置
      restAlltime: 0, //休息总时长
      setRest: false, //设置休息时间
      rules: {
        name: [{ required: true, message: "请输入班次名称", trigger: "blur" }],
      },
      options: [
        {
          label: "当日",
          value: false,
        },
        {
          label: "次日",
          value: true,
        },
      ],
    };
  },

  created() {
    if (this.group) {
      this.checkAttendance(this.group);
    }
  },

  props: ["group"],

  methods: {
    // 获取考勤组信息
    checkAttendance(id) {
      http
        .get("/bmsAttendanceGroup/get/config", {
          id: id,
        })
        .then((res) => {
          if (res.code == 200) {
            var object = {};
            if (res.data) {
              var val = res.data;
              object.type = this.judgeType(val);
              object.useHoliday = val.useHoliday;
              // 处理最早最晚打卡时间
              if (val.clockData) {
                var start = "";
                var end = "";
                start = this.secondsToHourMin(val.clockData.beginDaySecond);
                if (val.clockData.endDaySecond >= 86400) {
                  val.clockData.endDaySecond =
                    val.clockData.endDaySecond - 86400;
                  end =
                    "次日" + this.secondsToHourMin(val.clockData.endDaySecond);
                } else {
                  end =
                    "当日" + this.secondsToHourMin(val.clockData.endDaySecond);
                }
                object.time = start + "-" + end;
              }

              // 处理加班规则
              if (val.overtimeConfigData) {
                var overtime = val.overtimeConfigData;
                var text = ""; //工作日
                var text2 = ""; //节假日
                var text3 = ""; //休息日
                if (overtime.workOvertimeConfigItemData) {
                  var item = overtime.workOvertimeConfigItemData;
                  // 工作日
                  if (item.overtimeConfigItemApplyTimeData) {
                    text = "工作日按加班审批时长计算";
                  } else if (item.overtimeConfigItemClockTimeData) {
                    text = "工作日按打卡时长计算";
                  } else if (item.overtimeConfigItemMixTimeData) {
                    text = "工作日按审批和打卡时长计算";
                  } else {
                    text = "工作日不允许加班";
                  }
                }
                if (overtime.holidayOvertimeConfigItemData) {
                  var item = overtime.holidayOvertimeConfigItemData;
                  // 节假日
                  if (item.overtimeConfigItemApplyTimeData) {
                    text2 = "节假日按加班审批时长计算";
                  } else if (item.overtimeConfigItemClockTimeData) {
                    text2 = "节假日按打卡时长计算";
                  } else if (item.overtimeConfigItemMixTimeData) {
                    text2 = "节假日按审批和打卡时长计算";
                  } else {
                    text2 = "节假日不允许加班";
                  }
                }
                if (overtime.restOvertimeConfigItemData) {
                  var item = overtime.restOvertimeConfigItemData;
                  // 休息日
                  if (item.overtimeConfigItemApplyTimeData) {
                    text3 = "休息日按加班审批时长计算";
                  } else if (item.overtimeConfigItemClockTimeData) {
                    text3 = "休息日按打卡时长计算";
                  } else if (item.overtimeConfigItemMixTimeData) {
                    text3 = "休息日按审批和打卡时长计算";
                  } else {
                    text3 = "休息日不允许加班";
                  }
                }
                object.overtime = text + "," + text2 + "," + text3;
              }

              // 处理补卡申请
              if (val.applyRecordConfigData) {
                object.clockIn = true;
                var arr = [];
                if (val.applyRecordConfigData.permitAbsence) {
                  arr.push("旷工");
                }
                if (val.applyRecordConfigData.permitEarly) {
                  arr.push("早退");
                }
                if (val.applyRecordConfigData.permitLate) {
                  arr.push("迟到");
                }
                if (val.applyRecordConfigData.permitOther) {
                  arr.push("其他");
                }

                object.chockType = arr;

                // 补卡时间限制
                if (val.applyRecordConfigData.dayLimit) {
                  object.timelimit = val.applyRecordConfigData.dayLimit;
                } else {
                  object.timelimit = "无限制";
                }
                // 补卡次数
                if (val.applyRecordConfigData.monthMax) {
                  object.number = val.applyRecordConfigData.monthMax;
                } else {
                  object.number = "无限制";
                }
                // 截止日期
                if (val.applyRecordConfigData.dayMax) {
                  object.date = "次月" + val.applyRecordConfigData.dayMax;
                } else {
                  object.date = "不设置";
                }
              } else {
                object.clockIn = false;
              }

              // 如果是自由上下班
              if (val.freeWorkData) {
                if (val.freeWorkData.workTime) {
                  object.work = val.freeWorkData.workTime / 3600 + "小时";
                } else if (val.freeWorkData.workTime == 0) {
                  object.work = "配置错误";
                } else {
                  object.work = "无限制";
                }
                object.interval = val.freeWorkData.endSecond / 60;
                object.beginTime = this.secondsToHourMin(
                  val.freeWorkData.beginSecond
                );
              }

              this.attendanceGroup = object;
            }
          }
        });
    },
    // 判断上班类型
    judgeType(value) {
      if (value.fixWorkData) {
        return "固定上下班";
      } else if (value.freeWorkData) {
        return "自由上下班";
      } else {
        return "排班上下班";
      }
    },
    // 时间换为分钟
    timeToMinutes(time) {
      const [hour, minute] = time.split(":").map(Number);
      return hour * 60 + minute;
    },
    // 时间换为秒
    timeToSecond(time) {
      const [hour, minute] = time.split(":").map(Number);
      return hour * 3600 + minute * 60;
    },
    // 秒转换成时间
    secondsToHourMin(seconds) {
      // 将秒数转换为分钟
      let minutes = Math.floor(seconds / 60);
      // 将分钟转换为小时和分钟
      let hours = Math.floor(minutes / 60);
      let minutesRemainder = minutes % 60;

      // 格式化小时和分钟
      hours = hours < 10 ? "0" + hours : hours;
      minutesRemainder =
        minutesRemainder < 10 ? "0" + minutesRemainder : minutesRemainder;

      // 返回时分格式
      return hours + ":" + minutesRemainder;
    },
    // 查询是否在时间段内
    isInWorkingHours(time, isTomorrow) {
      var timeInMinutes = this.convertToMinutes(time);
      if (isTomorrow) {
        timeInMinutes = 1440 + timeInMinutes;
      }
      for (var i = 0; i < this.classesForm.arrangeDataList.length; i++) {
        var start = this.convertToMinutes(
          this.classesForm.arrangeDataList[i].clockData.beginTimeData.daySecond
        );
        var end = this.convertToMinutes(
          this.classesForm.arrangeDataList[i].clockData.endTimeData.daySecond
        );
        if (
          this.classesForm.arrangeDataList[i].clockData.beginTimeData.tomorrow
        ) {
          start = 1440 + start;
        }
        if (
          this.classesForm.arrangeDataList[i].clockData.endTimeData.tomorrow
        ) {
          end = 1440 + end;
        }
        if (timeInMinutes > start && timeInMinutes < end) {
          return i; // 返回时间段索引
        }
      }

      return -1;
    },
    // 触发监听
    triggerListening(value) {
      console.log(value);
      this.$emit("nestedObj1ValueChanged", value);
    },
    // 转化
    convertToMinutes(timeString) {
      var timeArray = timeString.split(":");
      return parseInt(timeArray[0]) * 60 + parseInt(timeArray[1]);
    },
    // 监听当日，次日
    changeUp(e, value) {
      // 获取班次的第一个时间段
      var onceClasses = this.classesForm.arrangeDataList[0];
      var onceClassesTime = "";
      // 获取班次的最后一个时间段
      var lastClasses =
        this.classesForm.arrangeDataList[
          this.classesForm.arrangeDataList.length - 1
        ];
      var lastClassesTime = "";
      // 第一个时间段开始时间
      if (onceClasses.clockData.beginTimeData) {
        onceClassesTime = onceClasses.clockData.beginTimeData.daySecond;
      }
      // 最后一个时间段结束时间
      if (lastClasses.clockData.endTimeData) {
        lastClassesTime = lastClasses.clockData.endTimeData.daySecond;
      }
      if (value.beginTimeData.tomorrow) {
        // 如果最后一个时间的明天
        if (lastClasses.clockData.endTimeData.tomorrow) {
          onceClassesTime = "00:00:00";
          lastClassesTime = lastClassesTime + ":00";
          value.beginTimeData.limitTime.selectableRange =
            onceClassesTime + "-" + lastClassesTime;
        } else {
          onceClassesTime = "00:00:00";
          lastClassesTime = "23:59:59";
          value.beginTimeData.limitTime.selectableRange =
            onceClassesTime + "-" + lastClassesTime;
        }
      } else {
        if (lastClasses.clockData.endTimeData.tomorrow) {
          onceClassesTime = onceClassesTime + ":00";
          lastClassesTime = "23:59:59";
          value.beginTimeData.limitTime.selectableRange =
            onceClassesTime + "-" + lastClassesTime;
        } else {
          onceClassesTime = onceClassesTime + ":00";
          lastClassesTime = lastClassesTime + ":00";
          value.beginTimeData.limitTime.selectableRange =
            onceClassesTime + "-" + lastClassesTime;
        }
      }

      if (value.endTimeData.tomorrow) {
        // 如果最后一个时间的明天
        if (lastClasses.clockData.endTimeData.tomorrow) {
          onceClassesTime = "00:00:00";
          lastClassesTime = lastClasses.clockData.endTimeData.daySecond + ":00";
          value.endTimeData.limitTime.selectableRange =
            onceClassesTime + "-" + lastClassesTime;
        } else {
          onceClassesTime = "00:00:00";
          lastClassesTime = "23:59:59";
          value.endTimeData.limitTime.selectableRange =
            onceClassesTime + "-" + lastClassesTime;
        }
      } else {
        if (lastClasses.clockData.endTimeData.tomorrow) {
          onceClassesTime = onceClassesTime + ":00";
          lastClassesTime = "23:59:59";
          value.endTimeData.limitTime.selectableRange =
            onceClassesTime + "-" + lastClassesTime;
        } else {
          onceClassesTime = onceClassesTime + ":00";
          lastClassesTime = lastClassesTime + ":00";
          value.endTimeData.limitTime.selectableRange =
            onceClassesTime + "-" + lastClassesTime;
        }
      }
    },
    // 取消弹窗
    cancelDiglog() {
      this.$refs.addForm.clearValidate();
      this.$emit("cancel");
    },
  },
};
</script>

<style scoped>
.classes_form {
  padding: 30px;
  box-sizing: border-box;
}

::v-deep .classes_name {
  width: 413px;
}

.attendance {
  width: 100%;
  padding: 20px 30px;
  box-sizing: border-box;
  background-color: #f5f6fa;
}
.attendance_top {
  width: 100%;
  height: 75px;
  display: flex;
  align-items: center;
}
.attendance_time {
  color: rgba(26, 26, 26, 0.6);
  font-size: 15px;
}

.attendance_single {
  width: 100%;
  height: 330px;
  border-bottom: 1px solid #cfd4e8;
  padding-left: 20px;
  box-sizing: border-box;
  display: flex;
  overflow-x: auto;
  overflow-y: none;
}
.attendance_single::-webkit-scrollbar {
  display: none;
}
.attendance_single_gowork {
  display: flex;
  margin-bottom: 40px;
}

.attendance_single_leave {
  display: flex;
}

.attendance_single_gowork_left {
  margin-right: 75px;
}

.attendance_single_belate {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: rgba(26, 26, 26, 0.6);
  margin-bottom: 14px;
}

.attendance_single_other {
  margin-bottom: 33px;
}

.attendance_single_belate2 {
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-bottom: 14px;
  color: rgba(26, 26, 26, 0.6);
}

.attendance_single_num {
  margin: 0 12px;
}

.attendance_single_title {
  font-size: 16px;
  color: #1a1a1a;
}

.attendance_single_checkin {
  display: flex;
  align-items: center;
  font-size: 15px;
  color: rgba(26, 26, 26, 0.6);
}

.attendance_single_checkbox {
  margin-right: 30px !important;
}

.attendance_single_other {
  display: flex;
  align-items: center;
}

::v-deep .el-radio__label {
  color: rgba(26, 26, 26, 0.6);
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
}

::v-deep .el-radio .el-radio__input .el-radio__inner {
  border-radius: 2px;
}
::v-deep .el-radio__input.is-checked .el-radio__inner {
  border-color: #0058ff;
  background: #0058ff;
}
::v-deep .el-radio .el-radio__input.is-checked .el-radio__inner::after {
  content: "";
  width: 8px;
  height: 3px;
  border: 2px solid white;
  border-top: transparent;
  border-right: transparent;
  text-align: center;
  display: block;
  position: absolute;
  top: 2px;
  left: 1px;
  transform: rotate(-45deg);
  border-radius: 0px;
  background: none;
}

.classes_form_bto {
  display: flex;
  align-items: center;
  justify-content: center;
}

::v-deep .classes_form_diglog {
  width: 100px;
  margin-right: 30px;
}

::v-deep .classdiglog .el-dialog {
  padding: 0 !important;
}

::v-deep .el-form-item__label {
  font-size: 16px;
}

::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #5c5c5c;
}

::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background: #e0ecff;
  color: #0058ff;
  border-color: #0058ff;
}

::v-deep .el-dialog__title {
  font-size: 16px;
}

::v-deep .el-checkbox__label {
  color: rgba(26, 26, 26, 0.6);
  font-size: 16px;
  font-weight: 350;
  line-height: 40px;
}
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: rgba(26, 26, 26, 0.6);
}
::v-deep .el-input-number__decrease {
  height: 50% !important;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
::v-deep .el-input-number__increase {
  height: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.shanges {
  width: 116px;
  height: 30px;
  background: #dee4ed;
  border-radius: 4px 4px 4px 4px;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  color: #1a1a1a;
}
.attendance_add {
  width: 108px;
  height: 35px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #0058ff;
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #0058ff;
  margin-right: 20px;
  justify-content: center;
  cursor: pointer;
}
.attendance_add_icon {
  width: 12px;
  height: 12px;
  margin-right: 5px;
}
.attendance_all {
  width: 100%;
  max-height: 626px;
  overflow-y: scroll;
  background: #ffffff;
}
.attendance_all::-webkit-scrollbar {
  display: none;
}
.attendance_all_header {
  width: 100%;
  height: 63px;
  border-bottom: 1px solid #cfd4e8;
  display: flex;
  align-items: center;
}
.attendance_all_header_left {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 20px;
  box-sizing: border-box;
}
.attendance_all_header_right {
  width: 71px;
  height: 100%;
  line-height: 63px;
  text-align: center;
  border-left: 1px solid #cfd4e8;
  box-sizing: border-box;
}
.attendance_all_header_right2 {
  width: 71px;
  height: 100%;
  line-height: 280px;
  text-align: center;
  border-left: 1px solid #cfd4e8;
  box-sizing: border-box;
  font-size: 15px;
  cursor: pointer;
}
.disable {
  color: rgba(26, 26, 26, 0.3);
}
.nodisable {
  color: #0058ff;
}
.workhour {
  width: 204px;
}
.workrule {
  width: 461px;
}
.afterwordrule {
  flex: 1;
}
.workhour_time {
  display: flex;
  align-items: center;
  border: 1px solid #d5d7e3;
  border-radius: 4px;
  width: 164px;
}
::v-deep .el-select {
  width: 76px;
}
::v-deep .workhour_time .el-input__inner {
  border: none;
  width: 100%;
}
::v-deep .workhour_time .el-date-editor.el-input {
  width: 88px;
}
::v-deep .workhour_time_specific .el-input--suffix .el-input__inner {
  padding-right: 0 !important;
}
.workhour_time_up {
  margin-top: 29px;
}
.workhour_time_down {
  margin-top: 60px;
}
.attendance_single_num {
  width: 100px;
}
::v-deep .attendance_single_num .el-input-number__decrease {
  width: 32px;
  background: white;
}
::v-deep .attendance_single_num .el-input-number__increase {
  width: 32px;
  background: white;
}
::v-deep
  .attendance_single_belate
  .el-input-number.is-controls-right
  .el-input__inner {
  padding-left: 10px;
}
::v-deep
  .attendance_single_checkin
  .el-input-number.is-controls-right
  .el-input__inner {
  padding-left: 10px;
}
.mt {
  margin-top: 29px;
  width: 461px;
}
.mt2 {
  margin-top: 29px;
  width: 458px;
}
::v-deep .workhour_time .el-form-item__content {
  line-height: 20px;
}
::v-deep .attendance_single_belate .el-icon-arrow-up {
  background: none !important;
  font-size: 18px !important;
}
::v-deep .attendance_single_belate .el-icon-arrow-up::after {
  content: "\e6e1";
}
::v-deep .attendance_single_belate .el-icon-arrow-up::before {
  content: "" !important;
}
::v-deep .attendance_single_belate .el-icon-arrow-down {
  background: none !important;
  font-size: 18px !important;
}

::v-deep .attendance_single_belate2 .el-icon-arrow-up {
  background: none !important;
  font-size: 18px !important;
}
::v-deep .attendance_single_belate2 .el-icon-arrow-up::after {
  content: "\e6e1";
}
::v-deep .attendance_single_belate2 .el-icon-arrow-up:before {
  content: "" !important;
}
::v-deep .attendance_single_belate2 .el-icon-arrow-down {
  background: none !important;
  font-size: 18px !important;
}

::v-deep .attendance_single_belate3 .el-icon-arrow-up {
  background: none !important;
  font-size: 18px !important;
}
::v-deep .attendance_single_belate3 .el-icon-arrow-up::after {
  content: "\e6e1";
}
::v-deep .attendance_single_belate3 .el-icon-arrow-up::before {
  content: "" !important;
}
::v-deep .attendance_single_belate3 .el-icon-arrow-down {
  background: none !important;
  font-size: 18px !important;
}
.overtime_rule_type {
  margin-top: 4px;
  height: 40px;
  width: 100%;
  border-bottom: 1px solid #cfd4e8;
}
::v-deep .overtime_rule_type2 .el-select {
  width: 80px;
  height: 30px;
  margin: 0 11px;
  font-size: 14px;
  line-height: auto;
}
::v-deep .overtime_rule_type2 .el-input {
  width: 80px;
  height: 30px;
}
::v-deep .overtime_rule_type2 .el-select .el-input__inner {
  width: 100%;
  height: 100%;
  color: #1a1a1a;
}
.overtime_rule_type2 {
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-top: 20px;
  color: #1a1a1a;
}
.overtime_rule_type_add {
  width: 18px;
  height: 18px;
  margin-left: 11px;
  cursor: pointer;
}
::v-deep .error .el-input__inner {
  border-color: red;
}
.error_tips {
  color: red;
}
.error_tips2 {
  color: red;
  height: 20px;
  line-height: 20px;
}
.rest_period {
  display: flex;
  font-size: 15px;
  align-items: center;
  color: #1a1a1a;
  margin-top: 10px;
}
.rest_period_title {
  margin-right: 11px;
}
.rest_period_heng {
  margin: 0 10px;
}
.error_tips {
  color: red;
  width: 164px;
  line-height: 20px;
}
.time_list {
  display: flex;
  width: 370px;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.time_single {
  flex: 0 0 116px;
  width: 116px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 16px;
  color: #1a1a1a;
  background: #dee4ed;
  border-radius: 4px 4px 4px 4px;
  margin-right: 8px;
}
::v-deep .el-icon-arrow-down {
  background: none !important;
  font-size: 0.5rem;
  line-height: 1.3rem;
}
::v-deep .el-icon-arrow-down:before {
  content: "\e6df" !important;
  font-size: 18px;
  visibility: visible;
}
.attendance_dialog_title {
  font-size: 16px;
  color: #1a1a1a;
}
.attendance_dialog_content {
  padding: 30px;
  box-sizing: border-box;
  width: 100%;
  background: #f5f6fa;
  border-radius: 6px 6px 6px 6px;
  margin-top: 14px;
}
.attendance_dialog_single {
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-bottom: 20px;
  color: #1a1a1a;
}
.attendance_dialog_single2 {
  display: flex;
  align-items: flex-start;
  font-size: 15px;
  color: #1a1a1a;
}
.attendance_dialog_single_name {
  color: rgba(26, 26, 26, 0.6);
  margin-right: 10px;
}
.attendance_dialog_single_chock {
  margin-bottom: 10px;
}
</style>