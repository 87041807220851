<template>
  <div class="checkin">
    <div class="checkin_title">数据看板</div>
    <div class="checkin_information">
      <div class="checkin_information_top">
        <div class="checkin_information_single">
          <div>在职人员(人)</div>
          <div class="checkin_information_single_content">
            <div class="checkin_information_single_people">{{ onjob }}</div>
            <img
              src="@/assets/bulletin/icon1.png"
              class="checkin_information_single_icon"
            />
          </div>
        </div>
        <div class="checkin_information_single">
          <div>离职人员(人)</div>
          <div class="checkin_information_single_content">
            <div class="checkin_information_single_people">
              {{ resignation }}
            </div>
            <img
              src="@/assets/bulletin/icon2.png"
              class="checkin_information_single_icon"
            />
          </div>
        </div>
        <div class="checkin_information_single">
          <div>在线设备(台)</div>
          <div class="checkin_information_single_content">
            <div class="checkin_information_single_people">
              {{ onlineLeng }}
            </div>
            <img
              src="@/assets/bulletin/icon3.png"
              class="checkin_information_single_icon"
            />
          </div>
        </div>
        <div class="checkin_information_single">
          <div>离线设备(台)</div>
          <div class="checkin_information_single_content">
            <div class="checkin_information_single_people">
              {{ offlineLeng }}
            </div>
            <img
              src="@/assets/bulletin/icon4.png"
              class="checkin_information_single_icon"
            />
          </div>
        </div>
      </div>
      <div class="checkin_information_btoom">
        <div class="checkin_information_data">
          <div class="checkin_information_data_top">
            <el-select
              v-model="depeValue"
              @change="selectDepa"
              clearable
              placeholder="请选择"
              class="checkin_information_data_dept"
            >
              <el-option
                v-for="item in depteOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <div class="checkin_information_data_date">
              <div class="checkin_information_shortcuts">
                <div
                  v-for="item in dateList"
                  @click="switchTab(item.value)"
                  :class="[
                    'checkin_information_shortcuts_sing',
                    item.value == currentDate ? 'currentSelect' : '',
                  ]"
                >
                  {{ item.label }}
                </div>
              </div>
              <el-date-picker
                class="checkin_information_date"
                v-model="date"
                type="daterange"
                range-separator="-"
                @change="selectDate"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </div>
          </div>
          <div
            v-if="allStatistics"
            class="checkin_information_data_statistics_all"
          >
            <div class="checkin_information_data_statistics">
              <div
                class="checkin_information_data_statistics_sing"
                v-for="(item, index) in statistics"
                :key="index"
              >
                <div class="checkin_information_data_statistics_title">
                  {{ item.label }}
                </div>
                <div class="checkin_information_data_statistics_people">
                  {{ item.value }}
                </div>
              </div>
            </div>
            <div
              class="checkin_information_data_content"
              id="addressChart"
            ></div>
          </div>
          <div class="checkin_information_data_raduis" v-else>
            <div class="depa_name">{{ selectDepaName }}</div>
            <div
              id="raidsChart"
              class="checkin_information_data_content2"
            ></div>
          </div>
        </div>
        <div class="checkin_information_attendance">
          <div class="checkin_information_attendance_top">假勤申请</div>
          <div class="checkin_information_attendance_content">
            <div v-for="item in attendanceList" :key="item.id" class="singe2">
              <div
                v-if="item.umsUser"
                :class="[
                  'checkin_information_attendance_single',
                  item.status == 0 ? 'ing' : 'end',
                ]"
              >
                <div class="checkin_information_attendance_text">
                  {{ item.umsUser.name }}提交的{{
                    typeofAttendance(item.type)
                  }}申请
                </div>
                <div>{{ item.createTime }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="filing">
      CopyRight@2023广州巨源信息技术有限公司-<a
        href="https://beian.miit.gov.cn/"
        >粤ICP备2023127373号-4</a
      >
    </div>
  </div>
</template>

<script>
import http from "../../utils/http";
import axios from "axios";
import * as echarts from "echarts";
export default {
  data() {
    return {
      onlineLeng: 0, //在线设备
      offlineLeng: 0, //离线设备
      onjob: 0, //在职人员
      resignation: 0, //离职人员
      attendanceList: [], //假勤列表
      allStatistics: true, //全公司统计
      departmentalObject: [], //部门统计
      currentDate: 2,
      depeValue: "", //部门选择
      selectDepaName: "", //部门选择的名字
      depteOptions: [], //部门列表
      showDept: [], //显示的部门
      date: [], //日期选择
      start: "", //开始日期
      end: "", //结束日期
      statistics: [],
      dateList: [
        {
          value: 2,
          label: "本周",
        },
        {
          value: 3,
          label: "本月",
        },
      ],
    };
  },

  watch: {
    // 监听输入框变化
    currentDate(newValue, oldValue) {
      if (newValue) {
        switch (newValue) {
          case 1:
            this.getDay();
            break;
          case 2:
            this.getWeek();
            break;
          case 3:
            this.getMonth();
            break;
          case 4:
            this.getYear();
            break;
        }
      }
    },
  },

  created() {
    // 获取设备列表
    this.getDevice();

    // 获取假勤申请
    this.getAttendance();

    // 获取全部人员
    this.getPeople();

    // 当天的数据
    this.getWeek();

    // 获取公司全部部门
    this.getDepartent();
  },

  methods: {
    // 获取设备列表
    getDevice() {
      http
        .get("/dmsDevice/list", {
          pageSize: 9999,
          pageNum: 1,
          checkOnline:true
        })
        .then((res) => {
          if (res.code == 200) {
            var online = [];
            var offline = [];
            res.data.list.map((item) => {
              if (item.lastUpdateTime) {
                var isonline = this.judgeGobeyond(item.lastUpdateTime);
                if (isonline) {
                  offline.push(item);
                } else {
                  online.push(item);
                }
              } else {
                offline.push(item);
              }
            });

            this.onlineLeng = online.length;
            this.offlineLeng = offline.length;
          }
        });
    },

    // 判断是否超过5分钟
    judgeGobeyond(string) {
      // 给定的时间字符串
      const givenTimeString = string;

      // 将给定的时间字符串转换为Date对象
      const givenTime = new Date(givenTimeString);

      // 获取当前时间
      const currentTime = new Date();

      // 计算时间差，单位为毫秒
      const timeDifference = currentTime - givenTime;

      // 将时间差转换为分钟
      const timeDifferenceInMinutes = timeDifference / (1000 * 60);

      if (timeDifferenceInMinutes > 5) {
        return true;
      } else {
        return false;
      }
    },

    // 获取假勤申请
    getAttendance() {
      http
        .get("/bmsApply/list", {
          pageNum: 1,
          pageSize: 11,
        })
        .then((res) => {
          if (res.code == 200) {
            this.attendanceList = res.data.list;
          }
        });
    },
    // 判断申请类型
    typeofAttendance(type) {
      switch (type) {
        case 0:
          return "加班";
        case 1:
          return "补卡";
        case 2:
          return "请假";
        case 3:
          return "出差";
        case 4:
          return "外出";
      }
    },

    // 获取全部人员
    getPeople() {
      http
        .get("/umsUser/list", {
          pageNum: 1,
          pageSize: 99999,
        })
        .then((res) => {
          if (res.code == 200) {
            var onjob = 0;
            var resignation = 0;
            res.data.list.map((item) => {
              if (item.status == 2 || item.status == 3 || item.status == 4) {
                onjob += 1;
              }

              if (item.status == -1) {
                resignation += 1;
              }
            });

            this.onjob = onjob;
            this.resignation = resignation;
          }
        });
    },

    // 切换日期
    switchTab(index) {
      this.currentDate = index;
    },
    // 获取公司全部门
    getDepartent() {
      http.get("/umsDepartment/list").then((res) => {
        if (res.code == 200) {
          var arr = [];
          res.data.map((item) => {
            item.value2 = 0;
            item.value3 = 0;
            item.value4 = 0;
            item.value5 = 0;
            item.value6 = 0;
            item.value7 = 0;
            arr.push(item.name);
          });
          this.depteOptions = res.data;
          this.showDept = arr;
        }
      });
    },
    // 获取统计
    getStatistics() {
      this.depteOptions.map((item) => {
        item.value2 = 0;
        item.value3 = 0;
        item.value4 = 0;
        item.value5 = 0;
        item.value6 = 0;
        item.value7 = 0;
      });
      http
        .get("/bmsAttendanceRecord/search/statistics", {
          beginDay: this.start,
          endDay: this.end,
          pageSize: 1,
          pageNum: 99999,
        })
        .then((res) => {
          if (res.code == 200) {
            var arr = res.data;
            var value1 = 0; //考勤人数
            var value2 = 0; // 出勤人数
            var value3 = 0; // 旷工天数
            var value4 = 0; //迟到次数
            var value5 = 0; //早退次数
            var value6 = 0; //加班时长
            var value7 = 0; //请假天数

            arr.map((item) => {
              if (item.arrange > 0 || item.arrange == -4) {
                value1 += 1;
              }
              if (item.action) {
                value2 += 1;
              }
              value3 += item.dayAbsence;
              value4 += item.lateCount;
              value5 += item.earlyCount;
              value6 += Math.floor(item.secOvertime / 3600);
              value7 +=
                item.dayRestExchange +
                item.dayRestMarry +
                item.dayRestMaternity +
                item.dayRestMatter +
                item.dayRestOther +
                item.dayRestSick +
                item.dayRestYear;

              // 处理每个部门里面的值
              var index = this.depteOptions.findIndex(
                (citem) => citem.id == item.jobDepartment
              );
              if (index > -1) {
                if (item.action) {
                  this.depteOptions[index].value2 += 1;
                }
                this.depteOptions[index].value3 += item.dayAbsence;
                this.depteOptions[index].value4 += item.lateCount;
                this.depteOptions[index].value5 += item.earlyCount;
                this.depteOptions[index].value6 += Math.floor(
                  item.secOvertime / 3600
                );
                this.depteOptions[index].value7 +=
                  item.dayRestExchange +
                  item.dayRestMarry +
                  item.dayRestMaternity +
                  item.dayRestMatter +
                  item.dayRestOther +
                  item.dayRestSick +
                  item.dayRestYear;
              }
            });

            this.statistics = [
              {
                label: "应出勤天数：",
                value: value1,
              },
              {
                label: "实际出勤天数：",
                value: value2,
              },
              {
                label: "旷工天数：",
                value: value3.toFixed(1),
              },
              {
                label: "迟到次数：",
                value: value4,
              },

              {
                label: "早退次数：",
                value: value5,
              },
              {
                label: "加班小时：",
                value: value6,
              },
              {
                label: "请假天数：",
                value: value7,
              },
            ];

            console.log(this.depteOptions);
            // 图标统计
            this.addressEcharts();
          }
        });
    },

    // 获取当日日期
    getDay() {
      var arr = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }];
      var arr2 = [
        { top: 3, num: 5 },
        { top: 1, num: 2 },
        { top: 1, num: 3 },
        { top: 2, num: 4 },
      ];
      const now = new Date(); // 创建一个新的Date对象，表示当前日期和时间

      // 提取年、月、日
      const year = now.getFullYear(); // 获取年份（四位数）
      const month = String(now.getMonth() + 1).padStart(2, "0"); // 获取月份（从0开始，所以需要+1），并确保是两位数
      const day = String(now.getDate()).padStart(2, "0"); // 获取日期，并确保是两位数

      // 格式化日期字符串
      const formattedDate = `${year}-${month}-${day}`;

      this.start = formattedDate;
      this.end = formattedDate;

      this.getStatistics();
    },

    // 获取本周日期
    getWeek() {
      const today = new Date();
      const dayOfWeek = today.getDay(); // 获取今天是星期几（0表示星期日，1表示星期一，以此类推）

      // 计算本周周一的日期
      const weekStartDate = new Date(today);
      weekStartDate.setDate(
        today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1)
      ); // 如果dayOfWeek是0（周日），则减6，否则加1

      // 计算本周周日的日期
      const weekEndDate = new Date(weekStartDate);
      weekEndDate.setDate(weekStartDate.getDate() + 6);

      // 返回周一和周日的日期
      var start = weekStartDate.toISOString().split("T")[0];
      var end = today.toISOString().split("T")[0];

      this.start = start;
      this.end = end;
      this.date = [];

      if (this.allStatistics) {
        this.getStatistics();
      } else {
        this.selectDepa(this.depeValue);
      }
    },

    // 获取当月的日期
    getMonth() {
      // 获取当前日期
      const now = new Date();

      // 获取当月的第一天
      const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);

      // 获取当月的最后一天
      const lastDay = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate()
      );

      // 格式化日期为 YYYY-MM-DD
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // 月份从0开始计数，需要加1，并保证两位数
        const day = String(date.getDate()).padStart(2, "0"); // 获取日期，并保证两位数
        return `${year}-${month}-${day}`;
      };

      this.start = formatDate(firstDay);
      this.end = formatDate(lastDay);
      this.date = [];

      if (this.allStatistics) {
        this.getStatistics();
      } else {
        this.selectDepa(this.depeValue);
      }
    },

    // 获取当年的日期
    getYear() {
      // 获取当前日期
      const now = new Date();

      // 获取当年的第一天
      const firstDayOfYear = new Date(now.getFullYear(), 0, 1);

      // 获取当年的最后一天
      const lastDayOfYear = new Date(now.getFullYear(), 11, 31);

      // 格式化日期为 YYYY-MM-DD
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // 月份从0开始计数，需要加1，并保证两位数
        const day = String(date.getDate()).padStart(2, "0"); // 获取日期，并保证两位数
        return `${year}-${month}-${day}`;
      };

      this.start = formatDate(firstDayOfYear);
      this.end = formatDate(lastDayOfYear);
      this.date = [];

      if (this.allStatistics) {
        this.getStatistics();
      } else {
        this.selectDepa(this.depeValue);
      }
    },

    // 选择日期
    selectDate(arr) {
      const formattedDates = arr.map((dateString) => {
        // 创建一个 Date 对象
        const date = new Date(dateString);

        // 使用 getFullYear, getMonth (注意月份是从0开始的，所以要+1), getDate 来获取年月日
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // 使用 padStart 来确保月份总是两位数
        const day = String(date.getDate()).padStart(2, "0"); // 使用 padStart 来确保日期总是两位数

        // 返回格式化后的日期字符串
        return `${year}-${month}-${day}`;
      });

      this.start = formattedDates[0];
      this.end = formattedDates[1];
      this.currentDate = -1;

      if (this.allStatistics) {
        this.getStatistics();
      } else {
        this.selectDepa(this.depeValue);
      }
    },

    // 选择部门
    selectDepa(val) {
      if (val) {
        this.allStatistics = false;
        this.addressChart.dispose();
        http
          .get("/bmsAttendanceRecord/search/statistics", {
            beginDay: this.start,
            endDay: this.end,
            pageNum: 1,
            pageSize: 9999,
            departmentIdList: val,
          })
          .then((res) => {
            if (res.code == 200) {
              var value = this.depteOptions.find((item) => {
                return item.id == val;
              });
              var arr = [];
              var absenteeism = 0; //旷工
              var late = 0; //迟到
              var overtime = 0; //加班
              var early = 0; //早退
              var leave = 0; //请假

              res.data.map((item) => {
                absenteeism += item.dayAbsence;
                late += item.lateCount;
                overtime += item.secOvertime / 3600;
                early += item.earlyCount;
                var allDay =
                  item.dayRestExchange +
                  item.dayRestMarry +
                  item.dayRestMaternity +
                  item.dayRestMatter +
                  item.dayRestOther +
                  item.dayRestSick +
                  item.dayRestYear;

                leave += allDay;
              });

              arr = [
                {
                  value: absenteeism,
                  name: "旷工(天)",
                },
                {
                  value: late,
                  name: "迟到(次数)",
                },
                {
                  value: overtime.toFixed(1),
                  name: "加班(小时)",
                },
                {
                  value: early,
                  name: "早退(次数)",
                },
                {
                  value: leave,
                  name: "请假(天)",
                },
              ];

              if (value) {
                this.selectDepaName = value.name;
              }
              this.$nextTick(() => {
                this.radisChart(arr);
              });
            }
          });
      } else {
        this.allStatistics = true;
        this.raidsChart.dispose();
        setTimeout(() => {
          this.getStatistics();
        }, 1000);
      }
    },

    // 圆形环状图
    radisChart(arr) {
      var option = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          top: "5%",
          left: "center",
        },
        series: [
          {
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            padAngle: 5,
            itemStyle: {
              borderRadius: 10,
            },
            label: {
              show: true,
              formatter: function (arg) {
                return arg.name + "-" + arg.value;
              },
            },
            emphasis: {
              label: {
                show: false,
                fontSize: 40,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: true,
            },
            data: arr,
          },
        ],
      };

      this.raidsChart = echarts.init(document.getElementById("raidsChart"));
      this.raidsChart.setOption(option);
      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        this.raidsChart.resize();
      });
    },

    // 图标
    addressEcharts() {
      var endPercent = (10 / this.showDept) * 100;
      var arr2 = []; //出勤人数
      var arr3 = []; //旷工天数
      var arr4 = []; //迟到次数
      var arr5 = []; //早退次数
      var arr6 = []; //加班时数
      var arr7 = []; //请假天数
      this.depteOptions.map((item) => {
        arr2.push(item.value2);
        arr3.push(item.value3);
        arr4.push(item.value4);
        arr5.push(item.value5);
        arr6.push(item.value6);
        arr7.push(item.value7);
      });
      let option = {
        // 图例
        legend: {
          left: 10, // 让图例靠右 可以是数值 也可以是"%"
          icon: "circle",
        },
        // 提示框
        tooltip: {
          trigger: "axis", // 触发类型 axis-坐标轴触发(主要在柱状图，折线图等会使用类目轴的图表中使用)
          // 坐标轴指示器配置项
          axisPointer: {
            type: "shadow",
          },
        },
        // 绘图网格
        grid: {
          left: "3%", // 离容器左侧的距离
          right: "4%", // 离容器右侧的距离
          bottom: "3%", // 离容器下侧的距离
          containLabel: true, // grid 区域是否包含坐标轴的刻度标签
        },
        // X轴
        xAxis: {
          type: "category", // 坐标轴类型为category-类目轴
          data: this.showDept, //类目数据[]
          axisLabel: {
            //坐标轴刻度标签的相关设置
            show: true, //是否显示
            textStyle: {
              color: "#7E84A3",
              borderWidth: 0,
              fontSize: 14,
            },
          },
          axisTick: {
            show: false, //是否显示
          },
        },
        // Y轴
        yAxis: {
          type: "value", // 坐标轴类型为数值轴
          minInterval: 1, // 最小间隔大小 因为此项目该数据不可能有小数 所以设置为1
          min: 10,
          max: (value) => {
            return value.max;
          },
          boundaryGap: [0, "10%"], //坐标轴两边留白策略 非类目轴是是一个两个值的数组，分别表示数据最小值和最大值的延伸范围，可以直接设置数值或者相对的百分比
        },
        // 系列 有几个系列写几组
        series: [
          {
            name: "出勤", // 系列名称，用于tooltip的显示，legend的图例筛选
            type: "bar", // bar-柱状图
            barWidth: 16,
            itemStyle: {
              // 图形样式。
              //柱条颜色
              color: "#21D59B",
            },
            data: arr2, //数据内容数组
          },
          {
            name: "旷工", // 系列名称，用于tooltip的显示，legend的图例筛选
            type: "bar", // bar-柱状图
            barWidth: 16,
            itemStyle: {
              // 图形样式。
              //柱条颜色
              color: "#0062FF",
            },
            data: arr3, //数据内容数组
          },
          {
            name: "迟到", // 系列名称，用于tooltip的显示，legend的图例筛选
            type: "bar", // bar-柱状图
            barWidth: 16,
            itemStyle: {
              // 图形样式。
              //柱条颜色
              color: "#36C9C9",
            },
            data: arr4, //数据内容数组
          },
          {
            name: "早退", // 系列名称，用于tooltip的显示，legend的图例筛选
            type: "bar", // bar-柱状图
            barWidth: 16,
            itemStyle: {
              // 图形样式。
              //柱条颜色
              color: "#FBD437",
            },
            data: arr5, //数据内容数组
          },
          {
            name: "加班", // 系列名称，用于tooltip的显示，legend的图例筛选
            type: "bar", // bar-柱状图
            barWidth: 16,
            itemStyle: {
              // 图形样式。
              //柱条颜色
              color: "#F2637B",
            },
            data: arr6, //数据内容数组
          },
          {
            name: "请假", // 系列名称，用于tooltip的显示，legend的图例筛选
            type: "bar", // bar-柱状图
            barWidth: 16,
            itemStyle: {
              // 图形样式。
              //柱条颜色
              color: "#975FE5",
            },
            data: arr7, //数据内容数组
          },
        ],
        /*设置可左右滑动*/
        dataZoom: [
          {
            type: "inside", //鼠标可拖动
            start: 0, //滚动条开始位置
            end: endPercent, //滚动条结束位置
            handleSize: 2,
          },
        ],
      };

      this.addressChart = echarts.init(document.getElementById("addressChart"));
      this.addressChart.setOption(option);
      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        this.addressChart.resize();
      });
    },
  },
};
</script>

<style scoped>
.checkin {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  box-sizing: border-box;
  background: #f4f7fc;
}
.checkin_title {
  font-size: 14px;
  color: #5c5c5c;
  margin-bottom: 10px;
}
.checkin_information {
  width: 100%;
  overflow-y: scroll;
  display: flex;
  flex: 1;
  flex-direction: column;
  box-sizing: border-box;
}
.checkin_information::-webkit-scrollbar {
  display: none;
}
.checkin_information_single {
  width: 380px;
  height: 130px;
  background: white;
  box-shadow: 0px 1px 4px 1px rgba(21, 34, 50, 0.08);
  border-radius: 6px 6px 6px 6px;
  padding: 15px 30px 0 30px;
  box-sizing: border-box;
}
.checkin_information_single_icon {
  width: 42px;
  height: 42px;
}
.checkin_information_single_content {
  display: flex;
  align-items: center;
  margin: 14px 0;
  justify-content: space-between;
}
.checkin_information_single_people {
  font-size: 41px;
  color: #313b5e;
  font-weight: bold;
}
.checkin_information_data_statistics_all {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.checkin_information_single_efficiency {
  display: flex;
  align-items: center;
}
.checkin_information_single_up {
  color: #3dd598;
  display: flex;
  align-items: center;
}
.checkin_information_single_dowm {
  color: #f0142f;
  display: flex;
  align-items: center;
}
.checkin_information_single_compare {
  color: rgba(49, 59, 94, 0.45);
  margin-left: 10px;
}
.checkin_information_single_up_icon {
  width: 16px;
  height: 16px;
}
.checkin_information_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.checkin_information_btoom {
  display: flex;
  align-items: center;
  margin-top: 10px;
  flex: 1;
  justify-content: space-between;
}
.checkin_information_data {
  width: 1213px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 1px 4px 1px rgba(21, 34, 50, 0.08);
  border-radius: 6px 6px 6px 6px;
}
.checkin_information_attendance {
  width: 380px;
  height: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 4px 1px rgba(21, 34, 50, 0.08);
  border-radius: 6px 6px 6px 6px;
}
.checkin_information_attendance_top {
  width: 100%;
  height: 69px;
  line-height: 69px;
  padding-left: 20px;
  color: rgba(0, 0, 0, 0.65);
  border-bottom: 1px solid #e8e8e8;
  box-sizing: border-box;
}
.checkin_information_attendance_content {
  flex: 1;
  overflow-y: auto;
  width: 327px;
  max-height: 450px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.singe2 {
  width: 100%;
  height: 75px;
}
.checkin_information_attendance_single {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 14px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: 1px solid #e8e8e8;
}
.ing {
  position: relative;
}
.ing::after {
  content: "";
  background: #f0142f;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.end {
  position: relative;
}
.end::after {
  content: "";
  background: rgba(0, 0, 0, 0.4);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.checkin_information_data_top {
  width: 100%;
  height: 69px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px 0 30px;
  box-sizing: border-box;
  border-bottom: 1px solid #e8e8e8;
}
.checkin_information_data_date {
  display: flex;
  align-items: center;
}
.checkin_information_shortcuts {
  display: flex;
  align-items: center;
  width: 115px;
  justify-content: space-between;
  margin-right: 30px;
}
.checkin_information_shortcuts_sing {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}
.currentSelect {
  color: #0058ff;
}
.checkin_information_data_dept {
  width: 140px;
  height: 32px;
}
::v-deep .checkin_information_data_dept .el-input {
  height: 100%;
}
::v-deep .checkin_information_data_dept .el-input__inner {
  height: 100%;
}
.checkin_information_date {
  width: 256px !important;
  height: 32px;
}
::v-deep .el-date-editor .el-range__icon {
  line-height: 28px !important;
}
::v-deep .el-date-editor .el-range-separator {
  line-height: 22px !important;
}
.checkin_information_data_content {
  width: 100%;
  padding: 0 69px 0 58px;
  box-sizing: border-box;
  max-height: 400px;
  flex: 1;
}
.checkin_information_data_raduis {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.checkin_information_data_content2 {
  width: 100%;
  flex: 1;
}
.checkin_information_data_statistics {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 40px;
  margin: 14px 0 10px 0;
}
.checkin_information_data_statistics_sing {
  display: flex;
  align-items: center;
}
.checkin_information_data_statistics_title {
  color: rgba(19, 21, 35, 0.65);
  font-size: 16px;
}
.checkin_information_data_statistics_people {
  font-size: 30px;
  font-weight: bold;
  color: #131523;
  margin-left: 5px;
}
.depa_name {
  font-size: 20px;
  color: #131523;
  font-weight: bold;
  margin: 20px 0 0 46px;
}
::v-deep .el-input__icon {
  line-height: 1.5rem;
}
.checkin_information_attendance_text {
  width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
