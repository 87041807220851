<template>
  <div class="visitor_information">
    <div class="visitor_information_title">
      考勤管理 / <span>班次设置</span>
    </div>
    <div class="visitor_information_c">
      <div class="visitor_information_t">
        <div class="visitor_information_search">
          <el-input
            class="department_search_inp"
            v-model="searchValue"
            @keyup.enter.native="seachResult"
            placeholder="搜索班次名称"
          ></el-input>
          <img
            src="@/assets/structure/delete.png"
            class="searchicon"
            @click="clearup"
            v-if="searchicon"
          />
          <img
            src="@/assets/structure/search.png"
            class="searchicon"
            @click="seachResult"
          />
        </div>
        <el-button
          @click="newFormVisible"
          type="primary"
          icon="el-icon-plus"
          class="structure_addstaff"
          >新建班次</el-button
        >
      </div>
      <div class="visitor_information_table">
        <el-table
          height="100%"
          ref="multipleTable"
          :data="tableData"
          class="v_table"
          :header-cell-style="getRowClass"
        >
          <template slot="empty">
            <div>
              <img src="@/assets/nodata.png" class="nodata" />
              <div>无数据</div>
            </div>
          </template>
          <el-table-column
            label="班次名称"
            width="200"
            header-align="left"
            align="left"
            prop="name"
          >
          </el-table-column>
          <el-table-column label="考勤时间" width="250">
            <template slot-scope="scope">
              <el-tooltip
                effect="dark"
                :content="attendanceTime(scope.row.arrangeDataList).join(',')"
                placement="top"
              >
                <div class="time_list">
                  <div
                    class="time_single"
                    v-for="(item, index) in attendanceTime(
                      scope.row.arrangeDataList
                    )"
                    :key="index"
                  >
                    {{ item }}
                  </div>
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="创建人" width="150">
            <template slot-scope="scope">
              <div v-if="scope.row.umsUser && !scope.row.type">
                {{ scope.row.umsUser.name }}
              </div>
              <div v-else>系统默认</div>
            </template>
          </el-table-column>
          <el-table-column prop="updateTime" label="最后更新时间" width="200">
          </el-table-column>
          <el-table-column label="操作" width="140">
            <template slot-scope="scope">
              <el-link
                type="primary"
                style="margin-right: 24px"
                @click="checkInfo(scope.row)"
                >查看</el-link
              >
              <el-link
                type="primary"
                style="margin-right: 24px"
                @click="editDiglog(scope.row)"
                >编辑</el-link
              >
              <el-link
                type="danger"
                v-if="!scope.row.type"
                style="margin-right: 24px"
                @click="deleteInvitefirst(scope.row.id)"
                >删除</el-link
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div
        class="newpagination"
        v-if="pages.total > 0"
        style="padding-left: 20px; box-sizing: border-box"
      >
        <div class="newpagination_text">共 {{ pages.total }} 条数据</div>
        <el-pagination
          layout="prev, pager, next"
          :total="pages.total"
          :current-page="pages.pageNum"
          :page-size="10"
          @current-change="chagePage"
        >
        </el-pagination>
      </div>
    </div>
    <div class="filing">
      CopyRight@2023广州巨源信息技术有限公司-<a
        href="https://beian.miit.gov.cn/"
        >粤ICP备2023127373号-4</a
      >
    </div>
    <!-- 新建班次 -->
    <el-dialog
      :title="tips"
      @close="cancelDiglog"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      width="70%"
      :key="key"
      class="classdiglog"
    >
      <AddClasses
        :isCheck="isCheck"
        @cancel="cancelDiglog"
        @comfin="comfinDiglog"
        :id="selectUserid"
      />
    </el-dialog>

    <!-- 删除班次弹窗 -->
    <Diglog
      @closeDiglog="closeDelete"
      @affirmDiglog="deleteClasses(selectUserid)"
      :isShow="delectVistorDiglog"
      :title="'提示'"
      :tips="'班次删除后，将影响目前所在班次考勤组的考勤，你确定要删除吗？'"
    />

    <!-- 编辑班次弹窗 -->
    <Diglog
      @closeDiglog="closeEdit"
      @affirmDiglog="editInfo()"
      :isShow="editclassDiglog"
      :title="'提示'"
      :tips="'班次编辑后，将影响目前所在班次考勤组的考勤，你确定要编辑吗？'"
    />
  </div>
</template>

<script>
import http from "../../../utils/http";
import exprotExcel from "../../../utils/exprotExcel";
import Diglog from "../../../assembly/dialog.vue";
import AddClasses from "./addClasses.vue";
export default {
  data() {
    return {
      searchValue: "", //搜索的关键词
      searchicon: false, //监听输入
      inviteVisitor: false, //邀请访客
      editclassDiglog: false, //编辑班次弹窗
      id: null, //标识
      tips: "新建班次",
      selectUserid: null, //选择的id
      isCheck: false, //是否是查看状态
      tableData: [], //表格数据
      dialogFormVisible: false,
      value1: "",
      timedifference: 0,
      key: null,
      delectVistorDiglog: false,
      selectUserid: [], //最终删除的数组
      pages: {}, //分页
      a: [],
      delectVistorDiglog: false, //删除访客提示框
      tableColumn: [
        {
          title: "访客姓名",
          dataIndex: "name",
          id: 1,
        },
        {
          title: "身份证",
          dataIndex: "idCardnew",
          id: 2,
        },
        {
          title: "手机号",
          dataIndex: "phone",
          id: 3,
        },
        {
          title: "公司",
          dataIndex: "companySelf",
          id: 4,
        },
        {
          title: "是否是黑名单",
          dataIndex: "hei",
          id: 5,
        },
      ], //导出内容参数
    };
  },
  created() {
    // 获取访客列表
    this.getVisitor({
      pageNum: 1,
    });

    var cache = localStorage.getItem("userinfo");
    this.id = JSON.parse(cache).id;
  },
  components: {
    Diglog,
    AddClasses,
  },
  watch: {
    searchValue(newName, oldName) {
      if (newName) {
        this.searchicon = true;
      } else {
        this.searchicon = false;

        // 重新获取审批列表
        this.getVisitor({
          pageNum: 1,
        });
      }
    },
  },
  methods: {
    // 表头背景颜色变色
    getRowClass({ rowIndex, columnIndex }) {
      if (rowIndex == 0) {
        return "background:#F5F6FA;color:#5C5C5C";
      }
    },
    // 秒转换成时间
    secondsToHourMin(seconds) {
      if (seconds >= 86400) {
        seconds = seconds - 86400;
      }
      // 将秒数转换为分钟
      let minutes = Math.floor(seconds / 60);
      // 将分钟转换为小时和分钟
      let hours = Math.floor(minutes / 60);
      let minutesRemainder = minutes % 60;

      // 格式化小时和分钟
      hours = hours < 10 ? "0" + hours : hours;
      minutesRemainder =
        minutesRemainder < 10 ? "0" + minutesRemainder : minutesRemainder;

      // 返回时分格式
      return hours + ":" + minutesRemainder;
    },
    // 时间换为分钟
    timeToMinutes(time) {
      const [hour, minute] = time.split(":").map(Number);
      return hour * 60 + minute;
    },
    // 时间换为秒
    timeToSecond(time) {
      const [hour, minute] = time.split(":").map(Number);
      return hour * 3600 + minute * 60;
    },
    // 获取班次列表
    getVisitor(val) {
      var object = {};
      if (this.searchValue) {
        object = {
          name: this.searchValue,
        };
      }
      http
        .get(
          "/bmsAttendanceArrange/list",
          Object.assign(
            val,
            {
              pageSize: 10,
            },
            object
          )
        )
        .then((res) => {
          if (res.code == 200) {
            this.pages = res.data;
            this.tableData = res.data.list;
          }
        });
    },

    // 打开弹窗
    newFormVisible() {
      this.key = Math.random();
      this.selectUserid = null;
      setTimeout(() => {
        this.dialogFormVisible = true;
      }, 200);
    },
    // 取消弹窗
    cancelDiglog() {
      this.dialogFormVisible = false;
      this.selectUserid = null;
      this.isCheck = false;
      this.tips = "新建班次";
    },
    // 确认选择
    comfinDiglog(value) {
      var confimForm = {}; //最终提交的表单
      // 深拷贝
      confimForm = JSON.stringify(value);
      confimForm = JSON.parse(confimForm);

      var shiftList = confimForm.arrangeDataList; //提交的班次列表
      var ruleList = confimForm.clockDataList; //休息时间列表
      // 要检查的键
      let keysToCheck = [
        "errortips",
        "errortips2",
        "errorignore",
        "errorAbsenteeism",
        "errorEearlyAbsenteeism",
        "errorEearly",
      ];
      let containsAtLeastOneKey = shiftList.some((obj) => {
        return keysToCheck.some((key) => obj.hasOwnProperty(key));
      });
      // 如果存在错误
      if (containsAtLeastOneKey) {
        return;
      }
      // 要检查的键(休息时间)
      let keysToCheck2 = ["error"];
      let containsAtLeastOneKey2 = ruleList.some((obj) => {
        return keysToCheck2.some((key) => obj.hasOwnProperty(key));
      });
      // 如果存在错误(休息时间)
      if (containsAtLeastOneKey2) {
        return;
      }

      shiftList.map((item) => [(item.clockDataList = [])]);

      ruleList.map((item) => {
        if (item.beginTimeData.daySecond) {
          item.beginTimeData.daySecond = this.timeToSecond(
            item.beginTimeData.daySecond
          );
          if (item.beginTimeData.tomorrow) {
            item.beginTimeData.daySecond = item.beginTimeData.daySecond + 86400;
          }
        }

        if (item.endTimeData.daySecond) {
          item.endTimeData.daySecond = this.timeToSecond(
            item.endTimeData.daySecond
          );
          if (item.endTimeData.tomorrow) {
            item.endTimeData.daySecond = item.endTimeData.daySecond + 86400;
          }
        }

        shiftList[item.indexPeriod].clockDataList.push({
          beginDaySecond: item.beginTimeData.daySecond,
          endDaySecond: item.endTimeData.daySecond,
        });
      });

      shiftList.map((item) => {
        // 要删除的属性列表(上班)
        let propertiesToRemove = [
          "beforeAttendanceTime",
          "beginAbsenceTime",
          "beginIgnoreTime",
          "beginChoose",
          "lateTime",
        ];
        // 要删除的属性列表(下班)
        let propertiesToRemove2 = [
          "endChoose",
          "endIgnoreTime",
          "afterAttendanceTime",
        ];
        // 判断需不需要打卡(上班卡)
        if (!item.beginTimeChoose) {
          propertiesToRemove.forEach((prop) => {
            if (item.hasOwnProperty(prop)) {
              delete item[prop];
            }
          });
        }
        // 判断需不需要打卡(下班卡)
        if (!item.endTimeChoose) {
          propertiesToRemove2.forEach((prop) => {
            if (item.hasOwnProperty(prop)) {
              delete item[prop];
            }
          });
        }
        // 转化
        if (item.afterAttendanceTime) {
          item.afterAttendanceTime = parseInt(item.afterAttendanceTime) * 60;
        }
        if (item.beforeAttendanceTime) {
          item.beforeAttendanceTime = parseInt(item.beforeAttendanceTime) * 60;
        }
        if (item.beginAbsenceTime) {
          item.beginAbsenceTime = parseInt(item.beginAbsenceTime) * 60;
        }
        if (item.beginNoneTime) {
          item.beginNoneTime = parseInt(item.beginNoneTime) * 60;
        }
        if (item.endNoneTime) {
          item.endNoneTime = parseInt(item.endNoneTime) * 60;
        }
        if (item.beginIgnoreTime) {
          item.beginIgnoreTime = parseInt(item.beginIgnoreTime) * 60;
        }
        if (item.endIgnoreTime) {
          item.endIgnoreTime = parseInt(item.endIgnoreTime) * 60;
        }
        if (item.lateTime) {
          item.lateTime = parseInt(item.lateTime) * 60;
        }
        if (item.earlyTime) {
          item.earlyTime = parseInt(item.earlyTime) * 60;
        }
        if (item.earlyAbsenceTime) {
          item.earlyAbsenceTime = parseInt(item.earlyAbsenceTime) * 60;
        }

        if (item.clockData.beginTimeData.daySecond) {
          item.clockData.beginDaySecond = this.timeToSecond(
            item.clockData.beginTimeData.daySecond
          );
          if (item.clockData.beginTimeData.tomorrow) {
            item.clockData.beginDaySecond =
              item.clockData.beginDaySecond + 86400;
          }

          delete item.clockData.beginTimeData;
        }

        if (item.clockData.endTimeData.daySecond) {
          item.clockData.endDaySecond = this.timeToSecond(
            item.clockData.endTimeData.daySecond
          );
          if (item.clockData.endTimeData.tomorrow) {
            item.clockData.endDaySecond = item.clockData.endDaySecond + 86400;
          }

          delete item.clockData.endTimeData;
        }

        item.clockDataList.map((citem) => {
          delete citem.indexPeriod;
        });
      });

      delete confimForm.clockDataList;

      // 最终确认
      if (confimForm.id) {
        http.post("/bmsAttendanceArrange/update", confimForm).then((res) => {
          if (res.code == 200) {
            this.$message.success("修改成功");

            this.dialogFormVisible = false;

            // 重新获取访客列表
            this.getVisitor({
              pageNum: 1,
            });
          } else {
            this.$message.error(res.message);
          }
        });
      } else {
        http
          .post(
            "/bmsAttendanceArrange/add",
            Object.assign(
              {
                creator: this.id,
              },
              confimForm
            )
          )
          .then((res) => {
            if (res.code == 200) {
              this.$message.success("添加成功");

              this.dialogFormVisible = false;

              // 重新获取访客列表
              this.getVisitor({
                pageNum: 1,
              });
            } else {
              this.$message.error(res.message);
            }
          });
      }
    },
    // 查看详细信息
    checkInfo(val) {
      this.key = Math.random();
      this.isCheck = true;
      this.selectUserid = val.id;
      this.tips = "查看班次";

      setTimeout(() => {
        this.dialogFormVisible = true;
      }, 500);
    },

    // 编辑信息弹窗
    editDiglog(val) {
      this.key = Math.random();
      this.tips = "编辑班次";
      this.selectUserid = val.id;

      setTimeout(() => {
        this.editclassDiglog = true;
      }, 500);
    },

    // 取消编辑信息弹窗
    closeEdit() {
      this.editclassDiglog = false;
      this.cancelDiglog();
    },

    // 编辑信息
    editInfo() {
      this.dialogFormVisible = true;
      this.editclassDiglog = false;
    },
    // 考勤时间
    attendanceTime(arr) {
      var newArr = [];
      if (arr) {
        arr.map((item) => {
          var start = ""; //开始时间
          var end = ""; //结束时间
          if (
            item.clockData.beginDaySecond ||
            item.clockData.beginDaySecond == 0
          ) {
            start = this.secondsToHourMin(item.clockData.beginDaySecond);
          }
          if (item.clockData.endDaySecond) {
            end = this.secondsToHourMin(item.clockData.endDaySecond);
          }
          newArr.push(start + "-" + end);
        });

        return newArr;
      } else {
        return newArr;
      }
    },
    // 表格下载之前
    startDownload(arr) {
      let target = event.target;
      if (target.nodeName == "I" || target.nodeName == "SPAN") {
        target = event.target.parentNode;
      }
      target.blur();
      if (arr.length == 0) {
        this.$message.error("请先选择访客");
        return;
      }
      let arr2 = [];
      for (let key of this.tableColumn) {
        arr2.push(key.key);
      }
      // const loading = this.$loading({
      //   lock: true,
      //   text: "导出表格中，请稍后...",
      //   spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.7)",
      // });
      arr.map((v) => {
        v.idCardnew = "\xa0" + v.idCard;
        if (v.isBlack == 0) {
          v.hei = "否";
        } else {
          v.hei = "是";
        }
        for (let i = 0; i < arr2.length; i++) {
          if (v[arr2[i]] == undefined) {
            v[arr2[i]] = "";
          }
        }
        for (let key in v) {
          if (v[key] == null) v[key] = "";
        }
      });
      exprotExcel.export(this.tableColumn, arr, "访客列表");
      this.$message.success("导出成功");
      this.$refs.multipleTable.clearSelection();
    },
    // 页数改变
    chagePage(e) {
      this.getVisitor({
        pageNum: e,
      });
    },

    // 搜索结果
    seachResult() {
      if (this.searchValue == "") {
        return;
      }

      this.getVisitor({
        pageNum: 1,
      });
    },

    // 清除输入框
    clearup() {
      this.searchValue = "";
    },

    // 删除班次
    deleteClasses(ids) {
      http
        .post("/bmsAttendanceArrange/delete", {
          ids: [ids],
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("删除成功");

            this.delectVistorDiglog = false;

            // 重新获取访客列表
            this.getVisitor({
              pageNum: 1,
            });
          } else {
            this.$message.error(res.message);
          }
        });
    },

    // 删除班次弹窗
    deleteInvitefirst(ids) {
      let target = event.target;
      if (target.nodeName == "I" || target.nodeName == "SPAN") {
        target = event.target.parentNode;
      }
      target.blur();
      setTimeout(() => {
        this.delectVistorDiglog = true;
      }, 200);
      this.selectUserid = ids;
    },

    // 取消删除
    closeDelete() {
      this.delectVistorDiglog = false;
    },
  },
};
</script>

<style scoped>
.visitor_information {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  box-sizing: border-box;
  background: #f4f7fc;
}

.visitor_information_c {
  width: 100%;
  box-shadow: 0px 1px 4px 1px rgba(21, 34, 50, 0.08);
  flex: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 6px;
  background: white;
}

.visitor_information_t {
  height: 76px;
  padding: 0 30px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(207, 212, 232, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.visitor_information_search {
  width: 243px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #d0d0d0;
  display: flex;
  align-items: center;
  padding-right: 12px;
  box-sizing: border-box;
  justify-content: space-between;
}

.department_search_inp {
  width: 200px;
}

.visitor_information_table {
  width: 100%;
  flex: 1;
  padding: 20px;
  position: relative;
  box-sizing: border-box;
}

.visitor_information_title {
  font-size: 14px;
  color: #b2b2b2;
  margin-bottom: 16px;
}

.visitor_information_title span {
  color: #5c5c5c;
}

.memeber_operation {
  margin-left: 8px;
  width: 107px;
}

.detailed {
  width: 86px;
  height: 26px;
  background: #e0ecff;
  color: #0058ff;
  font-size: 14px;
  text-align: center;
  line-height: 26px;
  cursor: pointer;
}

.qrcode {
  width: 100%;
}

.qrcode_frame {
  width: 457px;
  height: 358px;
  display: flex;
  color: #1a1a1a;
  margin: 60px auto 24px;
  flex-direction: column;
  align-items: center;
  padding-top: 46px;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.16);
}

.qrcode_name {
  font-size: 16px;
  margin-bottom: 30px;
}

.qrcode_img {
  width: 200px;
  height: 200px;
  background: black;
}

.qrcode_tips {
  color: #9a9a9a;
  font-size: 15px;
  text-align: center;
}

.qrcode_bto {
  display: flex;
  margin-top: 39px;
  justify-content: center;
}

.link_frame {
  width: 797px;
  height: 132px;
  margin: 30px;
  font-size: 16px;
  padding: 24px;
  color: #1a1a1a;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.16);
}

.link_tips {
  font-size: 15px;
  color: #9a9a9a;
  padding-left: 30px;
  box-sizing: border-box;
}

.link_bto {
  display: flex;
  margin-top: 289px;
  justify-content: center;
}

.invite_h {
  height: 73px;
  padding: 0 24px;
  color: #1a1a1a;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 18px;
  justify-content: space-between;
}

.invite_icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.visitor_name {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #1a1a1a;
}

.visitor_head {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 10px;
}

.primary-button-style {
  background: #2979ff;
  border-color: #2979ff;
}

::v-deep .el-table__body .el-table__row.hover-row td {
  background-color: rgba(0, 98, 255, 0.05);
}

.newpagination {
  height: 50px;
  margin-top: 30px;
}

.visitor_information_search:focus-within {
  border-color: #0096fa;
}

.v_table {
  width: 97%;
  height: 100%;
  position: absolute;
}
.time_list {
  display: flex;
  width: 370px;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.time_single {
  flex: 0 0 116px;
  width: 116px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 16px;
  color: #1a1a1a;
  background: #dee4ed;
  border-radius: 4px 4px 4px 4px;
  margin-right: 8px;
}
</style>
