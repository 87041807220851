<template>
  <div class="group common-style common-table_style">
    <div class="group_head">
      <span style="color: #b2b2b2">考勤管理 /</span>
      <span style="color: #5c5c5c"> 考勤组管理</span>
    </div>
    <div class="group_wrapper">
      <div class="group_search_wrapper">
        <div class="group_search">
          <div class="group_search_t group_search_item_n">
            <div class="approval_search">
              <el-input
                v-model="seachName"
                placeholder="考勤组名称"
                @keyup.enter.native="onSearch()"
                class="department_search_inp"
              ></el-input>
              <img
                src="@/assets/structure/delete.png"
                class="searchicon"
                @click="clearup"
                v-if="seachIcon"
              />
              <img
                src="@/assets/structure/search.png"
                class="searchicon_t"
                @click="onSearch()"
              />
            </div>
          </div>
        </div>
        <div class="group_add">
          <el-button @click="addGroup" type="primary" icon="el-icon-plus"
            >新建考勤组</el-button
          >
        </div>
        <div>
          <el-button @click="startDownload">导出考勤组</el-button>
        </div>
      </div>
      <div class="group_table_wrapper">
        <el-table
          ref="multipleTable"
          v-loading="tableloading"
          height="96%"
          :data="onList"
          :header-cell-style="{
            background: '#F5F6FA',
            color: '#5C5C5C',
          }"
          class="group_table common_text"
        >
          <template slot="empty">
            <div>
              <img src="@/assets/nodata.png" class="nodata" />
              <div class="nodata_text">无数据</div>
            </div>
          </template>
          <el-table-column
            label="考勤组名称"
            header-align="left"
            align="left"
            width="150"
          >
            <template slot-scope="scope">
              <span class="ellipsis">{{ scope.row.name }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="考勤类型"
            header-align="left"
            align="left"
            width="80"
          >
            <template slot-scope="scope">{{
              judgeType(scope.row.attendanceGroupConfigData)
            }}</template>
          </el-table-column>
          <el-table-column
            label="考勤时间"
            header-align="left"
            align="left"
            width="300"
          >
            <template slot-scope="scope">
              <el-tooltip
                effect="dark"
                :content="filterAttendance(scope.row, 2)"
                placement="top"
              >
                <div class="time_list">
                  <div
                    class="time_single"
                    v-for="(item, index) in filterAttendance(scope.row, 1)"
                    :key="index"
                  >
                    {{ item.classArr.join(";") }}
                  </div>
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            label="打卡人员"
            header-align="left"
            align="left"
            width="120"
          >
            <template slot-scope="scope">
              <el-tooltip
                v-if="formatDepart(scope.row).length > 12"
                class="item"
                effect="dark"
                :content="formatDepart(scope.row)"
                placement="top"
              >
                <span class="ellipsis">{{ formatDepart(scope.row) }}</span>
              </el-tooltip>
              <span v-else>{{ formatDepart(scope.row) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="人数"
            header-align="left"
            align="left"
            width="80"
            prop="userCount"
          >
          </el-table-column>
          <el-table-column
            label="人员列表"
            header-align="left"
            align="left"
            width="80"
          >
            <template slot-scope="scope">
              <button
                @click="topeopleList(scope.row.id)"
                class="group_button_p"
              >
                人员明细
              </button></template
            >
          </el-table-column>
          <el-table-column
            label="操作"
            header-align="left"
            align="left"
            width="140"
          >
            <template slot-scope="scope">
              <div class="operate">
                <span
                  @click="schedule(scope.row)"
                  v-if="
                    judgeType(scope.row.attendanceGroupConfigData) ==
                    '排班上下班'
                  "
                >
                  排班
                </span>
                <span @click="editGroup(scope.row)"> 编辑 </span>
                <span
                  style="color: #ff0000"
                  v-if="multipleSelection.length <= 1"
                  @click="todeleteConfirm(scope.row)"
                >
                  删除
                </span>
                <span v-else>删除</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-if="this.onList != 0" class="newpagination">
        <div class="newpagination_text">共 {{ pages.total }} 条数据</div>
        <el-pagination
          layout="prev, pager, next"
          :total="pages.total"
          :current-page="pages.pageNum"
          :page-size="10"
          @current-change="changePage"
        >
        </el-pagination>
      </div>
    </div>
    <div class="filing">
      CopyRight@2023广州巨源信息技术有限公司-<a
        href="https://beian.miit.gov.cn/"
        >粤ICP备2023127373号-4</a
      >
    </div>
    <!-- 删除考勤组 -->
    <el-dialog
      title=""
      :visible.sync="errorTips"
      width="33%"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <div class="diglog_h">
        <div>确认删除</div>
        <img
          src="@/assets/structure/close.png"
          class="diglog_icon"
          @click="errorTips = false"
        />
      </div>
      <div class="delectdiglog">
        <div v-if="this.deflag == 0" class="delectdiglog_title">
          删除该考勤组将无法生成已经排好该考勤组班次人员的报表，是否仍删除？
        </div>
        <div v-else class="delectdiglog_title">确定要删除这些考勤组吗</div>
      </div>

      <div class="diglog_operation">
        <el-button @click="errorTips = false">取消</el-button>
        <el-button
          v-if="this.deflag == 0"
          type="primary"
          @click="deleteConfirm(deteid, 1)"
          >确认</el-button
        >
        <el-button v-else type="primary" @click="deleteConfirm(deteidlist, 2)"
          >确认</el-button
        >
      </div>
    </el-dialog>

    <!-- 人员明细 -->
    <el-dialog
      title=""
      width="52%"
      :key="currentGroundid"
      :show-close="false"
      :visible.sync="inviteDetail"
      class="invite_frame"
    >
      <Detail @close="inviteDetail = false" :id="currentGroundid" />
    </el-dialog>
  </div>
</template>
  
<script>
import http from "../../utils/http";
import Detail from "./definitedetail.vue";
import exprotExcel from "../../utils/exprotExcel";
export default {
  components: { Detail },
  data() {
    return {
      tableloading: true,
      cKey: 1,
      form: {},

      onList: [],
      direction: "rtl",
      chooseKey: false,
      value: "",
      currentGroundid: null, //所选择的考勤组
      inviteDetail: false, //人员明细
      checkAll: false,

      isIndeterminate: true,
      pages: {}, //分页
      conditionObj: {}, //搜索条件
      tableSelection: [], //导出表格需要
      multipleSelection: [],
      tableColumn: [
        {
          title: "考勤组名称",
          dataIndex: "name",
          id: 1,
        },
        {
          title: "考勤类型",
          dataIndex: "shifttype",
          id: 2,
        },
        {
          title: "考勤时间",
          dataIndex: "attendance",
          id: 3,
        },
        {
          title: "打卡人员",
          dataIndex: "departmentMember",
          id: 4,
        },
        {
          title: "人数",
          dataIndex: "userCount",
          id: 5,
        },
      ],

      seachIcon: false,
      errorTips: false, //确认删除
      deteid: 0,
      detesn: "", //要删除的sn
      deteidlist: [],
      deflag: 0,
      seachName: "",
    };
  },
  watch: {
    seachName(newName, oldName) {
      if (newName) {
        this.seachIcon = true;
      } else {
        this.seachIcon = false;
      }
    },
  },
  created() {
    this.getList({ pageNum: 1 });
    // 获取缓存部门列表
    // var arr = localStorage.getItem("department");
    // var ar = JSON.parse(arr);
    // this.data = ar[0].subDepartment;
  },
  mounted() {},
  methods: {
    getList(onsiteform) {
      if (this.seachName != "") {
        var form = { likeString: this.seachName };
      }
      http
        .get("/bmsAttendanceGroup/list", {
          ...form,
          ...onsiteform,
          pageSize: 10,
        })
        .then((res) => {
          if (res.code == 200) {
            this.onList = res.data.list;
            this.pages = res.data;
            this.tableloading = false;
          } else {
            this.$message.error(res.message);
          }
        });
    },
    onSearch(num) {
      this.getList({ pageNum: 1 });
    },
    clearup() {
      this.seachName = "";
      this.getList({ pageNum: 1 });
    },

    addGroup() {
      this.$router.replace({
        path: "/addgroup",
        query: {
          isEdit: false,
        },
      });
    },
    editGroup(obj) {
      this.$router.replace({
        name: "addgroup",
        params: {
          isEdit: true,
          groupObj: obj,
          groupObjid: obj.id,
        },
      });
      // this.$bus.$emit('groupObj', obj)
    },
    schedule(row) {
      var isHoliday = row.attendanceGroupConfigData.useHoliday;
      this.$router.replace({
        path: "/schedule",
        query: { id: row.id, isHoliday: isHoliday },
      });
    },
    // 查看人员明细
    topeopleList(id) {
      this.currentGroundid = id;
      setTimeout(() => {
        this.inviteDetail = true;
      }, 200);
    },

    // 判断上班类型
    judgeType(value) {
      if (value.fixWorkData) {
        return "固定上下班";
      } else if (value.freeWorkData) {
        return "自由上下班";
      } else {
        return "排班上下班";
      }
    },

    // 表格下载之前
    startDownload() {
      const loading = this.$loading({
        lock: true,
        text: "导出表格中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      // let target = event.target;
      // if (target.nodeName == "I" || target.nodeName == "SPAN") {
      //   target = event.target.parentNode;
      // }
      // target.blur();
      let arr2 = [];
      for (let key of this.tableColumn) {
        arr2.push(key.key);
      }
      http
        .get("/bmsAttendanceGroup/list", {
          likeString: this.seachName,
          pageNum: 1,
          pageSize: 9999,
        })
        .then((res) => {
          loading.close();
          if (res.code == 200) {
            if (res.data.list.length > 0) {
              res.data.list.map((v) => {
                v.shifttype = this.judgeType(v.attendanceGroupConfigData);
                v.departmentMember = this.formatDepart(v);
                v.attendance = this.filterAttendance(v, 2);
                for (let i = 0; i < arr2.length; i++) {
                  if (v[arr2[i]] == undefined) {
                    v[arr2[i]] = "";
                  }
                }
                for (let key in v) {
                  if (v[key] == null) v[key] = "";
                }
              });
              exprotExcel.export(this.tableColumn, res.data.list, "考勤组列表");
              this.$message.success("导出成功");
            } else {
              this.$message.success("无数据导出");
            }
          }
        });
    },

    openChoose() {
      this.cKey = Math.random();
      setTimeout(() => {
        this.dialogChoose = true;
      }, 200);
      if (this.$refs.eltree) this.$refs.eltree.setChecked([]);
    },

    // 页数改变
    changePage(e) {
      this.getList({ pageNum: e });
    },
    //确认删除操作
    todeleteConfirm(item) {
      this.errorTips = true;
      this.deteid = item.id;
      this.detesn = item.name;
      this.deflag = 0;
    },
    deleteConfirm(a, num) {
      if (num == 2) {
        var arr2 = [];
        this.multipleSelection.map((item) => {
          arr2.push(item.id);
        });
        this.deleteGroup(arr2);
      } else this.deleteGroup(a);
    },
    //删除考勤组
    deleteGroup(ids) {
      var arr = [];
      if (Array.isArray(ids)) {
        arr = ids;
      } else {
        arr = [ids];
      }
      http
        .post("/bmsAttendanceGroup/delete", {
          ids: arr,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("删除成功");
            this.errorTips = false;
            this.$refs.multipleTable.clearSelection();
            this.getList({ pageNum: 1 });
          } else {
            this.$message.error(res.message);
          }
        });
    },
    formatDepart(row) {
      let arr = [];
      row.bmsAttendanceGroupDepartmentGetVOList.map((item) => {
        if (item.umsDepartment) {
          arr.push(item.umsDepartment.name);
        }2
      });
      row.bmsAttendanceGroupUserGetVOList.map((item) => {
        if (item.umsUser) {
          arr.push(item.umsUser.name);
        }
      });
      return arr.join(",");
    },

    // 考勤时间
    filterAttendance(object, type) {
      var arr = [];
      if (object.attendanceGroupConfigData.fixWorkData) {
        // 如果是固定班次
        arr = object.bmsAttendanceArrangeGetVOList;
        var newarr = [];
        arr.map((item) => {
          item.classArr = [];
          if (item.arrangeDataList) {
            item.arrangeDataList.map((citem) => {
              citem.classtime =
                this.secondsToHourMin(citem.clockData.beginDaySecond) +
                "～" +
                this.secondsToHourMin(citem.clockData.endDaySecond);
              item.classArr.push(citem.classtime);
            });

            newarr.push({
              name: item.name,
              classArr: item.classArr,
            });
          }
        });

        var allarr = this.removeSame1(newarr);
        if (type == 1) {
          return allarr;
        } else {
          var arr = [];
          allarr.map((item) => {
            arr.push(item.classArr.join(";"));
          });
          return arr.join(",");
        }
      } else if (object.attendanceGroupConfigData.freeWorkData) {
        if (type == 1) {
          return [];
        } else {
          return "";
        }
      } else {
        // 排班制
        arr = object.bmsAttendanceGroupArrangeListVOList;

        var newarr = [];

        arr.map((item) => {
          item.classArr = [];
          if (item.bmsAttendanceArrangeGetVO) {
            if (item.bmsAttendanceArrangeGetVO.arrangeDataList) {
              item.bmsAttendanceArrangeGetVO.arrangeDataList.map((citem) => {
                citem.classtime =
                  this.secondsToHourMin(citem.clockData.beginDaySecond) +
                  "～" +
                  this.secondsToHourMin(citem.clockData.endDaySecond);
                item.classArr.push(citem.classtime);
              });

              newarr.push({
                name: item.bmsAttendanceArrangeGetVO.name,
                classArr: item.classArr,
              });
            }
          }
        });

        var allarr = this.removeSame1(newarr);
        if (type == 1) {
          return allarr;
        } else {
          var arr = [];
          allarr.map((item) => {
            arr.push(item.classArr.join(";"));
          });
          return arr.join(",");
        }
      }
    },

    // 表格导出考勤时间
    tableExporttime(arr) {
      if (arr) {
        var newarr = [];
        arr.map((item) => {
          newarr.push(item.name + "：" + item.classArr.join(";"));
        });

        return newarr.join(" ");
      } else {
        return "";
      }
    },

    // 数组去重
    removeSame1(arr) {
      let map = new Map();
      for (let item of arr) {
        // map.has()方法判断map中是否存在这个键
        // 不存在，则将键和值同时设置到map集合中，
        if (!map.has(item.name)) {
          map.set(item.name, item);
        }
      }
      // 返回map的值
      return [...map.values()];
    },
    // 秒转换成时间
    secondsToHourMin(seconds) {
      if (seconds >= 86400) {
        seconds = seconds - 86400;
      }
      // 将秒数转换为分钟
      let minutes = Math.floor(seconds / 60);
      // 将分钟转换为小时和分钟
      let hours = Math.floor(minutes / 60);
      let minutesRemainder = minutes % 60;

      // 格式化小时和分钟
      hours = hours < 10 ? "0" + hours : hours;
      minutesRemainder =
        minutesRemainder < 10 ? "0" + minutesRemainder : minutesRemainder;

      // 返回时分格式
      return hours + ":" + minutesRemainder;
    },
  },
};
</script>
<style scoped>
.group {
  width: 100%;
  height: 100%;
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: #f4f7fc;
}

.group_head {
  font-size: 14px;
}

.group_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  background: #ffffff;
  box-shadow: 0px 1px 4px 1px rgba(21, 34, 50, 0.08);
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  margin-top: 16px;
}

.group_search_wrapper {
  display: flex;
  padding: 20px;
  height: 76px;
  box-sizing: border-box;
  width: auto;
  border-bottom: 1px solid rgba(207, 212, 232, 0.5);
}

.group_search_t {
  margin-right: 10px;
  width: auto;
}

.approval_search {
  width: 243px;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #d0d0d0;
  display: flex;
  align-items: center;
  padding-right: 12px;
  box-sizing: border-box;
  justify-content: space-between;
}

.approval_search:focus-within {
  border-color: #0096fa;
}

::v-deep .device_search_h .el-input {
  width: 200px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #d0d0d0;
}

::v-deep .device_search_h .el-input__inner {
  border: none;
  height: 36px;
}

::v-deep .group_search .el-range-editor .el-range-input::placeholder {
  font-size: 15px;
  font-weight: 400;
  color: #b2b2b2;
}

.group_add {
  margin-right: 10px;
  margin-left: auto;
}

.group_table_wrapper {
  padding: 20px 18px 20px 20px;
  box-sizing: border-box;
  width: calc(100vw - 330px);
  font-size: 16px;
  flex: 1;
  position: relative;
}

.group_table_wrapper ::v-deep .el-table__empty-block {
  width: 100% !important;
}

.group_table {
  white-space: nowrap;
  font-size: 16px;
  width: 100%;
  height: 100%;
  position: absolute;
}

.group_type {
  display: inline-block;
  width: 48px;
  height: 26px;
  line-height: 26px;
  background: #e0ecff;
  opacity: 1;
  text-align: center;
  color: #0058ff;
}

.group_button_p {
  /* width: 80px !important; */
  background: #e0ecff;
  cursor: pointer;
  font-size: 14px;
  opacity: 1;
  color: #0058ff !important;
  text-align: center;
  border: none;

  height: 26px;
}

::v-deep .group_table .el-button {
  width: 96px !important;
  height: 26px !important;
  background: #e0ecff;
  font-size: 14px;
  opacity: 1;
  color: #0058ff !important;
  text-align: center;
}

.operate {
  color: #0058ff;
  cursor: pointer;
}

.operate span {
  margin-right: 24px;
}

.group-img {
  width: 44px;
  height: 42px;
}

.newpagination {
  display: flex;
  height: 50px;
  padding-left: 20px;
  box-sizing: border-box;
  justify-content: space-between;
}

.newpagination_text {
  font-size: 16px;
  color: #5c5c5c;
}

::v-deep .newpagination .el-pagination {
  margin: 0 20px 30px auto;
}

/* 删除弹窗 */
.delectdiglog {
  width: 100%;
  margin-top: 20px;
  border-top: 1px solid #cfd4e8;
}

.delectdiglog_title {
  font-size: 16px;
  color: #1a1a1a;
  padding: 30px;
  box-sizing: border-box;
}

.diglog_h {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  padding: 24px 24px 0 24px;
  color: #1a1a1a;
}

.diglog_icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.diglog_operation {
  margin: 60px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.department_n {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #1a1a1a;
}

::v-deep.el-table .el-table__body td .cell:empty::after,
.el-table .el-table__body td .cell > span:empty::after,
.el-table .el-table__body td .cell .approval:empty::after,
.el-table .el-table__body td .cell div:empty::after,
.el-table .el-table__body td .cell .line-limit-length:empty::after {
  content: "--";
}
.ellipsis {
  width: 200px;
  overflow: hidden; /* 超出部分隐藏 */
  white-space: nowrap; /* 禁止文本换行 */
  text-overflow: ellipsis;
  display: block;
}
.time_list {
  display: flex;
  width: 370px;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.time_single {
  flex: 0 0 116px;
  height: 30px;
  padding: 0 10px;
  box-sizing: border-box;
  line-height: 30px;
  text-align: center;
  font-size: 16px;
  color: #1a1a1a;
  background: #dee4ed;
  border-radius: 4px 4px 4px 4px;
  margin-right: 8px;
}
</style>
  