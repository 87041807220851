<template>
  <div class="reception">
    <div class="reception_back"></div>
    <div v-if="!isexpire">
      <div class="reception_head">
        <div class="reception_head_icon" @click="clickFile">
          <input
            class="hide_file"
            ref="leftFile"
            id="upload"
            type="file"
            @change="getFile($event)"
            accept="image/*"
          />
          <img
            v-if="!imgHead"
            src="@/assets/visitor/head.png"
            class="reception_head_c"
          />
          <img v-else :src="imgHead" class="reception_head_h" />
          <div v-if="!imgHead">照片采集</div>
        </div>
        <div class="reception_head_tips">照片提示：免冠、无墨镜、露出五官</div>
      </div>
      <!-- <div class="fillin">
        <input
          class="hide_file"
          ref="leftFile"
          id="upload"
          type="file"
          @change="getFile($event)"
          accept="image/*"
        />
        <img src="@/assets/invite/img1.png" class="fillin_icon" />
        <div class="fillin_right">
          <div class="fillin_text">照片</div>
          <img
            v-if="!imgHead"
            src="@/assets/invite/head.png"
            class="fillin_head"
            @click="clickFile"
          />
          <img v-else :src="imgHead" class="fillin_head" @click="clickFile" />
        </div>
      </div> -->
      <div class="fillin">
        <img src="@/assets/invite/img2.png" class="fillin_icon" />
        <div class="fillin_right">
          <div class="fillin_text">姓名</div>
          <input
            type="text"
            placeholder="请填写"
            class="fillin_input"
            v-model="form.name"
          />
        </div>
      </div>
      <div class="fillin">
        <img src="@/assets/invite/img3.png" class="fillin_icon" />
        <div class="fillin_right">
          <div class="fillin_text">手机号</div>
          <input
            type="text"
            placeholder="请填写"
            class="fillin_input"
            v-model="form.phone"
          />
        </div>
      </div>
      <div class="fillin">
        <img src="@/assets/invite/img4.png" class="fillin_icon" />
        <div class="fillin_right">
          <div class="fillin_text">身份证号</div>
          <input
            type="text"
            placeholder="请填写"
            class="fillin_input"
            v-model="form.idCard"
          />
        </div>
      </div>
      <div class="fillin" v-if="inviteInfo.inviteType != 1">
        <img src="@/assets/invite/img5.png" class="fillin_icon" />
        <div class="fillin_right">
          <div class="fillin_text">来访事由</div>
          <input
            type="text"
            placeholder="请填写"
            class="fillin_input"
            v-model="form.reason"
          />
        </div>
      </div>
      <div class="fillin">
        <img src="@/assets/invite/img6.png" class="fillin_icon" />
        <div class="fillin_right">
          <div class="fillin_text">公司名称</div>
          <input
            type="text"
            placeholder="请填写"
            class="fillin_input"
            v-model="form.companySelf"
          />
        </div>
      </div>
      <div class="fillin">
        <img src="@/assets/invite/img7.png" class="fillin_icon" />
        <div class="fillin_right">
          <div class="fillin_text">到访人数</div>
          <input
            type="number"
            placeholder="请填写"
            class="fillin_input"
            v-model="form.peopleCount"
          />
        </div>
      </div>
      <div class="reception_tip">
        <div class="reception_tips">到访时间</div>
        <div class="reception_tips">
          {{ inviteInfo.beginTime }} 至 {{ inviteInfo.endTime }}
        </div>
      </div>

      <div class="reception_bto" @click="submitForm">提交</div>
    </div>

    <div v-else>
      <!-- <div class="fillin_top">访客信息</div> -->
      <div class="fillin_fail">
        <img src="@/assets/invite/fail.png" class="fillin_fail_icon" />
        <div class="fillin_fail_text">链接已过期</div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../../utils/http";
import axios from "axios";
export default {
  data() {
    return {
      form: {}, //表单
      inviteInfo: {}, //邀请信息
      imgHead: "",
      query: {}, //网页上的参数
      ossArguments: {}, //oss参数
      isexpire: false,
      uploadHead: false, //上传头像提示
    };
  },

  created() {
    //  获取url的参数
    var code = this.$route.query.invite_check;

    this.getInviteinfo(code);

    this.getOss(code);

    this.query = {
      inviteCheck: code,
    };
  },

  methods: {
    // 获取信息
    getInviteinfo(code) {
      http
        .get("/invite/get", {
          inviteCheck: code,
        })
        .then((res) => {
          if (res.code == 200) {
            this.inviteInfo = res.data;
          } else {
            this.isexpire = true;
          }
        });
    },

    // 点击触发input的点击事件
    clickFile() {
      this.$refs.leftFile.click();
    },
    // 获取选择的图片文件上传(头像)
    getFile(e) {
      let file = e.target.files[0];
      // this.headUrl = this.getFileURL(file);

      // this.file1 = file;
      var imgs = e.target.files || e.dataTransfer.files;
      if (!imgs.length) return;
      let self = this;
      for (var i = 0; i < imgs.length; i++) {
        this.blobToBase64(imgs[i], 1); //转成 base64
      }
    },

    // 判断是否为ios
    isIOS() {
      // const ua = navigator.userAgent;
      // return !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      var u = window.navigator.userAgent;
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //g
      return !isAndroid;
    },

    // 文件转换成base64的图片
    blobToBase64(blob, type) {
      this.uploadHead = this.$loading({
        lock: true,
        text: "上传头像中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const self = this; // 绑定this
      const reader = new FileReader(); //实例化一个reader文件
      reader.readAsDataURL(blob); // 添加二进制文件
      reader.onload = function (event) {
        const base64 = event.target.result; // 获取到它的base64文件
        const scale = 0.3; // 设置缩放比例 （0-1）
        if (self.isiOS) {
          //判断是否是ios
          //ios上传图片时会根据拍照时是横屏还是竖屏拍摄，图片会旋转的，我们要将图片旋转成正常
          EXIF.getData(blob, function () {
            EXIF.getAllTags(this);
            var orientation = EXIF.getTag(this, "Orientation");
            self.compressImg(base64, orientation, scale, blob.name); // 调用压缩方法
            //self.gotoService  压缩后上传至服务器
          });
        } else {
          self.compressImg(base64, 0, scale, blob.name); // 调用压缩方法
        }
      };
    },
    // 进行图片压缩
    compressImg(base64, orientation, scale, name) {
      // 处理缩放，转换格式
      let self = this;
      const img = new Image(); //new 一个图片
      img.src = base64;
      img.onload = function () {
        const canvas = document.createElement("canvas"); //    用canvas来压缩
        const ctx = canvas.getContext("2d");
        canvas.setAttribute("width", this.width);
        canvas.setAttribute("height", this.height);
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        if (self.isIos) {
          //ios处理图片旋转
          if (orientation != "" && orientation != 1) {
            switch (orientation) {
              case 6: //需要顺时针（向左）90度旋转
                self.rotateImg(img, "left", canvas);
                break;
              case 8: //需要逆时针（向右）90度旋转
                self.rotateImg(img, "right", canvas);
                break;
              case 3: //需要180度旋转
                self.rotateImg(img, "right", canvas); //转两次
                self.rotateImg(img, "right", canvas);
                break;
            }
          }
        }
        // 转成base64 文件
        let base64 = canvas.toDataURL("image/jpeg");
        // 根据自己需求填写大小 我的目标是小于1兆
        while (base64.length > 1024 * 1024) {
          scale -= 0.01;
          base64 = canvas.toDataURL("image/jpeg", scale);
        }
        // baser64 TO blob 这一块如果不懂可以自行百度，我就不加注释了
        const arr = base64.split(",");
        const mime = arr[0].match(/:(.*?);/)[1];
        const bytes = atob(arr[1]);
        const bytesLength = bytes.length;
        const u8arr = new Uint8Array(bytesLength);
        for (let i = 0; i < bytes.length; i++) {
          u8arr[i] = bytes.charCodeAt(i);
        }

        const blob = new Blob([u8arr], { type: mime });
        // 回调函数 根据需求返回二进制数据或者base64数据，我的项目都给返回了
        self.uploadOss(blob, name);
      };
    },
    // 旋转图片
    rotateImg(img, direction, canvas) {
      //最小与最大旋转方向，图片旋转4次后回到原方向
      var min_step = 0;
      var max_step = 3;
      //var img = document.getElementById(pid);
      if (img == null) return;
      var height = canvas.height;
      var width = canvas.width;
      //var step = img.getAttribute('step');
      var step = 2;
      if (step == null) {
        step = min_step;
      }
      if (direction == "right") {
        step++;
        //旋转到原位置，即超过最大值
        step > max_step && (step = min_step);
      } else {
        step--;
        step < min_step && (step = max_step);
      }
      //旋转角度以弧度值为参数
      var degree = (step * 90 * Math.PI) / 180;
      var ctx = canvas.getContext("2d");
      switch (step) {
        case 0:
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);
          break;
        case 1:
          canvas.width = height;
          canvas.height = width;
          ctx.rotate(degree);
          ctx.drawImage(img, 0, -height, width, height);
          break;
        case 2:
          canvas.width = width;
          canvas.height = height;
          ctx.rotate(degree);
          ctx.drawImage(img, -width, -height, width, height);
          break;
        case 3:
          canvas.width = height;
          canvas.height = width;
          ctx.rotate(degree);
          ctx.drawImage(img, -width, 0, width, height);
          break;
      }
    },

    // 获取阿里云参数
    getOss(code) {
      http
        .get("/oss/policy", {
          peopleType: 1,
          type: 0,
          inviteCheck: code,
        })
        .then((res) => {
          if (res.code == 200) {
            this.ossArguments = res.data;
          }
        });
    },

    // 上传到阿里云
    uploadOss(file, name) {
      const formData = new FormData();
      var newName = Date.now() + name;
      var url = this.ossArguments.host;
      formData.append("key", this.ossArguments.dir + file.name); //key 唯一值  即相对路径
      formData.append("policy", this.ossArguments.policy); //服务器返回的policy
      formData.append("OSSAccessKeyId", this.ossArguments.accessKeyId); //服务器返回的accessId
      formData.append("signature", this.ossArguments.signature); //服务器返回的signature
      formData.append("name", file.name); //文件名
      formData.append("file", file, newName);

      let config = {
        headers: { "Content-Type": "multipart/form-data" },
      };

      axios
        .post(url, formData, config)
        .then((response) => {
          this.uploadHead.close();
          this.imgHead =
            this.ossArguments.host + "/" + this.ossArguments.dir + newName;
          this.form.headUrl = this.imgHead;
        })
        .catch((e) => {
          this.uploadHead.close();
          this.$message.error("页面停留过长,请刷新页面再试");
        });
    },

    // 提交表单
    submitForm() {
      // 判断是否没填
      if (!this.form.headUrl) {
        this.$message.error("请先上传照片");
        return;
      }
      if (!this.form.name || this.form.name == "") {
        this.$message.error("请先填写姓名");
        return;
      }
      if (!this.form.phone || this.form.phone == "") {
        this.$message.error("请先填写手机号");
        return;
      }
      if (!this.form.idCard || this.form.idCard == "") {
        this.$message.error("请先填写身份证");
        return;
      }

      // 验证手机号
      var reg = /^1[3456789]\d{9}$/;
      if (!reg.test(this.form.phone)) {
        this.$message.error("请输入有效的手机号码");
        return;
      }

      // 验证身份证尾数
      if (
        this.form.idCard.length != 10 &&
        this.form.idCard.length != 15 &&
        this.form.idCard.length != 18 &&
        this.form.idCard.length != 9
      ) {
        this.$message.error("请输入有效的身份证号码");
        return;
      }

      const loading = this.$loading({
        lock: true,
        text: "正在生成访客码...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      http.post("/visitor/add", { ...this.query, ...this.form }).then((res) => {
        loading.close();
        if (res.code == 200) {
          window.location = res.data.url;
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
};
</script>

<style scoped>
.title {
  width: 100%;
  height: 46px;
  color: #1a1a1a;
  font-size: 17px;
  text-align: center;
  line-height: 46px;
  background: white;
}
.fillin {
  width: 100%;
  padding-left: 16px;
  box-sizing: border-box;
  height: 52px;
  display: flex;
  background: white;
  align-items: center;
  justify-content: flex-end;
}
.fillin_icon {
  width: 19px;
  height: 19px;
}
.fillin_right {
  flex: 1;
  display: flex;
  color: #1a1a1a;
  align-items: center;
  justify-content: space-between;
  padding-right: 16px;
  box-sizing: border-box;
  height: 100%;
  margin-left: 6px;
  border-bottom: 1px solid #e5e5e5;
}
.fillin_text {
  font-size: 15px;
}
.fillin_input {
  border: none;
  font-size: 15px;
  color: #1a1a1a;
  text-align: right;
}
.fillin_input:focus {
  outline: none;
}
.reception_back {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
}
.reception_tip {
  margin-top: 14px;
}
.reception_tips {
  padding: 0 16px;
  box-sizing: border-box;
  font-size: 13px;
  color: #767676;
}
.reception_bto {
  width: 343px;
  height: 47px;
  background: #0058ff;
  position: fixed;
  bottom: 63px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  line-height: 47px;
  color: white;
  font-size: 14px;
  border-radius: 6px;
}
.fillin_head {
  width: 42px;
  height: 42px;
  border-radius: 50%;
}
.fillin_fail_icon {
  width: 23.4375rem;
  height: 18.75rem;
}

.fillin_fail {
  font-size: 1rem;
  color: #000;
}
.fillin_fail_text {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}
.hide_file {
  display: none;
}
.fillin_input::-webkit-input-placeholder {
  /* WebKit browsers，webkit内核浏览器 */
  color: #b2b2b2;
  font-size: 15px;
}
.fillin_input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #b2b2b2;
  font-size: 15px;
}
.reception_head {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.reception_head_icon {
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #598fff;
  font-size: 14px;
  background: rgba(0, 88, 255, 0.1);
  border-radius: 50%;
  margin: 20px 0;
}
.reception_head_c {
  width: 37px;
  height: 40px;
  margin-bottom: 6px;
}
.reception_head_tips {
  font-size: 14px;
  color: #999999;
  margin-bottom: 20px;
}
.reception_head_h {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
</style>
