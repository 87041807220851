<template>
  <div class="approval">
    <div class="approval_t">
      <div class="approval_t_left2">
        <div class="approval_search">
          <el-tooltip
            class="item"
            effect="dark"
            content="姓名、员工工号、经办人"
            placement="top-start"
          >
            <el-input
              v-model="searchForm.likeString"
              @keyup.enter.native="seachResult"
              class="department_search_inp"
              placeholder="姓名、员工工号、经办人"
              style="width: 200px"
            >
            </el-input>
          </el-tooltip>
          <img
            src="@/assets/structure/delete.png"
            class="searchicon"
            @click="clearup"
            v-if="searchicon"
          />
          <img
            src="@/assets/structure/search.png"
            class="searchicon_t"
            @click="seachResult"
          />
        </div>
        <el-select
          ref="stlectTree"
          clearable
          @change="clearValue"
          v-model="selectTreeLast"
          placeholder="原部门"
          :popper-append-to-body="false"
          class="select-tree"
        >
          <el-option :value="selectTreeValueLast" style="height: auto">
            <!-- option展开高度太小，把height设置为auto就好啦 -->
            <el-tree
              :data="departmentList"
              node-key="departmentId"
              ref="tree"
              accordion
              highlight-current
              :props="defaultProps"
              @node-click="nodeClick"
            ></el-tree>
          </el-option>
        </el-select>
        <el-select
          ref="stlectTree2"
          v-model="selectTree"
          clearable
          @change="clearValue2"
          placeholder="现部门"
          :popper-append-to-body="false"
          class="select-tree"
        >
          <el-option :value="selectTreeValue" style="height: auto">
            <!-- option展开高度太小，把height设置为auto就好啦 -->
            <el-tree
              :data="departmentList"
              node-key="departmentId"
              ref="tree"
              accordion
              highlight-current
              :props="defaultProps"
              @node-click="nodeClick2"
            ></el-tree>
          </el-option>
        </el-select>
      </div>
      <div class="approval_t_right" @click="startDownload">导出报表</div>
    </div>
    <div class="approval_table">
      <el-table
        height="100%"
        :data="tableData"
        :header-cell-style="getRowClass"
        @selection-change="handleSelectionChange"
        class="a_table"
        row-key="id"
      >
        <template slot="empty">
          <div>
            <img src="@/assets/nodata.png" class="nodata" />
            <div>无数据</div>
          </div>
        </template>
        <el-table-column prop="name" label="姓名">
          <template slot-scope="scope">
            <div class="approval_name" v-if="scope.row.umsUser">
              <img
                :src="scope.row.umsUser.headUrl"
                class="approval_head"
                v-if="scope.row.umsUser.headUrl"
              />
              <img src="@/assets/head.png" v-else class="approval_head" />
              <div>{{ scope.row.umsUser.name }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="员工工号">
          <template slot-scope="scope">
            <div v-if="scope.row.umsUser">
              {{ scope.row.umsUser.jobNumber }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="departmentName" label="原部门">
          <template slot-scope="scope">
            <div v-if="scope.row.fromUmsDepartment" class="ellipsis">
              {{ scope.row.fromUmsDepartment.name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="departmentName" label="现部门">
          <template slot-scope="scope">
            <div v-if="scope.row.toUmsDepartment" class="ellipsis">
              {{ scope.row.toUmsDepartment.name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="job" label="职位">
          <template slot-scope="scope">
            <div v-if="scope.row.umsUser">
              {{ scope.row.umsUser.job }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="调动时间"> </el-table-column>
        <el-table-column prop="userExec" label="经办人">
          <template slot-scope="scope">
            <div v-if="scope.row.execUmsUser">
              {{ scope.row.execUmsUser.name }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="newpagination" v-if="pages.total > 0">
      <div class="newpagination_text">共 {{ pages.total }} 条数据</div>
      <el-pagination
        layout="prev, pager, next"
        :total="pages.total"
        :current-page="pages.pageNum"
        :page-size="50"
        @current-change="chagePage"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import http from "../../../utils/http";
import exprotExcel from "../../../utils/exprotExcel";
export default {
  data() {
    return {
      issuedKey: Math.random(),
      searchicon: false, //搜索图标
      memberIssued: false, //人员下发
      departmentList: [], //部门列表
      searchForm: {}, //搜索表格
      // 下拉部门选择
      selectTreeLast: "", //原部门
      selectTree: "", //现部门
      treeKey: 0,
      selectTreeValueLast: "", //原部门
      selectTreeValue: "", //现部门
      defaultProps: {
        children: "subDepartment",
        label: "name",
      },
      handler: "", //经办人
      tableData: [],
      searchData: [], //搜索列表
      loading: false, //搜索加载
      multipleSelection: [], //多选操作
      finalEmplo: [], //最终员工id
      pages: {},
      tableColumn: [
        {
          title: "姓名",
          dataIndex: "name",
          id: 1,
        },
        {
          title: "员工工号",
          dataIndex: "jobNumbernew",
          id: 2,
        },
        {
          title: "原部门",
          dataIndex: "primarydepartmentName",
          id: 3,
        },
        {
          title: "现部门",
          dataIndex: "presentdepartmentName",
          id: 4,
        },
        {
          title: "职位",
          dataIndex: "jobName",
          id: 5,
        },
        {
          title: "调动时间",
          dataIndex: "createTime",
          id: 6,
        },
        {
          title: "经办人",
          dataIndex: "userExecText",
          id: 7,
        },
      ], //导出内容参数
    };
  },
  watch: {
    "searchForm.likeString": {
      immediate: true,
      deep: true,
      handler(newItems, oldItems) {
        if (newItems) {
          this.searchicon = true;
        } else {
          this.searchicon = false;
          delete this.searchForm.likeString;

          // 获取审批列表
          this.approvalList({
            pageNum: 1,
            pageSize: 50,
          });
        }
      },
    },
  },
  created() {
    // 获取部门列表
    var arr = localStorage.getItem("department");
    var arr2 = JSON.parse(arr);
    this.departmentList = arr2;
  },
  methods: {
    // 表头背景颜色变色
    getRowClass({ rowIndex, columnIndex }) {
      if (rowIndex == 0) {
        return "background:#F5F6FA;color:#5C5C5C";
      }
    },

    // 递归处理数据
    convertToNestedStructure(arr) {
      // 创建一个映射，用于快速查找具有特定id的对象
      const idMap = new Map(arr.map((item) => [item.id, item]));

      // 创建一个新数组来存储转换后的嵌套结构
      const nestedArr = [];

      // 遍历原始数组
      arr.forEach((item) => {
        // 如果该项有父元素，则将其添加到父元素的subDepartment数组中
        if (item.parent) {
          const parent = idMap.get(item.parent);
        if (parent) {
            if (!parent.subDepartment) {
              parent.subDepartment = [];
            }
            item.parentDepament = parent.name;
            parent.subDepartment.push(item);
          }
        } else {
          // 如果没有父元素，则添加到新数组的根级别
          nestedArr.push(item);
        }
      });

      // 返回转换后的嵌套数组
      return nestedArr;
    },

    // 获取审批列表
    approvalList(value) {
      http
        .get("/umsUserChange/list", Object.assign(this.searchForm, value))
        .then((res) => {
          if (res.code == 200) {
            this.pages = res.data;
            this.tableData = res.data.list;

            this.$bus.$emit("approvalNum", res.data.total);
          }
        });
    },
    // 页数改变
    chagePage(e) {
      this.approvalList({
        pageNum: e,
        pageSize: 50,
      });
    },
    // 批量操作
    handleSelectionChange(val) {
      this.multipleSelection = [];
      val.map((item) => {
        this.multipleSelection.push(item.id);
      });
    },

    // 原部门清楚选项
    clearValue(value) {
      delete this.searchForm.departmentFrom;
      this.approvalList({
        pageNum: 1,
        pageSize: 50,
      });
    },
    // 现部门清楚选项
    clearValue2(value) {
      delete this.searchForm.departmentTo;
      this.approvalList({
        pageNum: 1,
        pageSize: 50,
      });
    },

    // 搜索结果
    seachResult() {
      if (this.searchForm.searchValue == "") {
        return;
      }

      this.approvalList({
        pageNum: 1,
        pageSize: 50,
      });
    },

    // 表格下载之前
    startDownload() {
      const loading = this.$loading({
        lock: true,
        text: "导出报表中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let target = event.target;
      if (target.nodeName == "I" || target.nodeName == "SPAN") {
        target = event.target.parentNode;
      }
      target.blur();
      let arr2 = [];
      for (let key of this.tableColumn) {
        arr2.push(key.key);
      }

      http
        .get(
          "/umsUserChange/list",
          Object.assign(this.searchForm, {
            pageNum: 1,
            pageSize: 99999,
          })
        )
        .then((res) => {
          if (res.code == 200) {
            loading.close();
            if (res.data.list.length > 0) {
              res.data.list.map((v) => {
                if (v.umsUser) {
                  v.name = v.umsUser.name;
                  if (v.umsUser.jobNumber) {
                    v.jobNumbernew = "\xa0" + v.umsUser.jobNumber;
                  }
                  v.jobName = v.umsUser.job;
                }
                v.createTime = "\xa0" + v.createTime;
                if (v.fromUmsDepartment) {
                  v.primarydepartmentName = v.fromUmsDepartment.name;
                }
                if (v.toUmsDepartment) {
                  v.presentdepartmentName = v.toUmsDepartment.name;
                }
                v.userExecText = v.execUmsUser.name;
                for (let i = 0; i < arr2.length; i++) {
                  if (v[arr2[i]] == undefined) {
                    v[arr2[i]] = "";
                  }
                }
                for (let key in v) {
                  if (v[key] == null) v[key] = "";
                }
              });
              exprotExcel.export(
                this.tableColumn,
                res.data.list,
                "人事调动记录"
              );
              this.$message.success("导出成功");
            } else {
              this.$message.error("无数据导出");
            }
          }
        });
    },

    // 部门选择(原部门)
    nodeClick(item) {
      const { id, name } = item;
      this.selectTreeLast = name;
      this.selectTreeValueLast = id;
      this.$refs.stlectTree.blur();
      this.searchForm.departmentFrom = id;
      this.approvalList({
        pageNum: 1,
        pageSize: 50,
      });
    },
    // 部门选择(现部门)
    nodeClick2(item) {
      const { id, name } = item;
      this.selectTree = name;
      this.selectTreeValue = id;
      this.$refs.stlectTree2.blur();
      this.searchForm.departmentTo = id;
      this.approvalList({
        pageNum: 1,
        pageSize: 50,
      });
    },

    // 清除输入框
    clearup() {
      this.searchForm.likeString = "";
      this.approvalList({
        pageNum: 1,
        pageSize: 50,
      });
    },
  },
};
</script>

<style scoped>
.approval {
  max-width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.approval_t {
  display: flex;
  margin-bottom: 16px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
}
.approval_search {
  width: 243px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #d0d0d0;
  display: flex;
  align-items: center;
  padding-right: 12px;
  box-sizing: border-box;
  justify-content: space-between;
}
.approval_table {
  width: 100%;
  flex: 1;
  position: relative;
  overflow-y: scroll;
  box-sizing: border-box;
}
.approval_table::-webkit-scrollbar {
  display: none;
}
.approval_name {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #1a1a1a;
}
.a_table {
  width: 100%;
  height: 100%;
  position: absolute;
}
.approval_head {
  width: 30px;
  object-fit: cover;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}
::v-deep .el-table__body .el-table__row.hover-row td {
  background-color: rgba(0, 98, 255, 0.05);
}
.approval_search:focus-within {
  border-color: #0096fa;
}
.newpagination {
  margin-top: 74px;
  padding: 0 20px;
  box-sizing: border-box;
}
::v-deep .select-tree {
  width: 200px;
  height: 36px;
  margin-left: 14px;
}
::v-deep .select-tree .el-input--suffix .el-input__inner {
  height: 36px;
}
.approval_t_left {
  display: flex;
  align-items: center;
}
.approval_t_right {
  width: 80px;
  height: 36px;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #d5d7e3;
  font-size: 14px;
  color: #7e84a3;
  line-height: 36px;
  text-align: center;
  cursor: pointer;
}
.approval_t_left2 {
  display: flex;
  align-items: center;
}
::v-deep .approval_t_left2 .el-select-dropdown__item {
  padding: 0;
}
.ellipsis {
  width: 150px;
  white-space: nowrap; /* 防止文本换行 */
  overflow: hidden; /* 隐藏超出容器的文本 */
  text-overflow: ellipsis; /* 显示省略号表示文本被截断 */
}
</style>
