<template>
  <div class="visitor_information">
    <div class="visitor_information_title">
      考勤报表 / <span>部门考勤汇总</span>
    </div>
    <div class="visitor_information_c">
      <div class="visitor_information_t">
        <div class="visitor_information_t_l">
          <el-date-picker
            v-model="date"
            @change="changeDate"
            type="daterange"
            range-separator="-"
            class="department_search_date"
            :picker-options="expireTimeOPtion"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
          <el-select
            ref="stlectTree"
            v-model="selectTree"
            placeholder="部门"
            :popper-append-to-body="false"
            class="select-tree"
          >
            <el-option :value="selectTreeValue" style="height: auto">
              <!-- option展开高度太小，把height设置为auto就好啦 -->
              <el-tree
                :data="departmentList"
                node-key="departmentId"
                ref="tree"
                accordion
                highlight-current
                :props="defaultProps"
                @node-click="nodeClick"
              ></el-tree>
            </el-option>
          </el-select>
        </div>
        <div>
          <el-button @click="setReport">设置报表</el-button>
          <el-button @click="startDownload">导出报表</el-button>
        </div>
      </div>
      <div class="visitor_information_table">
        <el-table
          v-loading="tableloading"
          height="95%"
          row-key="id"
          ref="multipleTable"
          :key="tableKey"
          :data="tableData"
          :cell-class-name="cellClass"
          class="v_table"
          border
          @selection-change="handleSelectionChange"
          :header-cell-style="getRowClass"
        >
          <template slot="empty">
            <div>
              <img src="@/assets/nodata.png" class="nodata" />
              <div>无数据</div>
            </div>
          </template>
          <el-table-column type="selection" width="55" reserve-selection>
          </el-table-column>
          <el-table-column label="部门" width="150" fixed>
            <template slot-scope="scope">
              <div class="departstyle">
                {{ scope.row.name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="peopleCount"
            label="部门人数"
            width="150"
            fixed
          >
          </el-table-column>
          <el-table-column
            prop="shouldDayCount"
            label="应出勤天数"
            min-width="150"
            v-if="judgingHeader(3)"
          >
            <template slot-scope="scope">
              <div v-if="scope.row.bmsAttendanceRecordList">
                {{ rquiredAttendance(scope.row.bmsAttendanceRecordList) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="实际出勤天数"
            min-width="150"
            v-if="judgingHeader(4)"
          >
            <template slot-scope="scope">
              <div v-if="scope.row.bmsAttendanceRecordList">
                {{ actualAttendance(scope.row.bmsAttendanceRecordList) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="出勤率(%)"
            min-width="150"
            v-if="judgingHeader(5)"
          >
            <template slot-scope="scope">
              <div v-if="scope.row.bmsAttendanceRecordList">
                {{ attendanceRate(scope.row.bmsAttendanceRecordList) }}
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="shouldDayCount"
            label="标准工作时长"
            min-width="150"
            v-if="judgingHeader(6)"
          >
          </el-table-column>
          <el-table-column
            prop="shouldDayCount"
            label="实际工作时长"
            min-width="150"
            v-if="judgingHeader(7)"
          >
            <template slot-scope="scope">
              <div v-if="scope.row.bmsAttendanceRecordList">
                {{ calculateSummary(scope.row.bmsAttendanceRecordList, 1) }}
              </div>
            </template>
          </el-table-column> -->
          <el-table-column
            label="异常统计"
            v-if="judgingHeader2([9, 10, 11, 12, 13])"
          >
            <el-table-column
              label="迟到次数"
              min-width="150"
              v-if="judgingHeader(9)"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.bmsAttendanceRecordList">
                  {{ calculateSummary(scope.row.bmsAttendanceRecordList, 2) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="shouldDayCount"
              label="迟到时长(分钟)"
              min-width="150"
              v-if="judgingHeader(10)"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.bmsAttendanceRecordList">
                  {{ calculateSummary(scope.row.bmsAttendanceRecordList, 3) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="早退次数"
              min-width="150"
              v-if="judgingHeader(11)"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.bmsAttendanceRecordList">
                  {{ calculateSummary(scope.row.bmsAttendanceRecordList, 4) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="shouldDayCount"
              label="早退时长(分钟)"
              min-width="150"
              v-if="judgingHeader(12)"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.bmsAttendanceRecordList">
                  {{ calculateSummary(scope.row.bmsAttendanceRecordList, 5) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="旷工天数"
              min-width="150"
              v-if="judgingHeader(13)"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.bmsAttendanceRecordList">
                  {{ calculateSummary(scope.row.bmsAttendanceRecordList, 6) }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            label="假勤情况"
            v-if="judgingHeader2([15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25])"
          >
            <el-table-column
              label="补卡次数"
              min-width="120"
              v-if="judgingHeader(15)"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.bmsAttendanceRecordList">
                  {{ calculateSummary(scope.row.bmsAttendanceRecordList, 7) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="外出"
              min-width="120"
              v-if="judgingHeader(17)"
            >
              <template slot-scope="scope">
                <div
                  v-if="
                    calculateSummary(scope.row.bmsAttendanceRecordList, 8) != 0
                  "
                >
                  {{
                    calculateSummary(scope.row.bmsAttendanceRecordList, 8)
                  }}小时
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="出差"
              min-width="120"
              v-if="judgingHeader(18)"
            >
              <template slot-scope="scope">
                <div
                  v-if="
                    calculateSummary(scope.row.bmsAttendanceRecordList, 9) != 0
                  "
                >
                  {{ calculateSummary(scope.row.bmsAttendanceRecordList, 9) }}天
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="年假"
              min-width="120"
              v-if="judgingHeader(19)"
            >
              <template slot-scope="scope">
                <div
                  v-if="
                    calculateSummary(scope.row.bmsAttendanceRecordList, 10) != 0
                  "
                >
                  {{
                    calculateSummary(scope.row.bmsAttendanceRecordList, 10)
                  }}天
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="事假"
              min-width="120"
              v-if="judgingHeader(20)"
            >
              <template slot-scope="scope">
                <div
                  v-if="
                    calculateSummary(scope.row.bmsAttendanceRecordList, 11) != 0
                  "
                >
                  {{
                    calculateSummary(scope.row.bmsAttendanceRecordList, 11)
                  }}天
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="病假"
              min-width="120"
              v-if="judgingHeader(21)"
            >
              <template slot-scope="scope">
                <div
                  v-if="
                    calculateSummary(scope.row.bmsAttendanceRecordList, 12) != 0
                  "
                >
                  {{
                    calculateSummary(scope.row.bmsAttendanceRecordList, 12)
                  }}天
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="调休假"
              min-width="120"
              v-if="judgingHeader(22)"
            >
              <template slot-scope="scope">
                <div
                  v-if="
                    calculateSummary(scope.row.bmsAttendanceRecordList, 13) != 0
                  "
                >
                  {{
                    calculateSummary(scope.row.bmsAttendanceRecordList, 13)
                  }}天
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="婚假"
              min-width="120"
              v-if="judgingHeader(23)"
            >
              <template slot-scope="scope">
                <div
                  v-if="
                    calculateSummary(scope.row.bmsAttendanceRecordList, 14) != 0
                  "
                >
                  {{
                    calculateSummary(scope.row.bmsAttendanceRecordList, 14)
                  }}天
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="产休假"
              min-width="120"
              v-if="judgingHeader(24)"
            >
              <template slot-scope="scope">
                <div
                  v-if="
                    calculateSummary(scope.row.bmsAttendanceRecordList, 15) != 0
                  "
                >
                  {{
                    calculateSummary(scope.row.bmsAttendanceRecordList, 15)
                  }}天
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="其他"
              min-width="120"
              v-if="judgingHeader(25)"
            >
              <template slot-scope="scope">
                <div
                  v-if="
                    calculateSummary(scope.row.bmsAttendanceRecordList, 16) != 0
                  "
                >
                  {{
                    calculateSummary(scope.row.bmsAttendanceRecordList, 16)
                  }}天
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            label="加班情况"
            v-if="judgingHeader2([27, 28, 29, 30])"
          >
            <el-table-column
              label="加班时长(小时)"
              min-width="200"
              v-if="judgingHeader(27)"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.allwork != 0">
                  {{ handleOvertime(scope.row.allwork) }}
                </div>
                <div v-else>- -</div>
              </template>
            </el-table-column>
            <el-table-column
              label="工作日加班时长(小时)"
              min-width="200"
              v-if="judgingHeader(28)"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.bmsAttendanceRecordList">
                  {{ handleOvertimeType(scope.row.bmsAttendanceRecordList, 1) }}
                </div>
                <div v-else>- -</div>
              </template>
            </el-table-column>
            <el-table-column
              label="休息日加班时长(小时)"
              min-width="200"
              v-if="judgingHeader(29)"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.bmsAttendanceRecordList">
                  {{ handleOvertimeType(scope.row.bmsAttendanceRecordList, 2) }}
                </div>
                <div v-else>- -</div>
              </template>
            </el-table-column>
            <el-table-column
              label="节假日加班时长(小时)"
              min-width="200"
              v-if="judgingHeader(30)"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.bmsAttendanceRecordList">
                  {{ handleOvertimeType(scope.row.bmsAttendanceRecordList, 3) }}
                </div>
                <div v-else>- -</div>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="filing">
      CopyRight@2023广州巨源信息技术有限公司-<a
        href="https://beian.miit.gov.cn/"
        >粤ICP备2023127373号-4</a
      >
    </div>

    <!-- 设置报表 -->
    <el-drawer
      :wrapperClosable="false"
      title=""
      :visible.sync="setReportDialog"
      :with-header="false"
      size="25%"
    >
      <div class="batch_t">
        <div class="branch_l">
          <img
            src="@/assets/structure/close.png"
            class="batch_close"
            @click="close"
          />
          <div>设置报表</div>
        </div>
        <div class="branch_bto">
          <el-button @click="close">取消</el-button>
          <el-button type="primary" @click="switcOperation">确认</el-button>
        </div>
      </div>
      <div class="batch_c">
        <el-tree
          :data="setReportList"
          ref="tree"
          show-checkbox
          node-key="id"
          :props="defaultProps2"
        >
        </el-tree>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import http from "../../../utils/http";
import exprotExcel from "../../../utils/exprotExcel";
export default {
  data() {
    return {
      tableloading: true,
      searchValue: "", //搜索的关键词
      searchicon: false, //监听输入
      tableData: [], //表格数据
      tipstitle: "确认删除此访客?",
      selectDeptid: [], //选择的部门
      selectUserid: [], //最终删除的数组
      date: null, //搜索的日期
      startTime: "", //开始日期
      endTime: "", //结束日期
      pages: {}, //分页
      pageNum: 1, //当前页
      searchForm: {}, //搜索详情
      multipleSelection: [],
      departmentList: [], //部门列表
      tableSelection: [], //导出表格需要
      company: {}, //公司信息
      delectVistorDiglog: false, //删除访客提示框
      tableKey: Math.random(), //表格key
      expireTimeOPtion: {
        disabledDate(time) {
          return time.getTime() > Date.now(); //如果没有后面的-8.64e7就是不可以选择今天的
        },
      },
      selectTree: "",
      treeKey: 0,
      selectTreeValue: "",
      defaultProps: {
        children: "subDepartment",
        label: "name",
      },
      defaultProps2: {
        children: "children",
        label: "label",
      },
      setReportDialog: false, //设置报表
      tableColumnList: [], //生成的报表头
      setReportList: [
        {
          label: "部门",
          id: 1,
          disabled: true,
        },
        {
          label: "部门人数",
          id: 2,
          disabled: true,
        },
        {
          label: "应出勤天数",
          id: 3,
        },
        {
          label: "实际出勤天数",
          id: 4,
        },
        {
          label: "出勤率",
          id: 5,
        },
        // {
        //   label: "标准工作时长",
        //   id: 6,
        // },
        // {
        //   label: "实际工作时长",
        //   id: 7,
        // },
        {
          label: "异常统计",
          id: 8,
          children: [
            {
              label: "迟到次数",
              id: 9,
            },
            {
              label: "迟到时长",
              id: 10,
            },
            {
              label: "早退次数",
              id: 11,
            },
            {
              label: "早退时长",
              id: 12,
            },
            {
              label: "旷工天数",
              id: 13,
            },
          ],
        },
        {
          label: "假勤情况",
          id: 14,
          children: [
            {
              label: "补卡次数",
              id: 15,
            },
            {
              label: "外出",
              id: 17,
            },
            {
              label: "出差",
              id: 18,
            },
            {
              label: "年假",
              id: 19,
            },
            {
              label: "事假",
              id: 20,
            },
            {
              label: "病假",
              id: 21,
            },
            {
              label: "调休假",
              id: 22,
            },
            {
              label: "婚假",
              id: 23,
            },
            {
              label: "产休假",
              id: 24,
            },
            {
              label: "其他",
              id: 25,
            },
          ],
        },
        {
          label: "加班情况",
          id: 26,
          children: [
            {
              label: "加班时长",
              id: 27,
            },
            {
              label: "工作日加班时长",
              id: 28,
            },
            {
              label: "休息日加班时长",
              id: 29,
            },
            {
              label: "节假日加班时长",
              id: 30,
            },
          ],
        },
      ], //报表总名称
      tableColumn: [
        {
          title: "部门",
          dataIndex: "name",
          id: 1,
        },
        {
          title: "部门人数",
          dataIndex: "peopleCount",
          id: 2,
        },
        {
          title: "应出勤天数",
          dataIndex: "should",
          id: 3,
        },
        {
          title: "实际出勤天数",
          dataIndex: "actual",
          id: 4,
        },
        {
          title: "出勤率(%)",
          dataIndex: "rate",
          id: 5,
        },
        // {
        //   title: "标准工作时长",
        //   key: "classestime",
        //   id: 6,
        // },
        // {
        //   title: "实际工作时长",
        //   key: "value1",
        //   id: 7,
        // },
        {
          title: "异常统计",
          children: [
            {
              title: "迟到次数",
              dataIndex: "value2",
              id: 9,
            },
            {
              title: "迟到时长(分钟)",
              dataIndex: "value3",
              id: 10,
            },
            {
              title: "早退次数",
              dataIndex: "value4",
              id: 11,
            },
            {
              title: "早退时长(分钟)",
              dataIndex: "value5",
              id: 12,
            },
            {
              title: "旷工天数",
              dataIndex: "value6",
              id: 13,
            },
          ],
        },
        {
          title: "假勤情况",
          children: [
            {
              title: "补卡次数",
              dataIndex: "value7",
              id: 15,
            },
            {
              title: "外出",
              dataIndex: "value8",
              id: 17,
            },
            {
              title: "出差",
              dataIndex: "value9",
              id: 18,
            },

            {
              title: "年假",
              dataIndex: "value10",
              id: 19,
            },
            {
              title: "事假",
              dataIndex: "value11",
              id: 20,
            },
            {
              title: "病假",
              dataIndex: "value12",
              id: 21,
            },
            {
              title: "调休假",
              dataIndex: "value13",
              id: 22,
            },
            {
              title: "婚假",
              dataIndex: "value14",
              id: 23,
            },
            {
              title: "产休假",
              dataIndex: "value15",
              id: 24,
            },
            {
              title: "其他",
              dataIndex: "value16",
              id: 25,
            },
          ],
        },
        {
          title: "加班情况",
          children: [
            {
              title: "加班时长(小时)",
              dataIndex: "overtime",
              id: 27,
            },
            {
              title: "工作日加班时长(小时)",
              dataIndex: "oovertime",
              id: 28,
            },
            {
              title: "休息日加班时长(小时)",
              dataIndex: "rovertime",
              id: 29,
            },
            {
              title: "节假日加班时长(小时)",
              dataIndex: "hovertime",
              id: 30,
            },
          ],
        },
      ], //导出内容参数
    };
  },
  created() {
    // 获取当前日期并搜索
    this.getDate();

    // 获取部门列表
    this.getDepartment();

    var arr = localStorage.getItem("departmentalreport");
    if (arr) {
      this.tableColumnList = JSON.parse(arr);
    } else {
      for (let index = 1; index < 33; index++) {
        this.tableColumnList.push(index);
      }
    }
  },
  methods: {
    // 表头背景颜色变色
    getRowClass({ rowIndex, columnIndex }) {
      if (rowIndex == 0) {
        return "background:#F5F6FA;color:#5C5C5C";
      }
    },

    // 获取部门列表
    getDepartment() {
      http.get("/umsDepartment/list").then((res) => {
        if (res.code == 200) {
          if (res.data.length > 0) {
            this.departmentList = this.convertToNestedStructure(res.data);
          }
        }
      });
    },
    // 递归处理数据
    convertToNestedStructure(arr) {
      // 创建一个映射，用于快速查找具有特定id的对象
      const idMap = new Map(arr.map((item) => [item.id, item]));

      // 创建一个新数组来存储转换后的嵌套结构
      const nestedArr = [];

      // 遍历原始数组
      arr.forEach((item) => {
        // 如果该项有父元素，则将其添加到父元素的subDepartment数组中
        if (item.parent) {
          const parent = idMap.get(item.parent);
          if (parent) {
            if (!parent.subDepartment) {
              parent.subDepartment = [];
            }
            item.parentDepament = parent.name;
            parent.subDepartment.push(item);
          }
        } else {
          // 如果没有父元素，则添加到新数组的根级别
          nestedArr.push(item);
        }
      });

      // 返回转换后的嵌套数组
      return nestedArr;
    },

    // 应出勤天数
    rquiredAttendance(arr) {
      if (arr) {
        var num = 0;
        arr.map((item) => {
          if (item.arrange > 0 || item.arrange == -4) {
            num += 1;
          }
        });
        return num;
      } else {
        return "- -";
      }
    },

    // 实际出勤天数
    actualAttendance(arr) {
      if (arr) {
        var num = 0;
        arr.map((item) => {
          if (item.arrange > 0) {
            if (item.action) {
              num += 1;
            }
          } else if (item.arrange == -4) {
            if (item.action) {
              num += 1;
            }
          }
        });
        return num;
      } else {
        return "- -";
      }
    },

    // 出勤率
    attendanceRate(arr) {
      var value = this.rquiredAttendance(arr);
      var value2 = this.actualAttendance(arr);

      if (value2 == 0 && value == 0) {
        return "0%";
      } else {
        var item = (value2 / value) * 100;
        return item.toFixed(1) + "%";
      }
    },

    // 实际工作时长
    calculateSummary(arr, type) {
      var num = 0;
      // 1实际工作时长，2迟到次数，3迟到时长，4早退次数，5早退时长，6旷工天数，7补卡次数，8外出，9出差，10年假，11事假，12病假，13调休假，14婚嫁，15产休假，16其它
      arr.map((item) => {
        switch (type) {
          case 1:
            if (item.secWork) {
              num += item.secWork;
            }
            break;
          case 2:
            if (item.lateCount) {
              num += item.lateCount;
            }
            break;
          case 3:
            if (item.secLate) {
              num += Math.floor(item.secLate / 60);
            }
            break;
          case 4:
            if (item.earlyCount) {
              num += item.earlyCount;
            }
            break;
          case 5:
            if (item.secEarly) {
              num += Math.ceil(item.secEarly / 60);
            }
            break;
          case 6:
            if (item.dayAbsence) {
              num += item.dayAbsence;
            }
            break;
          case 7:
            if (item.addCount) {
              num += item.addCount;
            }
            break;
          case 8:
            if (item.secOut) {
              num += item.secOut / 3600;
            }
            break;
          case 9:
            if (item.dayBusiness) {
              num += item.dayBusiness;
            }
            break;
          case 9:
            if (item.dayRestYear) {
              num += item.dayRestYear;
            }
            break;
          case 10:
            if (item.dayRestYear) {
              num += item.dayRestYear;
            }
            break;
          case 11:
            if (item.dayRestMatter) {
              num += item.dayRestMatter;
            }
            break;
          case 12:
            if (item.dayRestSick) {
              num += item.dayRestSick;
            }
            break;
          case 13:
            if (item.dayRestExchange) {
              num += item.dayRestExchange;
            }
            break;
          case 14:
            if (item.dayRestMarry) {
              num += item.dayRestMarry;
            }
            break;
          case 15:
            if (item.dayRestMaternity) {
              num += item.dayRestMaternity;
            }
            break;
          case 16:
            if (item.dayRestOther) {
              num += item.dayRestOther;
            }
            break;
        }
      });
      if (type == 8) {
        return num.toFixed(1);
      } else {
        return num;
      }
    },

    // 折叠所有节点
    setAllExpand(arr, state) {
      const _this = this;
      arr.forEach((i) => {
        // toggleRowExpansion(i, isExpand)用于多选表格，切换某一行的选中状态
        // 如果使用了第二个参数，则是设置这一行选中与否（selected 为 true 则选中）
        _this.$refs.multipleTable.toggleRowExpansion(i, state);
        if (i.childlist) {
          _this.setAllExpand(i.childlist, state);
        }
      });
    },
    // 全部收起
    closeAll() {
      const _this = this;
      _this.$nextTick(() => {
        _this.setAllExpand(_this.tableData, false);
      });
    },

    // 获取访客列表
    getVisitor() {
      http
        .get(
          "/bmsAttendanceRecord/search/group",
          Object.assign(this.searchForm, {
            pageSize: 10,
            pageNum: this.pageNum,
          })
        )
        .then((res) => {
          if (res.code == 200) {
            this.pages = res.data;
            res.data.list.map((item) => {
              item.allwork = 0;
              item.bmsAttendanceRecordList.map((citem) => {
                item.allwork += citem.secOvertime;
              });
              item.hasChildren = true;
              item.ischeck = true;
            });
            this.tableData = res.data.list;
            this.tableloading = false;

            if (this.$refs.multipleTable) {
              this.closeAll();
            }
          }
        });
    },

    // 设置报表
    setReport() {
      this.setReportDialog = true;
      setTimeout(() => {
        this.$refs.tree.setCheckedKeys(this.tableColumnList);
      }, 200);
    },

    // 关闭报表
    close() {
      this.setReportDialog = false;
    },

    // 确认修改报表
    switcOperation() {
      const list = this.$refs.tree.getCheckedKeys(true);
      this.tableColumnList = list;
      list.unshift(1, 2);
      localStorage.setItem("departmentalreport", JSON.stringify(list));
      this.setReportDialog = false;

      this.tableKey = Math.random();
    },

    // 判断是否含有所属的表头
    judgingHeader(index) {
      if (this.tableColumnList.includes(index)) {
        return true;
      } else {
        return false;
      }
    },
    // 判断是否含有所属的表头(一级)
    judgingHeader2(value) {
      var arr1 = this.tableColumnList;
      // 异常统计
      var istrue = this.hasIntersection(arr1, value);
      if (istrue) {
        return true;
      } else {
        return false;
      }
    },
    // 判断两个数组是否有交集
    hasIntersection(arr1, arr2) {
      for (let i = 0; i < arr1.length; i++) {
        for (let j = 0; j < arr2.length; j++) {
          if (arr1[i] === arr2[j]) {
            return true;
          }
        }
      }
      return false;
    },

    // 表格多选
    handleSelectionChange(val) {
      this.multipleSelection = [];
      this.tableSelection = val;
      val.map((item) => {
        this.multipleSelection.push(item.id);
      });
    },

    // 表格下载之前
    async startDownload() {
      let that = this;
      var arr = JSON.stringify(that.tableData);
      arr = JSON.parse(arr);
      let target = event.target;
      if (target.nodeName == "I" || target.nodeName == "SPAN") {
        target = event.target.parentNode;
      }
      target.blur();
      // 判断一个对象是否应该保留
      let shouldKeep = (obj) => {
        if (obj.id !== undefined) {
          return that.tableColumnList.includes(obj.id);
        } else if (obj.children !== undefined) {
          obj.children = obj.children.filter(shouldKeep);
          return obj.children.length > 0;
        }
      };
      var arr2 = that.tableColumn.filter(shouldKeep);
      var finalArr = [];
      const loading = that.$loading({
        lock: true,
        text: "导出表格中，请稍后...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      var total = that.pages.total;

      if (that.tableSelection.length > 0) {
        finalArr = that.tableSelection;
      } else {
        if (that.searchForm.beginDay && that.searchForm.endDay) {
          var date1 = new Date(that.searchForm.beginDay);
          var date2 = new Date(that.searchForm.endDay);
          const oneDay = 24 * 60 * 60 * 1000; // 一天的毫秒数
          const diff = Math.abs(date2.getTime() - date1.getTime()); // 计算差值的绝对值
          var isTrue = diff > 30 * oneDay;
          if (isTrue || total >= 40000) {
            loading.close();
            that.$message.error("导出时间相隔不能超过一个月,且不能高于4万条");
            return;
          }
        }
        try {
          var arr = await http.get(
            "/bmsAttendanceRecord/search/group",
            Object.assign(that.searchForm, {
              pageSize: total,
              pageNum: 1,
            })
          );
          if (arr.code == 200) {
            finalArr = arr.data.list;
          } else {
            loading.close();
          }
        } catch (e) {
          loading.close();
        }
      }

      if (finalArr.length > 0) {
        loading.close();
        finalArr.map((v) => {
          if (v.bmsAttendanceRecordList) {
            v.allwork = 0;
            v.bmsAttendanceRecordList.map((citem) => {
              v.allwork += citem.secOvertime;
            });
            v.should = that.rquiredAttendance(v.bmsAttendanceRecordList);
            v.actual = that.actualAttendance(v.bmsAttendanceRecordList);
            v.rate = that.attendanceRate(v.bmsAttendanceRecordList);
            v.value1 = that.calculateSummary(v.bmsAttendanceRecordList, 1);
            v.value2 = that.calculateSummary(v.bmsAttendanceRecordList, 2);
            v.value3 = that.calculateSummary(v.bmsAttendanceRecordList, 3);
            v.value4 = that.calculateSummary(v.bmsAttendanceRecordList, 4);
            v.value5 = that.calculateSummary(v.bmsAttendanceRecordList, 5);
            v.value6 = that.calculateSummary(v.bmsAttendanceRecordList, 6);
            v.value7 = that.calculateSummary(v.bmsAttendanceRecordList, 7);
            if (that.calculateSummary(v.bmsAttendanceRecordList, 8) != 0) {
              v.value8 =
                that.calculateSummary(v.bmsAttendanceRecordList, 8) + "小时";
            }
            if (that.calculateSummary(v.bmsAttendanceRecordList, 9) != 0) {
              v.value9 =
                that.calculateSummary(v.bmsAttendanceRecordList, 9) + "天";
            }
            if (that.calculateSummary(v.bmsAttendanceRecordList, 10) != 0) {
              v.value10 =
                that.calculateSummary(v.bmsAttendanceRecordList, 10) + "天";
            }
            if (that.calculateSummary(v.bmsAttendanceRecordList, 11) != 0) {
              v.value11 =
                that.calculateSummary(v.bmsAttendanceRecordList, 11) + "天";
            }
            if (that.calculateSummary(v.bmsAttendanceRecordList, 12) != 0) {
              v.value12 =
                that.calculateSummary(v.bmsAttendanceRecordList, 12) + "天";
            }
            if (that.calculateSummary(v.bmsAttendanceRecordList, 13) != 0) {
              v.value13 =
                that.calculateSummary(v.bmsAttendanceRecordList, 13) + "天";
            }
            if (that.calculateSummary(v.bmsAttendanceRecordList, 14) != 0) {
              v.value14 =
                that.calculateSummary(v.bmsAttendanceRecordList, 14) + "天";
            }
            if (that.calculateSummary(v.bmsAttendanceRecordList, 15) != 0) {
              v.value15 =
                that.calculateSummary(v.bmsAttendanceRecordList, 15) + "天";
            }
            if (that.calculateSummary(v.bmsAttendanceRecordList, 16) != 0) {
              v.value16 =
                that.calculateSummary(v.bmsAttendanceRecordList, 16) + "天";
            }
            v.overtime = that.handleOvertime(v.allwork);
            v.oovertime = that.handleOvertimeType(v.bmsAttendanceRecordList, 1);
            v.rovertime = that.handleOvertimeType(v.bmsAttendanceRecordList, 2);
            v.hovertime = that.handleOvertimeType(v.bmsAttendanceRecordList, 3);
          }
          for (let i = 0; i < arr2.length; i++) {
            if (v[arr2[i]] == undefined) {
              v[arr2[i]] = "";
            }
          }
          for (let key in v) {
            if (v[key] == null) v[key] = "";
          }
        });

        exprotExcel.export(arr2, finalArr, "部门考勤汇总");
        that.$message.success("导出成功");
      } else {
        loading.close();
        that.$message.error("导出失败");
      }
    },
    // 页数改变
    chagePage(e) {
      this.searchSetof(e);
    },

    // 搜索结果
    seachResult() {
      if (this.searchValue == "") {
        return;
      }

      this.searchSetof(1);
    },

    // 清除输入框
    clearup() {
      this.searchValue = "";
    },

    // 删除访客弹窗
    deleteInvitefirst(ids) {
      let target = event.target;
      if (target.nodeName == "I" || target.nodeName == "SPAN") {
        target = event.target.parentNode;
      }
      target.blur();
      var arr = [];
      if (Array.isArray(ids)) {
        if (ids.length == 0) {
          this.$message.error("请先选择部门");
          return;
        }
        this.tipstitle = "确认删除所选的部门？";
        arr = ids;
      } else {
        this.tipstitle = "确认删除此部门？";
        arr = [ids];
      }
      setTimeout(() => {
        this.delectVistorDiglog = true;
      }, 200);
      this.selectUserid = arr;
    },

    // 取消删除
    closeDelete() {
      this.delectVistorDiglog = false;
    },

    nodeClick(item) {
      const { id, name } = item;
      this.selectTree = name;
      this.selectTreeValue = id;
      this.$refs.stlectTree.blur();

      this.searchSetof(1);
    },

    // 选择所选日期
    changeDate(e) {
      if (e) {
        var start = new Date(e[0]);
        var end = new Date(e[1]);

        var syear = start.getFullYear();
        var smonth =
          start.getMonth() + 1 <= 9
            ? "0" + (start.getMonth() + 1)
            : start.getMonth() + 1;
        var sday =
          start.getDate() <= 9 ? "0" + start.getDate() : start.getDate();

        var eyear = end.getFullYear();
        var emonth =
          end.getMonth() + 1 <= 9
            ? "0" + (end.getMonth() + 1)
            : end.getMonth() + 1;
        var eday = end.getDate() <= 9 ? "0" + end.getDate() : end.getDate();

        this.startTime = syear + "-" + smonth + "-" + sday;
        this.endTime = eyear + "-" + emonth + "-" + eday;
      } else {
        this.$nextTick(() => {
          setTimeout(() => {
            this.getDate();
          }, 500);
        });
      }

      this.searchSetof(1);
    },

    // 搜索集合
    searchSetof(page) {
      if (page) {
        this.pageNum = page;
      } else {
        this.pageNum = 1;
      }
      var object = {};
      if (this.startTime && this.endTime) {
        object.beginDay = this.startTime;
        object.endDay = this.endTime;
      }

      if (this.selectTreeValue) {
        object.department = this.selectTreeValue;
      }
      this.searchForm = object;

      this.getVisitor();
    },

    // 查看下级部门
    checkDept(tree, treeNode, resolve) {
      var id = tree.id;
      http
        .get("/attendanceRecord/search/group", {
          department: id,
          startDate: this.startTime,
          endDate: this.endTime,
          isGetChild: 1,
        })
        .then((res) => {
          if (res.code == 200) {
            res.data.list.map((item) => {
              item.ischeck = false;
              item.hasChildren = true;
            });
            var list = res.data.list;
            resolve(list);
          }
        });
    },
    // 彻底隐藏

    cellClass({ row, columnIndex }) {
      if (!row.ischeck) {
        // 根据某个属性，添加样式，隐藏复选框
        return "myCell";
      }
    },

    // 获取当前月份
    getDate() {
      var date = new Date();
      var year = date.getFullYear(); //获取完整的年份(4位)
      var month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      var newmonth =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      var day = date.getDate();

      var lastDate = new Date(year, month, 0).getDate() + 1; //获取当月最后一日
      if (day == "01") {
        this.startTime = year + "-" + month + "-01";
      } else {
        this.startTime = year + "-" + newmonth + "-01";
      }

      if (day <= lastDate) {
        var newday = "";
        if (day < 10) {
          newday = "0" + day;
        } else {
          newday = day;
        }
        this.endTime = year + "-" + newmonth + "-" + newday;
      }
      this.date = [this.startTime, this.endTime];

      this.searchSetof();
    },

    itemError(item) {
      item.headUrl = require("@/assets/head.png");
    },
    // 处理加班时长
    handleOvertime(val) {
      if (val) {
        var num = val / 3600;
        return num.toFixed(1);
      } else {
        return "- -";
      }
    },
    // 处理加班类型时长
    handleOvertimeType(arr, type) {
      if (arr) {
        var num = 0;
        arr.map((item) => {
          if (type == 1) {
            if (item.arrange > 0 || item.arrange == -4) {
              num += item.secOvertime / 3600;
            }
          }

          if (type == 2) {
            if (item.arrange == -1 || item.arrange == -2) {
              num += item.secOvertime / 3600;
            }
          }

          if (type == 3) {
            if (item.arrange == -3) {
              num += item.secOvertime / 3600;
            }
          }
        });
      }

      if (num == 0) {
        return "- -";
      } else {
        return num.toFixed(1);
      }
    },
  },
};
</script>

<style scoped>
.visitor_information {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  box-sizing: border-box;
  background: #f4f7fc;
}
.visitor_information_c {
  width: 100%;
  box-shadow: 0px 1px 4px 1px rgba(21, 34, 50, 0.08);
  flex: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 6px;
  background: white;
}
.visitor_information_t {
  height: 76px;
  padding: 0 30px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(207, 212, 232, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.visitor_information_search {
  width: 243px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #d0d0d0;
  display: flex;
  align-items: center;
  padding-right: 12px;
  box-sizing: border-box;
  justify-content: space-between;
}
.department_search_inp {
  width: 200px;
}
.visitor_information_table {
  width: 100%;
  height: 100%;
  padding: 20px;
  position: relative;
  box-sizing: border-box;
}
.visitor_information_title {
  font-size: 14px;
  color: #b2b2b2;
  margin-bottom: 16px;
}
.visitor_information_title span {
  color: #5c5c5c;
}
.memeber_operation {
  margin-left: 8px;
  width: 107px;
}
.detailed {
  width: 86px;
  height: 26px;
  background: #e0ecff;
  color: #0058ff;
  font-size: 14px;
  text-align: center;
  line-height: 26px;
  cursor: pointer;
}
.qrcode {
  width: 100%;
}
.qrcode_frame {
  width: 457px;
  height: 358px;
  display: flex;
  color: #1a1a1a;
  margin: 60px auto 24px;
  flex-direction: column;
  align-items: center;
  padding-top: 46px;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.16);
}
.qrcode_name {
  font-size: 16px;
  margin-bottom: 30px;
}
.qrcode_img {
  width: 200px;
  height: 200px;
  background: black;
}
.qrcode_tips {
  color: #9a9a9a;
  font-size: 15px;
  text-align: center;
}
.qrcode_bto {
  display: flex;
  margin-top: 39px;
  justify-content: center;
}
.link_frame {
  width: 797px;
  height: 132px;
  margin: 30px;
  font-size: 16px;
  padding: 24px;
  color: #1a1a1a;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.16);
}
.link_tips {
  font-size: 15px;
  color: #9a9a9a;
  padding-left: 30px;
  box-sizing: border-box;
}
.link_bto {
  display: flex;
  margin-top: 289px;
  justify-content: center;
}
.invite_h {
  height: 73px;
  padding: 0 24px;
  color: #1a1a1a;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 18px;
  justify-content: space-between;
}
.invite_icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.visitor_name {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #1a1a1a;
}
.visitor_head {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 10px;
}
.primary-button-style {
  background: #2979ff;
  border-color: #2979ff;
}
::v-deep .el-table__body .el-table__row.hover-row td {
  background-color: rgba(0, 98, 255, 0.05);
}
.newpagination {
  margin-top: 74px;
}
.visitor_information_search:focus-within {
  border-color: #0096fa;
}
.v_table {
  width: 97%;
  height: 100%;
  position: absolute;
}
::v-deep .department_search_date {
  width: 283px;
  height: 36px;
  margin-right: 10px;
}
.visitor_information_t_l {
  display: flex;
  align-items: center;
}
.select-tree {
  width: 250px;
}
::v-deep .select-tree .el-select__tags {
  height: 40px;
  overflow: hidden;
  white-space: nowrap;
  flex-wrap: nowrap;
}
::v-deep .select-tree .el-select__tags-text {
  display: inline-block;
  max-width: 85px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
::v-deep .el-select-dropdown.is-multiple .el-select-dropdown__item {
  padding: 0;
}
::v-deep .el-table__fixed {
  height: 98% !important;
}
::v-deep .el-select-dropdown__item {
  padding: 0;
}
::v-deep .el-tree-node__content {
  height: 51px;
  font-size: 15px;
  color: #5c5c5c;
}
.departstyle {
  cursor: pointer;
}
::v-deep .el-table td.el-table__cell div {
  display: flex;
  align-items: center;
}
::v-deep .myCell .el-checkbox__input {
  display: none;
}
::v-deep .el-icon-arrow-right {
  background: url("@/assets/structure/arrowhead.png") center no-repeat;
  font-size: 8px;
}
::v-deep .el-icon-arrow-right:before {
  content: "替";
  font-size: 8px;
  visibility: hidden;
}
::v-deep .el-table__expand-icon--expanded {
  transform: rotate(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
}
::v-deep .el-select {
  height: 36px;
}
::v-deep .select-tree .el-select__tags {
  height: 36px;
  overflow: hidden;
  white-space: nowrap;
  flex-wrap: nowrap;
}
::v-deep .select-tree .el-select__tags-text {
  display: inline-block;
  max-width: 85px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
::v-deep .el-select-dropdown.is-multiple .el-select-dropdown__item {
  padding: 0;
}
::v-deep .el-select .el-input__inner {
  height: 36px;
}
::v-deep .el-date-editor .el-range-separator {
  line-height: 28px;
}
::v-deep .el-input--suffix .el-input__inner {
  height: 36px !important;
}
.branch_l {
  display: flex;
  align-items: center;
}
.batch_t {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
  box-sizing: border-box;
  padding-left: 20px;
  font-size: 18px;
  color: #1a1a1a;
  border-bottom: 1px solid #cfd4e8;
}
.batch_close {
  width: 24px;
  cursor: pointer;
  height: 24px;
  margin-right: 20px;
}
.batch_c {
  width: 100%;
  padding-top: 28px;
  box-sizing: border-box;
}
::v-deep .el-tree-node__label {
  line-height: auto;
}
</style>
