<template>
  <div class="login">
    <img src="@/assets/loginLogo.png" class="login_logo" />
    <div class="login_frame" v-if="pageType == 1">
      <div class="login_left">
        <!-- 登录页面 -->
        <div class="loginpage">
          <img src="@/assets/loginTitle.png" class="title" />
          <div class="login_selecttitle" v-if="ltype != 3">
            <div
              @click="switchStatus(1)"
              class="login_selecttitle_p"
              :style="{
                color: ltype == 1 || ltype == 3 ? '#0058FF' : '#1A1A1A',
              }"
            >
              <span v-if="ltype == 3"></span>
              <span v-else>手机号</span>
            </div>
            <div
              v-if="ltype != 3"
              @click="switchStatus(2)"
              class="login_selecttitle_e"
              :style="{ color: ltype == 2 ? '#0058FF' : '#1A1A1A' }"
            >
              邮箱
            </div>
          </div>
          <!-- 手机号 -->
          <el-form
            v-show="ltype == 1"
            ref="phoneForm"
            :model="pform"
            :rules="rules"
            autocomplete="off"
          >
            <el-form-item prop="account">
              <div class="phone">
                <el-input
                  type="tel"
                  v-model="pform.account"
                  placeholder="请输入手机号"
                ></el-input>
              </div>
            </el-form-item>
            <el-form-item prop="code">
              <div class="p_eamil">
                <el-input
                  v-model="pform.code"
                  placeholder="请输入验证码"
                ></el-input>
                <div
                  class="p_eamil_get"
                  v-if="isphoneShow"
                  @click="verification"
                >
                  获取验证码
                </div>
                <div v-else class="p_eamil_backwards">{{ phoneCount }}秒</div>
              </div>
            </el-form-item>
          </el-form>

          <!-- 邮箱 -->
          <el-form
            v-show="ltype == 2"
            ref="emailForm"
            :model="eform"
            autocomplete="off"
            :rules="rulese"
          >
            <el-form-item prop="account">
              <div class="phone">
                <el-input
                  v-model="eform.account"
                  placeholder="请输入邮箱"
                  type="email"
                ></el-input>
              </div>
            </el-form-item>
            <el-form-item prop="code">
              <div class="p_eamil">
                <el-input
                  v-model="eform.code"
                  placeholder="请输入验证码"
                ></el-input>
                <div
                  class="p_eamil_get"
                  v-if="isemailShow"
                  @click="verificationEmail"
                >
                  获取验证码
                </div>
                <div class="p_eamil_backwards" v-else>{{ emailCount }}秒</div>
              </div>
            </el-form-item>
          </el-form>

          <!-- 密码登陆 -->
          <div class="password_login" v-show="ltype == 3">
            <el-form
              ref="passwordForm"
              :model="pasform"
              :rules="rule3"
              autocomplete="off"
            >
              <el-form-item prop="account">
                <div class="phone">
                  <el-input
                    type="tel"
                    v-model="pasform.account"
                    placeholder="请输入手机号或邮箱"
                  ></el-input>
                </div>
              </el-form-item>
              <el-form-item prop="password">
                <div class="p_eamil">
                  <el-input
                    :type="isShowpas"
                    v-model="pasform.password"
                    placeholder="请输入密码"
                  ></el-input>
                  <img :src="showPasimg" class="check" @click="switchShow" />
                </div>
              </el-form-item>
              <!-- <el-form-item>
                <div class="password_code">
                  <el-input
                    class="password_input"
                    type="text"
                    maxlength="4"
                    v-model="pasform.inputCode"
                    placeholder="请输入您的验证码"
                  />
                  <div @click="refreshCode" class="password_security">
                    <SecurityCode :identifyCode="identifyCode"></SecurityCode>
                  </div>
                </div>
              </el-form-item> -->
            </el-form>
          </div>

          <Button :text="'登录'" :isinput="checkInput" @onSubmit="login" />
          <div class="required_frame">
            <div
              class="orderlogin"
              v-if="ltype != 2 && ltype == 1"
              @click="switchStatus(3)"
            >
              密码登录
            </div>
            <div
              class="orderlogin"
              v-if="ltype != 2 && ltype == 3"
              @click="switchStatus(1)"
            >
              验证码登录
            </div>
            <!-- <div class="policy_agreement">
              <el-checkbox v-model="agreement"> </el-checkbox>
              <div class="policy_agreement_t">
                我已阅读并同意<span>服务协议</span>和<span>隐私政策</span>
              </div>
            </div> -->
            <div class="controlbto">
              <div class="forget" v-if="ltype == 3" @click="goForget">
                忘记密码
              </div>
              <div class="register_text" @click="goRegitser">注册帐号</div>
            </div>
          </div>
          <!-- <div
            class="orderlogin"
            v-if="ltype != 2 && ltype == 1"
            @click="switchStatus(3)"
          >
            密码登录
          </div>
          <div
            class="orderlogin"
            v-if="ltype != 2 && ltype == 3"
            @click="switchStatus(1)"
          >
            验证码登录
          </div> -->
        </div>
      </div>
      <img src="~@/assets/login/loginlogo.png" class="loginlogo" />
    </div>

    <!-- 弹出验证码 -->
    <el-dialog
      title=""
      :width="width"
      :visible.sync="dialogTableVisible"
      :show-close="false"
      @open="getRef"
    >
      <slide-verify
        :w="640"
        :h="380"
        class="slide"
        ref="slideblock"
        @success="onSuccess"
        @fail="onFail"
        :imgs="puzzleImgList"
        :accuracy="accuracy"
        :slider-text="text"
      ></slide-verify>
    </el-dialog>

    <!-- 注册页面 -->
    <div v-if="pageType == 2" class="register">
      <Register @stopRegister="pageType = 1" />
    </div>

    <!-- 忘记密码 -->
    <div v-if="pageType == 3">
      <Forget @stopRegister="pageType = 1" :tel="pasform.account" />
    </div>

    <div class="filing2">
      CopyRight@2023广州巨源信息技术有限公司-<a
        href="https://beian.miit.gov.cn/"
        >粤ICP备2023127373号-4</a
      >
    </div>
  </div>
</template>

<script>
import Button from "../../assembly/button.vue";
import Register from "../login/register.vue";
import Forget from "../login/forget.vue";

import check from "../../assets/login/check.png";
import close from "../../assets/login/close.png";

import http from "../../utils/http";
import SecurityCode from "@/assembly/securityCode.vue";
export default {
  data() {
    return {
      pform: {
        account: "", //手机号
        code: "", //手机验证码
      },
      eform: {
        account: "", //邮箱
        code: "", //邮箱验证码
      },
      pasform: {
        account: "", //手机号
        password: "", //密码
      },
      dialogTableVisible: false, //弹出验证码层
      puzzleImgList: [],
      text: "向右滑",
      // 精确度小，可允许的误差范围小；为1时，则表示滑块要与凹槽完全重叠，才能验证成功。默认值为5
      accuracy: 3,
      width: "", //宽度
      storageToken: "", //储存的token
      isShowpas: "password",
      isCodeDisabled: false, //节流
      isEmailDisabled: false, //节流(邮箱)
      identifyCode: "", //图形验证码
      identifyCodeType: "1234567890", //定义验证类型 1.数字 2.字母
      showPasimg: check,
      timersecond: 0,
      timer: null,
      isphoneShow: true, //手机验证码
      phoneCount: 0, //倒计时 计数器
      isemailShow: true, //邮箱验证码
      emailCount: 0, //倒计时 计数器
      checkInput: false, //检测是否完全输入
      ltype: 3, //1是手机号，2是邮箱,3是密码登陆
      pageType: 1, //1是登录，2是注册，3是找回密码
      agreement: false, //同意服务协议和政策

      rules: {
        account: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      rulese: {
        account: [{ required: true, message: "请输入邮箱", trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      rule3: {
        account: [
          { required: true, message: "请输入手机号或邮箱", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },

  created() {
    this.handelImg(); //处理图片
  },

  watch: {
    // 监听返回
    pageType(newName, oldName) {
      if (newName == 1) {
        var num = localStorage.getItem("countdown");
        if (num && num > 0) {
          this.setTimersecond(num);
        }
      }
    },
    // 监听输入框变化
    pform: {
      handler: function (val, oldval) {
        if (this.ltype != 1) {
          return;
        }
        if (val.account && val.code) {
          this.checkInput = true;
        } else {
          this.checkInput = false;
        }
      },
      deep: true,
    },

    eform: {
      handler: function (val, oldval) {
        if (this.ltype != 2) {
          return;
        }
        if (val.account && val.code) {
          this.checkInput = true;
        } else {
          this.checkInput = false;
        }
      },
      deep: true,
    },

    pasform: {
      handler: function (val, oldval) {
        if (this.ltype != 3) {
          return;
        }
        if (val.account && val.password) {
          this.checkInput = true;
        } else {
          this.checkInput = false;
        }
      },
      deep: true,
    },
  },

  components: { Button, Register, Forget, SecurityCode },

  methods: {
    // 处理图片
    handelImg() {
      var arr = [];
      for (let index = 1; index < 231; index++) {
        var url =
          "https://comet-e-time.oss-rg-china-mainland.aliyuncs.com/other/" +
          index +
          ".jpg";
        arr.push(url);
      }

      this.puzzleImgList.push(...arr);
    },
    // 获取验证码(手机)
    verification() {
      if (!this.pform.account) {
        return;
      }

      // 判断是否是手机号
      if (!this.isPhoneNumber(this.pform.account)) {
        this.$message.error("请输入正确的手机号");
        return;
      }

      if (this.isCodeDisabled) {
        return;
      }

      this.isCodeDisabled = true;

      http
        .get("/umsSysUser/code_get", {
          phone: this.pform.account,
          getCodeType: "LOGIN_REGISTER",
        })
        .then((res) => {
          if (res.code == 200) {
            this.isphoneShow = false; //倒计时
            this.phoneCount = 60; //赋值3秒
            var times = setInterval(() => {
              this.phoneCount--; //递减
              if (this.phoneCount <= 0) {
                // <=0 变成获取按钮
                this.isphoneShow = true;
                this.isCodeDisabled = false;
                clearInterval(times);
              }
            }, 1000); //1000毫秒后执行
          } else {
            this.isCodeDisabled = false;
            this.$message.error(res.message);
          }
        });
    },

    // 判断是否为手机号
    isPhoneNumber(tel) {
      var reg = /^1[3,4,5,6,7,8,9][0-9]{9}$/;
      return reg.test(tel);
    },
    //验证码
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    //随机切换验证码
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode +=
          this.identifyCodeType[
            this.randomNum(0, this.identifyCodeType.length)
          ];
      }
    },

    // 继续倒计时
    setTimersecond(num) {
      this.timersecond = num;
      this.timer = setInterval(() => {
        this.timersecond--;
        if (this.timersecond === 0) {
          clearInterval(this.timer);
          localStorage.setItem("countdown", 0);
        }
        setTimeout(() => {
          localStorage.setItem("countdown", this.timersecond);
        }, 200);
      }, 1000);
    },
    // 获取验证码(邮箱)
    verificationEmail() {
      if (!this.eform.account) {
        return;
      }

      if (!this.isEmail(this.eform.account)) {
        this.$message.error("请输入正确的邮箱地址");
        return;
      }

      if (this.isEmailDisabled) {
        return;
      }

      this.isEmailDisabled = true;

      http
        .get("/umsSysUser/code_get", {
          email: this.eform.account,
          getCodeType: "LOGIN_REGISTER",
        })
        .then((res) => {
          this.isEmailDisabled = false;
          if (res.code == 200) {
            this.isemailShow = false; //倒计时
            this.emailCount = 60; //赋值3秒
            var times = setInterval(() => {
              this.emailCount--; //递减
              if (this.emailCount <= 0) {
                // <=0 变成获取按钮
                this.isemailShow = true;
                clearInterval(times);
              }
            }, 1000); //1000毫秒后执行
          } else {
            if (res.message == "操作失败") {
              this.$message.error("获取验证码过于频繁，请1分钟后再试");
            } else {
              this.$message.error(res.message);
            }
          }
        });
    },

    // 判断是否为邮箱
    isEmail(input) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(input);
    },
    // 验证成功，触发该方法
    onSuccess(times) {
      localStorage.setItem("token", this.storageToken);
      this.$message.success("验证成功");
      setTimeout(() => {
        this.$router.push({
          name: "Choosecompany",
        });
      }, 500);
    },
    onFail() {
      console.log("验证不通过");
      this.msg = "";
    },
    // 打开验证码
    openCode() {
      this.dialogTableVisible = true;
    },
    // 打开验证码弹窗
    getRef() {
      this.$nextTick(() => {
        var width = this.$refs.slideblock.$el.offsetWidth;

        this.width = width + "px";
      });
    },
    // 登录
    login() {
      var form = {};
      switch (this.ltype) {
        case 1:
          form.phone = this.pform.account;
          form.code = this.pform.code;
          form.getCodeType = "LOGIN_REGISTER";
          break;
        case 2:
          form.email = this.eform.account;
          form.code = this.eform.code;
          form.getCodeType = "LOGIN_REGISTER";
          break;
        case 3:
          form.account = this.pasform.account;
          form.password = this.pasform.password;
          break;
      }

      // 使用正则表达式判断输入的是手机号还是邮箱
      const phoneRegex = /^1[3-9]\d{9}$/;
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      // 先进行验证验证码操作
      if (this.ltype != 3) {
        http.post("/umsSysUser/code_verify", form).then((res) => {
          if (res.code == 200) {
            var object = {
              ticket: res.data.actionTicket,
              platformType: "PC",
            };
            if (this.ltype == 1) {
              object.phone = form.phone;
            } else if (this.ltype == 2) {
              object.email = form.email;
            }
            http.post("/umsSysUser/login", object).then((res2) => {
              if (res2.code == 200) {
                this.$message.success("登陆成功");
                this.$router.push({
                  name: "Choosecompany",
                });
                localStorage.setItem("token", res2.data.token);
              } else {
                this.$message.error(res2.message);
              }
            });
          } else {
            this.$message.error(res.message);
          }
        });
        // 直接登陆
      } else {
        var object = {};
        var istrue = phoneRegex.test(form.account);
        var istrue2 = emailRegex.test(form.account);
        delete this.pasform.inputCode;
        if (istrue) {
          object = {
            phone: form.account,
            password: form.password,
            platformType: "PC",
          };
        } else if (istrue2) {
          object = {
            email: form.account,
            password: form.password,
            platformType: "PC",
          };
        } else {
          this.$message.error("请输入正确的手机号或邮箱");
          return;
        }
        http.post("/umsSysUser/login", object).then((res2) => {
          if (res2.code == 200) {
            this.storageToken = res2.data.token;
            this.openCode();
          } else {
            this.$message.error(res2.message);
          }
        });
      }
    },
    // 切换密码显示
    switchShow() {
      if (this.isShowpas == "password") {
        this.isShowpas = "text";
        this.showPasimg = close;
      } else {
        this.isShowpas = "password";
        this.showPasimg = check;
      }
    },

    // 清除表单
    clearForm() {
      this.$refs["phoneForm"].resetFields();
      this.$refs["emailForm"].resetFields();
      this.$refs["passwordForm"].resetFields();
    },

    // 切换登录方式
    switchStatus(type) {
      if (type == this.ltype) {
        return;
      }

      this.clearForm();

      this.ltype = type;
    },

    // 注册账号
    goRegitser() {
      this.clearForm();

      this.pageType = 2;
    },

    // 忘记密码
    goForget() {
      if (!this.pasform.account) {
        this.$message({
          message: "请先输入手机号",
          type: "error",
        });
        return;
      }
      // 判断是否是手机号
      if (!this.isPhoneNumber(this.pasform.account)) {
        this.$message.error("请输入正确的手机号");
        return;
      }
      clearInterval(this.timer);
      this.pageType = 3;
    },
  },
};
</script>

<style scoped>
.login {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("@/assets/login/background.png");
  background-size: 100% 100%;
}
.login_frame {
  width: 1184px;
  height: 597px;
  background: white;
  box-shadow: 0px 0px 30px 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
}
.login_left {
  width: 592px;
  height: 100%;
  box-sizing: border-box;
}
.title {
  width: 324px;
  height: 43px;
}
.login_selecttitle {
  display: flex;
  margin: 59px 0 26px;
  font-size: 18px;
}
.login_selecttitle_p {
  margin-right: 30px;
  cursor: pointer;
}
.login_selecttitle_e {
  cursor: pointer;
}
.phone {
  width: 419px;
  height: 48px;
}
.p_eamil {
  width: 417px;
  position: relative;
  display: flex;
  align-items: center;
  height: 48px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
}
.p_eamil_get {
  height: 20px;
  padding-left: 19px;
  box-sizing: border-box;
  border-left: 1px solid #c6c6c6;
  color: #0058ff;
  font-size: 14px;
  cursor: pointer;
}
.p_eamil_backwards {
  height: 20px;
  text-align: center;
  flex: 1;
  box-sizing: border-box;
  border-left: 1px solid #c6c6c6;
  color: #b2b2b2;
  font-size: 14px;
}
.loginlogo {
  width: 592px;
  height: 100%;
}
.required_frame {
  margin-top: 19px;
  width: 419px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.policy_agreement {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #1a1a1a;
}
.policy_agreement_t {
  margin-left: 8px;
}
.policy_agreement span {
  color: #0058ff;
  cursor: pointer;
}
.register_text {
  font-size: 14px;
  color: #909090;
  cursor: pointer;
  margin-left: 6px;
}
.check {
  position: absolute;
  top: 50%;
  right: 15px;
  cursor: pointer;
  width: 18px;
  height: 18px;
  transform: translateY(-50%);
}
.orderlogin {
  font-size: 14px;
  color: #909090;
  cursor: pointer;
}
.loginpage {
  padding: 83px 0 0 87px;
  box-sizing: border-box;
}
.controlbto {
  display: flex;
  align-items: center;
}
.forget {
  padding-right: 5px;
  box-sizing: border-box;
  cursor: pointer;
  color: #909090;
  border-right: 1px solid #c6c6c6;
}
.register {
  width: 580px;
  height: 597px;
  background: white;
}
.filing2 {
  position: fixed;
  bottom: 20px;
  text-align: center;
  color: rgba(26, 26, 26, 0.6);
}
.login_logo {
  width: 195px;
  height: 50px;
  position: absolute;
  left: 30px;
  top: 24px;
}
.password_code {
  display: flex;
  width: 419px;
  height: 48px;
  align-items: center;
}
.password_input {
  height: 100%;
}
.password_security {
  height: 100%;
}
::v-deep .password_input .el-input__inner {
  height: 100%;
}
.password_login {
  margin-top: 80px;
  margin-bottom: 20px;
}
::v-deep .el-dialog {
  padding: 0;
  /* width: auto; */
}
::v-deep .slide-verify-slider {
  width: 99.8% !important;
  margin: 0 !important;
}
</style>
