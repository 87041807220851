<template>
  <div>
    <div class="batch_t">
      <div class="branch_l">
        <img
          src="@/assets/structure/close.png"
          class="batch_close"
          @click="close"
        />
        <div>补卡</div>
      </div>
      <div class="branch_bto">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="switcOperation">确认</el-button>
      </div>
    </div>
    <div class="batch_c">
      <!-- <div class="surplus">剩余补卡次数:{{ frequency }}</div> -->
      <el-form
        ref="batch"
        label-position="top"
        label-width="80px"
        :model="formLabelAlign"
        :rules="rules"
      >
        <el-form-item label="员工" prop="ids">
          <div class="unableChange">{{ name.join(",") }}</div>
        </el-form-item>
        <el-form-item label="补卡日期" prop="date">
          <el-date-picker
            @change="dateLimit"
            v-model="formLabelAlign.date"
            type="date"
            style="width: 100%"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="补卡时间" prop="time">
          <el-time-picker
            v-model="formLabelAlign.time"
            style="width: 100%"
            format="HH:mm"
          >
          </el-time-picker>
        </el-form-item>
        <el-form-item label="补卡类型" prop="typeRecord">
          <el-select v-model="formLabelAlign.typeRecord" style="width: 100%">
            <el-option
              v-for="item in replacementType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="补卡事由" prop="description">
          <el-input
            type="textarea"
            :rows="4"
            v-model="formLabelAlign.description"
            maxlength="100"
            show-word-limit
          >
          </el-input>
        </el-form-item>
        <el-form-item label="审批人">
          <div class="unableChange">{{ approvedBy }}</div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import http from "../../../../utils/http";
export default {
  data() {
    return {
      formLabelAlign: {}, //提交的表单
      applyList: [], //申请列表
      approvedBy: "", //审批人
      frequency: "0", //剩余补卡次数
      replacementSetting: {}, //考勤规则
      submit: false, //是否可以再提交
      rules: {
        ids: [
          {
            type: "array",
            required: true,
            message: "请选择至少一个用户",
            trigger: "blur",
          },
        ],
        date: [
          {
            type: "date",
            required: true,
            message: "请选择补卡日期",
            trigger: "change",
          },
        ],
        time: [
          {
            type: "date",
            required: true,
            message: "请选择补卡时间",
            trigger: "change",
          },
        ],
        typeRecord: [
          {
            required: true,
            message: "请选择补卡类型",
            trigger: "change",
          },
        ],
        description: [
          { required: true, message: "请输入补卡事由", trigger: "blur" },
        ],
      },
      replacementType: [
        {
          label: "旷工",
          value: 0,
        },
        {
          label: "迟到",
          value: 1,
        },
        {
          label: "早退",
          value: 2,
        },
        {
          label: "其他",
          value: 3,
        },
      ],
    };
  },
  props: ["name", "id", "groupId"],
  created() {
    this.formLabelAlign.ids = this.id;

    var user = localStorage.getItem("userinfo");
    user = JSON.parse(user);
    this.formLabelAlign.userExec = user.id;
    this.approvedBy = user.name;
  },
  methods: {
    // 关闭弹窗
    close() {
      this.$refs.batch.resetFields();
      this.$emit("close");
    },

    // 补卡判断限制
    dateLimit(e) {
      this.frequency = "0";
      this.replacementSetting = {};
      this.getAttendanceRule();

      var date = this.formatDate(e, 1);

      this.getApply(date);
    },

    // 获取申请信息
    getApply(date) {
      this.submit = true;
      http
        .get("/bmsApply/list", {
          statusList: "0,1",
          beginDay: date,
          endDay: date,
          type: 1,
          user: this.id[0],
        })
        .then((res) => {
          this.submit = false;
          if (res.code == 200) {
            res.data.list.map((item) => {
              var dateTime = new Date(item.timeRecord);
              var hours = dateTime.getHours(); // 获取小时
              var minutes = dateTime.getMinutes(); // 获取分钟
              if (hours < 10) {
                hours = "0" + hours;
              }
              if (minutes < 10) {
                minutes = "0" + minutes;
              }
              item.timeHours = hours + ":" + minutes + ":00";
            });

            this.applyList = res.data.list;
          }
        });
    },
    // 获取考勤规则
    getAttendanceRule() {
      if (this.groupId) {
        http
          .get("/bmsAttendanceGroup/get", {
            id: this.groupId,
          })
          .then((res) => {
            if (res.code == 200) {
              if (!this.isEmptyObject(res.data)) {
                this.remainingTimes(res.data);
              } else {
                this.frequency = "0";
                this.replacementSetting = {};
              }
            }
          });
      }
    },
    // 用考勤组规则来判断剩余补卡次数
    remainingTimes(object) {
      // 考勤组规则
      var group = object.attendanceGroupConfigData;
      if (group.applyRecordConfigData) {
        var replacementSetting = group.applyRecordConfigData;
        // 判断是否开启了补卡申请
        if (!this.isEmptyObject(replacementSetting)) {
          this.replacementSetting = replacementSetting;
          if (replacementSetting.monthMax) {
            this.checkSurplus(replacementSetting.monthMax);
          } else {
            this.frequency = "无限制";
            this.replacementSetting = replacementSetting;
          }
        } else {
          this.frequency = "0";
          this.replacementSetting = {};
        }
      } else {
        this.frequency = "0";
        this.replacementSetting = {};
      }
    },
    checkSurplus(num) {
      var date = this.formatDate(this.formLabelAlign.date, 1);
      http
        .get("/bmsAttendanceRecord/get/record_count", {
          dayString: date,
        })
        .then((res) => {
          if (res.code == 200) {
            var newNum = parseInt(num) - res.data;
            this.frequency = newNum;
          }
        });
    },
    // 判断是否为空对象
    isEmptyObject(obj) {
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    },
    // 确认弹窗
    switcOperation() {
      if (this.submit) {
        return;
      }
      this.$refs.batch.validate((valid) => {
        if (valid) {
          this.submit = true;
          var value = JSON.stringify(this.formLabelAlign);
          value = JSON.parse(value);
          value.date = this.formatDate(value.date, 1);
          value.time = this.formatDate(value.time, 2);
          value.timeRecord = value.date + " " + value.time;

          if (this.isEmptyObject(this.replacementSetting)) {
            this.$message.error("不允许补卡");
            this.submit = false;
            return;
          }

          // 判断是否能补卡
          if (value.typeRecord == 0) {
            if (!this.replacementSetting.permitAbsence) {
              this.$message.error("不允许补旷工");
              this.submit = false;
              return;
            }
          } else if (value.typeRecord == 1) {
            if (!this.replacementSetting.permitLate) {
              this.$message.error("不允许补迟到");
              this.submit = false;
              return;
            }
          } else if (value.typeRecord == 2) {
            if (!this.replacementSetting.permitEarly) {
              this.$message.error("不允许补早退");
              this.submit = false;
              return;
            }
          } else {
            if (!this.replacementSetting.permitOther) {
              this.$message.error("不允许补其它");
              this.submit = false;
              return;
            }
          }

          var currenObject = this.applyList.find(
            (item) => item.timeHours == value.time && value.status == 0
          );

          if (currenObject) {
            this.$message.error("该时间段已有相同的申请,请重新选择");
            this.submit = false;
            return;
          }

          delete value.date;
          delete value.time;

          value.type = 1;
          value.status = 1;
          value.timeExec = this.getCurrentDate();

          http.post("/bmsApply/add", value).then((res) => {
            this.submit = false;
            if (res.code == 200) {
              this.$message.success("登记成功");
              this.close();
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          this.submit = false;
          return false;
        }
      });
    },
    // 获取当前时间
    getCurrentDate() {
      var now = new Date();

      // 获取年份
      var year = now.getFullYear();

      // 获取月份（注意：月份是从0开始的，所以我们需要加1）
      var month = now.getMonth() + 1;
      month = month < 10 ? "0" + month : month; // 如果月份是一位数，前面补零

      // 获取日期
      var date = now.getDate();
      date = date < 10 ? "0" + date : date; // 如果日期是一位数，前面补零

      // 获取小时
      var hours = now.getHours();
      hours = hours < 10 ? "0" + hours : hours; // 如果小时是一位数，前面补零

      // 获取分钟
      var minutes = now.getMinutes();
      minutes = minutes < 10 ? "0" + minutes : minutes; // 如果分钟是一位数，前面补零
      // 秒
      var seconds = now.getSeconds();
      seconds = seconds < 10 ? "0" + seconds : seconds;

      // 将获取到的年月日时分拼接成字符串
      var currentDateTime =
        year +
        "-" +
        month +
        "-" +
        date +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds;
      return currentDateTime;
    },

    // 标准时间转换成yyyy-mm-dd
    formatDate(dateString, type) {
      // 创建一个Date对象
      var date = new Date(dateString);

      // 提取年、月和日
      var year = date.getFullYear();
      var month = ("0" + (date.getMonth() + 1)).slice(-2); // 月份从0开始，所以要加1，并确保两位数格式
      var day = ("0" + date.getDate()).slice(-2); // 确保两位数格式

      // 提取小时、分钟和秒
      var hours = ("0" + date.getHours()).slice(-2); // 确保两位数格式
      var minutes = ("0" + date.getMinutes()).slice(-2); // 确保两位数格式
      var seconds = ("0" + date.getSeconds()).slice(-2); // 确保两位数格式

      if (type == 1) {
        return year + "-" + month + "-" + day;
      } else {
        return hours + ":" + minutes + ":00";
      }
    },
  },
};
</script>

<style scoped>
.branch_l {
  display: flex;
  align-items: center;
}
.batch_t {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
  box-sizing: border-box;
  padding-left: 20px;
  font-size: 18px;
  color: #1a1a1a;
  border-bottom: 1px solid #cfd4e8;
}
.batch_close {
  width: 24px;
  cursor: pointer;
  height: 24px;
  margin-right: 20px;
}
.batch_c {
  padding: 0 30px;
  box-sizing: border-box;
  margin-top: 40px;
  overflow-y: scroll;
}
.batch_c::-webkit-scrollbar {
  display: none;
}
.department_search_inp {
  width: 100%;
  border: 1px solid #dcdfe6;
}
::v-deep .depa .el-select-dropdown {
  top: 330px !important;
}
::v-deep .batch_c .el-autocomplete {
  width: 100%;
}
.unableChange {
  width: 413px;
  height: 40px;
  border: 1px solid #d0d0d0;
  padding-left: 17px;
  box-sizing: border-box;
  line-height: 40px;
  font-size: 16px;
  color: #1a1a1a;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.surplus {
  color: rgba(26, 26, 26, 0.6);
  box-sizing: border-box;
  font-size: 14px;
}
</style>
