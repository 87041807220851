<template>
  <div>
    <div class="batch_t">
      <div class="branch_l">
        <img
          src="@/assets/structure/close.png"
          class="batch_close"
          @click="close"
        />
        <div>复制排班至</div>
      </div>
      <div class="branch_bto">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="switcOperation">确认</el-button>
      </div>
    </div>

    <div class="batch_c">
      <el-checkbox v-model="selectAll" class="checked_all">全选</el-checkbox>
      <el-checkbox-group v-model="checkList" class="checked_box">
        <el-checkbox :label="item.id" v-for="item in people" :key="item.id">{{
          item.name
        }}</el-checkbox>
      </el-checkbox-group>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      checkList: [], //提交的表单
      selectAll: false, //全选表单
      rules: {
        departmentName: [
          { required: true, message: "请输入部门名称", trigger: "blur" },
        ],
      },
      customerList: [],
      selectTreeName: "",
      selectTree: "",
    };
  },
  // type:1是添加，2是添加子级，3是编辑
  props: ["people"],

  watch: {
    selectAll(newVal) {
      if (newVal) {
        this.people.map((item) => {
          this.checkList.push(item.id);
        });
        const uniqueArr = Array.from(new Set(this.checkList));
        this.checkList = uniqueArr;
      } else {
        if (this.checkList.length == this.people.length) {
          this.checkList = [];
        }
      }
    },
    checkList(val) {
      console.log(val);
      if (val) {
        if (val.length == this.people.length) {
          this.selectAll = true;
        } else {
          this.selectAll = false;
        }
      }
    },
  },

  methods: {
    // 关闭弹窗
    close() {
      this.$emit("close");
    },

    // 确认弹窗
    switcOperation() {
      this.$emit("comfin", this.checkList);
    },
  },
};
</script>

<style scoped>
.branch_l {
  display: flex;
  align-items: center;
}
.batch_t {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
  box-sizing: border-box;
  padding-left: 20px;
  font-size: 18px;
  color: #1a1a1a;
  border-bottom: 1px solid #cfd4e8;
}
.batch_close {
  width: 24px;
  cursor: pointer;
  height: 24px;
  margin-right: 20px;
}
.batch_c {
  padding: 0 30px;
  box-sizing: border-box;
  margin-top: 40px;
}
.department_search_inp {
  width: 100%;
  border: 1px solid #dcdfe6;
}
::v-deep .depa .el-select-dropdown {
  top: 330px !important;
}
::v-deep .batch_c .el-autocomplete {
  width: 100%;
}
.checked_box {
  display: flex;
  flex-direction: column;
}
::v-deep .checked_box .el-checkbox {
  margin-bottom: 18px;
}
::v-deep .checked_box .el-checkbox__inner {
  width: 20px;
  height: 20px;
}
::v-deep .checked_box .el-checkbox__label {
  font-size: 18px;
  color: #1a1a1a;
  font-weight: normal;
}
::v-deep .checked_box .el-checkbox__inner::after {
  width: 4px;
  height: 11px;
  left: 6px;
}
::v-deep .checked_box .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #1a1a1a;
}
.checked_all {
  margin-bottom: 20px;
}
::v-deep .checked_all .el-checkbox__inner {
  width: 20px;
  height: 20px;
}
::v-deep .checked_all .el-checkbox__label {
  font-size: 18px;
  color: #1a1a1a;
  font-weight: normal;
}
::v-deep .checked_all .el-checkbox__inner::after {
  width: 4px;
  height: 11px;
  left: 6px;
}
</style>
