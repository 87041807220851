<template>
  <div class="checkin">
    <div class="checkin_title">考勤管理 / <span>登记补卡</span></div>
    <div class="checkin_information">
      <div class="member">
        <div :class="[sidebarShow ? 'member_l_zoom' : 'member_l']">
          <img
            v-if="sidebarShowIcon"
            src="@/assets/structure/zoom.png"
            class="membel_zoom"
            @click="translation"
          />
          <img
            v-else
            src="@/assets/structure/zoomr.png"
            class="membel_zoom_r"
            @click="translation"
          />
          <div v-show="sidebarShoworder" class="member_l_meum">
            <div class="search">
              <div class="search_input">
                <div class="member_search">
                  <el-select
                    ref="clearValue"
                    v-model="searchValue"
                    filterable
                    remote
                    :loading="loading"
                    @change="clickResult"
                    class="department_search_inp"
                    style="width: 200px"
                    placeholder="请搜索部门"
                    :remote-method="remoteMethod"
                  >
                    <el-option
                      v-for="item in searchData"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                  <!-- <el-input
                  :fetch-suggestions="querySearchAsync"
                  v-model="searchValue"
                  placeholder="请输入姓名、身份证号、手…"
                ></el-input> -->
                  <img :src="searchicon" class="searchicon" @click="clearup" />
                </div>
              </div>
            </div>
            <div v-if="normalxi">
              <!-- <div
                :class="[
                  'member_company',
                  ischangec == companyId ? 'aselect' : '',
                ]"
                @click="clickCompany"
              >
                <span>{{ companyName }}</span>
                <el-popover
                  :key="0"
                  ref="popoverRef_999"
                  placement="bottom-end"
                  width="144"
                  trigger="manual"
                  v-model="businessClick"
                >
                  <div>
                    <div
                      v-for="item in businessOptions"
                      :key="item.label"
                      @click.stop="businessXin(item.value)"
                      class="deptoption"
                    >
                      {{ item.label }}
                    </div>
                  </div>
                  <img
                    :src="ischangec == companyId ? multi : multiblack"
                    class="multi"
                    slot="reference"
                    @click.stop="businessclickEvent()"
                  />
                </el-popover>
              </div> -->
              <div class="tree_case">
                <el-tree
                  ref="elTree"
                  v-if="refreshTree"
                  :node-key="treeKey"
                  :data="departmentList"
                  :default-expand-all="isExpand"
                  :expand-on-click-node="false"
                  :props="defaultProps"
                  @node-click="handleNodeClick"
                >
                  <span class="custom-tree-node" slot-scope="{ node }">
                    <div class="tree_sinle">
                      <div class="tree_sinle_a">
                        <img
                          src="@/assets/structure/plan.png"
                          class="tree_sinle_icon"
                        />
                        <div
                          :class="[
                            'tree_sinle_l',
                            ischangec == node.data.id ? 'selectColor' : '',
                          ]"
                        >
                          {{ node.label }}
                        </div>
                      </div>
                      <span>
                        <el-popover
                          :key="node.id"
                          :ref="'popoverRef_' + node.id"
                          placement="bottom-end"
                          width="144"
                          trigger="manual"
                          v-model="node.isclick"
                        >
                          <div>
                            <div
                              v-for="item in options"
                              :key="item.label"
                              @click.stop="depamentshiftOperation(item.value)"
                              class="deptoption"
                            >
                              {{ item.label }}
                            </div>
                          </div>
                          <!-- <img
                            :src="
                              ischangec == node.data.id ? multi : multiblack
                            "
                            class="tree_multi"
                            slot="reference"
                            @click.stop="clickEvent(node)"
                          /> -->
                        </el-popover>
                      </span>
                    </div>
                  </span>
                </el-tree>
              </div>
            </div>
            <!-- 搜索结果 -->
            <div v-else>
              <div :class="['member_company', 'aselect']">
                <span>{{ searchfruit.name }}</span>
                <el-popover
                  :key="0"
                  ref="popoverRef_998"
                  placement="bottom-end"
                  width="144"
                  trigger="manual"
                  v-model="resultClick"
                >
                  <div>
                    <div
                      v-for="item in resultOption"
                      :key="item.label"
                      @click.stop="depamentshiftOperation(item.value)"
                      class="deptoption"
                    >
                      {{ item.label }}
                    </div>
                  </div>
                  <!-- <img
                    :src="multi"
                    class="multi"
                    slot="reference"
                    @click.stop="resultclickEvent(searchfruit)"
                  /> -->
                </el-popover>
              </div>
            </div>
          </div>
        </div>

        <!-- 右侧员工列表 -->
        <div class="member_r">
          <div class="member_r_t">
            <div class="member_r_t_nmae">
              <div
                :class="[sidebarShowIcon ? '' : 'left', 'approval_search']"
                tabindex="1"
              >
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="请输入姓名、员工工号、设备工号、手机号"
                  placement="top-start"
                >
                  <el-input
                    v-model="searchMember"
                    @keyup.enter.native="seachmemResult"
                    class="department_search_inp"
                    placeholder="姓名、员工工号..."
                    style="width: 200px"
                  >
                  </el-input>
                </el-tooltip>
                <img
                  src="@/assets/structure/delete.png"
                  class="searchicon"
                  @click="clearupMember"
                  v-if="searchMemicon"
                />
                <img
                  src="@/assets/structure/search.png"
                  class="searchicon_t"
                  @click="seachmemResult"
                />
              </div>
            </div>
            <!-- <div class="register_header_bto" @click="Batchoperations">
              登记补卡
            </div> -->
          </div>
          <div class="memeber_table_all">
            <div class="memeber_table" ref="memeberTable">
              <el-table
                height="100%"
                ref="multipleTable"
                :data="tableData"
                :header-cell-style="getRowClass"
                tooltip-effect="dark"
                class="m_table"
              >
                <template slot="empty">
                  <div>
                    <img src="@/assets/nodata.png" class="nodata" />
                    <div>无数据</div>
                  </div>
                </template>
                <el-table-column label="员工工号" width="180">
                  <template slot-scope="scope">{{
                    scope.row.jobNumber
                  }}</template>
                </el-table-column>
                <el-table-column prop="name" label="姓名" width="200">
                  <template slot-scope="scope">
                    <div class="member_name">
                      <img
                        :src="scope.row.headUrl"
                        class="member_head"
                        v-if="scope.row.headUrl"
                      />
                      <img src="@/assets/head.png" v-else class="member_head" />
                      <div>{{ scope.row.name }}</div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="deviceNumber"
                  label="设备工号"
                  width="200"
                >
                </el-table-column>
                <el-table-column prop="departmentName" label="部门" width="170">
                  <template slot-scope="scope">
                    <div v-if="scope.row.umsDepartment" class="ellipsis">
                      {{ scope.row.umsDepartment.name }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="departmentName"
                  label="生物值特征"
                  width="200"
                >
                  <template slot-scope="scope">
                    <div class="structure_feature">
                      <img
                        src="@/assets/structure/face.png"
                        class="feature"
                        v-if="scope.row.dataFace"
                      />
                      <img
                        src="@/assets/structure/fingerprint.png"
                        class="feature"
                        v-if="
                          scope.row.dataFinger && !isNull(scope.row.dataFinger)
                        "
                      />
                      <img
                        src="@/assets/structure/vein.png"
                        class="feature"
                        v-if="scope.row.dataHand"
                      />
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-link
                      type="primary"
                      @click="
                        openOvertime(
                          scope.row.id,
                          scope.row.name,
                          scope.row.attendanceGroup
                        )
                      "
                      >登记补卡</el-link
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>

          <div class="newpagination" v-if="pages.total > 0">
            <div class="newpagination_text">共 {{ pages.total }} 条数据</div>
            <el-pagination
              layout="prev, pager, next"
              :total="pages.total"
              :current-page="pages.pageNum"
              :page-size="50"
              @current-change="chagePage"
            >
            </el-pagination>
          </div>
        </div>

        <!-- 新增子部门或者编辑部门 -->
        <el-drawer
          :wrapperClosable="false"
          title=""
          :key="selectDivision"
          :visible.sync="addBranch"
          :with-header="false"
          size="25%"
        >
          <Branch
            @close="closeEdit"
            @comfin="Successfully"
            :id="selectDivision"
            :type="branchType"
          />
        </el-drawer>

        <!-- 删除部门 -->
        <showDialog
          :title="'确认删除部门'"
          :tips="tips"
          :isShow="deleteButton"
          @closeDiglog="canceldelete"
          @affirmDiglog="delectdept"
        />

        <!-- 删除部门提示 -->
        <el-dialog
          title=""
          :visible.sync="errorTips"
          width="33%"
          :show-close="false"
          :close-on-click-modal="false"
        >
          <div class="diglog_h">
            <div>确认删除部门</div>
            <img
              src="@/assets/structure/close.png"
              class="diglog_icon"
              @click="errorTips = false"
            />
          </div>
          <div class="delectdiglog">
            <div class="delectdiglog_title">{{ tips }}</div>
          </div>

          <div class="diglog_operation">
            <el-button type="primary" @click="errorTips = false"
              >确认</el-button
            >
          </div>
        </el-dialog>

        <!-- 补卡弹窗 -->
        <el-drawer
          :wrapperClosable="false"
          title=""
          :key="overtimeKey"
          :visible.sync="overtimeDialog"
          :with-header="false"
          size="25%"
        >
          <Replacement
            @close="closeOvertime"
            :name="selectEmployeesName"
            :id="selectEmployees"
            :groupId="selectEmployeesGroup"
          />
        </el-drawer>
      </div>
    </div>
    <div class="filing">
      CopyRight@2023广州巨源信息技术有限公司-<a
        href="https://beian.miit.gov.cn/"
        >粤ICP备2023127373号-4</a
      >
    </div>
  </div>
</template>

<script>
import Diglog from "../../../assembly/dialog.vue";
import Replacement from "./dialog/replacement.vue";

// 部门
import Branch from "../../structure/module/branch/newbranch.vue";
import showDialog from "../../../assembly/dialog.vue";

import http from "../../../utils/http";

import searchIcon from "../../../assets/structure/search.png";
import deleteIcon from "../../../assets/structure/delete.png";

import table2excel from "js-table2excel";

import multiblack from "../../../assets/structure/multiblack.png";
import multi from "../../../assets/structure/multi.png";
export default {
  data() {
    return {
      screenKey: Math.random(),
      overtimeKey: Math.random(),
      searchValue: "", //搜索内容
      searchMember: "", //搜索内容
      searchMemicon: false, //删除图片是否显示(员工)
      searchicon: searchIcon, //搜索图标
      sidebarShow: true, //显示隐藏
      sidebarShoworder: true, //延迟1秒隐藏
      sidebarShowIcon: true, //图标显示时间
      multiblack,
      multi,

      overtimeDialog: false, //加班弹窗
      selectEmployees: [], //所选员工id
      selectEmployeesGroup: null, //所选员工考勤组id
      selectEmployeesName: [], //所选员工姓名

      multipleSelection: [], //多选操作
      multipleSelectionCopy: [], //多选操作，不做编译
      refsName: 999, //ref控制显示隐藏

      companyName: "", //企业名称
      companyId: null, //企业id
      departmentList: [], //部门列表
      firstDept: [], //第一个部门
      departmentListCopy: [], //复制的部门列表
      isExpand: false, //是否默认展开全部节点
      refreshTree: true, //动态渲染
      ischangec: "", //公司样式
      treeKey: "0",
      defaultProps: {
        children: "subDepartment",
        label: "name",
      },

      filtrateResult: {}, //筛选字段
      filtrateNum: 0, //筛选数量

      // 部门操作
      businessClick: false, //企业选择
      resultClick: false, //搜索部门选择
      selectDivision: -1, //选择的部门
      value: 1,
      tableData: [],
      pages: {}, //页码
      searchData: [], //搜索列表
      normalxi: true, //是否正常显示树状图
      searchfruit: {}, //搜索结果
      loading: false, //搜索等待
      addBranch: false, //添加子部门或编辑
      branchType: 1, //1是添加，2是子级，3是编辑
      tips: "", //提示
      deleteButton: false, //删除部门
      errorTips: false, //错误提示
      businessOptions: [
        {
          label: "添加子部门",
          value: 2,
        },
      ],
      options: [
        {
          label: "编辑部门",
          value: 1,
        },
        {
          label: "添加子部门",
          value: 2,
        },
        {
          label: "删除",
          value: 3,
        },
      ],

      resultOption: [
        {
          label: "编辑部门",
          value: 1,
        },
        {
          label: "添加子部门",
          value: 2,
        },
        {
          label: "删除",
          value: 5,
        },
      ],
    };
  },

  components: {
    Diglog,
    Branch,
    showDialog,
    Replacement,
  },

  watch: {
    searchValue(newName, oldName) {
      if (newName) {
        this.searchicon = deleteIcon;
      } else {
        this.searchicon = searchIcon;
      }
    },

    searchMember(newName, oldName) {
      if (newName) {
        this.searchMemicon = true;
      } else {
        this.searchMemicon = false;

        // 获取员工列表
        this.getUser({
          pageNum: 1,
        });
      }
    },
  },

  created() {
    //  获取部门列表
    this.getDeptment();
  },

  // 点击任何地方取消下拉
  updated() {
    var that = this;
    that.$nextTick(() => {
      document.onclick = function () {
        if (that.$refs[`popoverRef_${that.refsName}`]) {
          that.$refs[`popoverRef_${that.refsName}`].doClose();
        }
      };
    });
  },

  methods: {
    // 平移动画
    translation() {
      this.sidebarShow = !this.sidebarShow;
      setTimeout(() => {
        this.sidebarShoworder = !this.sidebarShoworder;
      }, 100);

      // 图标变化
      setTimeout(() => {
        this.sidebarShowIcon = !this.sidebarShowIcon;
      }, 300);
    },

    // 表头背景颜色变色
    getRowClass({ rowIndex, columnIndex }) {
      if (rowIndex == 0) {
        return "background:#F5F6FA;color:#5C5C5C";
      }
    },

    // 多选操作
    handleSelectionChange(val) {
      var arr = []; //员工列表
      var arr2 = []; //员工id列表
      val.map((item) => {
        arr.push(item.name);
        arr2.push(item.id);
      });
      this.multipleSelection = JSON.stringify(val);
      this.multipleSelectionCopy = val;

      this.selectEmployeesName = arr;
      this.selectEmployees = arr2;
    },

    // ----------------------------员工---------------------------

    // 获取员工列表
    getUser(value) {
      var object = {};
      if (this.selectDivision == this.firstDept.id) {
        object = {};
      } else {
        object = {
          department: this.selectDivision,
        };
      }
      http
        .get(
          "/umsUser/list",
          Object.assign(
            {
              pageSize: 50,
              typeList: "2,3,4",
            },
            object,
            value
          )
        )
        .then((res) => {
          if (res.code == 200) {
            this.pages = res.data;
            this.tableData = res.data.list;
          }
        });
    },

    // 点击公司
    clickCompany() {
      var value = {};
      this.ischangec = this.companyId;
      this.selectDivision = Math.random() * -1;

      this.publicFiltering();
    },

    // 页数改变
    chagePage(e) {
      var value = {};
      if (Object.keys(this.filtrateResult).length === 0) {
        value = {
          pageNum: e,
          content: this.searchMember,
        };
      } else {
        value = Object.assign(this.filtrateResult, {
          pageNum: e,
        });
      }
      this.getUser(value);
    },

    // 筛选员工(姓名，身份证，工号筛选)
    seachmemResult() {
      // 清空筛选框
      this.filtrateResult = {};
      this.filtrateNum = 0;

      this.getUser({
        pageNum: 1,
        likeString: this.searchMember,
      });
    },

    // 清除输入框
    clearupMember() {
      this.searchMember = "";
    },

    // 打开加班弹窗
    openOvertime(id, name, group) {
      this.selectEmployees = [id];
      this.selectEmployeesName = [name];
      this.selectEmployeesGroup = group;
      this.overtimeKey = Math.random();
      setTimeout(() => {
        this.overtimeDialog = true;
      }, 200);
    },

    // 关闭加班
    closeOvertime() {
      this.overtimeDialog = false;
    },

    // 批量操作
    Batchoperations() {
      if (this.multipleSelectionCopy.length == 0) {
        this.$message.error("请先选择人员");
        return;
      }
      this.overtimeKey = Math.random();
      setTimeout(() => {
        this.overtimeDialog = true;
      }, 200);
    },

    // --------------------部门操作----------------------

    // 获取部门列表
    getDeptment() {
      http.get("/umsDepartment/list").then((res) => {
        if (res.code == 200) {
          // this.companyName = res.data.departmentName;
          // this.ischangec = res.data.departmentName;

          if (res.data.length > 0) {
            this.departmentList = this.convertToNestedStructure(res.data);
            var object = JSON.stringify(res.data);
            this.departmentListCopy = this.convertToNestedStructure(
              JSON.parse(object)
            );
            this.firstDept = res.data[0];

            if (this.departmentList) {
              this.ischangec = res.data[0].id;
              this.companyId = res.data[0].id;
              this.selectDivision = res.data[0].id;

              // 获取员工列表
              this.getUser({
                pageNum: 1,
              });
            }

            localStorage.setItem(
              "department",
              JSON.stringify(this.departmentList)
            );
          }
        }
      });
    },

    // 递归处理数据
    convertToNestedStructure(arr) {
      // 创建一个映射，用于快速查找具有特定id的对象
      const idMap = new Map(arr.map((item) => [item.id, item]));

      // 创建一个新数组来存储转换后的嵌套结构
      const nestedArr = [];

      // 遍历原始数组
      arr.forEach((item) => {
        // 如果该项有父元素，则将其添加到父元素的subDepartment数组中
        if (item.parent) {
          const parent = idMap.get(item.parent);
        if (parent) {
            if (!parent.subDepartment) {
              parent.subDepartment = [];
            }
            item.parentDepament = parent.name;
            parent.subDepartment.push(item);
          }
        } else {
          // 如果没有父元素，则添加到新数组的根级别
          nestedArr.push(item);
        }
      });

      // 返回转换后的嵌套数组
      return nestedArr;
    },
    // 点击部门
    handleNodeClick(data) {
      var value = {};
      this.ischangec = data.id;
      this.selectDivision = data.id;
      // 如果含有子部门,直接展开
      this.publicFiltering();
    },
    // 部门搜索
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        http
          .get("/umsDepartment/search", {
            departmentName: query,
          })
          .then((res) => {
            if (res.code == 200) {
              this.loading = false;
              this.searchData = res.data;
            }
          });
      } else {
        this.searchData = [];
      }
    },
    // 点击部门搜索结果
    clickResult(id) {
      var that = this;
      this.selectDivision = id;
      // 如果含有子部门,直接展开
      this.publicFiltering();

      http
        .get("/umsDepartment/get", {
          id: id,
        })
        .then((res) => {
          if (res.code == 200) {
            that.searchfruit = res.data;
            that.normalxi = false;
          }
        });
    },
    // 递归筛选
    searchNestedObject(array, targetValue) {
      for (let i = 0; i < array.length; i++) {
        const item = array[i];
        if (item.id === targetValue) {
          // 如果找到目标值，返回当前对象
          return item;
        }
        if (Array.isArray(item.subDepartment)) {
          // 如果对象有子数组，递归搜索子数组
          const result = this.searchNestedObject(
            item.subDepartment,
            targetValue
          );
          if (result) {
            // 如果在子数组中找到目标值，返回整个路径
            return {
              ...item,
              subDepartment: [result], // 只包含找到目标值的子路径
            };
          }
        }
      }
      return null; // 如果没有找到目标值，返回null
    },
    // 清除输入框
    clearup() {
      this.searchValue = "";
      this.selectDivision = Math.random() * -1;
      var value = "";
      this.normalxi = true;

      this.getDeptment();

      if (Object.keys(this.filtrateResult).length === 0) {
        value = {
          pageNum: 1,
          content: this.searchMember,
        };
      } else {
        value = Object.assign(this.filtrateResult, {
          pageNum: 1,
        });
      }

      this.getUser(value);

      this.refreshTree = false;
      this.isExpand = false;
      this.$nextTick(() => {
        this.refreshTree = true;
      });
    },
    // 点击操作方法(企业)
    businessclickEvent() {
      if (this.$refs[`popoverRef_${this.refsName}`]) {
        this.$refs[`popoverRef_${this.refsName}`].doClose();
      }
      this.businessClick = !this.businessClick;
      this.refsName = 999;
      this.selectDivision = Math.random() * -1;
    },
    // 部门操作(企业)
    businessXin() {
      this.businessClick = false;
      this.branchType = 2;
      setTimeout(() => {
        this.addBranch = true;
      }, 200);
    },
    // 点击操作方法
    clickEvent(item) {
      if (this.$refs[`popoverRef_${this.refsName}`]) {
        this.$refs[`popoverRef_${this.refsName}`].doClose();
      }
      item.isclick = !item.isclick;
      this.refsName = item.id;
      this.selectDivision = item.data.id;
    },
    // 点击操作方法(搜索结果)
    resultclickEvent(item) {
      if (this.$refs[`popoverRef_${this.refsName}`]) {
        this.$refs[`popoverRef_${this.refsName}`].doClose();
      }
      this.resultClick = !this.resultClick;
      this.refsName = 998;
      this.selectDivision = item.id;
    },
    // 部门操作
    depamentshiftOperation(value) {
      // const addKey = (arr) =>
      //   arr.map((item) => ({
      //     ...item,

      //     isclick: false, //key值为当前项id

      //     subDepartment: item.subDepartment ? addKey(item.subDepartment) : [], // 这里要判断原数据有没有子级如果没有判断会报错
      //   }));

      // this.departmentList = addKey(this.departmentList);

      this.$refs[`popoverRef_${this.refsName}`].doClose();

      switch (value) {
        case 1:
          // 编辑部门
          this.branchType = 3;
          setTimeout(() => {
            this.addBranch = true;
          }, 200);
          break;
        case 2:
          // 添加子部门
          this.branchType = 2;
          setTimeout(() => {
            this.addBranch = true;
          }, 200);
          break;
        case 3:
          // 删除
          this.deletecheckout();
          break;
      }
    },
    // 取消编辑或子级部门操作
    closeEdit() {
      this.selectDivision = -1;
      this.addBranch = false;
    },
    // 成功编辑或添加子级
    Successfully() {
      this.selectDivision = -1;

      this.addBranch = false;
      // 重新获取部门列表
      this.getDeptment();
    },
    // 删除部门(检验)
    deletecheckout() {
      http
        .post("/umsDepartment/delete", {
          ids: [this.selectDivision],
          operation: 1,
        })
        .then((res) => {
          if (res.code == 200) {
            this.tips = "部门删除后不可恢复，是否继续？";
            this.deleteButton = true;
          } else {
            this.tips = res.message;
            this.errorTips = true;
          }
        });
    },

    // 取消删除部门
    canceldelete() {
      this.selectDivision = -1;
      this.tips = "";
      this.deleteButton = false;
    },

    // 确认删除部门
    delectdept() {
      http
        .post("/umsDepartment/delete", {
          ids: [this.selectDivision],
          operation: 2,
        })
        .then((res) => {
          if (res.code == 200) {
            // 取消弹窗
            this.canceldelete();

            this.normalxi = true;

            // 获取员工列表
            this.publicFiltering();

            // 重新获取部门列表
            this.getDeptment();
            this.$message.success("删除成功");
          } else {
            this.$message.error(res.message);
          }
        });
    },

    // -------------------------------------------------

    // 公用筛选
    publicFiltering() {
      var that = this;
      var value = {};
      if (Object.keys(that.filtrateResult).length === 0) {
        value = {
          pageNum: 1,
          content: that.searchMember,
        };
      } else {
        value = Object.assign(that.filtrateResult, {
          pageNum: 1,
        });
      }

      that.getUser(value);
    },
    // 判断是否都是null
    isNull(value) {
      var arr = value.split(",");
      const areAllEmptyOrNullStrings = arr.every(
        (item) => item === "" || item === "null"
      );
      return areAllEmptyOrNullStrings;
    },
  },
};
</script>

<style scoped>
.checkin {
  width: 100%;
  height: 100%;
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: #f4f7fc;
}
.checkin_title {
  font-size: 14px;
  color: #b2b2b2;
  margin-bottom: 16px;
}
.checkin_title span {
  color: #5c5c5c;
}
.checkin_information {
  width: 100%;
  overflow-y: scroll;
  flex: 1;
  background: white;
  border-radius: 4px;
  box-shadow: 0px 1px 4px 1px rgba(21, 34, 50, 0.08);
  border-radius: 6px 6px 6px 6px;
  box-sizing: border-box;
}
.checkin_information::-webkit-scrollbar {
  display: none;
}
.member {
  max-width: 100%;
  height: 100%;
  overflow-x: hidden;
  display: flex;
}
.member_l {
  width: 0;
  height: 100%;
  position: relative;
  transition: all 0.5s ease 0ms;
}
.member_l_zoom {
  width: 330px;
  position: relative;
  transition: all 0.5s ease 0ms;
  border-right: 1px solid rgba(207, 212, 232, 0.5);
}
.member_company {
  width: 100%;
  height: 48px;
  display: flex;
  color: #5c5c5c;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
}
.ischangec {
  background: rgba(242, 247, 255, 0.8);
  color: #0058ff;
}
.member_company span {
  font-size: 16px;
}
.multi {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.tree_sinle {
  width: 100%;
  display: flex;
  align-items: center;
  padding-right: 12px;
  box-sizing: border-box;
  justify-content: space-between;
}
.tree_sinle_a {
  display: flex;
  align-items: center;
}
.tree_sinle_l {
  font-size: 16px;
  max-width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #5c5c5c;
  /* color: #0058ff; */
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
.tree_sinle_icon {
  width: 18px;
  height: 18px;
  margin-right: 12px;
}
.tree_multi {
  width: 24px;
  height: 24px;
}
.deptoption {
  height: 40px;
  line-height: 40px;
  padding-left: 10px;
  box-sizing: border-box;
  color: #5c5c5c;
  cursor: pointer;
}
.deptoption:hover {
  background: #edeeee;
}
.tree_multi:hover {
  background: #e2e2e2;
  border-radius: 5px;
}
.multi:hover {
  background: #e2e2e2;
  border-radius: 5px;
}
.search {
  width: 100%;
  height: 72px;
  display: flex;
  padding-top: 22px;
  padding-left: 20px;
  box-sizing: border-box;
}
.search_input {
  width: 243px;
  height: 36px;
}
.membel_zoom {
  width: 28px;
  height: 36px;
  position: absolute;
  top: 22px;
  cursor: pointer;
  right: 0;
}
.membel_zoom_r {
  width: 28px;
  height: 36px;
  position: absolute;
  top: 20px;
  cursor: pointer;
  right: -27px;
}
.member_r {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.member_r_t {
  display: flex;
  height: 76px;
  padding: 0 22px;
  align-items: center;
  border-bottom: 1px solid #cfd4e8;
  justify-content: space-between;
  box-sizing: border-box;
}
.member_r_filter {
  display: flex;
  cursor: pointer;
  font-size: 15px;
  color: #b2b2b2;
  width: 98px;
  height: 36px;
  padding-left: 16px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #d0d0d0;
  align-items: center;
}
.member_r_filter_content {
  display: flex;
  cursor: pointer;
  font-size: 15px;
  color: #0058ff;
  width: 119px;
  height: 36px;
  padding-left: 16px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #0058ff;
  align-items: center;
}
.member_r_filter_icon {
  width: 18px;
  height: 18px;
  margin-left: 18px;
}
.memeber_table {
  width: 100%;
  height: 100%;
  position: relative;
}
.qrcode {
  width: 100%;
}
.qrcode_frame {
  width: 457px;
  height: 358px;
  display: flex;
  color: #1a1a1a;
  margin: 60px auto 24px;
  flex-direction: column;
  align-items: center;
  padding-top: 46px;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.16);
}
.qrcode_name {
  font-size: 16px;
  margin-bottom: 30px;
}
.qrcode_img {
  width: 200px;
  height: 200px;
}
.qrcode_tips {
  color: #9a9a9a;
  font-size: 15px;
  text-align: center;
}
.qrcode_bto {
  display: flex;
  margin-top: 39px;
  justify-content: center;
}
.link_frame {
  width: 797px;
  height: 132px;
  margin: 30px;
  font-size: 16px;
  padding: 24px;
  color: #1a1a1a;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.16);
}
.link_tips {
  font-size: 15px;
  color: #9a9a9a;
  padding-left: 30px;
  box-sizing: border-box;
}
.link_bto {
  display: flex;
  margin-top: 289px;
  justify-content: center;
}
.invite_h {
  height: 73px;
  padding: 0 24px;
  color: #1a1a1a;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 18px;
  justify-content: space-between;
}
.invite_icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.member_search {
  width: 243px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #d0d0d0;
  display: flex;
  align-items: center;
  padding-right: 12px;
  box-sizing: border-box;
  justify-content: space-between;
}
.memeber_operation {
  margin-left: 8px;
  width: 107px;
}
.delectdiglog {
  width: 100%;
  margin-top: 20px;
  border-top: 1px solid #cfd4e8;
}
.delectdiglog_title {
  font-size: 16px;
  color: #1a1a1a;
  padding: 30px;
  box-sizing: border-box;
}
.diglog_h {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  padding: 24px 24px 0 24px;
  color: #1a1a1a;
}
.diglog_icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.diglog_operation {
  margin: 60px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.member_name {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #1a1a1a;
}
.member_head {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 10px;
}
.left {
  margin-left: 28px;
}
::v-deep .el-table__body .el-table__row.hover-row td {
  background-color: rgba(0, 98, 255, 0.05);
}
::v-deep .el-tree-node__content:hover {
  background: #f2f7ff;
}
.filtrate {
  display: flex;
  align-items: center;
}
.filtrate_single {
  padding: 6px;
  color: #0058ff;
  font-size: 15px;
  display: flex;
  align-items: center;
  background: #e0ecff;
  margin-right: 10px;
  border-radius: 4px;
}
.filtrate_icon {
  margin-left: 6px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.aselect {
  color: #0058ff;
  background-color: rgba(0, 98, 255, 0.05);
}
.selectColor {
  color: #0058ff;
}
.tree_case {
  flex: 1;
  overflow: auto; /* 添加滚动条 */
  max-height: 600px; /* 设置最大高度 */
}
.tree_case::-webkit-scrollbar {
  display: none;
}
.approval_search {
  width: 243px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #d0d0d0;
  display: flex;
  align-items: center;
  padding-right: 12px;
  box-sizing: border-box;
  margin-right: 10px;
  justify-content: space-between;
}
.member_r_t_nmae {
  display: flex;
  align-items: center;
}
.approval_search:focus-within {
  border-color: #0096fa;
}
.member_search:focus-within {
  border-color: #0096fa;
}
.m_table {
  width: 100%;
  height: 100%;
  margin-top: 16px;
  position: absolute;
}
.register_header {
  width: 100%;
  height: 76px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 38px;
  box-sizing: border-box;
  border-bottom: 1px solid #cfd4e8;
}
.register_header_bto {
  width: 80px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  font-size: 14px;
  color: #7e84a3;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #d5d7e3;
  cursor: pointer;
}
.memeber_table_all {
  padding: 0 22px;
  flex: 1;
  box-sizing: border-box;
}
.ellipsis {
  width: 130px;
  white-space: nowrap; /* 防止文本换行 */
  overflow: hidden; /* 隐藏超出容器的文本 */
  text-overflow: ellipsis; /* 显示省略号表示文本被截断 */
}
.structure_feature {
  display: flex;
  align-items: center;
}
.feature {
  width: 30px;
  height: 30px;
  margin-right: 12px;
}
.member_l_meum {
  display: flex;
  height: 100%;
  flex-direction: column;
}
.newpagination {
  height: 50px;
  padding: 0 10px;
  box-sizing: border-box;
  margin-top: 30px;
}
</style>
