import Vue from "vue";
import App from "./App.vue";

import router from "./router";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import "./css/elementcss.css";
import "./css/index.css";

import SlideVerify from 'vue-monoplasty-slide-verify';

// import 'lib-flexible/flexible.js'

Vue.config.productionTip = false;

import "./utils/fixle";

Vue.prototype.$bus = new Vue();

import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

Vue.use(SlideVerify);

Vue.use(ElementUI);

Vue.prototype.globalClick = function (callback) {
  document.onclick = function () {
    callback();
  };
};

/* 路由发生变化修改页面title */
router.beforeEach((to, from, next) => {
  if (to.name == "addgroup") {
    var name = to.fullPath;
    var regex = /isEdit=([^&]+)/;
    var matches = name.match(regex);
    if (matches && matches.length > 1) {
      document.title = "新建考勤组";
    } else {
      document.title = "编辑考勤组";
    }
  } else {
    document.title = `${to.meta.title}`;
  }
  next();
});

new Vue({
  router,
  render: (h) => h(App),
  beforeCreate() {
    Vue.prototype.$eventBus = this; // 全局事件总线  eventBus
  },
}).$mount("#app");
