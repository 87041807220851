// 这段代码它又来了，完全可以单独丢一个js文件里面。
if (!/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
  // 当前设备是移动设备
  let width = window.innerWidth;
  width = width <= 1200 ? 1200 : width;
  const htmlObj = document.getElementsByTagName("html")[0];
  htmlObj.style.fontSize = width / 120 + "px";

  if (document.createEvent) {
    var event = document.createEvent("HTMLEvents");
    event.initEvent("resize", true, true);
    window.dispatchEvent(event);
  } else if (document.createEventObject) {
    window.fireEvent("onresize");
  }

  window.addEventListener("resize", function () {
    let width = window.innerWidth;
    htmlObj.style.fontSize = width / 120 + "px";
  });
}
