// app.vue
<template>
  <div id="app">
    <div class="header">
      <div class="main">
        <div class="drug-name">
          <span>{{ drugName }}</span>
        </div>
        <div class="drug-img">
          <img :src="drugImg" :alt="drugName" @error="imageLoadError" />
        </div>
        <div class="drug-info" v-html="drugInfo"></div>
      </div>
    </div>
  </div>
</template>
<script >
export default {
  components: {},
  created() {
    this.init();
    this.OpenPup();
    // this.imgChange();

    console.log(this.$route.query);
  },
  data() {
    return {
      drugName: "",
      drugInfo: "",
      drugImg: "",
      imgCheckCount: 0,
    };
  },
  methods: {
    init() {
      this.drugName = decodeURI(this.$route.query.drugName);
      // this.drugName=decodeURI(this.$route.query.drugName)
      console.log(this.$route.query.drugName);
      // var destr = UrlDecode(str);
      // this.imgChange()
      this.drugImg = "image/" + this.drugName + ".jpg";
    },
    imageLoadError(e) {
      console.log("onerror");
      // this.drugImg = 'image/'+this.drugName+'.JPG'
      // this.loadingOnOff = true
      this.imgCheckCount += 1;
      this.imgCheks();
      e.target.onerror = null;
    },
    imgCheks() {
      if (this.imgCheckCount === 1) {
        this.drugImg = "image/" + this.drugName + ".JPG";
      } else if (this.imgCheckCount === 2) {
        this.drugImg = "image/" + this.drugName + "1.jpg";
      } else if (this.imgCheckCount === 3) {
        this.drugImg = "image/" + this.drugName + "1.JPG";
      }
    },
    imgChange() {
      // console.log("ceshi"+this.drugName)
      // console.log("ceshi"+this.isFileExist('image/'+this.drugName+'.jpg'))
      // console.log("ceshi"+this.isFileExist('image/'+this.drugName+'1.jpg'))
      if (this.checkImg("image/" + this.drugName + ".jpg")) {
        this.drugImg = "image/" + this.drugName + ".jpg";
      } else if (this.checkImg("image/" + this.drugName + ".JPG")) {
        this.drugImg = "image/" + this.drugName + ".JPG";
      } else if (this.checkImg("image/" + this.drugName + "1.jpg")) {
        this.drugImg = "image/" + this.drugName + "1.jpg";
      } else if (this.checkImg("image/" + this.drugName + "1.JPG")) {
        this.drugImg = "image/" + this.drugName + "1.JPG";
      }
      // console.log(this.drugImg)
      // console.log(this.isFileExist('image/'+this.drugName+'.jpg'))
    },

    OpenPup() {
      let xhr = new XMLHttpRequest();
      //  let okStatus = document.location.protocol === "file:" ? 0 : 200;
      // xhr.open("GET", val.url, false); // 线上链接地址
      // let drugUrl = this.drugName+".text"
      let drugUrl = "drug/" + this.drugName + ".txt";
      xhr.open("GET", drugUrl, false); // public文件夹下的绝对路径
      xhr.overrideMimeType("txt/html;charset=utf-8");
      xhr.send(null);
      // console.log(xhr.responseText)  // xhr.responseText为文本中的内容
      this.drugInfo = xhr.responseText;
      // console.log(this.drugInfo)
    },

    // // 判断本地是否有该文件
    // showimg (url) {
    //   // 判断结果
    //   let result = false
    //   url = './' + url
    //   // 获取该目录下所有文件名
    //   const files = require.context('public/image/', false, /\.jpg$/)
    //   console.log(files.keys())
    //   const filedata = files.keys()
    //   // 判断是否有该文件
    //   filedata.forEach((item) => {
    //     if (item === url) {
    //       result = true
    //     }
    //   })
    //   // 返回结果
    //   return result
    // },

    isFileExist(filePath) {
      let xhr = new XMLHttpRequest();
      xhr.open("GET", filePath, false);
      xhr.send();
      console.log(xhr);
      return xhr.status === 200;
    },
    checkImg(imgurl) {
      var imgObj = new Image();
      imgObj.src = imgurl;
      imgObj.onload = function (res) {
        return true;
        console.log("有效链接");
      };
      imgObj.onerror = function (err) {
        return false;
        console.log("无效链接");
      };
    },
  },
};
</script>
<style lang="less">
//#app {
//  font-family: Avenir, Helvetica, Arial, sans-serif;
//  -webkit-font-smoothing: antialiased;
//  -moz-osx-font-smoothing: grayscale;
//  text-align: center;
//  color: #2c3e50;
//}

html,
body {
  width: 100%;
  height: 100%;
  overflow: scroll;
}

html::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

body {
  margin: 0;
  padding: 0;
}

#app {
  width: 100vw;
  margin: 0;
  padding: 0;
}

.header {
  /*line-height: 1.5;*/
  /*max-height: 100vh;*/
  margin: 0;
  padding: 0;
  width: 100vw;
  background-color: blanchedalmond;
}

.main {
  margin: 0 5vw;
  width: 90vw;
  min-height: 100vh;
  background-color: white;
}

.drug-name {
  margin-bottom: 10vw;
  padding-top: 10vw;
  text-align: center;
}

.drug-name span {
  /*margin: auto;*/
  /*margin-left: 50px;*/
  color: #181818;
  font-size: 32px;
  font-weight: bold;
  display: inline-block;
  margin: 0 auto;
  text-align: center;
}

.drug-img {
  text-align: center;
}

.drug-img img {
  max-width: 80vw;
  height: auto;
  width: auto;
  margin: auto;
  text-align: center;
}

.drug-info {
  margin: 5vw 5vw 0;
  padding-bottom: 5vw;
  font-size: 16px;
  color: #181818;
  line-height: 30px;
  overflow: auto;
}

.drug-info span {
  font-size: 16px;
  color: #181818;
}

h1 {
  font-size: 20px;
  text-align: left;
  margin-bottom: 10px;
}

::v-deep p {
  margin-bottom: 15px;
}

::v-deep b {
  font-size: 19px;
  font-weight: bold;
  /*margin-bottom: 15px;*/
  /*padding-bottom: 15px;*/
  text-indent: 2em;
}
</style>

