<template>
  <div class="member">
    <!-- 右侧员工列表 -->
    <div class="member_r">
      <div class="member_r_t_l">
        <div class="member_r_t_left">
          <div
            :class="[sidebarShowIcon ? '' : 'left', 'approval_search']"
            tabindex="1"
          >
            <el-tooltip
              class="item"
              effect="dark"
              content="请输入姓名、员工工号、设备工号、手机号"
              placement="top-start"
            >
              <el-input
                v-model="searchForm.likeString"
                @keyup.enter.native="seachmemResult"
                class="department_search_inp"
                placeholder="请输入姓名、员工工号…"
                style="width: 200px"
              >
              </el-input>
            </el-tooltip>
            <img
              src="@/assets/structure/delete.png"
              class="searchicon"
              @click="clearupMember"
              v-if="searchMemicon"
            />
            <img
              src="@/assets/structure/search.png"
              class="searchicon_t"
              @click="seachmemResult"
            />
          </div>
          <div class="member_r_t_dowm">
            <el-select
              v-model="searchForm.leaveType"
              @change="seachmemResult"
              placeholder="离职类型"
              clearable
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="member_r_t_right" @click="startDownload">导出报表</div>
      </div>
      <div class="memeber_table" ref="memeberTable">
        <el-table
          height="100%"
          ref="multipleTable"
          :data="tableData"
          :header-cell-style="getRowClass"
          tooltip-effect="dark"
          class="m_table"
        >
          <template slot="empty">
            <div>
              <img src="@/assets/nodata.png" class="nodata" />
              <div>无数据</div>
            </div>
          </template>
          <el-table-column prop="name" label="姓名" width="150">
            <template slot-scope="scope">
              <div class="member_name">
                <img
                  :src="scope.row.headUrl"
                  class="member_head"
                  v-if="scope.row.headUrl"
                />
                <img src="@/assets/head.png" v-else class="member_head" />
                <div>{{ scope.row.name }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="员工工号" prop="jobNumber" width="150">
          </el-table-column>
          <el-table-column label="设备工号" prop="deviceNumber" width="150">
          </el-table-column>
          <el-table-column label="部门" width="150">
            <template slot-scope="scope">
              <div v-if="scope.row.umsDepartment" class="ellipsis">
                {{ scope.row.umsDepartment.name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="job" label="职位">
            <template slot-scope="scope">
              <div v-if="scope.row.job">{{ scope.row.job }}</div>
              <div v-else>--</div>
            </template>
          </el-table-column>
          <el-table-column label="离职类型" width="150">
            <template slot-scope="scope">
              <div v-if="scope.row.leaveType == 0">自离</div>
              <div v-if="scope.row.leaveType == 1">辞退</div>
              <div v-if="scope.row.leaveType == 3">其他</div>
            </template>
          </el-table-column>
          <el-table-column prop="timeQuit" label="离职日期" width="200">
            <template slot-scope="scope">
              <div v-if="scope.row.timeQuit">
                {{ hadleQuit(scope.row.timeQuit) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="leaveReason" label="离职原因" width="200">
          </el-table-column>
          <el-table-column label="经办人" width="120">
            <template slot-scope="scope">
              <div v-if="scope.row.umsUser">
                {{ scope.row.umsUser.name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="生物值特征" width="200">
            <template slot-scope="scope">
              <div class="structure_feature">
                <img
                  src="@/assets/structure/face.png"
                  class="feature"
                  v-if="scope.row.dataFace"
                />
                <img
                  src="@/assets/structure/fingerprint.png"
                  class="feature"
                  v-if="scope.row.dataFinger"
                />
                <img
                  src="@/assets/structure/vein.png"
                  class="feature"
                  v-if="scope.row.dataHand"
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200" fixed="right">
            <template slot-scope="scope">
              <el-link type="primary" @click="reEmployesOpen(scope.row)"
                >重新入职</el-link
              >
              <el-link
                type="danger"
                style="margin-left: 24px"
                v-if="scope.row.status != 4"
                @click="delectUser(scope.row.id)"
                >删除</el-link
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="newpagination" v-if="pages.total > 0">
        <div class="newpagination_text">共 {{ pages.total }} 条数据</div>
        <el-pagination
          layout="prev, pager, next"
          :total="pages.total"
          :current-page="pages.pageNum"
          :page-size="50"
          @current-change="chagePage"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 删除员工弹窗 -->
    <Diglog
      @closeDiglog="closeDelete"
      @affirmDiglog="deleteEmployes(selectEmployees)"
      :isShow="delectUserDiglog"
      :title="'删除人员'"
      :tips="tips"
    />

    <!-- 重新入职 -->
    <Diglog
      @closeDiglog="closereEmploymen"
      @affirmDiglog="reEmployes(selectEmployees)"
      :isShow="reEmploymentDiglog"
      :title="'重新入职'"
      :tips="tips2"
    />

    <!-- 编辑员工弹窗 -->
    <el-drawer
      :wrapperClosable="false"
      title=""
      :key="selectEmployees"
      :visible.sync="reEmployment"
      :with-header="false"
      size="47%"
    >
      <ReEmployment
        @close="closeEmploy"
        @comfinEmploy="addUser"
        :id="selectEmployees"
        :deparment="selectEmployeesName"
      />
    </el-drawer>
  </div>
</template>

<script>
import Diglog from "../../../assembly/dialog.vue";
import Scree from "../module/member/scree.vue";

import ReEmployment from "../module/member/reEmployment.vue";
import Upload from "../module/member/upload.vue";
import Distribution from "../module/member/distribution.vue";

// 部门
import Branch from "../module/branch/newbranch.vue";
import showDialog from "../../../assembly/dialog.vue";
import Alteration from "../module/member/alterationdept.vue";

import http from "../../../utils/http";

import searchIcon from "../../../assets/structure/search.png";
import deleteIcon from "../../../assets/structure/delete.png";

import exprotExcel from "../../../utils/exprotExcel";
import multiblack from "../../../assets/structure/multiblack.png";
import multi from "../../../assets/structure/multi.png";

export default {
  data() {
    return {
      issuedKey: Math.random(),
      filterkey: Math.random(),
      screenKey: Math.random(),
      searchValue: "", //搜索内容
      searchForm: {}, //搜索表单
      searchMemicon: false, //删除图片是否显示(员工)
      searchicon: searchIcon, //搜索图标
      sidebarShow: true, //显示隐藏
      sidebarShoworder: true, //延迟1秒隐藏
      sidebarShowIcon: true, //图标显示时间
      delectUserDiglog: false, //删除员工弹窗
      reEmploymentDiglog: false, //重新入职弹窗
      reEmployment: false, //重新入职编辑
      multiblack,
      multi,

      resignationType: "", //离职类型
      handle: "", //经办人

      tips: "确认将该人员删除？删除后该人员信息将永久清除。",
      tips2: "确认为该人员办理重新入职？重新入职后将同步恢复该人员信息。",

      selectEmployees: null, //所选员工id
      selectEmployeesName: "", //所选员工部门名称

      refsName: 999, //ref控制显示隐藏

      companyName: "", //企业名称
      departmentList: [], //部门列表
      isExpand: false, //是否默认展开全部节点
      refreshTree: true, //动态渲染
      ischangec: "", //公司样式
      treeKey: "0",
      defaultProps: {
        children: "subDepartment",
        label: "departmentName",
      },

      filtrateResult: {}, //筛选字段

      // 部门操作
      businessClick: false, //企业选择
      resultClick: false, //搜索部门选择
      selectDivision: -1, //选择的部门
      alterationDiglog: false, //变更部门
      value: 1,
      tableData: [],
      pages: {}, //页码
      searchData: [], //搜索列表
      normalxi: true, //是否正常显示树状图
      searchfruit: {}, //搜索结果
      loading: false, //搜索等待
      addBranch: false, //添加子部门或编辑
      branchType: 1, //1是添加，2是子级，3是编辑
      tips: "", //提示
      deleteButton: false, //删除部门
      errorTips: false, //错误提示
      options: [
        {
          label: "自离",
          value: 0,
        },
        {
          label: "辞退",
          value: 1,
        },
        {
          label: "其他",
          value: 3,
        },
      ],
      tableColumn: [
        {
          title: "姓名",
          dataIndex: "name",
          id: 1,
        },
        {
          title: "员工工号",
          dataIndex: "jobNumber",
          id: 2,
        },
        {
          title: "设备工号",
          dataIndex: "deviceNumber",
          id: 3,
        },
        {
          title: "部门",
          dataIndex: "departmentName",
          id: 4,
        },
        {
          title: "职位",
          dataIndex: "job",
          id: 5,
        },
        {
          title: "离职类型",
          dataIndex: "resignation",
          id: 6,
        },
        {
          title: "离职日期",
          dataIndex: "timeQuit",
          id: 7,
        },
        {
          title: "离职原因",
          dataIndex: "leaveReason",
          id: 8,
        },
        {
          title: "经办人",
          dataIndex: "xx",
          id: 9,
        },
      ], //导出内容参数
    };
  },
  components: {
    Diglog,
    Scree,
    Upload,
    Distribution,
    ReEmployment,
    Branch,
    showDialog,
    Alteration,
  },

  watch: {
    "searchForm.likeString"(newName, oldName) {
      if (newName) {
        this.searchMemicon = true;
      } else {
        this.searchMemicon = false;
      }
    },
  },

  created() {
    // 获取部门列表
    var depatment = localStorage.getItem("department");
    depatment = JSON.parse(depatment);
    this.departmentList = depatment;
    // 获取员工列表
    this.getUser({
      pageNum: 1,
    });
  },

  // 点击任何地方取消下拉
  updated() {
    var that = this;
    that.$nextTick(() => {
      document.onclick = function () {
        if (that.$refs[`popoverRef_${that.refsName}`]) {
          that.$refs[`popoverRef_${that.refsName}`].doClose();
        }
      };
    });
  },

  methods: {
    // 平移动画
    translation() {
      this.sidebarShow = !this.sidebarShow;
      setTimeout(() => {
        this.sidebarShoworder = !this.sidebarShoworder;
      }, 100);

      // 图标变化
      setTimeout(() => {
        this.sidebarShowIcon = !this.sidebarShowIcon;
      }, 300);
    },

    // 表头背景颜色变色
    getRowClass({ rowIndex, columnIndex }) {
      if (rowIndex == 0) {
        return "background:#F5F6FA;color:#5C5C5C";
      }
    },

    // ----------------------------员工---------------------------
    // 获取员工列表
    getUser(value) {
      http
        .get(
          "/umsUser/list",
          Object.assign(
            {
              pageSize: 50,
              typeList: "-1",
            },
            value,
            this.searchForm
          )
        )
        .then((res) => {
          if (res.code == 200) {
            this.pages = res.data;
            this.tableData = res.data.list;
          }
        });
    },

    // 页数改变
    chagePage(e) {
      var value = {};
      if (Object.keys(this.filtrateResult).length === 0) {
        value = {
          pageNum: e,
          likeString: this.searchMember,
        };
      } else {
        value = Object.assign(this.filtrateResult, {
          pageNum: e,
        });
      }
      this.getUser(value);
    },

    // 筛选员工(姓名，身份证，工号筛选)
    seachmemResult() {
      // 清空筛选框
      this.filtrateResult = {};
      this.filtrateNum = 0;
      this.isFiltrate = false;

      this.getUser({
        pageNum: 1,
        likeString: this.searchMember,
      });
    },

    // 清除员工筛选
    clearupMember() {
      this.searchForm.likeString = "";

      this.getUser({
        pageNum: 1,
      });
    },

    // 删除员工
    delectUser(id) {
      setTimeout(() => {
        this.delectUserDiglog = true;
      }, 200);
      if (Array.isArray(id)) {
        this.tips = "确认将所选人员删除？删除后人员信息将永久清除。";
      } else {
        this.tips = "确认将该人员删除？删除后该人员信息将永久清除。";
      }
      this.selectEmployees = id;
    },

    // 关闭删除弹窗
    closeDelete() {
      this.selectEmployees = null;
      this.delectUserDiglog = false;
    },

    // 删除员工(确认)
    deleteEmployes(ids) {
      var that = this;
      var arr = [];
      if (Array.isArray(ids)) {
        arr = ids;
      } else {
        arr = [ids];
      }
      http
        .post("/umsUser/delete", {
          ids: arr,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("删除成功");

            this.publicFiltering();
          } else {
            that.$message.error(res.message);
          }

          that.closeDelete();
        });
    },

    // 表格下载之前
    startDownload() {
      const loading = this.$loading({
        lock: true,
        text: "导出报表中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let target = event.target;
      if (target.nodeName == "I" || target.nodeName == "SPAN") {
        target = event.target.parentNode;
      }
      target.blur();
      let arr2 = [];
      for (let key of this.tableColumn) {
        arr2.push(key.key);
      }

      http
        .get(
          "/umsUser/list",
          Object.assign(this.searchForm, {
            pageNum: 1,
            typeList: "-1",
            pageSize: 99999,
          })
        )
        .then((res) => {
          if (res.code == 200) {
            loading.close();
            if (res.data.list.length > 0) {
              res.data.list.map((v) => {
                if (v.jobNumber) {
                  v.jobNumber = "\xa0" + v.jobNumber;
                }
                if (v.umsDepartment) {
                  v.departmentName = v.umsDepartment.name;
                }
                if (v.leaveType == 0) {
                  v.resignation = "自离";
                } else if (v.leaveType == 1) {
                  v.resignation = "辞退";
                } else if (v.leaveType == 3) {
                  v.resignation = "其他";
                }

                if (v.umsUser) {
                  v.xx = v.umsUser.name;
                }
                v.timeQuit = this.hadleQuit(v.timeQuit);
                for (let i = 0; i < arr2.length; i++) {
                  if (v[arr2[i]] == undefined) {
                    v[arr2[i]] = "";
                  }
                }
                for (let key in v) {
                  if (v[key] == null) v[key] = "";
                }
              });
              exprotExcel.export(
                this.tableColumn,
                res.data.list,
                "员工离职记录表"
              );
              this.$message.success("导出成功");
            } else {
              this.$message.error("无数据导出");
            }
          }
        });
    },

    // 重新入职弹窗
    reEmployesOpen(value) {
      this.reEmploymentDiglog = true;
      if (value) {
        this.selectEmployees = value.id;
        if (value.umsDepartment) {
          this.selectEmployeesName = value.umsDepartment.name;
        }
      }
    },
    // 关闭重新入职弹窗
    closereEmploymen() {
      this.selectEmployees = null;
      this.reEmploymentDiglog = false;
    },

    // 重新入职
    reEmployes() {
      this.reEmploymentDiglog = false;
      this.reEmployment = true;
    },
    // 新增员工
    addUser() {
      this.reEmployment = false;
      this.selectEmployees = null;
      // 重新获取员工列表
      this.publicFiltering();
    },
    // 关闭员工信息或者添加员工
    closeEmploy() {
      this.selectEmployees = null;
      this.reEmployment = false;
    },
    // 获取当天年月日时分秒
    getDateNon() {
      // 创建一个新的Date对象，该对象将自动包含当前日期和时间
      var currentDate = new Date();

      // 获取年份
      var year = currentDate.getFullYear();

      // 获取月份（注意月份从0开始，所以要加1）
      var month = currentDate.getMonth() + 1;

      // 获取日期
      var day = currentDate.getDate();

      return year + "-" + month + "-" + day + " " + "00:00:00";
    },

    // --------------------部门操作----------------------

    // -------------------------------------------------

    // 公用筛选
    publicFiltering() {
      var that = this;
      var value = {};
      if (Object.keys(that.filtrateResult).length === 0) {
        value = {
          pageNum: 1,
          departmentId: this.selectDivision < 0 ? "" : this.selectDivision,
        };
      } else {
        value = Object.assign(that.filtrateResult, {
          pageNum: 1,
          departmentId: this.selectDivision < 0 ? "" : this.selectDivision,
        });
      }

      that.getUser(value);
    },

    // 处理离职日期
    hadleQuit(value) {
      if (value) {
        var arr = value.split(" ");
        return arr[0];
      }
    },
  },
};
</script>

<style scoped>
.member {
  max-width: 100%;
  height: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}
.member_l {
  width: 0;
  height: 100%;
  position: relative;
  transition: all 0.5s ease 0ms;
}
.member_l_zoom {
  width: 330px;
  position: relative;
  transition: all 0.5s ease 0ms;
  border-right: 1px solid rgba(207, 212, 232, 0.5);
}
.member_company {
  width: 100%;
  height: 48px;
  display: flex;
  color: #5c5c5c;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
}
.ischangec {
  background: rgba(242, 247, 255, 0.8);
  color: #0058ff;
}
.member_company span {
  font-size: 16px;
}
.multi {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.tree_sinle {
  width: 100%;
  display: flex;
  align-items: center;
  padding-right: 12px;
  box-sizing: border-box;
  justify-content: space-between;
}
.tree_sinle_a {
  display: flex;
  align-items: center;
}
.tree_sinle_l {
  font-size: 16px;
  max-width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #5c5c5c;
  /* color: #0058ff; */
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
.tree_sinle_icon {
  width: 18px;
  height: 18px;
  margin-right: 12px;
}
.tree_multi {
  width: 24px;
  height: 24px;
}
.deptoption {
  height: 40px;
  line-height: 40px;
  padding-left: 10px;
  box-sizing: border-box;
  color: #5c5c5c;
  cursor: pointer;
}
.deptoption:hover {
  background: #edeeee;
}
.tree_multi:hover {
  background: #e2e2e2;
  border-radius: 5px;
}
.multi:hover {
  background: #e2e2e2;
  border-radius: 5px;
}
.search {
  width: 100%;
  height: 72px;
  display: flex;
  padding-top: 22px;
  padding-left: 20px;
  box-sizing: border-box;
}
.search_input {
  width: 243px;
  height: 36px;
}
.membel_zoom {
  width: 28px;
  height: 36px;
  position: absolute;
  top: 22px;
  cursor: pointer;
  right: 0;
}
.membel_zoom_r {
  width: 28px;
  height: 36px;
  position: absolute;
  top: 20px;
  cursor: pointer;
  right: -27px;
}
.member_r {
  flex: 1;
  padding: 22px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.member_r_t_l {
  display: flex;
  height: 60px;
  justify-content: space-between;
  box-sizing: border-box;
}
.member_r_filter {
  display: flex;
  cursor: pointer;
  font-size: 15px;
  color: #b2b2b2;
  width: 98px;
  height: 36px;
  padding-left: 16px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #d0d0d0;
  align-items: center;
}
.member_r_filter_content {
  display: flex;
  cursor: pointer;
  font-size: 15px;
  color: #0058ff;
  width: 119px;
  height: 36px;
  padding-left: 16px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #0058ff;
  align-items: center;
}
.member_r_filter_icon {
  width: 18px;
  height: 18px;
  margin-left: 18px;
}
.memeber_table {
  width: 100%;
  flex: 1;
  position: relative;
}
.qrcode {
  width: 100%;
}
.qrcode_frame {
  width: 457px;
  height: 358px;
  display: flex;
  color: #1a1a1a;
  margin: 60px auto 24px;
  flex-direction: column;
  align-items: center;
  padding-top: 46px;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.16);
}
.qrcode_name {
  font-size: 16px;
  margin-bottom: 30px;
}
.qrcode_img {
  width: 200px;
  height: 200px;
}
.qrcode_tips {
  color: #9a9a9a;
  font-size: 15px;
  text-align: center;
}
.qrcode_bto {
  display: flex;
  margin-top: 39px;
  justify-content: center;
}
.link_frame {
  width: 797px;
  height: 132px;
  margin: 30px;
  font-size: 16px;
  padding: 24px;
  color: #1a1a1a;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.16);
}
.link_tips {
  font-size: 15px;
  color: #9a9a9a;
  padding-left: 30px;
  box-sizing: border-box;
}
.link_bto {
  display: flex;
  margin-top: 289px;
  justify-content: center;
}
.invite_h {
  height: 73px;
  padding: 0 24px;
  color: #1a1a1a;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 18px;
  justify-content: space-between;
}
.invite_icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.member_search {
  width: 243px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #d0d0d0;
  display: flex;
  align-items: center;
  padding-right: 12px;
  box-sizing: border-box;
  justify-content: space-between;
}
.memeber_operation {
  margin-left: 8px;
  width: 107px;
}
.delectdiglog {
  width: 100%;
  margin-top: 20px;
  border-top: 1px solid #cfd4e8;
}
.delectdiglog_title {
  font-size: 16px;
  color: #1a1a1a;
  padding: 30px;
  box-sizing: border-box;
}
.diglog_h {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  padding: 24px 24px 0 24px;
  color: #1a1a1a;
}
.diglog_icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.diglog_operation {
  margin: 60px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.member_name {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #1a1a1a;
}
.member_head {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 10px;
}
.left {
  margin-left: 28px;
}
::v-deep .el-table__body .el-table__row.hover-row td {
  background-color: rgba(0, 98, 255, 0.05);
}
::v-deep .el-tree-node__content:hover {
  background: #f2f7ff;
}
.filtrate {
  display: flex;
  align-items: center;
}
.filtrate_single {
  padding: 6px;
  color: #0058ff;
  font-size: 15px;
  display: flex;
  align-items: center;
  background: #e0ecff;
  margin-right: 10px;
  border-radius: 4px;
}
.filtrate_icon {
  margin-left: 6px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.aselect {
  color: #0058ff;
  background-color: rgba(0, 98, 255, 0.05);
}
.selectColor {
  color: #0058ff;
}
.tree_case {
  height: 743px;
  overflow-y: scroll;
}
.tree_case::-webkit-scrollbar {
  display: none;
}
.approval_search {
  width: 243px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #d0d0d0;
  display: flex;
  align-items: center;
  padding-right: 12px;
  box-sizing: border-box;
  justify-content: space-between;
}
.approval_search:focus-within {
  border-color: #0096fa;
}
.member_search:focus-within {
  border-color: #0096fa;
}
.m_table {
  width: 100%;
  height: 100%;
  margin-top: 16px;
  position: absolute;
}

::v-deep .member_r_t_dowm .el-input__inner {
  height: 36px;
  margin-left: 10px;
}
::v-deep .el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background: none;
}
.member_r_t_left {
  display: flex;
  align-items: center;
}
.member_r_t_right {
  width: 80px;
  height: 36px;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #d5d7e3;
  font-size: 14px;
  color: #7e84a3;
  line-height: 36px;
  text-align: center;
  cursor: pointer;
}
.structure_feature {
  display: flex;
  align-items: center;
}
.feature {
  width: 30px;
  height: 30px;
  margin-right: 12px;
}
.ellipsis {
  width: 150px;
  white-space: nowrap; /* 防止文本换行 */
  overflow: hidden; /* 隐藏超出容器的文本 */
  text-overflow: ellipsis; /* 显示省略号表示文本被截断 */
}
</style>
