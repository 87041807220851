<template>
  <div class="structure">
    <div class="structure_t">组织架构 / <span>人员管理</span></div>
    <div class="structure_frame">
      <div class="structure_frame_head">
        <div class="structure_nav">
          <div v-for="item in navi" :key="item.id">
            <div
              @click="switchTab(item.id)"
              class="structure_nav_s"
              :style="{
                color: selectIndex == item.id ? '#0058FF' : '#1A1A1A',
              }"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="structure_frame_content">
        <!-- 在职成员 -->
        <Member v-if="selectIndex == 1" />
        <!-- 离职部门 -->
        <Resignation v-if="selectIndex == 2" />
        <!-- 人事调动 -->
        <Approval v-if="selectIndex == 3" />
      </div>
    </div>
    <div class="filing">
      CopyRight@2023广州巨源信息技术有限公司-<a
        href="https://beian.miit.gov.cn/"
        >粤ICP备2023127373号-4</a
      >
    </div>
  </div>
</template>

<script>
// 在职成员
import Member from "./personnel/member.vue";
// 离职成员
import Resignation from "./personnel/resignations.vue";
// 审批
import Approval from "./personnel/approval.vue";

import http from "../../utils/http";
export default {
  data() {
    return {
      selectIndex: 1, //当前所在的导航索引
      total: 0, //审批数量
      navi: [
        {
          name: "在职人员",
          id: 1,
          nav: "",
        },
        {
          name: "离职人员",
          id: 2,
          nav: "",
        },
        {
          name: "人事调动",
          id: 3,
          nav: "",
        },
      ],
    };
  },

  components: {
    Member,
    Resignation,
    Approval,
  },

  created() {
    // 实时监听审批时的动态
    this.$bus.$on("approvalNum", (arr) => {
      this.total = arr;
    });
  },

  methods: {
    // 切换tab
    switchTab(id) {
      if (this.selectIndex == id) {
        return;
      }
      this.selectIndex = id;
    },
  },
};
</script>

<style scoped>
.structure {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  box-sizing: border-box;
  background-color: #f4f7fc;
}
.structure_t {
  font-size: 14px;
  color: #b2b2b2;
  margin-bottom: 16px;
}
.structure_t span {
  color: #5c5c5c;
}
.structure_frame {
  width: 99%;
  box-shadow: 0px 1px 4px 1px rgba(21, 34, 50, 0.08);
  border-radius: 6px;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: white;
}
.structure_frame_head {
  width: 100%;
  height: 75px;
  justify-content: space-between;
  border-bottom: 1px solid rgba(207, 212, 232, 0.5);
  display: flex;
  padding: 0 20px 0 30px;
  box-sizing: border-box;
  align-items: center;
}
.structure_frame_content{
  flex: 1;
}
.structure_nav {
  display: flex;
  width: 360px;
  color: #1a1a1a;
  justify-content: space-between;
  font-size: 20px;
  align-items: center;
}
.structure_nav_s {
  cursor: pointer;
  font-size: 20px;
}
::v-deep .el-badge__content.is-fixed {
  background: #f0142f;
}
</style>
