<template>
  <div class="gps">
    <div>
      <div class="visitor_information_t">
        <div class="visitor_information_search">
          <el-input
            class="department_search_inp"
            v-model="searchWifi"
            @keyup.enter.native="seachResult"
            placeholder="Wi-Fi名称"
          ></el-input>
          <img
            src="@/assets/structure/delete.png"
            class="searchicon"
            @click="clearup"
            v-if="searchicon"
          />
          <img
            src="@/assets/structure/search.png"
            class="searchicon"
            @click="seachResult"
          />
        </div>
        <el-button
          @click="newFormVisible"
          type="primary"
          class="structure_addstaff"
          >新增Wi-Fi打卡</el-button
        >
      </div>
    </div>
    <div class="gps_table">
      <el-table
        height="100%"
        ref="multipleTable"
        :data="tableData"
        class="v_table"
        :header-cell-style="getRowClass"
      >
        <template slot="empty">
          <div>
            <img src="@/assets/nodata.png" class="nodata" />
            <div>无数据</div>
          </div>
        </template>
        <el-table-column label="Wi-Fi名称" width="350">
          <template slot-scope="scope">
            <div>
              {{ scope.row.wifiName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="MAC地址" width="300">
          <template slot-scope="scope">
            <div class="ellipsis">
              {{ scope.row.wifiMac }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="打卡人员"
          header-align="left"
          align="left"
          width="350"
        >
          <template slot-scope="scope">
            <button class="group_button_p" @click="checkPeople(scope.row.id)">
              人员明细
            </button></template
          >
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-link
              type="primary"
              style="margin-left: 4px"
              @click="openReviewen(scope.row.id)"
              >新增人员</el-link
            >
            <el-link
              type="primary"
              style="margin-left: 24px"
              @click="editWifi(scope.row)"
              >编辑</el-link
            >
            <el-link
              type="danger"
              style="margin-left: 24px"
              @click="deleteWifiDialog(scope.row.id)"
              >删除</el-link
            >
          </template>
        </el-table-column>
      </el-table>
      <div
        class="newpagination"
        style="padding-left: 20px; box-sizing: border-box"
      >
        <div class="newpagination_text">共 {{ pages.total }} 条数据</div>
        <el-pagination
          layout="prev, pager, next"
          :total="pages.total"
          :current-page="pages.pageNum"
          :page-size="10"
          @current-change="chagePage"
        >
        </el-pagination>
      </div>
    </div>
    <div class="newpagination">
      <div class="newpagination_text">共 {{ pages.total }} 条数据</div>
      <el-pagination
        layout="prev, pager, next"
        :total="pages.total"
        :current-page="pages.pageNum"
        @current-change="chagePage"
        :page-size="10"
      >
      </el-pagination>
    </div>
    <!-- 添加打卡地点 -->
    <el-dialog
      title=""
      ref="dialogRef"
      :visible.sync="checkLocation"
      :close-on-click-modal="false"
      width="48%"
      :show-close="false"
    >
      <div class="diglog_header">
        <div>{{ wifiForm.id ? "编辑打卡Wi-Fi" : "添加打卡Wi-Fi" }}</div>
        <img
          src="@/assets/device/close.png"
          class="close"
          @click="cancelDialog"
        />
      </div>
      <div>
        <div class="address_name">
          <div>Wi-Fi名称</div>
          <div class="address_bi">*</div>
          <el-input
            v-model="wifiForm.name"
            maxlength="36"
            placeholder="最多32个字符"
            class="address_specific"
          ></el-input>
        </div>
        <div class="address_name2">
          <div>MAC地址</div>
          <div class="address_bi">*</div>
          <el-input
            v-model="wifiForm.wifiMac"
            placeholder="例如：03:16:0f:22:1b:3d"
            class="address_specific"
          ></el-input>
        </div>
      </div>
      <div class="wifi_tips">
        <div class="wifi_tips_left">
          <div class="wifi_tips_left_title">获取方式</div>
          <div class="wifi_tips_left_content">
            1.可以向公司IT部门询问公司Wi-Fi的MAC地址
          </div>
          <div class="wifi_tips_left_content">
            2.也可以通过小程序扫描右侧二维码获取
          </div>
          <div class="wifi_tips_left_content">
            3.请确保路由器MAC地址不会动态变化，否则在连接Wi-Fi后可能出现“不在打卡范围内”等异常
          </div>
        </div>
        <div class="wifi_tips_right">
          <div class="box" ref="canvasWrapper"></div>
          <div>小程序扫码</div>
        </div>
      </div>
      <div class="map_bto">
        <div class="cancel" @click="cancelDialog">取消</div>
        <div class="confim" @click="confimDialog">确认</div>
      </div>
    </el-dialog>
    <!-- 选择人员范围 -->
    <el-dialog
      title=""
      :key="rangeKey"
      :visible.sync="availableDialog"
      width="45%"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <div class="diglog_h">
        <div>可用人员范围</div>
        <img
          src="@/assets/structure/close.png"
          class="diglog_icon"
          @click="closeavailableDialog"
        />
      </div>
      <div class="available_frame">
        <div class="available_frame_left">
          <div class="available_title">部门/人员</div>
          <div class="available_search">
            <el-select v-model="selectType" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-input
              v-model="searchValue"
              @keyup.enter.native="searchAvailableSear"
              class="available_search_input"
              :placeholder="selectType == 1 ? '部门名称' : '员工姓名'"
            >
            </el-input>
            <img
              src="@/assets/structure/search.png"
              class="available_search_icon"
              @click="searchAvailableSear"
            />
          </div>
          <div>
            <div class="breadcrumbs">
              <div
                v-for="(item, index) in breadcrumbs"
                :key="index"
                class="breadcrumbs_all"
                @click="clickBreadcrumbs(index, item)"
              >
                <div
                  :class="[
                    'breadcrumbs_text',
                    index == breadcrumbs.length - 1
                      ? 'breadcrumbs_current'
                      : 'breadcrumbs_last',
                  ]"
                >
                  {{ item.name }}
                </div>
                <div style="margin: 0 5px">
                  {{ index == breadcrumbs.length - 1 ? " " : "/" }}
                </div>
              </div>
            </div>
            <div class="available_depaall">
              <div
                :class="[
                  'available_depa',
                  availableSelecDept == item.id ? 'available_depa_select' : '',
                ]"
                v-for="(item, index) in availableDept"
                @click="switchDpat(item)"
                :key="index"
              >
                <div class="available_depa_left">
                  <div class="available_depa_left_icon">
                    <img src="@/assets/device.png" />
                  </div>
                  <div class="available_depa_left_name">{{ item.name }}</div>
                </div>
                <div
                  v-if="item.subDepartment"
                  class="available_depa_left_next"
                  @click="checkSubordinate(item)"
                >
                  下级
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="available_frame_center">
          <div class="available_title">待选择人员</div>
          <div class="available_frame_tobeselected">
            <div class="available_frame_tobeselected_top">
              <el-checkbox @change="selectAllpeople" v-model="isSelecAll"
                >全选</el-checkbox
              >
              <div>
                已选：{{
                  countCommonValues(waitPeopleList, completePeopleList)
                }}
              </div>
            </div>
            <div class="available_frame_tobeselected_center">
              <el-checkbox-group v-model="completePeopleList">
                <div
                  class="available_frame_tobeselected_single"
                  v-for="item in waitPeopleList"
                  :key="item.id"
                >
                  <el-checkbox
                    :label="item"
                    :checked="item.checked"
                    :disabled="item.isIn || !item.jobNumber"
                    ><br
                  /></el-checkbox>
                  <div class="available_depa_left_icon2">
                    <img src="@/assets/device.png" />
                  </div>
                  <div>
                    <div class="available_frame_tobeselected_name">
                      {{ item.name }}
                    </div>
                    <div class="available_frame_tobeselected_depa">
                      {{ item.department || item.umsDepartment.name }}
                    </div>
                  </div>
                </div>
              </el-checkbox-group>
            </div>
          </div>
        </div>
        <div class="available_frame_right">
          <div class="available_title">已选择人员</div>
          <div class="available_frame_tobeselected">
            <div class="available_frame_tobeselected_delte" @click="deleteAll">
              全部移除
            </div>
            <div class="available_frame_tobeselected_center">
              <div
                class="available_frame_tobeselected_single2"
                v-for="(item, index) in completePeopleList"
                :key="index"
              >
                <div class="available_frame_select_left">
                  <div class="available_depa_left_icon">
                    <img src="@/assets/device.png" />
                  </div>
                  <div>
                    <div class="available_frame_tobeselected_name">
                      {{ item.name }}
                    </div>
                    <div class="available_frame_tobeselected_depa">
                      {{ item.department }}
                    </div>
                  </div>
                </div>
                <img
                  src="@/assets/close.png"
                  class="available_frame_select_right"
                  @click="deleteSelect(index)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="available_frame_bto">
        <div class="available_frame_cancel" @click="closeavailableDialog">
          取消
        </div>
        <el-button
          class="available_frame_comfin"
          @click="comfinAdd"
          v-loading="onSubmit"
          >确定</el-button
        >
      </div>
    </el-dialog>
    <!-- 人员明细 -->
    <el-dialog
      title=""
      width="52%"
      :key="currentGroundid"
      :show-close="false"
      :visible.sync="inviteDetail"
      class="invite_frame"
    >
      <Detail
        @close="
          inviteDetail = false;
          currentGroundid = null;
        "
        :id="currentGroundid"
        :type="2"
      />
    </el-dialog>
    <!-- 删除班次弹窗 -->
    <Diglog
      @closeDiglog="closeWifiDialog"
      @affirmDiglog="deleteWifi"
      :isShow="delectVistorDiglog"
      :title="'删除打卡Wi-Fi'"
      :tips="'删除打卡Wi-Fi后，所在打卡Wi-Fi点人员将无法通过Wi-Fi打卡'"
    />
  </div>
</template>

<script>
import http from "../../../utils/http";
import Detail from "./detail.vue";
import Diglog from "../../../assembly/dialog.vue";

import QRCode from "qrcode";
export default {
  data() {
    return {
      wifiForm: {}, //wifi信息
      searchicon: false, //监听输入
      searchWifi: "", //输入框搜索wifi
      searchValue: "", //输入框输入的信息
      pageNum: 1, //页码
      checkLocation: false, //添加打卡地点弹窗
      tableData: [], //表格数据
      pages: {}, //分页列表
      availableDialog: false, //打卡人员弹窗
      rangeKey: Math.random(), //打卡人员key
      selectType: 1, //搜索选择类型
      searchAvailable: "", //搜索姓名
      onSubmit: false, //是否加载
      deptList: [], //部门数组
      isSelecAll: false, //是否全选
      breadcrumbs: [], //面包屑
      availableDept: [], //部门列表
      availableDeptCopy: [], //复制部门列表
      availableSelecDept: null, //所选部门id
      wifiId: null, //所选的wifi的id
      options: [
        {
          label: "部门",
          value: 1,
        },
        {
          label: "人员",
          value: 2,
        },
      ],
      waitPeopleList: [], //待选人员
      selectIds: [], //已选择人员
      completePeopleList: [], //已选人员
      ticket: "", //网络凭证
      timer: null, //定时器

      inviteDetail: false, //人员明细弹窗
      currentGroundid: null, //当前选择的地址

      delectVistorDiglog: false, //删除地址弹窗

      rangerList: [
        {
          value: "100",
          label: "100米",
        },
        {
          value: "200",
          label: "200米",
        },
        {
          value: "300",
          label: "100米",
        },
        {
          value: "400",
          label: "400米",
        },
        {
          value: "500",
          label: "500米",
        },
      ], //范围
    };
  },

  components: { Detail, Diglog },

  created() {
    // 获取打卡列表
    this.getList();

    // 获取全部部门
    this.getDepartmentList();
  },

  mounted() {
    this.checkLocation = true;
    this.$refs.dialogRef.rendered = true; // 为dialog设置一个ref为dialogRef
    this.checkLocation = false;
  },

  watch: {
    completePeopleList: {
      handler(newValue, oldValue) {
        if (this.waitPeopleList.length > 0) {
          var filteredArr = this.waitPeopleList.filter(function (item) {
            return !item.isIn;
          });
          // 当 items 数组发生变化时，这个函数会被调用
          let containsAll = filteredArr.every((value) =>
            newValue.includes(value)
          );
          // 你可以在这里执行你想要的操作
          if (containsAll) {
            this.isSelecAll = true;
          } else {
            this.isSelecAll = false;
          }

          var isrepeat = this.hasDuplicates(newValue);
          if (isrepeat) {
            var arr = this.removeDuplicates(newValue);

            this.completePeopleList = arr;
          }
        }
      },
      deep: true, // 深度监听，用于监听数组内部对象的变化
    },
    // 监听待选择列表
    waitPeopleList: {
      handler(newValue, oldValue) {
        this.completePeopleList.map((item) => {
          var index = newValue.findIndex((citem) => item.id == citem.id);
          if (index > -1) {
            this.$set(newValue[index], "checked", true);
          }
        });
      },
      deep: true, // 深度监听，用于监听数组内部对象的变化
    },
    searchWifi(newName, oldName) {
      if (newName) {
        this.searchicon = true;
      } else {
        this.searchicon = false;
      }
    },
    // 监听输入框变化
    searchValue(newValue, oldValue) {
      if (!newValue) {
        this.breadcrumbs = [];
        this.availableDept = this.availableDeptCopy;
        this.waitPeopleList = [];
      }
    },
  },

  methods: {
    // 获取打卡列表
    getList() {
      var object = {};
      if (this.searchWifi) {
        object.wifiName = this.searchWifi;
      }
      http
        .get(
          "/bmsWifiGroup/list",
          Object.assign(
            {
              pageNum: this.pageNum,
              pageSize: 10,
            },
            object
          )
        )
        .then((res) => {
          if (res.code == 200) {
            this.tableData = res.data.list;
            this.pages = res.data;
          }
        });
    },
    // 搜索
    seachResult() {
      this.pageNum = 1;
      this.getList();
    },
    // 清除搜索
    clearup() {
      this.searchWifi = "";
      this.pageNum = 1;
      this.getList();
    },
    // 表头背景颜色变色
    getRowClass({ rowIndex, columnIndex }) {
      if (rowIndex == 0) {
        return "background:#F5F6FA;color:#5C5C5C";
      }
    },

    // 改变页数
    chagePage(page) {
      this.pageNum = page;
      this.getList();
    },

    // ----------------------新增编辑删除wifi打卡--------------------------
    // 新增wifi打卡
    newFormVisible() {
      this.$refs.canvasWrapper.innerHTML = "";
      http.get("/bmsWifiGroup/get/wifi_info_ticket").then((res) => {
        if (res.code == 200) {
          var ticket = "type=1&ticket=" + res.data;
          this.ticket = res.data;
          // 生成二维码
          QRCode.toCanvas(
            ticket,
            {
              margin: 1,
            },
            (error, canvas) => {
              if (error) {
                console.error(error);
                return;
              }
              // 在canvas的父元素中插入canvas元素
              this.$nextTick(() => {
                this.$refs.canvasWrapper.appendChild(canvas);
              });
            }
          );
          this.checkLocation = true;

          this.timer = setInterval(() => {
            // 执行需要定时重复执行的任务
            this.getWifiInfo();
          }, 2000);
        }
      });
    },
    // 切换二维码
    switchQrcode() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
      this.$refs.canvasWrapper.innerHTML = "";
      http.get("/bmsGpsGroup/get/gps_info_ticket").then((res) => {
        if (res.code == 200) {
          var ticket = "type=2&ticket=" + res.data;
          this.ticket = res.data;
          // 生成二维码
          QRCode.toCanvas(
            ticket,
            {
              margin: 1,
            },
            (error, canvas) => {
              if (error) {
                console.error(error);
                return;
              }
              // 在canvas的父元素中插入canvas元素
              this.$nextTick(() => {
                this.$refs.canvasWrapper.appendChild(canvas);
              });
            }
          );
          this.timer = setInterval(() => {
            // 执行需要定时重复执行的任务
            this.getWifiInfo();
          }, 2000);
        }
      });
    },
    // 轮询获取扫码信息
    getWifiInfo() {
      http
        .get("/bmsWifiGroup/get/wifi_info", {
          ticket: this.ticket,
        })
        .then((res) => {
          if (res.code == 200) {
            if (res.data) {
              this.wifiForm = res.data;
              this.switchQrcode();
            }
          }
        });
    },
    // 取消弹窗
    cancelDialog() {
      this.checkLocation = false;
      clearInterval(this.timer);
      this.timer = null;
      this.wifiForm = {};
    },

    // 编辑wifi
    editWifi(val) {
      var newVal = JSON.stringify(val);
      newVal = JSON.parse(newVal);

      this.wifiForm = newVal;
      this.checkLocation = true;
    },

    // 确认操作
    confimDialog() {
      if (!this.wifiForm.name || this.wifiForm.name == "") {
        this.$message.error("请先输入wifi名称");
        return;
      }
      if (!this.wifiForm.wifiMac || this.wifiForm.wifiMac == "") {
        this.$message.error("请先输入网络地址");
        return;
      }

      // 判断是新增还是编辑
      if (this.wifiForm.id) {
        http
          .post("/bmsWifiGroup/update", {
            id: this.wifiForm.id,
            name: this.wifiForm.name,
            wifiName: this.wifiForm.name,
            wifiMac: this.wifiForm.wifiMac,
          })
          .then((res) => {
            if (res.code == 200) {
              this.$message.success("编辑成功");

              this.cancelDialog();

              this.pageNum = 1;
              this.getList();
            } else {
              this.$message.error(res.message);
            }
          });
      } else {
        http
          .post("/bmsWifiGroup/add", {
            name: this.wifiForm.name,
            wifiName: this.wifiForm.name,
            wifiMac: this.wifiForm.wifiMac,
          })
          .then((res) => {
            if (res.code == 200) {
              this.$message.success("添加成功");

              this.cancelDialog();

              this.pageNum = 1;
              this.getList();
            } else {
              this.$message.error(res.message);
            }
          });
      }
    },

    // 删除提示弹窗
    deleteWifiDialog(id) {
      this.currentGroundid = id;
      this.delectVistorDiglog = true;
    },

    // 关闭删除提示弹窗
    closeWifiDialog() {
      this.currentGroundid = null;
      this.delectVistorDiglog = false;
    },

    // 确认删除操作
    deleteWifi() {
      http
        .post("/bmsWifiGroup/delete", {
          ids: [this.currentGroundid],
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("删除成功");

            this.closeWifiDialog();

            this.pageNum = 1;
            this.getList();
          } else {
            this.$message.error(res.message);
          }
        });
    },

    // ----------------------打卡人员范围--------------------------
    // 打开设备人员弹窗
    openReviewen(id) {
      this.wifiId = id;
      this.rangeKey = Math.random();
      setTimeout(() => {
        this.availableDialog = true;
      }, 500);
    },
    // 获取部门列表
    getDepartmentList() {
      http.get("/umsDepartment/list").then((res) => {
        if (res.code == 200) {
          var arr = this.convertToNestedStructure(res.data);
          this.availableDept = arr;
          this.availableDeptCopy = JSON.stringify(arr);
          this.availableDeptCopy = JSON.parse(this.availableDeptCopy);
        }
      });
    },
    // 递归处理数据
    convertToNestedStructure(arr) {
      // 创建一个映射，用于快速查找具有特定id的对象
      const idMap = new Map(arr.map((item) => [item.id, item]));

      // 创建一个新数组来存储转换后的嵌套结构
      const nestedArr = [];

      // 遍历原始数组
      arr.forEach((item) => {
        // 如果该项有父元素，则将其添加到父元素的subDepartment数组中
        if (item.parent) {
          const parent = idMap.get(item.parent);
        if (parent) {
            if (!parent.subDepartment) {
              parent.subDepartment = [];
            }
            item.parentDepament = parent.name;
            parent.subDepartment.push(item);
          }
        } else {
          // 如果没有父元素，则添加到新数组的根级别
          nestedArr.push(item);
        }
      });

      // 返回转换后的嵌套数组
      return nestedArr;
    },
    // 获取部门列表
    getDept(id, department) {
      http
        .get("/bmsWifiGroupPeople/search", {
          department: id,
          wifi: this.wifiId,
        })
        .then((res) => {
          if (res.code == 200) {
            var arr = [];
            res.data.map((item) => {
              item.department = department;
              item.checked = false;
              arr.push(item);
            });
            this.waitPeopleList = arr;

            if (arr.length > 0) {
              var istrue = this.areAllIdsInArray(arr, this.completePeopleList);
              if (istrue) {
                this.isSelecAll = true;
              } else {
                this.isSelecAll = false;
              }
            }
          }
        });
    },
    // 取消设备打卡人员弹窗
    closeavailableDialog() {
      this.completePeopleList = [];
      this.breadcrumbs = [];
      this.availableDept = this.availableDeptCopy;
      this.isSelecAll = false;
      this.waitPeopleList = [];
      this.availableDialog = false;
    },
    // 确认添加打卡人员
    comfinAdd() {
      if (this.completePeopleList.length == 0) {
        this.$message.error("请先选择人员");
        return;
      }
      this.onSubmit = true;
      var arr = [];
      this.completePeopleList.map((item) => {
        arr.push(item.id);
      });

      http
        .post("/bmsWifiGroupPeople/add", {
          userList: arr,
          wifi: this.wifiId,
        })
        .then((res) => {
          this.onSubmit = false;
          if (res.code == 200) {
            this.$message.success("添加成功");
            this.closeavailableDialog();

            this.pageNum = 1;
            this.getList();
          }
        });
    },
    // 点击面包屑的部门
    clickBreadcrumbs(index, item) {
      this.breadcrumbs.splice(index + 1);
      var arr = this.searchNestedObject(this.availableDeptCopy, item.id);
      this.getDept(item.id, item.name);
      this.availableDept = arr.subDepartment;
    },
    // 查询子级
    searchNestedObject(arr, id) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].id === id) {
          return arr[i];
        }
        if (arr[i].subDepartment) {
          const found = this.searchNestedObject(arr[i].subDepartment, id);
          if (found) {
            return found;
          }
        }
      }
      return undefined;
    },
    // 切换部门
    switchDpat(item) {
      this.availableSelecDept = item.id;
      this.getDept(item.id, item.name);
    },
    // 查看下级部门
    checkSubordinate(item) {
      this.breadcrumbs.push({
        name: item.name,
        id: item.id,
      });

      this.getDept(item.id, item.name);
      this.availableDept = item.subDepartment;
    },
    // 判断有几个已经选择
    countCommonValues(array1, array2) {
      // 将 array1 的 id 存储在一个集合中
      const set1 = new Set(array1.map((item) => item.id));

      // 计数器
      let commonCount = 0;

      // 遍历 array2 并检查每个 id 是否存在于集合中
      for (let item of array2) {
        if (set1.has(item.id)) {
          commonCount++;
        }
      }

      return commonCount;
    },
    // 判断待选择是否都在已选择里面
    areAllIdsInArray(a, b) {
      // 将 b 的 id 存储在一个集合中
      const setB = new Set(b.map((item) => item.id));

      // 检查 a 中的每个 id 是否都在集合 setB 中
      for (let item of a) {
        if (!setB.has(item.id)) {
          return false; // 如果有一个 id 不在 b 中，则返回 false
        }
      }

      return true; // 如果所有 id 都在 b 中，则返回 true
    },
    // 判断数组是否有重复
    hasDuplicates(array) {
      const set = new Set();
      for (let item of array) {
        if (set.has(item.id)) {
          return true; // 如果集合中已经存在该元素，则说明有重复
        }
        set.add(item.id); // 否则将元素添加到集合中
      }
      return false; // 遍历完数组后仍未发现重复项
    },
    // 数组去重
    removeDuplicates(array) {
      // 使用 Map 来去重，Map 的键是 id，值是对象本身
      const map = new Map();

      for (let item of array) {
        map.set(item.id, item); // 如果 id 已经存在，则会覆盖
      }

      // 将 Map 的值转换回数组
      return Array.from(map.values());
    },
    // 搜索
    searchAvailableSear() {
      if (this.selectType == 1) {
        // 搜索部门
        let result = this.findNode(this.searchValue);
        if (result) {
          this.availableDept = [result];
        } else {
          this.availableDept = [];
        }
      } else {
        // 搜索人
        http
          .get("/bmsWifiGroupPeople/search", {
            wifi: this.wifiId,
            userName: this.searchValue,
          })
          .then((res) => {
            if (res.code == 200) {
              this.waitPeopleList = res.data;
            }
          });
      }
    },
    // 搜索指定的值
    findNode(target) {
      let stack = [...this.availableDeptCopy]; // 初始化栈为顶层数组的元素

      while (stack.length > 0) {
        let node = stack.pop(); // 弹出栈顶元素

        if (node.name === target) {
          return node; // 找到目标节点，返回
        }

        if (node.subDepartment) {
          stack.push(...node.subDepartment); // 将子节点压入栈中
        }
      }

      return null; // 未找到目标节点
    },
    // 全选人员
    selectAllpeople(value) {
      if (value) {
        var filteredArr = this.waitPeopleList.filter(function (item) {
          return !item.isIn;
        });
        // 合并两个数组
        let combinedArray = this.completePeopleList.concat(filteredArr);

        // 使用 filter 方法去重
        let uniqueArray = combinedArray.filter((value, index, self) => {
          return self.indexOf(value) === index;
        });

        this.completePeopleList = uniqueArray;
      } else {
        let result = this.completePeopleList.filter(
          (item) => !this.waitPeopleList.includes(item)
        );
        this.completePeopleList = result;
      }
    },

    // 删除选中的人员
    deleteSelect(index) {
      this.completePeopleList.splice(index, 1);
    },
    // 删除全部选中的人员
    deleteAll() {
      this.completePeopleList = [];
    },
    // ----------------------查看人员明细--------------------------
    // 人员明细
    checkPeople(id) {
      this.currentGroundid = id;
      setTimeout(() => {
        this.inviteDetail = true;
      }, 500);
    },
  },
};
</script>

<style scoped>
.gps {
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: white;
}
.visitor_information_t {
  box-sizing: border-box;
  border-bottom: 1px solid rgba(207, 212, 232, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
}
.visitor_information_search {
  width: 243px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #d0d0d0;
  display: flex;
  align-items: center;
  padding-right: 12px;
  box-sizing: border-box;
  justify-content: space-between;
}
.department_search_inp {
  width: 200px;
}
.gps_table {
  width: 100%;
  flex: 1;
  position: relative;
}
.v_table {
  width: 100%;
  height: 100%;
  position: absolute;
}
.group_button_p {
  /* width: 80px !important; */
  background: #e0ecff;
  cursor: pointer;
  font-size: 14px;
  opacity: 1;
  color: #0058ff !important;
  text-align: center;
  border: none;

  height: 26px;
}
.diglog_header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 74px;
  justify-content: space-between;
  padding: 0 24px;
  box-sizing: border-box;
  font-size: 18px;
  color: #1a1a1a;
}
.close {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
::v-deep .el-dialog {
  padding: 0;
  padding-bottom: 40px;
  box-sizing: border-box;
}
.address_name {
  width: 100%;
  padding-left: 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin: 30px 0;
}
.address_name2 {
  width: 100%;
  padding-left: 45px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.address_bi {
  color: #f0142f;
  margin: 0 15px 0 6px;
}
.address_specific {
  width: 400px;
  height: 40px;
  border-radius: 6px 6px 6px 6px;
}
.map_bto {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 26px;
}
.cancel {
  width: 100px;
  height: 40px;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #d5d7e3;
  text-align: center;
  line-height: 40px;
  font-size: 15px;
  color: #7e84a3;
  margin-right: 30px;
  cursor: pointer;
}
.confim {
  width: 100px;
  height: 40px;
  background: #0058ff;
  text-align: center;
  line-height: 40px;
  color: white;
  font-size: 15px;
  border-radius: 4px 4px 4px 4px;
  cursor: pointer;
}
/* 打卡人员弹窗 */
.diglog_h {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  padding: 24px;
  border-bottom: 1px solid #cfd4e8;
  color: #1a1a1a;
}

.diglog_icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.diglog_operation {
  margin: 10px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dialog-range-body {
  height: 583px;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #d0d0d0;
  margin: 0 10px;
  display: flex;
}

.dialog-range-left {
  width: 50%;
  border-right: 1px solid #d0d0d0;
  padding: 20px;
}

.range_search {
  width: 100%;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #d0d0d0;
  display: flex;
  align-items: center;
  padding-right: 12px;
  box-sizing: border-box;
  justify-content: space-between;
}

.range_search:focus-within {
  border-color: #0096fa;
}

::v-deep .range_search .el-input__inner {
  height: 36px;
  border: none;
}

.range-left-all {
  padding-top: 20px;
  margin-bottom: 10px;
}

.crumbs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  color: #5c5c5c;
}

.range-left-user {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  line-height: 40px;
}

.dialog-range-right {
  padding: 20px;
  width: 50%;
}

.right-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 15px;
  font-size: 15px;
  max-height: 500px;
}
.right-list::-webkit-scrollbar {
  display: none;
}
.right-list2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  line-height: 32px;
  margin-top: 15px;
  font-size: 15px;
  height: 528px;
}
.selectoption_none {
  height: 490px;
  width: 100%;
  font-size: 15px;
  text-align: center;

  line-height: 490px;
}
.selectoption_t {
  height: 36px;
  display: flex;
  justify-content: space-between;
}
.wifi_tips {
  display: flex;
  width: 100%;
  margin-top: 60px;
  margin-bottom: 67px;
  padding-left: 45px;
  box-sizing: border-box;
}
.wifi_tips_left {
  width: 491px;
  border-right: 1px solid #d0d0d0;
  box-sizing: border-box;
}
.wifi_tips_left_title {
  font-size: 15px;
  color: #0058ff;
  margin-bottom: 20px;
}
.wifi_tips_left_content {
  font-size: 15px;
  color: #1a1a1a;
  width: 461px;
  margin-bottom: 10px;
}
.wifi_tips_right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: #1a1a1a;
}
.box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 174px !important;
  height: 174px !important;
  margin-bottom: 7px;
}

/* ------ */
.distribution_checkbox {
  height: 400px;
  overflow: auto;
}

.distribution_checkbox_option {
  display: flex;
  flex-direction: row;
  line-height: 40px;
  font-size: 14px;
}

.distribution_checkbox_link {
  margin-left: auto;
  margin-right: 0px;
  font-size: 12px;
}
.selectItem {
  display: flex;
  align-items: center;
}
::v-deep .el-checkbox__label {
  color: #1a1a1a;
  font-weight: normal;
  font-size: 15px;
  line-height: 40px;
}
::v-deep .selectItem > .el-checkbox__label {
  font-size: 1rem;
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.distribution_checkbox_link {
  margin-left: auto;
  margin-right: 0px;
  font-size: 12px;
}
.selectoption_single {
  background: #e0ecff;
  border-radius: 4px;
  padding: 6px;
  color: #0058ff;
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-right: 16px;
  margin-bottom: 5px;
  line-height: 30px;
}

.selectoption_none {
  height: 490px;
  width: 100%;
  font-size: 15px;
  text-align: center;

  line-height: 490px;
}
.available_frame {
  width: 797px;
  height: 486px;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #d0d0d0;
  margin: 30px auto;
  display: flex;
}
.available_frame_left {
  width: 346px;
  height: 100%;
  border-right: 1px solid #d0d0d0;
}
.available_title {
  width: 100%;
  height: 45px;
  background: #ededed;
  padding-left: 20px;
  box-sizing: border-box;
  line-height: 45px;
  color: #1a1a1a;
  border-radius: 3px 0px 0px 0px;
}
.available_frame_center {
  width: 224px;
  height: 100%;
  border-right: 1px solid #d0d0d0;
}
.available_frame_right {
  flex: 1;
  height: 100%;
}
.available_search {
  width: 307px;
  margin: 15px auto 0;
  height: 36px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #d0d0d0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 16px;
  box-sizing: border-box;
}
.available_search_icon {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.available_search_input {
  height: 98%;
}
::v-deep .available_search .el-input__inner {
  height: 100%;
  border: none;
}
.breadcrumbs {
  display: flex;
  margin: 20px 0;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 20px;
  box-sizing: border-box;
}
.breadcrumbs_text {
  cursor: pointer;
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.breadcrumbs_current {
  color: #1a1a1a;
}
.breadcrumbs_last {
  color: rgba(26, 26, 26, 0.6);
}
.available_depa {
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
  cursor: pointer;
}
.available_depa_left {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #1a1a1a;
}
.available_depa_left_icon {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #cbe7ff;
  margin-right: 10px;
}
.available_depa_left_icon2 {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #cbe7ff;
  margin: 0 8px 0 0;
}
.available_depa_left_icon img {
  width: 18px;
  height: 18px;
}
.available_depa_left_icon2 img {
  width: 18px;
  height: 18px;
}
.available_depa_left_next {
  color: #0058ff;
  font-size: 15px;
  cursor: pointer;
}
.available_frame_tobeselected {
  margin-top: 12px;
}
.available_frame_tobeselected_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: rgba(26, 26, 26, 0.6);
  padding: 0 12px;
  box-sizing: border-box;
}
.available_frame_tobeselected_delte {
  font-size: 14px;
  color: rgba(26, 26, 26, 0.6);
  padding: 0 12px;
  text-align: right;
  cursor: pointer;
}
::v-deep .available_frame_tobeselected_top .el-checkbox__label {
  font-size: 14px;
  color: rgba(26, 26, 26, 0.6);
  font-weight: normal;
}
.available_frame_tobeselected_center {
  margin-top: 15px;
  height: 350px;
  overflow-y: scroll;
}
.available_frame_tobeselected_center::-webkit-scrollbar {
  display: none;
}
.available_frame_tobeselected_single {
  display: flex;
  height: 56px;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
}
.available_frame_tobeselected_single2 {
  display: flex;
  height: 56px;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
}
.available_frame_tobeselected_name {
  font-size: 15px;
  color: #1a1a1a;
}
.available_frame_tobeselected_depa {
  font-size: 13px;
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: rgba(26, 26, 26, 0.6);
}
.available_frame_select_left {
  display: flex;
  align-items: center;
}
.available_frame_select_right {
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.available_frame_bto {
  display: flex;
  align-items: center;
  justify-content: center;
}
.available_frame_cancel {
  width: 100px;
  height: 40px;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #d5d7e3;
  font-size: 15px;
  color: #7e84a3;
  margin-right: 30px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}
.available_frame_comfin {
  width: 100px;
  height: 40px;
  background: #0058ff;
  border-radius: 4px 4px 4px 4px;
  font-size: 15px;
  color: white;
  text-align: center;
  cursor: pointer;
}
.breadcrumbs_all {
  display: flex;
  align-items: center;
}
.newpagination {
  height: 50px;
  margin-top: 10px;
}
.available_depaall {
  height: 330px;
  overflow-y: scroll;
}
::v-deep .available_search .el-select {
  width: 120px;
  border-right: 1px solid #d0d0d0;
}
</style>