<template>
  <div>
    <div class="batch_t">
      <div class="branch_l">
        <img
          src="@/assets/structure/close.png"
          class="batch_close"
          @click="close"
        />
        <div>员工离职</div>
      </div>
      <div class="branch_bto">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="switcOperation">确认</el-button>
      </div>
    </div>

    <div class="batch_c">
      <el-form
        ref="batch"
        label-position="top"
        label-width="80px"
        :model="formLabelAlign"
        :rules="rules"
      >
        <el-form-item label="离职类型" prop="leaveType">
          <el-select v-model="formLabelAlign.leaveType" style="width: 100%">
            <el-option
              v-for="item in resignationList"
              :key="item.index"
              :label="item.name"
              :value="item.index"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="离职时间" prop="timeQuit">
          <el-date-picker
            v-model="formLabelAlign.timeQuit"
            value-format="yyyy-MM-dd hh:mm:ss"
            type="date"
            style="width: 100%"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="经办人" prop="parentDepartment">
          <div class="handler">{{ handler.name }}</div>
        </el-form-item>
        <el-form-item label="离职原因" prop="leaveReason">
          <el-input
            type="textarea"
            :rows="6"
            maxlength="30"
            style="width: 100%"
            placeholder="请输入内容"
            v-model="formLabelAlign.leaveReason"
          >
          </el-input>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import http from "../../../../utils/http";
export default {
  data() {
    return {
      title: "新建部门",
      searchData: [],
      loading: false,
      handler: {}, //经办人
      formLabelAlign: {}, //提交的表单
      rules: {
        leaveType: [
          { required: true, message: "请选择离职类型", trigger: "change" },
        ],
        timeQuit: [
          {
            required: true,
            message: "请选择离职时间",
            trigger: "change",
          },
        ],
      },
      resignationList: [
        {
          name: "自离",
          index: 0,
        },
        {
          name: "辞退",
          index: 1,
        },
        {
          name: "其它",
          index: 3,
        },
      ], //离职类型
      customerList: [],
      defaultProps: {
        children: "subDepartment",
        label: "departmentName",
      },
      selectTreeName: "",
      selectTree: "",
    };
  },
  props: ["selectId"],
  created() {
    var userinfo = localStorage.getItem("userinfo");
    if (userinfo) {
      userinfo = JSON.parse(userinfo);
      this.handler = userinfo;
    }
  },
  methods: {
    // 关闭弹窗
    close() {
      this.$refs.batch.resetFields();
      this.$emit("close");
    },

    // 确认弹窗
    comfin() {
      this.$refs.batch.resetFields();
      this.$emit("comfin");
    },

    // 选择上级部门点击方法
    addAdminHandleNodeClick(e) {
      this.selectTreeName = e.departmentName;
      this.formLabelAlign.parentDepartment = e.departmentId;
      this.$refs.multiSelect.blur();
    },

    // 新建还是编辑
    switcOperation() {
      this.$refs["batch"].validate((valid) => {
        if (valid) {
          var obj = JSON.stringify(this.formLabelAlign);
          obj = JSON.parse(obj);
          obj.userUpdateType = "LEAVE";
          if (typeof this.selectId == "string") {
            obj.ids = this.selectId.split(",").map(Number);
          } else {
            obj.ids = [this.selectId];
          }
          obj.leaveUserExec = this.handler.id;
          if (obj.leaveReason == "" || !obj.leaveReason) {
            obj.leaveReason = " ";
          }
          obj.status = -1;
          http.post("/umsUser/update", obj).then((res) => {
            if (res.code == 200) {
              this.$message.success("操作成功");
              this.comfin();
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.branch_l {
  display: flex;
  align-items: center;
}
.batch_t {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
  box-sizing: border-box;
  padding-left: 20px;
  font-size: 18px;
  color: #1a1a1a;
  border-bottom: 1px solid #cfd4e8;
}
.batch_close {
  width: 24px;
  cursor: pointer;
  height: 24px;
  margin-right: 20px;
}
.batch_c {
  padding: 0 30px;
  box-sizing: border-box;
  margin-top: 40px;
}
.department_search_inp {
  width: 100%;
  border: 1px solid #dcdfe6;
}
::v-deep .depa .el-select-dropdown {
  top: 330px !important;
}
::v-deep .batch_c .el-autocomplete {
  width: 100%;
}
.handler {
  width: 100%;
  padding-left: 12px;
  box-sizing: border-box;
  font-size: 16px;
  color: #1a1a1a;
  border-radius: 4px;
  height: 40px;
  border: 1px solid #d0d0d0;
}
</style>
