<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: "App",
};
</script>

<style>
html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}
body::-webkit-scrollbar {
  display: none;
}
#app {
  font-family: PingFang SC-Medium, PingFang SC;
  width: 100%;
  height: 100%;
  font-size: 14px;
}
</style>
