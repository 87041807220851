<template>
  <div>
    <div class="diglog_h">
      <div>{{ title }}</div>
      <img
        src="@/assets/structure/close.png"
        class="diglog_icon"
        @click="closeDiglog"
      />
    </div>
    <div class="delectdiglog">
      <div class="delectdiglog_text">审批意见</div>
      <el-input
        type="textarea"
        clear="delectdiglog_view"
        maxlength="50"
        :rows="5"
        v-model="textarea"
      >
      </el-input>
    </div>

    <div class="diglog_operation">
      <el-button @click="closeDiglog">取消</el-button>
      <el-button type="primary" @click="affirmDiglog">确认</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title"],
  data() {
    return {
      textarea: "", //审核内容
    };
  },
  methods: {
    // 关闭弹窗
    closeDiglog() {
      this.$emit("closeDiglog");
    },
    // 确认弹窗
    affirmDiglog() {
      this.$emit("affirmDiglog", this.textarea);
    },
  },
};
</script>

<style scoped>
.delectdiglog {
  width: 100%;
  margin-top: 20px;
  padding: 26px 24px;
  box-sizing: border-box;
  border-top: 1px solid #cfd4e8;
}
.delectdiglog_text {
  font-size: 16px;
  color: #1a1a1a;
  box-sizing: border-box;
  margin-bottom: 8px;
}
.diglog_h {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  padding: 24px 24px 0 24px;
  color: #1a1a1a;
}
.diglog_icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.diglog_operation {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
