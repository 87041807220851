<template>
  <div>
    <div class="batch_t">
      <div class="branch_l">
        <img
          src="@/assets/structure/close.png"
          class="batch_close"
          @click="close"
        />
        <div>外出</div>
      </div>
      <div class="branch_bto">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="switcOperation">确认</el-button>
      </div>
    </div>

    <div class="batch_c">
      <el-form
        ref="batch"
        label-position="top"
        label-width="80px"
        :model="formLabelAlign"
        :rules="rules"
      >
        <el-form-item label="员工" prop="ids">
          <div class="unableChange">{{ name.join(",") }}</div>
        </el-form-item>
        <el-form-item label="外出地址" prop="address">
          <el-input v-model="formLabelAlign.address"></el-input>
        </el-form-item>
        <el-form-item label="开始时间" prop="timeBegin">
          <el-date-picker
            v-model="formLabelAlign.timeBegin"
            @change="isWithintheshift"
            format="yyyy-MM-dd HH:mm"
            type="datetime"
            style="width: 100%"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间" prop="timeEnd">
          <el-date-picker
            v-model="formLabelAlign.timeEnd"
            @change="isWithintheshift"
            format="yyyy-MM-dd HH:mm"
            type="datetime"
            style="width: 100%"
          >
          </el-date-picker>
        </el-form-item>
        <div v-if="isError" style="color: red">{{ error }}</div>
        <el-form-item label="外出时长(小时)" prop="ids">
          <div class="unableChange">{{ difference }}</div>
        </el-form-item>
        <el-form-item label="外出事由" prop="description">
          <el-input
            type="textarea"
            :rows="5"
            v-model="formLabelAlign.description"
            maxlength="100"
            show-word-limit
          >
          </el-input>
        </el-form-item>
        <el-form-item label="审批人">
          <div class="unableChange">{{ approvedBy }}</div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import http from "../../../../utils/http";
export default {
  data() {
    return {
      formLabelAlign: {}, //提交的表单
      difference: "", //小时差
      handler: {}, //经办人
      approvedBy: "", //审批人
      isError: false, //是否可以提交
      error: "", //错误提示
      submitObject: {}, //考勤数据
      rules: {
        ids: [
          {
            type: "array",
            required: true,
            message: "请选择至少一个用户",
            trigger: "blur",
          },
        ],
        address: [
          { required: true, message: "请输入外出地址", trigger: "blur" },
        ],
        timeBegin: [
          {
            type: "date",
            required: true,
            message: "请选择开始时间",
            trigger: "change",
          },
        ],
        timeEnd: [
          {
            type: "date",
            required: true,
            message: "请选择结束时间",
            trigger: "change",
          },
        ],
        description: [
          { required: true, message: "请输入外出事由", trigger: "blur" },
        ],
      },
      customerList: [],
    };
  },
  props: ["name", "id"],
  created() {
    this.formLabelAlign.ids = this.id;

    var user = localStorage.getItem("userinfo");
    user = JSON.parse(user);
    this.formLabelAlign.userExec = user.id;
    this.approvedBy = user.name;
  },
  methods: {
    // 关闭弹窗
    close() {
      this.$refs.batch.resetFields();
      this.$emit("close");
    },

    // 确认弹窗
    switcOperation() {
      this.$refs.batch.validate((valid) => {
        if (valid) {
          var value = JSON.stringify(this.formLabelAlign);
          value = JSON.parse(value);
          value.timeBegin = this.formatDate(value.timeBegin);
          value.timeEnd = this.formatDate(value.timeEnd);
          value.type = 4;
          value.status = 1;
          value.timeExec = this.getCurrentDate();

          if (this.isError) {
            return;
          }

          http.post("/bmsApply/add", value).then((res) => {
            if (res.code == 200) {
              this.$message.success("登记成功");
              this.close();
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          return false;
        }
      });
    },
    // 获取当前时间
    getCurrentDate() {
      var now = new Date();

      // 获取年份
      var year = now.getFullYear();

      // 获取月份（注意：月份是从0开始的，所以我们需要加1）
      var month = now.getMonth() + 1;
      month = month < 10 ? "0" + month : month; // 如果月份是一位数，前面补零

      // 获取日期
      var date = now.getDate();
      date = date < 10 ? "0" + date : date; // 如果日期是一位数，前面补零

      // 获取小时
      var hours = now.getHours();
      hours = hours < 10 ? "0" + hours : hours; // 如果小时是一位数，前面补零

      // 获取分钟
      var minutes = now.getMinutes();
      minutes = minutes < 10 ? "0" + minutes : minutes; // 如果分钟是一位数，前面补零
      // 秒
      var seconds = now.getSeconds();
      seconds = seconds < 10 ? "0" + seconds : seconds;

      // 将获取到的年月日时分拼接成字符串
      var currentDateTime =
        year +
        "-" +
        month +
        "-" +
        date +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds;
      return currentDateTime;
    },
    // 判断时间在不在班次内
    isWithintheshift() {
      if (this.formLabelAlign.timeBegin && this.formLabelAlign.timeEnd) {
        var beginTime = this.formatDate(this.formLabelAlign.timeBegin);
        var endTime = this.formatDate(this.formLabelAlign.timeEnd);
        if (beginTime && endTime) {
          if (endTime < beginTime) {
            this.error = "结束时间必须大于开始时间";
            this.isError = true;
            return;
          }
          let array = beginTime.split(" ");
          http
            .get("/bmsAttendanceRecord/search", {
              user: this.id[0],
              beginDay: array[0],
              endDay: array[0],
              pageNum: 1,
              pageSize: 10,
            })
            .then((res) => {
              if (res.code == 200) {
                var arr = res.data.list;
                if (arr.length > 0) {
                  if (arr[0].arrange > 0) {
                    var attendance = arr[0].bmsAttendanceArrangeGetVO;
                    var arrK = [];
                    attendance.arrangeDataList.map((item) => {
                      arrK.push(item.clockData);
                    });

                    let array = beginTime.split(" ");
                    let array2 = endTime.split(" ");

                    // 判断是不是同一天
                    if (array[0] == array2[0]) {
                      var start = this.timeToSeconds(array[1]);
                      var end = this.timeToSeconds(array2[1]);

                      var isStart = this.isWithinRange(start, arrK);
                      var isEnd = this.isWithinRange(end, arrK);

                      if (!isStart || !isEnd) {
                        this.error = "申请时间必须是班次时间内";
                        this.isError = true;
                        return;
                      } else {
                        this.timeDifference(beginTime, endTime);
                        this.isError = false;
                      }
                    } else {
                      var start = this.timeToSeconds(array[1]);
                      var end = this.timeToSeconds(array2[1]);
                      end = end + 86400;
                      var isStart = this.isWithinRange(start, arrK);
                      var isEnd = this.isWithinRange(end, arrK);
                      if (!isStart || !isEnd) {
                        this.error = "申请时间必须是班次时间内";
                        this.isError = true;
                        return;
                      } else {
                        this.timeDifference(beginTime, endTime);
                        this.isError = false;
                      }
                    }
                    this.timeDifference(beginTime, endTime);
                    this.submitObject = arr[0];
                    this.isError = false;
                  } else if (arr[0].arrange == -4) {
                    this.timeDifference(beginTime, endTime);
                    this.submitObject = arr[0];
                    this.isError = false;
                  } else {
                    this.error = "非工作日不能申请外出";
                    this.isError = true;
                  }
                } else {
                  this.error = "当前无排班信息";
                  this.isError = true;
                }
              }
            });
        }
      }
    },
    // 查看时间在不在任何一个范围内
    isWithinRange(number, ranges) {
      for (var i = 0; i < ranges.length; i++) {
        var range = ranges[i];
        if (number >= range.beginDaySecond && number <= range.endDaySecond) {
          return true;
        }
      }
      return false;
    },
    // 时间转成秒来显示
    timeToSeconds(timeString) {
      const [hours, minutes] = timeString.split(":").map(Number);
      const seconds = hours * 3600 + minutes * 60;
      return seconds;
    },
    // 判断是否是空对象
    isEmptyObject(obj) {
      // 检查参数是否是对象
      if (typeof obj !== "object" || obj === null) {
        return false;
      }
      // 使用Object.keys()方法获取对象的所有键，并检查长度是否为0
      return Object.keys(obj).length === 0;
    },
    // 标准时间转换成yyyy-mm-dd
    formatDate(standardTime) {
      const date = new Date(standardTime);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    // 判断两个时间差多少
    timeDifference(date1, date2) {
      // 获取两个时间的时间戳（假设时间格式为 "YYYY-MM-DD HH:mm:ss"）
      var time1 = new Date(date1).getTime(); // 第一个时间
      var time2 = new Date(date2).getTime(); // 第二个时间

      var time = 0;

      if (time2 < time1) {
        time = "0";
      } else {
        // 计算两个时间的时间戳差值（单位：毫秒）
        var timeDiff = Math.abs(time2 - time1);

        // 将毫秒转换为小时
        var hoursDiff = timeDiff / (1000 * 60 * 60);

        time = hoursDiff.toFixed(1);
      }

      this.difference = time;
    },
  },
};
</script>

<style scoped>
.branch_l {
  display: flex;
  align-items: center;
}
.batch_t {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
  box-sizing: border-box;
  padding-left: 20px;
  font-size: 18px;
  color: #1a1a1a;
  border-bottom: 1px solid #cfd4e8;
}
.batch_close {
  width: 24px;
  cursor: pointer;
  height: 24px;
  margin-right: 20px;
}
.batch_c {
  padding: 0 30px;
  box-sizing: border-box;
  margin-top: 40px;
  overflow-y: scroll;
}
.batch_c::-webkit-scrollbar {
  display: none;
}
.department_search_inp {
  width: 100%;
  border: 1px solid #dcdfe6;
}
::v-deep .depa .el-select-dropdown {
  top: 330px !important;
}
::v-deep .batch_c .el-autocomplete {
  width: 100%;
}
.unableChange {
  width: 413px;
  height: 40px;
  border: 1px solid #d0d0d0;
  padding-left: 17px;
  box-sizing: border-box;
  line-height: 40px;
  font-size: 16px;
  color: #1a1a1a;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
