<template>
  <div class="member">
    <div :class="[sidebarShow ? 'member_l_zoom' : 'member_l']">
      <img
        v-if="sidebarShowIcon"
        src="@/assets/structure/zoom.png"
        class="membel_zoom"
        @click="translation"
      />
      <img
        v-else
        src="@/assets/structure/zoomr.png"
        class="membel_zoom_r"
        @click="translation"
      />
      <div v-show="sidebarShoworder" class="member_l_meum">
        <div class="search">
          <div class="search_input">
            <div class="member_search">
              <el-select
                ref="clearValue"
                v-model="searchValue"
                filterable
                remote
                :loading="loading"
                @change="clickResult"
                class="department_search_inp"
                style="width: 200px"
                placeholder="请搜索部门"
                :remote-method="remoteMethod"
              >
                <el-option
                  v-for="item in searchData"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <img :src="searchicon" class="searchicon" @click="clearup" />
            </div>
          </div>
        </div>
        <div v-if="normalxi">
          <div class="tree_case">
            <el-tree
              ref="elTree"
              v-if="refreshTree"
              :node-key="treeKey"
              :data="departmentList"
              :default-expand-all="isExpand"
              :expand-on-click-node="false"
              :props="defaultProps"
              @node-click="handleNodeClick"
            >
              <span class="custom-tree-node" slot-scope="{ node }">
                <div class="tree_sinle">
                  <div class="tree_sinle_a">
                    <img
                      src="@/assets/structure/plan.png"
                      class="tree_sinle_icon"
                    />
                    <div
                      :class="[
                        'tree_sinle_l',
                        ischangec == node.data.id ? 'selectColor' : '',
                      ]"
                    >
                      {{ node.label }}
                    </div>
                  </div>
                  <span>
                    <el-popover
                      :key="node.id"
                      :ref="'popoverRef_' + node.id"
                      placement="bottom-end"
                      width="144"
                      trigger="manual"
                      v-model="node.isclick"
                    >
                      <div>
                        <div
                          v-for="item in options"
                          :key="item.label"
                          @click.stop="depamentshiftOperation(item.value)"
                          class="deptoption"
                        >
                          {{ item.label }}
                        </div>
                      </div>
                      <img
                        :src="ischangec == node.data.id ? multi : multiblack"
                        class="tree_multi"
                        slot="reference"
                        @click.stop="clickEvent(node)"
                      />
                    </el-popover>
                  </span>
                </div>
              </span>
            </el-tree>
          </div>
        </div>
        <!-- 搜索结果 -->
        <div v-else>
          <div :class="['member_company', 'aselect']">
            <span>{{ searchfruit.name }}</span>
            <el-popover
              :key="0"
              ref="popoverRef_998"
              placement="bottom-end"
              width="144"
              trigger="manual"
              v-model="resultClick"
            >
              <div>
                <div
                  v-for="item in resultOption"
                  :key="item.label"
                  @click.stop="depamentshiftOperation(item.value)"
                  class="deptoption"
                >
                  {{ item.label }}
                </div>
              </div>
              <img
                :src="multi"
                class="multi"
                slot="reference"
                @click.stop="resultclickEvent(searchfruit)"
              />
            </el-popover>
          </div>
        </div>
      </div>
    </div>

    <!-- 右侧员工列表 -->
    <div class="member_r">
      <div class="member_r_t">
        <div class="member_r_t_nmae">
          <div
            :class="[sidebarShowIcon ? '' : 'left', 'approval_search']"
            tabindex="1"
          >
            <el-tooltip
              class="item"
              effect="dark"
              content="请输入姓名、员工工号、设备工号、手机号"
              placement="top-start"
            >
              <el-input
                v-model="searchMember"
                @keyup.enter.native="seachmemResult"
                class="department_search_inp"
                placeholder="请输入姓名、员工工号…"
                style="width: 200px"
              >
              </el-input>
            </el-tooltip>
            <img
              src="@/assets/structure/delete.png"
              class="searchicon"
              @click="clearupMember"
              v-if="searchMemicon"
            />
            <img
              src="@/assets/structure/search.png"
              class="searchicon_t"
              @click="seachmemResult"
            />
          </div>
          <div
            v-if="!isFiltrate"
            class="member_r_filter"
            @click="filterpopClick"
          >
            <span>筛选</span>
            <img
              src="@/assets/structure/filter.png"
              class="member_r_filter_icon"
            />
          </div>
          <div v-else>
            <div
              :class="[
                sidebarShowIcon ? '' : 'left',
                'member_r_filter_content',
              ]"
            >
              <span>筛选({{ filtrateNum }})</span>
              <img
                src="@/assets/structure/filter2.png"
                class="member_r_filter_icon"
              />
            </div>
          </div>
        </div>
        <div>
          <el-button
            @click="newlyicreased"
            type="primary"
            icon="el-icon-plus"
            class="structure_addstaff"
            >添加员工</el-button
          >
          <el-button @click="inviteStaff = true">邀请员工</el-button>
          <el-select
            value="更多功能"
            class="memeber_operation"
            @change="batchOperation"
          >
            <el-option
              v-for="item in operate"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div v-if="isFiltrate && Object.values(filtrateResult).length > 0">
        <div class="filtrate">
          <div
            class="filtrate_single"
            v-if="filtrateResult.sex == 0 || filtrateResult.sex == 1"
          >
            <div v-if="filtrateResult.sex == 1">性别:男</div>
            <div v-if="filtrateResult.sex == 0">性别:女</div>
            <img
              src="@/assets/structure/optionclose.png"
              class="filtrate_icon"
              @click="delectscreening(2)"
            />
          </div>
          <div class="filtrate_single" v-if="filtrateResult.timeJoin">
            <div>日期:{{ filtrateResult.timeJoin }}</div>
            <img
              src="@/assets/structure/optionclose.png"
              class="filtrate_icon"
              @click="delectscreening(3)"
            />
          </div>
        </div>
      </div>
      <div class="memeber_table" ref="memeberTable">
        <el-table
          height="100%"
          ref="multipleTable"
          :data="tableData"
          :header-cell-style="getRowClass"
          @selection-change="handleSelectionChange"
          tooltip-effect="dark"
          class="m_table"
        >
          <template slot="empty">
            <div>
              <img src="@/assets/nodata.png" class="nodata" />
              <div>无数据</div>
            </div>
          </template>
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="name" label="姓名" width="250">
            <template slot-scope="scope">
              <div class="member_name">
                <img
                  :src="scope.row.headUrl"
                  class="member_head"
                  v-if="scope.row.headUrl"
                />
                <img src="@/assets/head.png" v-else class="member_head" />
                <div>{{ scope.row.name }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="员工工号" width="130">
            <template slot-scope="scope">
              <div class="ellipsis">
                {{ scope.row.jobNumber }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="deviceNumber" label="设备工号" width="130">
            <template slot-scope="scope">
              <div class="ellipsis">
                {{ scope.row.deviceNumber }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="jobDepartment" label="部门">
            <template slot-scope="scope">
              <div v-if="scope.row.umsDepartment" class="ellipsis">
                {{ scope.row.umsDepartment.name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="生物特征值" width="200">
            <template slot-scope="scope">
              <div class="structure_feature">
                <img
                  src="@/assets/structure/face.png"
                  class="feature"
                  v-if="scope.row.dataFace"
                />
                <img
                  src="@/assets/structure/fingerprint.png"
                  class="feature"
                  v-if="scope.row.dataFinger && !isNull(scope.row.dataFinger)"
                />
                <img
                  src="@/assets/structure/vein.png"
                  class="feature"
                  v-if="scope.row.dataHand"
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-link type="primary" @click="checkInformation(scope.row)"
                >查看</el-link
              >
              <el-link
                type="danger"
                style="margin-left: 24px"
                v-if="scope.row.status != 4"
                @click="resignationUser(scope.row.id)"
                >离职</el-link
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="newpagination" v-if="pages.total > 0">
        <div class="newpagination_text">共 {{ pages.total }} 条数据</div>
        <el-pagination
          layout="prev, pager, next"
          :total="pages.total"
          :current-page="pages.pageNum"
          :page-size="50"
          @current-change="chagePage"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 筛选 -->
    <el-drawer
      :wrapperClosable="false"
      :key="screenKey"
      title=""
      :visible.sync="filterpop"
      :with-header="false"
      size="25%"
    >
      <Scree @close="filterpop = false" @comfin="dressing" />
    </el-drawer>

    <!-- 添加员工 -->
    <el-drawer
      :wrapperClosable="false"
      title=""
      :key="selectEmployees"
      :visible.sync="addpop"
      :with-header="false"
      size="47%"
    >
      <Newemployees
        @close="closeEmploy"
        @comfinEmploy="addUser"
        :id="selectEmployees"
        :deparment="selectEmployeesName"
      />
    </el-drawer>
    <!-- 邀请员工 -->
    <el-dialog
      title=""
      width="45%"
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="inviteStaff"
      class="invite_frame"
    >
      <div class="invite_h">
        <div>邀请员工</div>
        <img
          src="@/assets/structure/close.png"
          class="invite_icon"
          @click="inviteStaff = false"
        />
      </div>
      <el-tabs v-model="activeName">
        <el-tab-pane label="二维码" name="1">
          <div class="qrcode">
            <div class="qrcode_frame">
              <div class="qrcode_name">{{ companyName }}邀请你加入</div>
              <img
                class="qrcode_img"
                :src="`data:image/png;base64,` + inviteWay.qrCode"
              />
            </div>
            <div class="qrcode_tips">成员可通过扫描二维码加入企业</div>
            <div class="qrcode_bto">
              <el-button @click="inviteStaff = false">取消</el-button>
              <el-button type="primary" @click="dowloadImg">保存</el-button>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="链接" name="2">
          <div class="qrcode">
            <div class="link_frame">
              邀请你加入{{ companyName }}，可通过此链接{{ inviteWay.url }}
              加入企业
            </div>
            <div class="link_tips">成员可通过复制链接到微信加入企业</div>
            <div class="link_bto">
              <el-button @click="inviteStaff = false">取消</el-button>
              <el-button
                type="primary"
                v-clipboard:copy="inviteWay.url"
                v-clipboard:success="copyHref"
                >复制</el-button
              >
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>

    <!-- 批量上传 -->
    <el-drawer
      :wrapperClosable="false"
      title=""
      :key="uploadKey"
      :visible.sync="batchUpload"
      :with-header="false"
      size="46%"
    >
      <Upload @close="batchUpload = false" @success="uploadResult" />
    </el-drawer>

    <!-- 新增子部门或者编辑部门 -->
    <el-drawer
      :wrapperClosable="false"
      title=""
      :key="depamentKey"
      :visible.sync="addBranch"
      :with-header="false"
      size="25%"
    >
      <Branch
        :userName="selectDivisionName"
        @close="closeEdit"
        @comfin="Successfully"
        :id="selectDivision"
        :type="branchType"
      />
    </el-drawer>

    <!-- 变更部门 -->
    <el-dialog
      :close-on-click-modal="false"
      :key="alterKey"
      title=""
      width="32%"
      :show-close="false"
      :visible.sync="alterationDiglog"
      class="invite_frame"
    >
      <Alteration
        @close="alterationDiglog = false"
        :ids="multipleSelection"
        @comfin="modiSuccessfully"
      />
    </el-dialog>

    <!-- 删除部门 -->
    <showDialog
      :title="'确认删除部门'"
      :tips="tips"
      :isShow="deleteButton"
      @closeDiglog="canceldelete"
      @affirmDiglog="delectdept"
    />

    <!-- 删除部门提示 -->
    <el-dialog
      title=""
      :visible.sync="errorTips"
      width="33%"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <div class="diglog_h">
        <div>确认删除部门</div>
        <img
          src="@/assets/structure/close.png"
          class="diglog_icon"
          @click="errorTips = false"
        />
      </div>
      <div class="delectdiglog">
        <div class="delectdiglog_title">{{ tips }}</div>
      </div>

      <div class="diglog_operation">
        <el-button type="primary" @click="errorTips = false">确认</el-button>
      </div>
    </el-dialog>

    <!-- 员工离职弹窗 -->
    <el-drawer
      :wrapperClosable="false"
      title=""
      :key="resignationKey"
      :visible.sync="resignationDialog"
      :with-header="false"
      size="25%"
    >
      <Resignation
        @close="closeResignation"
        @comfin="resignationSuccess"
        :selectId="selectEmployees"
      />
    </el-drawer>

    <!-- 批量修改 -->
    <el-drawer
      :wrapperClosable="false"
      title=""
      :key="modificationKey"
      :visible.sync="modificationDialog"
      :with-header="false"
      size="25%"
    >
      <Edit
        @close="closebatchModification"
        @comfin="modificationSuccess"
        :selectId="selectEmployees"
      />
    </el-drawer>
  </div>
</template>

<script>
import Diglog from "../../../assembly/dialog.vue";
import Scree from "../module/member/scree.vue";

import Newemployees from "../module/member/newemployees.vue";
import Upload from "../module/member/upload.vue";
import Distribution from "../module/member/distribution.vue";
import Resignation from "../module/member/resignation.vue";
import Edit from "../module/member/edit.vue";

// 部门
import Branch from "../module/branch/newbranch.vue";
import showDialog from "../../../assembly/dialog.vue";
import Alteration from "../module/member/alterationdept.vue";

import http from "../../../utils/http";

import searchIcon from "../../../assets/structure/search.png";
import deleteIcon from "../../../assets/structure/delete.png";

import exprotExcel from "../../../utils/exprotExcel";
import multiblack from "../../../assets/structure/multiblack.png";
import multi from "../../../assets/structure/multi.png";

export default {
  data() {
    return {
      resignationKey: Math.random(),
      screenKey: Math.random(),
      uploadKey: Math.random(),
      alterKey: Math.random(),
      filterpop: false, //筛选按钮
      searchValue: "", //搜索内容
      searchMember: "", //搜索内容
      searchMemicon: false, //删除图片是否显示(员工)
      searchicon: searchIcon, //搜索图标
      sidebarShow: true, //显示隐藏
      sidebarShoworder: true, //延迟1秒隐藏
      sidebarShowIcon: true, //图标显示时间
      resignationDialog: false, //员工离职弹窗
      firstDept: {}, //第一个部门
      depamentKey: Math.random(),
      multiblack,
      multi,

      tips: "确认删除此员工？",

      modificationDialog: false, //批量修改弹窗
      modificationKey: Math.random(), //批量修改key

      addpop: false, //添加员工弹窗
      selectEmployees: null, //所选员工id
      selectEmployeesName: null, //所选员工所在的部门
      filterpop: false, //筛选弹窗
      inviteStaff: false, //邀请员工弹窗
      batchUpload: false, //批量上传弹窗
      activeName: "1", //当前目录，1是二维码，2是链接

      multipleSelection: [], //多选操作
      multipleSelectionCopy: [], //多选操作，不做编译
      refsName: 999, //ref控制显示隐藏

      inviteWay: {}, //邀请方式

      companyName: "", //企业名称
      companyId: null, //企业id
      departmentList: [], //部门列表
      isExpand: true, //是否默认展开全部节点
      refreshTree: true, //动态渲染
      ischangec: "", //公司样式
      treeKey: "0",
      defaultProps: {
        children: "subDepartment",
        label: "name",
      },

      isFiltrate: false, //是否进行了筛选
      filtrateResult: {}, //筛选字段
      filtrateNum: 0, //筛选数量

      // 部门操作
      businessClick: false, //企业选择
      resultClick: false, //搜索部门选择
      selectDivision: -1, //选择的部门
      selectDivisionNo: null, //选择的部门编号
      selectDivisionName: "", //所选的部门负责人
      alterationDiglog: false, //变更部门
      value: 1,
      tableData: [],
      pages: {}, //页码
      searchData: [], //搜索列表
      normalxi: true, //是否正常显示树状图
      searchfruit: {}, //搜索结果
      loading: false, //搜索等待
      addBranch: false, //添加子部门或编辑
      branchType: 1, //1是添加，2是子级，3是编辑
      tips: "", //提示
      deleteButton: false, //删除部门
      errorTips: false, //错误提示
      businessOptions: [
        {
          label: "添加子部门",
          value: 2,
        },
      ],
      operate: [
        {
          label: "人员离职",
          value: 1,
        },
        {
          label: "人员调动",
          value: 2,
        },
        {
          label: "人员导入",
          value: 3,
        },
        {
          label: "人员导出",
          value: 4,
        },
        {
          label: "批量修改",
          value: 5,
        },
      ],
      options: [
        {
          label: "编辑部门",
          value: 1,
        },
        {
          label: "添加子部门",
          value: 2,
        },
        {
          label: "删除",
          value: 3,
        },
      ],

      resultOption: [
        {
          label: "编辑部门",
          value: 1,
        },
        {
          label: "添加子部门",
          value: 2,
        },
        {
          label: "删除",
          value: 5,
        },
      ],
      tableColumn: [
        {
          title: "姓名",
          dataIndex: "name",
          id: 1,
        },
        {
          title: "员工工号",
          dataIndex: "jobNumbernew",
          id: 2,
        },
        {
          title: "设备工号",
          dataIndex: "deviceNumber",
          id: 3,
        },
        {
          title: "部门",
          dataIndex: "departmentName",
          id: 4,
        },
        {
          title: "入职日期",
          dataIndex: "timeJoin",
          id: 5,
        },
        {
          title: "出生日期",
          dataIndex: "birthday",
          id: 6,
        },
        {
          title: "性别",
          dataIndex: "sexName",
          id: 7,
        },
        {
          title: "学历",
          dataIndex: "education",
          id: 8,
        },
        {
          title: "籍贯",
          dataIndex: "nativePlace",
          id: 9,
        },
        {
          title: "地址",
          dataIndex: "address",
          id: 10,
        },
        {
          title: "手机号",
          dataIndex: "phone",
          id: 11,
        },
        {
          title: "E-mail",
          dataIndex: "mail",
          id: 12,
        },
        {
          title: "身份证号",
          dataIndex: "idCardnew",
          id: 13,
        },
        {
          title: "职位",
          dataIndex: "job",
          id: 14,
        },
        {
          title: "卡号",
          dataIndex: "jobCard",
          id: 15,
        },
        {
          title: "设备密码",
          dataIndex: "devicePassword",
          id: 16,
        },
        {
          title: "车牌号",
          dataIndex: "carNumber",
          id: 17,
        },
        {
          title: "备注",
          dataIndex: "remark",
          id: 18,
        },
      ], //导出内容参数
    };
  },
  components: {
    Diglog,
    Scree,
    Upload,
    Distribution,
    Newemployees,
    Branch,
    showDialog,
    Alteration,
    Resignation,
    Edit,
  },

  watch: {
    searchValue(newName, oldName) {
      if (newName) {
        this.searchicon = deleteIcon;
      } else {
        this.searchicon = searchIcon;
      }
    },

    searchMember(newName, oldName) {
      if (newName) {
        this.searchMemicon = true;
      } else {
        this.searchMemicon = false;

        // 获取员工列表
        this.getUser({
          pageNum: 1,
        });
      }
    },

    filtrateNum(newName, oldName) {
      if (newName == 0) {
        this.isFiltrate = false;
      }
    },
  },

  created() {
    // 获取部门列表
    this.getDeptment();

    // 获取员工二维码和链接
    this.getInviteway();
  },

  // 点击任何地方取消下拉
  updated() {
    var that = this;
    that.$nextTick(() => {
      document.onclick = function () {
        if (that.$refs[`popoverRef_${that.refsName}`]) {
          that.$refs[`popoverRef_${that.refsName}`].doClose();
        }
      };
    });
  },

  methods: {
    // 平移动画
    translation() {
      this.sidebarShow = !this.sidebarShow;
      setTimeout(() => {
        this.sidebarShoworder = !this.sidebarShoworder;
      }, 100);

      // 图标变化
      setTimeout(() => {
        this.sidebarShowIcon = !this.sidebarShowIcon;
      }, 300);
    },

    // 表头背景颜色变色
    getRowClass({ rowIndex, columnIndex }) {
      if (rowIndex == 0) {
        return "background:#F5F6FA;color:#5C5C5C";
      }
    },

    // 多选操作
    handleSelectionChange(val) {
      this.multipleSelection = JSON.stringify(val);
      this.multipleSelectionCopy = val;
    },

    // ----------------------------员工---------------------------

    // 获取邀请员工的二维码和链接
    getInviteway() {
      http.get("/umsUser/get/invite").then((res) => {
        if (res.code == 200) {
          this.inviteWay = res.data;
        }
      });
    },

    // 获取员工列表
    getUser(value) {
      var object = {};
      if (this.selectDivision == this.firstDept.id && !this.searchValue) {
        object = {};
      } else {
        object = {
          department: this.selectDivision,
        };
      }
      http
        .get(
          "/umsUser/list",
          Object.assign(
            {
              pageSize: 50,
              typeList: "2,3,4",
            },
            value,
            object
          )
        )
        .then((res) => {
          if (res.code == 200) {
            this.pages = res.data;
            this.tableData = res.data.list;
          }
        });
    },

    // 添加员工
    newlyicreased() {
      this.selectEmployees = Math.random() * -1;
      setTimeout(() => {
        this.addpop = true;
      }, 200);
    },

    // 点击公司
    clickCompany() {
      var value = {};
      this.ischangec = this.companyId;
      this.selectDivision = Math.random() * -1;

      this.publicFiltering();
    },

    // 页数改变
    chagePage(e) {
      var value = {};
      if (Object.keys(this.filtrateResult).length === 0) {
        value = {
          pageNum: e,
          likeString: this.searchMember,
        };
      } else {
        value = Object.assign(this.filtrateResult, {
          pageNum: e,
        });
      }
      this.getUser(value);
    },

    // 批量添加
    quantity() {
      this.uploadKey = Math.random();
      setTimeout(() => {
        this.batchUpload = true;
      }, 500);
    },

    // 打开筛选弹窗
    filterpopClick() {
      this.screenKey = Math.random();
      setTimeout(() => {
        this.filterpop = true;
      }, 200);
    },

    // 保存图片
    dowloadImg() {
      // 这里是获取到的图片base64编码,这里只是个例子哈，要自行编码图片替换这里才能测试看到效果
      const imgUrl = `data:image/png;base64,` + this.inviteWay.qrCode;
      // 如果浏览器支持msSaveOrOpenBlob方法（也就是使用IE浏览器的时候），那么调用该方法去下载图片
      if (window.navigator.msSaveOrOpenBlob) {
        const bstr = atob(imgUrl.split(",")[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        const blob = new Blob([u8arr]);
        window.navigator.msSaveOrOpenBlob(blob, "邀请员工二维码" + "." + "png");
      } else {
        // 这里就按照chrome等新版浏览器来处理
        const a = document.createElement("a");
        a.href = imgUrl;
        a.setAttribute("download", "邀请员工二维码");
        a.click();
      }
    },
    // 复制链接
    copyHref() {
      this.$message.success("复制成功");
    },

    // 筛选员工
    dressing(value) {
      this.filterpop = false;
      this.filtrateNum = 0;

      this.isFiltrate = true;
      this.filtrateResult = value;

      if (value.sex == 0 || value.sex == 1) {
        this.filtrateNum++;
      }

      if (value.timeJoin) {
        this.filtrateNum++;
      }

      // 清空输入框
      this.searchMember = "";

      value.pageNum = 1;

      this.getUser(value);
    },

    // 筛选员工(姓名，身份证，工号筛选)
    seachmemResult() {
      // 清空筛选框
      this.filtrateResult = {};
      this.filtrateNum = 0;
      this.isFiltrate = false;

      this.getUser({
        pageNum: 1,
        likeString: this.searchMember,
      });
    },

    // 清除员工筛选
    clearupMember() {
      this.searchMember = "";
    },

    // 删除某个筛选(1是部门，2是性别，3是入职日期)
    delectscreening(value) {
      switch (value) {
        case 1:
          delete this.filtrateResult.departmentIdList;
          delete this.filtrateResult.name;

          this.dressing(this.filtrateResult);
          break;
        case 2:
          delete this.filtrateResult.sex;
          this.dressing(this.filtrateResult);
          break;
        case 3:
          delete this.filtrateResult.timeJoin;
          this.dressing(this.filtrateResult);
          break;
      }
    },

    // 新增员工
    addUser() {
      this.addpop = false;
      this.selectEmployees = null;
      // 重新获取员工列表
      this.publicFiltering();
    },

    // 查看员工信息
    checkInformation(value) {
      this.selectEmployees = value.id;
      if (value.umsDepartment) {
        this.selectEmployeesName = value.umsDepartment.name;
      } else {
        this.selectEmployeesName = "";
      }
      setTimeout(() => {
        this.addpop = true;
      }, 200);
    },

    // 关闭员工信息或者添加员工
    closeEmploy() {
      this.selectEmployees = null;
      this.addpop = false;
    },

    // 批量操作
    batchOperation(val) {
      // 1是批量删除
      switch (val) {
        case 1:
          if (this.multipleSelectionCopy.length == 0) {
            this.$message.error("请先选择人员");
            return;
          }
          this.resignationUser(this.multipleSelectionCopy);
          break;
        case 2:
          if (this.multipleSelectionCopy.length == 0) {
            this.$message.error("请先选择人员");
            return;
          }
          this.alterationDept();
          break;
        case 3:
          this.quantity();
          break;
        case 4:
          if (this.multipleSelectionCopy.length == 0) {
            this.$message.error("请先选择人员");
            return;
          }
          // 人员导出
          this.startDownload(this.multipleSelectionCopy);
          break;
        case 5:
          if (this.multipleSelectionCopy.length == 0) {
            this.$message.error("请先选择人员");
            return;
          }
          // 批量修改
          this.batchModification(this.multipleSelectionCopy);
          break;
      }
    },

    // 批量修改
    batchModification(ids) {
      this.modificationKey = Math.random();
      if (Array.isArray(ids)) {
        var arr = [];
        ids.map((item) => {
          arr.push(item.id);
        });
        this.selectEmployees = arr.join(",");
      } else {
        this.selectEmployees = ids;
      }
      setTimeout(() => {
        this.modificationDialog = true;
      }, 500);
    },

    // 关闭批量修改弹窗
    closebatchModification() {
      this.modificationDialog = false;
    },

    // 成功批量修改
    modificationSuccess() {
      // 重新获取人员列表
      this.publicFiltering();
      this.modificationDialog = false;
    },

    // 表格下载之前
    startDownload(arr) {
      let arr2 = [];
      for (let key of this.tableColumn) {
        arr2.push(key.key);
      }
      // const loading = this.$loading({
      //   lock: true,
      //   text: "导出表格中，请稍后...",
      //   spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.7)",
      // });
      arr.map((v) => {
        if (v.jobNumber) {
          v.jobNumbernew = "\xa0" + v.jobNumber;
        }
        if (v.idCard && v.idCard != " ") {
          v.idCardnew = "\xa0" + v.idCard;
        }
        v.departmentName = v.umsDepartment.name;
        if (v.timeJoin) {
          v.timeJoin = this.hadleDate(v.timeJoin);
        }
        if (v.birthday) {
          v.birthday = this.hadleDate(v.birthday);
        }
        if (v.sex) {
          if (v.sex == 0) {
            v.sexName = "女";
          } else {
            v.sexName = "男";
          }
        }
        for (let i = 0; i < arr2.length; i++) {
          if (v[arr2[i]] == undefined) {
            v[arr2[i]] = "";
          }
        }
        for (let key in v) {
          if (v[key] == null) v[key] = "";
        }
      });
      exprotExcel.export(this.tableColumn, arr, "人员列表");
      this.$message.success("导出成功");
      this.$refs.multipleTable.clearSelection();
    },

    // 处理日期
    hadleDate(value) {
      if (value) {
        var arr = value.split(" ");
        return arr[0];
      }
    },

    // 员工离职
    resignationUser(ids) {
      var iftrue = false;
      this.resignationKey = Math.random();
      if (Array.isArray(ids)) {
        var arr = [];
        ids.map((item) => {
          if (item.status == 4) {
            iftrue = true;
            this.$message.error("超级管理员不能直接离职");
            return;
          }
          arr.push(item.id);
        });
        this.selectEmployees = arr.join(",");
      } else {
        this.selectEmployees = ids;
      }

      if (!iftrue) {
        setTimeout(() => {
          this.resignationDialog = true;
        }, 200);
      }
    },

    // 关闭员工离职弹窗
    closeResignation() {
      this.resignationDialog = false;
    },
    // 成功员工离职弹窗
    resignationSuccess() {
      // 重新获取人员列表
      this.publicFiltering();
      this.resignationDialog = false;
    },

    // 批量上传结束
    uploadResult() {
      this.batchUpload = false;
      this.publicFiltering();
    },

    // 判断是否都是null
    isNull(value) {
      var arr = value.split(",");
      const areAllEmptyOrNullStrings = arr.every(
        (item) => item === "" || item === "null"
      );
      return areAllEmptyOrNullStrings;
    },

    // --------------------部门操作----------------------

    // 获取部门列表
    getDeptment() {
      http.get("/umsDepartment/list").then((res) => {
        if (res.code == 200) {
          // this.companyName = res.data.departmentName;
          // this.ischangec = res.data.departmentName;

          if (res.data) {
            this.departmentList = this.convertToNestedStructure(res.data);
            this.firstDept = res.data[0];

            if (this.departmentList) {
              this.ischangec = res.data[0].id;
              this.companyId = res.data[0].id;
              this.selectDivision = res.data[0].id;

              // 获取员工列表
              this.getUser({
                pageNum: 1,
              });
            }

            localStorage.setItem(
              "department",
              JSON.stringify(this.departmentList)
            );
          }
        }
      });
    },

    // 递归处理数据
    convertToNestedStructure(arr) {
      // 创建一个映射，用于快速查找具有特定id的对象
      const idMap = new Map(arr.map((item) => [item.id, item]));

      // 创建一个新数组来存储转换后的嵌套结构
      const nestedArr = [];

      // 遍历原始数组
      arr.forEach((item) => {
        // 如果该项有父元素，则将其添加到父元素的subDepartment数组中
        if (item.parent) {
          const parent = idMap.get(item.parent);
        if (parent) {
            if (!parent.subDepartment) {
              parent.subDepartment = [];
            }
            item.parentDepament = parent.name;
            parent.subDepartment.push(item);
          }
        } else {
          // 如果没有父元素，则添加到新数组的根级别
          nestedArr.push(item);
        }
      });

      // 返回转换后的嵌套数组
      return nestedArr;
    },
    // 点击部门
    handleNodeClick(data) {
      this.ischangec = data.id;
      this.selectDivision = data.id;
      // 如果含有子部门,直接展开
      this.publicFiltering();
    },
    // 变更部门
    alterationDept() {
      this.alterKey = Math.random();
      setTimeout(() => {
        this.alterationDiglog = true;
      }, 500);
    },
    // 变更部门成功
    modiSuccessfully() {
      // 重新获取人员列表
      this.publicFiltering();

      this.alterationDiglog = false;
    },
    // 部门搜索
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        http
          .get("/umsDepartment/search", {
            departmentName: query,
          })
          .then((res) => {
            if (res.code == 200) {
              this.loading = false;
              this.searchData = res.data;
            }
          });
      } else {
        this.searchData = [];
      }
    },
    // 点击部门搜索结果
    clickResult(id) {
      var that = this;
      this.selectDivision = id;
      // 如果含有子部门,直接展开
      this.publicFiltering();

      http
        .get("/umsDepartment/get", {
          id: id,
        })
        .then((res) => {
          if (res.code == 200) {
            that.searchfruit = res.data;
            that.normalxi = false;
          }
        });
    },
    // 清除输入框
    clearup() {
      this.searchValue = "";
      this.selectDivision = Math.random() * -1;
      var value = "";
      this.normalxi = true;

      this.getDeptment();

      if (Object.keys(this.filtrateResult).length === 0) {
        value = {
          pageNum: 1,
          likeString: this.searchMember,
        };
      } else {
        value = Object.assign(this.filtrateResult, {
          pageNum: 1,
        });
      }

      this.getUser(value);

      this.refreshTree = false;
      this.isExpand = false;
      this.$nextTick(() => {
        this.refreshTree = true;
      });
    },
    // 点击操作方法(企业)
    businessclickEvent() {
      if (this.$refs[`popoverRef_${this.refsName}`]) {
        this.$refs[`popoverRef_${this.refsName}`].doClose();
      }
      this.businessClick = !this.businessClick;
      this.refsName = 999;
      this.selectDivision = Math.random() * -1;
    },
    // 部门操作(企业)
    businessXin() {
      this.businessClick = false;
      this.branchType = 2;
      setTimeout(() => {
        this.addBranch = true;
      }, 200);
    },
    // 点击操作方法
    clickEvent(item) {
      if (this.$refs[`popoverRef_${this.refsName}`]) {
        this.$refs[`popoverRef_${this.refsName}`].doClose();
      }
      item.isclick = !item.isclick;
      this.refsName = item.id;
      if (item.data.umsUser) {
        this.selectDivisionName = item.data.umsUser.name;
      } else {
        this.selectDivisionName = "";
      }
      this.selectDivision = item.data.id;
      this.selectDivisionNo = item.data.no;
    },
    // 点击操作方法(搜索结果)
    resultclickEvent(item) {
      if (this.$refs[`popoverRef_${this.refsName}`]) {
        this.$refs[`popoverRef_${this.refsName}`].doClose();
      }
      this.resultClick = !this.resultClick;
      this.refsName = 998;
      this.selectDivision = item.id;
    },
    // 部门操作
    depamentshiftOperation(value, item) {
      this.$refs[`popoverRef_${this.refsName}`].doClose();

      switch (value) {
        case 1:
          // 编辑部门
          this.branchType = 3;
          this.depamentKey = Math.random();
          setTimeout(() => {
            this.addBranch = true;
          }, 500);
          break;
        case 2:
          // 添加子部门
          this.branchType = 2;
          this.depamentKey = Math.random();
          setTimeout(() => {
            this.addBranch = true;
          }, 500);
          break;
        case 3:
          // 删除
          if (this.selectDivisionNo == 1) {
            this.$message.error("顶级部门无法删除");
          } else {
            this.deletecheckout();
          }
          break;
      }
    },
    // 取消编辑或子级部门操作
    closeEdit() {
      this.selectDivision = -1;
      this.selectDivisionName = "";
      this.addBranch = false;
    },
    // 成功编辑或添加子级
    Successfully() {
      this.selectDivision = -1;
      this.selectDivisionName = "";

      this.addBranch = false;
      // 重新获取部门列表
      this.getDeptment();
    },
    // 删除部门(检验)
    deletecheckout() {
      http
        .post("/umsDepartment/delete", {
          ids: [this.selectDivision],
          operation: 1,
        })
        .then((res) => {
          if (res.code == 200) {
            this.tips = "部门删除后不可恢复，是否继续？";
            this.deleteButton = true;
          } else {
            this.tips = res.message;
            this.errorTips = true;
          }
        });
    },

    // 取消删除部门
    canceldelete() {
      this.selectDivision = -1;
      this.tips = "";
      this.deleteButton = false;
    },

    // 确认删除部门
    delectdept() {
      http
        .post("/umsDepartment/delete", {
          ids: [this.selectDivision],
          operation: 2,
        })
        .then((res) => {
          if (res.code == 200) {
            // 取消弹窗
            this.canceldelete();

            this.normalxi = true;

            // 获取员工列表
            this.publicFiltering();

            // 重新获取部门列表
            this.getDeptment();
            this.$message.success("删除成功");
          } else {
            this.$message.error(res.message);
          }
        });
    },

    // -------------------------------------------------

    // 公用筛选
    publicFiltering() {
      var that = this;
      var value = {};
      if (Object.keys(that.filtrateResult).length === 0) {
        value = {
          pageNum: 1,
          likeString: that.searchMember,
        };
      } else {
        value = Object.assign(that.filtrateResult, {
          pageNum: 1,
        });
      }

      that.getUser(value);
    },
  },
};
</script>

<style scoped>
.member {
  max-width: 100%;
  height: 100%;
  overflow-x: hidden;
  display: flex;
}
.member_l {
  width: 0;
  height: 100%;
  position: relative;
  transition: all 0.5s ease 0ms;
}
.member_l_zoom {
  width: 330px;
  position: relative;
  transition: all 0.5s ease 0ms;
  border-right: 1px solid rgba(207, 212, 232, 0.5);
}
.member_company {
  width: 100%;
  height: 48px;
  display: flex;
  color: #5c5c5c;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
}
.ischangec {
  background: rgba(242, 247, 255, 0.8);
  color: #0058ff;
}
.member_company span {
  font-size: 16px;
}
.multi {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.tree_sinle {
  width: 100%;
  display: flex;
  align-items: center;
  padding-right: 12px;
  box-sizing: border-box;
  justify-content: space-between;
}
.tree_sinle_a {
  display: flex;
  align-items: center;
}
.tree_sinle_l {
  font-size: 16px;
  max-width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #5c5c5c;
  /* color: #0058ff; */
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
.tree_sinle_icon {
  width: 18px;
  height: 18px;
  margin-right: 12px;
}
.tree_multi {
  width: 24px;
  height: 24px;
}
.deptoption {
  height: 40px;
  line-height: 40px;
  padding-left: 10px;
  box-sizing: border-box;
  color: #5c5c5c;
  cursor: pointer;
}
.deptoption:hover {
  background: #edeeee;
}
.tree_multi:hover {
  background: #e2e2e2;
  border-radius: 5px;
}
.multi:hover {
  background: #e2e2e2;
  border-radius: 5px;
}
.search {
  width: 100%;
  height: 72px;
  display: flex;
  padding-top: 22px;
  padding-left: 20px;
  box-sizing: border-box;
}
.search_input {
  width: 243px;
  height: 36px;
}
.membel_zoom {
  width: 28px;
  height: 36px;
  position: absolute;
  top: 22px;
  cursor: pointer;
  right: 0;
}
.membel_zoom_r {
  width: 28px;
  height: 36px;
  position: absolute;
  top: 20px;
  cursor: pointer;
  right: -27px;
}
.member_r {
  flex: 1;
  height: 100%;
  padding: 22px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.member_r_t {
  display: flex;
  height: 60px;
  justify-content: space-between;
  box-sizing: border-box;
}
.member_r_filter {
  display: flex;
  cursor: pointer;
  font-size: 15px;
  color: #b2b2b2;
  width: 98px;
  height: 36px;
  padding-left: 16px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #d0d0d0;
  align-items: center;
}
.member_r_filter_content {
  display: flex;
  cursor: pointer;
  font-size: 15px;
  color: #0058ff;
  width: 119px;
  height: 36px;
  padding-left: 16px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #0058ff;
  align-items: center;
}
.member_r_filter_icon {
  width: 18px;
  height: 18px;
  margin-left: 18px;
}
.memeber_table {
  width: 100%;
  flex: 1;
  position: relative;
}
.qrcode {
  width: 100%;
}
.qrcode_frame {
  width: 457px;
  height: 358px;
  display: flex;
  color: #1a1a1a;
  margin: 60px auto 24px;
  flex-direction: column;
  align-items: center;
  padding-top: 46px;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.16);
}
.qrcode_name {
  font-size: 16px;
  margin-bottom: 30px;
}
.qrcode_img {
  width: 200px;
  height: 200px;
}
.qrcode_tips {
  color: #9a9a9a;
  font-size: 15px;
  text-align: center;
}
.qrcode_bto {
  display: flex;
  margin-top: 39px;
  justify-content: center;
}
.link_frame {
  width: 797px;
  height: 132px;
  margin: 30px;
  font-size: 16px;
  padding: 24px;
  color: #1a1a1a;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.16);
}
.link_tips {
  font-size: 15px;
  color: #9a9a9a;
  padding-left: 30px;
  box-sizing: border-box;
}
.link_bto {
  display: flex;
  margin-top: 289px;
  justify-content: center;
}
.invite_h {
  height: 73px;
  padding: 0 24px;
  color: #1a1a1a;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 18px;
  justify-content: space-between;
}
.invite_icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.member_search {
  width: 243px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #d0d0d0;
  display: flex;
  align-items: center;
  padding-right: 12px;
  box-sizing: border-box;
  justify-content: space-between;
}
.memeber_operation {
  margin-left: 8px;
  width: 107px;
}
.delectdiglog {
  width: 100%;
  margin-top: 20px;
  border-top: 1px solid #cfd4e8;
}
.delectdiglog_title {
  font-size: 16px;
  color: #1a1a1a;
  padding: 30px;
  box-sizing: border-box;
}
.diglog_h {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  padding: 24px 24px 0 24px;
  color: #1a1a1a;
}
.diglog_icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.diglog_operation {
  margin: 60px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.member_name {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #1a1a1a;
}
.member_head {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 10px;
}
.left {
  margin-left: 28px;
}
::v-deep .el-table__body .el-table__row.hover-row td {
  background-color: rgba(0, 98, 255, 0.05);
}
::v-deep .el-tree-node__content:hover {
  background: #f2f7ff;
}
.filtrate {
  display: flex;
  align-items: center;
}
.filtrate_single {
  padding: 6px;
  color: #0058ff;
  font-size: 15px;
  display: flex;
  align-items: center;
  background: #e0ecff;
  margin-right: 10px;
  border-radius: 4px;
}
.filtrate_icon {
  margin-left: 6px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.aselect {
  color: #0058ff;
  background-color: rgba(0, 98, 255, 0.05);
}
.selectColor {
  color: #0058ff;
}
.tree_case {
  flex: 1;
  overflow: auto; /* 添加滚动条 */
  max-height: 500px; /* 设置最大高度 */
}
.tree_case::-webkit-scrollbar {
  display: none;
}
.approval_search {
  width: 243px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #d0d0d0;
  display: flex;
  align-items: center;
  padding-right: 12px;
  box-sizing: border-box;
  margin-right: 10px;
  justify-content: space-between;
}
.member_r_t_nmae {
  display: flex;
  align-items: flex-start;
}
.approval_search:focus-within {
  border-color: #0096fa;
}
.member_search:focus-within {
  border-color: #0096fa;
}
.m_table {
  width: 100%;
  height: 100%;
  margin-top: 16px;
  position: absolute;
}
.structure_feature {
  display: flex;
  align-items: center;
}
.feature {
  width: 30px;
  height: 30px;
  margin-right: 12px;
}
.ellipsis {
  width: 130px;
  white-space: nowrap; /* 防止文本换行 */
  overflow: hidden; /* 隐藏超出容器的文本 */
  text-overflow: ellipsis; /* 显示省略号表示文本被截断 */
}
.member_l_meum {
  display: flex;
  height: 100%;
  flex-direction: column;
}
</style>
