<template>
  <div>
    <div class="batch_t">
      <div class="branch_l">
        <img
          src="@/assets/structure/close.png"
          class="batch_close"
          @click="close"
        />
        <div>{{ title }}</div>
      </div>
      <div class="branch_bto">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="switcOperation">确认</el-button>
      </div>
    </div>

    <div class="batch_c">
      <el-form
        ref="batch"
        label-position="top"
        label-width="80px"
        :model="formLabelAlign"
        :rules="rules"
      >
        <el-form-item label="部门名称" prop="name">
          <el-input v-model="formLabelAlign.name" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="部门编号" prop="no">
          <el-input v-model.number="formLabelAlign.no" maxlength="9"></el-input>
        </el-form-item>
        <el-form-item label="上级部门" prop="parent">
          <el-select
            :disabled="!formLabelAlign.parent"
            clear="depa"
            style="width: 100%"
            ref="multiSelect"
            v-model="selectTreeName"
            placeholder="请选择"
            :popper-append-to-body="false"
          >
            <el-option
              :value="selectTree"
              class="setstyle"
              style="overflow: auto; height: 100%"
              disabled
            >
              <el-tree
                style="min-height: 150px; max-height: 300px"
                :data="customerList"
                :props="defaultProps"
                default-expand-all
                ref="tree"
                check-strictly
                :expand-on-click-node="false"
                @node-click="
                  (data, node, item) =>
                    addAdminHandleNodeClick(data, node, item)
                "
              ></el-tree>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="部门负责人" prop="user">
          <div>
            <el-autocomplete
              v-model="name"
              :fetch-suggestions="querySearchAsync"
              @select="handleSelect"
            ></el-autocomplete>
          </div>
          <div class="depa_tips">
            <img src="@/assets/structure/tips.png" class="depa_tips_icon" />
            <div>如已选择负责人，可更换，但无法删除</div>
          </div>
          <div class="depa_tips">
            <img src="@/assets/structure/tips.png" class="depa_tips_icon" />
            <div>不设置部门负责人本部门员工将无法提交假勤申请</div>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import http from "../../../../utils/http";
export default {
  data() {
    return {
      title: "新建部门",
      searchData: [],
      loading: false,
      name: "", //部门负责人名字
      formLabelAlign: {
        id: null,
        user: null,
      }, //提交的表单
      formLabelAlignCopy: {}, //复制表单
      rules: {
        name: [{ required: true, message: "请输入部门名称", trigger: "blur" }],
        no: [
          { required: true, message: "请输入部门编号", trigger: "blur" },
          {
            type: "number",
            message: "输入内容不是有效的数字",
          },
        ],
      },
      customerList: [], //所选部门列表
      allDeptmentList: [], //全部部门列表
      defaultProps: {
        children: "subDepartment",
        label: "name",
      },
      selectTreeName: "",
      selectTree: "",
    };
  },
  created() {
    this.getDeptment();

    switch (this.type) {
      case 1:
        this.title = "新建部门";
        this.getMaxNumber();
        break;
      case 2:
        this.title = "添加子部门";
        // 如果存在id的话
        if (this.id && this.id != -1) {
          this.getDepatinfo(this.id);

          this.getMaxNumber();
        }
        break;
      case 3:
        this.title = "编辑部门";
        // 如果存在id的话
        if (this.id && this.id != -1) {
          this.getDepatinfo(this.id);
        }
        break;
    }
  },
  // type:1是添加，2是添加子级，3是编辑
  props: ["id", "type", "userName"],

  methods: {
    // 关闭弹窗
    close() {
      this.$refs.batch.resetFields();
      this.$emit("close");
    },

    // 确认弹窗
    comfin() {
      this.$refs.batch.resetFields();
      this.$emit("comfin");
    },

    // 选择上级部门点击方法
    addAdminHandleNodeClick(e) {
      this.selectTreeName = e.name;
      this.formLabelAlign.parent = e.id;
      this.$refs.multiSelect.blur();
    },

    // 获取部门列表
    getDeptment() {
      http
        .get("/umsDepartment/list", {
          pageNum: 1,
          pageSize: 999,
        })
        .then((res) => {
          if (res.code == 200) {
            if (res.data) {
              const nestedArr = this.convertToNestedStructure(res.data);
              this.customerList = nestedArr;
              this.allDeptmentList = res.data;
              if (this.type == 1) {
                this.selectTreeName = nestedArr[0].name;
                this.formLabelAlign.parent = nestedArr[0].id;
              }
              this.formLabelAlign.user = "";
            }
          }
        });
    },

    // 递归处理数据
    convertToNestedStructure(arr) {
      // 创建一个映射，用于快速查找具有特定id的对象
      const idMap = new Map(arr.map((item) => [item.id, item]));

      // 创建一个新数组来存储转换后的嵌套结构
      const nestedArr = [];

      // 遍历原始数组
      arr.forEach((item) => {
        // 如果该项有父元素，则将其添加到父元素的subDepartment数组中
        if (item.parent) {
          const parent = idMap.get(item.parent);
        if (parent) {
            if (!parent.subDepartment) {
              parent.subDepartment = [];
            }
            item.parentDepament = parent.name;
            parent.subDepartment.push(item);
          }
        } else {
          // 如果没有父元素，则添加到新数组的根级别
          nestedArr.push(item);
        }
      });

      // 返回转换后的嵌套数组
      return nestedArr;
    },

    // 获取部门信息
    getDepatinfo(id) {
      http
        .get("/umsDepartment/get", {
          id: id,
        })
        .then((res) => {
          if (this.type == 3) {
            this.formLabelAlign = res.data;

            setTimeout(() => {
              this.$nextTick(() => {
                let foundObject = this.allDeptmentList.find(
                  (obj) => obj.id === res.data.parent
                );
                console.log(foundObject);
                if (foundObject) {
                  this.selectTreeName = foundObject.name;
                  this.formLabelAlign.parent = res.data.parent;
                }
                this.name = this.userName;
                this.formLabelAlign.user = res.data.user;
              });
            }, 500);
          } else if (this.type == 2) {
            setTimeout(() => {
              this.selectTreeName = res.data.name;
              this.formLabelAlign.parent = res.data.id;
            }, 200);
          }

          this.formLabelAlignCopy = JSON.stringify(res.data);
        });
    },

    // 获取最大部门编号
    getMaxNumber() {
      http.get("/umsDepartment/get/max_no").then((res) => {
        if (res.code == 200) {
          if (res.data < 1000000000) {
            this.$set(this.formLabelAlign, "no", res.data);
          }
        }
      });
    },

    // 新建还是编辑
    switcOperation() {
      if (this.type == 3) {
        this.editBranch();
      } else {
        this.createBranch();
      }
    },

    // 新建部门
    createBranch() {
      this.$refs["batch"].validate((valid) => {
        delete this.formLabelAlign.id;
        if (valid) {
          if (this.name == "") {
            delete this.formLabelAlign.user;
          }
          http.post("/umsDepartment/add", this.formLabelAlign).then((res) => {
            if (res.code == 200) {
              this.$message.success("添加成功");
              this.comfin();
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    // 编辑部门
    editBranch() {
      this.$refs["batch"].validate((valid) => {
        if (valid) {
          var obj = {};
          var object = JSON.stringify(this.formLabelAlign);
          object = JSON.parse(object);
          obj.id = object.id;

          var value = JSON.parse(this.formLabelAlignCopy);
          if (object.name != value.name) {
            obj.name = object.name;
          }
          if (object.parent != value.parent) {
            obj.parent = object.parent;
          }
          if (object.user != value.user) {
            obj.user = object.user;
          }
          if (object.no != value.no) {
            obj.no = object.no;
          }
          http.post("/umsDepartment/update", obj).then((res) => {
            if (res.code == 200) {
              this.$message.success("编辑成功");
              this.comfin();
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },

    // 搜索员工
    querySearchAsync(queryString, cb) {
      http
        .get("/umsUser/list", {
          likeString: queryString,
          typeList: "2,3,4",
          pageNum: 1,
          pageSize: 1000,
        })
        .then((res) => {
          if (res.code == 200) {
            var nameAndId = [];
            if (res.data.list.length > 0) {
              res.data.list.map((item) => {
                nameAndId.push({
                  id: item.id,
                  value: item.name,
                });
              });
            }
            setTimeout(() => {
              cb(nameAndId);
            }, 1000);
          }
        });
    },

    // 选择员工
    handleSelect(item) {
      if (item.id) {
        this.formLabelAlign.user = item.id;
      }
    },
  },
};
</script>

<style scoped>
.branch_l {
  display: flex;
  align-items: center;
}
.batch_t {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
  box-sizing: border-box;
  padding-left: 20px;
  font-size: 18px;
  color: #1a1a1a;
  border-bottom: 1px solid #cfd4e8;
}
.batch_close {
  width: 24px;
  cursor: pointer;
  height: 24px;
  margin-right: 20px;
}
.batch_c {
  padding: 0 30px;
  box-sizing: border-box;
  margin-top: 40px;
}
.department_search_inp {
  width: 100%;
  border: 1px solid #dcdfe6;
}
::v-deep .depa .el-select-dropdown {
  top: 330px !important;
}
::v-deep .batch_c .el-autocomplete {
  width: 100%;
}
.depa_tips {
  display: flex;
  align-items: center;
  font-size: 15px;
  color: rgba(26, 26, 26, 0.6);
}
.depa_tips_icon {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}
</style>
