<template>
  <div class="gps">
    <div class="visitor_information_t">
      <div class="visitor_information_search">
        <el-input
          class="department_search_inp"
          v-model="searchAddress"
          @keyup.enter.native="seachResult"
          placeholder="打卡地点"
        ></el-input>
        <img
          src="@/assets/structure/delete.png"
          class="searchicon"
          @click="clearup"
          v-if="searchicon"
        />
        <img
          src="@/assets/structure/search.png"
          class="searchicon"
          @click="seachResult"
        />
      </div>
      <el-button
        @click="newFormVisible"
        type="primary"
        class="structure_addstaff"
        >新增GPS打卡</el-button
      >
    </div>
    <div class="gps_table">
      <el-table
        height="100%"
        ref="multipleTable"
        :data="tableData"
        class="v_table"
        :header-cell-style="getRowClass"
      >
        <template slot="empty">
          <div>
            <img src="@/assets/nodata.png" class="nodata" />
            <div>无数据</div>
          </div>
        </template>
        <el-table-column label="打卡地点" width="400">
          <template slot-scope="scope">
            <div>
              {{ scope.row.name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="有效范围(米)" width="300">
          <template slot-scope="scope">
            <div class="ellipsis">
              {{ scope.row.distanceLimit }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="打卡人员"
          header-align="left"
          align="left"
          width="350"
        >
          <template slot-scope="scope">
            <button class="group_button_p" @click="openPersion(scope.row.id)">
              人员明细
            </button></template
          >
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-link
              type="primary"
              style="margin-left: 4px"
              @click="openReviewen(scope.row.id)"
              >新增人员</el-link
            >
            <el-link
              type="primary"
              style="margin-left: 24px"
              @click="editAddress(scope.row)"
              >编辑</el-link
            >
            <el-link
              type="danger"
              style="margin-left: 24px"
              @click="deleteAddress(scope.row.id)"
              >删除</el-link
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="newpagination">
      <div class="newpagination_text">共 {{ pages.total }} 条数据</div>
      <el-pagination
        layout="prev, pager, next"
        :total="pages.total"
        :current-page="pages.pageNum"
        @current-change="chagePage"
        :page-size="10"
      >
      </el-pagination>
    </div>

    <!-- 添加打卡地点 -->
    <el-dialog
      title=""
      ref="dialogRef"
      :key="mapKey"
      :visible.sync="checkLocation"
      :close-on-click-modal="false"
      width="48%"
      :show-close="false"
    >
      <div class="diglog_header">
        <div>{{ gpsForm.id ? "编辑GPS打卡地点" : "添加GPS打卡地点" }}</div>
        <img
          src="@/assets/device/close.png"
          class="close"
          @click="cancelDialog"
        />
      </div>
      <div class="map">
        <div class="map_left_opertion">
          <div class="map_search">
            <el-autocomplete
              v-model="address"
              :fetch-suggestions="querySearchAsync"
              @select="handleSelect"
              placeholder="请输入内容"
            >
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-autocomplete>
          </div>
          <el-popover placement="bottom" trigger="hover">
            <div class="reporting">
              <div>小程序扫码</div>
              <div ref="canvasWrapper" class="box2"></div>
            </div>
            <div slot="reference" class="phone_reporting">
              <img src="@/assets/phone.png" class="phone_icon" />
              <div>手机上报</div>
            </div>
          </el-popover>
        </div>
        <div class="map_operation">
          <img src="@/assets/+.png" class="operation_icon" @click="enlarge" />
          <img src="@/assets/-.png" class="operation_icon" @click="narrow" />
        </div>
        <div id="container" class="map_content"></div>
      </div>
      <div class="show">
        <div class="address_name">
          <div>打卡地点名称</div>
          <div class="address_bi">*</div>
          <el-input
            v-model="gpsForm.name"
            placeholder="请输入内容"
            class="address_specific"
          ></el-input>
        </div>
        <div class="address_range">
          <div>打卡范围</div>
          <div class="address_bi">*</div>
          <el-select v-model="gpsForm.distanceLimit" placeholder="请选择">
            <el-option
              v-for="item in rangerList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="map_bto">
        <div class="cancel" @click="cancelDialog">取消</div>
        <div class="confim" @click="confimDialog">确认</div>
      </div>
    </el-dialog>
    <!-- 选择人员范围 -->
    <el-dialog
      title=""
      :key="rangeKey"
      :visible.sync="availableDialog"
      width="45%"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <div class="diglog_h">
        <div>可用人员范围</div>
        <img
          src="@/assets/structure/close.png"
          class="diglog_icon"
          @click="closeavailableDialog"
        />
      </div>
      <div class="available_frame">
        <div class="available_frame_left">
          <div class="available_title">部门/人员</div>
          <div class="available_search">
            <el-select v-model="selectType" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-input
              v-model="searchValue"
              @keyup.enter.native="searchAvailableSear"
              class="available_search_input"
              :placeholder="selectType == 1 ? '部门名称' : '员工姓名'"
            >
            </el-input>
            <img
              src="@/assets/structure/search.png"
              class="available_search_icon"
              @click="searchAvailableSear"
            />
          </div>
          <div>
            <div class="breadcrumbs">
              <div
                v-for="(item, index) in breadcrumbs"
                :key="index"
                class="breadcrumbs_all"
                @click="clickBreadcrumbs(index, item)"
              >
                <div
                  :class="[
                    'breadcrumbs_text',
                    index == breadcrumbs.length - 1
                      ? 'breadcrumbs_current'
                      : 'breadcrumbs_last',
                  ]"
                >
                  {{ item.name }}
                </div>
                <div style="margin: 0 5px">
                  {{ index == breadcrumbs.length - 1 ? " " : "/" }}
                </div>
              </div>
            </div>
            <div class="available_depaall">
              <div
                :class="[
                  'available_depa',
                  availableSelecDept == item.id ? 'available_depa_select' : '',
                ]"
                v-for="(item, index) in availableDept"
                @click="switchDpat(item)"
                :key="index"
              >
                <div class="available_depa_left">
                  <div class="available_depa_left_icon">
                    <img src="@/assets/device.png" />
                  </div>
                  <div class="available_depa_left_name">{{ item.name }}</div>
                </div>
                <div
                  v-if="item.subDepartment"
                  class="available_depa_left_next"
                  @click="checkSubordinate(item)"
                >
                  下级
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="available_frame_center">
          <div class="available_title">待选择人员</div>
          <div class="available_frame_tobeselected">
            <div class="available_frame_tobeselected_top">
              <el-checkbox @change="selectAllpeople" v-model="isSelecAll"
                >全选</el-checkbox
              >
              <div>
                已选：{{
                  countCommonValues(waitPeopleList, completePeopleList)
                }}
              </div>
            </div>
            <div class="available_frame_tobeselected_center">
              <el-checkbox-group v-model="completePeopleList">
                <div
                  class="available_frame_tobeselected_single"
                  v-for="item in waitPeopleList"
                  :key="item.id"
                >
                  <el-checkbox
                    :label="item"
                    :checked="item.checked"
                    :disabled="item.isIn || !item.jobNumber"
                    ><br
                  /></el-checkbox>
                  <div class="available_depa_left_icon2">
                    <img src="@/assets/device.png" />
                  </div>
                  <div>
                    <div class="available_frame_tobeselected_name">
                      {{ item.name }}
                    </div>
                    <div class="available_frame_tobeselected_depa">
                      {{ item.department || item.umsDepartment.name }}
                    </div>
                  </div>
                </div>
              </el-checkbox-group>
            </div>
          </div>
        </div>
        <div class="available_frame_right">
          <div class="available_title">已选择人员</div>
          <div class="available_frame_tobeselected">
            <div class="available_frame_tobeselected_delte" @click="deleteAll">
              全部移除
            </div>
            <div class="available_frame_tobeselected_center">
              <div
                class="available_frame_tobeselected_single2"
                v-for="(item, index) in completePeopleList"
                :key="index"
              >
                <div class="available_frame_select_left">
                  <div class="available_depa_left_icon">
                    <img src="@/assets/device.png" />
                  </div>
                  <div>
                    <div class="available_frame_tobeselected_name">
                      {{ item.name }}
                    </div>
                    <div class="available_frame_tobeselected_depa">
                      {{ item.department }}
                    </div>
                  </div>
                </div>
                <img
                  src="@/assets/close.png"
                  class="available_frame_select_right"
                  @click="deleteSelect(index)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="available_frame_bto">
        <div class="available_frame_cancel" @click="closeavailableDialog">
          取消
        </div>
        <el-button
          class="available_frame_comfin"
          @click="comfinAdd"
          v-loading="onSubmit"
          >确定</el-button
        >
      </div>
    </el-dialog>
    <!-- 人员明细 -->
    <el-dialog
      title=""
      width="52%"
      :key="currentGroundid"
      :show-close="false"
      :visible.sync="inviteDetail"
      class="invite_frame"
    >
      <Detail
        @close="
          inviteDetail = false;
          currentGroundid = null;
        "
        :id="currentGroundid"
        :type="1"
      />
    </el-dialog>
    <!-- 删除班次弹窗 -->
    <Diglog
      @closeDiglog="closeDelete"
      @affirmDiglog="comfinDeleteAddress"
      :isShow="delectVistorDiglog"
      :title="'删除打卡地点'"
      :tips="'删除打卡地点后，所在打卡地点人员将无法通过GPS打卡'"
    />
  </div>
</template>

<script>
import http from "../../../utils/http";
import { jsonp } from "vue-jsonp";
import Detail from "./detail.vue";
import Diglog from "../../../assembly/dialog.vue";

import QRCode from "qrcode";
export default {
  data() {
    return {
      gpsForm: {
        distanceLimit: 100,
      }, //gps表格
      pageNum: 1, //页数
      searchicon: false, //监听输入
      pointCenter: "", //坐标中心点
      searchAddress: "", //输入框搜索地址
      searchValue: "", //输入框输入的信息
      checkLocation: false, //添加打卡地点弹窗
      tableData: [], //表格数据
      pages: {}, //分页列表
      map: null, //地图组件
      markerLayer: null, //地图标记
      mapKey: Math.random(), //地图key
      ticket: "", //信息
      timer: null, //定时器
      circle: null, //半径圆
      address: "", //地址搜索关键词
      options: [], //地址列表
      scope: "", //打卡范围
      currentGps: null, //当前选的gps的id
      availableDialog: false, //打卡人员弹窗
      rangeKey: Math.random(), //打卡人员key
      selectType: 1, //搜索选择类型
      searchAvailable: "", //搜索姓名
      onSubmit: false, //是否加载
      deptList: [], //部门数组
      isSelecAll: false, //是否全选
      breadcrumbs: [], //面包屑
      availableDept: [], //部门列表
      availableDeptCopy: [], //复制部门列表
      availableSelecDept: null, //所选部门id
      gpsId: null, //所选的gps的id
      options: [
        {
          label: "部门",
          value: 1,
        },
        {
          label: "人员",
          value: 2,
        },
      ],
      waitPeopleList: [], //待选人员
      selectIds: [], //已选择人员
      completePeopleList: [], //已选人员

      inviteDetail: false, //人员明细弹窗
      currentGroundid: null, //当前选择的地址

      delectVistorDiglog: false, //删除地址弹窗

      rangerList: [
        {
          value: 100,
          label: "100米",
        },
        {
          value: 200,
          label: "200米",
        },
        {
          value: 300,
          label: "300米",
        },
        {
          value: 400,
          label: "400米",
        },
        {
          value: 500,
          label: "500米",
        },
      ], //范围
    };
  },

  components: { Detail, Diglog },

  created() {
    // 获取打卡列表
    this.getList();

    // 获取全部部门
    this.getDepartmentList();
  },

  mounted() {
    this.checkLocation = true;
    this.$refs.dialogRef.rendered = true; // 为dialog设置一个ref为dialogRef
    this.checkLocation = false;
  },

  watch: {
    completePeopleList: {
      handler(newValue, oldValue) {
        if (this.waitPeopleList.length > 0) {
          var filteredArr = this.waitPeopleList.filter(function (item) {
            return !item.isIn;
          });
          // 当 items 数组发生变化时，这个函数会被调用
          let containsAll = filteredArr.every((value) =>
            newValue.includes(value)
          );
          // 你可以在这里执行你想要的操作
          if (containsAll) {
            this.isSelecAll = true;
          } else {
            this.isSelecAll = false;
          }

          var isrepeat = this.hasDuplicates(newValue);
          if (isrepeat) {
            var arr = this.removeDuplicates(newValue);

            this.completePeopleList = arr;
          }
        }
      },
      deep: true, // 深度监听，用于监听数组内部对象的变化
    },
    // 监听待选择列表
    waitPeopleList: {
      handler(newValue, oldValue) {
        this.completePeopleList.map((item) => {
          var index = newValue.findIndex((citem) => item.id == citem.id);
          if (index > -1) {
            this.$set(newValue[index], "checked", true);
          }
        });
      },
      deep: true, // 深度监听，用于监听数组内部对象的变化
    },
    "gpsForm.price": function (newValue, oldValue) {
      // 当 myObject 的 someValue 属性变化时，这个函数会被调用
      if (this.circle) {
        this.circle.setRadius(newValue);
      }
    },
    searchAddress(newName, oldName) {
      if (newName) {
        this.searchicon = true;
      } else {
        this.searchicon = false;

        // 重新获取审批列表
        this.getList();
      }
    },

    // 监听输入框变化
    searchValue(newValue, oldValue) {
      if (!newValue) {
        this.breadcrumbs = [];
        this.availableDept = this.availableDeptCopy;
        this.waitPeopleList = [];
      }
    },
  },

  methods: {
    // 获取打卡列表
    getList() {
      var objcet = {};
      if (this.searchAddress) {
        objcet = {
          name: this.searchAddress,
        };
      }
      http
        .get(
          "/bmsGpsGroup/list",
          Object.assign(
            {
              pageNum: this.pageNum,
              pageSize: 10,
            },
            objcet
          )
        )
        .then((res) => {
          if (res.code == 200) {
            this.tableData = res.data.list;
            this.pages = res.data;
          }
        });
    },
    // 搜索
    seachResult() {
      this.pageNum = 1;
      this.getList();
    },

    // 清除搜索
    clearup() {
      this.searchAddress = "";
    },

    // 表头背景颜色变色
    getRowClass({ rowIndex, columnIndex }) {
      if (rowIndex == 0) {
        return "background:#F5F6FA;color:#5C5C5C";
      }
    },

    // 改变页数
    chagePage(page) {
      this.pageNum = page;
      this.getList();
    },
    // ----------------------新增删除编辑打卡--------------------------
    // 新增gps打卡
    newFormVisible() {
      this.$refs.canvasWrapper.innerHTML = "";
      http.get("/bmsGpsGroup/get/gps_info_ticket").then((res) => {
        if (res.code == 200) {
          var ticket = "type=2&ticket=" + res.data;
          this.ticket = res.data;
          // 生成二维码
          QRCode.toCanvas(
            ticket,
            {
              margin: 1,
            },
            (error, canvas) => {
              if (error) {
                console.error(error);
                return;
              }
              // 在canvas的父元素中插入canvas元素
              this.$nextTick(() => {
                this.$refs.canvasWrapper.appendChild(canvas);
              });
            }
          );
          this.checkLocation = true;
          this.$nextTick(() => {
            // 1，初始化腾讯地图
            this.initMap();
          });

          this.timer = setInterval(() => {
            // 执行需要定时重复执行的任务
            this.getGpsInfo();
          }, 2000);
        }
      });
    },
    // 切换二维码
    switchQrcode() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
      this.$refs.canvasWrapper.innerHTML = "";
      http.get("/bmsGpsGroup/get/gps_info_ticket").then((res) => {
        if (res.code == 200) {
          var ticket = "type=2&ticket=" + res.data;
          this.ticket = res.data;
          // 生成二维码
          QRCode.toCanvas(
            ticket,
            {
              margin: 1,
            },
            (error, canvas) => {
              if (error) {
                console.error(error);
                return;
              }
              // 在canvas的父元素中插入canvas元素
              this.$nextTick(() => {
                this.$refs.canvasWrapper.appendChild(canvas);
              });
            }
          );
          this.timer = setInterval(() => {
            // 执行需要定时重复执行的任务
            this.getGpsInfo();
          }, 2000);
        }
      });
    },
    // 轮询获取扫码信息
    getGpsInfo() {
      http
        .get("/bmsGpsGroup/get/gps_info", {
          ticket: this.ticket,
        })
        .then((res) => {
          if (res.code == 200) {
            if (res.data) {
              var arr = res.data.gpsLoc.split(",");
              var objcet = {
                latLng: {
                  lat: arr[0],
                  lng: arr[1],
                },
              };
              this.eventClick(objcet);

              this.switchQrcode();
            }
          }
        });
    },
    // 取消弹窗
    cancelDialog() {
      this.checkLocation = false;
      delete this.gpsForm.id;
      this.gpsForm.name = "";
      this.pointCenter = "";
      this.address = "";
      this.gpsForm.distanceLimit = 100;
      clearInterval(this.timer);
      this.timer = null;
    },
    // 编辑gps打卡
    editAddress(val) {
      var newVal = JSON.stringify(val);
      newVal = JSON.parse(newVal);

      console.log(newVal);
      this.gpsForm = newVal;
      this.pointCenter = newVal.gpsLoc;

      setTimeout(() => {
        this.newFormVisible();
      }, 500);
    },
    // 删除地址弹窗
    deleteAddress(id) {
      this.currentGroundid = id;
      this.delectVistorDiglog = true;
    },
    // 关闭删除地址弹窗
    closeDelete() {
      this.currentGroundid = null;
      this.delectVistorDiglog = false;
    },
    // 确认删除地址
    comfinDeleteAddress() {
      http
        .post("/bmsGpsGroup/delete", {
          ids: [this.currentGroundid],
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("删除成功");
            // 取消弹窗
            this.closeDelete();
            // 重新获取列表
            this.pageNum = 1;
            this.getList();
          } else {
            this.$message.error(res.message);
          }
        });
    },
    // 初始化地图
    initMap() {
      var center = null;
      if (!this.pointCenter) {
        center = new qq.maps.LatLng(23.175206, 113.456581);
      } else {
        var arr = this.pointCenter.split(",");
        center = new qq.maps.LatLng(arr[0], arr[1]);
      }
      var map = new qq.maps.Map(document.getElementById("container"), {
        center: center,
        zoom: 18,
        disableDefaultUI: true,
      });
      // marker
      var marker = new qq.maps.Marker({
        map: map,
        Draggable: true,
        position: center,
      });
      this.markerLayer = marker;
      // marker监听拖动事件
      qq.maps.event.addDomListener(marker, "dragend", this.eventClick);

      // 圆形覆盖物
      //设置圆形覆盖物的颜色和透明度rgba
      var circle_color = new qq.maps.Color(
        Number(0),
        Number(88),
        Number(255),
        0.2
      );
      this.circle = new qq.maps.Circle({
        center: center, // 圆心坐标，同地图中心点
        radius: 100, // 圆半径，单位为米
        strokeColor: "#0058FF", // 圆边框颜色
        strokeWeight: 0.5, // 圆边框线宽度
        fillColor: circle_color, // 圆填充颜色
      });
      this.map = map;
      this.circle.setMap(this.map);
    },

    // 触发远程搜索
    querySearchAsync(queryString, cb) {
      const url = "https://apis.map.qq.com/ws/place/v1/suggestion";
      jsonp(url, {
        key: "3WDBZ-HMUCX-NIE43-ZPLQ4-OOHAO-OKBES",
        output: "jsonp",
        keyword: this.address,
      })
        .then((res) => {
          if (res.status === 0) {
            var arr = res.data;
            var newArr = [];
            arr.map((item) => {
              newArr.push({
                value: item.title,
                location: item.location,
              });
            });

            cb(newArr);
          }
        })
        .catch((e) => {
          console.log(e);
          cb([]);
        });
    },
    // 选择地点
    handleSelect(value) {
      var location = value.location;
      var center = new qq.maps.LatLng(location.lat, location.lng);
      // 移动marker
      this.markerLayer.setPosition(center);
      // 移动地图中心点
      this.map.panTo(center);
      // 移动圆的中心点
      this.circle.setCenter(center);

      this.pointCenter = location.lat + "," + location.lng;

      this.$set(this.gpsForm, "name", value.value);
    },
    // 拖动事件
    eventClick(e) {
      // 经度
      var lat = e.latLng.lat;
      // 纬度
      var lng = e.latLng.lng;
      // 中心点
      var center = new qq.maps.LatLng(lat, lng);
      // 移动marker
      this.markerLayer.setPosition(center);
      // 移动地图中心点
      this.map.panTo(center);
      // 移动圆的中心点
      this.circle.setCenter(center);
      // 逆坐标解析
      const url = "https://apis.map.qq.com/ws/geocoder/v1/?";
      jsonp(url, {
        key: "3WDBZ-HMUCX-NIE43-ZPLQ4-OOHAO-OKBES",
        output: "jsonp",
        location: lat + "," + lng,
      })
        .then((res) => {
          if (res.status === 0) {
            var address = res.result.formatted_addresses.recommend;
            this.$set(this.gpsForm, "name", address);
            this.pointCenter = lat + "," + lng;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // 随机生成一个三位数id
    generateRandomId() {
      return Math.floor(Math.random() * 1000) + 100;
    },
    // 放大
    enlarge() {
      this.map.zoomTo(this.map.getZoom() + 1);
    },
    // 缩小
    narrow() {
      this.map.zoomTo(this.map.getZoom() - 1);
    },

    // 确认操作
    confimDialog() {
      if (!this.gpsForm.name || this.gpsForm.name == "") {
        this.$message.error("请先输入名称");
        return;
      }
      if (!this.pointCenter || this.pointCenter == "") {
        this.$message.error("请先搜索准确地址或手机上报");
        return;
      }

      // 判断是添加还是编辑
      if (this.gpsForm.id) {
        http
          .post("/bmsGpsGroup/update", {
            id: this.gpsForm.id,
            name: this.gpsForm.name,
            gpsName: this.gpsForm.name,
            distanceLimit: this.gpsForm.distanceLimit,
            gpsLoc: this.pointCenter,
          })
          .then((res) => {
            if (res.code == 200) {
              this.$message.success("编辑成功");
              // 取消弹窗
              this.cancelDialog();
              // 重新获取列表
              this.pageNum = 1;
              this.getList();
            } else {
              this.$message.error(res.message);
            }
          });
      } else {
        http
          .post("/bmsGpsGroup/add", {
            name: this.gpsForm.name,
            gpsName: this.gpsForm.name,
            distanceLimit: this.gpsForm.distanceLimit,
            gpsLoc: this.pointCenter,
          })
          .then((res) => {
            if (res.code == 200) {
              this.$message.success("添加成功");
              // 取消弹窗
              this.cancelDialog();
              // 重新获取列表
              this.pageNum = 1;
              this.getList();
            } else {
              this.$message.error(res.message);
            }
          });
      }
    },
    // ----------------------人员明细-------------------------
    searchPeo() {
      if (this.search == "") {
        return;
      }
      http
        .get("/umsDepartment/list/down", {
          userName: this.search,
        })
        .then((res) => {
          if (res.code == 200) {
            this.isSearchResult = true;
            this.deptList = res.data;
          }
        });
    },
    clearName() {
      this.search = "";
    },
    // 打开
    openPersion(id) {
      this.currentGroundid = id;
      setTimeout(() => {
        this.inviteDetail = true;
      }, 500);
    },

    // ----------------------打卡人员范围--------------------------
    // 打开设备人员弹窗
    openReviewen(id) {
      this.gpsId = id;
      this.rangeKey = Math.random();
      setTimeout(() => {
        this.availableDialog = true;
      }, 500);
    },
    // 获取部门列表
    getDepartmentList() {
      http.get("/umsDepartment/list").then((res) => {
        if (res.code == 200) {
          var arr = this.convertToNestedStructure(res.data);
          this.availableDept = arr;
          this.availableDeptCopy = JSON.stringify(arr);
          this.availableDeptCopy = JSON.parse(this.availableDeptCopy);
        }
      });
    },
    // 递归处理数据
    convertToNestedStructure(arr) {
      // 创建一个映射，用于快速查找具有特定id的对象
      const idMap = new Map(arr.map((item) => [item.id, item]));

      // 创建一个新数组来存储转换后的嵌套结构
      const nestedArr = [];

      // 遍历原始数组
      arr.forEach((item) => {
        // 如果该项有父元素，则将其添加到父元素的subDepartment数组中
        if (item.parent) {
          const parent = idMap.get(item.parent);
        if (parent) {
            if (!parent.subDepartment) {
              parent.subDepartment = [];
            }
            item.parentDepament = parent.name;
            parent.subDepartment.push(item);
          }
        } else {
          // 如果没有父元素，则添加到新数组的根级别
          nestedArr.push(item);
        }
      });

      // 返回转换后的嵌套数组
      return nestedArr;
    },
    // 获取部门列表
    getDept(id, department) {
      http
        .get("/bmsGpsGroupPeople/search", {
          department: id,
          gps: this.gpsId,
        })
        .then((res) => {
          if (res.code == 200) {
            var arr = [];
            res.data.map((item) => {
              item.department = department;
              item.checked = false;
              arr.push(item);
            });
            this.waitPeopleList = arr;

            if (arr.length > 0) {
              var istrue = this.areAllIdsInArray(arr, this.completePeopleList);
              if (istrue) {
                this.isSelecAll = true;
              } else {
                this.isSelecAll = false;
              }
            }
          }
        });
    },
    // 取消设备打卡人员弹窗
    closeavailableDialog() {
      this.completePeopleList = [];
      this.breadcrumbs = [];
      this.availableDept = this.availableDeptCopy;
      this.isSelecAll = false;
      this.waitPeopleList = [];
      this.availableDialog = false;
    },
    // 确认添加打卡人员
    comfinAdd() {
      if (this.completePeopleList.length == 0) {
        this.$message.error("请先选择人员");
        return;
      }
      this.onSubmit = true;
      var arr = [];
      this.completePeopleList.map((item) => {
        arr.push(item.id);
      });

      http
        .post("/bmsGpsGroupPeople/add", {
          userList: arr,
          gps: this.gpsId,
        })
        .then((res) => {
          this.onSubmit = false;
          if (res.code == 200) {
            this.$message.success("添加成功");
            this.closeavailableDialog();

            this.pageNum = 1;
            this.getList();
          }
        });
    },
    // 点击面包屑的部门
    clickBreadcrumbs(index, item) {
      this.breadcrumbs.splice(index + 1);
      var arr = this.searchNestedObject(this.availableDeptCopy, item.id);
      this.getDept(item.id, item.name);
      this.availableDept = arr.subDepartment;
    },
    // 查询子级
    searchNestedObject(arr, id) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].id === id) {
          return arr[i];
        }
        if (arr[i].subDepartment) {
          const found = this.searchNestedObject(arr[i].subDepartment, id);
          if (found) {
            return found;
          }
        }
      }
      return undefined;
    },
    // 切换部门
    switchDpat(item) {
      this.availableSelecDept = item.id;
      this.getDept(item.id, item.name);
    },
    // 查看下级部门
    checkSubordinate(item) {
      this.breadcrumbs.push({
        name: item.name,
        id: item.id,
      });

      this.getDept(item.id, item.name);
      this.availableDept = item.subDepartment;
    },
    // 判断有几个已经选择
    countCommonValues(array1, array2) {
      // 将 array1 的 id 存储在一个集合中
      const set1 = new Set(array1.map((item) => item.id));

      // 计数器
      let commonCount = 0;

      // 遍历 array2 并检查每个 id 是否存在于集合中
      for (let item of array2) {
        if (set1.has(item.id)) {
          commonCount++;
        }
      }

      return commonCount;
    },
    // 判断待选择是否都在已选择里面
    areAllIdsInArray(a, b) {
      // 将 b 的 id 存储在一个集合中
      const setB = new Set(b.map((item) => item.id));

      // 检查 a 中的每个 id 是否都在集合 setB 中
      for (let item of a) {
        if (!setB.has(item.id)) {
          return false; // 如果有一个 id 不在 b 中，则返回 false
        }
      }

      return true; // 如果所有 id 都在 b 中，则返回 true
    },
    // 判断数组是否有重复
    hasDuplicates(array) {
      const set = new Set();
      for (let item of array) {
        if (set.has(item.id)) {
          return true; // 如果集合中已经存在该元素，则说明有重复
        }
        set.add(item.id); // 否则将元素添加到集合中
      }
      return false; // 遍历完数组后仍未发现重复项
    },
    // 数组去重
    removeDuplicates(array) {
      // 使用 Map 来去重，Map 的键是 id，值是对象本身
      const map = new Map();

      for (let item of array) {
        map.set(item.id, item); // 如果 id 已经存在，则会覆盖
      }

      // 将 Map 的值转换回数组
      return Array.from(map.values());
    },
    // 搜索
    searchAvailableSear() {
      if (this.selectType == 1) {
        // 搜索部门
        let result = this.findNode(this.searchValue);
        if (result) {
          this.availableDept = [result];
        } else {
          this.availableDept = [];
        }
      } else {
        // 搜索人
        http
          .get("/bmsGpsGroupPeople/search", {
            gps: this.gpsId,
            userName: this.searchValue,
          })
          .then((res) => {
            if (res.code == 200) {
              this.waitPeopleList = res.data;
            }
          });
      }
    },
    // 搜索指定的值
    findNode(target) {
      let stack = [...this.availableDeptCopy]; // 初始化栈为顶层数组的元素

      while (stack.length > 0) {
        let node = stack.pop(); // 弹出栈顶元素

        if (node.name === target) {
          return node; // 找到目标节点，返回
        }

        if (node.subDepartment) {
          stack.push(...node.subDepartment); // 将子节点压入栈中
        }
      }

      return null; // 未找到目标节点
    },
    // 全选人员
    selectAllpeople(value) {
      if (value) {
        var filteredArr = this.waitPeopleList.filter(function (item) {
          return !item.isIn;
        });
        // 合并两个数组
        let combinedArray = this.completePeopleList.concat(filteredArr);

        // 使用 filter 方法去重
        let uniqueArray = combinedArray.filter((value, index, self) => {
          return self.indexOf(value) === index;
        });

        this.completePeopleList = uniqueArray;
      } else {
        let result = this.completePeopleList.filter(
          (item) => !this.waitPeopleList.includes(item)
        );
        this.completePeopleList = result;
      }
    },

    // 删除选中的人员
    deleteSelect(index) {
      this.completePeopleList.splice(index, 1);
    },
    // 删除全部选中的人员
    deleteAll() {
      this.completePeopleList = [];
    },
  },
};
</script>

<style scoped>
.gps {
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: white;
}
.visitor_information_t {
  box-sizing: border-box;
  border-bottom: 1px solid rgba(207, 212, 232, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
}
.visitor_information_search {
  width: 243px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #d0d0d0;
  display: flex;
  align-items: center;
  padding-right: 12px;
  box-sizing: border-box;
  justify-content: space-between;
}
.department_search_inp {
  width: 200px;
}
.gps_table {
  width: 100%;
  flex: 1;
  position: relative;
}
.v_table {
  width: 100%;
  height: 100%;
  position: absolute;
}
.group_button_p {
  /* width: 80px !important; */
  background: #e0ecff;
  cursor: pointer;
  font-size: 14px;
  opacity: 1;
  color: #0058ff !important;
  text-align: center;
  border: none;

  height: 26px;
}
.diglog_header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 74px;
  justify-content: space-between;
  padding: 0 24px;
  box-sizing: border-box;
  font-size: 18px;
  color: #1a1a1a;
}
.close {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
::v-deep .el-dialog {
  padding: 0;
  padding-bottom: 40px;
  box-sizing: border-box;
}
.map {
  width: 100%;
  height: 386px;
  position: relative;
}
.map_left_opertion {
  display: flex;
  align-items: center;
  position: absolute;
  top: 14px;
  left: 14px;
  z-index: 9999;
}
.map_search {
  width: 320px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
::v-deep .el-autocomplete {
  width: 100%;
  height: 100%;
}
.map_content {
  width: 100%;
  height: 100%;
}
.show {
  margin-top: 30px;
}
.address_name {
  width: 100%;
  padding-left: 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.address_bi {
  color: #f0142f;
  margin: 0 15px 0 6px;
}
.address_specific {
  width: 400px;
  height: 40px;
  border-radius: 6px 6px 6px 6px;
  border: 1px solid #d0d0d0;
}
.address_range {
  margin-top: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 70px;
}
.map_bto {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 26px;
}
.cancel {
  width: 100px;
  height: 40px;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #d5d7e3;
  text-align: center;
  line-height: 40px;
  font-size: 15px;
  color: #7e84a3;
  margin-right: 30px;
  cursor: pointer;
}
.confim {
  width: 100px;
  height: 40px;
  background: #0058ff;
  text-align: center;
  line-height: 40px;
  color: white;
  font-size: 15px;
  border-radius: 4px 4px 4px 4px;
  cursor: pointer;
}
/* 打卡人员弹窗 */
.diglog_h {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  padding: 24px;
  border-bottom: 1px solid #cfd4e8;
  color: #1a1a1a;
}

.diglog_icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.diglog_operation {
  margin: 10px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dialog-range-body {
  height: 583px;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #d0d0d0;
  margin: 0 10px;
  display: flex;
}

.dialog-range-left {
  width: 50%;
  border-right: 1px solid #d0d0d0;
  padding: 20px;
}

.range_search {
  width: 100%;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #d0d0d0;
  display: flex;
  align-items: center;
  padding-right: 12px;
  box-sizing: border-box;
  justify-content: space-between;
}

.range_search:focus-within {
  border-color: #0096fa;
}

::v-deep .range_search .el-input__inner {
  height: 36px;
  border: none;
}

.range-left-all {
  padding-top: 20px;
  margin-bottom: 10px;
}

.crumbs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  color: #5c5c5c;
}

.range-left-user {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  line-height: 40px;
}

.dialog-range-right {
  padding: 20px;
  width: 50%;
}

.right-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 15px;
  font-size: 15px;
  max-height: 500px;
}
.right-list::-webkit-scrollbar {
  display: none;
}
.right-list2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  line-height: 32px;
  margin-top: 15px;
  font-size: 15px;
  height: 528px;
}
.selectoption_none {
  height: 490px;
  width: 100%;
  font-size: 15px;
  text-align: center;

  line-height: 490px;
}
.selectoption_t {
  height: 36px;
  display: flex;
  justify-content: space-between;
}
.map_operation {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  z-index: 9999;
  top: 14px;
  right: 20px;
  width: 38px;
  height: 72px;
  background: #ffffff;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);
  border-radius: 2px 2px 2px 2px;
}
.operation_icon {
  width: 14px;
  height: 14px;
  cursor: pointer;
}
.phone_reporting {
  width: 116px;
  height: 38px;
  margin-left: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.2);
  border-radius: 4px 4px 4px 4px;
  font-size: 15px;
  color: #5d5d5d;
  cursor: pointer;
}
.phone_icon {
  width: 15px;
  height: 21px;
  margin-right: 12px;
}
.reporting {
  width: 180px;
  height: 204px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: #1a1a1a;
  border-radius: 4px 4px 4px 4px;
}
.box2 {
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px !important;
  height: 140px !important;
}

/* ---- */
.distribution_checkbox {
  height: 400px;
  overflow: auto;
}

.distribution_checkbox_option {
  display: flex;
  flex-direction: row;
  line-height: 40px;
  font-size: 14px;
}

.distribution_checkbox_link {
  margin-left: auto;
  margin-right: 0px;
  font-size: 12px;
}
.selectItem {
  display: flex;
  align-items: center;
}
::v-deep .el-checkbox__label {
  color: #1a1a1a;
  font-weight: normal;
  font-size: 15px;
  line-height: 40px;
}
::v-deep .selectItem > .el-checkbox__label {
  font-size: 1rem;
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.distribution_checkbox_link {
  margin-left: auto;
  margin-right: 0px;
  font-size: 12px;
}
.selectoption_single {
  background: #e0ecff;
  border-radius: 4px;
  padding: 6px;
  color: #0058ff;
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-right: 16px;
  margin-bottom: 5px;
  line-height: 30px;
}

.selectoption_none {
  height: 490px;
  width: 100%;
  font-size: 15px;
  text-align: center;

  line-height: 490px;
}
.available_frame {
  width: 797px;
  height: 486px;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #d0d0d0;
  margin: 30px auto;
  display: flex;
}
.available_frame_left {
  width: 346px;
  height: 100%;
  border-right: 1px solid #d0d0d0;
}
.available_title {
  width: 100%;
  height: 45px;
  background: #ededed;
  padding-left: 20px;
  box-sizing: border-box;
  line-height: 45px;
  color: #1a1a1a;
  border-radius: 3px 0px 0px 0px;
}
.available_frame_center {
  width: 224px;
  height: 100%;
  border-right: 1px solid #d0d0d0;
}
.available_frame_right {
  flex: 1;
  height: 100%;
}
.available_search {
  width: 307px;
  margin: 15px auto 0;
  height: 36px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #d0d0d0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 16px;
  box-sizing: border-box;
}
.available_search_icon {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.available_search_input {
  height: 98%;
}
::v-deep .available_search .el-input__inner {
  height: 100%;
  border: none;
}
.breadcrumbs {
  display: flex;
  margin: 20px 0;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 20px;
  box-sizing: border-box;
}
.breadcrumbs_text {
  cursor: pointer;
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.breadcrumbs_current {
  color: #1a1a1a;
}
.breadcrumbs_last {
  color: rgba(26, 26, 26, 0.6);
}
.available_depa {
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
  cursor: pointer;
}
.available_depa_left {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #1a1a1a;
}
.available_depa_left_icon {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #cbe7ff;
  margin-right: 10px;
}
.available_depa_left_icon2 {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #cbe7ff;
  margin: 0 8px 0 0;
}
.available_depa_left_icon img {
  width: 18px;
  height: 18px;
}
.available_depa_left_icon2 img {
  width: 18px;
  height: 18px;
}
.available_depa_left_next {
  color: #0058ff;
  font-size: 15px;
  cursor: pointer;
}
.available_frame_tobeselected {
  margin-top: 12px;
}
.available_frame_tobeselected_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: rgba(26, 26, 26, 0.6);
  padding: 0 12px;
  box-sizing: border-box;
}
.available_frame_tobeselected_delte {
  font-size: 14px;
  color: rgba(26, 26, 26, 0.6);
  padding: 0 12px;
  text-align: right;
  cursor: pointer;
}
::v-deep .available_frame_tobeselected_top .el-checkbox__label {
  font-size: 14px;
  color: rgba(26, 26, 26, 0.6);
  font-weight: normal;
}
.available_frame_tobeselected_center {
  margin-top: 15px;
  height: 350px;
  overflow-y: scroll;
}
.available_frame_tobeselected_center::-webkit-scrollbar {
  display: none;
}
.available_frame_tobeselected_single {
  display: flex;
  height: 56px;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
}
.available_frame_tobeselected_single2 {
  display: flex;
  height: 56px;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
}
.available_frame_tobeselected_name {
  font-size: 15px;
  color: #1a1a1a;
}
.available_frame_tobeselected_depa {
  font-size: 13px;
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: rgba(26, 26, 26, 0.6);
}
.available_frame_select_left {
  display: flex;
  align-items: center;
}
.available_frame_select_right {
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.available_frame_bto {
  display: flex;
  align-items: center;
  justify-content: center;
}
.available_frame_cancel {
  width: 100px;
  height: 40px;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #d5d7e3;
  font-size: 15px;
  color: #7e84a3;
  margin-right: 30px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}
.available_frame_comfin {
  width: 100px;
  height: 40px;
  background: #0058ff;
  border-radius: 4px 4px 4px 4px;
  font-size: 15px;
  color: white;
  text-align: center;
  cursor: pointer;
}
.newpagination {
  height: 50px;
  margin-top: 10px;
}
.available_depaall {
  height: 330px;
  overflow-y: scroll;
}
.breadcrumbs_all {
  display: flex;
  align-items: center;
}
::v-deep .available_search .el-select {
  width: 120px;
  border-right: 1px solid #d0d0d0;
}
</style>