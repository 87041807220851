<template>
  <div class="detail">
    <div class="detail_h">
      <div>人员明细</div>
      <img
        src="@/assets/structure/close.png"
        class="detail_icon"
        @click="close"
      />
    </div>
    <div class="detail_content">
      <el-table
        :data="tableData"
        :header-cell-style="getRowClass"
        class="d_table"
      >
        <template slot="empty">
          <div>
            <img src="@/assets/nodata.png" class="nodata" />
            <div>无数据</div>
          </div>
        </template>
        <el-table-column prop="name" label="姓名"> </el-table-column>
        <el-table-column prop="jobNumber" label="员工工号"> </el-table-column>
        <el-table-column label="部门">
          <template slot-scope="scope">
            <span>{{ scope.row.umsDepartment.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="job" label="职位"> </el-table-column>
        <!-- <el-table-column label="操作" width="300" fixed="right">
          <template slot-scope="scope">
            <el-link
              type="primary"
              style="margin-right: 24px"
              @click="clockin(scope.row.id)"
              >打卡</el-link
            >
          </template>
        </el-table-column> -->
      </el-table>
    </div>
    <div class="sing">
      <el-pagination
        v-if="pages.total > 0"
        layout="prev, pager, next"
        :total="pages.total"
        :page-size="7"
        :current-page="pages.pageNum"
        @current-change="chagePage"
      >
      </el-pagination>
    </div>

    <el-dialog
      title="选择时间"
      :visible.sync="dialogVisible"
      :modal="false"
      width="30%"
      :before-close="handleClose"
    >
      <el-date-picker
        v-model="value1"
        type="datetime"
        class="datetime"
        placeholder="选择日期时间"
      >
      </el-date-picker>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="chockincomfin">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import http from "../../utils/http";
export default {
  data() {
    return {
      tableData: [],
      value1: "",
      selectId: null,
      dialogVisible: false,
      pages: {},
    };
  },
  props: ["id"],
  created() {
    this.getDetail({
      pageNum: 1,
    });
  },
  methods: {
    // 关闭弹窗
    close() {
      this.$emit("close");
    },
    // 表头背景颜色变色
    getRowClass({ rowIndex, columnIndex }) {
      if (rowIndex == 0) {
        return "background:#F5F6FA;color:#5C5C5C";
      }
    },

    // 模拟打卡
    clockin(id) {
      this.dialogVisible = true;
      this.selectId = id;
    },

    handleClose() {
      this.selectId = null;
      this.value1 = "";
      this.dialogVisible = false;
    },
    // 确定打卡
    chockincomfin() {
      if (this.value1 == "") {
        return;
      }
      var string = this.formatDate(this.value1);
      http
        .post("/bmsAttendanceClock/add/simulate", {
          clockTime: string,
          user: this.selectId,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("打卡成功");
            this.handleClose();
          } else {
            this.$message.error(res.message);
          }
        });
    },
    formatDate(date) {
      var date = new Date(date);
      var YY = date.getFullYear();
      var MM =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var hh =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var mm =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var ss =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return YY + "-" + MM + "-" + DD + " " + hh + mm + ss;
    },

    // 获取人员明细明细
    getDetail(val) {
      http
        .get(
          "/bmsAttendanceGroup/get/people",
          Object.assign(
            {
              id: this.id,
              pageSize: 7,
            },
            val
          )
        )
        .then((res) => {
          if (res.code == 200) {
            this.pages = res.data;
            this.tableData = res.data.list;
          }
        });
    },

    // 页数改变
    chagePage(e) {
      this.getDetail({
        pageNum: e,
      });
    },
  },
};
</script>

<style scoped>
.detail_h {
  height: 73px;
  padding: 0 24px;
  color: #1a1a1a;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 18px;
  justify-content: space-between;
  border-bottom: 1px solid #cfd4e8;
}
.detail_icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.detail_content {
  height: 550px;
  padding: 25px 30px;
  box-sizing: border-box;
}
.sing {
  display: flex;
  justify-content: center;
  align-items: center;
}
.come {
  width: 62px;
  height: 26px;
  background: #e0ecff;
  color: #0058ff;
  font-size: 14px;
  text-align: center;
  line-height: 26px;
}
.future {
  width: 62px;
  height: 26px;
  background: #edeeee;
  color: #5c5c5c;
  font-size: 14px;
  text-align: center;
  line-height: 26px;
}
::v-deep .el-table__body .el-table__row.hover-row td {
  background-color: rgba(0, 98, 255, 0.05);
}
::v-deep .el-table .cell {
  padding-left: 15px;
}
.d_table {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.d_table::-webkit-scrollbar {
  display: none;
}
.datetime {
  margin: 20px;
}
</style>
