<template>
  <div class="record common-style common-table_style">
    <div class="record_head">
      <span style="color: #b2b2b2">通行记录 /</span>
      <span style="color: #5c5c5c"> 通行记录</span>
    </div>
    <div class="record_wrapper">
      <div class="record_search_wrapper">
        <div class="record_search">
          <div class="record_search_item">
            <el-date-picker
              v-model="totaltime"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </div>

          <div class="record_search_item">
            <div
              v-if="isFiltrate && filtrateResult.length > 0"
              class="filtrate_wrapper"
            >
              <el-tooltip
                class="item"
                effect="dark"
                placement="top"
                :content="formatRecord(filtrateResult)"
              >
                <div class="filtrate">
                  <div class="filtrate_single">
                    <div class="filtrate_single_a" slot="reference">
                      {{ formatRecord(filtrateResult) }}
                    </div>
                    <img
                      src="@/assets/structure/optionclose.png"
                      class="filtrate_icon"
                      @click="delectscreening()"
                    />
                  </div>
                </div>
              </el-tooltip>
            </div>
            <div v-else class="device_search_h">
              <!-- <el-input placeholder="请选择人员范围" readonly="" class="device_search_t">
              </el-input> -->
              <el-select
                placeholder="请选择人员范围"
                class="device_search_t"
                @click.native="modelClick()"
                ref="selectRef"
                v-model="memberRange"
              ></el-select>
            </div>
          </div>
          <div class="device_search_h record_search_item">
            <el-select
              v-if="passform.peopleType !== null"
              v-model="passform.peopleType"
              clearable
              placeholder="请选择人员类型"
              @clear="clearType()"
              class="device_search_t"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-select
              v-else
              v-model="passform.peopleType"
              placeholder="请选择人员类型"
              class="device_search_t"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="record_search_item record_search_item_n">
            <div class="approval_search">
              <el-input
                v-model="passform.nameAndSNLike"
                @keyup.enter.native="onSearch(2)"
                placeholder="搜索设备名称、SN序列号"
                class="department_search_inp"
                style="width: 200px"
              ></el-input>

              <img
                src="@/assets/structure/delete.png"
                class="searchicon"
                @click="clearup"
                v-if="searchPass"
              />
              <img
                src="@/assets/structure/search.png"
                class="searchicon"
                @click="onSearch(2)"
              />
            </div>
          </div>
          <el-button type="primary" @click="onSearch">查询</el-button>
        </div>
        <div class="record_export">
          <el-button style="color: #7e84a3" @click="startDownload()"
            >批量导出</el-button
          >
        </div>
      </div>
      <div class="record_table_wrapper">
        <el-table
          ref="multipleTable"
          :data="passList"
          :header-cell-style="{
            background: '#F5F6FA',
            color: '#5C5C5C',
          }"
          height="48rem"
          class="record_table common_text"
          @selection-change="handleSelectionChange"
        >
          <template slot="empty">
            <div>
              <img src="@/assets/nodata.png" class="nodata_common" />
              <div class="nodata_text">无数据</div>
            </div>
          </template>
          <el-table-column
            type="selection"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="peopleType"
            label="人员类型"
            header-align="left"
            align="left"
            width="120"
          >
            <template slot-scope="scope">
              <span v-show="scope.row.peopleType == 0">员工</span>
              <span v-show="scope.row.peopleType == 1">访客</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="jobNumber"
            label="工号"
            header-align="left"
            align="left"
            width="140"
          >
          </el-table-column>
          <el-table-column
            prop="name"
            label="姓名"
            header-align="left"
            align="left"
            width="140"
          >
          </el-table-column>
          <el-table-column
            prop="departmentName"
            label="部门"
            header-align="left"
            align="left"
            width="140"
          >
          </el-table-column>
          <el-table-column
            prop="passTime"
            label="通行时间"
            header-align="left"
            align="left"
            width="190"
          >
          </el-table-column>
          <el-table-column
            prop="deviceName"
            label="设备名称"
            header-align="left"
            align="left"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="sn"
            label="SN序列号"
            header-align="left"
            align="left"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="direction"
            label="通行方向"
            header-align="left"
            align="left"
            width="150"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.direction == 0">进向</span>
              <span v-else-if="scope.row.direction == 1">出向</span>
              <span v-else>不限</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="category"
            label="设备种类"
            header-align="left"
            align="left"
            width="150"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.category == 0" class="device_type"
                >门禁</span
              >
              <span v-else class="device_type">考勤</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="recognizeType"
            label="识别方式"
            header-align="left"
            align="left"
            width="200"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.recognizeType == 0">人脸</span>
              <span v-else-if="scope.row.recognizeType == 1">指纹</span>
              <span v-else-if="scope.row.recognizeType == 2">密码</span>
              <span v-else-if="scope.row.recognizeType == 3">刷卡</span>
              <span v-else-if="scope.row.recognizeType == 4">扫码</span>
              <span v-else></span>
            </template>
          </el-table-column>
          <el-table-column
            prop="registerHeadUrl"
            label="登记照片"
            header-align="left"
            align="left"
            width="200"
          >
            <template slot-scope="scope">
              <img
                :src="scope.row.registerHeadUrl"
                alt=""
                @click="traUrl(scope.row.registerHeadUrl, 1)"
                class="record-img"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="headUrl"
            label="实时照片"
            header-align="left"
            align="left"
          >
            <template slot-scope="scope">
              <img
                :src="scope.row.headUrl"
                class="record-img"
                @click="traUrl(scope.row.headUrl, 2)"
              />
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div
        v-if="this.passList != 0"
        class="newpagination"
        style="padding-left: 20px; box-sizing: border-box"
      >
        <div class="newpagination_text">共 {{ pages.total }} 条数据</div>
        <el-pagination
          layout="prev, pager, next"
          :total="pages.total"
          :current-page="pages.pageNum"
          :page-size="50"
          @current-change="changePage"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 实时图片 -->
    <el-dialog
      :visible.sync="openImg"
      custom-class="img-dialog common-style"
      :close-on-click-modal="false"
      @close="cancelRange"
    >
      <template slot="title">
        <div v-if="imgNum == 1" class="img-dialog_title">登记照片</div>
        <div v-if="imgNum == 2" class="img-dialog_title">实时照片</div>
      </template>

      <img class="img-dialog-body" :src="bigImg" alt="" />
    </el-dialog>
    <el-dialog
      width="1000px"
      height="827px"
      title="请选择人员范围"
      :visible.sync="dialogRange"
      custom-class="dialog-range common-style"
      :close-on-click-modal="false"
      @close="cancelRange"
    >
      <div class="dialog-range-body">
        <div class="dialog-range-left">
          <div class="range_search">
            <el-input
              v-model="search"
              placeholder="输入员工姓名、工号"
              @keyup.enter.native="seachResult"
            ></el-input>
            <img
              src="@/assets/structure/delete.png"
              class="searchicon"
              @click="clearName"
              v-if="searchRange"
            />
            <img
              src="@/assets/structure/search.png"
              class="searchicon"
              @click="seachResult"
            />
          </div>
          <div class="range-left-all">
            <div class="crumbs" v-if="!isSearchResult">
              <div v-if="crumbsList.length == 1">
                <div
                  v-for="(item, index) in crumbsList"
                  :key="index"
                  @click="breadDept(item, index)"
                >
                  &nbsp;{{ item.name }}
                </div>
              </div>
              <div class="crumbs" v-else>
                <div
                  v-for="(item, index) in crumbsList"
                  :key="index"
                  @click="breadDept(item, index)"
                >
                  &nbsp;{{ item.name }}<span> / </span>
                </div>
              </div>
            </div>
            <div v-else>搜索结果</div>
          </div>
          <div class="distribution_checkbox">
            <div
              class="distribution_checkbox_option"
              v-for="item in deptList"
              :key="item.id"
            >
              <el-checkbox
                v-model="item.ischecked"
                @change="selectDept2(item)"
                >{{ item.name }}</el-checkbox
              >
              <el-link
                :disabled="item.ischecked"
                type="primary"
                @click="querySubordinates(item)"
                v-if="!item.userId && !item.jobDepartment"
                class="distribution_checkbox_link"
                >下级</el-link
              >
            </div>
          </div>
        </div>
        <div class="dialog-range-right">
          <div class="selectoption">
            <div class="selectoption_t">
              <div class="selectoption_t_all">
                已选<span :class="[checkList.length > 0 ? 'discolour' : '']"
                  >({{ checkList.length }})</span
                >
              </div>
              <div>
                <el-link type="primary" @click="clearall">清除</el-link>
              </div>
            </div>
            <div class="right-list" v-if="this.checkList.length != 0">
              <div
                class="selectoption_single"
                v-for="item in checkList"
                :key="item.id"
              >
                <div>{{ item.name }}</div>
                <img
                  @click="delectDept(item)"
                  src="@/assets/structure/optionclose.png"
                  class="icon-close"
                />
              </div>
            </div>
            <div class="right-list2" v-else>
              <div class="selectoption_none">暂无内容，请在左侧勾选</div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer common-style">
        <el-button
          class="add_confirm"
          style="background: none; margin-right: 15px"
          @click="cancelRange"
          >取 消</el-button
        >
        <el-button type="primary" class="add_confirm" @click="searchUserList"
          >确 认</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import http from "../../utils/http";
import table2excel from "js-table2excel";
export default {
  components: {},
  data() {
    return {
      noimg: "@/assets/structure/close.png",
      prangge: {},
      passList: [],
      passform: { nameAndSNLike: "" },
      totaltime: null,
      openImg: false, //实时照片弹窗
      dialogRange: false, //选择人员范围
      isSearchResult: false,
      imgNum: 0,
      bigImg: "",
      tableData: [],
      value: "",
      options: [
        {
          value: 0,
          label: "员工",
        },
        {
          value: 1,
          label: "访客",
        },
      ],
      checkedCities: [],
      checklist: [],
      isIndeterminate: true,
      pickedList: [],
      pages: {}, //分页
      content: "",
      flag: 0,
      conditionObj: {}, //搜索条件
      memberRange: "",
      tableColumn: [
        {
          title: "人员类型",
          key: "peopleType",
          type: "text",
        },
        {
          title: "工号",
          key: "jobNumber",
          type: "text",
        },
        {
          title: "姓名",
          key: "name",
          type: "text",
        },
        {
          title: "部门",
          key: "departmentName",
          type: "text",
        },
        {
          title: "通行时间",
          key: "passTime",
          type: "text",
        },
        {
          title: "设备名称",
          key: "deviceName",
          type: "text",
        },
        {
          title: "SN序列号",
          key: "sn",
          type: "text",
        },
        {
          title: "通行方向",
          key: "direction",
          type: "text",
        },
        {
          title: "设备种类",
          key: "category",
          type: "text",
        },
        {
          title: "识别方式",
          key: "recognizeType",
          type: "text",
        },
        {
          title: "登记图片",
          key: "registerHeadUrl",
          type: "image",
          width: 50,
          height: 80,
        },
        {
          title: "实时图片",
          key: "headUrl",
          type: "image",
          width: 50,
          height: 80,
        },
      ],
      formScree: {},
      data: [],
      defaultProps: {
        children: "subDepartment",
        label: "departmentName",
      },
      search: "", //搜索内容
      topleave: [], //部门列表
      crumbsList: [
        {
          name: "全部",
          departmentId: "",
        },
      ], //面包屑
      deptList: [],
      crumblist: [], //标题
      //面包屑
      crumbscopyList: [
        {
          name: "全部",
          departmentId: null,
        },
      ], //深层面包屑
      crumbscopyListcopy: [
        {
          name: "全部",
          departmentId: null,
        },
      ],
      checkList: [], //所选的列表
      checkPersion: [], //所选的人员
      checkDepartment: [], //所选的部门
      tableSelection: [], //导出表格需要
      multipleSelection: [],
      multipleSelectionCopy: [], //多选操作，不做编译
      isFiltrate: false, //是否进行了筛选
      filtrateResult: [], //筛选字段
      filtrateNum: 0, //筛选数量
      searchPass: false,
      searchRange: false,
    };
  },
  created() {
    this.getPassList({ pageNum: 1 });
    // 获取企业名称
    var company = localStorage.getItem("department");
    this.topleave = JSON.parse(company);

    this.deptList.push({
      name: this.topleave[0].departmentName,
      departmentId: "",
      ischecked: false,
    });
  },
  watch: {
    search(newName, oldName) {
      if (newName == "") {
        this.searchRange = false;
        this.isSearchResult = false;

        this.deptList = [];

        // 获取企业名称
        var company = localStorage.getItem("department");
        this.topleave = JSON.parse(company);

        this.deptList.push({
          name: this.topleave[0].departmentName,
          departmentId: "",
          ischecked: false,
        });

        this.crumbsList = [
          {
            name: "全部",
            departmentId: null,
          },
        ];
      } else {
        this.searchRange = true;
      }
    },
    "passform.nameAndSNLike"(newName, oldName) {
      if (newName) {
        this.searchPass = true;
      } else {
        this.searchPass = false;
      }
    },
  },

  mounted() {},
  methods: {
    formatRecord(list) {
      if (list.length == 1) return list[0];
      else return list.join(",");
    },
    // 删除某个筛选(1是部门，2是性别，3是入职日期)
    delectscreening() {
      this.filtrateResult = [];
      this.isFiltrate = false;
      this.prangge = {};
      // this.getPassList({ pageNum: 1 });
    },
    // 筛选员工
    dressing(value) {
      this.dialogRange = false;
      this.$nextTick(() => {
        value.map((item) => {
          this.filtrateResult.push(item.name);
        });
      });
      this.isFiltrate = true;
      // 清空输入框
      // this.totaltime =null;
      // this.passform = {};
    },
    searchUser() {
      this.checklist = [];

      let nS = {
        content: this.content,
        type: 1,
      };
      http.get("/user/list", nS).then((res) => {
        if (res.code == 200) {
          let arr = res.data.list;
          arr.map((item) => {
            if (this.checklist.indexOf(item.id) === -1) {
              this.checklist.push(item);
            }
          });
          if (!this.checkNum() || this.content == "") this.flag = 1;
          else this.flag = 2;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    searchUserList() {
      if (this.checkList <= 0) {
        this.$message.error("请先勾选部门／员工");
        return;
      }
      // 获取企业名称
      var user = localStorage.getItem("userinfo");
      var company = JSON.parse(user);
      var obj = {};

      var arr2 = []; //部门
      var arr3 = []; //人员
      this.checkDepartment.map((item) => {
        arr2.push(item.departmentId);
      });
      this.checkPersion.map((item) => {
        arr3.push(item.userId);
      });
      if (arr2.length == 0) arr2 = null;
      if (arr3.length == 0) arr3 = null;
      var index = this.checkList.findIndex((item) => item.departmentId == "");
      if (index > -1) {
        // 如果存在的话
        obj = {
          companyId: company.jobCompany,
          pageNum: 1,
        };
      } else {
        obj = {
          departmentIdList: arr2,
          userIdList: arr3,
          pageNum: 1,
        };
      }
      this.prangge = obj;
      this.dressing(this.checkList);
      // this.getPassList(obj);
    },

    checkNum() {
      const re = /^[+-]?\d*(\.\d*)?(e[+-]?\d+)?$/;
      if (re.test(this.content)) return true;
      else return false;
    },
    getPassList(form) {
      let nS = {};
      if (this.totaltime != null)
        nS = {
          startDate: this.totaltime[0],
          endDate: this.totaltime[1],
        };
      let aaa = { ...this.prangge, ...this.passform, ...nS };

      http.post("/pass/list", { ...form, pageSize: 50, ...aaa }).then((res) => {
        if (res.code == 200) {
          this.pages = res.data;
          this.passList = res.data.list;
          this.$refs.multipleTable.doLayout();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    onSearch(num) {
      // let a = this.totaltime;
      // let nS = {};
      // switch (num) {
      //   case 1:
      //     if (a == null) {
      //       this.getPassList({ pageNum: 1 });
      //       return;
      //     }
      //     nS = {
      //       startDate: a[0],
      //       endDate: a[1],
      //     };
      //     this.passform = {};
      //     this.filtrateResult = [];
      //     this.prangge = {};
      //     break;
      //   case 2:
      //     nS = {
      //       nameAndSNLike: this.passform.nameAndSNLike,
      //     };
      //     this.passform.peopleType = "";
      //     this.totaltime = "";
      //     this.filtrateResult = [];
      //     this.prangge = {};
      //     break;
      //   case 4:
      //     nS = {
      //       peopleType: this.passform.peopleType,
      //     };
      //     this.passform.nameAndSNLike = "";
      //     this.totaltime = "";
      //     this.filtrateResult = [];
      //     this.prangge = {};
      //     break;
      // }
      // this.conditionObj = nS;
      this.getPassList({ pageNum: 1 });
    },
    clearup() {
      this.passform.nameAndSNLike = "";
      // this.getPassList({
      //   pageNum: 1,
      // });
    },
    clearName() {
      this.search = "";
    },
    //打开人员范围
    openRange() {
      this.content = "";
      this.dialogRange = true;
    },
    modelClick() {
      this.$refs.selectRef.blur();
      this.openRange();
    },
    // 表格下载之前
    startDownload() {
      var arr = JSON.parse(JSON.stringify(this.multipleSelectionCopy));
      if (arr.length == 0) {
        this.$message.error("请先选择通行记录");
        return;
      }
      let arr2 = [];
      for (let key of this.tableColumn) {
        arr2.push(key.key);
      }

      if (arr) {
        if (arr.length === 0) {
          this.$message.info("暂无数据可导出！");
        } else {
          arr.map((v) => {
            if (v.category == 0) {
              v.category = "门禁";
            } else {
              v.category = "考勤";
            }
            if (v.direction == 0) {
              v.direction = "进向";
            } else if (v.direction == 1) {
              v.direction = "出向";
            } else {
              v.direction = "不限";
            }
            if (v.peopleType == 0) {
              v.peopleType = "员工 ";
            } else {
              v.peopleType = "访客";
            }
            switch (v.recognizeType) {
              case 0:
                v.recognizeType = "人脸";
                break;
              case 1:
                v.recognizeType = "指纹";
                break;
              case 2:
                v.recognizeType = "密码";
                break;
              case 3:
                v.recognizeType = "刷卡";
                break;
              case 4:
                v.recognizeType = "扫码";
                break;
            }
            if (v.passTime) {
              v.passTime = v.passTime.split(" ").join("\xa0");
            }
            if (v.jobNumber) v.jobNumber = "\xa0" + v.jobNumber;
            for (let i = 0; i < arr2.length; i++) {
              if (v[arr2[i]] == undefined) {
                v[arr2[i]] = "";
              }
            }
            for (let key in v) {
              if (v[key] == null) v[key] = "";
            }
          });
        }

        table2excel(this.tableColumn, arr, "通行记录.xlsx"); //生成Excel表格
        this.$message.success("导出成功");
        // 清空所有选中状态
        this.$refs.multipleTable.clearSelection();
      }
    },

    //点击下级
    nextDepart(i) {
      this.crumblist.push(i);
      this.getAlldepart({ id: i.departmentId });
    },
    //回到上一级
    back() {},
    traUrl(url, num) {
      this.imgNum = num;
      this.openImg = true;
      this.bigImg = url;
    },
    // 表格多选
    handleSelectionChange(val) {
      this.multipleSelection = [];
      this.multipleSelectionCopy = Array.from(val);
      val.map((item) => {
        this.multipleSelection.push(item.id);
      });
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.checklist.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.checklist.length;
    },
    // 页数改变
    changePage(e) {
      // let a = this.totaltime;
      // let obj = {};
      // if (a == null || a == '') {
      //   obj = { ...this.prangge, ...this.passform, };
      // }
      // else {

      //   obj = {
      //     ...this.prangge, ...this.passform, startDate: a[0],
      //     endDate: a[1],
      //   };
      // }
      // console.log(obj)
      this.getPassList({ pageNum: e });
    },
    checkdepart() {
      this.pickedList = [];

      for (let i = 0; i < this.deptList.length; i++) {
        this.pickedList.push(this.deptList[i]);
      }
    },

    // 选择部门
    selectDept(item) {
      if (this.deptList.length > 0) {
        var onslie = this.checkList.findIndex((value) => value.id == item.id);
        if (onslie > -1) {
          this.checkList.splice(onslie, 1);
        } else {
          this.checkList.push(item);
        }
      } else {
        this.checkList.push(item);
      }
    },
    clearType() {
      this.passform.peopleType = null;
    },
    //十进制数转为对应的开门方式
    resolve(num) {
      let a = parseInt(num.toString(2));
      let b = (Array(5).join(0) + a).slice(-5);
      let arr = [];
      b.split("").forEach((index, item) => {
        if (index == 1) {
          switch (item) {
            case 0:
              arr.push("人脸");
              break;
            case 1:
              arr.push("指纹");
              break;
            case 2:
              arr.push("密码");
              break;
            case 3:
              arr.push("刷卡");
              break;
            case 4:
              arr.push("扫码");
              break;
          }
        }
      });
      if (arr.length <= 0) return "-";
      if (arr.length == 1) return arr.join("");
      else return arr.join("/");
    },
    // 获取部门列表
    getDeptlist(id) {
      http
        .get("/department/push_list", {
          departmentId: id,
        })
        .then((res) => {
          if (res.code == 200) {
            var index = this.crumbscopyList.findIndex(
              (item) => item.departmentId == id
            );
            res.data.map((item) => {
              item.ischecked = false;
            });

            res.data.map((item) => {
              item.ischecked = this.judgeStaus(item);
            });
            this.crumbscopyList[index].child = res.data;
            this.deptList = res.data;
          }
        });
    },
    // 获取搜索结果
    seachResult() {
      if (this.search == "") {
        return;
      }
      http
        .get("/department/push_list", {
          userName: this.search,
        })
        .then((res) => {
          if (res.code == 200) {
            this.isSearchResult = true;
            res.data.map((item) => {
              item.ischecked = this.judgeStaus(item);
            });
            this.deptList = res.data;
          }
        });
    },

    // 点击面包屑部门
    breadDept(item, index) {
      this.crumbsList.splice(index + 1, this.crumbsList.length - 1);

      var index = this.checkDepartment.findIndex(
        (item) => item.departmentId == ""
      );

      if (item.name == "全部") {
        if (index > -1) {
          this.deptList = [
            {
              name: this.topleave[0].departmentName,
              departmentId: null,
              ischecked: true,
            },
          ];
        } else {
          this.deptList = [
            {
              name: this.topleave[0].departmentName,
              departmentId: "",
              ischecked: false,
            },
          ];
        }
      } else {
        this.getDeptlist(item.departmentId);
      }
    },
    // 查询下级
    querySubordinates(item) {
      this.crumbsList.push({
        name: item.name,
        departmentId: item.departmentId,
      });
      // 深层次的面包屑
      this.crumbscopyList.push({
        name: item.name,
        departmentId: item.departmentId,
      });

      this.getDeptlist(item.departmentId);
    },
    // 判断是否是选中状态
    judgeStaus(value) {
      // 如果是人员
      if (value.userId || value.jobDepartment) {
        var index = this.checkPersion.findIndex(
          (item) => item.userId == value.userId
        );
        if (index > -1) {
          return true;
        } else {
          return false;
        }
      } else {
        var index = this.checkDepartment.findIndex(
          (item) => item.departmentId == value.departmentId
        );
        if (index > -1) {
          return true;
        } else {
          return false;
        }
      }
    },
    // 选择部门
    selectDept2(item) {
      item.path = [];
      // 处理所选的部门或人员,加路径部门
      this.crumbsList.map((value) => {
        item.path.push(value.departmentId);
      });
      // 如果是选中状态
      if (item.ischecked) {
        // 先判断是否在这路径上(人)
        if (item.userId) {
          // 如果是人
          this.checkPersion.push(item);
        } else {
          var arr = [];
          var arr2 = [];
          // 部门
          for (let index = 0; index < this.checkDepartment.length; index++) {
            for (
              let cindex = 0;
              cindex < this.checkDepartment[index].path.length;
              cindex++
            ) {
              if (
                this.checkDepartment[index].path[cindex] == item.departmentId
              ) {
                arr.push(this.checkDepartment[index]);
              }
            }
          }
          for (let index = 0; index < this.checkPersion.length; index++) {
            for (
              let cindex = 0;
              cindex < this.checkPersion[index].path.length;
              cindex++
            ) {
              if (this.checkPersion[index].path[cindex] == item.departmentId) {
                arr2.push(this.checkPersion[index]);
              }
            }
          }
          this.checkDepartment.push(item);

          let ids = arr.map((item) => item.departmentId);
          this.checkDepartment = this.checkDepartment.filter(
            (item) => !ids.includes(item.departmentId)
          );

          let ids2 = arr2.map((item) => item.departmentId);
          this.checkPersion = this.checkPersion.filter(
            (item) => !ids2.includes(item.departmentId)
          );
        }
      } else {
        // 取消选中(如果是人员)
        if (item.userId) {
          var index = this.checkPersion.findIndex(
            (value) => value.userId == item.userId
          );
          if (index > -1) {
            this.checkPersion.splice(index, 1);
          }
        } else {
          // 如果是部门
          var index = this.checkDepartment.findIndex(
            (value) => value.departmentId === item.departmentId
          );
          if (index > -1) {
            this.checkDepartment.splice(index, 1);
          }
        }
      }
      this.checkList = [...this.checkDepartment, ...this.checkPersion];
    },

    // 删除所选部门
    delectDept(item) {
      if (item.userId) {
        var onslie = this.checkPersion.findIndex(
          (value) => value.userId == item.userId
        );
        this.checkPersion.splice(onslie, 1);

        // 如果在当前列表
        var index = this.deptList.findIndex(
          (value) => value.userId == item.userId
        );
        if (index > -1) {
          this.deptList[index].ischecked = false;
        }
      } else {
        var onslie = this.checkDepartment.findIndex(
          (value) => value.departmentId == item.departmentId
        );
        this.checkDepartment.splice(onslie, 1);

        // 如果在当前列表
        var index = this.deptList.findIndex(
          (value) => value.departmentId == item.departmentId
        );
        if (index > -1) {
          this.deptList[index].ischecked = false;
        }
      }

      this.checkList = [...this.checkPersion, ...this.checkDepartment];
    },

    // 清除所有选择的部门
    clearall() {
      this.deptList.map((item) => (item.ischecked = false));
      this.checkList = [];
      this.checkDepartment = [];
      this.checkPersion = [];
    },
    cancelRange() {
      this.search = "";
      this.crumbsList = [
        {
          name: "全部",
          departmentId: "",
        },
      ];
      this.checkList = [];
      this.checkPersion = [];
      this.checkDepartment = [];
      this.filtrateResult = [];
      this.deptList.map((item) => (item.ischecked = false));

      this.deptList = [];
      var company = localStorage.getItem("department");
      this.topleave = JSON.parse(company);

      this.deptList.push({
        name: this.topleave[0].departmentName,
        departmentId: "",
        ischecked: false,
      });
      this.dialogRange = false;
    },
  },
};
</script>
<style scoped>
.record {
  /* width: 100%; */
  padding: 16px 20px;
  background: #f4f7fc;
  /* height: 100%; */
}

.record_head {
  font-size: 14px;
  color: #5c5c5c;
}

.record_wrapper {
  width: calc(100vw - 290px);
  height: 940px;
  background: #ffffff;
  box-shadow: 0px 1px 4px 1px rgba(21, 34, 50, 0.08);
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  margin-top: 16px;
}

.record_search_wrapper {
  display: flex;
  padding: 20px 20px 18px 20px;
  border-bottom: 1px solid rgba(207, 212, 232, 0.5);
}

.record_search {
  display: flex;
  justify-content: space-between;
}

.record_search_item {
  margin-right: 10px;
}

::v-deep .el-date-editor {
  width: 283px;
  height: 38px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #d0d0d0;
}

.approval_search {
  width: 243px;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #d0d0d0;
  display: flex;
  align-items: center;
  padding-right: 12px;
  box-sizing: border-box;
  justify-content: space-between;
}

.approval_search:focus-within {
  border-color: #0096fa;
}

.record_export {
  margin-left: auto;
  margin-right: 0;
}

.device_search_b {
  border: none;
  padding-left: 0;
  margin-top: 1px;
  padding-right: 10px;
  background: none;
}

.device_search_b:hover {
  background: none;
}

/* ::v-deep .device_search_t .el-input__inner {
    background: url("@/assets/up.png") no-repeat center center;
    background-size: 19px 19px;
    background-position: 170px 10px;
    padding: 0 0px 0 auto;
    box-sizing: border-box;
  }



  ::v-deep .device_search_t .el-icon-arrow-up {
    display: none;
  } */

::v-deep .device_search_t .el-input {
  width: 200px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #d0d0d0;
}

::v-deep .device_search_h .el-input {
  width: 200px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #d0d0d0;
}

::v-deep .device_search_h .el-input__inner {
  border: none;
  height: 36px;
}

::v-deep .device_search_t .el-input__inner {
  border: none;
  height: 36px;
}

::v-deep .device_search_p .el-input__inner {
  border: 1px solid #d0d0d0;
  height: 36px;
}

::v-deep .record_search .el-range-editor .el-range-input::placeholder {
  font-size: 15px;
  font-weight: 400;
  color: #b2b2b2;
}

.record_table_wrapper {
  padding: 20px 18px 20px 20px;
  width: calc(100vw - 330px);
  font-size: 16px;
}

.record_table {
  white-space: nowrap;
  font-size: 16px;
}

.device_type {
  display: inline-block;
  width: 48px;
  height: 26px;
  background: #e0ecff;
  opacity: 1;
  text-align: center;
  color: #0058ff;
  line-height: 26px;
}

.record-img {
  width: 44px;
  height: 42px;
}

.img-dialog_title {
  font-size: 18px;
  font-weight: 400;
  color: #1a1a1a;
}

::v-deep .img-dialog {
  width: 540px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
}

::v-deep .img-dialog .el-dialog__body {
  text-align: center;
}

.img-dialog-body {
  width: 380px;
  height: 420px;
  margin-top: 60px;
  margin-bottom: 30px;
}

.dialog-range-body {
  height: 583px;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #d0d0d0;
  margin: 0 10px;
  display: flex;
}

.dialog-range-left {
  width: 50%;
  border-right: 1px solid #d0d0d0;
  padding: 20px;
}

.range_search {
  width: 100%;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #d0d0d0;
  display: flex;
  align-items: center;
  padding-right: 12px;
  box-sizing: border-box;
  justify-content: space-between;
}

.range_search:focus-within {
  border-color: #0096fa;
}

::v-deep .range_search .el-input__inner {
  height: 36px;
  border: none;
}

.range-left-all {
  padding-top: 20px;
  margin-bottom: 10px;
}

.crumbs {
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  color: #5c5c5c;
}

.range-left-user {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  line-height: 40px;
}

.dialog-range-right {
  padding: 20px;
  width: 50%;
}

.right-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 15px;
  font-size: 15px;
  max-height: 500px;
}
.right-list::-webkit-scrollbar{
  display: none;
}
.right-list2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  line-height: 32px;
  margin-top: 15px;
  font-size: 15px;
  height: 528px;
}

.selectoption_single {
  background: #e0ecff;
  border-radius: 4px;
  padding: 6px;
  color: #0058ff;
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-right: 16px;
  margin-bottom: 5px;
  line-height: 30px;
}

.selectoption_none {
  height: 490px;
  width: 100%;
  font-size: 15px;
  text-align: center;

  line-height: 490px;
}

.icon-close {
  color: #e0ecff;
  border-radius: 10px;
  margin-left: 5px;
}

.newpagination {
  display: flex;
  margin-top: 0px;
  justify-content: space-between;
}

.newpagination_text {
  font-size: 16px;
  color: #5c5c5c;
}

::v-deep .newpagination .el-pagination {
  margin: 0 20px 30px auto;
}

.distribution_checkbox {
  height: 400px;
  overflow: auto;
}

.distribution_checkbox_option {
  display: flex;
  width: 429px;
  flex-direction: row;
  line-height: 40px;
  font-size: 14px;
}

.distribution_checkbox_link {
  margin-left: auto;
  margin-right: 0px;
  font-size: 12px;
}

.selectoption_t {
  height: 36px;
  display: flex;
  justify-content: space-between;
}

.discolour {
  color: #0058ff;
}

.filtrate_wrapper {
  width: 200px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #d0d0d0;
}

.filtrate {
  display: flex;
  align-items: center;
}

.filtrate_single {
  margin: 5px 3px;
  padding: 3px 6px;
  color: #0058ff;
  font-size: 12px;
  display: flex;
  align-items: center;
  background: #e0ecff;
  border-radius: 4px;
  max-width: 190px;
  height: 20px;
}

.filtrate_single_a {
  width: auto;
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.filtrate_icon {
  margin-left: 6px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

::v-deep .el-link .is-disabled:focus {
  color: wheat;
}

::v-deep .right-list .selectoption_single {
  margin-bottom: 11px;
}

/* ::v-deep .common_text .el-table__empty-block {
  text-align: left;
} */
::v-deep.el-table .el-table__body td .cell:empty::after,
.el-table .el-table__body td .cell > span:empty::after,
.el-table .el-table__body td .cell .approval:empty::after,
.el-table .el-table__body td .cell div:empty::after,
.el-table .el-table__body td .cell .line-limit-length:empty::after {
  content: "--";
}

::v-deep .el-icon-circle-close {
  width: 30px;
  background: #ffffff;
}

::v-deep .record_search .el-button--primary {
  height: 38px;
  line-height: 38px;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: 20px;
}
</style>