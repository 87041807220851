<template>
  <div class="classes_form">
    <el-form
      :model="classesForm"
      label-position="top"
      :rules="rules"
      ref="addForm"
    >
      <!-- <el-form-item label="上下班次数" v-if="!isCheck">
            <el-radio-group v-model="classesForm.type">
              <el-radio-button :label="1">1次上下班</el-radio-button>
              <el-radio-button :label="2">2次上下班</el-radio-button>
              <el-radio-button :label="3">3次上下班</el-radio-button>
            </el-radio-group>
          </el-form-item> -->
      <el-form-item label="考勤时间">
        <div class="attendance">
          <div class="attendance_top">
            <div class="attendance_add" v-if="!isCheck">
              <img src="@/assets/class/add.png" class="attendance_add_icon" />
              <div @click="periodAdded">添加时段</div>
            </div>
            <div class="attendance_time">
              总工作时长:{{ calculateTotal(timedifference, restAlltime) }}
            </div>
          </div>
          <div class="attendance_all">
            <div class="attendance_all_header">
              <div class="attendance_all_header_left">
                <div class="workhour">上班时间</div>
                <div class="workrule">上班打卡规则</div>
                <div class="afterwordrule">下班打卡规则</div>
              </div>
              <div
                class="attendance_all_header_right"
                v-if="classesForm.arrangeDataList.length > 1 && !isCheck"
              >
                操作
              </div>
            </div>
            <div
              class="attendance_single"
              v-for="(item, index) in classesForm.arrangeDataList"
              :key="index"
            >
              <div class="workhour">
                <div class="workhour_time_up">
                  <div class="workhour_time">
                    <el-select
                      @change="triggerListening"
                      v-model="item.clockData.beginTimeData.tomorrow"
                      :disabled="index == 0 || isCheck"
                      placeholder="当日"
                    >
                      <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                    <div class="workhour_time_specific">
                      <el-time-picker
                        :clearable="false"
                        @change="triggerListening"
                        :editable="false"
                        v-model="item.clockData.beginTimeData.daySecond"
                        :disabled="isCheck"
                        placeholder="选择"
                        format="HH:mm"
                        value-format="HH:mm"
                      >
                      </el-time-picker>
                    </div>
                  </div>
                  <div class="error_tips" v-if="item.errortips">
                    {{ item.errortips }}
                  </div>
                  <el-checkbox
                    v-model="item.beginTimeChoose"
                    :disabled="index == 0 || isCheck"
                    >打上班卡</el-checkbox
                  >
                </div>
                <div class="workhour_time_down">
                  <div class="workhour_time">
                    <el-select
                      @change="triggerListening"
                      :disabled="isCheck"
                      v-model="item.clockData.endTimeData.tomorrow"
                      placeholder="当日"
                    >
                      <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                    <div class="workhour_time_specific">
                      <el-time-picker
                        :clearable="false"
                        :editable="false"
                        @change="triggerListening"
                        v-model="item.clockData.endTimeData.daySecond"
                        :disabled="isCheck"
                        placeholder="选择"
                        format="HH:mm"
                        value-format="HH:mm"
                      >
                      </el-time-picker>
                    </div>
                  </div>
                  <div class="error_tips" v-if="item.errortips2">
                    {{ item.errortips2 }}
                  </div>
                  <el-checkbox v-model="item.endTimeChoose" :disabled="isCheck"
                    >打下班卡</el-checkbox
                  >
                </div>
              </div>
              <div class="mt" v-if="item.beginTimeChoose">
                <div class="workrule">
                  <div class="attendance_single_belate">
                    <div>最早可提前</div>
                    <el-input-number
                      :disabled="isCheck"
                      :min="0"
                      v-model="item.beforeAttendanceTime"
                      controls-position="right"
                      class="attendance_single_num"
                    ></el-input-number>
                    <div>分钟打卡</div>
                  </div>
                  <div class="attendance_single_belate">
                    <div>最晚可延后</div>
                    <el-input-number
                      :disabled="isCheck"
                      :min="0"
                      v-model="item.beginNoneTime"
                      controls-position="right"
                      class="attendance_single_num"
                    ></el-input-number>
                    <div>分钟打卡</div>
                  </div>
                  <div class="attendance_single_belate">
                    <div>迟到</div>
                    <el-input-number
                      :disabled="isCheck"
                      v-model="item.beginIgnoreTime"
                      :min="0"
                      controls-position="right"
                      class="attendance_single_num"
                    ></el-input-number>
                    <div>分钟忽略不计</div>
                  </div>
                  <div class="error_tips2" v-if="item.errorignore">
                    {{ item.errorignore }}
                  </div>
                  <div class="attendance_single_belate">
                    <div>迟到</div>
                    <el-input-number
                      :disabled="isCheck"
                      v-model="item.beginAbsenceTime"
                      :min="0"
                      controls-position="right"
                      class="attendance_single_num"
                    ></el-input-number>
                    <div>分钟记为旷工</div>
                  </div>
                  <div class="error_tips2" v-if="item.errorAbsenteeism">
                    {{ item.errorAbsenteeism }}
                  </div>
                  <el-radio-group
                    v-model="item.beginChoose"
                    class="attendance_single_belate3"
                  >
                    <div class="attendance_single_checkin">
                      <el-radio
                        :label="1"
                        :disabled="isCheck"
                        text-color="#1A1A1A99"
                        class="attendance_single_checkbox"
                        >上班无签到记为迟到</el-radio
                      >
                      <div>迟到时长记为</div>
                      <el-input-number
                        :disabled="isCheck"
                        v-model="item.lateTime"
                        :min="0"
                        controls-position="right"
                        class="attendance_single_num"
                      ></el-input-number>
                      <div>分钟</div>
                    </div>
                    <div class="attendance_single_checkin">
                      <el-radio
                        :label="2"
                        :disabled="isCheck"
                        text-color="#1A1A1A99"
                        class="attendance_single_checkbox"
                        >上班无签到记为旷工</el-radio
                      >
                    </div>
                  </el-radio-group>
                </div>
              </div>
              <div v-else class="mt"></div>
              <div class="mt2" v-if="item.endTimeChoose">
                <div class="afterwordrule">
                  <div class="attendance_single_belate2">
                    <div>最早可提前</div>
                    <el-input-number
                      :disabled="isCheck"
                      :min="0"
                      v-model="item.endNoneTime"
                      controls-position="right"
                      class="attendance_single_num"
                    ></el-input-number>
                    <div>分钟打卡</div>
                  </div>
                  <div class="attendance_single_belate2">
                    <div>最晚可延后</div>
                    <el-input-number
                      :disabled="isCheck"
                      :min="0"
                      v-model="item.afterAttendanceTime"
                      controls-position="right"
                      class="attendance_single_num"
                    ></el-input-number>
                    <div>分钟打卡</div>
                  </div>
                  <div class="mq">
                    <div class="attendance_single_belate">
                      <div>早退</div>
                      <el-input-number
                        :disabled="isCheck"
                        v-model="item.endIgnoreTime"
                        :min="0"
                        controls-position="right"
                        class="attendance_single_num"
                      ></el-input-number>
                      <div>分钟忽略不计</div>
                    </div>
                  </div>
                  <div class="error_tips2" v-if="item.errorEearly">
                    {{ item.errorEearly }}
                  </div>
                  <div class="mq">
                    <div class="attendance_single_belate">
                      <div>早退</div>
                      <el-input-number
                        :disabled="isCheck"
                        v-model="item.earlyAbsenceTime"
                        :min="0"
                        controls-position="right"
                        class="attendance_single_num"
                      ></el-input-number>
                      <div>分钟记为旷工</div>
                    </div>
                  </div>
                  <div class="error_tips2" v-if="item.errorEearlyAbsenteeism">
                    {{ item.errorEearlyAbsenteeism }}
                  </div>
                  <el-radio-group
                    v-model="item.endChoose"
                    class="attendance_single_belate3"
                  >
                    <div class="attendance_single_checkin">
                      <el-radio
                        :label="1"
                        :disabled="isCheck"
                        text-color="#5C5C5C"
                        class="attendance_single_checkbox"
                        >下班无签到记为早退</el-radio
                      >
                      <div>早退时长记为</div>
                      <el-input-number
                        :disabled="isCheck"
                        :min="0"
                        v-model="item.earlyTime"
                        controls-position="right"
                        class="attendance_single_num"
                      ></el-input-number>
                      <div>分钟</div>
                    </div>
                    <div class="attendance_single_checkin">
                      <el-radio
                        :label="2"
                        :disabled="isCheck"
                        text-color="#5C5C5C"
                        class="attendance_single_checkbox"
                        >下班无签到记为旷工</el-radio
                      >
                    </div>
                  </el-radio-group>
                </div>
              </div>
              <div v-else class="mt2"></div>
              <div
                v-if="classesForm.arrangeDataList.length > 1 && !isCheck"
                @click="deleteAdd(index)"
                :class="[
                  'attendance_all_header_right2',
                  index == 0 ? 'disable' : 'nodisable',
                ]"
              >
                删除
              </div>
            </div>
          </div>
          <div>
            <el-checkbox
              v-model="setRest"
              @change="openRest"
              :disabled="isCheck"
              >设置休息时间</el-checkbox
            >
            <div v-if="setRest">
              <div
                v-for="(item, index) in classesForm.clockDataList"
                :key="index"
              >
                <div class="rest_period">
                  <div class="rest_period_title">休息{{ index + 1 }}</div>
                  <div class="workhour_time">
                    <el-select
                      :disabled="isCheck"
                      v-model="item.beginTimeData.tomorrow"
                      placeholder="当日"
                    >
                      <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                    <div class="workhour_time_specific">
                      <el-time-picker
                        :clearable="false"
                        v-model="item.beginTimeData.daySecond"
                        :disabled="isCheck"
                        placeholder="选择"
                        format="HH:mm"
                        value-format="HH:mm"
                      >
                      </el-time-picker>
                    </div>
                  </div>
                  <div class="rest_period_heng">-</div>
                  <div class="workhour_time">
                    <el-select
                      :disabled="isCheck"
                      v-model="item.endTimeData.tomorrow"
                      placeholder="当日"
                    >
                      <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                    <div class="workhour_time_specific">
                      <el-time-picker
                        :clearable="false"
                        v-model="item.endTimeData.daySecond"
                        :disabled="isCheck"
                        placeholder="选择"
                        format="HH:mm"
                        value-format="HH:mm"
                      >
                      </el-time-picker>
                    </div>
                  </div>
                  <img
                    v-if="classesForm.clockDataList.length < 3"
                    src="@/assets/add.png"
                    class="overtime_rule_type_add"
                    @click="restAdd(1)"
                  />
                  <img
                    v-if="classesForm.clockDataList.length > 1"
                    src="@/assets/de.png"
                    class="overtime_rule_type_add"
                    @click="restDelete(index)"
                  />
                </div>
                <div v-if="item.error" class="error_tips2">
                  {{ item.error }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import http from "../../../../utils/http";
export default {
  data() {
    return {
      classesForm: {
        type: 1,
        arrangeDataList: [], //班次列表
        clockDataList: [], //休息时间
      }, //新建班次表格
      timedifference: 0, //计算总时长
      attendanceGroup: {}, //考勤组配置
      restAlltime: 0, //休息总时长
      setRest: false, //设置休息时间
      rules: {
        name: [{ required: true, message: "请输入班次名称", trigger: "blur" }],
      },
      options: [
        {
          label: "当日",
          value: false,
        },
        {
          label: "次日",
          value: true,
        },
      ],
    };
  },
  watch: {
    id(newName, oldName) {
      if (newName) {
        this.checkClasses(this.id);
      }
    },
    "classesForm.arrangeDataList": {
      immediate: true,
      deep: true,
      handler(newItems, oldItems) {
        var endMinute = ""; //总时长
        if (newItems.length > 1) {
          var onceItem = newItems[0]; //第一段时间
          var agoItem = newItems[newItems.length - 2]; //前一段时间
          var lastItem = newItems[newItems.length - 1]; //最后一段时间

          // 判断各种时间
          for (let index = 1; index < newItems.length; index++) {
            if (
              newItems[index].clockData.beginTimeData.daySecond &&
              newItems[index].clockData.endTimeData.daySecond
            ) {
              // 开始时间
              var lastStartTime = this.timeToMinutes(
                newItems[index].clockData.beginTimeData.daySecond
              );
              // 结束时间
              var lastendTime = this.timeToMinutes(
                newItems[index].clockData.endTimeData.daySecond
              );
              // 如果是第二天(上班时间)
              if (newItems[index].clockData.beginTimeData.tomorrow) {
                lastStartTime = 1440 + lastStartTime;
              }
              // 如果是第二天(下班时间)
              if (newItems[index].clockData.endTimeData.tomorrow) {
                lastendTime = 1440 + lastendTime;
              }
              // 迟到忽略时间
              var neglectingLateness = newItems[index].beginIgnoreTime;
              // 迟到计为旷工时间
              var lateAbsenteeism = newItems[index].beginAbsenceTime;
              // 早退忽略时间
              var lateIgnore = newItems[index].endIgnoreTime;
              // 早退记为旷工
              var earlyAbsenceTime = newItems[index].earlyAbsenceTime;
              // 最早打上班卡时间
              var earlyWorkTime =
                lastStartTime - newItems[index].beforeAttendanceTime;
              // 最晚打上班卡时间
              var early = newItems[index].beginNoneTime;
              // 最早打下班卡时间
              var advance = newItems[index].endNoneTime;

              // 判断最早上班时间和前一个最早上班时间是否冲突
              if (lastStartTime) {
                // 如果存在上班时间
                if (!newItems[index].beginTimeChoose) {
                  earlyWorkTime = lastStartTime;
                }
                var lastEndChock = 0; //最晚打下班卡时间
                var time = this.timeToMinutes(
                  newItems[index - 1].clockData.endTimeData.daySecond
                );
                // 判断上个结束时间
                if (newItems[index - 1].endTimeChoose) {
                  lastEndChock = time + newItems[index - 1].afterAttendanceTime;
                } else {
                  lastEndChock = time;
                }

                if (earlyWorkTime <= lastEndChock) {
                  newItems[index].errortips =
                    "最早打上班卡时间不能小于上一班次最晚打下班卡时间";
                  return;
                } else {
                  delete newItems[index].errortips;
                }
              }
              // 先判断需不需要打上班卡
              if (newItems[index].beginTimeChoose) {
                // 判断计为旷工时间是否大于忽略时间
                if (neglectingLateness >= lateAbsenteeism) {
                  newItems[index].errorignore =
                    "忽略不计的迟到时间要小于计为旷工的迟到时间";
                  break;
                } else {
                  delete newItems[index].errorignore;
                }
                // 判断结束时间是否大于开始时间+计为旷工时间
                if (lateAbsenteeism >= early) {
                  newItems[index].errorAbsenteeism =
                    "旷工迟到时间不能大于最晚打上班卡时间";
                  break;
                } else {
                  delete newItems[index].errorAbsenteeism;
                }
              } else {
                delete newItems[index].errorAbsenteeism;
                delete newItems[index].errorignore;
              }

              // 先判断需不需要打下班卡
              if (newItems[index].endTimeChoose) {
                // 判断忽略早退时间是否小于上班时间
                if (lastendTime - lateIgnore < lastStartTime) {
                  newItems[index].errorEearly =
                    "忽略不计的早退时间不能小于上班时间";
                  break;
                } else {
                  delete newItems[index].errorEearly;
                }

                // 判断早退时间是否小于最早打下班卡时间
                if (earlyAbsenceTime >= advance) {
                  newItems[index].errorEearlyAbsenteeism =
                    "早退记为旷工不能小于最早打下班卡时间";
                  break;
                } else {
                  delete newItems[index].errorEearlyAbsenteeism;
                }

                // 判断早退时间是否大于忽略时间
                if (earlyAbsenceTime <= lateIgnore) {
                  newItems[index].errorEearlyAbsenteeism =
                    "早退记为旷工不能小于早退忽略不计时间";
                  break;
                } else {
                  delete newItems[index].errorEearlyAbsenteeism;
                }
              } else {
                delete newItems[index].errorEearly;
                delete newItems[index].errorEearlyAbsenteeism;
              }
            }
          }

          // 先判断第一个时间
          if (onceItem) {
            // 上班打卡规则总计算
            var workRules = null;
            // 下班打卡规则总计算
            var afterWorkRules = null;
            // 上下班数组
            var commuting = onceItem.clockData;
            // 判断是否同时存在上班时间和下班时间
            if (
              commuting.beginTimeData.daySecond &&
              commuting.endTimeData.daySecond
            ) {
              //  上班时间换算成分钟
              var workMinute = this.timeToMinutes(
                commuting.beginTimeData.daySecond
              );
              // 下班时间换算成分钟
              var afterWorkMinute = this.timeToMinutes(
                commuting.endTimeData.daySecond
              );
              // 第一段开始时间离当日的24点所剩的时间数
              var surplusTime =
                1440 - workMinute + parseInt(onceItem.beforeAttendanceTime);
              // 上班时间(上班时间-最早可提前时间)
              workRules = workMinute - parseInt(onceItem.beforeAttendanceTime);
              // 如果需要打下班卡(下班时间+最晚可延后时间)
              if (onceItem.endTimeChoose) {
                afterWorkRules =
                  afterWorkMinute + parseInt(onceItem.afterAttendanceTime);
              } else {
                afterWorkRules = afterWorkMinute;
              }

              // 判断打卡时间是否大于24小时
              // 1.如果下班时间选择次日
              if (commuting.endTimeData.tomorrow) {
                endMinute = surplusTime + afterWorkRules;
                // 如果大于24小时
                if (endMinute > 1440) {
                  onceItem.errortips2 = "工作时间不能超出24小时";
                  return;
                } else {
                  delete onceItem.errortips2;
                }
              } else {
                // 2.如果下班时间选择当日
                // 上班最晚可延后时间
                var delay = parseInt(onceItem.beginNoneTime);
                // 下班最早可提前时间
                var advanced = parseInt(onceItem.endNoneTime);
                // 判断上班需不需要打卡
                var start = null;
                var end = null;

                if (onceItem.beginTimeChoose) {
                  start = workMinute + delay;
                } else {
                  start = workMinute;
                }

                if (onceItem.endTimeChoose) {
                  end = afterWorkMinute - advanced;
                } else {
                  end = afterWorkMinute;
                }
                if (start >= end) {
                  onceItem.errortips2 =
                    "上班最晚可延迟打卡时间与下班最早可提前打卡时间不能重叠";
                  return;
                } else {
                  delete onceItem.errortips2;
                }
              }
            }
          }

          // 上一段时间的开始结束数组
          var agoCmmuting = agoItem.clockData;
          // 如果上一段选择了结束时间
          if (agoCmmuting.endTimeData.daySecond) {
            // 上一段结束时间(分钟)
            var agoItemEndtime = this.timeToMinutes(
              agoCmmuting.endTimeData.daySecond
            );
            // 上一段的结束时间+最晚可延后时间
            var agoItemallTime = null;
            // 1.如果上一段结束时间需要打卡
            if (agoItem.endTimeChoose) {
              agoItemallTime =
                agoItemEndtime + parseInt(agoItem.afterAttendanceTime);
            } else {
              // 2.如果上一段结束时间不需要打卡
              agoItemallTime = agoItemEndtime;
            }

            // 如果上一段结束时间是次日
            if (agoCmmuting.endTimeData.tomorrow) {
              agoItemallTime = agoItemallTime + 1440;
            }

            // 判断上一段的时间是否符合标准
            if (
              agoCmmuting.beginTimeData.daySecond &&
              agoCmmuting.endTimeData.daySecond
            ) {
              var agoItemStarAll = 0; //最晚上班打卡时间
              var agoItemEndAll = 0; //最早下班打卡时间
              var agoItemStar = this.timeToMinutes(
                agoCmmuting.beginTimeData.daySecond
              );

              var agoItemEnd = this.timeToMinutes(
                agoCmmuting.endTimeData.daySecond
              );
              if (agoCmmuting.beginTimeData.tomorrow) {
                agoItemStar = agoItemStar + 1440;
              }
              if (agoCmmuting.endTimeData.tomorrow) {
                agoItemEnd = agoItemEnd + 1440;
              }
              var latestCard = agoItem.beginNoneTime;
              var earlyWork = agoItem.endNoneTime;

              if (agoItem.beginTimeChoose) {
                agoItemStarAll = agoItemStar + latestCard;
              } else {
                agoItemStarAll = agoItemStar;
              }

              if (agoItem.endTimeChoose) {
                agoItemEndAll = agoItemEnd - earlyWork;
              } else {
                agoItemEndAll = agoItemEnd;
              }

              if (agoItemStarAll >= agoItemEndAll) {
                agoItem.errortips2 =
                  "上班最晚可延迟打卡时间与下班最早可提前打卡时间不能重叠";
                return;
              } else {
                delete agoItem.errortips2;
              }
            }

            // 最后一段的开始结束时间数组
            var lastCommuting = lastItem.clockData;
            // 如果最后一段选择了开始时间
            if (lastCommuting.beginTimeData.daySecond) {
              // 最后一段开始时间
              var lastItemStarttime = this.timeToMinutes(
                lastCommuting.beginTimeData.daySecond
              );
              // 最后一段的开始总时间
              var lastItemallStarttime = null;
              // 1.如果最后一段开始时间需要打卡
              if (lastItem.beginTimeChoose) {
                lastItemallStarttime =
                  lastItemStarttime - parseInt(lastItem.beforeAttendanceTime);
              } else {
                // 2.如果最后一段开始时间不用打卡
                lastItemallStarttime = lastItemStarttime;
              }

              // 1.如果最后一段开始时间是次日
              if (lastCommuting.beginTimeData.tomorrow) {
                lastItemallStarttime = 1440 + lastItemallStarttime;
              }
              // 如果最后一段小于前一段的结束时间
              if (lastItemallStarttime <= agoItemallTime) {
                lastItem.errortips =
                  "最早打卡时间需大于上一段的下班时间加延后打卡时间";
                return;
              } else {
                delete lastItem.errortips;
              }
            }

            // 如果同时选了开始时间和结束时间
            if (
              lastCommuting.beginTimeData.daySecond &&
              lastCommuting.endTimeData.daySecond
            ) {
              // 最后一段开始时间
              var lastItemStarttime = this.timeToMinutes(
                lastCommuting.beginTimeData.daySecond
              );
              // 最后一段结束时间
              var lastItemEndtime = this.timeToMinutes(
                lastCommuting.endTimeData.daySecond
              );
              // 如果上班时间为次日
              if (lastCommuting.beginTimeData.tomorrow) {
                lastItemStarttime = lastItemStarttime + 1440;
              }
              // 如果下班时间为次日
              if (lastCommuting.endTimeData.tomorrow) {
                lastItemEndtime = lastItemEndtime + 1440;
              }
              // 如果下班时间小于上班时间
              // 上班最晚可延后时间
              var delay = parseInt(lastItem.beginNoneTime);
              // 下班最早可提前时间
              var advanced = parseInt(lastItem.endNoneTime);
              // 判断上班需不需要打卡
              var start = null;
              var end = null;

              if (lastItem.beginTimeChoose) {
                start = lastItemStarttime + delay;
              } else {
                start = lastItemStarttime;
              }

              if (lastItem.endTimeChoose) {
                end = lastItemEndtime - advanced;
              } else {
                end = lastItemEndtime;
              }

              if (start >= end) {
                lastItem.errortips2 =
                  "上班最晚可延迟打卡时间与下班最早可提前打卡时间不能重叠";
                return;
              } else {
                delete lastItem.errortips2;
              }
            }

            // 如果选了最后一段时间的结束时间
            if (lastItem.clockData.endTimeData.daySecond) {
              // 第一个时间
              var onceStarttime = this.timeToMinutes(
                onceItem.clockData.beginTimeData.daySecond
              );
              // 第一个总时间
              var onceStartAlltime =
                onceStarttime - parseInt(onceItem.beforeAttendanceTime);
              // 最后一个时间
              var lastEndtime = this.timeToMinutes(
                lastItem.clockData.endTimeData.daySecond
              );
              // 第一段开始时间离当日的24点所剩的时间数
              var residueTime = 1440 - onceStartAlltime;
              // 最后一个结束总时间
              var lastEndAlltime = null;

              // 如果最后一个时间是次日
              if (lastCommuting.endTimeData.tomorrow) {
                // 1.如果最后一个时间需要打卡
                if (lastItem.endTimeChoose) {
                  lastEndAlltime =
                    lastEndtime + parseFloat(lastItem.afterAttendanceTime);
                } else {
                  // 2.最后一个时间不需要打卡
                  lastEndAlltime = lastEndtime;
                }

                endMinute = residueTime + lastEndAlltime;
                if (endMinute >= 1440) {
                  lastItem.errortips2 = "打卡时间不能超出24小时";
                  return;
                } else {
                  delete lastItem.errortips2;
                }
              } else {
                // 如果不是次日
                // 1.如果最后一个时间需要打卡
                if (lastItem.endTimeChoose) {
                  lastEndAlltime =
                    lastEndtime + parseFloat(lastItem.afterAttendanceTime);
                } else {
                  // 2.最后一个时间不需要打卡
                  lastEndAlltime = lastEndtime;
                }

                endMinute = lastEndAlltime - onceStartAlltime;
                if (endMinute >= 1440) {
                  lastItem.errortips2 = "打卡时间不能超出24小时";
                  return;
                } else {
                  delete lastItem.errortips2;
                }
              }
            }
          }
        } else {
          var onceItem = newItems[0]; //第一段时间
          if (onceItem) {
            // 第一个时间(开始时间)
            var lastStartTime = this.timeToMinutes(
              onceItem.clockData.beginTimeData.daySecond
            );
            // 最后一个时间(结束时间)
            var lastendTime = this.timeToMinutes(
              onceItem.clockData.endTimeData.daySecond
            );
            // 最后一个时间(不加任何数据)
            var lastendTimeOnce = null;

            // 如果结束时间是第二天
            if (onceItem.clockData.endTimeData.tomorrow) {
              lastendTimeOnce = lastendTime + 1440;
            } else {
              lastendTimeOnce = lastendTime;
            }

            if (lastendTimeOnce <= lastStartTime) {
              onceItem.errortips2 = "下班时间不能早于上班时间";
              return;
            } else {
              delete onceItem.errortips2;
            }

            // 最后一个时间跟第一个时间相隔多少分钟
            var onceTime =
              lastStartTime - parseInt(onceItem.beforeAttendanceTime);
            var lastAlltime = null;

            // 结束时间距离开始时间有多少分钟差
            var surplusTime = 1440 - onceTime;

            // 判断是否是第二天
            if (onceItem.clockData.endTimeData.tomorrow) {
              // 判断是否需要打下班卡
              lastAlltime = lastendTime + surplusTime;
            } else {
              // 判断是否需要打下班卡
              if (onceItem.endTimeChoose) {
                lastAlltime =
                  1440 - lastendTime + parseInt(onceItem.afterAttendanceTime);
              } else {
                lastAlltime = lastendTime;
              }
            }

            if (lastAlltime >= 1440) {
              onceItem.errortips2 = "打卡时间不能超出24小时";
              return;
            } else {
              delete onceItem.errortips2;
            }

            // 迟到忽略时间
            var neglectingLateness = onceItem.beginIgnoreTime;
            // 迟到计为旷工时间
            var lateAbsenteeism = onceItem.beginAbsenceTime;
            // 早退忽略不计时间
            var lateIgnore = onceItem.endIgnoreTime;
            // 早退记为旷工
            var earlyAbsenceTime = onceItem.earlyAbsenceTime;
            // 最晚可延后打上班卡时间
            var earlyDelay = onceItem.beginNoneTime;
            // 最早可提前打下班卡时间
            var advance = onceItem.endNoneTime;

            // 判断计为旷工时间是否大于忽略时间
            if (neglectingLateness >= lateAbsenteeism) {
              onceItem.errorignore =
                "忽略不计的迟到时间要小于计为旷工的迟到时间";
              return;
            } else {
              delete onceItem.errorignore;
            }

            // 判断最晚可延后时间是否大于记为旷工时间
            if (lateAbsenteeism >= earlyDelay) {
              onceItem.errorAbsenteeism = "旷工迟到时间不能大于最晚打卡时间";
              return;
            } else {
              delete onceItem.errorAbsenteeism;
            }

            // 如果需要打下班卡才判断
            if (onceItem.endTimeChoose) {
              // 判断忽略早退时间是否小于上班时间
              if (lastendTimeOnce - lateIgnore < lastStartTime) {
                onceItem.errorEearly = "忽略不计的早退时间不能小于上班时间";
                return;
              } else {
                delete onceItem.errorEearly;
              }

              // 判断早退记为旷工时间是否小于上班时间
              if (earlyAbsenceTime >= advance) {
                onceItem.errorEearlyAbsenteeism =
                  "早退记为旷工时间不能大于最早打下班卡时间";
                return;
              } else {
                delete onceItem.errorEearlyAbsenteeism;
              }

              // 判断早退记为旷工时间是否小于忽略不计时间
              if (earlyAbsenceTime <= lateIgnore) {
                onceItem.errorEearlyAbsenteeism =
                  "早退记为旷工时间不能小于忽略不计时间";
                return;
              } else {
                delete onceItem.errorEearlyAbsenteeism;
              }
            } else {
              delete onceItem.errorEearly;
              delete onceItem.errorAbsenteeism;
              delete onceItem.errorEearlyAbsenteeism;
            }

            // 判断最后一个时间
            if (onceItem.clockData.endTimeData.tomorrow) {
              if (onceItem.clockData.endTimeData.daySecond) {
                // 如果需要打下班卡
                if (onceItem.endTimeChoose) {
                  endMinute =
                    surplusTime +
                    lastendTime +
                    parseInt(onceItem.afterAttendanceTime);
                } else {
                  endMinute = surplusTime + lastendTime;
                }
                // 如果大于24小时
                if (endMinute > 1440) {
                  onceItem.errortips2 = "打卡时间不能超出24小时";
                  return;
                } else {
                  delete onceItem.errortips2;
                }
              }
            } else {
              var laststartTime = this.timeToMinutes(
                onceItem.clockData.beginTimeData.daySecond
              );
              var lastendTime = this.timeToMinutes(
                onceItem.clockData.endTimeData.daySecond
              );
              // 上班最晚可延后时间
              var delay = parseInt(onceItem.beginNoneTime);
              // 下班最早可提前时间
              var advanced = parseInt(onceItem.endNoneTime);

              endMinute = lastendTime - laststartTime;

              // 判断上班需不需要打卡
              var start = null;
              var end = null;

              if (onceItem.beginTimeChoose) {
                start = laststartTime + delay;
              } else {
                start = laststartTime;
              }

              if (onceItem.endTimeChoose) {
                end = lastendTime - advanced;
              } else {
                end = lastendTime;
              }

              if (start >= end) {
                onceItem.errortips2 =
                  "上班最晚可延迟打卡时间与下班最早可提前打卡时间不能重叠";
                return;
              } else {
                delete onceItem.errortips2;
              }
            }
          }
        }
        // 处理总时长
        if (endMinute) {
          var totalWorking = null; //总工作时长
          for (let index = 0; index < newItems.length; index++) {
            var start = newItems[index].clockData.beginTimeData;
            var end = newItems[index].clockData.endTimeData;
            if (start.daySecond && end.daySecond) {
              var startTime = this.convertToMinutes(start.daySecond);
              var endTime = this.convertToMinutes(end.daySecond);
              if (start.tomorrow) {
                startTime = startTime + 1440;
              }
              if (end.tomorrow) {
                endTime = endTime + 1440;
              }

              totalWorking += parseInt(endTime) - parseInt(startTime);
            }
          }

          this.timedifference = totalWorking;
        }
      },
    },

    "classesForm.type": {
      handler(newV, oldV) {
        var object = {
          afterAttendanceTime: 30,
          beforeAttendanceTime: 30,
          beginIgnoreTime: 30,
          endIgnoreTime: 30,
          beginAbsenceTime: 240,
          earlyTime: 240,
          lateTime: 240,
          beginChoose: 1,
          endChoose: 1,
          earlyAbsenceTime: 120,
          beginTimeChoose: true,
          endTimeChoose: true,
        };
        switch (newV) {
          case 1:
            this.classesForm.arrangeDataList = [
              Object.assign(object, {
                clockData: {
                  beginTimeData: {
                    tomorrow: false,
                    daySecond: "9:00",
                    limitTime: { selectableRange: "" },
                  },
                  endTimeData: {
                    tomorrow: false,
                    daySecond: "18:00",
                    limitTime: { selectableRange: "" },
                  },
                },
              }),
            ];
            break;
          // case 2:
          //   this.classesForm.arrangeDataList.push(
          //     Object.assign(object, {
          //       clockData: {
          //         beginTimeData: {
          //           tomorrow: false,
          //           daySecond: "",
          //           limitTime: { selectableRange: "" },
          //         },
          //         endTimeData: {
          //           tomorrow: false,
          //           daySecond: "",
          //           limitTime: { selectableRange: "" },
          //         },
          //       },
          //     })
          //   );
          //   break;
          // case 3:
          //   this.classesForm.arrangeDataList.push(
          //     Object.assign(object, {
          //       clockData: {
          //         beginTimeData: {
          //           tomorrow: false,
          //           daySecond: "",
          //           limitTime: { selectableRange: "" },
          //         },
          //         endTimeData: {
          //           tomorrow: false,
          //           daySecond: "",
          //           limitTime: { selectableRange: "" },
          //         },
          //       },
          //     })
          //   );
          //   break;
        }
      },
      immediate: true,
    },
    "classesForm.clockDataList": {
      immediate: true,
      deep: true,
      handler(newV, oldV) {
        var restTime = 0;
        if (newV.length > 0) {
          // 获取班次的第一个时间段
          var onceClasses = this.classesForm.arrangeDataList[0];
          var onceClassesTime = "";
          // 获取班次的最后一个时间段
          var lastClasses =
            this.classesForm.arrangeDataList[
              this.classesForm.arrangeDataList.length - 1
            ];
          var lastClassesTime = "";
          // 第一个时间段开始时间
          if (onceClasses.clockData.beginTimeData) {
            onceClassesTime = onceClasses.clockData.beginTimeData.daySecond;
          }
          // 最后一个时间段结束时间
          if (lastClasses.clockData.endTimeData) {
            lastClassesTime = lastClasses.clockData.endTimeData.daySecond;
          }

          // 判断是否在工作时间段内
          if (newV.length > 1) {
            for (let index = 1; index < newV.length; index++) {
              var previousItem = newV[index - 1]; //上一个结束时间
              var currentItme = newV[index]; //当前开始时间
              var previousItemTime = previousItem.daySecond;

              if (currentItme.beginTimeData.tomorrow) {
                // 如果最后一个时间的明天
                if (lastClasses.clockData.endTimeData.tomorrow) {
                  onceClassesTime = "00:00:00";
                  lastClassesTime = lastClassesTime + ":00";
                  currentItme.beginTimeData.limitTime.selectableRange =
                    onceClassesTime + "-" + lastClassesTime;
                } else {
                  onceClassesTime = "00:00:00";
                  lastClassesTime = "23:59:59";
                  currentItme.beginTimeData.limitTime.selectableRange =
                    onceClassesTime + "-" + lastClassesTime;
                }
              } else {
                if (lastClasses.clockData.endTimeData.tomorrow) {
                  onceClassesTime = onceClassesTime + ":00";
                  lastClassesTime = "23:59:59";
                  currentItme.beginTimeData.limitTime.selectableRange =
                    onceClassesTime + "-" + lastClassesTime;
                } else {
                  onceClassesTime = onceClassesTime + ":00";
                  lastClassesTime = lastClassesTime + ":00";
                  currentItme.beginTimeData.limitTime.selectableRange =
                    onceClassesTime + "-" + lastClassesTime;
                }
              }
              if (currentItme.endTimeData.tomorrow) {
                // 如果最后一个时间的明天
                if (lastClasses.clockData.endTimeData.tomorrow) {
                  onceClassesTime = "00:00:00";
                  lastClassesTime =
                    lastClasses.clockData.endTimeData.daySecond + ":00";
                  currentItme.endTimeData.limitTime.selectableRange =
                    onceClassesTime + "-" + lastClassesTime;
                } else {
                  onceClassesTime = "00:00:00";
                  lastClassesTime = "23:59:59";
                  currentItme.endTimeData.limitTime.selectableRange =
                    onceClassesTime + "-" + lastClassesTime;
                }
              } else {
                if (lastClasses.clockData.endTimeData.tomorrow) {
                  onceClassesTime = onceClassesTime + ":00";
                  lastClassesTime = "23:59:59";
                  currentItme.endTimeData.limitTime.selectableRange =
                    onceClassesTime + "-" + lastClassesTime;
                } else {
                  onceClassesTime = onceClassesTime + ":00";
                  lastClassesTime = lastClassesTime + ":00";
                  currentItme.endTimeData.limitTime.selectableRange =
                    onceClassesTime + "-" + lastClassesTime;
                }
              }

              // 判断逻辑
              if (
                previousItem.beginTimeData.daySecond &&
                previousItem.endTimeData.daySecond
              ) {
                var previousItmeStart = this.timeToMinutes(
                  previousItem.beginTimeData.daySecond
                );
                var previousItmeEnd = this.timeToMinutes(
                  previousItem.endTimeData.daySecond
                );
                //   判断是否在工作时间内
                var isPeriod = this.isInWorkingHours(
                  previousItem.beginTimeData.daySecond,
                  previousItem.beginTimeData.tomorrow
                );
                var isPeriod2 = this.isInWorkingHours(
                  previousItem.endTimeData.daySecond,
                  previousItem.endTimeData.tomorrow
                );
                if (isPeriod == -1 || isPeriod2 == -1) {
                  previousItem.error = "休息时间段必须在工作时间内";
                  break;
                } else {
                  // 判断是否在同一个时间段内
                  if (isPeriod != isPeriod2) {
                    previousItem.error = "休息时间段必须在同一个班次";
                    break;
                  } else {
                    delete previousItem.error;
                    previousItem.indexPeriod = isPeriod;
                  }
                }

                if (previousItem.beginTimeData.tomorrow) {
                  previousItmeStart = previousItmeStart + 1440;
                }
                if (previousItem.endTimeData.tomorrow) {
                  previousItmeEnd = previousItmeEnd + 1440;
                }
                if (previousItmeEnd < previousItmeStart) {
                  {
                    previousItem.error = "结束时间必须大于开始时间";
                    break;
                  }
                } else {
                  delete previousItem.error;
                }

                if (
                  currentItme.beginTimeData.daySecond &&
                  currentItme.endTimeData.daySecond
                ) {
                  var currentItmeStart = currentItme.beginTimeData.daySecond;
                  var currentItmeEnd = currentItme.endTimeData.daySecond;
                  //   判断是否在工作时间内
                  var isPeriod = this.isInWorkingHours(
                    currentItme.beginTimeData.daySecond,
                    currentItme.beginTimeData.tomorrow
                  );
                  var isPeriod2 = this.isInWorkingHours(
                    currentItme.endTimeData.daySecond,
                    currentItme.endTimeData.tomorrow
                  );
                  if (isPeriod == -1 || isPeriod2 == -1) {
                    newV[index].error = "休息时间段必须在工作时间内";
                    break;
                  } else {
                    // 判断是否在同一个时间段内
                    if (isPeriod != isPeriod2) {
                      newV[index].error = "休息时间段必须在同一个班次";
                      break;
                    } else {
                      delete newV[index].error;
                      newV[index].indexPeriod = isPeriod;
                    }
                  }

                  if (currentItme) {
                    previousItemTime = this.timeToMinutes(
                      previousItem.endTimeData.daySecond
                    );
                    if (previousItem.endTimeData.tomorrow) {
                      previousItemTime = previousItemTime + 1440;
                    }
                    currentItmeStart = this.timeToMinutes(
                      currentItme.beginTimeData.daySecond
                    );
                    if (currentItme.beginTimeData.tomorrow) {
                      currentItmeStart = currentItmeStart + 1440;
                    }
                    currentItmeEnd = this.timeToMinutes(
                      currentItme.endTimeData.daySecond
                    );
                    if (currentItme.endTimeData.tomorrow) {
                      currentItmeEnd = currentItmeEnd + 1440;
                    }

                    if (currentItmeStart < previousItemTime) {
                      newV[index].error = "开始时间必须大于上一段结束时间";
                      break;
                    } else {
                      delete newV[index].error;
                    }
                  }

                  if (currentItmeStart && currentItmeEnd) {
                    if (currentItmeEnd <= currentItmeStart) {
                      newV[index].error = "结束时间必须大于开始时间";
                      break;
                    } else {
                      delete newV[index].error;
                    }
                  }
                }
              }
            }
          } else {
            var currentItme = newV[0]; //当前开始时间

            if (currentItme.beginTimeData.tomorrow) {
              // 如果最后一个时间的明天
              if (lastClasses.clockData.endTimeData.tomorrow) {
                onceClassesTime = "00:00:00";
                lastClassesTime = lastClassesTime + ":00";
                currentItme.beginTimeData.limitTime.selectableRange =
                  onceClassesTime + "-" + lastClassesTime;
              } else {
                onceClassesTime = "00:00:00";
                lastClassesTime = "23:59:59";
                currentItme.beginTimeData.limitTime.selectableRange =
                  onceClassesTime + "-" + lastClassesTime;
              }
            } else {
              if (lastClasses.clockData.endTimeData.tomorrow) {
                onceClassesTime = onceClassesTime + ":00";
                lastClassesTime = "23:59:59";
                currentItme.beginTimeData.limitTime.selectableRange =
                  onceClassesTime + "-" + lastClassesTime;
              } else {
                onceClassesTime = onceClassesTime + ":00";
                lastClassesTime = lastClassesTime + ":00";
                currentItme.beginTimeData.limitTime.selectableRange =
                  onceClassesTime + "-" + lastClassesTime;
              }
            }

            if (currentItme.endTimeData.tomorrow) {
              // 如果最后一个时间的明天
              if (lastClasses.clockData.endTimeData.tomorrow) {
                onceClassesTime = "00:00:00";
                lastClassesTime =
                  lastClasses.clockData.endTimeData.daySecond + ":00";
                currentItme.endTimeData.limitTime.selectableRange =
                  onceClassesTime + "-" + lastClassesTime;
              } else {
                onceClassesTime = "00:00:00";
                lastClassesTime = "23:59:59";
                currentItme.endTimeData.limitTime.selectableRange =
                  onceClassesTime + "-" + lastClassesTime;
              }
            } else {
              if (lastClasses.clockData.endTimeData.tomorrow) {
                onceClassesTime = onceClassesTime + ":00";
                lastClassesTime = "23:59:59";
                currentItme.endTimeData.limitTime.selectableRange =
                  onceClassesTime + "-" + lastClassesTime;
              } else {
                onceClassesTime = onceClassesTime + ":00";
                lastClassesTime = lastClassesTime + ":00";
                currentItme.endTimeData.limitTime.selectableRange =
                  onceClassesTime + "-" + lastClassesTime;
              }
            }

            if (
              currentItme.beginTimeData.daySecond &&
              currentItme.endTimeData.daySecond
            ) {
              var isPeriod = this.isInWorkingHours(
                currentItme.beginTimeData.daySecond,
                currentItme.beginTimeData.tomorrow
              );
              var isPeriod2 = this.isInWorkingHours(
                currentItme.endTimeData.daySecond,
                currentItme.endTimeData.tomorrow
              );
              if (isPeriod == -1 || isPeriod2 == -1) {
                newV[0].error = "休息时间段必须在工作时间内";
                return;
              } else {
                // 判断是否在同一个时间段内
                if (isPeriod != isPeriod2) {
                  newV[0].error = "休息时间段必须在同一个班次";
                  return;
                } else {
                  delete newV[0].error;
                  newV[0].indexPeriod = isPeriod;
                }
              }
              var currentItmeStart = this.timeToMinutes(
                currentItme.beginTimeData.daySecond
              );
              var currentItmeEnd = this.timeToMinutes(
                currentItme.endTimeData.daySecond
              );
              if (currentItme.endTimeData.tomorrow) {
                currentItmeEnd = currentItmeEnd + 1440;
              }
              if (currentItmeStart && currentItmeEnd) {
                if (currentItmeEnd <= currentItmeStart) {
                  newV[0].error = "结束时间必须大于开始时间";
                  return;
                } else {
                  delete newV[0].error;
                }
              }
            }
          }

          newV.map((item) => {
            var start = item.beginTimeData;
            var end = item.endTimeData;
            if (start.daySecond && end.daySecond) {
              var startTime = this.timeToMinutes(start.daySecond);
              var endTime = this.timeToMinutes(end.daySecond);
              if (start.tomorrow) {
                startTime = startTime + 1440;
              }
              if (end.tomorrow) {
                endTime = endTime + 1440;
              }
            }

            restTime += endTime - startTime;
          });

          this.restAlltime = restTime;
        }
      },
    },
  },

  created() {
    if (this.id) {
      this.checkClasses(this.id);
    }
    this.$on("nestedObj1ValueChanged", (newValue) => {
      if (this.classesForm.clockDataList.length > 0) {
        this.$set(
          this.classesForm.clockDataList,
          "abc",
          this.classesForm.clockDataList.length
        );
        this.$delete(this.classesForm.clockDataList, "abc");
      }
    });
  },

  props: ["isCheck", "id"],

  methods: {
    // 查看班次
    checkClasses(id) {
      http
        .get("/bmsAttendanceArrange/get/config", {
          id: id,
        })
        .then((res) => {
          if (res.code == 200) {
            var value = JSON.stringify(res.data);
            value = JSON.parse(value);

            var arr = value; //班次列表

            this.classesForm.type = arr.length;
            var newArr = [];
            var onceClasses = arr[0];
            var onceClassesTime = onceClasses.clockData.beginDaySecond;
            var lastClasses = arr[arr.length - 1];
            var lastClassesTime = lastClasses.clockData.endDaySecond;
            setTimeout(() => {
              arr.map((item, index) => {
                // 转化
                if (item.afterAttendanceTime || item.afterAttendanceTime == 0) {
                  item.afterAttendanceTime = item.afterAttendanceTime / 60;
                } else {
                  item.afterAttendanceTime = 30;
                }
                if (
                  item.beforeAttendanceTime ||
                  item.beforeAttendanceTime == 0
                ) {
                  item.beforeAttendanceTime = item.beforeAttendanceTime / 60;
                } else {
                  item.beforeAttendanceTime = 30;
                }
                if (item.beginAbsenceTime || item.beginAbsenceTime == 0) {
                  item.beginAbsenceTime = item.beginAbsenceTime / 60;
                } else {
                  item.beginAbsenceTime = 240;
                }
                if (item.beginIgnoreTime || item.beginIgnoreTime == 0) {
                  item.beginIgnoreTime = item.beginIgnoreTime / 60;
                } else {
                  item.beginIgnoreTime = 30;
                }
                if (item.endIgnoreTime || item.endIgnoreTime == 0) {
                  item.endIgnoreTime = item.endIgnoreTime / 60;
                } else {
                  item.endIgnoreTime = 30;
                }
                if (item.beginNoneTime || item.beginNoneTime == 0) {
                  item.beginNoneTime = item.beginNoneTime / 60;
                } else {
                  item.beginNoneTime = 30;
                }
                if (item.endNoneTime || item.endNoneTime == 0) {
                  item.endNoneTime = item.endNoneTime / 60;
                } else {
                  item.endNoneTime = 30;
                }
                if (item.lateTime || item.lateTime == 0) {
                  item.lateTime = item.lateTime / 60;
                } else {
                  item.lateTime = 240;
                }
                if (item.earlyTime || item.earlyTime == 0) {
                  item.earlyTime = item.earlyTime / 60;
                } else {
                  item.earlyTime = 240;
                }

                if (item.earlyAbsenceTime || item.earlyAbsenceTime == 0) {
                  item.earlyAbsenceTime = item.earlyAbsenceTime / 60;
                } else {
                  item.earlyAbsenceTime = 120;
                }

                // 如果不存在打上班卡
                if (!item.endChoose) {
                  item.endChoose = 1;
                }

                if (!item.beginChoose) {
                  item.beginChoose = 1;
                }

                if (item.clockData.beginDaySecond) {
                  item.clockData.beginTimeData = {};
                  // 如果大于86400秒，是第二天
                  if (item.clockData.beginDaySecond >= 86400) {
                    item.clockData.beginTimeData.tomorrow = true;
                    item.clockData.beginTimeData.daySecond =
                      this.secondsToHourMin(
                        item.clockData.beginDaySecond - 86400
                      );
                  } else {
                    item.clockData.beginTimeData.tomorrow = false;
                    item.clockData.beginTimeData.daySecond =
                      this.secondsToHourMin(item.clockData.beginDaySecond);
                  }
                }

                if (item.clockData.endDaySecond) {
                  item.clockData.endTimeData = {};
                  // 如果大于86400秒，是第二天
                  if (item.clockData.endDaySecond >= 86400) {
                    item.clockData.endTimeData.tomorrow = true;
                    item.clockData.endTimeData.daySecond =
                      this.secondsToHourMin(
                        item.clockData.endDaySecond - 86400
                      );
                  } else {
                    item.clockData.endTimeData.tomorrow = false;
                    item.clockData.endTimeData.daySecond =
                      this.secondsToHourMin(item.clockData.endDaySecond);
                  }
                }
                item.clockDataList.map((citem, cindex) => {
                  citem.beginTimeData = {};
                  // 如果大于86400秒，是第二天
                  if (citem.beginDaySecond >= 86400) {
                    citem.beginTimeData.tomorrow = true;
                    citem.beginTimeData.daySecond = this.secondsToHourMin(
                      citem.beginDaySecond - 86400
                    );
                  } else {
                    citem.beginTimeData.tomorrow = false;
                    citem.beginTimeData.daySecond = this.secondsToHourMin(
                      citem.beginDaySecond
                    );
                  }

                  citem.endTimeData = {};
                  // 如果大于86400秒，是第二天
                  if (citem.endDaySecond >= 86400) {
                    citem.endTimeData.tomorrow = true;
                    citem.endTimeData.daySecond = this.secondsToHourMin(
                      citem.endDaySecond - 86400
                    );
                  } else {
                    citem.endTimeData.tomorrow = false;
                    citem.endTimeData.daySecond = this.secondsToHourMin(
                      citem.endDaySecond
                    );
                  }
                  citem.indexPeriod = index;
                  citem.beginTimeData.limitTime = {
                    selectableRange: "",
                  };
                  citem.endTimeData.limitTime = {
                    selectableRange: "",
                  };

                  if (citem.beginTimeData.tomorrow) {
                    // 如果最后一个时间的明天
                    if (lastClasses.clockData.endDaySecond >= 86400) {
                      onceClassesTime = "00:00:00";
                      lastClassesTime = lastClassesTime + ":00";
                      citem.beginTimeData.limitTime.selectableRange =
                        onceClassesTime + "-" + lastClassesTime;
                    } else {
                      onceClassesTime = "00:00:00";
                      lastClassesTime = "23:59:59";
                      citem.beginTimeData.limitTime.selectableRange =
                        onceClassesTime + "-" + lastClassesTime;
                    }
                  } else {
                    if (lastClasses.clockData.endDaySecond >= 86400) {
                      onceClassesTime = onceClassesTime + ":00";
                      lastClassesTime = "23:59:59";
                      citem.beginTimeData.limitTime.selectableRange =
                        onceClassesTime + "-" + lastClassesTime;
                    } else {
                      onceClassesTime = onceClassesTime + ":00";
                      lastClassesTime = lastClassesTime + ":00";
                      citem.beginTimeData.limitTime.selectableRange =
                        onceClassesTime + "-" + lastClassesTime;
                    }
                  }

                  if (citem.endTimeData.tomorrow) {
                    // 如果最后一个时间的明天
                    if (lastClasses.clockData.endDaySecond >= 86400) {
                      onceClassesTime = "00:00:00";
                      if (lastClasses.clockData.endTimeData) {
                        lastClassesTime =
                          lastClasses.clockData.endTimeData.daySecond + ":00";
                        citem.endTimeData.limitTime.selectableRange =
                          onceClassesTime + "-" + lastClassesTime;
                      }
                    } else {
                      onceClassesTime = "00:00:00";
                      lastClassesTime = "23:59:59";
                      citem.endTimeData.limitTime.selectableRange =
                        onceClassesTime + "-" + lastClassesTime;
                    }
                  } else {
                    if (lastClasses.clockData.endDaySecond >= 86400) {
                      onceClassesTime = onceClassesTime + ":00";
                      lastClassesTime = "23:59:59";
                      citem.endTimeData.limitTime.selectableRange =
                        onceClassesTime + "-" + lastClassesTime;
                    } else {
                      onceClassesTime = onceClassesTime + ":00";
                      lastClassesTime = lastClassesTime + ":00";
                      citem.endTimeData.limitTime.selectableRange =
                        onceClassesTime + "-" + lastClassesTime;
                    }
                  }

                  newArr.push(citem);
                });
              });
              this.classesForm.arrangeDataList = arr;
              this.classesForm.clockDataList = newArr;
              if (newArr.length > 0) {
                this.setRest = true;
              } else {
                this.setRest = false;
              }
            }, 500);
          }
        });
    },
    // 时间换为分钟
    timeToMinutes(time) {
      const [hour, minute] = time.split(":").map(Number);
      return hour * 60 + minute;
    },
    // 时间换为秒
    timeToSecond(time) {
      const [hour, minute] = time.split(":").map(Number);
      return hour * 3600 + minute * 60;
    },
    // 秒转换成时间
    secondsToHourMin(seconds) {
      // 将秒数转换为分钟
      let minutes = Math.floor(seconds / 60);
      // 将分钟转换为小时和分钟
      let hours = Math.floor(minutes / 60);
      let minutesRemainder = minutes % 60;

      // 格式化小时和分钟
      hours = hours < 10 ? "0" + hours : hours;
      minutesRemainder =
        minutesRemainder < 10 ? "0" + minutesRemainder : minutesRemainder;

      // 返回时分格式
      return hours + ":" + minutesRemainder;
    },
    // 查询是否在时间段内
    isInWorkingHours(time, isTomorrow) {
      var timeInMinutes = this.convertToMinutes(time);
      if (isTomorrow) {
        timeInMinutes = 1440 + timeInMinutes;
      }
      for (var i = 0; i < this.classesForm.arrangeDataList.length; i++) {
        var start = this.convertToMinutes(
          this.classesForm.arrangeDataList[i].clockData.beginTimeData.daySecond
        );
        var end = this.convertToMinutes(
          this.classesForm.arrangeDataList[i].clockData.endTimeData.daySecond
        );
        if (
          this.classesForm.arrangeDataList[i].clockData.beginTimeData.tomorrow
        ) {
          start = 1440 + start;
        }
        if (
          this.classesForm.arrangeDataList[i].clockData.endTimeData.tomorrow
        ) {
          end = 1440 + end;
        }
        if (timeInMinutes > start && timeInMinutes < end) {
          return i; // 返回时间段索引
        }
      }

      return -1;
    },
    // 触发监听
    triggerListening(value) {
      console.log(value);
      this.$emit("nestedObj1ValueChanged", value);
    },
    // 转化
    convertToMinutes(timeString) {
      var timeArray = timeString.split(":");
      return parseInt(timeArray[0]) * 60 + parseInt(timeArray[1]);
    },
    // 监听当日，次日
    changeUp(e, value) {
      // 获取班次的第一个时间段
      var onceClasses = this.classesForm.arrangeDataList[0];
      var onceClassesTime = "";
      // 获取班次的最后一个时间段
      var lastClasses =
        this.classesForm.arrangeDataList[
          this.classesForm.arrangeDataList.length - 1
        ];
      var lastClassesTime = "";
      // 第一个时间段开始时间
      if (onceClasses.clockData.beginTimeData) {
        onceClassesTime = onceClasses.clockData.beginTimeData.daySecond;
      }
      // 最后一个时间段结束时间
      if (lastClasses.clockData.endTimeData) {
        lastClassesTime = lastClasses.clockData.endTimeData.daySecond;
      }
      if (value.beginTimeData.tomorrow) {
        // 如果最后一个时间的明天
        if (lastClasses.clockData.endTimeData.tomorrow) {
          onceClassesTime = "00:00:00";
          lastClassesTime = lastClassesTime + ":00";
          value.beginTimeData.limitTime.selectableRange =
            onceClassesTime + "-" + lastClassesTime;
        } else {
          onceClassesTime = "00:00:00";
          lastClassesTime = "23:59:59";
          value.beginTimeData.limitTime.selectableRange =
            onceClassesTime + "-" + lastClassesTime;
        }
      } else {
        if (lastClasses.clockData.endTimeData.tomorrow) {
          onceClassesTime = onceClassesTime + ":00";
          lastClassesTime = "23:59:59";
          value.beginTimeData.limitTime.selectableRange =
            onceClassesTime + "-" + lastClassesTime;
        } else {
          onceClassesTime = onceClassesTime + ":00";
          lastClassesTime = lastClassesTime + ":00";
          value.beginTimeData.limitTime.selectableRange =
            onceClassesTime + "-" + lastClassesTime;
        }
      }

      if (value.endTimeData.tomorrow) {
        // 如果最后一个时间的明天
        if (lastClasses.clockData.endTimeData.tomorrow) {
          onceClassesTime = "00:00:00";
          lastClassesTime = lastClasses.clockData.endTimeData.daySecond + ":00";
          value.endTimeData.limitTime.selectableRange =
            onceClassesTime + "-" + lastClassesTime;
        } else {
          onceClassesTime = "00:00:00";
          lastClassesTime = "23:59:59";
          value.endTimeData.limitTime.selectableRange =
            onceClassesTime + "-" + lastClassesTime;
        }
      } else {
        if (lastClasses.clockData.endTimeData.tomorrow) {
          onceClassesTime = onceClassesTime + ":00";
          lastClassesTime = "23:59:59";
          value.endTimeData.limitTime.selectableRange =
            onceClassesTime + "-" + lastClassesTime;
        } else {
          onceClassesTime = onceClassesTime + ":00";
          lastClassesTime = lastClassesTime + ":00";
          value.endTimeData.limitTime.selectableRange =
            onceClassesTime + "-" + lastClassesTime;
        }
      }
    },
    // 新增班次
    periodAdded() {
      var index = this.classesForm.arrangeDataList.length;
      if (index == 3) {
        this.$message.error("最多存在3个时间段");
        return;
      }
      var object = {
        afterAttendanceTime: 30,
        beforeAttendanceTime: 30,
        beginIgnoreTime: 30,
        endIgnoreTime: 30,
        beginAbsenceTime: 240,
        earlyTime: 240,
        lateTime: 240,
        beginChoose: 1,
        endChoose: 1,
        earlyAbsenceTime: 120,
        beginTimeChoose: true,
        endTimeChoose: true,
      };
      this.classesForm.arrangeDataList.push(
        Object.assign(object, {
          clockData: {
            beginTimeData: {
              tomorrow: false,
              daySecond: "",
              limitTime: { selectableRange: "" },
            },
            endTimeData: {
              tomorrow: false,
              daySecond: "",
              limitTime: { selectableRange: "" },
            },
          },
        })
      );
    },
    // 删除新增的班次
    deleteAdd(index) {
      if (index == 0) {
        return;
      }
      console.log(index);
      this.classesForm.arrangeDataList.splice(index, 1);
    },
    // 开启休息时间
    openRest(event) {
      if (this.isCheck) {
        return;
      }
      if (event) {
        this.restAdd(1);
      } else {
        this.restAdd(2);
      }
    },
    // 新增休息时间
    restAdd(type) {
      if (this.isCheck) {
        return;
      }
      var arr = this.classesForm.clockDataList;
      var obj = {
        beginTimeData: {
          daySecond: "",
          tomorrow: false,
          limitTime: { selectableRange: "" },
        },
        endTimeData: {
          daySecond: "",
          tomorrow: false,
          limitTime: { selectableRange: "" },
        },
      };

      if (type == 1) {
        arr.push(obj);
      } else {
        this.$set(this.classesForm, "clockDataList", []);
      }
    },
    // 删除休息时间
    restDelete(index) {
      if (this.isCheck) {
        return;
      }
      var arr = this.classesForm.clockDataList;
      arr.splice(index, 1);
    },
    // 取消弹窗
    cancelDiglog() {
      this.$refs.addForm.clearValidate();
      this.$emit("cancel");
    },
    // 确认选择
    comfinDiglog() {
      if (this.isCheck) {
        this.cancelDiglog();
        return;
      }

      if (!this.classesForm.name) {
        this.$message.error("请先填写班次名称");
        return;
      }

      // 工作时间
      var timeDay = this.classesForm.arrangeDataList;
      // 休息时间
      var clockDay = this.classesForm.clockDataList;
      for (let index = 0; index < timeDay.length; index++) {
        var start = timeDay[index].clockData.beginTimeData;
        var end = timeDay[index].clockData.endTimeData;
        if (start.daySecond == "" || end.daySecond == "") {
          this.$message.error("请先选择班次时间");
          return;
        }
      }
      if (clockDay.length > 0) {
        for (let index = 0; index < clockDay.length; index++) {
          var start = clockDay[index].beginTimeData;
          var end = clockDay[index].beginTimeData;
          if (start.daySecond == "" || end == "") {
            this.$message.error("请先选择休息时间");
            return;
          }
        }
      }

      for (
        let index = 0;
        index < this.classesForm.arrangeDataList.length;
        index++
      ) {
        var hasEmptyField = Object.entries(
          this.classesForm.arrangeDataList[index]
        ).some(([key, value]) => value === undefined);
        if (hasEmptyField) {
          this.$message.error("输入框不能为空");
          return;
        }
      }

      this.$emit("comfin", this.classesForm);
    },

    // 计算总工时
    calculateTotal(work, rest) {
      if (!rest) {
        rest = 0;
      }
      const time = work - rest;
      const hours = Math.floor(time / 60);
      const mins = time % 60;
      const formattedTime = `${hours}小时${mins}分钟`;
      return formattedTime;
    },
  },
};
</script>

<style scoped>
.classes_form {
  padding: 30px;
  box-sizing: border-box;
}

::v-deep .classes_name {
  width: 413px;
}

.attendance {
  width: 100%;
  padding: 20px 30px;
  box-sizing: border-box;
  background-color: #f5f6fa;
}
.attendance_top {
  width: 100%;
  height: 75px;
  display: flex;
  align-items: center;
}
.attendance_time {
  color: rgba(26, 26, 26, 0.6);
  font-size: 15px;
}

.attendance_single {
  width: 100%;
  height: 330px;
  border-bottom: 1px solid #cfd4e8;
  padding-left: 20px;
  box-sizing: border-box;
  display: flex;
  overflow-x: auto;
  overflow-y: none;
}
.attendance_single::-webkit-scrollbar {
  display: none;
}
.attendance_single_gowork {
  display: flex;
  margin-bottom: 40px;
}

.attendance_single_leave {
  display: flex;
}

.attendance_single_gowork_left {
  margin-right: 75px;
}

.attendance_single_belate {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: rgba(26, 26, 26, 0.6);
  margin-bottom: 14px;
}

.attendance_single_other {
  margin-bottom: 33px;
}

.attendance_single_belate2 {
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-bottom: 14px;
  color: rgba(26, 26, 26, 0.6);
}

.attendance_single_num {
  margin: 0 12px;
}

.attendance_single_title {
  font-size: 16px;
  color: #1a1a1a;
}

.attendance_single_checkin {
  display: flex;
  align-items: center;
  font-size: 15px;
  color: rgba(26, 26, 26, 0.6);
}

.attendance_single_checkbox {
  margin-right: 30px !important;
}

.attendance_single_other {
  display: flex;
  align-items: center;
}

::v-deep .el-radio__label {
  color: rgba(26, 26, 26, 0.6);
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
}

::v-deep .el-radio .el-radio__input .el-radio__inner {
  border-radius: 2px;
}
::v-deep .el-radio__input.is-checked .el-radio__inner {
  border-color: #0058ff;
  background: #0058ff;
}
::v-deep .el-radio .el-radio__input.is-checked .el-radio__inner::after {
  content: "";
  width: 8px;
  height: 3px;
  border: 2px solid white;
  border-top: transparent;
  border-right: transparent;
  text-align: center;
  display: block;
  position: absolute;
  top: 2px;
  left: 1px;
  transform: rotate(-45deg);
  border-radius: 0px;
  background: none;
}

.classes_form_bto {
  display: flex;
  align-items: center;
  justify-content: center;
}

::v-deep .classes_form_diglog {
  width: 100px;
  margin-right: 30px;
}

::v-deep .classdiglog .el-dialog {
  padding: 0 !important;
}

::v-deep .el-form-item__label {
  font-size: 16px;
}

::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #5c5c5c;
}

::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background: #e0ecff;
  color: #0058ff;
  border-color: #0058ff;
}

::v-deep .el-dialog__title {
  font-size: 16px;
}

::v-deep .el-checkbox__label {
  color: rgba(26, 26, 26, 0.6);
  font-size: 16px;
  font-weight: 350;
  line-height: 40px;
}
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: rgba(26, 26, 26, 0.6);
}
::v-deep .el-input-number__decrease {
  height: 50% !important;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
::v-deep .el-input-number__increase {
  height: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.shanges {
  width: 116px;
  height: 30px;
  background: #dee4ed;
  border-radius: 4px 4px 4px 4px;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  color: #1a1a1a;
}
.attendance_add {
  width: 108px;
  height: 35px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #0058ff;
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #0058ff;
  margin-right: 20px;
  justify-content: center;
  cursor: pointer;
}
.attendance_add_icon {
  width: 12px;
  height: 12px;
  margin-right: 5px;
}
.attendance_all {
  width: 100%;
  max-height: 626px;
  overflow-y: scroll;
  background: #ffffff;
}
.attendance_all::-webkit-scrollbar {
  display: none;
}
.attendance_all_header {
  width: 100%;
  height: 63px;
  border-bottom: 1px solid #cfd4e8;
  display: flex;
  align-items: center;
}
.attendance_all_header_left {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 20px;
  box-sizing: border-box;
}
.attendance_all_header_right {
  width: 71px;
  height: 100%;
  line-height: 63px;
  text-align: center;
  border-left: 1px solid #cfd4e8;
  box-sizing: border-box;
}
.attendance_all_header_right2 {
  width: 71px;
  height: 100%;
  line-height: 280px;
  text-align: center;
  border-left: 1px solid #cfd4e8;
  box-sizing: border-box;
  font-size: 15px;
  cursor: pointer;
}
.disable {
  color: rgba(26, 26, 26, 0.3);
}
.nodisable {
  color: #0058ff;
}
.workhour {
  width: 204px;
}
.workrule {
  width: 461px;
}
.afterwordrule {
  flex: 1;
}
.workhour_time {
  display: flex;
  align-items: center;
  border: 1px solid #d5d7e3;
  border-radius: 4px;
  width: 164px;
}
::v-deep .el-select {
  width: 76px;
}
::v-deep .workhour_time .el-input__inner {
  border: none;
  width: 100%;
}
::v-deep .workhour_time .el-date-editor.el-input {
  width: 88px;
}
::v-deep .workhour_time_specific .el-input--suffix .el-input__inner {
  padding-right: 0 !important;
}
.workhour_time_up {
  margin-top: 29px;
}
.workhour_time_down {
  margin-top: 60px;
}
.attendance_single_num {
  width: 100px;
}
::v-deep .attendance_single_num .el-input-number__decrease {
  width: 32px;
  background: white;
}
::v-deep .attendance_single_num .el-input-number__increase {
  width: 32px;
  background: white;
}
::v-deep
  .attendance_single_belate
  .el-input-number.is-controls-right
  .el-input__inner {
  padding-left: 10px;
}
::v-deep
  .attendance_single_checkin
  .el-input-number.is-controls-right
  .el-input__inner {
  padding-left: 10px;
}
.mt {
  margin-top: 29px;
  width: 461px;
}
.mt2 {
  margin-top: 29px;
  width: 458px;
}
::v-deep .workhour_time .el-form-item__content {
  line-height: 20px;
}
::v-deep .attendance_single_belate .el-icon-arrow-up {
  background: none !important;
  font-size: 18px !important;
}
::v-deep .attendance_single_belate .el-icon-arrow-up::after {
  content: "\e6e1";
}
::v-deep .attendance_single_belate .el-icon-arrow-up::before {
  content: "" !important;
}
::v-deep .attendance_single_belate .el-icon-arrow-down {
  background: none !important;
  font-size: 18px !important;
}

::v-deep .attendance_single_belate2 .el-icon-arrow-up {
  background: none !important;
  font-size: 18px !important;
}
::v-deep .attendance_single_belate2 .el-icon-arrow-up::after {
  content: "\e6e1";
}
::v-deep .attendance_single_belate2 .el-icon-arrow-up:before {
  content: "" !important;
}
::v-deep .attendance_single_belate2 .el-icon-arrow-down {
  background: none !important;
  font-size: 18px !important;
}

::v-deep .attendance_single_belate3 .el-icon-arrow-up {
  background: none !important;
  font-size: 18px !important;
}
::v-deep .attendance_single_belate3 .el-icon-arrow-up::after {
  content: "\e6e1";
}
::v-deep .attendance_single_belate3 .el-icon-arrow-up::before {
  content: "" !important;
}
::v-deep .attendance_single_belate3 .el-icon-arrow-down {
  background: none !important;
  font-size: 18px !important;
}
.overtime_rule_type {
  margin-top: 4px;
  height: 40px;
  width: 100%;
  border-bottom: 1px solid #cfd4e8;
}
::v-deep .overtime_rule_type2 .el-select {
  width: 80px;
  height: 30px;
  margin: 0 11px;
  font-size: 14px;
  line-height: auto;
}
::v-deep .overtime_rule_type2 .el-input {
  width: 80px;
  height: 30px;
}
::v-deep .overtime_rule_type2 .el-select .el-input__inner {
  width: 100%;
  height: 100%;
  color: #1a1a1a;
}
.overtime_rule_type2 {
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-top: 20px;
  color: #1a1a1a;
}
.overtime_rule_type_add {
  width: 18px;
  height: 18px;
  margin-left: 11px;
  cursor: pointer;
}
::v-deep .error .el-input__inner {
  border-color: red;
}
.error_tips {
  color: red;
}
.error_tips2 {
  color: red;
  height: 20px;
  line-height: 20px;
}
.rest_period {
  display: flex;
  font-size: 15px;
  align-items: center;
  color: #1a1a1a;
  margin-top: 10px;
}
.rest_period_title {
  margin-right: 11px;
}
.rest_period_heng {
  margin: 0 10px;
}
.error_tips {
  color: red;
  width: 164px;
  line-height: 20px;
}
.time_list {
  display: flex;
  width: 370px;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.time_single {
  flex: 0 0 116px;
  width: 116px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 16px;
  color: #1a1a1a;
  background: #dee4ed;
  border-radius: 4px 4px 4px 4px;
  margin-right: 8px;
}
::v-deep .el-icon-arrow-down {
  background: none !important;
  font-size: 0.5rem;
  line-height: 1.3rem;
}
::v-deep .el-icon-arrow-down:before {
  content: "\e6df" !important;
  font-size: 18px;
  visibility: visible;
}
.attendance_dialog_title {
  font-size: 16px;
  color: #1a1a1a;
}
.attendance_dialog_content {
  padding: 30px;
  box-sizing: border-box;
  width: 100%;
  background: #f5f6fa;
  border-radius: 6px 6px 6px 6px;
  margin-top: 14px;
}
.attendance_dialog_single {
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-bottom: 20px;
  color: #1a1a1a;
}
.attendance_dialog_single2 {
  display: flex;
  align-items: flex-start;
  font-size: 15px;
  color: #1a1a1a;
}
.attendance_dialog_single_name {
  color: rgba(26, 26, 26, 0.6);
  margin-right: 10px;
}
.attendance_dialog_single_chock {
  margin-bottom: 10px;
}
</style>