<template>
  <div class="cycle_wrapper">
    <el-dialog
      :visible.sync="isShow2"
      :show-close="false"
      :close-on-click-modal="false"
      :key="tableKey"
    >
      <div class="drawer_add_h">
        <div>新建周期</div>
        <img
          src="@/assets/structure/close.png"
          class="diglog_icon"
          @click="closeAdd"
        />
      </div> 
      <div class="drawer_add_body">
        <el-form :model="groupForm" label-position="left">
          <el-form-item label="周期名称" prop="name" class="drawer_add_label">
            <el-input
              v-model="groupForm.name"
              placeholder="最多20个字符"
              maxlength="20"
            ></el-input>
          </el-form-item>

          <div class="drawer_add_body_t">周期班次</div>
          <div
            v-for="(item, index) in formList"
            class="drawer_add_list"
            :key="item.id"
          >
            <div class="drawer_add_title">第{{ index + 1 }}天</div>
            <!-- <el-input v-model="formList[index]"></el-input> -->
            <el-select v-model="formList[index]" placeholder="休息">
              <el-option
                v-for="item in formOptions"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </el-form>
        <div @click="addList" class="drawer_add_btn">
          <i class="el-icon-circle-plus-outline" style="margin-right: 10px"></i
          >添加
        </div>
      </div>

      <div class="diglog_operation">
        <el-button @click="closeAdd">取消</el-button>
        <el-button type="primary" @click="affirmDiglog">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
  
<script>
import http from "../../utils/http";
export default {
  components: {},
  props: ["isShow2", "groupId"],

  data() {
    return {
      tableKey: 0,

      formList: ["", "", "", "", "", "", ""],
      tableSelection: [], //导出表格需要
      id: this.groupId,
      multipleSelection: [],
      dialogAdd: true,
      groupForm: {},
      formOptions: [],
    };
  },
  watch: {},
  created() {
    this.getClassList();
  },
  methods: {
    addList() {
      this.formList.push("");
    },
    closeAdd() {
      this.$emit("closeAdd");
    },
    affirmDiglog() {
      var _this = this;
      // let Flag = false;
      // for (let i = 0; i < this.formList.length; i++) {
      //     if (_this.formList[i]) Flag = true;

      // }
      // if (!Flag) {
      //     _this.$message.error("请至少填写一个班次");
      //     return;
      // }
      if (!_this.groupForm.name) {
        _this.$message.error("请填写周期名称");
        return;
      }
      let arr = [];
      // let data = JSON.parse(localStorage.getItem("userinfo"));
      _this.formList.map((item, index) => {
        if (item == "" || item == "休息") _this.formList[index] = -1;
        arr.push({ arrange: _this.formList[index] });
      });
      http
        .post("/bmsAttendanceWeek/add", {
          attendanceGroup: parseInt(_this.id),
          weekDataList: arr,
          name: _this.groupForm.name,
        })
        .then((res) => {
          if (res.code == 200) {
            _this.$message.success("新增成功");
            _this.formList = ["", "", "", "", "", "", ""];
            _this.groupForm.name = "";
            _this.$emit("affirmDiglog2");
            this.getCycle({ attendanceGroup: _this.id });
          } else {
            _this.$message.error(res.message);
          }
        });
    },

    getClassList() {
      http
        .get("/bmsAttendanceGroupArrange/list", {
          attendanceGroup: this.id,
          pageNum: 1,
          pageSize: 10,
        })
        .then((res) => {
          if (res.code == 200) {
            this.formOptions = [];
            if (res.data.length > 0) {
              res.data.map((item) => {
                if (item.choose) {
                  this.formOptions.push(item.bmsAttendanceArrangeGetVO);
                }
              });
              this.formOptions.map((item) => {
                item.label = this.formatTime(item);
              });

              this.formOptions.push({
                label: "休息",
                id: -1,
              });
            }
          } else {
            this.$message.error(res.message);
          }
        });
    },
    getCycle(obj) {
      http.get("/bmsAttendanceWeek/list", obj).then((res) => {
        if (res.code == 200) {
          this.classData = res.data;
          this.cycleList = res.data.attendanceArrangeGetVOList;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    formatTime(tt) {
      let arr = tt.arrangeDataList;
      console.log(arr, 1);
      if (arr.length <= 0) return "";
      let str = "";
      for (let i = 0; i < arr.length; i++) {
        let str2 =
          this.secondsToHourMin(arr[i].clockData.beginDaySecond) +
          "-" +
          this.secondsToHourMin(arr[i].clockData.endDaySecond);
        if (str == "") str = str2;
        else str = str + "  " + str2;
      }
      return tt.name + "(" + str + ")";
    },

    // 秒转换成时间
    secondsToHourMin(seconds) {
      if (seconds >= 86400) {
        seconds = seconds - 86400;
      }
      // 将秒数转换为分钟
      let minutes = Math.floor(seconds / 60);
      // 将分钟转换为小时和分钟
      let hours = Math.floor(minutes / 60);
      let minutesRemainder = minutes % 60;

      // 格式化小时和分钟
      hours = hours < 10 ? "0" + hours : hours;
      minutesRemainder =
        minutesRemainder < 10 ? "0" + minutesRemainder : minutesRemainder;

      // 返回时分格式
      return hours + ":" + minutesRemainder;
    },
  },
};
</script>
<style scoped>
.cycle_wrapper ::v-deep .el-dialog {
  width: 620px;
}

.drawer_add_body {
  padding: 30px;
  border-top: 1px solid #cfd4e8;
}

.drawer_add_body_title {
  font-size: 16px;
  color: #1a1a1a;
  padding: 30px;
  box-sizing: border-box;
}

.drawer_add_h {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  padding: 24px;
  color: #1a1a1a;
}

.drawer_add_list {
  display: flex;
  height: 60px;
  line-height: 60px;
}

::v-deep .drawer_add_list .el-input__inner {
  width: 500px;
}

.drawer_add_title {
  width: 100px;

  font-size: 15px;
  color: #1a1a1a;
}

.drawer_add_btn {
  font-size: 15px;
  margin-top: 20px;
  color: #9a9a9a;
}

.diglog_icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

::v-deep .drawer_add_label .el-form-item__label {
  color: #1a1a1a;
  font-size: 16px;
  text-align: left;
  float: none;
  word-break: break-word;
}

::v-deep .drawer_add_label .el-form-item__label::after {
  content: "*";
  color: #1a1a1a;
  margin-left: 5px;
  font-size: 18px;
}

.drawer_add_body_t {
  font-size: 16px;
  margin: 10px 0 10px 0;
  color: #1a1a1a;
}

.diglog_operation {
  display: flex;
  align-items: center;
  justify-content: center;
}

::v-deep .drawer_add_label .el-form-item__label::after {
  color: red;
}
</style>
  