<template>
  <div class="checkin">
    <div class="checkin_title">系统设置 / <span>关于我们</span></div>
    <div class="checkin_information">
      <img src="@/assets/logo.png" class="logo" />
      <div class="about_info">
        <div class="about_info_title">版本号：</div>
        <div>V1.0.0</div>
      </div>
      <div class="about_info">
        <div class="about_info_title">公司：</div>
        <div>广州巨源信息技术有限公司</div>
      </div>
      <div class="about_info">
        <div class="about_info_title">公司地址：</div>
        <div>广州市黄埔区科珠北路232号益科智能创新园2栋610</div>
      </div>
      <div class="about_info">
        <div class="about_info_title">联系电话：</div>
        <div>4009939288</div>
      </div>
      <div class="about_info">
        <div class="about_info_title">公司网址：</div>
        <div>https://www.comet-juyuan.com</div>
      </div>
    </div>
    <div class="filing">
      CopyRight@2023广州巨源信息技术有限公司-<a
        href="https://beian.miit.gov.cn/"
        >粤ICP备2023127373号-4</a
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  methods: {},
};
</script>

<style scoped>
.checkin {
  width: 100%;
  height: 100%;
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: #f4f7fc;
}
.checkin_title {
  font-size: 14px;
  color: #b2b2b2;
  margin-bottom: 16px;
}
.checkin_title span {
  color: #5c5c5c;
}
.checkin_information {
  width: 100%;
  overflow-y: scroll;
  padding: 30px 36px;
  flex: 1;
  background: white;
  border-radius: 4px;
  box-shadow: 0px 1px 4px 1px rgba(21, 34, 50, 0.08);
  border-radius: 6px 6px 6px 6px;
  box-sizing: border-box;
}
.checkin_information::-webkit-scrollbar {
  display: none;
}
.logo {
  height: 71px;
  margin-bottom: 40px;
}
.about_info {
  display: flex;
  margin-bottom: 16px;
  align-items: center;
  color: rgba(26, 26, 26, 0.6);
}
.about_info_title {
  font-size: 16px;
  color: #1a1a1a;
  width: 104px;
}
</style>
