<template>
  <div class="visitor_information">
    <div class="visitor_information_title">
      考勤报表 / <span>部门考勤明细</span>
    </div>
    <div class="visitor_information_c">
      <div class="visitor_information_t">
        <div class="visitor_information_t_l">
          <el-date-picker
            v-model="date"
            @change="changeDate"
            type="daterange"
            range-separator="-"
            class="department_search_date"
            :picker-options="expireTimeOPtion"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
          <el-select
            v-model="selectTree"
            placeholder="部门"
            :popper-append-to-body="false"
            multiple
            @change="selectChange"
            class="select-tree"
          >
            <el-option :value="selectTreeValue" style="height: auto">
              <!-- option展开高度太小，把height设置为auto就好啦 -->
              <el-tree
                :data="departmentList"
                show-checkbox
                node-key="departmentId"
                ref="tree"
                accordion
                check-strictly
                highlight-current
                :props="defaultProps"
                @check="handleCheckChange"
              ></el-tree>
            </el-option>
          </el-select>
        </div>
        <div>
          <el-button @click="setReport">设置报表</el-button>
          <el-button @click="startDownload(tableSelection)">批量导出</el-button>
        </div>
      </div>
      <div class="visitor_information_table">
        <el-table
          v-loading="tableloading"
          height="45rem"
          ref="multipleTable"
          :data="tableData"
          class="v_table"
          border
          @selection-change="handleSelectionChange"
          :header-cell-style="getRowClass"
        >
          <template slot="empty">
            <div>
              <img src="@/assets/nodata.png" class="nodata" />
              <div>无数据</div>
            </div>
          </template>
          <el-table-column type="selection" width="55" fixed> </el-table-column>
          <el-table-column prop="jobNumber" label="工号" width="150" fixed>
          </el-table-column>
          <el-table-column prop="name" label="姓名" width="200" fixed>
            <template slot-scope="scope">
              <div class="visitor_name">
                <img
                  @error="itemError(scope.row)"
                  :src="scope.row.headUrl"
                  class="visitor_head"
                  v-if="scope.row.headUrl"
                />
                <img src="@/assets/head.png" v-else class="visitor_head" />
                <div class="ellipsis2">{{ scope.row.name }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="departmentName" label="部门" width="120" fixed>
            <template slot-scope="scope">
              <div class="ellipsis3">{{ scope.row.departmentName }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="departmentName"
            label="部门人数"
            width="120"
            fixed
          >
            <template slot-scope="scope">
              <div class="ellipsis3">{{ scope.row.departmentName }}</div>
            </template>
          </el-table-column>
          <el-table-column label="职位" width="150">
            <template slot-scope="scope">
              <div v-if="scope.row.job">{{ scope.row.job }}</div>
              <div v-else>- -</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="attendanceGroupName"
            label="考勤组名称"
            width="200"
          >
            <template slot-scope="scope">
              <span class="ellipsis">{{ scope.row.attendanceGroupName }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="companySelf" label="应出勤天数" width="100">
            <template slot-scope="scope">
              <div>{{ attendanceDay(scope.row.attendanceRecordList) }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="companySelf" label="实际出勤天数" width="120">
            <template slot-scope="scope">
              <div
                style="color: #0058ff"
                v-if="calculateDay(scope.row.attendanceRecordList, 1)"
              >
                {{ calculateDay(scope.row.attendanceRecordList, 1) }}
              </div>
              <div v-else style="color: #0058ff">0</div>
            </template>
          </el-table-column>
          <el-table-column prop="companySelf" label="出勤率" width="80">
            <template slot-scope="scope">
              <div>
                {{ attendanceCalculation(scope.row.attendanceRecordList) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="companySelf" label="旷工天数" width="80">
            <template slot-scope="scope">
              <div
                v-if="calculateDay(scope.row.attendanceRecordList, 2)"
                class="warning"
              >
                {{ calculateDay(scope.row.attendanceRecordList, 2) }}
              </div>
              <div v-else>- -</div>
            </template>
          </el-table-column>
          <el-table-column prop="companySelf" label="迟到次数" width="80">
            <template slot-scope="scope">
              <div
                v-if="calculateDay(scope.row.attendanceRecordList, 3)"
                class="warning"
              >
                {{ calculateDay(scope.row.attendanceRecordList, 3) }}
              </div>
              <div v-else>- -</div>
            </template>
          </el-table-column>
          <el-table-column prop="companySelf" label="早退次数" width="80">
            <template slot-scope="scope">
              <div
                v-if="calculateDay(scope.row.attendanceRecordList, 4)"
                class="warning"
              >
                {{ calculateDay(scope.row.attendanceRecordList, 4) }}
              </div>
              <div v-else>- -</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div
        class="newpagination"
        v-if="pages.total > 0"
        style="padding-left: 20px; box-sizing: border-box"
      >
        <div class="newpagination_text">共 {{ pages.total }} 条数据</div>
        <el-pagination
          layout="prev, pager, next"
          :total="pages.total"
          :current-page="pages.pageNum"
          :page-size="10"
          @current-change="chagePage"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 设置报表 -->
    <el-drawer
      :wrapperClosable="false"
      title=""
      :visible.sync="setReportDialog"
      :with-header="false"
      size="25%"
    >
      <div class="batch_t">
        <div class="branch_l">
          <img
            src="@/assets/structure/close.png"
            class="batch_close"
            @click="close"
          />
          <div>设置报表</div>
        </div>
        <div class="branch_bto">
          <el-button @click="close">取消</el-button>
          <el-button type="primary" @click="switcOperation">确认</el-button>
        </div>
      </div>
      <div class="batch_c">
        <el-tree
          :data="setReportList"
          ref="tree"
          show-checkbox
          node-key="id"
          :props="defaultProps2"
        >
        </el-tree>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import http from "../../../utils/http";
import exprotExcel from "../../../utils/exprotExcel";
export default {
  data() {
    return {
      tableloading: true,
      searchValue: "", //搜索的关键词
      searchicon: false, //监听输入
      tableData: [], //表格数据
      tipstitle: "确认删除此访客?",
      selectDeptid: [], //选择的部门
      selectUserid: [], //最终删除的数组
      date: null, //搜索的日期
      startTime: "", //开始日期
      endTime: "", //结束日期
      pages: {}, //分页
      multipleSelection: [],
      departmentList: [], //部门列表
      tableSelection: [], //导出表格需要
      company: {}, //公司信息
      delectVistorDiglog: false, //删除访客提示框
      selectedData: [],
      selectTree: "",
      selectTreeValue: [],
      expireTimeOPtion: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e7; //如果没有后面的-8.64e7就是不可以选择今天的
        },
      },
      defaultProps: {
        children: "subDepartment",
        label: "departmentName",
      },
      defaultProps2: {
        children: "children",
        label: "label",
      },
      setReportDialog: false, //设置报表
      tableColumnList: [], //生成的报表头
      setReportList: [
        {
          label: "部门",
          id: 1,
          disabled: true,
        },
        {
          label: "部门人数",
          id: 2,
          disabled: true,
        },
        {
          label: "应出勤天数",
          id: 3,
        },
        {
          label: "实际出勤天数",
          id: 4,
        },
        {
          label: "出勤率",
          id: 5,
        },
        {
          label: "标准工作时长",
          id: 6,
        },
        {
          label: "实际工作时长",
          id: 7,
        },
        {
          label: "异常统计",
          id: 8,
          children: [
            {
              label: "迟到次数",
              id: 9,
            },
            {
              label: "迟到时长",
              id: 10,
            },
            {
              label: "早退次数",
              id: 11,
            },
            {
              label: "早退时长",
              id: 12,
            },
            {
              label: "旷工天数",
              id: 13,
            },
          ],
        },
        {
          label: "假勤情况",
          id: 14,
          children: [
            {
              label: "补卡次数",
              id: 15,
            },
            {
              label: "外出",
              id: 17,
            },
            {
              label: "出差",
              id: 18,
            },
            {
              label: "年假",
              id: 19,
            },
            {
              label: "事假",
              id: 20,
            },
            {
              label: "病假",
              id: 21,
            },
            {
              label: "调休假",
              id: 22,
            },
            {
              label: "婚假",
              id: 23,
            },
            {
              label: "产休假",
              id: 24,
            },
            {
              label: "其他",
              id: 25,
            },
          ],
        },
        {
          label: "加班情况",
          id: 26,
          children: [
            {
              label: "加班时长",
              id: 27,
            },
            {
              label: "工作日加班时长",
              id: 28,
            },
            {
              label: "休息日加班时长",
              id: 29,
            },
            {
              label: "节假加班时长",
              id: 30,
            },
          ],
        },
      ], //报表总名称
      tableColumn: [
        {
          title: "工号",
          dataIndex: "jobNumbernew",
          id: 1,
        },
        {
          title: "姓名",
          dataIndex: "name",
          id: 2,
        },
        {
          title: "部门",
          dataIndex: "departmentName",
          id: 3,
        },
        {
          title: "职位",
          dataIndex: "job",
          id: 4,
        },
        {
          title: "考勤组名称",
          dataIndex: "attendanceGroupName",
          id: 5,
        },
        {
          title: "应出勤天数",
          dataIndex: "should",
          id: 6,
        },
        {
          title: "实际出勤天数",
          dataIndex: "reality",
          id: 7,
        },
        {
          title: "出勤率",
          dataIndex: "probability",
          id: 8,
        },
        {
          title: "旷工天数",
          dataIndex: "absenteeism",
          id: 9,
        },
        {
          title: "迟到次数",
          dataIndex: "belate",
          id: 10,
        },
        {
          title: "早退次数",
          dataIndex: "early",
          id: 11,
        },
      ], //导出内容参数
    };
  },
  created() {
    // 获取当前日期并搜索
    // this.getDate();

    // 获取部门列表
    this.getDepartment();
  },
  methods: {
    // 表头背景颜色变色
    getRowClass({ rowIndex, columnIndex }) {
      if (rowIndex == 0) {
        return "background:#F5F6FA;color:#5C5C5C";
      }
    },

    // 表格多选
    handleSelectionChange(val) {
      this.multipleSelection = [];
      this.tableSelection = val;
      val.map((item) => {
        this.multipleSelection.push(item.id);
      });
    },

    // 获取部门列表
    getDepartment() {
      http.get("/umsDepartment/list").then((res) => {
        if (res.code == 200) {
          if (res.data.length > 0) {
            this.departmentList = this.convertToNestedStructure(res.data);
          }
        }
      });
    },
    // 递归处理数据
    convertToNestedStructure(arr) {
      // 创建一个映射，用于快速查找具有特定id的对象
      const idMap = new Map(arr.map((item) => [item.id, item]));

      // 创建一个新数组来存储转换后的嵌套结构
      const nestedArr = [];

      // 遍历原始数组
      arr.forEach((item) => {
        // 如果该项有父元素，则将其添加到父元素的subDepartment数组中
        if (item.parent) {
          const parent = idMap.get(item.parent);
          if (parent) {
            if (!parent.subDepartment) {
              parent.subDepartment = [];
            }
            item.parentDepament = parent.name;
            parent.subDepartment.push(item);
          }
        } else {
          // 如果没有父元素，则添加到新数组的根级别
          nestedArr.push(item);
        }
      });

      // 返回转换后的嵌套数组
      return nestedArr;
    },

    // 获取访客列表
    getVisitor(val) {
      http
        .get(
          "/attendanceRecord/search/user",
          Object.assign(val, {
            pageSize: 10,
          })
        )
        .then((res) => {
          if (res.code == 200) {
            this.pages = res.data;
            this.tableData = res.data.list;
            this.tableloading = false;
          }
        });
    },

    // 设置报表
    setReport() {
      this.setReportDialog = true;
      setTimeout(() => {
        this.$refs.tree.setCheckedKeys(this.tableColumnList);
      }, 200);
    },

    // 关闭报表
    close() {
      this.setReportDialog = false;
    },

    // 确认修改报表
    switcOperation() {
      const list = this.$refs.tree.getCheckedKeys(true);
      this.tableColumnList = list;
      localStorage.setItem("departmentalreport", JSON.stringify(list));
      this.setReportDialog = false;
    },

    // 判断是否含有所属的表头
    judgingHeader(index) {
      if (this.tableColumnList.includes(index)) {
        return true;
      } else {
        return false;
      }
    },
    // 判断是否含有所属的表头(一级)
    judgingHeader2(value) {
      var arr1 = this.tableColumnList;
      // 异常统计
      var istrue = this.hasIntersection(arr1, value);
      if (istrue) {
        return true;
      } else {
        return false;
      }
    },
    // 判断两个数组是否有交集
    hasIntersection(arr1, arr2) {
      for (let i = 0; i < arr1.length; i++) {
        for (let j = 0; j < arr2.length; j++) {
          if (arr1[i] === arr2[j]) {
            return true;
          }
        }
      }
      return false;
    },

    // 表格下载之前
    startDownload(arr) {
      let target = event.target;
      if (target.nodeName == "I" || target.nodeName == "SPAN") {
        target = event.target.parentNode;
      }
      target.blur();
      if (arr.length == 0) {
        this.$message.error("请先选择记录");
        return;
      }
      let arr2 = [];
      for (let key of this.tableColumn) {
        arr2.push(key.key);
      }
      // const loading = this.$loading({
      //   lock: true,
      //   text: "导出表格中，请稍后...",
      //   spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.7)",
      // });
      arr.map((v) => {
        v.jobNumbernew = "\xa0" + v.jobNumber;
        v.should = v.attendanceRecordList.length;
        v.reality = this.calculateDay(v.attendanceRecordList, 1);
        v.probability = this.attendanceCalculation(v.attendanceRecordList);
        v.absenteeism = this.calculateDay(v.attendanceRecordList, 2);
        v.belate = this.calculateDay(v.attendanceRecordList, 3);
        v.early = this.calculateDay(v.attendanceRecordList, 4);
        for (let i = 0; i < arr2.length; i++) {
          if (v[arr2[i]] == undefined) {
            v[arr2[i]] = "";
          }
        }
        for (let key in v) {
          if (v[key] == null) v[key] = "";
        }
      });
      exprotExcel.export(this.tableColumn, arr, "部门考勤明细");
      this.$message.success("导出成功");
      this.$refs.multipleTable.clearSelection();
    },
    // 页数改变
    chagePage(e) {
      this.searchSetof(e);
    },

    // 搜索结果
    seachResult() {
      if (this.searchValue == "") {
        return;
      }

      this.searchSetof(1);
    },

    // 清除输入框
    clearup() {
      this.searchValue = "";
    },

    // 删除访客弹窗
    deleteInvitefirst(ids) {
      let target = event.target;
      if (target.nodeName == "I" || target.nodeName == "SPAN") {
        target = event.target.parentNode;
      }
      target.blur();
      var arr = [];
      if (Array.isArray(ids)) {
        if (ids.length == 0) {
          this.$message.error("请先选择访客");
          return;
        }
        this.tipstitle = "确认删除所选的访客？";
        arr = ids;
      } else {
        this.tipstitle = "确认删除此访客？";
        arr = [ids];
      }
      setTimeout(() => {
        this.delectVistorDiglog = true;
      }, 200);
      this.selectUserid = arr;
    },

    // 取消删除
    closeDelete() {
      this.delectVistorDiglog = false;
    },

    // 多选,删除任一select选项的回调
    selectChange(e) {
      var arrNew = [];
      var dataLength = this.selectedData.length;
      var eleng = e.length;
      for (let i = 0; i < dataLength; i++) {
        for (let j = 0; j < eleng; j++) {
          if (e[j] === this.selectedData[i].departmentName) {
            arrNew.push(this.selectedData[i]);
          }
        }
      }
      this.$refs.tree.setCheckedNodes(arrNew); //设置勾选的值

      if (e.length > 0) {
        this.selectDeptid = [];
        var arr = [];
        e.map((item) => {
          var index = this.selectedData.findIndex(
            (citem) => citem.departmentName == item
          );

          if (index > -1) {
            this.selectDeptid.push(this.selectedData[index].departmentId);
            arr.push(this.selectedData[index]);
          }
        });

        this.selectedData = arr;
      } else {
        this.selectedData = [];
        this.selectDeptid = [];
      }

      this.searchSetof(1);
    },
    // 多选框
    handleCheckChange(data, checked, indeterminate) {
      var arr = checked.checkedKeys;
      this.selectTree = [];

      var index = this.selectedData.findIndex(
        (item) => item.departmentId == data.departmentId
      );
      console.log(data);
      if (index > -1) {
        this.selectedData.splice(index, 1);
      } else {
        this.selectedData.push(data);
      }

      this.selectedData.map((item) => {
        this.selectTree.push(item.departmentName);
      });

      this.selectDeptid = arr;
      this.selectTreeValue = arr;

      // console.log(this.selectTreeValue);
      // console.log(this.selectTree);

      this.searchSetof(1);
    },

    // getSimpleCheckedNodes(store) {
    //   const checkedNodes = [];
    //   const traverse = function (node) {
    //     const childNodes = node.root ? node.root.childNodes : node.childNodes;
    //     childNodes.forEach((child) => {
    //       if (child.checked) {
    //         checkedNodes.push(child.data);
    //       }
    //       if (child.indeterminate) {
    //         traverse(child);
    //       }
    //     });
    //   };
    //   traverse(store);
    //   return checkedNodes;
    // },
    // 设置默认选中的tree回显
    setCheckedKeys() {
      this.$refs.tree.setCheckedKeys([7, 81, 82]);
    },

    // 选择所选日期
    changeDate(e) {
      if (e) {
        var start = new Date(e[0]);
        var end = new Date(e[1]);

        var syear = start.getFullYear();
        var smonth =
          start.getMonth() + 1 <= 9
            ? "0" + (start.getMonth() + 1)
            : start.getMonth() + 1;
        var sday =
          start.getDate() <= 9 ? "0" + start.getDate() : start.getDate();

        var eyear = end.getFullYear();
        var emonth =
          end.getMonth() + 1 <= 9
            ? "0" + (end.getMonth() + 1)
            : end.getMonth() + 1;
        var eday = end.getDate() <= 9 ? "0" + end.getDate() : end.getDate();

        this.startTime = syear + "-" + smonth + "-" + sday;
        this.endTime = eyear + "-" + emonth + "-" + eday;
      } else {
        this.$nextTick(() => {
          setTimeout(() => {
            this.getDate();
          }, 500);
        });
      }

      this.searchSetof(1);
    },

    // 搜索集合
    searchSetof(page) {
      var object = {
        pageNum: page,
      };
      if (this.startTime && this.endTime) {
        object.startDate = this.startTime;
        object.endDate = this.endTime;
      }

      if (this.selectDeptid.length > 0) {
        object.departmentIdList = this.selectDeptid.join(",");
      }

      this.getVisitor(object);
    },

    // 计算应出勤天数
    attendanceDay(arr) {
      if (arr.length > 0) {
        var newarr = [];
        arr.map((item) => {
          if (item.arrange > 0) {
            newarr.push(item);
          }
        });

        return newarr.length;
      } else {
        return "0";
      }
    },

    // 计算天数
    calculateDay(arr, type) {
      // 1是实际出勤,2是旷工,3是迟到,4是早退
      var absenteeism = 0;
      var attendance = 0;
      var belate = 0;
      var leave = 0;
      if (arr.length > 0) {
        arr.map((item) => {
          if (item.lateCount) {
            belate += item.lateCount;
          }
          if (item.earlyCount) {
            leave += item.earlyCount;
          }
          if (item.absence) {
            attendance += item.absence;
          }
          if (item.timeString) {
            absenteeism += 1;
          }
        });

        switch (type) {
          case 1:
            return absenteeism;
          case 2:
            return attendance;
          case 3:
            return belate;
          case 4:
            return leave;
        }
      } else {
        return false;
      }
    },

    //  出勤率计算
    attendanceCalculation(arr) {
      if (arr.length > 0) {
        var absenteeism = 0;
        var newarr = [];
        arr.map((item) => {
          if (item.arrange > -1) {
            newarr.push(item);
          }
        });

        newarr.map((citem) => {
          if (citem.timeString) {
            absenteeism += 1;
          }
        });

        if (newarr.length == 0) {
          return "- -";
        } else {
          return (
            Math.round((absenteeism / newarr.length) * 10000) / 100.0 + "%"
          );
        }
      } else {
        return "- -";
      }
    },

    // 获取当前月份
    getDate() {
      var date = new Date();
      var year = date.getFullYear(); //获取完整的年份(4位)
      var month =
        date.getMonth() <= 9 ? "0" + date.getMonth() : date.getMonth();
      var newmonth =
        date.getMonth() + 1 <= 9
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      var day = date.getDate();

      var lastDate = new Date(year, month, 0).getDate(); //获取当月最后一日
      if (day == "01") {
        this.startTime = year + "-" + month + "-01";
      } else {
        this.startTime = year + "-" + newmonth + "-01";
      }

      if (day <= lastDate) {
        var newday = "";
        if (day - 1 < 10) {
          newday = "0" + (day - 1);
        } else {
          newday = day - 1;
        }
        this.endTime = year + "-" + newmonth + "-" + newday;
      }
      this.date = [this.startTime, this.endTime];

      this.searchSetof(1);
    },

    itemError(item) {
      item.headUrl = require("@/assets/head.png");
    },
  },
};
</script>

<style scoped>
.visitor_information {
  width: 100%;
  height: 100%;
  padding: 16px 20px;
  box-sizing: border-box;
  background: #f4f7fc;
}
.visitor_information_c {
  width: 100%;
  box-shadow: 0px 1px 4px 1px rgba(21, 34, 50, 0.08);
  height: 940px;
  box-sizing: border-box;
  border-radius: 6px;
  background: white;
}
.visitor_information_t {
  height: 76px;
  padding: 0 30px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(207, 212, 232, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.visitor_information_search {
  width: 243px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #d0d0d0;
  display: flex;
  align-items: center;
  padding-right: 12px;
  box-sizing: border-box;
  justify-content: space-between;
}
.department_search_inp {
  width: 200px;
}
.visitor_information_table {
  width: 100%;
  height: 720px;
  padding: 20px;
  position: relative;
  box-sizing: border-box;
}
.visitor_information_title {
  font-size: 14px;
  color: #b2b2b2;
  margin-bottom: 16px;
}
.visitor_information_title span {
  color: #5c5c5c;
}
.memeber_operation {
  margin-left: 8px;
  width: 107px;
}
.detailed {
  width: 86px;
  height: 26px;
  background: #e0ecff;
  color: #0058ff;
  font-size: 14px;
  text-align: center;
  line-height: 26px;
  cursor: pointer;
}
.qrcode {
  width: 100%;
}
.qrcode_frame {
  width: 457px;
  height: 358px;
  display: flex;
  color: #1a1a1a;
  margin: 60px auto 24px;
  flex-direction: column;
  align-items: center;
  padding-top: 46px;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.16);
}
.qrcode_name {
  font-size: 16px;
  margin-bottom: 30px;
}
.qrcode_img {
  width: 200px;
  height: 200px;
  background: black;
}
.qrcode_tips {
  color: #9a9a9a;
  font-size: 15px;
  text-align: center;
}
.qrcode_bto {
  display: flex;
  margin-top: 39px;
  justify-content: center;
}
.link_frame {
  width: 797px;
  height: 132px;
  margin: 30px;
  font-size: 16px;
  padding: 24px;
  color: #1a1a1a;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.16);
}
.link_tips {
  font-size: 15px;
  color: #9a9a9a;
  padding-left: 30px;
  box-sizing: border-box;
}
.link_bto {
  display: flex;
  margin-top: 289px;
  justify-content: center;
}
.invite_h {
  height: 73px;
  padding: 0 24px;
  color: #1a1a1a;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 18px;
  justify-content: space-between;
}
.invite_icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.visitor_name {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #1a1a1a;
}
.visitor_head {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 10px;
}
.primary-button-style {
  background: #2979ff;
  border-color: #2979ff;
}
::v-deep .el-table__body .el-table__row.hover-row td {
  background-color: rgba(0, 98, 255, 0.05);
}
.newpagination {
  margin-top: 74px;
}
.visitor_information_search:focus-within {
  border-color: #0096fa;
}
.v_table {
  width: 97%;
  height: 100%;
  position: absolute;
}
::v-deep .department_search_date {
  width: 283px;
  height: 36px;
  margin-right: 10px;
}
.visitor_information_t_l {
  display: flex;
  align-items: center;
}
.select-tree {
  width: 200px;
}
::v-deep .select-tree .el-select__tags {
  height: 40px;
  overflow: hidden;
  white-space: nowrap;
  flex-wrap: nowrap;
}
::v-deep .select-tree .el-select__tags-text {
  display: inline-block;
  max-width: 85px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
::v-deep .el-select-dropdown.is-multiple .el-select-dropdown__item {
  padding: 0;
}
::v-deep .el-table__fixed {
  height: 98% !important;
}
::v-deep .el-tree-node__content {
  height: 51px;
  font-size: 15px;
  color: #5c5c5c;
}
::v-deep .el-select {
  height: 36px;
}
::v-deep .select-tree .el-select__tags {
  height: 36px;
  overflow: hidden;
  white-space: nowrap;
  flex-wrap: nowrap;
}
::v-deep .select-tree .el-select__tags-text {
  display: inline-block;
  max-width: 85px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
::v-deep .el-select-dropdown.is-multiple .el-select-dropdown__item {
  padding: 0;
}
::v-deep .el-select .el-input__inner {
  height: 36px;
}
::v-deep .el-date-editor .el-range-separator {
  line-height: 28px;
}
::v-deep .el-input--suffix .el-input__inner {
  height: 36px !important;
}
.ellipsis {
  width: 150px;
  overflow: hidden; /* 超出部分隐藏 */
  white-space: nowrap; /* 禁止文本换行 */
  text-overflow: ellipsis;
  display: block;
}
.ellipsis2 {
  width: 120px;
  overflow: hidden; /* 超出部分隐藏 */
  white-space: nowrap; /* 禁止文本换行 */
  text-overflow: ellipsis;
}
.ellipsis3 {
  width: 120px;
  overflow: hidden; /* 超出部分隐藏 */
  white-space: nowrap; /* 禁止文本换行 */
  text-overflow: ellipsis;
}
::v-deep .visitor_information_table .el-table .el-table__cell {
  padding: 0;
}
::v-deep .el-table__body-wrapper {
  height: 83% !important;
}
</style>
