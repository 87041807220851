<template>
  <div class="method common-style">
    <div class="method_head">
      <span style="color: #b2b2b2">系统设置 /</span>
      <span style="color: #5c5c5c"> 修改密码</span>
    </div>
    <div class="method_wrapper">
      <div class="method_search_wrapper">
        <div class="method_search">修改密码</div>
      </div>
      <el-form
        class="form_device"
        :model="passForm"
        :rules="rules"
        ref="ruleForm"
        label-position="left"
      >
        <el-form-item label="原密码" prop="oldPassword">
          <el-input
            v-model="passForm.oldPassword"
            style="width: 413px"
            placeholder="请输入原密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="password">
          <el-input
            :type="isShowpas"
            v-model="passForm.password"
            autocomplete="new-password"
            style="width: 413px"
            placeholder="密码需同时包含字母和数字，至少8个字符"
          ></el-input>
          <img :src="showPasimg" class="check_pass" @click="switchShow" />
        </el-form-item>
        <el-form-item label="确认密码" prop="newPassword">
          <div style="width: 413px">
            <el-input
              :type="isShowpas2"
              v-model="passForm.newPassword"
              style="width: 413px"
              placeholder="再次输入密码"
            ></el-input>
            <img :src="showPasimg2" class="check_pass" @click="switchShow2" />
          </div>
        </el-form-item>
        <el-form-item>
          <el-button
            style="width: 100px; margin-top: 10px"
            type="primary"
            @click="changePass"
            >确认</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="filing">
      CopyRight@2023广州巨源信息技术有限公司-<a
        href="https://beian.miit.gov.cn/"
        >粤ICP备2023127373号-4</a
      >
    </div>
  </div>
</template>

<script>
import http from "../../utils/http";
import check from "../../assets/login/check.png";
import close from "../../assets/login/close.png";
export default {
  components: {},
  data() {
    // 密码验证
    const validatePass = (rule, value, callback) => {
      if (value) {
        if (value.length < 8) {
          callback(new Error("密码小于8位"));
        } else {
          let reg = /^(\d+[a-zA-Z]+|[a-zA-Z]+\d+)([0-9a-zA-Z]*)$/;
          if (!reg.test(value)) {
            callback(new Error("密码格式错误，只能由数字和字母组合"));
          } else {
            callback();
          }
        }
      } else callback(new Error("请输入新密码"));
    };
    const passWordTow = (rule, value, callback) => {
      if (value !== this.passForm.password) {
        callback(new Error("两次密码不一致"));
      } else {
        callback();
      }
    };
    return {
      isShowpas: "password",
      isShowpas2: "password",
      showPasimg: check,
      showPasimg2: check,
      passForm: {},
      rules: {
        oldPassword: [
          { required: true, message: "请输入原密码", trigger: "blur" },
        ],
        password: [
          { required: true, trigger: "blur", validator: validatePass },
        ],
        newPassword: [
          { required: true, message: "请再次输入新密码", trigger: "blur" },
          { required: true, trigger: "blur", validator: passWordTow },
        ],
      },
    };
  },
  created() {},
  methods: {
    changePass() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          http
            .post("/umsSysUser/change_password", {
              oldPassword: this.passForm.oldPassword,
              newPassword: this.passForm.newPassword,
            })
            .then((res) => {
              if (res.code == 200) {
                this.$message.success("修改成功");
                this.$router.replace("/");
              } else {
                this.$message.error(res.message);
              }
            });
        }
      });
    },
    // 切换密码显示
    switchShow() {
      if (this.isShowpas == "password") {
        this.isShowpas = "text";
        this.showPasimg = close;
      } else {
        this.isShowpas = "password";
        this.showPasimg = check;
      }
    },
    // 切换密码显示
    switchShow2() {
      if (this.isShowpas2 == "password") {
        this.isShowpas2 = "text";
        this.showPasimg2 = close;
      } else {
        this.isShowpas2 = "password";
        this.showPasimg2 = check;
      }
    },
  },
};
</script>
<style scoped>
.method {
  /* width: 100%; */
  padding: 16px 20px;
  background: #f4f7fc;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  flex-direction: column;
  z-index: -1;
}
.method_head {
  font-size: 14px;
}
.method_wrapper {
  width: auto;
  flex: 1;
  background: #ffffff;
  box-shadow: 0px 1px 4px 1px rgba(21, 34, 50, 0.08);
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  margin-top: 16px;
}
.method_search_wrapper {
  display: flex;
  padding: 30px;
  width: auto;
  border-bottom: 1px solid rgba(207, 212, 232, 0.5);
  font-size: 16px;
}
.method_search {
  display: flex;
  width: 750px;
}
.form_method {
  margin: 30px 0 0 30px;
}
.form_method-tip {
  height: 32px;
  border-radius: 4px 4px 4px 4px;
  line-height: 32px;
  color: #9a9a9a;
  margin-bottom: 30px;
  font-size: 16px;
}
.email_two_body {
  margin: 40px 30px;
}
.form_device {
  margin: 40px 0 0 60px;
}
.check_pass {
  position: absolute;
  top: 50%;
  margin-left: -40px;
  cursor: pointer;
  width: 18px;
  height: 18px;
  transform: translateY(-50%);
}
</style>