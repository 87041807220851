<template>
  <div class="approval_frame">
    <div class="structure_t">审批管理 / <span>人员审批</span></div>
    <div class="approval">
      <div class="approval_t">
        <div class="approval_t_left">
          <div class="approval_search">
            <el-input
              v-model="searchForm.likeString"
              @keyup.enter.native="seachResult"
              class="department_search_inp"
              placeholder="请输入姓名、手机号" 
              style="width: 200px"
            >
            </el-input>
            <img
              src="@/assets/structure/delete.png"
              class="searchicon"
              @click="clearup"
              v-if="searchicon"
            />
            <img
              src="@/assets/structure/search.png"
              class="searchicon_t"
              @click="seachResult"
            />
          </div>
        </div>
        <div>
          <!-- <el-button @click="approvalOperation(multipleSelection, 2)"
            >批量通过</el-button
          > -->
          <el-button @click="approvalOperation(multipleSelection, 1)"
            >批量驳回</el-button
          >
          <el-button @click="startDownload">导出报表</el-button>
        </div>
      </div>
      <div class="approval_table">
        <el-table
          height="100%"
          :data="tableData"
          :header-cell-style="getRowClass"
          @selection-change="handleSelectionChange"
          ref="table"
          class="a_table"
          row-key="id"
        >
          <template slot="empty">
            <div>
              <img src="@/assets/nodata.png" class="nodata" />
              <div>无数据</div>
            </div>
          </template>
          <el-table-column type="selection" width="55" reserve-selection>
          </el-table-column>
          <el-table-column prop="type" label="申请类型" width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 3">管理员</span>
              <span v-else-if="scope.row.status == 4">超级管理员</span>
              <span v-else>员工</span>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="姓名" width="250">
            <template slot-scope="scope">
              <div class="approval_name">
                <img
                  :src="scope.row.headUrl"
                  class="approval_head"
                  v-if="scope.row.headUrl"
                />
                <img src="@/assets/head.png" v-else class="approval_head" />
                <div>{{ scope.row.name }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="性别" width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.sex == 1">男</span>
              <span v-if="scope.row.sex == 0">女</span>
            </template>
          </el-table-column>
          <el-table-column prop="job" label="职位" width="150">
          </el-table-column>
          <el-table-column prop="idCard" label="身份证" width="200">
          </el-table-column>
          <el-table-column prop="phone" label="手机号" width="200">
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-link type="primary" @click="reEmployes(scope.row.id)"
                >通过</el-link
              >
              <el-link
                type="danger"
                style="margin-left: 24px"
                @click="approvalOperation(scope.row.id, 1)"
                >驳回</el-link
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="newpagination" v-if="pages.total > 0">
        <div class="newpagination_text">共 {{ pages.total }} 条数据</div>
        <el-pagination
          layout="prev, pager, next"
          :total="pages.total"
          :current-page="pages.pageNum"
          :page-size="50"
          @current-change="chagePage"
        >
        </el-pagination>
      </div>

      <!-- 编辑员工弹窗 -->
      <el-drawer
        :wrapperClosable="false"
        title=""
        :key="selectEmployees"
        :visible.sync="reEmployment"
        :with-header="false"
        size="47%"
      >
        <ReEmployment
          @close="closeEmploy"
          @comfinEmploy="addUser"
          :id="selectEmployees"
        />
      </el-drawer>
    </div>
    <div class="filing">
      CopyRight@2023广州巨源信息技术有限公司-<a 
        href="https://beian.miit.gov.cn/"
        >粤ICP备2023127373号-4</a
      >
    </div>
  </div>
</template>

<script>
import ReEmployment from "../../views/structure/module/member/reEmployment.vue";
import http from "../../utils/http";
import exprotExcel from "../../utils/exprotExcel";
export default {
  data() {
    return {
      selectEmployees: Math.random(),
      searchValue: "", //搜索的关键词
      searchicon: false, //搜索图标
      searchForm: {}, //搜索列表
      tableData: [],
      searchData: [], //搜索列表
      loading: false, //搜索加载
      tableSelection: [], //所选的全部员工
      multipleSelection: [], //多选操作
      finalEmplo: [], //最终员工id
      reEmployment: false, //重新入职编辑
      selectEmployees: null, //所选员工id
      pages: {},
      //   部门选择
      selectTree: "",
      treeKey: 0,
      selectTreeValue: "",
      departmentList: [], //部门列表
      defaultProps: {
        children: "subDepartment",
        label: "name",
      },

      tableColumn: [
        {
          title: "申请类型",
          dataIndex: "applyType",
          id: 1,
        },
        {
          title: "姓名",
          dataIndex: "name",
          id: 2,
        },
        {
          title: "性别",
          dataIndex: "sexText",
          id: 3,
        },
        {
          title: "职位",
          dataIndex: "job",
          id: 6,
        },
        {
          title: "身份证",
          dataIndex: "idCard",
          id: 7,
        },
        {
          title: "手机号",
          dataIndex: "phone",
          id: 8,
        },
      ], //导出内容参数
    };
  },
  watch: {
    "searchForm.likeString": {
      immediate: true,
      deep: true,
      handler(newItems, oldItems) {
        if (newItems) {
          this.searchicon = true;
        } else {
          this.searchicon = false;

          // 获取审批列表
          this.approvalList({
            pageNum: 1,
          });
        }
      },
    },
  },
  created() {
    // 获取审批列表
    this.approvalList({
      pageNum: 1,
    });

    this.getDepartment();
  },
  components: { ReEmployment },
  methods: {
    // 表头背景颜色变色
    getRowClass({ rowIndex, columnIndex }) {
      if (rowIndex == 0) {
        return "background:#F5F6FA;color:#5C5C5C";
      }
    },

    // 获取审批列表
    approvalList(value) {
      http.get(
          "/umsUser/list",
          Object.assign(
            {
              pageSize: 50,
              typeList: "0",
            },
            value,
            this.searchForm
          )
        )
        .then((res) => {
          if (res.code == 200) {
            this.pages = res.data;
            this.tableData = res.data.list;
            this.$bus.$emit("approvalNum", res.data.total);
          }
        });
    },
    // 页数改变
    chagePage(e) {
      this.approvalList({
        pageNum: e,
      });
    },
    // 批量操作
    handleSelectionChange(val) {
      this.multipleSelection = [];
      this.tableSelection = val;
      val.map((item) => {
        this.multipleSelection.push(item.id);
      });
    },

    // 获取部门列表
    getDepartment() {
      http.get("/umsDepartment/list").then((res) => {
        if (res.code == 200) {
          if (res.data.length > 0) {
            this.departmentList = this.convertToNestedStructure(res.data);
          }
        }
      });
    },
    // 递归处理数据
    convertToNestedStructure(arr) {
      // 创建一个映射，用于快速查找具有特定id的对象
      const idMap = new Map(arr.map((item) => [item.id, item]));

      // 创建一个新数组来存储转换后的嵌套结构
      const nestedArr = [];

      // 遍历原始数组
      arr.forEach((item) => {
        // 如果该项有父元素，则将其添加到父元素的subDepartment数组中
        if (item.parent) {
          const parent = idMap.get(item.parent);
        if (parent) {
            if (!parent.subDepartment) {
              parent.subDepartment = [];
            }
            item.parentDepament = parent.name;
            parent.subDepartment.push(item);
          }
        } else {
          // 如果没有父元素，则添加到新数组的根级别
          nestedArr.push(item);
        }
      });

      // 返回转换后的嵌套数组
      return nestedArr;
    },
    // 清空部门
    selectDept(e) {
      if (e == "") {
        delete this.searchForm.department;
        this.approvalList({
          pageNum: 1,
        });
      }
    },
    // 部门选择
    nodeClick(item) {
      const { id, name } = item;
      this.selectTree = name;
      this.selectTreeValue = id;
      this.$refs.stlectTree.blur(); 

      this.searchForm.department = this.selectTreeValue;
      this.approvalList({
        pageNum: 1,
      });
    },
    // 关闭重新入职弹窗
    closereEmploymen() {
      this.selectEmployees = null;
      this.reEmploymentDiglog = false;
    },
    // 入职
    reEmployes(id) {
      this.selectEmployees = id;
      setTimeout(() => {
        this.reEmployment = true;
      }, 500);
    },
    // 关闭员工信息或者添加员工
    closeEmploy() {
      this.selectEmployees = null;
      this.reEmployment = false;
    },
    // 新增员工
    addUser() {
      this.reEmployment = false;
      this.selectEmployees = null;
      // 获取审批列表
      this.approvalList({
        pageNum: 1,
      });
    },
    // 搜索结果
    seachResult() {
      this.approvalList({
        pageNum: 1,
      });
    },

    // 清除输入框
    clearup() {
      this.searchForm.likeString = "";
      this.approvalList({
        pageNum: 1,
      });
    },
    // 表格下载之前
    async startDownload() {
      var finalArr = [];
      const loading = this.$loading({
        lock: true,
        text: "导出报表中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let target = event.target;
      if (target.nodeName == "I" || target.nodeName == "SPAN") {
        target = event.target.parentNode;
      }
      target.blur();
      let arr2 = [];
      for (let key of this.tableColumn) {
        arr2.push(key.key);
      }

      if (this.tableSelection.length > 0) {
        finalArr = this.tableSelection;
      } else {
        var arr = await http.get(
          "/umsUser/list",
          Object.assign(this.searchForm, {
            pageNum: 1,
            typeList: "0",
            pageSize: 99999,
          })
        );
        if (arr.code == 200) {
          finalArr = arr.data.list;
        }
      }

      if (finalArr.length > 0) {
        loading.close();
        finalArr.map((v) => {
          v.jobNumbernew = "\xa0" + v.jobNumber;
          if (v.umsDepartment) {
            v.departmentName = v.umsDepartment.name;
          }
          if (v.sex == 0) {
            v.sexText = "女";
          } else if (v.sex == 1) {
            v.sexText = "男";
          }
          if (v.status == 3) {
            v.applyType = "管理员";
          } else if (v.status == 4) {
            v.applyType = "超级管理员";
          } else {
            v.applyType = "员工";
          }
          for (let i = 0; i < arr2.length; i++) {
            if (v[arr2[i]] == undefined) {
              v[arr2[i]] = "";
            }
          }
          for (let key in v) {
            if (v[key] == null) v[key] = "";
          }
        });
        exprotExcel.export(this.tableColumn, finalArr, "人员审核表");
        this.$message.success("导出成功");
      } else {
        loading.close();
        this.$message.error("无数据导出");
      }
    },
    // 审批操作
    approvalOperation(ids, type) {
      if (!ids || ids.length == 0) {
        this.$message.error("请先选择人员");
        return;
      }
      var arr = [];
      if (Array.isArray(ids)) {
        arr = ids;
      } else {
        arr = [ids];
      }

      http
        .post("/umsUser/update", {
          ids: arr,
          status: type,
          userUpdateType: "NORMAL",
        })
        .then((res) => {
          if (res.code == 200) {
            this.$refs.table.clearSelection();
            if (type == 2) {
              this.$message.success("审批通过");
            } else {
              this.$message.success("审批不通过");
            }

            // 重新获取审批列表
            this.approvalList({
              pageNum: 1,
            });
          } else {
            this.$message.error(res.message);
          }
        });
    },

    // 打开下发弹窗
    openIssued(arr) {
      this.issuedKey = Math.random();
      this.finalEmplo = JSON.stringify(arr);
      setTimeout(() => {
        this.memberIssued = true;
      }, 200);
    },

    // 取消下发
    closeIssued() {
      this.memberIssued = false;
      this.finalEmplo = [];
      this.issuedKey = Math.random();
    },
  },
};
</script>

<style scoped>
.approval_frame {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  box-sizing: border-box;
  background-color: #f4f7fc;
}
.structure_t {
  font-size: 14px;
  color: #b2b2b2;
  margin-bottom: 16px;
}
.structure_t span {
  color: #5c5c5c;
}
.approval {
  width: 100%;
  flex: 1;
  padding: 24px 30px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: white;
}
.approval_t {
  display: flex;
  margin-bottom: 16px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
}
.approval_search {
  width: 243px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #d0d0d0;
  display: flex;
  align-items: center;
  padding-right: 12px;
  box-sizing: border-box;
  justify-content: space-between;
}
.approval_table {
  width: 100%;
  flex: 1;
  position: relative;
  overflow-y: scroll;
  box-sizing: border-box;
}
.approval_table::-webkit-scrollbar {
  display: none;
}
.approval_name {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #1a1a1a;
}
.a_table {
  width: 100%;
  height: 100%;
  position: absolute;
}
.approval_head {
  width: 30px;
  object-fit: cover;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}
::v-deep .el-table__body .el-table__row.hover-row td {
  background-color: rgba(0, 98, 255, 0.05);
}
.approval_search:focus-within {
  border-color: #0096fa;
}
.newpagination {
  margin-top: 74px;
  padding: 0 20px;
  box-sizing: border-box;
}
.approval_t_left {
  display: flex;
  align-items: center;
}
::v-deep .el-select {
  width: 140px;
  height: 36px;
}
::v-deep .el-select .el-input__inner {
  height: 36px;
}
.ellipsis {
  width: 150px;
  white-space: nowrap; /* 防止文本换行 */
  overflow: hidden; /* 隐藏超出容器的文本 */
  text-overflow: ellipsis; /* 显示省略号表示文本被截断 */
}
</style>
