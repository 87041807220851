<template>
  <div class="visitor_information">
    <div class="visitor_information_title">
      考勤报表 / <span>个人考勤汇总</span>
    </div>
    <div class="visitor_information_c">
      <div class="visitor_information_t">
        <div class="visitor_information_t_left">
          <el-date-picker
            v-model="date"
            type="daterange"
            @change="changeDate"
            range-separator="-"
            class="department_search_date"
            :picker-options="expireTimeOPtion"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
          <el-select
            v-model="selectTree"
            placeholder="部门"
            :popper-append-to-body="false"
            multiple
            @change="selectChange"
            class="select-tree"
          >
            <el-option :value="selectTreeValue" style="height: auto">
              <!-- option展开高度太小，把height设置为auto就好啦 -->
              <el-tree
                :data="departmentList"
                show-checkbox
                node-key="id"
                ref="tree"
                accordion
                check-strictly
                highlight-current
                :props="defaultProps"
                @check="handleCheckChange"
              ></el-tree>
            </el-option>
          </el-select>
          <div class="visitor_information_search">
            <el-input
              class="department_search_inp"
              v-model="searchValue"
              placeholder="搜索员工工号、姓名"
            ></el-input>
            <img
              src="@/assets/structure/delete.png"
              class="searchicon"
              @click="clearup"
              v-if="searchicon"
            />
          </div>
          <el-button
            class="visitor_information_bto"
            type="primary"
            @click="searchSetof(1)"
            >查询</el-button
          >
          <el-button class="visitor_information_bto2" @click="reset"
            >重置</el-button
          >
        </div>
        <div>
          <el-button @click="setReport">设置报表</el-button>
          <el-button @click="startDownload">导出报表</el-button>
        </div>
      </div>
      <div class="visitor_information_table">
        <el-table
          v-loading="tableloading"
          height="95%"
          ref="multipleTable"
          :data="tableData"
          :key="tableKey"
          border
          class="v_table"
          row-key="id"
          @selection-change="handleSelectionChange"
          :header-cell-style="getRowClass"
        >
          <template slot="empty">
            <div>
              <img src="@/assets/nodata.png" class="nodata" />
              <div>无数据</div>
            </div>
          </template>
          <el-table-column type="selection" width="55" reserve-selection>
          </el-table-column>
          <el-table-column label="姓名" width="200" fixed>
            <template slot-scope="scope">
              <div class="visitor_name">
                <div class="ellipsis2">{{ scope.row.name }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="员工工号" width="150" fixed>
            <template slot-scope="scope">
              <div>
                {{ scope.row.jobNumber }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="部门" width="150" fixed>
            <template slot-scope="scope">
              <div v-if="scope.row.umsDepartment">
                {{ scope.row.umsDepartment.name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="考勤组名称"
            min-width="220"
            v-if="judgingHeader(4)"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.bmsAttendanceGroup">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="scope.row.bmsAttendanceGroup.name"
                  v-if="!isEmptyObject(scope.row.bmsAttendanceGroup)"
                  placement="top"
                >
                  <span class="ellipsis">{{
                    scope.row.bmsAttendanceGroup.name
                  }}</span>
                </el-tooltip>
                <span v-else>- -</span>
              </span>
              <span v-else>- -</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="companySelf"
            label="应出勤天数"
            min-width="180"
            v-if="judgingHeader(5)"
          >
            <template slot-scope="scope">
              <div v-if="attendanceDay(scope.row.bmsAttendanceRecordList) != 0">
                {{ attendanceDay(scope.row.bmsAttendanceRecordList) }}
              </div>
              <div v-else>- -</div>
            </template>
          </el-table-column>
          <el-table-column
            label="实际出勤天数"
            min-width="180"
            v-if="judgingHeader(6)"
          >
            <template slot-scope="scope">
              <div style="color: #0058ff">
                {{ calculateDay(scope.row.bmsAttendanceRecordList, 1) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="出勤率"
            min-width="180"
            v-if="judgingHeader(7)"
          >
            <template slot-scope="scope">
              <div>
                {{ attendanceCalculation(scope.row.bmsAttendanceRecordList) }}
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column
            label="标准工作时长"
            width="180"
            v-if="judgingHeader(8)"
          >
            <template slot-scope="scope">
              <div>
                {{ attendanceCalculation(scope.row.attendanceRecordList) }}
              </div>
            </template>
          </el-table-column> -->
          <!-- <el-table-column
            label="实际工作时长"
            width="180"
            v-if="judgingHeader(9)"
          >
            <template slot-scope="scope">
              <div>
                {{ attendanceCalculation(scope.row.attendanceRecordList) }}
              </div>
            </template>
          </el-table-column> -->
          <el-table-column
            label="异常统计"
            v-if="judgingHeader2([11, 12, 13, 14, 15])"
          >
            <el-table-column
              prop="companySelf"
              label="迟到次数"
              min-width="150"
              v-if="judgingHeader(11)"
            >
              <template slot-scope="scope">
                <div
                  v-if="calculateDay(scope.row.bmsAttendanceRecordList, 3) != 0"
                  class="warning"
                >
                  {{ calculateDay(scope.row.bmsAttendanceRecordList, 3) }}
                </div>
                <div v-else>- -</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="companySelf"
              label="迟到时长(分钟)"
              min-width="150"
              v-if="judgingHeader(12)"
            >
              <template slot-scope="scope">
                <div
                  v-if="
                    minuteConversion(scope.row.bmsAttendanceRecordList, 1) != 0
                  "
                  class="warning"
                >
                  {{ minuteConversion(scope.row.bmsAttendanceRecordList, 1) }}
                </div>
                <div v-else>- -</div>
              </template>
            </el-table-column>
            <el-table-column
              label="早退次数"
              min-width="150"
              v-if="judgingHeader(13)"
            >
              <template slot-scope="scope">
                <div
                  v-if="calculateDay(scope.row.bmsAttendanceRecordList, 4) != 0"
                  class="warning"
                >
                  {{ calculateDay(scope.row.bmsAttendanceRecordList, 4) }}
                </div>
                <div v-else>- -</div>
              </template>
            </el-table-column>
            <el-table-column
              label="早退时长(分钟)"
              min-width="150"
              v-if="judgingHeader(14)"
            >
              <template slot-scope="scope">
                <div
                  v-if="
                    minuteConversion(scope.row.bmsAttendanceRecordList, 2) != 0
                  "
                  class="warning"
                >
                  {{ minuteConversion(scope.row.bmsAttendanceRecordList, 2) }}
                </div>
                <div v-else>- -</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="companySelf"
              label="旷工天数"
              min-width="150"
              v-if="judgingHeader(15)"
            >
              <template slot-scope="scope">
                <div
                  v-if="calculateDay(scope.row.bmsAttendanceRecordList, 2) != 0"
                  class="warning"
                >
                  {{ calculateDay(scope.row.bmsAttendanceRecordList, 2) }}
                </div>
                <div v-else>- -</div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            label="假勤情况"
            v-if="
              judgingHeader2([16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27])
            "
          >
            <el-table-column
              label="补卡次数"
              min-width="120"
              v-if="judgingHeader(17)"
            >
              <template slot-scope="scope">
                <div>
                  {{ fakeAttendance(scope.row.bmsAttendanceRecordList, 1) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="外出"
              min-width="120"
              v-if="judgingHeader(19)"
            >
              <template slot-scope="scope">
                <div
                  v-if="
                    fakeAttendance(scope.row.bmsAttendanceRecordList, 2) != 0
                  "
                >
                  {{ fakeAttendance(scope.row.bmsAttendanceRecordList, 2) }}小时
                </div>
                <div v-else>- -</div>
              </template>
            </el-table-column>
            <el-table-column
              label="出差"
              min-width="120"
              v-if="judgingHeader(20)"
            >
              <template slot-scope="scope">
                <div
                  v-if="
                    fakeAttendance(scope.row.bmsAttendanceRecordList, 3) != 0
                  "
                >
                  {{ fakeAttendance(scope.row.bmsAttendanceRecordList, 3) }}天
                </div>
                <div v-else>- -</div>
              </template>
            </el-table-column>
            <el-table-column
              label="年假"
              min-width="120"
              v-if="judgingHeader(21)"
            >
              <template slot-scope="scope">
                <div
                  v-if="
                    fakeAttendance(scope.row.bmsAttendanceRecordList, 4) != 0
                  "
                >
                  {{ fakeAttendance(scope.row.bmsAttendanceRecordList, 4) }}天
                </div>
                <div v-else>- -</div>
              </template>
            </el-table-column>
            <el-table-column
              label="事假"
              min-width="120"
              v-if="judgingHeader(22)"
            >
              <template slot-scope="scope">
                <div
                  v-if="
                    fakeAttendance(scope.row.bmsAttendanceRecordList, 5) != 0
                  "
                >
                  {{ fakeAttendance(scope.row.bmsAttendanceRecordList, 5) }}天
                </div>
                <div v-else>- -</div>
              </template>
            </el-table-column>
            <el-table-column
              label="病假"
              min-width="120"
              v-if="judgingHeader(23)"
            >
              <template slot-scope="scope">
                <div
                  v-if="
                    fakeAttendance(scope.row.bmsAttendanceRecordList, 6) != 0
                  "
                >
                  {{ fakeAttendance(scope.row.bmsAttendanceRecordList, 6) }}天
                </div>
                <div v-else>- -</div>
              </template>
            </el-table-column>
            <el-table-column
              label="调休假"
              min-width="120"
              v-if="judgingHeader(24)"
            >
              <template slot-scope="scope">
                <div
                  v-if="
                    fakeAttendance(scope.row.bmsAttendanceRecordList, 7) != 0
                  "
                >
                  {{ fakeAttendance(scope.row.bmsAttendanceRecordList, 7) }}天
                </div>
                <div v-else>- -</div>
              </template>
            </el-table-column>
            <el-table-column
              label="婚假"
              min-width="120"
              v-if="judgingHeader(25)"
            >
              <template slot-scope="scope">
                <div
                  v-if="
                    fakeAttendance(scope.row.bmsAttendanceRecordList, 8) != 0
                  "
                >
                  {{ fakeAttendance(scope.row.bmsAttendanceRecordList, 8) }}天
                </div>
                <div v-else>- -</div>
              </template>
            </el-table-column>
            <el-table-column
              label="产休假"
              min-width="120"
              v-if="judgingHeader(26)"
            >
              <template slot-scope="scope">
                <div
                  v-if="
                    fakeAttendance(scope.row.bmsAttendanceRecordList, 9) != 0
                  "
                >
                  {{ fakeAttendance(scope.row.bmsAttendanceRecordList, 9) }}天
                </div>
                <div v-else>- -</div>
              </template>
            </el-table-column>
            <el-table-column
              label="其他"
              min-width="120"
              v-if="judgingHeader(27)"
            >
              <template slot-scope="scope">
                <div
                  v-if="
                    fakeAttendance(scope.row.bmsAttendanceRecordList, 10) != 0
                  "
                >
                  {{ fakeAttendance(scope.row.bmsAttendanceRecordList, 10) }}天
                </div>
                <div v-else>- -</div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            label="加班情况"
            v-if="judgingHeader2([28, 29, 30, 31, 32])"
          >
            <el-table-column
              label="加班时长(小时)"
              min-width="200"
              v-if="judgingHeader(29)"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.allwork != 0">
                  {{ handleOvertime(scope.row.allwork) }}
                </div>
                <div v-else>- -</div>
              </template>
            </el-table-column>
            <el-table-column
              label="工作日加班时长(小时)"
              min-width="200"
              v-if="judgingHeader(30)"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.bmsAttendanceRecordList">
                  {{ handleOvertimeType(scope.row.bmsAttendanceRecordList, 1) }}
                </div>
                <div v-else>- -</div>
              </template>
            </el-table-column>
            <el-table-column
              label="休息日加班时长(小时)"
              min-width="200"
              v-if="judgingHeader(31)"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.bmsAttendanceRecordList">
                  {{ handleOvertimeType(scope.row.bmsAttendanceRecordList, 2) }}
                </div>
                <div v-else>- -</div>
              </template>
            </el-table-column>
            <el-table-column
              label="节假日加班时长(小时)"
              min-width="200"
              v-if="judgingHeader(32)"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.bmsAttendanceRecordList">
                  {{ handleOvertimeType(scope.row.bmsAttendanceRecordList, 3) }}
                </div>
                <div v-else>- -</div>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
      </div>
      <div
        class="newpagination"
        v-if="pages.total > 0"
        style="padding-left: 20px; box-sizing: border-box"
      >
        <div class="newpagination_text">共 {{ pages.total }} 条数据</div>
        <el-pagination
          layout="prev, pager, next"
          :total="pages.total"
          :current-page="pages.pageNum"
          :page-size="10"
          @current-change="chagePage"
        >
        </el-pagination>
      </div>
    </div>
    <div class="filing">
      CopyRight@2023广州巨源信息技术有限公司-<a
        href="https://beian.miit.gov.cn/"
        >粤ICP备2023127373号-4</a
      >
    </div>

    <!-- 设置报表 -->
    <el-drawer
      :wrapperClosable="false"
      title=""
      :visible.sync="setReportDialog"
      :with-header="false"
      size="25%"
    >
      <div class="batch_t">
        <div class="branch_l">
          <img
            src="@/assets/structure/close.png"
            class="batch_close"
            @click="close"
          />
          <div>设置报表</div>
        </div>
        <div class="branch_bto">
          <el-button @click="close">取消</el-button>
          <el-button type="primary" @click="switcOperation">确认</el-button>
        </div>
      </div>
      <div class="batch_c">
        <el-tree
          :data="setReportList"
          ref="tree"
          show-checkbox
          node-key="id"
          :props="defaultProps2"
        >
        </el-tree>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import http from "../../../utils/http";
import exprotExcel from "../../../utils/exprotExcel";
export default {
  data() {
    return {
      tableloading: true,
      searchValue: "", //搜索的关键词
      selectTree: "", //部门多选
      selectDeptid: [], //选择的部门id
      selectTreeValue: [], //部门多选
      departmentList: [], //部门列表
      date: null, //搜索的日期
      totalday: [], //当前月份总天数
      copytotalday: [], //复制
      startTime: "", //开始日期
      pageNum: 1, // 页数
      searchForm: {}, //搜索集合
      endTime: "", //结束日期
      searchicon: false, //监听输入
      tableData: [], //表格数据
      tipstitle: "确认删除此访客?",
      selectUserid: [], //最终删除的数组
      selectedData: [],
      pages: {}, //分页
      multipleSelection: [],
      tableSelection: [], //导出表格需要
      tableKey: Math.random(), //表格key
      company: {}, //公司信息
      delectVistorDiglog: false, //删除访客提示框
      tableHeight: null,
      arrayKey: "attendanceRecordList",
      expireTimeOPtion: {
        disabledDate(time) {
          return time.getTime() > Date.now(); //如果没有后面的-8.64e7就是不可以选择今天的
        },
      },
      defaultProps: {
        children: "subDepartment",
        label: "name",
      },
      defaultProps2: {
        children: "children",
        label: "label",
      },
      setReportDialog: false, //设置报表
      tableColumnList: [], //生成的报表头
      setReportList: [
        {
          label: "姓名",
          id: 1,
          disabled: true,
        },
        {
          label: "员工工号",
          id: 2,
          disabled: true,
        },
        {
          label: "部门",
          id: 3,
          disabled: true,
        },
        {
          label: "考勤组名称",
          id: 4,
        },
        {
          label: "应出勤天数",
          id: 5,
        },
        {
          label: "实际出勤天数",
          id: 6,
        },
        {
          label: "出勤率",
          id: 7,
        },
        // {
        //   label: "标准工作时长",
        //   id: 8,
        // },
        // {
        //   label: "实际工作时长",
        //   id: 9,
        // },
        {
          label: "异常统计",
          id: 10,
          children: [
            {
              label: "迟到次数",
              id: 11,
            },
            {
              label: "迟到时长",
              id: 12,
            },
            {
              label: "早退次数",
              id: 13,
            },
            {
              label: "早退时长",
              id: 14,
            },
            {
              label: "旷工天数",
              id: 15,
            },
          ],
        },
        {
          label: "假勤情况",
          id: 16,
          children: [
            {
              label: "补卡次数",
              id: 17,
            },
            {
              label: "外出",
              id: 19,
            },
            {
              label: "出差",
              id: 20,
            },
            {
              label: "年假",
              id: 21,
            },
            {
              label: "事假",
              id: 22,
            },
            {
              label: "病假",
              id: 23,
            },
            {
              label: "调休假",
              id: 24,
            },
            {
              label: "婚假",
              id: 25,
            },
            {
              label: "产休假",
              id: 26,
            },
            {
              label: "其他",
              id: 27,
            },
          ],
        },
        {
          label: "加班情况",
          id: 28,
          children: [
            {
              label: "加班时长",
              id: 29,
            },
            {
              label: "工作日加班时长",
              id: 30,
            },
            {
              label: "休息日加班时长",
              id: 31,
            },
            {
              label: "节假日加班时长",
              id: 32,
            },
          ],
        },
      ], //报表总名称
      tableColumn: [
        {
          title: "姓名",
          dataIndex: "name",
          id: 1,
        },
        {
          title: "员工工号",
          dataIndex: "jobNumbernew",
          id: 2,
        },
        {
          title: "部门",
          dataIndex: "departmentName",
          id: 3,
        },
        {
          title: "考勤组名称",
          dataIndex: "attendanceGroupName",
          id: 4,
        },
        {
          title: "应出勤天数",
          dataIndex: "classestime",
          id: 5,
        },
        {
          title: "实际出勤天数",
          dataIndex: "start",
          id: 6,
        },
        {
          title: "出勤率",
          dataIndex: "end",
          id: 7,
        },
        // {
        //   title: "标准工作时长",
        //   dataIndex: "standard",
        //   id: 8,
        // },
        // {
        //   title: "实际工作时长",
        //   dataIndex: "actual",
        //   id: 9,
        // },
        {
          title: "异常统计",
          children: [
            {
              title: "迟到次数",
              dataIndex: "lateCount",
              id: 11,
            },
            {
              title: "迟到时长(分钟)",
              dataIndex: "latetime",
              id: 12,
            },
            {
              title: "早退次数",
              dataIndex: "earlyCount",
              id: 13,
            },
            {
              title: "早退时长(分钟)",
              dataIndex: "earlytime",
              id: 14,
            },
            {
              title: "旷工天数",
              dataIndex: "absence",
              id: 15,
            },
          ],
        },
        {
          title: "假勤情况",
          children: [
            {
              title: "补卡次数",
              dataIndex: "replacement",
              id: 17,
            },
            {
              title: "外出",
              dataIndex: "goout",
              id: 19,
            },
            {
              title: "出差",
              dataIndex: "business",
              id: 20,
            },

            {
              title: "年假",
              dataIndex: "value",
              id: 21,
            },
            {
              title: "事假",
              dataIndex: "value2",
              id: 22,
            },
            {
              title: "病假",
              dataIndex: "value3",
              id: 23,
            },
            {
              title: "调休假",
              dataIndex: "value4",
              id: 24,
            },
            {
              title: "婚假",
              dataIndex: "value5",
              id: 25,
            },
            {
              title: "产休假",
              dataIndex: "value6",
              id: 26,
            },
            {
              title: "其他",
              dataIndex: "value7",
              id: 27,
            },
          ],
        },
        {
          title: "加班情况",
          children: [
            {
              title: "加班时长(小时)",
              dataIndex: "overtime",
              id: 29,
            },
            {
              title: "工作日加班时长(小时)",
              dataIndex: "oovertime",
              id: 30,
            },
            {
              title: "休息日加班时长(小时)",
              dataIndex: "rovertime",
              id: 31,
            },
            {
              title: "节假日加班时长(小时)",
              dataIndex: "hovertime",
              id: 32,
            },
          ],
        },
      ], //导出内容参数
      tableColumnFinal: [], //最终导出内容参数
    };
  },
  created() {
    // 获取当前日期并搜索
    this.getDate();

    // 获取部门列表
    this.getDepartment();

    this.getDay();

    var arr = localStorage.getItem("personalsummaryreport");
    if (arr) {
      this.tableColumnList = JSON.parse(arr);
    } else {
      for (let index = 1; index < 33; index++) {
        this.tableColumnList.push(index);
      }
    }
  },
  watch: {
    searchValue(newName, oldName) {
      if (newName) {
        this.searchicon = true;
      } else {
        this.searchicon = false;
      }
    },
  },
  methods: {
    // 表头背景颜色变色
    getRowClass({ rowIndex, columnIndex }) {
      if (rowIndex == 0) {
        return "background:#F5F6FA;color:#5C5C5C";
      }
    },

    // 获取访客列表
    getVisitor() {
      http
        .get(
          "/bmsAttendanceRecord/search/user",
          Object.assign(this.searchForm, {
            pageSize: 10,
            pageNum: this.pageNum,
          })
        )
        .then((res) => {
          if (res.code == 200) {
            this.pages = res.data;
            res.data.list.map((item) => {
              item.allwork = 0;
              item.bmsAttendanceRecordList.map((citem) => {
                item.allwork += citem.secOvertime;
              });
            });

            this.tableData = res.data.list;
            this.tableloading = false;
          }
        });
    },

    // 获取部门列表
    getDepartment() {
      http.get("/umsDepartment/list").then((res) => {
        if (res.code == 200) {
          if (res.data.length > 0) {
            this.departmentList = this.convertToNestedStructure(res.data);
          }
        }
      });
    },
    // 递归处理数据
    convertToNestedStructure(arr) {
      // 创建一个映射，用于快速查找具有特定id的对象
      const idMap = new Map(arr.map((item) => [item.id, item]));

      // 创建一个新数组来存储转换后的嵌套结构
      const nestedArr = [];

      // 遍历原始数组
      arr.forEach((item) => {
        // 如果该项有父元素，则将其添加到父元素的subDepartment数组中
        if (item.parent) {
          const parent = idMap.get(item.parent);
          if (parent) {
            if (!parent.subDepartment) {
              parent.subDepartment = [];
            }
            item.parentDepament = parent.name;
            parent.subDepartment.push(item);
          }
        } else {
          // 如果没有父元素，则添加到新数组的根级别
          nestedArr.push(item);
        }
      });

      // 返回转换后的嵌套数组
      return nestedArr;
    },

    // 设置报表
    setReport() {
      this.setReportDialog = true;
      setTimeout(() => {
        this.$refs.tree.setCheckedKeys(this.tableColumnList);
      }, 200);
    },

    // 关闭报表
    close() {
      this.setReportDialog = false;
    },

    // 确认修改报表
    switcOperation() {
      const list = this.$refs.tree.getCheckedKeys(true);
      this.tableColumnList = list;
      list.unshift(1, 2, 3);
      localStorage.setItem("personalsummaryreport", JSON.stringify(list));
      this.setReportDialog = false;

      this.tableKey = Math.random();
    },
    // 判断是否空数组
    isEmptyObject(obj) {
      return Object.keys(obj).length === 0;
    },
    // 判断是否含有所属的表头
    judgingHeader(index) {
      if (this.tableColumnList.includes(index)) {
        return true;
      } else {
        return false;
      }
    },
    // 判断是否含有所属的表头(一级)
    judgingHeader2(value) {
      var arr1 = this.tableColumnList;
      // 异常统计
      var istrue = this.hasIntersection(arr1, value);
      if (istrue) {
        return true;
      } else {
        return false;
      }
    },
    // 判断两个数组是否有交集
    hasIntersection(arr1, arr2) {
      for (let i = 0; i < arr1.length; i++) {
        for (let j = 0; j < arr2.length; j++) {
          if (arr1[i] === arr2[j]) {
            return true;
          }
        }
      }
      return false;
    },

    // 表格多选
    handleSelectionChange(val) {
      this.multipleSelection = [];
      this.tableSelection = val;
      val.map((item) => {
        this.multipleSelection.push(item.id);
      });
    },

    // 表格下载之前
    async startDownload() {
      let that = this;
      var arr = JSON.stringify(that.tableData);
      arr = JSON.parse(arr);
      let target = event.target;
      if (target.nodeName == "I" || target.nodeName == "SPAN") {
        target = event.target.parentNode;
      }
      target.blur();
      // 判断一个对象是否应该保留
      let shouldKeep = (obj) => {
        if (obj.id !== undefined) {
          return that.tableColumnList.includes(obj.id);
        } else if (obj.children !== undefined) {
          obj.children = obj.children.filter(shouldKeep);
          return obj.children.length > 0;
        }
      };
      var arr2 = that.tableColumn.filter(shouldKeep);
      var finalArr = [];
      const loading = that.$loading({
        lock: true,
        text: "导出表格中，请稍后...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      var total = that.pages.total;

      if (that.tableSelection.length > 0) {
        finalArr = that.tableSelection;
      } else {
        if (that.searchForm.beginDay && that.searchForm.endDay) {
          var date1 = new Date(that.searchForm.beginDay);
          var date2 = new Date(that.searchForm.endDay);
          const oneDay = 24 * 60 * 60 * 1000; // 一天的毫秒数
          const diff = Math.abs(date2.getTime() - date1.getTime()); // 计算差值的绝对值
          var isTrue = diff > 30 * oneDay;
          if (isTrue || total >= 40000) {
            loading.close();
            that.$message.error("导出时间相隔不能超过一个月,且不能高于4万条");
            return;
          }
        }
        try {
          var arr = await http.get(
            "/bmsAttendanceRecord/search/user",
            Object.assign(that.searchForm, {
              pageSize: total,
              pageNum: 1,
            })
          );
          if (arr.code == 200) {
            finalArr = arr.data.list;
          } else {
            loading.close();
          }
        } catch (e) {
          loading.close();
        }
      }

      if (finalArr.length > 0) {
        loading.close();
        finalArr.map((v) => {
          v.allwork = 0;
          v.bmsAttendanceRecordList.map((citem) => {
            v.allwork += citem.secOvertime;
          });
          if (v.jobNumber) {
            v.jobNumbernew = "\xa0" + v.jobNumber;
          }
          if (v.umsDepartment) {
            v.departmentName = v.umsDepartment.name;
          }
          if (!that.isEmptyObject(v.bmsAttendanceGroup)) {
            v.attendanceGroupName = v.bmsAttendanceGroup.name;
          }
          if (that.attendanceDay(v.bmsAttendanceRecordList) != 0) {
            v.classestime = that.attendanceDay(v.bmsAttendanceRecordList);
          }
          v.start = that.calculateDay(v.bmsAttendanceRecordList, 1);
          v.end = that.attendanceCalculation(v.bmsAttendanceRecordList);
          v.lateCount = that.calculateDay(v.bmsAttendanceRecordList, 3);
          v.latetime = that.minuteConversion(v.bmsAttendanceRecordList, 1);
          v.earlyCount = that.calculateDay(v.bmsAttendanceRecordList, 4);
          v.earlytime = that.minuteConversion(v.bmsAttendanceRecordList, 2);
          v.absence = that.calculateDay(v.bmsAttendanceRecordList, 2);
          v.replacement = that.fakeAttendance(v.bmsAttendanceRecordList, 1);
          if (that.fakeAttendance(v.bmsAttendanceRecordList, 2) != 0) {
            v.goout =
              that.fakeAttendance(v.bmsAttendanceRecordList, 2) + "小时";
          } else {
            v.goout = "- -";
          }
          if (that.fakeAttendance(v.bmsAttendanceRecordList, 3) != 0) {
            v.business =
              that.fakeAttendance(v.bmsAttendanceRecordList, 3) + "天";
          } else {
            v.business = "- -";
          }
          if (that.fakeAttendance(v.bmsAttendanceRecordList, 4) != 0) {
            v.value = that.fakeAttendance(v.bmsAttendanceRecordList, 4) + "天";
          } else {
            v.value = "- -";
          }
          if (that.fakeAttendance(v.bmsAttendanceRecordList, 5) != 0) {
            v.value2 = that.fakeAttendance(v.bmsAttendanceRecordList, 5) + "天";
          } else {
            v.value2 = "- -";
          }
          if (that.fakeAttendance(v.bmsAttendanceRecordList, 6) != 0) {
            v.value3 = that.fakeAttendance(v.bmsAttendanceRecordList, 6) + "天";
          } else {
            v.value3 = "- -";
          }
          if (that.fakeAttendance(v.bmsAttendanceRecordList, 7) != 0) {
            v.value4 = that.fakeAttendance(v.bmsAttendanceRecordList, 7) + "天";
          } else {
            v.value4 = "- -";
          }
          if (that.fakeAttendance(v.bmsAttendanceRecordList, 8) != 0) {
            v.value5 = that.fakeAttendance(v.bmsAttendanceRecordList, 8) + "天";
          } else {
            v.value5 = "- -";
          }
          if (that.fakeAttendance(v.bmsAttendanceRecordList, 9) != 0) {
            v.value6 = that.fakeAttendance(v.bmsAttendanceRecordList, 9) + "天";
          } else {
            v.value6 = "- -";
          }
          if (that.fakeAttendance(v.bmsAttendanceRecordList, 10) != 0) {
            v.value7 =
              that.fakeAttendance(v.bmsAttendanceRecordList, 10) + "天";
          } else {
            v.value7 = "- -";
          }

          v.overtime = that.handleOvertime(v.allwork);
          v.oovertime = that.handleOvertimeType(v.bmsAttendanceRecordList, 1);
          v.rovertime = that.handleOvertimeType(v.bmsAttendanceRecordList, 2);
          v.hovertime = that.handleOvertimeType(v.bmsAttendanceRecordList, 3);
          for (let i = 0; i < arr2.length; i++) {
            if (v[arr2[i]] == undefined) {
              v[arr2[i]] = "";
            }
          }
          for (let key in v) {
            if (v[key] == null) v[key] = "";
          }
        });

        exprotExcel.export(arr2, finalArr, "个人考勤汇总");
        that.$message.success("导出成功");
      } else {
        loading.close();
        that.$message.error("导出失败");
      }
    },
    // 页数改变
    chagePage(e) {
      this.searchSetof(e);
    },

    // 清除输入框
    clearup() {
      this.searchValue = "";
    },
    // 多选,删除任一select选项的回调
    selectChange(e) {
      var arrNew = [];
      var dataLength = this.selectedData.length;
      var eleng = e.length;
      for (let i = 0; i < dataLength; i++) {
        for (let j = 0; j < eleng; j++) {
          if (e[j] === this.selectedData[i].departmentName) {
            arrNew.push(this.selectedData[i]);
          }
        }
      }
      this.$refs.tree.setCheckedNodes(arrNew); //设置勾选的值

      if (e.length > 0) {
        this.selectDeptid = [];
        var arr = [];
        e.map((item) => {
          var index = this.selectedData.findIndex(
            (citem) => citem.departmentName == item
          );

          if (index > -1) {
            this.selectDeptid.push(this.selectedData[index].departmentId);
            arr.push(this.selectedData[index]);
          }
        });

        this.selectedData = arr;
      } else {
        this.selectedData = [];
        this.selectDeptid = [];
      }
    },
    // 多选框
    handleCheckChange(data, checked, indeterminate) {
      var arr = checked.checkedKeys;
      this.selectTree = [];

      var index = this.selectedData.findIndex((item) => item.id == data.id);
      console.log(data);
      if (index > -1) {
        this.selectedData.splice(index, 1);
      } else {
        this.selectedData.push(data);
      }

      this.selectedData.map((item) => {
        this.selectTree.push(item.name);
      });

      this.selectDeptid = arr;
      this.selectTreeValue = arr;
    },
    // 设置默认选中的tree回显
    setCheckedKeys() {
      this.$refs.tree.setCheckedKeys([7, 81, 82]);
    },

    // 删除访客弹窗
    deleteInvitefirst(ids) {
      let target = event.target;
      if (target.nodeName == "I" || target.nodeName == "SPAN") {
        target = event.target.parentNode;
      }
      target.blur();
      var arr = [];
      if (Array.isArray(ids)) {
        if (ids.length == 0) {
          this.$message.error("请先选择访客");
          return;
        }
        this.tipstitle = "确认删除所选的访客？";
        arr = ids;
      } else {
        this.tipstitle = "确认删除此访客？";
        arr = [ids];
      }
      setTimeout(() => {
        this.delectVistorDiglog = true;
      }, 200);
      this.selectUserid = arr;
    },

    // 转换成星期几
    weekDay(time) {
      var arr = time.split(" ");
      const weekNum = new Date(arr[0]).getDay();
      let week = "";
      switch (weekNum) {
        case 0:
          week = "星期天";
          break;
        case 1:
          week = "星期一";
          break;
        case 2:
          week = "星期二";
          break;
        case 3:
          week = "星期三";
          break;
        case 4:
          week = "星期四";
          break;
        case 5:
          week = "星期五";
          break;
        case 6:
          week = "星期六";
          break;
      }
      return week;
    },

    // 选择所选日期
    changeDate(e) {
      console.log(e);
      if (e) {
        var start = new Date(e[0]);
        var end = new Date(e[1]);

        var syear = start.getFullYear();
        var smonth =
          start.getMonth() + 1 <= 9
            ? "0" + (start.getMonth() + 1)
            : start.getMonth() + 1;
        var sday =
          start.getDate() <= 9 ? "0" + start.getDate() : start.getDate();

        var eyear = end.getFullYear();
        var emonth =
          end.getMonth() + 1 <= 9
            ? "0" + (end.getMonth() + 1)
            : end.getMonth() + 1;
        var eday = end.getDate() <= 9 ? "0" + end.getDate() : end.getDate();

        this.startTime = syear + "-" + smonth + "-" + sday;
        this.endTime = eyear + "-" + emonth + "-" + eday;

        this.totalday = this.getAllDatesInRange(this.startTime, this.endTime);

        var arr = [];
        this.totalday.map((item) => {
          arr.push({
            title: item,
            key: item,
            type: "text",
          });
        });

        this.tableColumnFinal = this.tableColumn.concat(arr);

        this.$nextTick(() => {
          this.$refs["multipleTable"].doLayout();
        });
      } else {
        this.$nextTick(() => {
          setTimeout(() => {
            this.getDate();
          }, 500);
        });
      }
    },

    // 取消删除
    closeDelete() {
      this.delectVistorDiglog = false;
    },

    // 搜索集合
    searchSetof(page) {
      if (page) {
        this.pageNum = page;
      } else {
        this.pageNum = 1;
      }
      var object = {};

      if (this.searchValue != "") {
        object.likeString = this.searchValue;
      }

      if (this.startTime && this.endTime) {
        object.beginDay = this.startTime;
        object.endDay = this.endTime;
      }

      if (this.selectDeptid.length > 0) {
        object.departmentIdList = this.selectDeptid.join(",");
      }
      this.searchForm = object;

      this.getVisitor();
    },

    // 重置
    reset() {
      this.searchValue = "";
      this.selectTreeValue = [];
      this.selectTree = [];
      this.selectDeptid = [];
      this.getDate();
    },

    // 计算应出勤天数
    attendanceDay(arr) {
      if (arr.length > 0) {
        var newarr = [];
        arr.map((item) => {
          if (item.arrange > 0 || item.arrange == -4) {
            newarr.push(item);
          }
        });

        return newarr.length;
      } else {
        return "0";
      }
    },

    // 计算天数
    calculateDay(arr, type) {
      // 1是实际出勤,2是旷工,3是迟到,4是早退
      var absenteeism = 0;
      var attendance = 0;
      var belate = 0;
      var leave = 0;
      if (arr.length > 0) {
        arr.map((item) => {
          if (item.lateCount) {
            belate += item.lateCount;
          }
          if (item.earlyCount) {
            leave += item.earlyCount;
          }
          if (item.dayAbsence) {
            attendance += item.dayAbsence;
          }
          if (item.action) {
            absenteeism += 1;
          }
        });

        switch (type) {
          case 1:
            return absenteeism;
          case 2:
            return attendance;
          case 3:
            return belate;
          case 4:
            return leave;
        }
      } else {
        return "- -";
      }
    },

    // 判断假勤情况
    fakeAttendance(arr, type) {
      // 1是补卡次数,2是外出,3是出差,4是年假,5是事假,6病假,7调休假,8婚假,9产休假,10其它
      var num = 0;
      if (arr) {
        arr.map((item) => {
          switch (type) {
            case 1:
              num += item.addCount;
              break;
            case 2:
              if (item.secOut) {
                num += item.secOut / 3600;
              }
              break;
            case 3:
              num += item.dayBusiness;
              break;
            case 4:
              num += item.dayRestYear;
              break;
            case 5:
              num += item.dayRestMatter;
              break;
            case 6:
              num += item.dayRestSick;
              break;
            case 7:
              num += item.dayRestExchange;
              break;
            case 8:
              num += item.dayRestMarry;
              break;
            case 9:
              num += item.dayRestMaternity;
              break;
            case 10:
              num += item.dayRestOther;
              break;
          }
        });
        if (type == 2) {
          return num.toFixed(1);
        } else {
          return num;
        }
      }
    },

    //  出勤率计算
    attendanceCalculation(arr) {
      if (arr.length > 0) {
        var absenteeism = 0;
        var newarr = [];
        arr.map((item) => {
          if (item.arrange > 0 || item.arrange == -4) {
            newarr.push(item);
          }
        });

        newarr.map((citem) => {
          if (citem.action) {
            absenteeism += 1;
          }
        });

        if (newarr.length == 0) {
          return "- -";
        } else {
          return (
            Math.round((absenteeism / newarr.length) * 10000) / 100.0 + "%"
          );
        }
      } else {
        return "- -";
      }
    },

    // 秒换成分钟
    minuteConversion(arr, type) {
      // 1是迟到，2是早退
      if (arr.length > 0) {
        var time_l = 0;
        var time_e = 0;
        arr.map((item) => {
          if (type == 1) {
            time_l += item.secLate;
          }

          if (type == 2) {
            time_e += item.secEarly;
          }
        });

        switch (type) {
          case 1:
            return Math.floor(time_l / 60);
          case 2:
            return Math.ceil(time_e / 60);
        }
      } else {
        return "- -";
      }
    },
    getDaysInMonth(year, month) {
      // 注意：月份在 JavaScript 中是从 0 到 11，所以实际月份要加 1
      return new Date(year, month + 1, 0).getDate();
    },
    // 获取当前月份的天数
    getDay() {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month =
        currentDate.getMonth() + 1 < 10
          ? "0" + (currentDate.getMonth() + 1)
          : currentDate.getMonth() + 1;

      const day = currentDate.getDate();
      const lastmonth = this.getDaysInMonth(year, month - 1);

      if (day == 1) {
        for (let index = 0; index < lastmonth - 1; index++) {
          var str = year + "-" + month - 1 + "-";
          if (index + 1 < 10) {
            str = str + "0" + (index + 1);
          } else {
            str = str + (index + 1);
          }
          this.totalday.push(str);
          this.copytotalday.push(str);
        }
      } else {
        for (let index = 0; index < day - 1; index++) {
          var str = year + "-" + month + "-";
          if (index + 1 < 10) {
            str = str + "0" + (index + 1);
          } else {
            str = str + (index + 1);
          }
          this.totalday.push(str);
          this.copytotalday.push(str);
        }
      }

      var arr = [];
      this.totalday.map((item) => {
        arr.push({
          title: item,
          key: item,
          type: "text",
        });
      });

      this.tableColumnFinal = this.tableColumn.concat(arr);
    },

    // 循环寻找
    circularComparison(arr, value) {
      var index = arr.findIndex((item) => item.dayString == value);
      if (index > -1) {
        if (arr[index].arrange == -1) {
          return -1;
        } else {
          return index;
        }
      } else {
        return -1;
      }
    },

    // 循环寻找(具体情况)
    loopsituation(arr, value) {
      var arr2 = [];
      var index = arr.findIndex((item) => item.dayString == value);
      var string = "lateCount";
      var string2 = "earlyCount";
      var string3 = "absence";
      if (arr[index][string] && arr[index][string] > 0) {
        arr2.push("迟");
      }

      if (arr[index][string2] && arr[index][string2] > 0) {
        arr2.push("早");
      }

      if (arr[index][string3] && arr[index][string3] > 0) {
        arr2.push("旷");
      }

      return arr2;
    },

    // 对应的打卡时间
    correspondingTime(arr, value) {
      var index = arr.findIndex((item) => item.dayString == value);
      if (arr[index].timeString) {
        var str = arr[index].timeString.replace(/-/g, " ~ ");
        return " " + str;
      } else {
        return "-- ~ --";
      }
    },

    // 判断样式
    setStyle(arr) {
      var object = {};
      if (arr) {
        if (arr.indexOf("早") > -1 || arr.indexOf("迟") > -1) {
          object = {
            color: "#F26005",
          };
        }
        if (arr.indexOf("旷") > -1) {
          object = {
            color: "#F0142F",
          };
        }

        if (arr.length > 1) {
          object = {
            color: "#F0142F",
          };
        }
      }

      return object;
    },

    // 循环寻找(正常数据)
    loopsituation2(arr, value) {
      var index = arr.findIndex((item) => item.dayString == value);
      if (
        arr[index].absence == 0 &&
        arr[index].earlyCount == 0 &&
        arr[index].lateCount == 0
      ) {
        return true;
      } else {
        return false;
      }
    },

    // 两个日期之间的天数
    formatNumberWithZero(number) {
      return number < 10 ? "0" + number : String(number);
    },

    getAllDatesInRange(startDate, endDate) {
      const datesInRange = [];
      const currentDate = new Date(startDate);
      const lastDate = new Date(endDate);

      while (currentDate <= lastDate) {
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1;
        const day = currentDate.getDate();

        const formattedMonth = this.formatNumberWithZero(month);
        const formattedDay = this.formatNumberWithZero(day);
        datesInRange.push(`${year}-${formattedMonth}-${formattedDay}`);

        currentDate.setDate(currentDate.getDate() + 1);
      }

      return datesInRange;
    },

    // 获取当前月份
    getDate() {
      var date = new Date();
      var year = date.getFullYear(); //获取完整的年份(4位)
      var month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      var newmonth =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      var day = date.getDate();

      var lastDate = new Date(year, month, 0).getDate() + 1; //获取当月最后一日
      if (day == "01") {
        this.startTime = year + "-" + month + "-01";
      } else {
        this.startTime = year + "-" + newmonth + "-01";
      }

      if (day <= lastDate) {
        var newday = "";
        if (day < 10) {
          newday = "0" + day;
        } else {
          newday = day;
        }
        this.endTime = year + "-" + newmonth + "-" + newday;
      }
      this.date = [this.startTime, this.endTime];

      this.searchSetof();
    },

    itemError(item) {
      item.headUrl = require("@/assets/head.png");
    },

    // 处理加班时长
    handleOvertime(val) {
      if (val) {
        return (val / 3600).toFixed(1);
      }
    },

    // 处理加班类型时长
    handleOvertimeType(arr, type) {
      if (arr) {
        var num = 0;
        arr.map((item) => {
          if (type == 1) {
            if (item.arrange > 0 || item.arrange == -4) {
              num += item.secOvertime / 3600;
            }
          }

          if (type == 2) {
            if (item.arrange == -1 || item.arrange == -2) {
              num += item.secOvertime / 3600;
            }
          }

          if (type == 3) {
            if (item.arrange == -3) {
              num += item.secOvertime / 3600;
            }
          }
        });
      }

      if (num == 0) {
        return "- -";
      } else {
        return num.toFixed(1);
      }
    },
  },
};
</script>

<style scoped>
.visitor_information {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  box-sizing: border-box;
  background: #f4f7fc;
}
.visitor_information_c {
  width: 100%;
  box-shadow: 0px 1px 4px 1px rgba(21, 34, 50, 0.08);
  flex: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 6px;
  background: white;
}
.visitor_information_t {
  height: 76px;
  padding: 0 30px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(207, 212, 232, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.visitor_information_search {
  width: 243px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #d0d0d0;
  display: flex;
  align-items: center;
  padding-right: 12px;
  box-sizing: border-box;
  justify-content: space-between;
}
.department_search_inp {
  width: 200px;
}
.visitor_information_table {
  width: 100%;
  flex: 1;
  padding: 20px;
  position: relative;
  box-sizing: border-box;
}
.visitor_information_title {
  font-size: 14px;
  color: #b2b2b2;
  margin-bottom: 16px;
}
.visitor_information_title span {
  color: #5c5c5c;
}
.memeber_operation {
  margin-left: 8px;
  width: 107px;
}
.detailed {
  width: 86px;
  height: 26px;
  background: #e0ecff;
  color: #0058ff;
  font-size: 14px;
  text-align: center;
  line-height: 26px;
  cursor: pointer;
}
.qrcode {
  width: 100%;
}
.qrcode_frame {
  width: 457px;
  height: 358px;
  display: flex;
  color: #1a1a1a;
  margin: 60px auto 24px;
  flex-direction: column;
  align-items: center;
  padding-top: 46px;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.16);
}
.qrcode_name {
  font-size: 16px;
  margin-bottom: 30px;
}
.qrcode_img {
  width: 200px;
  height: 200px;
  background: black;
}
.qrcode_tips {
  color: #9a9a9a;
  font-size: 15px;
  text-align: center;
}
.qrcode_bto {
  display: flex;
  margin-top: 39px;
  justify-content: center;
}
.link_frame {
  width: 797px;
  height: 132px;
  margin: 30px;
  font-size: 16px;
  padding: 24px;
  color: #1a1a1a;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.16);
}
.link_tips {
  font-size: 15px;
  color: #9a9a9a;
  padding-left: 30px;
  box-sizing: border-box;
}
.link_bto {
  display: flex;
  margin-top: 289px;
  justify-content: center;
}
.invite_h {
  height: 73px;
  padding: 0 24px;
  color: #1a1a1a;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 18px;
  justify-content: space-between;
}
.invite_icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.visitor_name {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #1a1a1a;
}
.visitor_head {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 10px;
}
.primary-button-style {
  background: #2979ff;
  border-color: #2979ff;
}
::v-deep .el-table__body .el-table__row.hover-row td {
  background-color: rgba(0, 98, 255, 0.05);
}
.newpagination {
  height: 50px;
  margin-top: 34px;
}
.visitor_information_search:focus-within {
  border-color: #0096fa;
}
.v_table {
  width: 97%;
  height: 100%;
  position: absolute;
}
.visitor_information_t_left {
  display: flex;
  align-items: center;
}
.department_search_date {
  height: 36px;
  margin-right: 10px;
}
::v-deep .el-select {
  margin-right: 10px;
  height: 36px;
}
::v-deep .el-input__inner {
  height: 36px;
}
::v-deep .select-tree .el-select__tags {
  height: 36px;
  overflow: hidden;
  white-space: nowrap;
  flex-wrap: nowrap;
}
::v-deep .select-tree .el-select__tags-text {
  display: inline-block;
  max-width: 85px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
::v-deep .el-select-dropdown.is-multiple .el-select-dropdown__item {
  padding: 0;
}
.timestatus {
  width: 68px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 16px;
}
.showdate {
  display: flex;
  align-items: center;
}
.showdate_single {
  width: 40px;
  height: 30px;
  margin: 0 5px;
  line-height: 30px;
  text-align: center;
  font-size: 16px;
}
::v-deep .el-table__fixed {
  height: 98% !important;
}
::v-deep .el-tree-node__content {
  height: 51px;
  font-size: 15px;
  color: #5c5c5c;
}
::v-deep .el-date-editor .el-range-separator {
  line-height: 28px;
}
::v-deep .el-input--suffix .el-input__inner {
  height: 36px !important;
}
.ellipsis {
  width: 200px;
  overflow: hidden; /* 超出部分隐藏 */
  white-space: nowrap; /* 禁止文本换行 */
  text-overflow: ellipsis;
  display: block;
}
.ellipsis2 {
  width: 120px;
  overflow: hidden; /* 超出部分隐藏 */
  white-space: nowrap; /* 禁止文本换行 */
  text-overflow: ellipsis;
}
.ellipsis3 {
  width: 120px;
  overflow: hidden; /* 超出部分隐藏 */
  white-space: nowrap; /* 禁止文本换行 */
  text-overflow: ellipsis;
}
::v-deep .visitor_information_bto {
  height: 35px;
  line-height: 0.8;
  margin-left: 20px;
  background: #0058ff;
  border-color: #0058ff;
}
::v-deep .visitor_information_bto2 {
  height: 35px;
  line-height: 0.8;
  margin-left: 20px;
  background: #e8f4fe;
}
.branch_l {
  display: flex;
  align-items: center;
}
.batch_t {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
  box-sizing: border-box;
  padding-left: 20px;
  font-size: 18px;
  color: #1a1a1a;
  border-bottom: 1px solid #cfd4e8;
}
.batch_close {
  width: 24px;
  cursor: pointer;
  height: 24px;
  margin-right: 20px;
}
.batch_c {
  width: 100%;
  padding-top: 28px;
  box-sizing: border-box;
}
::v-deep .el-tree-node__label {
  line-height: auto;
}
</style>