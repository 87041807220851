<template>
  <div class="cycle_wrapper">
    <el-drawer
      size="42%"
      :visible.sync="isShowdiglog"
      custom-class="drawer_cycle"
      :direction="direction"
      :showClose="false"
      :wrapperClosable="false"
      :key="cKey"
    >
      <span slot="title" class="dialog_title">
        <i class="el-icon-close dialog_close" @click="closeDialog"></i>
        <span>排班周期</span>

        <div class="dialog_confirm">
          <el-button
            class="dialog_confirm_c"
            style="background: none"
            @click="closeDialog()"
            >取 消</el-button
          >
          <el-button
            type="primary"
            @click="confirmDialog"
            class="dialog_confirm_c"
            >确 认</el-button
          >
        </div>
      </span>
      <button class="cycle_search" @click="addCycle">新建周期</button>
      <div class="group_table_wrapper">
        <el-table
          class="dialog_table"
          ref="itemTable2"
          :data="classData"
          @selection-change="handleSelectionChange"
          :header-cell-style="tableCellSytle"
          height="37.25rem"
        >
          <el-table-column
            type="selection"
            header-align="left"
            align="left"
          ></el-table-column>
          <el-table-column
            prop="name"
            label="周期名称"
            width="100"
          ></el-table-column>
          <el-table-column label="周期班次" width="320">
            <template slot-scope="scope">
              <div class="cycle_class" ref="tagContainer">
                <span
                  ref="tags"
                  v-for="(item, index) in handlearr(
                    scope.row.bmsAttendanceArrangeGetVOList
                  )"
                  :key="index"
                >
                  <span
                    class="cycle_list_item"
                    style="width: 100px"
                    v-if="item.id"
                    >{{ item.name }}</span
                  >
                  <span
                    v-else
                    class="cycle_list_item"
                    style="width: 100px; background: #e8e8e8"
                    >休息</span
                  >
                </span>
                <button
                  v-if="
                    omitDisplay(scope.row.bmsAttendanceArrangeGetVOList).length > 0
                  "
                  slot="reference"
                  class="cycle_list_popover"
                  v-popover="`refpopover${scope.row.id}`"
                >
                  +{{
                    omitDisplay(scope.row.bmsAttendanceArrangeGetVOList).length
                  }}
                </button>
              </div>
              <el-popover
                placement="top-start"
                trigger="hover"
                :ref="'refpopover' + scope.row.id"
              >
                <div class="cycle_popover" id="barparent">
                  <div
                    v-for="(item, index) in omitDisplay(
                      scope.row.bmsAttendanceArrangeGetVOList
                    )"
                    :key="index"
                  >
                    <div class="cycle_popover_item" v-if="item.id">
                      {{ item.name }}
                    </div>
                    <div
                      class="cycle_popover_item"
                      v-else
                      style="background: #e8e8e8"
                    >
                      休息
                    </div>
                  </div>
                </div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column prop="date" label="操作" width="100">
            <template slot-scope="scope">
              <el-link type="primary" @click="todeleteConfirm(scope.row)"
                >删除</el-link
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-drawer>
    <!-- 删除周期 -->
    <showDialog
      :title="'删除周期'"
      :tips="'确认删除此周期?'"
      :isShow="errorTips"
      @closeDiglog="errorTips = false"
      @affirmDiglog="deleteConfirm(deteid, 2)"
    />
    <!-- <el-dialog
      title=""
      :visible.sync="errorTips"
      width="33%"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <div class="diglog_h">
        <div>确认删除</div>
        <img
          src="@/assets/structure/close.png"
          class="diglog_icon"
          @click="errorTips = false"
        />
      </div>
      <div class="delectdiglog">
        <div v-if="this.deflag == 0" class="delectdiglog_title">
          确定要删除该周期吗？
        </div>
        <div v-else class="delectdiglog_title">确定要删除这些周期吗</div>
      </div>

      <div class="diglog_operation">
        <el-button @click="errorTips = false">取消</el-button>
        <el-button
          v-if="this.deflag == 0"
          type="primary"
          @click="deleteConfirm(deteid, 1)"
          >确认</el-button
        >
        <el-button v-else type="primary" @click="deleteConfirm(deteidlist, 2)"
          >确认</el-button
        >
      </div>
    </el-dialog> -->

    <eject2
      v-if="firstdoor"
      :isShow2="firstdoor"
      :key="firstkey"
      @closeAdd="closeAdd"
      @affirmDiglog2="affirmDiglog2"
      :groupId="groupId"
    ></eject2>
  </div>
</template>

<script>
import http from "../../utils/http";
import eject2 from "./cycleDrawer2.vue";
import showDialog from "../../assembly/dialog.vue";
export default {
  components: { eject2, showDialog },
  // props: ["isShow", "isShow2", "groupId", { cList: { type: Array } }],
  props: { isShow: Boolean, groupId: String, cList: Array },
  data() {
    return {
      tableKey: 0,
      firstkey: null,
      curVisible: true,
      isShowdiglog: this.isShow,
      cKey: 1,
      direction: "rtl",
      classData: [],
      totalWidth: [], // 监听初始化数据，否则会totalWidth报undefined
      cycleList: [],
      formList: new Array(7),
      tableSelection: [], //导出表格需要
      firstdoor: false, //第二层弹窗
      oldSelectlist: [], //旧的选择周期列表
      multipleSelection: [],
      dialogAdd: true,
      groupForm: {},
      errorTips: false, //确认删除
      deteid: 0,
      detesn: "", //要删除的sn
      deteidlist: [],
      deflag: 0,
    };
  },
  created() {
    this.getCycle();
  },
  methods: {
    closeDialog() {
      this.$emit("closeDiglog");
    },
    confirmDialog() {
      var _this = this;
      var object = {};
      // 筛选
      if (_this.multipleSelection.length <= 0) {
        object.deleteChooseGroupArrange = _this.oldSelectlist;
      }
      let arr = [];
      _this.multipleSelection.map((item) => {
        arr.push(item.id);
      });
      // 新增的班次列表
      let arr1 = _this.getAbsentValues(_this.oldSelectlist, arr);
      // 删除班次列表
      let arr2 = _this.getAbsentValues(arr, _this.oldSelectlist);

      let dArr = []; //不属于该考勤组的班次
      let cArr = []; //包含不属于该考勤组班次的周期
      let lastClist = [];
      this.cList.map((item) => {
        lastClist.push(item.bmsAttendanceArrangeGetVO.id);
      });
      for (let i = 0; i < this.multipleSelection.length; i++) {
        let classArr = this.multipleSelection[i].bmsAttendanceArrangeGetVO;
        if (classArr) {
          dArr = classArr.filter((item) => {
            if (item.id && lastClist.indexOf(item.id) < 0) {
              return item;
            }
          });
        }

        if (dArr.length > 0) cArr.push(this.multipleSelection[i].name);
      }
      let dList = [];
      dArr.map((item) => {
        dList.push(item.name);
      });
      if (cArr.length > 0) {
        _this.$message.error(
          "周期" + cArr.join("、") + "包含不属于该考勤组的班次,不能勾选"
        );
        return;
      }

      // 判断排班信息是否有变
      if (arr1.length <= 0 && arr2.length <= 0) {
        this.$message.success("修改成功");
        this.$emit("affirmDiglog");
        return;
      }
      if (arr1.length > 0) {
        object.addChooseWeek = arr1;
      }
      if (arr2.length > 0) object.deleteChooseWeek = arr2;

      http.post("/bmsAttendanceWeek/update/choose", object).then((res) => {
        if (res.code == 200) {
          this.$message.success("修改成功");
          this.$emit("affirmDiglog", arr2);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    getAbsentValues(arr1, arr2) {
      let res = [];
      arr2.forEach((item, idx) => {
        if (arr1.indexOf(item) == -1) res.push(item);
      });
      return res;
    },

    // 获取考勤周期
    getCycle() {
      http
        .get("/bmsAttendanceWeek/list", {
          attendanceGroup: parseInt(this.groupId),
        })
        .then((res) => {
          if (res.code == 200) {
            this.oldSelectlist = [];
            if (res.data.list.length > 0) {
              this.classData = res.data.list;
              this.classData.map((item) => {
                if (item.choose) {
                  this.oldSelectlist.push(item.id);
                  this.multipleSelection.push(item);
                  this.$nextTick(function () {
                    this.$refs.itemTable2.toggleRowSelection(item, true);
                  });
                }
              });
            }else{
              this.classData = []
            }
          } else {
            this.$message.error(res.message);
          }
        });
    },

    handleSelectionChange(val) {
      this.multipleSelection = [];
      val.map((item) => {
        this.multipleSelection.push(item);
      });
    },
    tableCellSytle() {
      return "background-color: #F5F6FA; color: #5C5C5C;";
    },

    //打开新建周期
    addCycle() {
      this.isShowdiglog = false;
      this.firstdoor = true;
    },
    // 关闭新建周期
    closeAdd() {
      this.firstdoor = false;
      this.firstkey = Math.random();
      this.isShowdiglog = true;
    },
    // 确认新建周期
    affirmDiglog2() {
      this.closeAdd();

      this.getCycle();
    },
    //确认删除操作
    todeleteConfirm(item) {
      this.errorTips = true;
      this.deteid = item.id;
      this.detesn = item.name;
      this.deflag = 0;
    },
    deleteConfirm(num) {
      if (num == 2) {
        var arr2 = [];
        this.multipleSelection.map((item) => {
          arr2.push(item.id);
        });
        this.deleteGroup(arr2);
      } else {
        this.deleteGroup(this.deteid);
      }
    },

    //删除周期
    deleteGroup(ids) {
      var arr = [];
      // console.log(ids);
      if (Array.isArray(ids)) {
        arr = ids;
      } else {
        arr = [ids];
      }
      http
        .post("/bmsAttendanceWeek/delete", {
          ids: arr,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("删除成功");
            let Flag = this.multipleSelection.filter((item) => {
              return item.id == ids;
            });
            if (Flag.length > 0) this.$emit("deleteCycle2", ids);
            this.errorTips = false;
            this.getCycle();
          } else {
            this.$message.error(res.message);
          }
        });
    },

    // 处理数组
    handlearr(arr) {
      if (arr) {
        var length = 0;
        var width = 0;
        for (let index = 0; index < arr.length; index++) {
          width += this.getTextWidth(arr[index].name);
          if (width > 320) {
            length = index;
            break;
          }
        }

        if (length == 0) {
          return arr;
        } else {
          return arr.slice(0, length);
        }
      }
    },

    // 省略显示的值
    omitDisplay(arr) {
      if (arr) {
        var length = 0;
        var width = 0;
        for (let index = 0; index < arr.length; index++) {
          width += this.getTextWidth(arr[index].name);
          if (width > 320) {
            length = index;
            break;
          }
        }

        if (length == 0) {
          return [];
        } else {
          return arr.slice(length, arr.length);
        }
      }
    },

    getTextWidth(text) {
      // 创建临时元素
      const _span = document.createElement("span");
      // 放入文本
      _span.innerText = text;
      // 设置文字大小
      _span.style.fontSize = "14px";
      // span元素转块级
      _span.style.position = "absolute";
      // span放入body中
      document.body.appendChild(_span);
      // 获取span的宽度
      let width = _span.offsetWidth;
      // 从body中删除该span
      document.body.removeChild(_span);
      // 返回span宽度
      return width + 16;
    },

    // 设置鼠标移入
  },
};
</script>
<style scoped>
.cycle_wrapper {
  width: 100%;
  padding: 16px 20px;
  background: #f4f7fc;
}

/* ::v-deep .cycle_wrapper-style .el-table__body,
.el-table__header {
  table-layout: fixed;
} */

.dialog_title {
  font-size: 18px;
  color: #1a1a1a;
  display: flex;
  justify-items: center;
}

.dialog_close {
  font-size: 24px;
  margin: 0 20px 0 0;
}

.dialog_confirm_c {
  background: #0058ff;
  font-size: 15px;
  width: 100px;
  height: 40px;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
}

.cycle_search {
  height: 33px;
  width: 92px !important;
  margin-bottom: 20px;
  font-size: 15px;
  border: 1px solid #0058ff;
  color: #0058ff;
  border-radius: 4px 4px 4px 4px;
  background: none;
}

::v-deep .dialog_table .el-table__cell {
  font-size: 15px;
  border: 0;
  color: #5c5c5c;
}

::v-deep .el-table {
  /* overflow-y: hidden; */
  /* height: 37.5rem; */
  /* width: 500px; */
}

.group_table_wrapper {
  /* padding: 20px 18px 20px 20px; */
  /* width: 100%; */
  overflow-x: auto !important;
}

/* 解决element-ui的table表格控件表头与内容列不对齐问题 */
body .el-table th.gutter {
  display: table-cell !important;
}

/* ::v-deep .dialog_table .el-table__body-wrapper {
  overflow-y: auto;
  height: 37.5rem;

} */

.cycle_popover {
  padding: 24px 20px;
  display: flex;
  flex-wrap: wrap;
}

.cycle_popover_item {
  padding: 8px;
  height: 26px;
  line-height: 26px;
  background: #e0ecff;
  margin-right: 6px;
  font-size: 14px;
  color: #1a1a1a;
  margin-bottom: 12px;
}

.cycle_class {
  display: flex;
  max-width: 600px;
  overflow: hidden;
}

.cycle_list_item {
  padding: 8px;
  width: 76px;
  height: 28px;
  line-height: 28px;
  background: #e0ecff;
  margin-right: 6px;
  font-size: 14px;
  color: #1a1a1a;
  word-break: keep-all;
  white-space: nowrap;
}

.cycle_list_popover {
  border: none;
  height: 28px;
  line-height: 28px;
}

.dialog_confirm {
  margin: 0 0 0 auto;
  float: right;
}
</style>
