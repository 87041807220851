<template>
  <div class="checkin">
    <div class="checkin_title">系统设置 / <span>帮助中心</span></div>
    <div class="checkin_information">
      <div class="checkin_pdf">
        <div class="checkin_pdf_left">
          <img src="@/assets/help.png" class="checkin_pdf_icon" />
          <div>科密云考勤用户操作手册</div>
        </div>
        <div class="checkin_pdf_left">
          <div class="checkin_pdf_preview" @click="preview">预览</div>
          <div class="checkin_pdf_upload" @click="download">下载</div>
        </div>
      </div>
    </div>
    <div class="filing">
      CopyRight@2023广州巨源信息技术有限公司-<a
        href="https://beian.miit.gov.cn/"
        >粤ICP备2023127373号-4</a
      >
    </div>
  </div>
</template>

<script>
import http from "../../utils/http";
import axios from "axios";
export default {
  data() {
    return {};
  },

  methods: {
    // 预览pdf
    preview() {
      const filePath = "./static/科密易考勤使用说明书.pdf";
      window.open(filePath);
    },

    // 下载pdf
    download() {
      const filePath = "./static/科密易考勤使用说明书.pdf";
      const link = document.createElement("a");
      link.href = filePath;
      link.setAttribute("download", "科密易考勤使用说明书.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>

<style scoped>
.checkin {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  box-sizing: border-box;
  background: #f4f7fc;
}
.checkin_title {
  font-size: 14px;
  color: #b2b2b2;
  margin-bottom: 16px;
}
.checkin_title span {
  color: #5c5c5c;
}
.checkin_information {
  width: 100%;
  overflow-y: scroll;
  flex: 1;
  background: white;
  border-radius: 4px;
  box-shadow: 0px 1px 4px 1px rgba(21, 34, 50, 0.08);
  border-radius: 6px 6px 6px 6px;
  box-sizing: border-box;
  padding: 40px 36px;
  box-sizing: border-box;
}
.checkin_information::-webkit-scrollbar {
  display: none;
}
.checkin_pdf {
  width: 100%;
  height: 96px;
  background: #f5f6fa;
  border: 1px solid #d5d7e3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px 0 18px;
  box-sizing: border-box;
}
.checkin_pdf_icon {
  width: 60px;
  height: 60px;
  margin-right: 16px;
}
.checkin_pdf_left {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #333333;
}
.checkin_pdf_preview {
  width: 100px;
  height: 40px;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #d5d7e3;
  font-size: 15px;
  color: #7e84a3;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}
.checkin_pdf_upload {
  width: 100px;
  height: 40px;
  background: #0058ff;
  border-radius: 4px 4px 4px 4px;
  color: white;
  text-align: center;
  line-height: 40px;
  margin-left: 30px;
  cursor: pointer;
}
</style>
